import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { SelectWorkerButton } from '../../../../generic/cards/OrderGethering/OrderGetheringItemWithDetailes';
import { LocationIcon, PackageIcon, PalletIcon, ScalesIcon, SettingsIcon, SpeedIcon, UserIcon } from '../../../../generic/icons/Icons';
import { BodyGroupContentWrapper, BodyGroupTitleWrapper, IconWrapperOrdersSecond } from '../likutExpress/likutWorkers/LikutSetWorkers';
import { BodyGroupContainerCustom } from '../likutExpress/monitoring/MonitoringMain';
import InnerGroupContainer from './InnerGroupContainer';

const Wrapper = styled.div`
    min-width: ${props => props.static ? '270px' : '250px'};
    background: ${props => props.dragged ? 'rgba(41,127,118, 0.5)' : props.overwidth ? 'rgba(255,99,71, 0.9)' : '#fff'} ;
    padding:  0.5em;
    /* overflow: auto; */
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    transition: 0.5s;
    /* top: 0; */
    right: 0;
    border-radius: 30px;
   
  

`


const TitleWrapper = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    min-height: 7.5em;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #a0bab8 */

`

const TitleInnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #eff3f4;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    gap: .5em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    padding: 0.5em;

`
const OutBoxContainer = styled.div`
    position: absolute;
    top: -0.2em;
    left: -.2em;
    width: 2.5em;
    height: 2.5em;
    background: #e9f3f2;
    display: flex;
    flex-direction: ${props => props.reversed ? 'row' : 'row-reverse'};
    align-items: center;
    justify-content: center;
    gap: 0.2em;
    border-radius: 50%;
    padding: 0.3em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    color:#75759e;
    i {
        color: tomato;
    }
`
const TitleItem = styled.div`
    width: 100%;
    /* background: red; */
    padding: 0 .5em;
    display: flex;
    flex-direction: ${props => props.reversed ? 'row' : 'row-reverse'};
    gap: 0.5em;
    justify-content: flex-end;
    color:#75759e;
    font-weight: ${props => props.mainField ? '600' : '400'};
    /* overflow: hidden; */
    
  text-overflow: ellipsis;
    i {
        color: tomato;
    }
`
const InnerRow = styled.div`
    display: flex;
    flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'} ;
    gap: 0.4em;
    justify-content: flex-end;
    /* background: red; */

`
function MainGroupContainer({
    PalletTransferZone, OrderPallets, collectDragPallets, NetWeight, item, reversed,
    transportAreaDict, cars, CustomerID, palletList, Width, statusListAvalibleToCollectInPallet, 
    index, changeDriver
}) {
    const [calculatedWidth, setCalculatedWidth] = useState(0);
    let tagAreaName = transportAreaDict[PalletTransferZone] ? transportAreaDict[PalletTransferZone]?.AreaDesc?.trim() : 'none'
    // console.log(transportAreaDict)
    let generalPalletQty = OrderPallets.reduce((acc, cur) => {
        if (cur.Custom) {
            acc += cur.PalletsQty
        } else {
            acc += cur?.pallets?.reduce((acc, cur) => {
                acc += cur.Total_Pallets
                return acc
            }, 0) || 0
        }
        return acc
    }, 0)
    const carID = transportAreaDict[PalletTransferZone]?.CarId
    const defaultWidth = cars[carID]?.Max_Weight || 0
    const carNum = cars[carID]?.CarNum
    const generalPackageQty = OrderPallets.reduce((acc, cur) => {
        if (cur.Custom) {
            // console.log(cur)
            acc += cur.Products
        } else {
            acc += cur?.pallets?.reduce((acc, cur) => {
                acc += cur.Total_Packages
                return acc
            }, 0)
        }
        return acc
    }, 0)
    // console.log(generalPackageQty)
    const dependForUpdateWidth = palletList.current
    const palletsInCar = cars[carID]?.Pallets || 0
    const driverName = cars[carID]?.DriverName?.trim() || 'empty data'
    const fillCarByPallets = Math.round((generalPalletQty / palletsInCar) * 100)
    const isOverWeight = PalletTransferZone !== 0 && (calculatedWidth > defaultWidth || generalPalletQty > palletsInCar)
    const clientsQty = () => {
        let reduced = OrderPallets.reduce((acc, cur) => {
            acc = acc.concat(cur.CustomerID)
            return acc
        }, [])
        const newSet = new Set(reduced)
        return newSet.size
    }
    useEffect(() => {
        let calc = OrderPallets.reduce((acc, cur) => {
            if (cur.Width) {
                acc += cur.Width
            } else {
                acc += cur.pallets.reduce((acc, cur) => {
                    acc += cur.GrossWeight
                    return acc
                }, 0)
            }
            return acc
        }, 0)
        setCalculatedWidth(Math.round(calc))
    }, [dependForUpdateWidth])

    // console.log(palletList)
    return (
        <Droppable
            droppableId={PalletTransferZone + ''}
            type="dropOrder"
        >
            {
                (provided, snapshot) =>
                    <Wrapper
                        static={PalletTransferZone === 0}
                        visible={true}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        dragged={snapshot.isDraggingOver}
                        overwidth={isOverWeight}
                    // key={item.PalletTransferZone}

                    >
                        <TitleWrapper>
                            <TitleInnerContainer>
                               
                                <TitleItem mainField reversed={reversed}>
                                    {
                                        !reversed ? tagAreaName : 'ללא קו'
                                    }
                                    <SpeedIcon />
                                </TitleItem>

                                <TitleItem reversed={reversed}>
                                    {
                                        !reversed &&
                                            <SelectWorkerButton 
                                                style={{padding: '0.2em 0.5em', background: '#fff'}}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    changeDriver(PalletTransferZone)
                                                    console.log(PalletTransferZone)
                                                }}
                                            
                                            >
                                                <SettingsIcon />
                                            </SelectWorkerButton>
                                    }
                                    {driverName} - {carNum}
                                    
                                    <UserIcon />
                                </TitleItem>
                                <TitleItem reversed={reversed}>
                                    <InnerRow reversed={reversed}>
                                        {
                                            !reversed &&
                                            <p>
                                                {defaultWidth} /
                                            </p>
                                        }
                                        <p>
                                            {calculatedWidth}
                                        </p>
                                    </InnerRow>
                                    <ScalesIcon />
                                </TitleItem>
                                <TitleItem reversed={reversed}>

                                    {/* <InnerRow reversed={reversed}>
                                        
                                    </InnerRow> */}
                                    {/* {palletQty} */}
                                    <InnerRow reversed={reversed}>
                                        {
                                            !reversed &&
                                            <p>
                                                {palletsInCar} /
                                            </p>
                                        }

                                        <p>
                                            {generalPalletQty}
                                        </p>
                                        {
                                            !reversed &&
                                            <p>
                                                ({fillCarByPallets}%)
                                            </p>
                                        }

                                    </InnerRow>
                                    <PalletIcon />
                                </TitleItem>
                                <TitleItem reversed={reversed}>
                                    <InnerRow reversed={reversed}>
                                        <LocationIcon />
                                        {clientsQty()}
                                    </InnerRow>
                                    <InnerRow reversed={reversed}>
                                        <PackageIcon />
                                        {generalPackageQty} 
                                    </InnerRow>
                                </TitleItem>
                            </TitleInnerContainer>
                        </TitleWrapper>

                        {/* <BodyGroupTitleWrapper>
                            {PalletTransferZone}
                            <IconWrapperOrdersSecond>
                                {OrderPallets?.length}
                            </IconWrapperOrdersSecond>
                        </BodyGroupTitleWrapper> */}
                        {
                            OrderPallets?.map((item, index) =>
                                <InnerGroupContainer
                                    {...item}
                                    PalletTransferZone={PalletTransferZone}
                                    index={index}
                                    key={item.OrderID}
                                    collectDragPallets={collectDragPallets}
                                    reversed={reversed}
                                    statusListAvalibleToCollectInPallet={statusListAvalibleToCollectInPallet}

                                />
                            )
                        }

                        {provided.placeholder}

                    </Wrapper>
            }




        </Droppable>
    );
}

export default MainGroupContainer;