import React, {useState} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import SearchBlockWithTitle from '../../procedures/Q8/Layouts/SearchBlockWithTitle'
import styled from 'styled-components'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import SearchGrower from '../../knisot/Create/Components/SearchGrower'
import { IGrowerItem, IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import SearchCustomers from '../../forcasts/Supply/Components/SearchCustomers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'

const LikutMain = () => {
  const customerList = useSelector((state: RootState) => state.defaults?.clientList?.list)
  const [barcode, setBarcode] = useState<string>('')
  const [growerID, setGrowerID] = useState<number>(0)
  const [customerID, setCustomerID] = useState<number>(0)

  const {
    data: growerList
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, 'data'
  )
  
  return (
    <OrderlikeLayout
      label='pallet '
    >
      <MainWrapper>
        
        <SearchBlockWithTitle
          title=''
        >
          <SearchInput 
            input={barcode}
            setInput={setBarcode}
            iconHide={true}
            value={'חיפוש לפי ברקוד'}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title='מיגדל'
        >
          <SearchGrower 
            list={growerList?.growers || []}
            defaultID={growerID}
            setGrowerID={setGrowerID}
            placeholder='בחר מיגדל'

          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title='לקוח'
        >
          <SearchCustomers 
            defaultID={customerID}
            setCustomerID={setCustomerID}
            list={customerList || []}
            placeholder='בחר לקוח'
          
          />
        </SearchBlockWithTitle>

      </MainWrapper>
    </OrderlikeLayout>
  )
}
const MainWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  background: #fff;
  border-radius: 30px;
  padding: 0.5em 0;
`

export default LikutMain