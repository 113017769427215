import React from 'react'
import styled from 'styled-components'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, CalendatDay, EditIcon, InvoiceIcon, InvoiceMoneyIcon, LinkIcon, PackageIcon, PalletIcon, ScalesIcon } from '../../../../../../generic/icons/Icons'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import cogsPng from '../../../../../../generic/assets/cogsPNG.png'



const Info = () => {
  return (
    <MainWrapper>
        <Img src={cogsPng} alt='cogs' />
        <TitleLayout>
            <TitleRow>
                <TitleRowItem name='מספר תעודה - ' icon={<InvoiceIcon />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='מספר חשבונית - ' icon={<InvoiceMoneyIcon />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='אסמכתא - ' icon={<LinkIcon />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='כמות אריזות - ' icon={<PackageIcon />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='כמות משטחים - ' icon={<PalletIcon />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='משקל - ' icon={<ScalesIcon />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='תאור פריט - ' icon={<AppleIcon />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='תאריך - ' icon={<CalendatDay />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name='הערה לשורה - ' icon={<EditIcon />} />
            </TitleRow>
        </TitleLayout>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    /* gap: 0.5em; */
    padding: 0.5em 2em;
`
const Img = styled.img`
    min-width: 350px;
`
export default Info