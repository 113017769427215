import styled from 'styled-components'
import React from 'react'

const Wrapper = styled.div`
    width: 100%;
    min-height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    /* background: red; */
    color: #fff;
    z-index: 1;
`

export default function Title({label, color}) {
    return (
        <Wrapper style={{color: color ? color : '#fff'}}>
            {label}
        </Wrapper>
    )
}
