import React, {useState, useMemo, useEffect, useContext} from 'react'
import { getWeekDetailsByDate } from './hooks/getWeekDetailsByDate'

import FullSizeLayout from '../../../../../generic/Layouts/FullSizeLayout';
import BodyScrollContainer from './Layouts/BodyScrollContainer';
import DateColumnItem from './Components/DateColumnItem';
import DatePickerISO from './Components/DatePickerISO';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes';
import { Forecasts } from '../../../../../modules/API/Forcasts/forcasts';
import { IForecastsItem } from '../../../../../modules/API/Drivers/models/kavData';
import { CalendarContainerProps } from './types/dayColumn';
import { RootState } from '../../../../../../store/reducers/rootReducer';
import { IRecommendationItem } from '../../../../../modules/API/Recommendation/models/recomendationItem';
import { Recommendation } from '../../../../../modules/API/Recommendation/Recommendation';
import ColumModal from '../../../../../generic/Layouts/ColumModal';
import ReccomendationModal from './Components/Modals/ReccomendationModal';
import { ForcastProductItem } from '../../../../../modules/API/Forcasts/models/forcastProductitem';
import { createForecastItemFromRec } from './utils/createForecastItemFromRec';
import { ContextModal } from '../../../../../modules/modal/modalContext';
import { IDataForEditForecastItem } from './types/dataForEditForecastItem';
import EditForecastItemModal from './Components/Modals/EditForecastItemModal';
import ButtonDateControl from './Components/ButtonDateControl';
import styled from 'styled-components';
import { dateChangeWeek } from './utils/dateChangeWeek';
import { IRecommendationModifiedItem } from './types/recommendationModified';
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import useDataFetcher from '../../knisot/hooks/useFetchData';
import { IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers';
import { Defaults } from '../../../../../modules/API/Defaults/defaults';
import SearchGrower from '../../knisot/Create/Components/SearchGrower';
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal';


const ForcastsMain = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const isGrower = user?.rights.includes("GROWER")
  const [modal, setModal] = useContext(ContextModal)
  const defaults = useSelector((state: RootState) => state.defaults.defaults)
  const [isOpenRecModal, setIsOpenRecModal] = useState<boolean>(false)
  const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false)
  const [isOpenForecastModal, setIsOpenForecastModal] = useState<boolean>(false)
  const [selectedDataForRec, setSelectedDataForRec] = useState<{index: number, list: ForcastProductItem[]}>()
  const [selectedDataForForecast, setSelectedDataForForecast] = useState<IDataForEditForecastItem>()
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [forecastsList, setForecastsList] = useState<CalendarContainerProps[]>([])
  const [reccomendationList, setReccomendationList] = useState<IRecommendationItem[]>()
  const [growerID, setGrowerID] = useState<number>(isGrower ? Number(user?.code_internal) : 0)
  const calculationMethod = selectBrodMigdal(defaults, 344, "Num_Value")
 
  const [weekRange, formattedRange] = useMemo(() => {
    const [weekRange, formattedRange] = getWeekDetailsByDate(selectedDate)
    setForecastsList(weekRange)
    return [weekRange, formattedRange]
  }, [selectedDate, growerID])

  const {
    data: growerList,
    isLoading: growerListLoading
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, "data"
  )

  const selectedGrower = useMemo(() => {
    return growerList?.growers.find((grower) => grower.GrowerID === growerID)
  },[growerList, growerID])

  useEffect(() => {
    (async () => {
      dispatch({type: LOAD})
      try {
        const fetchGrowerID = isGrower ? Number(user?.code_internal) : growerID
        // setGrowerID(fetchGrowerID)
        if(!isGrower && !growerID) return
        //DateFrom=2&DateTo=3
        let recomList = reccomendationList
        const resForcasts = await Forecasts.getForecastsByDateRange(`DateFrom=${formattedRange[0]}&DateTo=${formattedRange[1]}&GrowerID=${fetchGrowerID}`)
        if (!recomList || !isGrower) {
          // TO DO CHANGE TO USER ID
          recomList = await Recommendation.getRecommendationByGrowerID(fetchGrowerID)
          setReccomendationList(recomList)
        }
        const newArr = [...weekRange]
        console.log(weekRange)
        resForcasts.forEach((item) => {
          const index = newArr.findIndex((day) => day.dateRequestFormat === item.Collection_Date)
          if (index !== -1) {
            // PART FOR MERGE DUPLICATE ITEMS
            const foundItem = newArr[index].list.find((listItem) => listItem.ProductID === item.ProductID && listItem.VarietyID === item.VarietyID)
            // if(foundItem) {
            //   newArr[index].list = newArr[index].list.map((listItem) => {

            //   })
            // }
            newArr[index].list = [
              ...newArr[index].list,
              item
            ]
            
          }
        })
        setForecastsList(newArr)
      } catch (error) {
        console.log(error)
      } finally{
        
        dispatch({type: LOAD_END})
      }
    })()
  }, [weekRange, formattedRange, growerID,])

  const createForecastFromRec = async (recItems: IRecommendationModifiedItem[]) => {
    if(!selectedDataForRec) return
    // const isAlreadyExistItem = forecastsList[selectedDataForRec.index].list.some(item => item.ProductID === recItem.ProductID && item.VarietyID === recItem.VarietyID)
    // if(isAlreadyExistItem) return

    try {
      const finalResult = recItems.map((recItem) => {
        const isGrower = user?.rights.includes("GROWER")
        const fetchGrowerID = isGrower ? Number(user?.code_internal) : growerID
        let item = createForecastItemFromRec(recItem, fetchGrowerID)
        item.Collection_Date = forecastsList[selectedDataForRec.index].dateRequestFormat
        return item
      })
      // const dataItem = createForecastItemFromRec(recItems)
      // dataItem.Collection_Date = forecastsList[selectedDataForRec.index].dateRequestFormat
      const res = await Forecasts.createForecast(finalResult)
      const newArr = [...forecastsList]
      newArr[selectedDataForRec.index].list = [
        ...newArr[selectedDataForRec.index].list,
        ...res
      ]
      setForecastsList(newArr)
      handleOpenRecModal()
      setModal({
        open: true,
        value: "נוצר בהצלחה",
        mood: true,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000);
    } catch {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000);
    }
  }

  const editForecastItem = async (data: IDataForEditForecastItem) => {
    try {
      const res = await Forecasts.updateForecast(data.itemData.item)
      const newArr = [...forecastsList]
      const index = newArr.findIndex((day) => day.dateRequestFormat === data.itemData.item.Collection_Date)
      const itemIndex = newArr[index].list.findIndex((item) => item.RecID === data.itemData.item.RecID)
      newArr[index].list[itemIndex] = data.itemData.item
      setForecastsList(newArr)
      handleOpenForecastModal()
      setModal({
        open: true,
        value: "נשמר בהצלחה",
        mood: true,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000);
    } catch {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000);
    }
  }
  const handleDeleteForecastItem = async (recID: number) => {
    try {
      await Forecasts.deleteForecasts(recID)
      const newArr = [...forecastsList]
      const index = newArr.findIndex((day) => day.dateRequestFormat === selectedDataForForecast?.itemData.item.Collection_Date)
      const itemIndex = newArr[index].list.findIndex((item) => item.RecID === recID)
      newArr[index].list.splice(itemIndex, 1)
      setForecastsList(newArr)
      handleOpenForecastModal()
      setModal({
        open: true,
        value: "נמחק בהצלחה",
        mood: true,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      
      }, 3000)
    } catch (err) {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000)
    } finally {

    }
  }
  const handleControlFilterModal = () => {
    setIsOpenFilterModal(!isOpenFilterModal)
  }

  const handleChangeWeekDate = (dir: "back" | "up") => {
    setSelectedDate(dateChangeWeek(selectedDate, dir))
  }

  const handleOpenRecModal = () => {
    setIsOpenRecModal(!isOpenRecModal)
  }

  const handleOpenForecastModal = () => {
    setIsOpenForecastModal(!isOpenForecastModal)
  }

  const selectDayForRec = (columnIndex: number, list: ForcastProductItem[]) => {
    setSelectedDataForRec({index: columnIndex, list})
    handleOpenRecModal()
  }

  const selectDayForForecast = (data: IDataForEditForecastItem) => {
    setSelectedDataForForecast(data)
    handleOpenForecastModal()
  }

  const recommendationModalProps = {
    recList: reccomendationList,
    selectedDataForRec: selectedDataForRec,
    createForecastFromRec: createForecastFromRec,
    growerID: growerID,
    calculationMethod: calculationMethod
  }

  const forecastModalProps = {
    selectedDataForForecast: selectedDataForForecast,
    editForecastItem: editForecastItem,
    calculationMethod: calculationMethod,
    handleDeleteForecastItem: handleDeleteForecastItem
  }

  const filterModalProps = {
    growerID: growerID,
    setGrowerID: setGrowerID,
    growerList: growerList?.growers || []
  }
  

  return (
    <FullSizeLayout label={'תחזית אספקה'}>
      {/* {
        isOpenFilterModal &&
          <ColumModal 
            title='סינון'
            Children={FilterModal}
            childrenProps={filterModalProps}
            controlFunc={handleControlFilterModal}
          />
      } */}
      {
        isOpenForecastModal &&
          <ColumModal 
            title='עדכון תחזית'
            Children={EditForecastItemModal}
            childrenProps={forecastModalProps}
            controlFunc={handleOpenForecastModal}
          />
      }
      {
        isOpenRecModal && 
          <ColumModal 
            title={`המלצות ל- ${forecastsList[selectedDataForRec?.index || 0].dateDesc}`}
            Children={ReccomendationModal}
            childrenProps={recommendationModalProps}
            controlFunc={handleOpenRecModal}
          />
      }
      <HeaderWrapper>
        <ControlSearchPannelLayout>
          {/* <NameWrapper>

            {selectedGrower?.GrowerName?.trim()}
          </NameWrapper> */}
          {
            !isGrower && 
              <SearchGrower
                list={growerList?.growers || []}
                defaultID={growerID}
                setGrowerID={setGrowerID}
                required
                placeholder='בחר ספק'
              />
          }
        <DateWrapper>
        {/* <CircleActiveFilterBtn
          func={handleControlFilterModal}
        >
          <UserIcon />
        </CircleActiveFilterBtn> */}
          <ButtonDateControl
            position='left'
            handleChangeWeekDate={handleChangeWeekDate}
            />
          <DatePickerISO
            selected={selectedDate}
            onChange={(date: Date) => setSelectedDate(date)}
            />
          <ButtonDateControl
            position='right'
            handleChangeWeekDate={handleChangeWeekDate}
            />
        </DateWrapper>
        </ControlSearchPannelLayout>
      </HeaderWrapper>
      <BodyScrollContainer>
        {
          forecastsList.map((day, index) => {
            return (
              <DateColumnItem 
                key={day.dateRequestFormat} 
                item={day}
                index={index}
                selectDayForRec={selectDayForRec}
                selectDayForForecast={selectDayForForecast}
                calculationMethod={calculationMethod}
                isAwailableToCreateForecast={!!growerID}
              />
            )
          })
        }
        
      </BodyScrollContainer>
    </FullSizeLayout>
  )
}
const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
`
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  
`
export default ForcastsMain