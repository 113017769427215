const viewWindow = {
  // "packageOrder_ViewWindow_title": 'הזמנה/החזרה אריזה {{defID}}',
  "packageOrder_ViewWindow_title": 'الطلب/الإرجاع - التغليف {{defID}}',
}

export const packageOrderTranslationAR = {
  packageOrder: {
    "packageOrder_orders_title": "الطلب/الإرجاع - التغليف",
    "packageOrder_orders_search_placeholder": "بحث",
    "packageOrder_modal_teuda_title": "طلب تغليف: {{num}}",
    "packageOrder_modal_teuda_checkbox": "إرسال بريد إلكتروني",
    "packageOrder_modal_remark_title": 'حاوية بلاستيكية دولف',
    "packageOrder_modal_print_title": "طباعة",
    "packageOrder_modal_print_order": "طلب",
    "packageOrder_modal_print_certificate": "شهادة",
    "packageOrder_NewOrder_lable_date": "تاريخ",
    "packageOrder_NewOrder_lable_sender": "المرسل",
    "packageOrder_NewOrder_lable_receipient": "المستلم",
    "packageOrder_NewOrder_lable_car": "سيارة",
    "packageOrder_NewOrder_lable_refference": "مرجع",
    "packageOrder_NewOrder_placeholder_sender": "اختر المر",
    "packageOrder_NewOrder_placeholder_receipient": "اختر المستلم",
    "packageOrder_NewOrder_placeholder_car": "اختر سيارة",
    "packageOrder_NewOrder_placeholder_refference": "مرجع",
    "packageOrder_NewOrder_checkbox": "طلب توصيل",
    ...viewWindow
  }
}