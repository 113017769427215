import React, {useState} from 'react'
import { IKavDataItem } from '../../../../../modules/API/Drivers/models/kavData'
import { IClientItem } from '../../../../../modules/API/Defaults/models/clients'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../generic/assets/package.png'
import SearchClient from './SearchClient'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import SelectFromTheList from '../../../../../generic/customSearchComponents/SelectFromTheList'
import SearchBlockWithTitle from '../../procedures/Q8/Layouts/SearchBlockWithTitle'
import { ArrowIcon } from '../../../../../generic/icons/Icons'
import { IPackPalletDefaultClient } from '../../../../../modules/API/Packages/models/arizotLine'
type Props = {
  item: IKavDataItem
  clientList: IClientItem[]
  createPackageOrder: (
    clientInfo: {
      clientID: number,
      selectID: number
    }
  ) => void
  tranzitClient: IPackPalletDefaultClient
}

const selectList = [
  {
    id: 1,
    name: 'החזרה'
  },
  {
    id: 2,
    name: 'מסירה'
  }
]
const PackageOrderTrucks = ({
  item, clientList, createPackageOrder, tranzitClient
}: Props) => {
  const [clientID, setClientID] = useState<number>(0)
  const [selectID, setSelectID] = useState<number>(1)
  const selectedClient = clientList.filter(item => item.ClientID === clientID)[0]
  
  const handleSelect = (id: number) => {
    setSelectID(+id)
  }
  const handleSave = () => {
    
    createPackageOrder({
      clientID,
      selectID,
    })
  }
  return (
    <MainWrapper>
      <ImageContainer>
        <ImageComponentHead 
          img={Image}
          large
        />
      </ImageContainer>
      <BodyContainer>
        <SearchClient 
          list={clientList}
          defaultID={clientID}
          setClientID={setClientID}
          requered
        />
        <SearchBlockWithTitle
          title={'סוג פעולה'}
        >
          <SelectFromTheList 
            selectedValue={selectID}
            list={selectList}
            lable={'בחר סוג פעולה'}
            selectFunction={handleSelect}
            size={'full'}
            dispalyFieldName='name'
            itemKey='id'
          />
        </SearchBlockWithTitle>
        {
          clientID ? 
            <TextWrapper>
                {
                  selectID === 1 ? 
                    <>
                      {tranzitClient.ClientName?.trim()}
                      <ArrowIcon position={'left'} />
                      {selectedClient.ClientName?.trim()}
                    </> :
                    <>
                      {selectedClient.ClientName?.trim()}
                      <ArrowIcon position={'left'} />
                      {tranzitClient.ClientName?.trim()}
                    </>
                }

            </TextWrapper> : ''
          
        }
      </BodyContainer>
      {
        clientID ?
        <ControlButton 
          lable={'שמור'}
          handleClick={handleSave}
        /> : ''

      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 80%;
  height: 100%;
  padding: 0.5em;
  display: flex;
  flex-flow: column;
  gap: 1em;

`
const BodyContainer = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-flow: column;
  gap: 1em;

`
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  color: #75759e;
  padding: 0.5em;
  border-radius: 20px;
  background: #EFF3F4;

`
const ImageContainer = styled.div`
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export default PackageOrderTrucks