import React from 'react'
import ArizotItemLayout from '../../../../../../generic/Layouts/Wrappers/ArizotItemLayout'
import { IPackTypeItem } from '../../../../../../modules/API/Packages/models/mlaiTypes'

interface IProps extends IPackTypeItem {
    id: string,
    updateInput: (qty: number, id: string) => void,
    disableInput?: boolean
}

const ArizotTypeItem = ({Description, PD_Type, Qty, id, updateInput, disableInput}: IProps) => {
    const isArizot = PD_Type === 'א' 
    console.log(id)
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let toNum = +e.target.value
        updateInput(toNum, id)
    }
  return (
    <ArizotItemLayout 
        Description={Description}
        isArizot={isArizot}
        defaultQty={Qty}
        onChange={handleInput}
        disableInput={disableInput}
    />
  )
}

export default ArizotTypeItem