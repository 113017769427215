import {useState, useMemo, useContext, useRef} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Recommendation } from '../../../../../modules/API/Recommendation/Recommendation'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { PlusIcon } from '../../../../../generic/icons/Icons'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import RecomendationItem from './Components/RecomendationItem'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IProductsWithVarietyItem } from '../../../../../modules/API/Defaults/models/compliteProducts'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import CreateRecommendationProductModal from './Modals/CreateRecommendationProductModal'
import { IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { IPostRecommendationProductFunctionProps } from './types/createRecommendationProductProps'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'
import { IProductListNodeResponse } from '../../../../../modules/API/Defaults/models/productListNode'

type Props = {}
//"Code_Dest_Suppl": 0, - growerID
//"Dest_Suppl": 1, - grower
const GrowerProductsMain = (props: Props) => {
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const matchModalRoute = useRouteMatch(`${LINKS.forecasts.products.mainURL}/create`)
  const history = useHistory()
  const [modal, setModal] = useContext(ContextModal)
  const [input, setInput] = useState<string>("")
  const {
    data: recommendedProducts,
    setData: setRecommendedProducts
  } = useDataFetcher(
    Recommendation.getRecommendationByGrowerID, `${user?.code_internal || 0}`
  )
  const {
    data: growerList,
    isLoading: growerListLoading
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, "data"
  )
  const {
    data: poductList,
  } = useDataFetcher<IProductListNodeResponse>(
    Defaults.getProductListNode, "1"
  )
  
  const handleControlModal = () => {
    if(matchModalRoute) return history.push(LINKS.forecasts.products.mainURL)
    history.push(`${LINKS.forecasts.products.mainURL}/create`)
  }

  const handleDelete = async (recID: number) => {
    try {
      if (!recommendedProducts) return
      const index = recommendedProducts.findIndex(item => item.RecID === recID)
      await Recommendation.deleteRecommendation(recommendedProducts[index].RecID)
      const newRecommendedProducts = recommendedProducts.filter((_, i) => i !== index)
      setRecommendedProducts(newRecommendedProducts)
      setModal({
        open: true,
        value: "הפריט נמחק בהצלחה",
        mood: true,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000);
    } catch (error) {
      setModal({
        open: true,
        value: "הפריט לא נמחק",
        mood: false,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000);
    }
  }

  const handleCreateRecommendation = async (data: IPostRecommendationProductFunctionProps) => {
    try {
      const postData = {
        Dest_Suppl: 1,
        ...data
      }
      const res = await Recommendation.createRecommendation(postData)
      setRecommendedProducts([...recommendedProducts || [], res])
      handleControlModal()
      setModal({
        open: true,
        value: "נוצר בהצלחה",
        mood: true,
      });
      setTimeout(() => {
        setModal({
          open: false
        });
      }, 3000);
    } catch (error: any) {
      console.log(error.response)
      if(error?.response?.status === 405) {
        setModal({
          open: true,
          value: "הפריט כבר קיים",
          mood: false,
        });
        setTimeout(() => {
          setModal({
            open: false
          });
        }, 3000);
      } else {
        setModal({
          open: true,
          value: "משהו השתבש",
          mood: false,
        });
        setTimeout(() => {
          setModal({
            open: false
          });
        }, 3000);
      }
    }
  }
  
  const recommendationModalProps = {
    productList: poductList?.products || [],
    growerList: growerList?.growers || [],
    handleCreateRecommendation: handleCreateRecommendation,
    user: user
  }

  const render = useMemo(() => {
    if(!recommendedProducts) return []
    return recommendedProducts.filter((item) => 
       `${item.ProductDesc?.trim()} ${item.VarietyDesc?.trim()}`.toLowerCase().includes(input.toLowerCase()) ||
        item.GrowerName?.trim().toLowerCase().includes(input.toLowerCase())
    )
  }, [input, recommendedProducts])

  return (
    <OrderlikeLayout
      label='פריטים לספק'
    >
      {
        !!matchModalRoute && !growerListLoading &&
        <ColumModal 
          Children={CreateRecommendationProductModal}
          childrenProps={recommendationModalProps}
          controlFunc={handleControlModal}
          title='הוספת פריט לספק'
        />
      }
      <ControlSearchPannelLayout>
        <SearchInput 
          input={input}
          setInput={setInput}
        />
        <CircleActiveFilterBtn func={handleControlModal}>
          <PlusIcon />
        </CircleActiveFilterBtn>
      </ControlSearchPannelLayout>
      <TitlesList 
        list={render}
        uniqueKey={'RecID'}
        additionalData={{
          deleteFunc: handleDelete
        }}
        Element={RecomendationItem}
        noPadding={false}
      />
    </OrderlikeLayout>
  )
}

export default GrowerProductsMain