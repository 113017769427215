import React, {useCallback} from 'react'
import { IPackMovementsitem } from '../../../../../../modules/API/Packages/models/packsMovments'
import TableBody from '../../../procedures/Q8/Layouts/Table/TableBody'
import TableRow from '../../../procedures/Q8/Layouts/Table/TableRow'
import TableBodyCell from '../../../procedures/Q8/Layouts/Table/TableBodyCell'
import { convertToDate } from '../../../../../../modules/convertDateString'
import { PackageIcon, PalletIcon } from '../../../../../../generic/icons/Icons'
type Props = {
  list: IPackMovementsitem[]
}

const TableBodyMovements = ({
  list
}: Props) => {
  const dateConverted = useCallback((date: string) => {
    return convertToDate(date)
  }, [])
  return (
    <TableBody>
      {
        list.map((item, index) => 
        <TableRow key={index}>
          <TableBodyCell>{dateConverted(item.ValueDate)}</TableBodyCell>
          <TableBodyCell>{item.SenderName?.trim()}</TableBodyCell>
          <TableBodyCell>{item.RecieverName?.trim()}</TableBodyCell>
          <TableBodyCell>
              <>
                  {item.Pack_Name?.trim()}
                  {
                      item.Type?.trim() === 'א' ?
                          <PackageIcon />:
                              <PalletIcon />
                  }
              </>
          </TableBodyCell>
          <TableBodyCell>{item.Quantity.toLocaleString()}</TableBodyCell>
                  

      </TableRow>
        )
      }
    </TableBody>
  )
}

export default TableBodyMovements