import React, {useState} from 'react'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../generic/assets/delivery.png'
import { IExternalPallet } from '../../../../../modules/API/Knisot/models/externalPallet'
import SearchBlockWithTitle from '../../procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchGrower from '../../knisot/Create/Components/SearchGrower'
import { IGrowerItem } from '../../../../../modules/API/Defaults/models/growers'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IVarietyListResponse } from '../../../../../modules/API/Defaults/models/varietyList'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import SearchProduct from '../../forcasts/Products/Components/SearchProduct'
import { IProductListItemNode } from '../../../../../modules/API/Defaults/models/productListNode'
import SearchVaiety from '../../forcasts/Products/Components/SearchVaiety'
import SearchPalletType from '../../knisot/Create/Components/SearchPalletType'
import { IPalletTypeItem } from '../../../../../modules/API/Defaults/models/pallets'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import SearchPackage from '../../knisot/Create/Components/SearchPackage'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { IPostExternalEntryDetail } from '../../../../../modules/API/Knisot/models/postExternalEntryDetail'
import SearchWarehouse from '../../knisot/Create/Components/SearchWarehouse'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
type Props = {
  item: IExternalPallet,
  growerList: IGrowerItem[]
  productList: IProductListItemNode[]
  palletTypeList: IPalletTypeItem[]
  packageList: IPackagesItem[],
  createLocalEntryDetail: (data: IPostExternalEntryDetail) => void,
  tranzitList: IWarhouseListItem[]
}

const ModalExternalPaletItem = ({
  item, growerList, productList, palletTypeList,
  packageList, createLocalEntryDetail, tranzitList
}: Props) => {
  const [t] = useTranslation('buttons')
  const {
    GrowerDesc, GrowerLocal, ProductLocal, VarietyLocal, Product, Variety,
    VarietyDesc, ProductDesc, TypePalletLocal, ExternalPallet, EntryNum,
    AmountPack, TypePackDesc, TypePalletDesc, EntryStatus, DetailStatus, 
    TypePackLocal, TranzitNameLocal, TranzitLocal, Tranzit, TranzitDesc, Locating
  } = item
  const [selectedGrowerID, setSelectedGrowerID] = useState<number>(GrowerLocal)
  const [selectedProductID, setSelectedProductID] = useState<number>(ProductLocal || 0)
  const [selectedVarietyID, setSelectedVarietyID] = useState<number>(VarietyLocal || 0)
  const [selectedPalletTypeID, setSelectedPalletTypeID] = useState<number>(TypePalletLocal)
  const [selectedPackageID, setSelectedPackageID] = useState<number>(TypePackLocal)
  const [packageQty, setPackageQty] = useState<number>(AmountPack || 0)
  const [slectedTransit, setSlectedTransit] = useState<number>(TranzitLocal || 0)
  const [location, setLocation] = useState<string>(Locating ||'')
  const {
    data: varietyList
  } = useDataFetcher<IVarietyListResponse>(
    Defaults.getVarietyByProductID, `${selectedProductID}`
  )
  const handleSave = () => {
    const data: IPostExternalEntryDetail = {
      Grower: selectedGrowerID,
      ProductID: selectedProductID,
      VarietyID: selectedVarietyID,
      TypePallet: selectedPalletTypeID,
      TypePack: selectedPackageID,
      AmountPack: +packageQty,
      ExternalPallet: ExternalPallet,
      ExpctEntryNum: EntryNum,
      ExternalProduct: Product,
      ExternalVariety: Variety,
      Tranzit: slectedTransit,
      Locating: location
  }

  createLocalEntryDetail(data)
}

  const defVariety = varietyList?.variety.filter(item => item.VarietyID === VarietyLocal)[0]
  const isAllowToSave = selectedGrowerID && selectedProductID && selectedVarietyID && selectedPalletTypeID && selectedPackageID && packageQty
  const handleLocation = (e: string) => {
    if(location.length >= 15) return
    setLocation(e)
  }
  return (
    <MainContainer>
      {
        DetailStatus !== 0 && <DisabledOverlay />
      }
      <ImageContainer>
        <ImageComponentHead 
          img={Image}
        />
      </ImageContainer>
      <ContentContainer>
        <SearchBlockWithTitle
          title={
            <Paragraph>
              ספק: <span>{GrowerDesc.trim()}</span>
            </Paragraph>
          }
        >
          <SearchGrower 
            setGrowerID={setSelectedGrowerID}
            defaultID={selectedGrowerID}
            required={!selectedGrowerID}
            list={growerList}
            disabled={DetailStatus !== 0 }
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={[
            <Paragraph>
              פריט: <span>{ProductDesc.trim()} {VarietyDesc.trim()}</span>
            </Paragraph>,
            <Paragraph>זן</Paragraph>
          ]}
        >
          {
            selectedProductID ?
              <SearchVaiety 
                setID={setSelectedVarietyID}
                defaultID={selectedVarietyID || VarietyLocal || (defVariety?.VarietyID || 0) }
                required={!selectedVarietyID}
                list={varietyList?.variety || []}
                
              /> : ''
          }
          <SearchProduct 
            setDefaultProductID={setSelectedProductID}
            defaultProductID={selectedProductID || ProductLocal}
            required={!selectedProductID}
            list={productList}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={
            <Paragraph>
              סוג משטח: <span>{TypePalletDesc.trim()}</span>
            </Paragraph>
          }
        >
          <SearchPalletType 
            setPalletID={setSelectedPalletTypeID}
            defaultID={selectedPalletTypeID || TypePalletLocal}
            required={!selectedPalletTypeID}
            list={palletTypeList}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={
            <Paragraph>
              מחסן: <span>{TranzitDesc.trim()}</span>
            </Paragraph>
          }
        >
          <SearchWarehouse 
            defaultID={slectedTransit || TranzitLocal}
            setWarehouseID={setSlectedTransit}
            list={tranzitList}
            disabled={EntryStatus !== 0}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={
            <Paragraph>
              {/* מחסן: <span>{TranzitDesc.trim()}</span> */}
              איתור למשטח
            </Paragraph>
          }
        >
          <SearchInput 
            input={location}
            setInput={handleLocation}
            iconHide
            value={'איתור'}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={[
            <Paragraph>
              סוג אריזה: <span>{TypePackDesc.trim()}</span>
            </Paragraph>,
            'כמות',
          ]}
        >
          <SearchInput 
            type={'number'}
            setInput={setPackageQty}
            input={(packageQty || AmountPack)?.toString()}
          />
          <SearchPackage 
            setPackageID={setSelectedPackageID}
            defaultID={selectedPackageID}
            required={!selectedPackageID}
            list={packageList}
          />
        </SearchBlockWithTitle>
        
      </ContentContainer>
      {
        DetailStatus === 0 &&
        <ControlButton 
          disabled={!isAllowToSave}
          lable={t('save')}
          handleClick={handleSave}
        />
      }
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 90%;
  max-height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 20%;
`
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`

const DisabledOverlay = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 30px;
  /* right: 0; */
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;

`
const Paragraph = styled.p`
  span {
    color: tomato;
  }

`
export default ModalExternalPaletItem