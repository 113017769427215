import React from 'react'

export default function LikutExpressPrintersItem({AddressWeight, DescWeights, NumWeight, printerNum}) {
    // console.log(printerNum === NumWeight)
    return (
        <option
            value={NumWeight}
            selected={+printerNum === NumWeight ? true : false}
        >
            {`${DescWeights}(${AddressWeight || ''})`}
        </option>
    )
}
