import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: ${props => props.noPadding ? '1em 0' : '1em 0.5em'};
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    /* align-items: center; */
    background: ${props => props.backgroundColor ? props.backgroundColor : '#fdfdfd'} ;
    overflow-y: auto;
    overflow-x: hidden;

    gap: 0.5em;
    border-radius: ${props => props.borderLess ? "0" : '30px'} ;
    ::-webkit-scrollbar {
        width: 0;
    }
    /* border-bottom: 1px solid #5f5353; */
`
export default function TitlesList({ list, uniqueKey, additionalData, Element, queryRender, noPadding, backgroundColor, borderLess }) {
    return (
        <MainWrapper 
            noPadding={noPadding} 
            backgroundColor={backgroundColor}
            borderLess={borderLess}
        >
            {
                queryRender ?
                    list?.map((item, index) => {
                        if (queryRender(item)) {
                            return <Element
                                key={item[uniqueKey]}
                                index={index}
                                item={item}
                                {...item}
                                {...additionalData}
                            />
                        }
                        else return null
                    }
                    )
                    :
                    list?.map((item, index) =>
                        <Element
                            key={item[uniqueKey] || index}
                            index={index}
                            item={item}
                            {...item}
                            {...additionalData}
                        />
                    )
            }
            <div 
                id='last-item'
                style={{
                    opacity: '0', 
                    width: '100%',
                    height: '1em',
                    background: 'red'
                }}
            >123</div>
        </MainWrapper>
    )
}

TitlesList.propTypes = {
    list: PropTypes.array,
    uniqueKey: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    additionalData: PropTypes.any,
    Element: PropTypes.func,
    queryRender: PropTypes.func || PropTypes.bool,
    borderLess: PropTypes.bool
}

TitlesList.defaultProps = {
    backgroundColor: '#fdfdfd', 
    borderLess: false
}
