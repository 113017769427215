import { CONTROL_PALLETS_CLEAR_STATE, CONTROL_PALLETS_EDIT_GOOD, CONTROL_PALLETS_SELECT_PALLET, CONTROL_PALLET_LOAD_NEW_CLEAR_ITEM, CONTROL_PALLET_LOAD_PREVIOUS_STATE_FOR_GOOD, CONTROL_PALLET_NEW_CLEAR_ITEM, SELECT_CLIENT } from "../actions/actionsTypes"

const initialState = {
    client: {
        name: null,
        id: null
    },
    pallet: {
        id: null
    },
    good: {
        grower: {
            name: null,
            id: null
        },
        product: {
            name: null,
            id: null
        },
        variety: {
            id: null
        },
        size: {
            id: null
        },
        package: {
            id: null,
            qty: null
        },
        reference: {
            value: null
        },
        weights: {
            value: null
        }
    },
    previousState: null
}

const controlPalletsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_CLIENT:
            return {
                ...state,
                client: {
                    name: action.name,
                    id: action.id
                }
            }
        case CONTROL_PALLETS_SELECT_PALLET:
            return {
                ...state,
                pallet: {
                    id: action.id
                }
            } 
        case CONTROL_PALLETS_EDIT_GOOD:
            return {
                ...state,
                good: {
                    ...state.good,
                    ...action.payload
                }
            }
        case CONTROL_PALLET_NEW_CLEAR_ITEM:
            return {
                ...state,
                previousState: state,
                good: {
                    ...initialState.good
                }
            }
        case CONTROL_PALLET_LOAD_NEW_CLEAR_ITEM:
            return {
                ...state,
                good: {
                    ...initialState.good
                }
            }
        case CONTROL_PALLET_LOAD_PREVIOUS_STATE_FOR_GOOD:
            return {
                ...state,
                good: {
                    ...state.previousState?.good
                }
            }
        case CONTROL_PALLETS_CLEAR_STATE:
            return initialState       
        default: return state
    }
}

export default controlPalletsReducer