import React, { useState } from "react";
import styled from "styled-components";
import { PlusIcon } from "../../../../../generic/icons/Icons";
import SelectFromTheList from "../../../../../generic/customSearchComponents/SelectFromTheList";
import Delivery from '../../../../../generic/assets/delivery.png'
import { ITransportAreaItem } from "../../../../../../models/Defaults/transportArea";

type Props = {
    list: ITransportAreaItem[];
    handleSelect: (target: string) => void
};

const NewKavSelector = ({
    list, handleSelect
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleControl = (state: boolean) => {
        setIsOpen(state)
    }
  return (
    <FilterIndicator
      onMouseEnter={() => {
        handleControl(true);
      }}
    >
      <ButtonWrapper>
        <PlusIcon />
      </ButtonWrapper>
      <FilterBodyWrapper
        isOpen={isOpen}
        onMouseLeave={() => {
            handleControl(false);
        }}
      >
        <img src={Delivery} alt="logo" />
        <FilterBodyItemContainer>
          <SelectFromTheList
            lable="קו הפצה - הכל"
            list={list}
            size="full"
            itemKey="AreaID"
            fieldValue="AreaID"
            dispalyFieldName="AreaDesc"
            selectFunction={handleSelect}
          />
        </FilterBodyItemContainer>
      </FilterBodyWrapper>
    </FilterIndicator>
  );
};

export default NewKavSelector;

export const FilterIndicator = styled.div<{common?: boolean}>`
    position: relative;
    height: 3.5em;
    min-width: 3.5em;
    background: #eff3f4 ;
    border-radius: 50%;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: #297f76;
    display: flex;
    justify-content: center;
    align-items: center;
    
    :hover {
        background: ${props => props.common ? '#eff3f4' : '#297f76'} ;
    }
    
`
export const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #eff3f4;
    border-radius: 30px;
    align-items: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    :hover {
        transform: translateX(3px) rotate(45deg) translateY(-3px);
    }
`

const FilterBodyWrapper = styled.div<{isOpen?: boolean}>`
    position: absolute;
    bottom: -290px;
    right: 0;
    width: 300px;
    height: 290px;
    background: #eff3f4;
    z-index: 2;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1em;
    padding: 1em;
    border-radius: 30px;
    -webkit-filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    img {
        min-width: 300px;
    }

`
const FilterBodyItemContainer = styled.div`
    width: 100%;
    padding: 0.2em;
    background: #fff;
    border-radius: 30px;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
    gap: 0.5em;
    ::-webkit-scrollbar {
        width: 0;
    }
`
