import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'




import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { IDateRanger } from '../utils/forDate/stateModel';

type Props = {
  setData?: React.Dispatch<React.SetStateAction<Record<string, IDateRanger>>>,
  onChange: any,
  range: IDateRanger[]
}

const ReactDatePicker = ({setData, range, onChange}: Props) => {
  return (
    <div className='calendarWrap'> 
      <DateRange   
        ranges={range}
        onChange={onChange}
        scroll={{ enabled: true }}
        minDate={addDays(new Date(), -700)}
        maxDate={addDays(new Date(), 30)}
      />
    </div>
  )
}

export default ReactDatePicker