import React from 'react'
import styled from 'styled-components'
import { Decorator } from '../../../../../../generic/Layouts/OrderlikeLayout'
import Title from '../../../../../../generic/Title/Title'

type Size = "md" | "lg" | "sm" | "full"
type Props = {
    children: React.ReactNode | React.ReactNode[],
    title?: string;
    titleColor?: string;
    size?: Size;
}

function MarketMainLayout({
    children, title, titleColor, size = "full"
}: Props) {
  return (
    <MainWrapper
    >
        <Wrapper size={size}>
            <Decorator/>
            <Title label={title} color={titleColor} />
            {children}
        </Wrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    background: #e9f3f2;
    @media screen and (max-width: 1000px) {
        padding: 0;
    }
`
const Wrapper = styled.div<{size?: Size}>`
    position: relative;
    max-width: ${props => props.size === 'md' ? '1000px' : 
        props.size === 'lg' ? '1200px' :
        props.size === 'sm' ? '800px' :
        '100%'
    };
    height: 100%;
    padding: 0 1em 1em 1em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    border-radius: 30px;
    margin: 0 auto;
    background: #fff;
    overflow: hidden;
    -webkit-filter: drop-shadow(10px 1px 2px rgba(157,157,157, 0.2));
    filter: drop-shadow(10px 1px 2px rgba(157,157,157, 0.2));
    @media screen and (max-width: 1000px) {
        border-radius: 0;
    }
    
`
export default MarketMainLayout