import './performans.scss'
import ButtonPerformance from './buttonPerformance'
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function Performance(){
    
    AOS.init();
    return (
        <div className="main-performans" id="performance">
            <div className="main-performans__wrapper">
                <div className="main-performans__wrapper-conteiner">
                    <div className="main-performans__wrapper-conteiner__inner" data-aos="fade-right" data-aos-once="true">
                        <div className="main-performans__wrapper-conteiner__inner-front front-first-img">
                            <img src="/images/lime.png" alt="lime" />
                            <div className="main-performans__wrapper-conteiner__inner-front__tag ">מודול הנה"ח</div>
                        </div>
                        <div className="main-performans__wrapper-conteiner__inner-back">
                            <div className="main-performans__wrapper-conteiner__inner-back__title">
                                
                            </div>
                            <div className="main-performans__wrapper-conteiner__inner-back__sub">
                                מודול הנה"ח
                            </div>
                            <div className="main-performans__wrapper-conteiner__inner-back__inner">
                                <ul className="main-performans__wrapper-conteiner__inner-back__inner-list">
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        ניהול כרטסת
                                    </li>
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        חשבונית יצרן
                                    </li>
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        חשבונת לקוח
                                    </li>
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        ניהול הוצאות
                                    </li>
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        דו"ח אובליגו 
                                    </li>
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        הדפסת שיקים
                                    </li>
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        "סגירת מסמכים"
                                    </li>
                                    <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                        ממשק למערכת הנה"ח
                                    </li>
                                </ul>
                            </div>
                            <div className="main-performans__wrapper-conteiner__inner-back__btn">
                                {/* <button className="btn-performance">try now</button> */}
                                <ButtonPerformance />
                            </div>
                        </div>
                    </div>
                    <div className="main-performans__wrapper-conteiner__inner" data-aos="fade-right" data-aos-once="true">
                        <div className="main-performans__wrapper-conteiner__inner-front front-second-img">
                            <img src="/images/mandarin.png" alt="mandarin" />
                            <div className="main-performans__wrapper-conteiner__inner-front__tag ">בית אריזה</div>
                        </div>
                        <div className="main-performans__wrapper-conteiner__inner-back">
                        <div className="main-performans__wrapper-conteiner__inner-back__title">
                                
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__sub">
                                    בית אריזה
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__inner">
                                    <ul className="main-performans__wrapper-conteiner__inner-back__inner-list">
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            תעודות כניסה
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            תוצאות מיון
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            בניית משטחים
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            תעודות משלוח
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            מלאי אריות/משטחים
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            היטלים למועצה
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            מגוון שאילתות
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            שליחת מייל מובנה
                                        </li>
                                    </ul>
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__btn">
                                    {/* <button className="btn-performance">try now</button> */}
                                    <ButtonPerformance />
                                </div>
                        </div>
                    </div>
                    <div className="main-performans__wrapper-conteiner__inner" data-aos="fade-left" data-aos-once="true">
                        <div className="main-performans__wrapper-conteiner__inner-front front-third-img">
                            <img src="/images/lemon.png" alt="lemon"/>
                            <div className="main-performans__wrapper-conteiner__inner-front__tag "> מחסן לוגיסטי</div>
                        </div>
                        <div className="main-performans__wrapper-conteiner__inner-back">
                        <div className="main-performans__wrapper-conteiner__inner-back__title">
                                
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__sub">
                                    מחסן לוגיסטי
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__inner">
                                    <ul className="main-performans__wrapper-conteiner__inner-back__inner-list">
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            הזמנות לקוח
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            הזמנות רכש
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                           ליקוט
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            קווי הפצה/העמסה
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            ניהול מלאי
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            מחירונים
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            עבודה עם מסופון
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            רווחיות
                                        </li>
                                    </ul>
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__btn">
                                    {/* <button className="btn-performance">try now</button> */}
                                    <ButtonPerformance />
                                </div>
                        </div>
                    </div>
                    <div className="main-performans__wrapper-conteiner__inner" data-aos="fade-left" data-aos-once="true">
                        <div className="main-performans__wrapper-conteiner__inner-front front-fourth-img">
                            <img src="/images/grape1.png" alt="grapefruit" />
                          <div className="main-performans__wrapper-conteiner__inner-front__tag ">מודול יצוא</div>
                        </div>
                        <div className="main-performans__wrapper-conteiner__inner-back">
                        <div className="main-performans__wrapper-conteiner__inner-back__title">
                                
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__sub">
                                    מודול יצוא
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__inner">
                                    <ul className="main-performans__wrapper-conteiner__inner-back__inner-list">
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            תעודות משלוח יצוא
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            Packing List
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            Proforma Invoice
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            Invoice
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            ניתוח עיסקה
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            ממשק להגנת הצומח
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            ממשק לעמיל מכס
                                        </li>
                                        <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            תיק משלוח
                                        </li>
                                        {/* <li className="main-performans__wrapper-conteiner__inner-back__inner-list__item">
                                            Solution
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="main-performans__wrapper-conteiner__inner-back__btn">
                                    {/* <button className="btn-performance">try now</button> */}
                                    <ButtonPerformance />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}