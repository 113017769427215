import React from 'react'
import { IClientBills } from '../../../../../../modules/API/Bills/models/clientBills'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { CalendatDay, EditIcon, InvoiceMoneyIcon, PrintIcon, ShekelIcon, UserIcon, VerticalDots } from '../../../../../../generic/icons/Icons'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../../Routs/config'
import { IRemarkData } from '../../../arizot/View/ArizotViewMain'
import { convertToDate } from '../../../../../../modules/convertDateString'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../../../knisot/Components/SwipeContent'

interface IProps extends IClientBills {
    // item: IClientBills
    isDisableCOntroll?: boolean;
    selectRemarkData?: (data: IRemarkData) => void;
    selectItemLink?: string;
    print?: (id: number | string) => void;
    
    
}

const ClientBillTitleItem = ({
    Name, Invoice, Remarks, Total_Invoice,selectRemarkData, 
    isDisableCOntroll, DateFrom, DateTo, DateReg, selectItemLink,
    print
}: IProps) => {
    const history = useHistory()
    const regDate = convertToDate(DateReg)
    const dateFrom = convertToDate(DateFrom)
    const dateTo = convertToDate(DateTo)
    const handleOpenRemark = () => {
        if (!selectRemarkData) return
        selectRemarkData({title: `${Invoice}`, body: Remarks, id: Invoice})
    }
    const handleOpenDetails = () => {
        history.push(`${selectItemLink}${Invoice}`)
    }
    const handlePrint = () => {
        if (!print) return
        print(Invoice)
    }
  return (
    <TitleLayout
        isDraggable
    >
        <SwipeableListItem
            swipeRight={{
                content: <SwipeContent text={'עיון'}  />,
                action: handleOpenDetails
            }}
            threshold={0.3}
        >
            <MainWrapper>
                <BodyTitle>

            
            {
                !isDisableCOntroll &&
                <SideButtonWrapper>
                    <ButtonContainer onClick={handlePrint}>
                        <PrintIcon />
                    </ButtonContainer>
                    {
                        Remarks.trim() &&
                            <ButtonContainer 
                                isContentRemark={!!Remarks?.trim()} 
                                onClick={handleOpenRemark}
                            >
                                <EditIcon />
                            </ButtonContainer>
                    }
                </SideButtonWrapper>
            }
            <TitleRow>

                <TitleRowItem name={`${regDate}`} icon={<CalendatDay />}/>
                <TitleRowItem name={`${Invoice}`} icon={<InvoiceMoneyIcon />}/>
            </TitleRow>
            <TitleRow>
                <TitleRowItem name={Name?.trim()} icon={<UserIcon />}/>
            </TitleRow>
            <TitleRow>
                <TitleRowItem name={`${Math.round(Total_Invoice).toLocaleString()}`} icon={<ShekelIcon />}/>
            </TitleRow>
            <TitleRow>
                <TitleRowItem name={`${dateTo} - ${dateFrom}`} icon={<CalendatDay />}/>
            </TitleRow>
            </BodyTitle>

        </MainWrapper>
        </SwipeableListItem>
    </TitleLayout>
  )
}

const SideButtonWrapper = styled.div`
    position: absolute;
    left: 1em;
    top: 0;
    height: 100%;
    width: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    color: #297F76;
    /* background: red; */
`
const ButtonContainer = styled.div<{isContentRemark?: boolean}>`
    width: 50%;
    cursor: pointer;
    transition: 0.3s;
    color: ${props => props.isContentRemark ? 'tomato' : 'none'};
    &:hover {
        color: tomato;
    }
`
const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    /* background: red; */
`
const BodyTitle = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
export default ClientBillTitleItem