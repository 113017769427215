import { useState } from "react";
import styled from "styled-components";
import ArizotItemLayout from "../../../../../../generic/Layouts/Wrappers/ArizotItemLayout";

const BodyWrapper = styled.div`
    background: #e9f0f0;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 1em;
    padding: 0.6em 1em;
    border-radius: 30px;
    /* border: 2px solid #ced3ff; */
    color: #573d16;
    -webkit-filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    @media screen and (max-width: 600px){
        width: 80%;
        padding: 0.5em 1em;
    }
`
const InputWrapper = styled.div`
    /* background: green; */
    width: 100%;
    height: 2.5em;
    position: relative;
    margin-bottom: 0.5em;
    border: 2px solid #eff3f4;
    border-radius: 30px;
    p{  
        width: 100%;
        height: 100%;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.5em;
        background: #7ab0ab;
        filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
        /* border-radius: 5px; */
        color: #fff;
        font-weight: 600;
        /* -webkit-box-shadow: 6px 6px 2px -2px #80E081; 
        box-shadow: 6px 6px 2px -2px #80E081; */
    }
`

const Input = styled.input`
    appearance: none;
    border: none;
    /* border: 2px solid #d3ffce; */
    border-radius: 30px;
    height: 100%;
    width: 100%;
    outline: none;
    text-align: right;
    padding: 0 1em;
    font-size: 1.2em;
    

`
const IconDiv = styled.div`
    position: absolute;
    width: 3em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #83848c;
    font-size: 0.9em;
    /* background: yellow; */
`


export default function AddArizotItem({
    Pack_Name, Qty, Remark, PackID, setRemarkData, handleRemarkModal,
    Pack_Pallet, id, patchState, isDisabled=false, isActiveButton=true
}){
    const isArizot = Pack_Pallet?.trim() === 'א'
    const remarkData = {
        title: Pack_Name?.trim(),
        body: Remark?.trim(),
        id: id
    }
    
    const handleInput = (e) => {
        let toNum = +e.target.value
        patchState(toNum, id)
    }
    const handleButton = () => {
        setRemarkData(remarkData)
        handleRemarkModal()
    }
    return(
        <ArizotItemLayout
            Description={Pack_Name?.trim()}
            isArizot={isArizot}
            defaultQty={Qty || 0}
            onChange={handleInput}
            disableInput={isDisabled}
            handleButton={handleButton}
            isActiveButton={isActiveButton}
        />   
    )
}