import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode
}

const BodyScrollContainer = ({children}: Props) => {
  return (
    <ContentWrapper>
        {children}
    </ContentWrapper>
  )
}

export default BodyScrollContainer

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    /* gap: 0.5em; */
    border-radius: 30px;
    /* padding: 0 0 1.5em 0; */
    overflow: hidden;
    background: #e9f3f2;
    z-index: 0;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
`