
export type TStatus = 1 | 2 | 3 | 4 | 5;
export const statusOrderRehesh = {
    1: 'חדש',
    2: 'נדחה',
    3: "מאושר",
    4: "משלוח",
    5: "ליקוט",
}
export const statusOrderReheshArr = Object.entries(statusOrderRehesh).map(([key, value]) => ({ id: key, value }));

export const statusOrderReheshConvert = (id: TStatus) => {
    const accepted = "#80E081";
    const awaited = "#FFFACE";
    const rejected = "tomato";
    
    const colorHash = {
        1: awaited,
        2: rejected,
        3: accepted,
        4: accepted,
        5: accepted,
    }

    return {
        color: colorHash[id],
        text: statusOrderRehesh[id]
    }
}