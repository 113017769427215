import React from 'react'
import styled from 'styled-components'
import { TCarDict, TCarList } from '../../../../../../models/Defaults/carList'
import { ITransportAreaDict } from '../../../../../../models/Defaults/transportArea'
import { TOrderList } from '../../../../../../models/RequestsModel/orderModel'
import { TPalletForTransferList } from '../../../../../../models/RequestsModel/palletsForTransfer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import { currentCarPallets } from '../Dashboard/DashboardTruck'
import RowElement from './RowElement'


type Props = {
    carList: TCarList,
    carsDict: TCarDict,
    palletList: {azmanot: TOrderList, current: IKavItem[], defaultArray: TPalletForTransferList},
    transportAreaDict: ITransportAreaDict,
    ignorDirection: number
}

interface IKavItem {
    CustomerID: number | undefined,
    NetWeight: number,
    OrderPallets: IOrderItem[],
    PalletTransferZone: number
}

interface IOrderItem {
    Custom: boolean,
    CustomerID: number,
    Customer_Name: string,
    OrderID: number,
    PalletsQty: number,
    Products: number,
    Status: number,
    Width: number | null | undefined,
    pallets: TPalletForTransferList
}

export interface ICollectDataOutput {
    CarNum: number,
    Detailes: ICollectDataDetailesItem[],
    DriverName: string
}

interface ICollectDataDetailesItem {
    AreaDesc: string,
    DriverName: string,
    MaxFillCarByPallets: number
    PalletsQty: number
    TotalWidth: number
    UniqPoints: number
}

export interface ITitleItemP {
    isLong?: boolean | undefined
    length?: number,
    border?: boolean
}
const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    padding: 0.5em;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 0.5em;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0;
    }
`
export const TitleWrapper = styled.div<ITitleItemP>`
    width: 100%;
    min-height: ${props => props.length ? `${props.length * 1.9}em`: '3em'};
    border-radius: 30px;
    background: #eff3f4;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 0.5em;
`
export const TitleItem = styled.div<ITitleItemP>`
    min-width: ${props => props.isLong ? '18%' : '13%'};
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    color: #297f76;
    font-weight: 600;
    border-left: ${props => props.border ? '2px solid #297f76' : 'none'} ;


`

const StatisticTable = ({carList, palletList, transportAreaDict, carsDict, ignorDirection}: Props) => {
    const collectData = (): ICollectDataOutput[] => {
        const reduced = palletList.current.reduce((acc: Record<number, ICollectDataOutput>, cur) => {
            if (cur.PalletTransferZone === 0 || cur.PalletTransferZone === ignorDirection) return acc
            let transferAreaInfo = transportAreaDict[cur.PalletTransferZone]
            if (!transferAreaInfo) return acc
            let carID = transferAreaInfo.CarId
            if(!carID) return acc
            let objectConstructor = {
                AreaDesc: transferAreaInfo.AreaDesc?.trim(),
                DriverName: carsDict[carID]?.DriverName?.trim(),
                TotalWidth: cur.NetWeight,
                PalletsQty: currentCarPallets(cur.OrderPallets),
                MaxFillCarByPallets: carsDict[carID].Pallets,
                UniqPoints: clientsQty(cur.OrderPallets)
            }
            acc[carID] = {
                DriverName: carsDict[carID].DriverName?.trim(),
                CarNum:carsDict[carID].CarNum,
                Detailes: acc[carID] ?  [...acc[carID].Detailes, objectConstructor] : [objectConstructor]
            }
            // console.log(acc)
            return acc
        }, {})

        return Object.values(reduced)
    }

    const dataSet = collectData()
//     console.log(dataSet)
//    console.log(palletList.current)
//    console.log(carList)
//    console.log(carsDict)
//    console.log(transportAreaDict)
  return (
    <MainContainer>
        <TitleWrapper>
            <TitleItem border isLong>נהג</TitleItem>
            <TitleItem border>מספר משאית</TitleItem>
            <TitleItem border isLong>קו</TitleItem>
            <TitleItem border>מספר נקודות</TitleItem>
            <TitleItem border>משטחים</TitleItem>
            <TitleItem>ניצול משאית</TitleItem>
        </TitleWrapper>
        <TitlesList
            list={dataSet}
            uniqueKey={'CarNum'}
            Element={RowElement}
            noPadding
        />
    </MainContainer>
  )
}

export default StatisticTable

const clientsQty = (orders: IOrderItem[]) => {
    let reduced = orders.reduce((acc: number[], cur) => {
        acc = acc.concat(cur.CustomerID)
        return acc
    }, [])
    const newSet = new Set(reduced)
    return newSet.size
}