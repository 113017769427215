import React, {useContext, useMemo, useState} from 'react'
import styled from 'styled-components'
import { ITeudotItem, TTeudotList } from '../../../../../../../../models/RequestsModel/teudot'
import BodyScrollContainer from '../../../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../../../generic/titlesRowItem/TitlesList'
import TeudaItem from '../../../../kosherTeudot/TeudaItem'
import useDataFetcher from '../../../../knisot/hooks/useFetchData'
import { Certificates } from '../../../../../../../modules/API/Certificates/certificates'
import { format } from 'date-fns'
import LoaderSpinner from '../../../../../../../modules/loadSpinner/LoaderSpinner'
import { useDispatch, useSelector } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../../../../store/actions/actionsTypes'
import { GET_PDF_TEUDOT } from '../../../../../../../modules/API/settings'
import { RootState } from '../../../../../../../../store/reducers/rootReducer'
import TeudaItemMarket from './TeudaItemMarket'
import ControlSearchPannelLayout from '../../../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../../../generic/inputs/inputSearch'
import { ISellerItem } from '../../../../../../../modules/API/Defaults/models/seller'
import SearchSeller from '../SearchSeller'
import { ITeudotSmsData } from '../../../../../../../modules/API/Certificates/models/smsData'
import { ContextModal } from '../../../../../../../modules/modal/modalContext'
import selectBrodMigdal from '../../../../../../../generic/utils/selectBrodMigdal'

type Props = {
  fetchItem: (deliveryNum: number, deliverySub: number) => void,
  date: string
  sellerList: ISellerItem[],
  selectedSeller: number,
  setSelectedSeller: React.Dispatch<React.SetStateAction<number>>
}

const TeudotModalMain = ({
  fetchItem, 
  date,
  sellerList,
  selectedSeller,
  setSelectedSeller

}: Props) => {
  const [search, setSearch] = useState<string>('')
  const defaults = useSelector((state: RootState) => state.defaults?.defaults)
  const token = useSelector((state: RootState) => state.mainReducer?.token)
  const isAllowToSendSms = selectBrodMigdal(defaults, 273, "Text_Value") !== ""
  const dispatch = useDispatch()
  const [modal, setModal] = useContext(ContextModal)
  // const date = format(new Date(), 'yyyyMMdd')
  const {
    data: teudotList,
    isLoading: isLoadingTeudotList
  } = useDataFetcher<TTeudotList>(
    Certificates.getCertsByDate, `DateFrom=${date}&DateTo=${date}`
  )
  
  const sendSms = async (data: ITeudotSmsData) => {
    if(!isAllowToSendSms) return 
    dispatch({ type: LOAD });
    try { 
      await Certificates.sendCertBySms(data);
      setModal({
        open: true,
        value: 'הודעה נשלחה בהצלחה',
        mood: true
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
    } catch (err: any) {
      console.log(err)
      setModal({
        open: true,
        value: err.response?.data?.error === 'Client has no phone' ? 'לא נמצא מספר טלפון ללקוח' : err.response?.data?.error,
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
    } finally {
      dispatch({ type: LOAD_END });
    }
  }
  const printPdf = async (CODE: number, SUBCODE: number) => {
    dispatch({ type: LOAD });
    try {
      let response = await fetch(
        `${GET_PDF_TEUDOT}deliveryNum=${CODE}&deliverySub=${SUBCODE}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.blob();
      // Receive PDF Steam as as Blob
      const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
      const fileURL = URL.createObjectURL(file); //Build a URL from the file
      console.log(data)
      window.open(fileURL); //Open the URL on new Window

      // check if popup is not disabled then open the PDF in new tab
      if (window.open(fileURL) === null) {
        setModal({
          open: true,
          value: "בטל חסימת חלונות קופצים במכשירך",
          mood: false
        })
        setTimeout(() => {
          setModal({
            open: false,
            value: '2'
          })
        }, 3000)

        // click to download 
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', `${CODE}_${SUBCODE}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(fileURL)
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: LOAD_END });
    }
  }

  const render = useMemo(() => {
    if(!teudotList) return []
    return teudotList.filter((item) => 
      (
        item.Delivery_Num.toString().includes(search.toLocaleLowerCase()) ||
        item.Customer_Name.trim().toLowerCase().includes(search.toLowerCase())
      ) &&
      (selectedSeller !== 0 ? item.Reseller_Code === selectedSeller : true)
    )
  }, [search, teudotList, selectedSeller])

  const PList = {
    print: printPdf,
    sign: () => {},
    isMashgiah: false,
    fetchItem: fetchItem,
    sendSms: sendSms,
    isAllowToSendSms: isAllowToSendSms
  }

  return (
    <MainWrapper>
      <ControlSearchPannelLayout>
        <SearchSeller 
          list={sellerList}
          defaultID={selectedSeller}
          setSellerID={setSelectedSeller}
          required={false}
        />
        <SearchInput 
          input={search}
          setInput={setSearch}
        />
      </ControlSearchPannelLayout>
      <BodyScrollContainer >
        {
          !isLoadingTeudotList ?
            <TitlesList
                list={render}
                uniqueKey={"Delivery_Num"}
                Element={TeudaItemMarket}
                queryRender={() => true}
                additionalData={PList}
                noPadding={false}
                backgroundColor={'#eff3f4'}
              /> :
              <SpinerWrapper>
                <LoaderSpinner />
              </SpinerWrapper>
        }
      </BodyScrollContainer>
    </MainWrapper>

  )
}
const SpinerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  overflow: hidden;
`
export default React.memo(TeudotModalMain)