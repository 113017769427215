import React, { useState } from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode;
    func: () => void;
    withAction?: boolean,
    required?: boolean
}

interface IIcon {
    isActive: boolean;
}
const MainWrapper = styled.div<IIcon>`
    position: relative;
    min-width: 3.5em;
    height: 3.5em;
    background: #eff3f4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.isActive ? 'tomato' : '#297f76'};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #d7dcdd;
    }

`
const ReqIcon = styled.div<{isActive?: boolean}>`
    position: absolute;
    top: -.3em;
    right: -.3em;
    width: 1em;
    height: 1em;
    background: tomato;
    border-radius: 50%;
    display: ${props => props.isActive ? 'block' : 'none'};

`
function CircleActiveFilterBtn({children, func, withAction, required}: Props) {
    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        func();
        if (withAction) {
            setIsActive(!isActive);
        }
    }
  return (
    <MainWrapper isActive={isActive} onClick={handleClick}>
        <ReqIcon isActive={required} />
        {children}
    </MainWrapper>
  )
}

export default CircleActiveFilterBtn