import React, { useEffect, useRef } from 'react';

interface DebounceProps {
  callback: (e: string | number, fieldName?: string) => void;
  delay: number;
}

const useDebounce = ({ callback, delay }: DebounceProps) => {
  const timerRef = useRef<NodeJS.Timeout  | undefined>();

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const debounceCallback = (e: string | number, fieldName?: string) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      callback(e, fieldName);
    }, delay);
  };

  return debounceCallback;
};

export default useDebounce;