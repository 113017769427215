import React from 'react'
import styled from 'styled-components'

type Props = {
  handleClick: () => void
}

const AddLine = (props: Props) => {
  return (
    <MainWrapper>
      <Button onClick={props.handleClick}>הוסף שורה</Button>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5em;
  
`
const Button = styled.button`
  border: none;
  border-radius: 25px;
  padding: 0.5em 1em;
  font-size: 1.1em;
  background-color: #80E081;
  color: #fff;
  cursor: pointer;  
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }
`
export default AddLine