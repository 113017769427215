import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import SearchBlockWithTitle from '../../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import CircleNumericInput from '../../../../../../../generic/inputs/CircleNumericInput'
import SearchProductWithVariety from '../SearchProductWithVariety'
import { IProductsWithVarietyItem } from '../../../../../../../modules/API/Defaults/models/compliteProducts'
import { IPalletTypeItem } from '../../../../../../../modules/API/Defaults/models/pallets'
import SearchPalletType from '../SearchPalletType'
import { IPackagesItem } from '../../../../../../../modules/API/Defaults/models/packages'
import SearchPackage from '../SearchPackage'
import { IEntriesItem } from '../../../../../../../modules/API/Miun/models/sortingList'
import { IDetailsSelected } from '../../CreateKnisa'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { IEntriesKnisotItem } from '../../../../../../../modules/API/Knisot/models/entrie'
import { IEntriePutData } from '../../../../../../../modules/API/Market/models/putRequestInterface'
import selectOnFocus from '../../../../../../../modules/autoselect'
import { useKnisaConfig } from '../../config'
import { ISizeItem } from '../../../../../../../modules/API/Products/models/size'
import SearchSize from '../SearchSize'
import CheckBoxWithTitle from '../../../../../../../generic/particles/CheckBox'
import SearchInput from '../../../../../../../generic/inputs/inputSearch'

type Props = {
  productsWithVariety: IProductsWithVarietyItem[]
  palletList: IPalletTypeItem[]
  packageList: IPackagesItem[]
  cashedDetails: IEntriesKnisotItem | null
  sizeList: ISizeItem[]
  handleCreateDetails: (data: IDetailsSelected, addictional?: { multyplier: number, isCopyWithWeigh: boolean, isEdit: boolean}) => void
  handleChangeDetails: (data: IEntriePutData) => void
}

const ModalKnisaAddProduct = ({
  productsWithVariety, palletList, packageList, cashedDetails,
  handleCreateDetails, handleChangeDetails, sizeList
}: Props) => {
  const config = useKnisaConfig()
  const defaultPackage = cashedDetails?.TypePack || config.create.details.package[0].value as number
  const [productQty, setProductQty] = useState<number>(cashedDetails?.AmountPack || 0)
  const [productID, setProductID] = useState<number>(cashedDetails?.ProductID || 0)
  const [varietyID, setVarietyID] = useState<number>(cashedDetails?.VarietyID || 0)
  const [price, setPrice] = useState<number>(cashedDetails?.PriceWeight || 0)
  const [weight, setWeight] = useState<number>(cashedDetails?.Gross || 0)
  const [remark, setRemark] = useState<string>(cashedDetails?.Remark || '')
  const [palletID, setPalletID] = useState<number>(cashedDetails?.TypePallet || 0)
  const [palletQty, setPalletQty] = useState<number>(cashedDetails?.AmountPallets || 1)
  const [packageID, setPackageID] = useState<number>(defaultPackage || 0)
  const [sizeID, setSizeID] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [multiplier, setMultiplier] = useState<number>(0)
  const [isCopyWeight, setIsCopyWeight] = useState<boolean>(config.create.details.multuplier[0].value as boolean)
  const [externalPalletNum, setExternalPalletNum] = useState<number>(cashedDetails?.ExternalPallet || 0)
  
  const isActiveSave = useMemo(() => {
    return productQty > 0 && productID !== 0 &&
    
      (config.create.details.weight[0].value ? weight > 0 : true) &&
      palletID !== 0 && packageID !== 0

  }, [
    productQty, config,
    productID,
    weight,
    palletID, packageID
  ])
  console.log(isActiveSave)
  const handleCreateDetailsFunc = (isEdit: boolean) => {
    handleCreateDetails({
      productQty,
      productID,
      varietyID,
      price,
      weight,
      remark,
      palletID,
      packageID,
      externalPalletNum,
      palletQty,
    }, multiplier ? { multyplier: multiplier, isCopyWithWeigh: isCopyWeight, isEdit: isEdit } : undefined)
  }
  const handleChangesDetailsFunc = (isEdit: boolean) => {
    if (!cashedDetails) return
    handleChangeDetails({
      Details: [{
        DetailNum: cashedDetails.DetailNum,
        ProductID: productID,
        VarietyID: varietyID,
        PriceWeight: price,
        Gross: weight,
        Remark: remark,
        TypePallet: palletID,
        TypePack: packageID,
        AmountPack: productQty,
        ExternalPallet: +externalPalletNum,
        AmountPallets: palletQty,
        
        // TODO: add location
      }]
    })
    if (multiplier) {
      handleCreateDetailsFunc(isEdit)
    }
  }
  return (
    <MainWrapper>
      <BodyWrapper>
        <SearchBlockWithTitle
          title={['מספר כניסה חיצוני', '']}
        >
          <SearchInput 
            input={externalPalletNum + ''}
            value={''}
            type={'number'}
            iconHide
            setInput={setExternalPalletNum}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={['משטח', 'כמות']}
        >
          <CircleNumericInput 
            defaultValue={palletQty}
            changeFunc={(e: number) => setPalletQty(e)}
          />
          <SearchPalletType
            list={palletList}
            defaultID={palletID}
            setPalletID={setPalletID}
            required
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={['פריט', 'גודל']}
        >
          <SearchSize
            list={sizeList}
            defaultID={sizeID}
            setSizeID={setSizeID}
          />
          <SearchProductWithVariety
            defaultProductID={productID}
            setProductID={setProductID}
            defaultVarietyID={varietyID}
            setVarietyID={setVarietyID}
            list={productsWithVariety}
            required
          />
        </SearchBlockWithTitle>

        <SearchBlockWithTitle
          title={['אריזה', ' כמות']}
        >
          <CircleNumericInput
            defaultValue={productQty}
            changeFunc={(e: number) => setProductQty(e)}
          />
          <SearchPackage
            list={packageList}
            defaultID={packageID}
            setPackageID={setPackageID}
            required

          />

        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={['משקל', ' מחיר']}
        >
          <Input
            defaultValue={price}
            placeholder='מחיר'
            type="number"
            onChange={(e) => setPrice(Number(e.target.value))}
            onClick={selectOnFocus}
          />
          <Input
            defaultValue={weight}
            placeholder='משקל'
            type="number"
            onChange={(e) => setWeight(Number(e.target.value))}
            onClick={selectOnFocus}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={'איתור'}
        >
          <SearchInput 
            setInput={setLocation}
            input={location}
            iconHide
            value={'איתור'}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={'הערה'}
        >
          <TextArea
            defaultValue={remark}
            placeholder='הערה'
            onChange={(e) => setRemark(e.target.value)}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={['העתק', 'ומשקל']}
        >
          <CheckBoxWithTitle
            title=''
            stateFunc={setIsCopyWeight}
            defaultState={isCopyWeight}
          />
          <Input
            defaultValue={multiplier}
            placeholder='multiplier'
            type="number"
            onChange={(e) => setMultiplier(Number(e.target.value))}
            onClick={selectOnFocus}
          />
        </SearchBlockWithTitle>
      </BodyWrapper>
      {
        isActiveSave ?
          cashedDetails ?
            <ControlButton
              lable={'שמור שינויים'}
              handleClick={() => handleChangesDetailsFunc(true)}
            /> :
            <ControlButton
              lable={'הוסף פריט'}
              handleClick={() => handleCreateDetailsFunc(false)}

            /> : ''
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  align-items: center;
`

const BodyWrapper = styled.div`
  width: 90%;
  max-height: 83%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;

  }

`
const Input = styled.input`
  width: 100%;
  height: 3.4em;
  border-radius: 30px;
  background: #EFF3F4;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1em;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
`

const TextArea = styled.textarea`
  appearance: none;
  border-radius: 30px;
  border: none;
  width: 100%;
  background: #EFF3F4;
  padding: 1em;
  font-size: 1em;
  font-family: 'Assistant', sans-serif;
  outline: none;
  direction: rtl;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default ModalKnisaAddProduct