import React, {useContext} from 'react'
import './logoutBtn.scss'
import {useSelector} from 'react-redux'
import { useDispatch } from 'react-redux';
import { USER_LOADED, LOAD, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CLEAR_ERRORS } from '../../../store/actions/actionsTypes';
import {logOutFunc} from '../../modules/requests/logOut'
import { LogoutIcon } from '../../generic/icons/Icons'
import styled from 'styled-components'

export const IconWrapperControl = styled.div`
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #297f76;
    cursor: pointer;
    
    i {
        font-size: 1em;
    }
` 
function LogOutBtn() {
    
    const dispatch = useDispatch();
    const getToken = useSelector(token => token.mainReducer.token);
    
    return (
        <IconWrapperControl  onClick={()=>{logOutFunc(getToken, dispatch, LOGOUT)}}>
            <LogoutIcon />
        </IconWrapperControl>
    )
}
export default LogOutBtn;