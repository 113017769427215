import styled from "styled-components"

export const ControlButtonWrapper = styled.div`
    width: 150px;
    height: 2.5em;
    background: #80b3ae;
    border-radius: 30px;
    display: flex;
    /* @media screen and (max-width: 650px) {
        width: 30%;
    } */
`
export const ControlButton = styled.button`
    background: none;
    border: none;
    border-radius: 30px;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fdfdfd;
    transition: 0.3s ease;
    font-size: 1.02em;
    :hover {
        background: #4b938c;
        
    }
`