import React from 'react'
import { IOrderDetailsItem } from '../../../../modules/API/Orders/models/orders'
import { useSelector } from 'react-redux';
import selectBrodMigdal from '../../../../generic/utils/selectBrodMigdal';
import { RootState } from '../../../../../store/reducers/rootReducer';


const useConfigLefiMishkal = () => {
  const defaults = useSelector((e: RootState) => e.defaults?.defaults);
  const isWeightCalculation = selectBrodMigdal(defaults, 619, 'Logical_Value');

  const calcWeightPerUnit = (item: IOrderDetailsItem) => {
    return item.OrderWeight / item.OrderPacks
  }
  return [isWeightCalculation, calcWeightPerUnit] as const
}

export default useConfigLefiMishkal