import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import defaultsReducer from "./defaultsReducer";
import reducer from "./reducer";
import likutReducer from "./likutReducer";
import controlPalletsReducer from "./controlPalletsReducer";
import truckReducer from "./truckReducer";
import { IInitialState } from "../initialState";
import kosherTeudotReducer from "./kosherTeudotReducer";
import driverDerucer from "./driverReducer";
import miunReducer from "./miunReducer";
import entryReducer from "./entries";
import orderRehesh from "./orderRehesh";
import crossModulesQueries from "./crossModulesQueries";
import clientBillsReducer from "./clientBills";
import marketReucer from "./market";
import procedures from "./procedures";
import cefiReducer from "./cefi";
import packageReducer from "./package";
import workerOrders from "./workersOrders";
import kabalot from "./kabalot";
import receipts from "./receipt";
import printerReducer from "./printer";
import { RESET_STATE } from "../actions/actionsTypes";

// const a: IInitialState
const appReducer = combineReducers({
  mainReducer: reducer,
  loadingBar: loadingBarReducer,
  defaults: defaultsReducer,
  likut: likutReducer,
  controlPallets: controlPalletsReducer,
  controlTruck: truckReducer,
  kosherTeudotReducer: kosherTeudotReducer,
  driverDerucer: driverDerucer,
  miunReducer: miunReducer,
  entryReducer: entryReducer,
  orderRehesh: orderRehesh,
  crossModulesQueries: crossModulesQueries,
  clientBillsReducer: clientBillsReducer,
  marketReucer: marketReucer,
  procedures: procedures,
  cefiReducer: cefiReducer,
  packageReducer: packageReducer,
  workerOrders: workerOrders,
  kabalot: kabalot,
  receipts: receipts,
  printerReducer: printerReducer
  
});

const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STATE) {
    console.log("RESET_STATE");
    state = undefined;
  }
  return appReducer(state, action);
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;
