import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './reducers/rootReducer'
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;



const loadState = () => {
    try {
        // Load the data saved in localStorage, against the key 'app_state'
        const serialisedState = window.localStorage.getItem('app_state');
        // If no data is saved, return undefined
        if (!serialisedState) return undefined;

        // De-serialise the saved state, and return it.
        return JSON.parse(serialisedState);
    } catch (err) {
        // Return undefined if localStorage is not available, 
        return undefined;
    }
};
const oldState = loadState();
export const store = createStore(rootReducer, oldState, composeEnhancers(applyMiddleware(thunk, loadingBarMiddleware())));

//save the data to localStorage
const saveState = (state) => {
    try {
        if (state === undefined) {
            localStorage.removeItem('app_state');
        } else {
            const serialisedState = JSON.stringify(state);
            localStorage.setItem('app_state', serialisedState);
        }
    } catch(err) {

    }
};
// listener initialize onchange state
store.subscribe(() => {
    saveState(store.getState());
});


export default store;