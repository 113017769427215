import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { LOAD, LOAD_END, SELECT_SORTING_DATE } from '../../../../../store/actions/actionsTypes'
import DatePickerModule from '../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../generic/inputs/inputSearch'
import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import BodyScrollContainer from '../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import ControlSearchPannelLayout from '../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import TitlesList from '../../../../generic/titlesRowItem/TitlesList'
import { addIDForTheList } from '../../../../generic/utils/addUIDForTheList'
import { Miun } from '../../../../modules/API/Miun/miun'
import { ISortingListItem } from '../../../../modules/API/Miun/models/sortingList'
import { ContextModal } from '../../../../modules/modal/modalContext'
import { LINKS } from '../../../../Routs/config'
import CreateMiun from './Create/CreateMiun'
import MiunSortingItem from './MiunSortingItem'
import { format } from 'date-fns'
import ColumModal from '../../../../generic/Layouts/ColumModal'
import CreateSorting from './modal/CreateSorting'
import ControlButton from '../../../../generic/buttons/ControlButton'
import { RootState } from '../../../../../store/reducers/rootReducer'
import { IWorkerItem } from '../../../../modules/API/Defaults/models/workers'
import { ICreateSortingData } from '../../../../modules/API/Miun/models/fetchData'
import useDataFetcher from '../knisot/hooks/useFetchData'
import { Defaults } from '../../../../modules/API/Defaults/defaults'
import { IProductListNodeResponse } from '../../../../modules/API/Defaults/models/productListNode'
import { IGrowerNodeGetResponse } from '../../../../modules/API/Defaults/models/growers'
import { IWarhouseListItem } from '../../../../modules/API/Defaults/models/warhouseList'
import uuid from 'react-uuid'

type Props = {}

const initialDate = () => {
  let def = new Date()
  def.setDate(1)
  def.setMonth(def.getMonth() - 1)
  return format(def, 'yyyyMMdd')
}

const MiunMain = (props: Props) => {
  
  const defStartDate = initialDate();
    const defEndDate = format(new Date(), 'yyyyMMdd');
    const {path} = useRouteMatch();
    const dispatch = useDispatch();
    const [modal, setModal] = useContext(ContextModal)
    const [startDate, setStartDate] = useState<string>(defStartDate);
    const [endDate, setEndDate] = useState<string>(defEndDate);
    const [data, setData] = useState<ISortingListItem[]>();
    const [input, setInput] = useState<string>('');
    const [isOpenCreateSorting, setIsOpenCreateSorting] = useState<boolean>(false);
    
    const setDefaultDate = useSelector((state: RootState) => state.miunReducer.date)

    const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
      
      const handleDate = (e: object | object[]) => {
          console.log(e)
              dispatch({type: SELECT_SORTING_DATE, payload: e}
          )
        } 

    const {
      data: productList,
      isLoading: productListIsLoading
    } = useDataFetcher<IProductListNodeResponse>(
      Defaults.getProductListNode, "123",
    )
    const {
      data: dataGrowers,
      isLoading: isLoadingGrowers
    } = useDataFetcher<IGrowerNodeGetResponse>(
      Defaults.getGrowerList, "data"
    )
    const {
      data: warhouseList,
      isLoading: isLoadingWarhouseList
    } = useDataFetcher<IWarhouseListItem[]>(
      Defaults.getWarhouseList, "data"
    )
  
    const uppendData = (dataItem: ISortingListItem) => {
      if(!data || !dataItem) return
      let newData = [
        {
          ...dataItem,
          id: uuid()
        },
      ...data 
      ] 
      setData(newData)
    }
    const additionalDataSortingCreate = {
      productList: productList?.products || [],
      dataGrowers: dataGrowers?.growers || [],
      warhouseList: warhouseList || [],
      uppendData: uppendData
      
    }
    const handleModalCreateSorting = () => {
      setIsOpenCreateSorting(!isOpenCreateSorting)
    }
    const getSorting = async () => {
      dispatch({type: LOAD})
      try {
        const res = await Miun.getSortingByDates(startDate, endDate);
        setData(addIDForTheList(res))
      } catch (err) {
        setModal({
          open: true,
          value: "משהו השתבש",
          mood: false
      })
      setTimeout(() => {
          setModal({
              ...modal,
              open: false
          })
      }, 3000)
      } finally {
        dispatch({type: LOAD_END})
        
      }
    }
    
    const render = useMemo(() => {
      const filtered = data?.filter(item => 
        `${item.ProductDesc?.trim()} ${item.VarietyDesc?.trim()}`.toLowerCase()
          .includes(input.toLowerCase()) ||
            item.GrowerName?.trim().toLowerCase().includes(input.toLowerCase())
      )
      return filtered
    }, [input, data])

    const handleSelect = () => {}

    const ItemsProps = {
      handleSelect: handleSelect
    }

    useEffect(() => {
      if(!endDate) return
      getSorting()
    }, [endDate])

  return (
    <Switch>
      <Route path={path} exact>
        <OrderlikeLayout label='תעודות מיון'>
            {
              isOpenCreateSorting &&
              <ColumModal
                title='יצירת תעודת מיון'
                controlFunc={handleModalCreateSorting}
                childrenProps={additionalDataSortingCreate}
                Children={CreateSorting}
                />
            }
            <ControlSearchPannelLayout>
                <DatePickerModule 
                    multyDate
                    setSelectedDateStart={setStartDate}
                    setSelectedDateEnd={setEndDate}
                    disabled={false}
                    selected={setDefaultDate ? selectedDate : new Date()}
                    ignorBrod={false}
                    defEndDate={false}
                    large={false}
                    cashFunc={handleDate}
                  />
                <SearchInput 
                  input={input} 
                  setInput={setInput} 
                />
            </ControlSearchPannelLayout>
            <BodyScrollContainer>
              <TitlesList 
                list={render}
                noPadding={false}
                uniqueKey={'id'}
                Element={MiunSortingItem}
                additionalData={ItemsProps}
              />
            </BodyScrollContainer>
            <ControlButton
              lable={'מיון חדש'}
              handleClick={handleModalCreateSorting}
            />
        </OrderlikeLayout>
      </Route>
      <Route
        path={LINKS.miun.control.create}
        render={ 
          () => <CreateMiun saveFunc={getSorting} />
        }
      />
    </Switch>
  )
}

export default MiunMain