import {LOAD_ORDERS} from '../API/settings'


export const getAzmanot = async (token, startDate, endDate, setState, setDefaultState, message, setMessage) => {
    // dispatch(showLoading())
    const axios = require('axios');
    const CancelToken = axios.CancelToken.source();

    axios.get(LOAD_ORDERS + `fromDate=${startDate}&toDate=${endDate}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    },
    {
      cancelToken: CancelToken.token
    })
      .then(res => {
        const sorted = res.data.orders.sort((a, b) => {
          return a.Delivery_Date - b.Delivery_Date
        }).reverse()
        
        setState(sorted)
        setDefaultState(sorted)
        // dispatch(hideLoading())
      })
      .catch(err => {
        // console.log(err.response.data.message)
        if ((err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired' || err.response?.data.message === 'קונטקסט לא פעיל, כנס מחדש למערכת') && setMessage) {
            setMessage({
            open: true,
            value: 'התנתקת מהמערכת, אנא כנס מחדש',
            mood: false
          })
          setTimeout(() => {
            setMessage({
              ...message,
              open: false
            })
          }, 3000)
        }
      })
  }