const viewWindow = {
  // "packageOrder_ViewWindow_title": 'הזמנה/החזרה אריזה {{defID}}',
"packageOrder_ViewWindow_title": 'Заказ/Возврат - Упаковка {{defID}}',
}
export const packageOrderTranslationRU = {
  packageOrder: {
    "packageOrder_orders_title": 'Заказ/Возврат - Упаковка',
    "packageOrder_orders_search_placeholder": 'Поиск',
    "packageOrder_modal_teuda_title": 'Заказ упаковки: {{num}}',
    "packageOrder_modal_teuda_checkbox": 'Отправить по электронной почте',
    "packageOrder_modal_remark_title": 'Пластиковый контейнер Долав',
    "packageOrder_modal_print_title": 'Печать',
    "packageOrder_modal_print_order": 'Заказ',
    "packageOrder_modal_print_certificate": 'Сертификат',
    "packageOrder_NewOrder_lable_date": 'Дата',
    "packageOrder_NewOrder_lable_sender": 'Отправитель',
    "packageOrder_NewOrder_lable_receipient": 'Получатель',
    "packageOrder_NewOrder_lable_car": 'Машина',
    "packageOrder_NewOrder_lable_refference": 'Ссылка',
    "packageOrder_NewOrder_placeholder_sender": 'Выберите отправителя',
    "packageOrder_NewOrder_placeholder_receipient": 'Выберите получателя',
    "packageOrder_NewOrder_placeholder_car": 'Выберите машину',
    "packageOrder_NewOrder_placeholder_refference": 'Ссылка',
    "packageOrder_NewOrder_checkbox": 'Запросить доставку',
    ...viewWindow
  }
}