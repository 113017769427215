import React from 'react'
import styled from 'styled-components'
import { IWarhouseListItem } from '../../../../../../../modules/API/Defaults/models/warhouseList'
import SearchWarehouse from '../../../../knisot/Create/Components/SearchWarehouse'
import ImageComponentHead from '../../../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../../../generic/assets/warhouseWork.webp'
type Props = {
  list: IWarhouseListItem[],
  defaultID: number,
  setWarehouseID: React.Dispatch<React.SetStateAction<number>>
}

const FilterModal = ({
  list,
  defaultID,
  setWarehouseID,

}: Props) => {
  return (
    <MainWrapper>
      <ImageWrapper>

      <ImageComponentHead 
        img={Image}
        large
      />
      </ImageWrapper>
      {
        
      }
      <SearchWarehouse
        list={list}
        defaultID={defaultID}
        setWarehouseID={setWarehouseID}
    
      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 45%;
`
export default FilterModal