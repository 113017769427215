import React, {useState} from 'react'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon, ScalesIcon, ShekelIcon } from '../../../../../generic/icons/Icons'
import { IMarketPalletDetails } from '../../../../../modules/API/Market/models/marketRequests'
import UserIcon from '../../../../../generic/icons/IconLib/UserIcon'
import styled from 'styled-components'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../../knisot/Components/SwipeContent'
import ConfirmDelate from '../Components/ConfirmDelate'

interface IProps extends IMarketPalletDetails {
  item: IMarketPalletDetails,
  handleSelectProduct: (item: IMarketPalletDetails) => void,
  handleDelete: (id: number) => void
}

const PalletDetailesItem = ({
  ProductDesc, VarietyDesc, GrowerName, SizeID, Pack_Name, Quantity,
  SalePrice, WeightNeto, item, handleSelectProduct, handleDelete, RecID
}: IProps) => {
  const [isOpenDelate, setIsOpenDelate] = useState<boolean>(false)
  const [isDelated, setIsDelated] = useState<boolean>(false)
  const handleSelect = () => {
    handleSelectProduct(item)
    setIsOpenDelate(false)
  }
  const handleOpenConfirm = () => {
    setIsOpenDelate(!isOpenDelate)
  }
  const handleDeleteItem = () => {
    setIsDelated(true)
    return new Promise((resolve, reject) => {
      resolve(
        setTimeout(() => {
          handleDelete(RecID)
          
        }, 500)
      )
      reject(
        setTimeout(() => {
          setIsDelated(false)
          setIsOpenDelate(false)
        }, 500)

      )
    })
  }
  return (
    <TitleLayout
      isDraggable
      isDelete={isDelated}
    >
      <SwipeableListItem
        swipeRight={{
          content: <SwipeContent text='עדכון' />,
          action: handleSelect
        }}
        swipeLeft={{
          content: <SwipeContent text='מחק' isPrimal position='right' />,
          action: handleOpenConfirm
        }}
        threshold={0.3}
      >
        <MainWrapper>
       
          <BodyTitle>
            
            <TitleRow>
                <TitleRowItem 
                  icon={<UserIcon fill />} 
                  name={`${GrowerName.trim()}`} 
                />
              <TitleRowItem 
                icon={<AppleIcon />} 
                name={`${ProductDesc.trim()} ${VarietyDesc.trim()} ${SizeID.trim()}`} 
              />
            </TitleRow>
            <TitleRow>
              <TitleRowItem 
                icon={<PackageIcon />} 
                name={`${Quantity} - ${Pack_Name.trim()}`} 
              />
            </TitleRow>
            <TitleRow>
              <TitleRowItem 
                icon={<ShekelIcon />} 
                name={`${SalePrice}`} 
              />
              <TitleRowItem 
                icon={<ScalesIcon />} 
                name={`${WeightNeto.toLocaleString()}`} 
              />
            </TitleRow>
          </BodyTitle>
          {
            isOpenDelate &&
            <ConfirmDelate 
              handleClick={handleDeleteItem}
            />
          }
        </MainWrapper>

      </SwipeableListItem>
    </TitleLayout>
  )
}
const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`

const BodyTitle = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`
export default PalletDetailesItem