
import {LOAD_CLIENTS, LOAD_DEFAULTS, LOAD_PACKAGELIST, LOAD_PRODUCT_GROUPS, LOAD_TRANSPORT_AREAS, LOAD_WORKERS, TABLE_OF_TABLES} from '../actions/actionsTypes'

const initialState = {
    defaults : null,
    packageList: null,
    clients: null,
    transportAreas: null,
    clientList: {
        list: null,
        hashTable: null
    },
    workerList: null
}

const defaultsReducer = (state = initialState, action ) => {

    switch(action.type){
        case LOAD_DEFAULTS:
            return {
                ...state,
                // ...action.payload,
                defaults: action.payload
            }
        case LOAD_PACKAGELIST:
            return {
                ...state,
                packageList: action.payload
            }

        case LOAD_PRODUCT_GROUPS:
            return {
                ...state,
                groupList: action.payload
            }    
        case LOAD_WORKERS:
            return {
                ...state,
                workerList: action.payload
            } 
        case TABLE_OF_TABLES:
            return {
                ...state,
                tableOfTables: action.payload
            } 
        case LOAD_CLIENTS:
            return {
                ...state,
                clientList: action.payload
            }
        case LOAD_TRANSPORT_AREAS:
            return {
                ...state,
                transportAreas: action.payload
            }
        default: return state;
    }
}

export default defaultsReducer