const viewWindow = {
  // "packageOrder_ViewWindow_title": 'הזמנה/החזרה אריזה {{defID}}',
  "packageOrder_ViewWindow_title": 'Order/Return - Package {{defID}}',
}

export const packageOrderTranslationEN = {
  packageOrder: {
    "packageOrder_orders_title": 'Order/Return - Package',
    "packageOrder_orders_search_placeholder": 'Search',
    "packageOrder_modal_teuda_title": 'Package Order: {{num}}',
    "packageOrder_modal_teuda_checkbox": 'Send Email',
    "packageOrder_modal_remark_title": 'Plastic container Dolav',
    "packageOrder_modal_print_title": 'Print',
    "packageOrder_modal_print_order": 'Order',
    "packageOrder_modal_print_certificate": 'Certificate',
    "packageOrder_NewOrder_lable_date": 'Date',
    "packageOrder_NewOrder_lable_sender": 'Sender',
    "packageOrder_NewOrder_lable_receipient": 'Recipient',
    "packageOrder_NewOrder_lable_car": 'Car',
    "packageOrder_NewOrder_lable_refference": 'Reference',
    "packageOrder_NewOrder_placeholder_sender": 'Select sender',
    "packageOrder_NewOrder_placeholder_receipient": 'Select recipient',
    "packageOrder_NewOrder_placeholder_car": 'Select car',
    "packageOrder_NewOrder_placeholder_refference": 'Reference',
    "packageOrder_NewOrder_checkbox": 'Request delivery',
    ...viewWindow
  }
}