import React from 'react';
import { IconProps } from './model/IconInterface';

const PalletIcon: React.FC<IconProps> = ({ size = 20, color = '#297F76', fill}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill ? color : 'none'}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      // style={{ transform: 'rotate(90deg)' }}
    >
      {/* Horizontal lines */}
      <line x1="2" y1="10" x2="22" y2="10" />
      <line x1="2" y1="15" x2="22" y2="15" />

      {/* Vertical lines */}
      <line x1="12" y1="15" x2="12" y2="10" />
      <line x1="5" y1="15" x2="5" y2="10" />
      <line x1="19" y1="15" x2="19" y2="10" />
    </svg>
  );
};

export default PalletIcon;