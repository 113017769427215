import React, {useMemo, useState} from 'react'
import { IClientItem } from '../../../../../modules/API/Defaults/models/clients'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  
  list: IClientItem[],
  defaultID: number,
  setClientID: React.Dispatch<React.SetStateAction<number>>,
  requered?: boolean
}

const SearchClient = ({
  list, defaultID, setClientID, requered
}: Props) => {
  const defaultClient = list.filter(item => item.ClientID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedClient, setSelectedClient] = useState<IClientItem | null>(defaultClient || null)
  const [search, setSearch] = useState<string>(defaultClient?.ClientName?.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedClient(null)
      setClientID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IClientItem) => {
    setClientID(item.ClientID)
    setSearch(item.ClientName.trim())
    setSelectedClient(item)
    setIsOpenDropDown(false)
  }
  const render = useMemo(() => {
      if(!list) return []
      return list.filter(item => 
        item.ClientName?.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
  }, [list, search])
  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={'חפש לקוח'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='ClientName'
      nameIdElement='ClientID'
      required={requered}
      size={'full'}
    />
  )
}

export default SearchClient