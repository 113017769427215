import React, {useState, useMemo} from 'react'
import SearchFromTheList from '../../customSearchComponents/SearchFromTheList'

interface IFormat {
  NumWeight: number,
  DescWeights: string

}
type Props = {
  list: IFormat[],
  defaultID: number,
  setFormatID: (id: number) => void,
  required?: boolean,
}

const SearchFormatsPrint = ({
  list, defaultID, setFormatID, required

}: Props) => {
  const defaultFormat = list.filter(item => item.NumWeight === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedFormat, setSelectedFormat] = useState<IFormat | null>(defaultFormat || null)
  const [search, setSearch] = useState<string>(defaultFormat?.DescWeights?.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedFormat(null)
      setFormatID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IFormat) => {
    setFormatID(item.NumWeight)
    setSearch(item.DescWeights.trim())
    setSelectedFormat(item)
    setIsOpenDropDown(false)
  }

  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.DescWeights?.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [list, search])

  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={'בחר פורמט'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='DescWeights'
      nameIdElement='NumWeight'
      required={required}
      size={'full'}
    />
  )
}

export default SearchFormatsPrint