import { TCarDict } from "../../../../../../models/Defaults/carList"
import { ICustomerItem } from "../../../../../modules/API/Drivers/models/kavData"

type ICurrentPalletWeightValue = {
  [key: string]: number
}
const orderAllowToShow = [4, 7, 9]
export const currentPalletWeightValue = (customersArr: ICustomerItem[]): ICurrentPalletWeightValue => {
  let currValues = customersArr.reduce((acc: Record<string, number>, cur) => {
    if (cur.Type === 1) return acc // ignore Order rehesh
    let temp = cur.Orders.reduce((finalOrders: Record<string, number>, order) => {
      let tempPallet = order.PalletsList.reduce((finalPallets: Record<string, number>, pallet) => {
        finalPallets.weightCalc = (finalPallets.weightCalc || 0) + (pallet.Gross || 0)
        finalPallets.palletCalc = (finalPallets.palletCalc || 0) + (pallet.PallQty || 0)
        finalPallets.packageQty = (finalPallets.packageQty || 0) + (pallet.Qty || 0)
        return finalPallets
      }, {})
      if (orderAllowToShow.some(e => e === order.Status)) {
        finalOrders.weightCalc = (finalOrders.weightCalc ? finalOrders.weightCalc : 0) + (tempPallet.weightCalc || 0)
        finalOrders.palletCalc = (finalOrders.palletCalc ? finalOrders.palletCalc : 0) + (tempPallet.palletCalc || 0)
        finalOrders.packageQty = (finalOrders.packageQty ? finalOrders.packageQty : 0) + (tempPallet.packageQty || 0)

      } else {
        finalOrders.weightCalc = (finalOrders.weightCalc ? finalOrders.weightCalc : 0) + (order.Weight)
        finalOrders.palletCalc = (finalOrders.palletCalc ? finalOrders.palletCalc : 0) + (order.Pallets)
        finalOrders.packageQty = (finalOrders.packageQty ? finalOrders.packageQty : 0) + (order.Packs)
      }

      // console.log(finalOrders)
      return finalOrders
    }, {})

    acc.weightCalc = Math.round((acc.weightCalc || 0) + temp.weightCalc)
    acc.palletCalc = Math.ceil((acc.palletCalc || 0) + temp.palletCalc)
    acc.packageQty = Math.round((acc.packageQty || 0) + temp.packageQty)
    // console.log(acc)
    return acc
  }, {})
  return { ...currValues }
}

export const machineСapacity = (carInfoDict: TCarDict | null | undefined, carID: number) => {
  if(!carInfoDict || !carID) return {CarNum: null, Max_Weight: null, Pallets: null}
  if(!carInfoDict[carID]) {
    console.error(`Car with ID ${carID} not found in carInfoDict`)
    return {CarNum: null, Max_Weight: null, Pallets: null}
  
  }
  let { CarNum, Max_Weight, Pallets} = carInfoDict[carID]
  return { CarNum, Max_Weight, Pallets}
}
