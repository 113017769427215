import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[];
  title?: string;
}

const BodySection = ({children, title='title'}: Props) => {
  return (
    <MainWrapper>
      <Title>{title}</Title>
      <BodyWrapper>
        {children}
      </BodyWrapper>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
`
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 0.5em;
  font-size: 1.2em;
  color: #297F76;
`
const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  background: #fff;

`
export default BodySection