import { requests } from "../config";
import { IReceiptChangeStatus, IReceiptPostRequest } from "./models/requests";

export const Reciept = {
  // receipt?DateFrom=2&DateTo=3&CustomerID=4
  getReciepts: (data: string) => requests.get(`receipt?${data}`, 'go'),
  getRecieptByID: (id: number | string) => requests.get(`receipt/${id}`, 'go'),
  postReceipt: (data: IReceiptPostRequest) => requests.post(`receipt`, 'go', data),
  getDebt: (id: number | string) => requests.get(`receipt/debt/by_client?ClientID=${id}`, 'go'),
  changeStatus: (data: IReceiptChangeStatus[]) => requests.put(`receipt/status`, 'go', data),
} 