import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.3em 0.5em;
    :hover{
        background: #80e081;
    }
`

export default function ArizotClientItem({ClientName, setInputClient, setWrapperClientStatus, setClientIdRecip, ClientID}){

    return(
        <Wrapper onClick={()=> {setInputClient(ClientName.trim()); setWrapperClientStatus(false); setClientIdRecip(ClientID)}}>
            {`${ClientName.trim()} (${ClientID})` }
        </Wrapper>
    )
}