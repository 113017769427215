import React, { useState } from 'react'
import styled from 'styled-components'
import useFocus from '../../../../../generic/hooks/useFocus'
import { CloseIcon, EditIcon } from '../../../../../generic/icons/Icons'
import { DetalisContainer, DetalisInnerItem, DetalisItem, ItemContainer, TitleContainer } from '../likutSection/LikutSectionGoodsItem'

const RemarkButtonWrapper = styled.div`
    /* background: red; */
    position: absolute;
    top: 0;
    left: 0;
    width: 4em;
    height: 100%;
    border-radius: 30px;
    display: flex;
    justify-content:center;
    align-items: center;
    color: #8a8ab4;
    cursor: pointer;
`
const RemarkWrapper = styled.div`
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width: 50%;
    /* background: red; */
    border-radius: 30px;
    transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
`
const RemarkInnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #fdfdfd;
    border-radius: 30px;
    border: 1px solid #eff3f4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
`
const RemarkCloseIcon = styled.div`
    position: absolute;
    right: -1em;
    top: 0.5em;
    width: 2em;
    height: 2em;
    background: #7ab0ab;
    color: #f2f2f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
const RemarkField = styled.textarea`
    appearance: none;
    resize: none;
    width: 100%;
    height: 80%;
    border-radius: 20px;
    outline: none;
    border: none;
    background: #eff3f4;
    padding: 0.5em 1em;
    font-size: 1.3em;
    font-weight: 400;
    direction: rtl;
    color: #8a8aaf;
`
export default function LikutExpressCheckItem({ GrowerName, ProductDesc, Prod_remark, Quantity, Pack_Name, WeightNeto, setCommentData, RecID, CheckComment, commentData }) {
    const [inputRef, setInputFocus] = useFocus()
    const [remark, setRemark] = useState(CheckComment || ''); // Prod_remark is testname of the field
    const [isOpenRemark, setIsOpenRemark] = useState(false);
    const collectCommentsFunc = () => {
        let found = commentData.find((item) => {
            return item.RecID === RecID;
        })
        if (found) {

            let indProd = commentData.indexOf(found)
            setCommentData(commentData.splice((indProd), 1))
        }
        // console.log('ALERT')
        setCommentData([
            ...commentData,
            {
                "RecID": RecID,
                "CheckComment": remark 
            }
        ])
    }

    let remarkWindowToggle = isOpenRemark ? 'translateX(0)' : 'translateX(-110%)'
    return (
        <ItemContainer style={{ overflow: 'hidden' }}>
            <RemarkButtonWrapper
                style={{ color: remark.length > 0 ? 'tomato' : '#8a8ab4' }}
                onClick={() => { setIsOpenRemark(!isOpenRemark); setInputFocus() }}
            >
                <EditIcon />
            </RemarkButtonWrapper>
            <RemarkWrapper style={{ transform: remarkWindowToggle }}>
                <RemarkInnerContainer>
                    <RemarkCloseIcon 
                        onClick={() => { 
                                setIsOpenRemark(!isOpenRemark);
                                collectCommentsFunc()
                            }}
                    >
                        <CloseIcon />
                    </RemarkCloseIcon>
                    <RemarkField
                        ref={inputRef}
                        defaultValue={CheckComment}
                        onBlur={() => {
                            collectCommentsFunc()
                        }}
                        onChange={(e) => { setRemark(e.target.value) }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setIsOpenRemark(!isOpenRemark);
                                collectCommentsFunc()
                                e.target.blur()
                            }
                        }}
                    />
                </RemarkInnerContainer>
            </RemarkWrapper>
            <TitleContainer>
                {ProductDesc?.trim()}
            </TitleContainer>
            <DetalisContainer>
                <DetalisItem>
                    <DetalisInnerItem primal>משקל</DetalisInnerItem>
                    <DetalisInnerItem>{WeightNeto}</DetalisInnerItem>
                </DetalisItem>
                <DetalisItem>
                    <DetalisInnerItem primal>כמות</DetalisInnerItem>
                    <DetalisInnerItem>{Quantity}</DetalisInnerItem>
                </DetalisItem>
                <DetalisItem>
                    <DetalisInnerItem primal>אריזה</DetalisInnerItem>
                    <DetalisInnerItem>{Pack_Name}</DetalisInnerItem>
                </DetalisItem>
            </DetalisContainer>
        </ItemContainer>
    )
}
