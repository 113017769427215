import React, {useMemo, useState} from 'react'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'
import { IGrowerItem } from '../../../../../../modules/API/Defaults/models/growers'

type Props = {
  list: IGrowerItem[]
  defaultID: number
  setGrowerID: React.Dispatch<React.SetStateAction<number>>,
  required?: boolean
  placeholder?: string
  disabled?: boolean
}

const SearchGrower = ({
  list, defaultID, required, setGrowerID, placeholder, disabled
}: Props) => {
  const defaultGrower = list.filter(item => item.GrowerID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedGrower, setSelectedGrower] = useState<IGrowerItem | null>(defaultGrower || null)
  const [search, setSearch] = useState<string>(defaultGrower?.GrowerName?.trim() || '')
  const handleSearch = (e: string) => {
    if(disabled) return
    if(e === '') {
      setSelectedGrower(null)
      setGrowerID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IGrowerItem) => {
    if(disabled) return
    setGrowerID(item.GrowerID)
    setSearch(item.GrowerName.trim())
    setSelectedGrower(item)
    setIsOpenDropDown(false)
  }
  const render = useMemo(() => {
      if(!list) return []
      return list.filter(item => 
        item.GrowerName?.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
  }, [list, search])
  
  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={placeholder || 'חפש ספק'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='GrowerName'
      nameIdElement='GrowerID'
      required={required}
      size={'full'}
      disabled={disabled}
    />
  )
}

export default SearchGrower