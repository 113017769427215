import React from 'react'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import { IOrderReheshDetailes } from '../../../../../modules/API/AzmanotRehesh/models/order'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, EditIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../generic/icons/Icons'
import styled from 'styled-components'
import { IOrderReheshSelected } from '../AzmanotReheshMain'
import { TStatus, statusOrderReheshConvert } from '../helpers/statuses'
import { EXIST_PATH } from '../../../../../modules/API/settings'

interface IProps extends IOrderReheshDetailes  {
    index: number;
    handleSelectItemForStatus: (item: IOrderReheshSelected) => void;
    isGrower: boolean;
    item: IOrderReheshDetailes;
    parrentID: number;
}

const AzmanotReheshNestedItem = ({
    GrowerName, OrderWeight, OrderPacks, item, Status,
    Order_Pallets, handleSelectItemForStatus, isGrower,
    parrentID, isDetails, GeneralProductID, ProductDesc, VarietyDesc,
    Remarks
}: IProps) => {

    const weight = Math.round(OrderWeight).toLocaleString()
    const packs = Math.round(OrderPacks).toLocaleString()
    const isAllowEdit = isGrower && (Status === 1 || Status === 2 || Status === 3);
    const handleOpen = () => {
        handleSelectItemForStatus({...item, parrentID: parrentID})
    }
    const {color, text} = statusOrderReheshConvert(Status as TStatus)
    // console.log(color, text)
    const img = GeneralProductID !== 0 ? 
        `${EXIST_PATH}/images/products/${GeneralProductID}.png` 
            : '/emptyBox.png'
  return (
    <TitleLayout
        onClick={handleOpen}
        borderColor={color}
    >
        {
            isDetails &&
                <PictureWrapper>
                        <Img src={img} />
                </PictureWrapper>

        }
        {
            isDetails &&
            <TitleRow>
                {
                    Remarks?.trim() &&
                    <TitleRowItem name={""} icon={<EditIcon />} iconColor='tomato' />
                }
                <TitleRowItem name={`${ProductDesc?.trim()} ${VarietyDesc?.trim()}`} icon={<AppleIcon />} />
                {/* <StatusWrapper styleProps={color}>{text}</StatusWrapper> */}
            </TitleRow>

        }
        <TitleRow>
            <TitleRowItem name={GrowerName?.trim()} icon={<UserIcon />} />
            <StatusWrapper styleProps={color}>{text}</StatusWrapper>
        </TitleRow>
        <TitleRow>
            
            <TitleRowItem name={`${packs}`} icon={<PackageIcon />}/>
            <TitleRowItem name={`${weight}`} icon={<ScalesIcon />}/>
            <TitleRowItem name={`${Order_Pallets}`} icon={<PalletIcon />}/>
        </TitleRow>
    </TitleLayout>
  )
}

const StatusWrapper = styled.div<{styleProps: string}>`
    padding: 0.2em 0.5em;
    border-radius: 10px;
    transform: translateY(-4px);
    background: ${props => props.styleProps ? props.styleProps : '#297f76'};
`
const PictureWrapper = styled.div`
    position: absolute;
    top: calc(50% - 1.5em);
    width: 3em;
    height: 3em;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`
const Img = styled.img`
    user-select: none;
    width: 100%;
`
export default AzmanotReheshNestedItem