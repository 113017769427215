import { AnyAction } from "redux"
import { CLEAR_MARKET_STATE, SELECT_DATA_PRODUCT, SELECT_DATE_FOR_MARKET } from "../actions/actionsTypes"

export interface IProductCashItem {
    
    growerID: number;
    productID: number;
    varietyID: number;
    packageID: number;
    packageQty: number;
    price: number;
    weight: number;
    reference: number;
    
}

export interface IActionProductCash {
    data: Record<number | string, IProductCashItem | null> | null;
    date: string | null;
    
}


const initialState: IActionProductCash = {
    data: null,
    date: null
}

const marketReucer = (state = initialState, action: AnyAction) => {
    // console.log
    switch(action.type) {
        case SELECT_DATA_PRODUCT:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.customerID]: action.payload.data
                  
                }
              
            }
            case SELECT_DATE_FOR_MARKET:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_MARKET_STATE:
            return {
                ...initialState
            }
        default: return state
    }
}

export default marketReucer