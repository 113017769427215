import axios from "axios";
import {CURRENT_PATH} from '../API/settings'
import history from '../../../history'
import { CLEAR_DRIVER_STATE, CLEAR_ENTRY_STATE, CLEAR_RECEIPTS_STATE, CLEAR_STATE_KOSHER } from "../../../store/actions/actionsTypes";
import { resetState } from "../../../store/actions/resetState";


export const logOutFunc = async (getToken, dispatch, LOGOUT) => {
    try {
        await axios.delete(`${CURRENT_PATH}`, {
            headers: {
                         'Authorization' : 'Bearer ' + getToken
                    }
        })
        history.push('/login')
        dispatch(resetState())

    } catch(err) {
     
        history.push('/login')
        dispatch(resetState())
    }
}