import styled from "styled-components";


const Wrapper = styled.div`
    cursor: pointer;
    width: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
 
`


export default function RemarkButton({showRemark, setShowRemark, remarkButtonRef}){

    const remarkStatusSwitchert = () => {
        setShowRemark(!showRemark)
    }
    return(
        <Wrapper  onClick={() => {remarkStatusSwitchert()}}>
            <i ref={remarkButtonRef} className="fas fa-pen"></i>
        </Wrapper>
    );
}