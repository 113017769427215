import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ArrowIcon } from '../../../../../../generic/icons/Icons'

type Props = {
  position: 'left' | 'right'
  handleChangeWeekDate: (dir: "back" | "up") => void
}

const ButtonDateControl = ({
  position,
  handleChangeWeekDate
}: Props) => {
  const handleClick = () => {
    handleChangeWeekDate(position !== 'left' ? 'back' : 'up')
  }
  return (
    <MainWrapper
      dir={position}
      onClick={handleClick}
    >
      <ArrowIcon position={position} />
    </MainWrapper>
  )
}
const moveRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5px);
  }
`
const moveLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5px);
  }

`
const MainWrapper = styled.button<{dir: "left" | "right"}>`
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 3.5em;
  height: 3.5em;
  cursor: pointer;
  :hover {
    animation: ${props => props.dir === 'left' ? moveLeft : moveRight} 0.3s forwards;
  }
  i {
    font-size: 1.1em;
    color: #297F76;
  }
`

export default ButtonDateControl