import React from 'react';
import { IconProps } from './model/IconInterface';

const InvoiceIcon: React.FC<IconProps> = ({ size = 20, color = '#297F76', fill}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill ? color : 'none'}
      stroke={color}
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
    </svg>
  );
};

export default InvoiceIcon;