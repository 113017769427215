import React, { useState } from 'react'
import styled from 'styled-components'
import { ICarItem, TCarList } from '../../../../../models/Defaults/carList'
import DeliveriPNG from '../../../../generic/assets/delivery.png'
import ControlButton from '../../../../generic/buttons/ControlButton'
import SearchFromTheList from '../../../../generic/customSearchComponents/SearchFromTheList'
import { PalletIcon, PhoneIcon, ScalesIcon, SpeedIcon, UserIcon } from '../../../../generic/icons/Icons'
import { SearchWrapper } from '../likutExpress/monitoring/SelectWorkerModal'


type Props = {
    saveFunc: (id: number | ICarItem) => void,
    list: TCarList,
    closeFunc: (flag?:boolean) => void,
    defaultCar?: ICarItem,
    isFullObject?: boolean,
    additionalData?: boolean
}
interface IListState {
    default: TCarList,
    current: TCarList
}

function SelectDriver({
    saveFunc, list, closeFunc, defaultCar, isFullObject,
    additionalData
}: Props) {
    const [isOpenList, setIsOpenList] = useState<boolean>(false)
    const [input, setInput] = useState<string>(defaultCar?.DriverName?.trim() || '');
    // const [driverID, setDriverID] = useState<number | null>(defaultCar?.CarID || null);
    const [driverInfo, setDriverInfo] = useState<ICarItem | null>(defaultCar || null)
    const [listState, setListState] = useState({
        def: list,
        current: list
    })
    console.log(defaultCar)
    const searchFunc = (input: string) => {
        setInput(input)
        const filtered = listState.def.filter(item => item.DriverName.toLocaleLowerCase().includes(input.toLowerCase()))
        const obj = {
            ...listState,
            current: filtered
        }
        setListState(obj)
        setIsOpenList(filtered.length > 0 && input ? true : false);
        if (!input.trim()) {
            // setDriverID(null);
            setDriverInfo(null)
        }
        
    }
    const selectDriver = (item: ICarItem): void => {
        // setDriverID(item.CarID)
        setInput(item.DriverName?.trim());
        setIsOpenList(false)
        setDriverInfo(item)
    
    }
  return (
    <MainWrapper>
        <TitleImg src={DeliveriPNG}  alt='Driver'/>
        <SearchWrapper>
            <SearchFromTheList 
                size={'full'}
                input={input}
                placeHolder={'חיפוש'}
                list={listState.current}
                searchFunc={searchFunc}
                selectFunc={selectDriver}
                isOpenList={isOpenList}
                itemDisplayName={'DriverName'}
                nameIdElement={'CarID'}
                isNeedFullObject
            />
        </SearchWrapper>
        {
            driverInfo && 
                <DetailsWrapper>
                    <DetailsContainerRow>
                        {driverInfo.Phone} 
                        <PhoneIcon />
                    </DetailsContainerRow>
                    <DetailsContainerRow>
                        {driverInfo.CarNum} 
                        <SpeedIcon color={'tomato'} />
                    </DetailsContainerRow>
                    <DetailsContainerRow>
                        {driverInfo.Max_Weight} 
                        <ScalesIcon />
                    </DetailsContainerRow>
                    <DetailsContainerRow>
                        {driverInfo.Pallets} 
                        <PalletIcon />
                    </DetailsContainerRow>
                </DetailsWrapper>
        }
        {
                driverInfo && 
                    <SearchWrapper isSave>
                        <ControlButton
                            primal
                            lable={'עדכן'}
                            handleClick={() => {
                                saveFunc(defaultCar || isFullObject ? driverInfo : driverInfo.CarID); 
                                
                            }}
                        />
                    </SearchWrapper>      
            }
        
    </MainWrapper>
  )
}

export default SelectDriver

const MainWrapper = styled.div`
    /* width: 80%; */
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5em;
`
const TitleImg = styled.img`
    min-width: 400px
`

const DetailsWrapper = styled.div`
    /* background: red; */
    width: 80%;
    height: 6em;
    padding: 1em 1em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`
const DetailsContainerRow = styled.div`
    width: 100%;
    /* background: yellow; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.4em;
    color: #297f76;
    i {
        color: tomato;
    }
`