import React from 'react'
import { IOrderReheshDetailes } from '../../../../../modules/API/AzmanotRehesh/models/order'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import styled from 'styled-components'
import { EditIcon } from '../../../../../generic/icons/Icons'
import { IOrderReheshSelected } from '../AzmanotReheshMain'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import AzmanotReheshNestedItem from './AzmanotReheshNestedItem'

interface Props extends IOrderReheshDetailes {
  item: IOrderReheshDetailes;
  isGrower: boolean;
  parrentID: number;
  handleSelectItemForStatus: (item: IOrderReheshSelected) => void;
  index: number;
}

const AzmanotReheshDetailsItem = (props: Props) => {

//   const handleOpen = () => {
//     handleSelectItemForStatus({...item, parrentID: parrentID})
// }
  return (
    // <TitleLayout>
    //   <SideButtonWrapper onClick={handleOpen}>
    //     <EditIcon />
    //   </SideButtonWrapper>
    //   <TitleRow>

    //   </TitleRow>
    // </TitleLayout>
    <>
    <AzmanotReheshNestedItem
      {...props}
      isDetails={true}
      />
    </>
  )
}

export default AzmanotReheshDetailsItem