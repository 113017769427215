import styled from "styled-components";
import ModalDelete from '../../secondbar/modalAzmanot/modalDelete/modalDelete'
import { SwipeableListItem, ActionAnimations } from '@sandstreamdev/react-swipeable-list';
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { InnerContainer, MainWrapper, DeleteWrapper, ContentWrapper, ContentItem, ContentElement, StatusWrapper } from "../accounting/ArizotAccountingItem";
import { ArrowLongIcon, InvoiceIcon, CalendatDay, DeleteIcon, UserIcon, PrintIcon, EmailIcon } from "../../../../../generic/icons/Icons";
import { statusConverterArizot } from "../../../../../generic/artcoded/statusAzmanot";
import { LINKS } from "../../../../../Routs/config";


const SideIcon = styled.div`
    position: absolute;
    right: 0.5em;
    top: 1.6em;
    color: #8d7c8f;
    /* background: red; */

`
const EmailSent = styled.div`
    padding: 0.2em;
    border-radius: 50%;
    position: absolute;
    left: -0.1em;
    top: -.1em;
    color: #8d7c8f;
    -webkit-filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));
    filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));
    color: tomato;
`
const IconWrapper = styled.div`
    width: 5em;
    /* background: red; */
    display: flex;
    justify-content: space-around;
    gap: 0.5em;
    align-items: center;
    color: #297f76;
    
`
const Icon = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    :hover{
        i {
            transform: scale(1.2);
            color: tomato;
        }
    }
`


export default function ArizotBodyItem({ 
    PO_Date, RecipientName, Reference, Vehicle_Num, 
    PO_Status, SenderName, deleteArizot, PO_Num, printFunc,
    handleOpenModalFunc, PD_Num, isAllowTeuda, item, Sent
}) {
    const history = useHistory();
    const [confDelete, setConfDelete] = useState(false)
    const { status, color } = useMemo(() => {
        return statusConverterArizot(PO_Status)
    }, [PO_Status])
    const Date = PO_Date ? (PO_Date.substring(6, 8) + '/' + PO_Date.substring(4, 6)) : '00/00'
    let blockSwipe = PO_Status !== 0 ? true : false
    // let statusTextToggle = PO_Status === 0 ? 'סגורה' : 'פתוחה';
    const isShowTeuda = PO_Status === 0 && isAllowTeuda
    const handleClick = () => {
        handleOpenModalFunc(PO_Num)
    }
    return (
        <MainWrapper >
            <SwipeableListItem
                swipeLeft={!blockSwipe ? {
                    content: <ModalDelete
                        // callModal={<i className="far fa-trash-alt"></i>} 
                        background={"tomato"}
                        callModal={"? מחק"}
                        flex={"flex-end"}
                        border
                        />,

                    action: () => {
                        setConfDelete(!confDelete)
                    },
                    ActionAnimations
                } : null}


                swipeRight={{
                    content: <ModalDelete 
                    border 
                    background={'#7ab0ab'} 
                    callModal={blockSwipe ? "עיון" : 'עדכון'}
                    //  customerid={OrderID} 

                    />,
                    action: () => {
                        history.push(`${LINKS.arizot.arizaAhzara.editByID}${PO_Num}`)
                    }

                }}
                // onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
                threshold={0.3}
            >
                <InnerContainer
                    isShowDelete={confDelete}
                >
                    {
                        Sent && 
                            <EmailSent>
                                <EmailIcon />
                            </EmailSent>
                    }
                    <IconWrapper >
                        <Icon onClick={() => {printFunc(item); console.log(item)} }>
                            <PrintIcon />
                        </Icon>
                        {
                            isShowTeuda &&
                                <Icon onClick={handleClick}>
                                    <InvoiceIcon />
                                </Icon>
                        }
                    </IconWrapper>
                    <SideIcon>
                        <ArrowLongIcon />
                    </SideIcon>
                    <ContentWrapper>
                        <ContentItem isTitle>
                            <ContentElement>
                                {SenderName?.trim().toLowerCase()}
                                <UserIcon />
                            </ContentElement>
                        </ContentItem>
                        <ContentItem>
                            {
                                Reference?.trim() &&
                                    <ContentElement>
                                        {Reference?.trim().toLowerCase()}
                                        <InvoiceIcon />
                                    </ContentElement>
                            }
                            <ContentElement>
                                {Date?.trim().toLowerCase()}
                                <CalendatDay />
                            </ContentElement>
                            <ContentElement>
                                <StatusWrapper color={color}>
                                    {status}
                                </StatusWrapper>
                            </ContentElement>
                            <ContentElement>
                                {RecipientName?.trim().toLowerCase()}
                                <UserIcon />
                            </ContentElement>
                        </ContentItem>
                    </ContentWrapper>
                </InnerContainer>
                <DeleteWrapper
                    isOpen={confDelete}
                    onClick={() => { deleteArizot(PO_Num); setConfDelete(false) }}
                >
                    <DeleteIcon />
                </DeleteWrapper>
            </SwipeableListItem>
        </MainWrapper>
    )
}