import styled from 'styled-components';
import { BarIcon } from '../../../../../../../generic/icons/Icons';
import WrapperItem from './wrapperItem'
import { useMemo } from 'react';

const SelectPackageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 1.8em;
    border-radius: 30px;
    background: #eff3f4;
    
`
const SelectItem = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: 2px solid #eff3f4;
    font-size: 1em;
    color: #4b938c;
    padding: 0 0.5em;
    appearance: none;
    background: #eff3f4;
    -webkit-appearance: none;
    outline: none;
    
    :focus {
        
        @keyframes pulse {
            0% {
                border: 2px solid #eff3f4;
            }

            50% {
                border: 2px solid #4b938c;
            }
        }
        animation: 2s pulse infinite;
    }
`

const IconWrapper = styled.div`
    position: absolute;
    /* background: red; */
    height: 100%;
    width: 2.5em;
    color: #4b938c;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    font-size: 0.9em;
`
export default function WrapperList({packegeList, setPackType, id, PackID, disableInputs, setlectedPack}) {
    const defPackID = PackID;
    
    // console.log(setlectedPack)
    return(
        <SelectPackageWrapper>
            <IconWrapper style={{display: PackID !== 0 ? 'none' : 'flex'}}><BarIcon /></IconWrapper>
            <SelectItem  defaultValue={setlectedPack || defPackID} disabled={disableInputs} dir="rtl" onChange={(e)=> {setPackType(e.target.value)}}>
                <option value={0}>ארגז/קרטון</option>
                {packegeList.map(item => 
                     <WrapperItem key={item.PackID}{...item}/> 
                )}
            </SelectItem>
        </SelectPackageWrapper>
    );

}