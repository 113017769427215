import React from 'react'
import logo from './mainlogo.png'
import './mainlogo.css'
import { Link } from 'react-router-dom'


function MainLogo({handlerSpan, handlerLogo}) {
    return(
        <div className="header-img">
            <Link to="/home" style={{color: handlerLogo}}>
                {/* <img src={logo} alt={"logo"}></img> */}
                AGRO<span className="header-img-span" style={{color: handlerSpan}}>LINE</span>
            </Link>
        </div>
    )
}

export default MainLogo