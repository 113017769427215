import React, {useState, useEffect} from 'react'
import { IDataForEditForecastItem } from '../../types/dataForEditForecastItem'
import { ForcastProductItem } from '../../../../../../../modules/API/Forcasts/models/forcastProductitem'
import styled from 'styled-components'
import ProductImage from '../../../../queries/TeudotDetails/Components/ProductImage'
import SearchBlockWithTitle from '../../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchInput from '../../../../../../../generic/inputs/inputSearch'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import CircleActiveFilterBtn from '../../../../../../../generic/particles/CircleActiveFilterBtn'
import { DeleteIcon } from '../../../../../../../generic/icons/Icons'

type Props = {
  selectedDataForForecast: IDataForEditForecastItem
  editForecastItem: (data: IDataForEditForecastItem) => void
  calculationMethod: number
  handleDeleteForecastItem: (item: number) => void
}

const EditForecastItemModal = ({
  selectedDataForForecast,
  editForecastItem,
  calculationMethod,
  handleDeleteForecastItem
}: Props) => {
  const [selectedForecast, setSelectedForecast] = useState<ForcastProductItem>(selectedDataForForecast.itemData.item)
  const [t] = useTranslation(['buttons'])

  const handleChangeAmount = (e: string) => {
    setSelectedForecast({
      ...selectedForecast,
      ForecastAmnt: +e
    })
  }

  const handleChangePallets = (e: string) => {
    setSelectedForecast({
      ...selectedForecast,
      ForecastPallets: +e
    })
  }
  const handleChangeWeight = (e: string) => {
    setSelectedForecast({
      ...selectedForecast,
      ForecastWieght: +e
    })
  }
  const handleSave = () => {
    editForecastItem({
      indexColumn: selectedDataForForecast.indexColumn,
      itemData: {
        index: selectedDataForForecast.itemData.index,
        item: selectedForecast
      }
    })
  }
  console.log(selectedForecast)
  const handleDelete = () => {

    handleDeleteForecastItem(selectedForecast.RecID)
  }
  return (
    <MainWrapper>
      <ImageWrapper>
        <ProductImage 
          id={selectedForecast.GeneralProductID}
          size='lg'
        />
      </ImageWrapper>
      <TitleWrapper>
        {`${selectedForecast.ProductDesc.trim()} ${selectedForecast.VarietyDesc.trim()}`}
      </TitleWrapper>
      <BodyWrapper>
        <SearchBlockWithTitle
          title={
            calculationMethod === 2 ? ' כמות משטחים' :
            calculationMethod === 3 ? 'משקל' :
            '  כמות אריזות'
          }
        >
          {/* <SearchInput 
            input={selectedForecast.ActualPallets.toString()}
            value={'כמות משטחים'}
            iconHide
            type={'number'}
            setInput={handleChangePallets}
            // defaultValue={selectedForecast.ActualWeight}
          
          /> */}
          <SearchInput 
            input={
              calculationMethod === 2 ? selectedForecast.ForecastPallets.toString() :
              calculationMethod === 3 ? selectedForecast.ForecastWieght.toString() :
              selectedForecast.ForecastAmnt.toString()
            }
            value={'משקל צפוי'}
            iconHide
            type={'number'}
            setInput={
              calculationMethod === 2 ? handleChangePallets : 
              calculationMethod === 3 ? handleChangeWeight :
              handleChangeAmount
            }
            // defaultValue={selectedForecast.ActualWeight}
          
          />
        </SearchBlockWithTitle>
      </BodyWrapper>
      <ControlWrapper>
        <CircleActiveFilterBtn func={handleDelete}>
          <DeleteIcon  />
        </CircleActiveFilterBtn>

        <ControlButton 
          lable={`${t("save")}`}
          handleClick={handleSave}
          />
        </ControlWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 100px;
`
const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const TitleWrapper = styled.h4`
  color: #297F76;


`
const ControlWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1em;
  i {
    color: tomato;
  }
`
export default EditForecastItemModal