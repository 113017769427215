import React from 'react'
import { INavItem } from '../SettingsNav'
import styled from 'styled-components'
import { Link, useRouteMatch } from 'react-router-dom'

const COLOR: string = '#EFF3F4'
const COLOR_PRIMAL: string = '#297F76'

interface IProps extends INavItem {}

const NavItem = ({
  name, icon, path
}: IProps) => {
  const match = useRouteMatch(path)
  return (
    <MainWrapper isMatch={!!match}>
      <Link to={path}>
        <IconWrapper isMatch={!!match}>
          {icon}
        </IconWrapper>
        <NameWrapper isMatch={!!match}>
          {name}
        </NameWrapper>
      </Link>
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{isMatch: boolean}>`
  padding: 0.5em;
  min-width: 4em;
  max-width: 6.5em;
  background: ${props => props.isMatch ? COLOR: 'none'};
  transition: all 0.2s ease-in-out;
  transform: ${props => props.isMatch ? 'translateY(-5%)': 'translateY(0)'};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const IconWrapper = styled.div<{isMatch: boolean}>`
  font-size: 1.5em;
  margin-bottom: 0.1em;
  color: ${COLOR_PRIMAL};

`

const NameWrapper = styled.div<{isMatch: boolean}>`
  color: ${COLOR_PRIMAL};

`
export default NavItem