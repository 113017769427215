import React from 'react'
import { IPalletDetails } from '../../../../../../modules/API/Pallets/models/palletDetails'
import styled from 'styled-components'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import PalletDetalelItem from './PalletDetalelItem'
import { IPalletTypeItem } from '../../../../../../modules/API/Defaults/models/pallets'
import { IPackagesItem } from '../../../../../../modules/API/Defaults/models/packages'
import ControlButton from '../../../../../../generic/buttons/ControlButton'

type Props = {
  list: IPalletDetails[],
  palletTypes: IPalletTypeItem[],
  packageList: IPackagesItem[],
  handleSave: () => void,
  handlePatchItem: (recID: number, fieldName: string, value: string | number) => void
}

const PalletDetaileModal = ({
  list, palletTypes, packageList, handlePatchItem, handleSave
}: Props) => {
  console.log(list)
  return (
    <MainWrapper>
      <BodyContainer>
        {
          list.map((item, index) => {
            return <PalletDetalelItem 
              key={item.RecID}
              palletTypes={palletTypes}
              packageList={packageList}
              handlePatchItem={handlePatchItem}
              {...item}
            />

          })
        }
      </BodyContainer>
        <ControlButton lable={'שמור'} handleClick={handleSave} />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  padding: 1em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`
const BodyContainer = styled.div`
  width: 100%;
  max-height: 500px;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  overflow-y: auto;
  padding: 0.5em;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default PalletDetaileModal