import React from 'react'
import { ISupplyDataCustomerItem } from '../../../../../modules/API/Forcasts/models/seller'
import styled from 'styled-components'
import CustomerItem from './Components/CustomerItem'
import SideBarProductListTayout from './generic/SideBarProductListTayout'
import BodyHorizontalScrollLayout from './generic/BodyHorizontalScrollLayout'

type Props = {
  list: ISupplyDataCustomerItem[]
  setList: (list: ISupplyDataCustomerItem[]) => void
}

const CustomerBody = ({
  list,
  setList
}: Props) => {
  return (
    <MainWrapper>
      <BodyHorizontalScrollLayout>
        {
          list.map((item, index) => (
            <CustomerItem
              {...item}
              item={item}
              key={item.Customer.CustomerID}
            />
          ))
        }
      </BodyHorizontalScrollLayout>
      
      <SideBarProductListTayout>
        <>
        </>
      </SideBarProductListTayout>
      
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5em;
  z-index: 1;
  border-radius: 30px;
  overflow-y: hidden;

`


export default CustomerBody