import React, { useContext } from 'react'
import styled from 'styled-components'
import Title from '../../../../../generic/Title/Title';
import { LikutModals } from '../../../../../modules/Context/CefiNum';
import { ContentWrapper, Decorator, MainWrapper } from '../../secondbar/mainbar2';
import LikutSectionGoodsList from './LikutSectionGoodsList';
import ControlButton from '../../../../../generic/buttons/ControlButton';
const MainWrapperModalRow = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.3);
    z-index: 99;
`
export default function AzmanaRowModal({
    azmanotListDefault, defaultPackageList, handleClose, entryLine
}) {
    const [resonState, setResonState] = useContext(LikutModals);
    console.log(azmanotListDefault)
    const listRender = azmanotListDefault.filter(item => 
        item?.ProductFamilyId === entryLine?.ProductFamilyId
        );
    return (
        <MainWrapperModalRow>
            <MainWrapper>
                <Decorator />  
                <Title label={`בחר מוצר`} />
                <ContentWrapper>
                    <LikutSectionGoodsList azmanotList={listRender} defaultPackageList={defaultPackageList} setDefaultPacks={resonState?.props?.setDefProd} selecteble={true} />
                </ContentWrapper>
            <ControlButton lable={'חזרה'} handleClick={handleClose} />
            </MainWrapper>
        </MainWrapperModalRow>
    )
}
