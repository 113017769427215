import axios from 'axios';
import Delivery from '../../../../generic/assets/delivery.png'
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { LOAD, LOAD_END, LOGOUT, SELECT_MARKETING_AREA } from '../../../../../store/actions/actionsTypes';
import SelectFromTheList from '../../../../generic/customSearchComponents/SelectFromTheList';
import DatePickerModule from '../../../../generic/datePicker/datePicker';
import { ChartIcon, InvoiceIcon, LogIcon, PlusIcon } from '../../../../generic/icons/Icons';
import SearchInput from '../../../../generic/inputs/inputSearch';
import { GET_DELIVERYCODES, GET_PALLETS_COLLECTION, GET_DELIVERY_AZMANOT, PATCH_PALLET, GET_CAR_ARIZOT, TRANSPORT_AREAS } from '../../../../modules/API/settings';
import { ContextModal } from '../../../../modules/modal/modalContext';
import MainGroupContainer from './MainGroupContainer';
import Logger from './Logger/Logger';
import { format } from 'date-fns';
import DashboardTruck from './Dashboard/DashboardTruck';
import MultiSelect from './MultiSelect';
import StatisticTable from './StatisticTable/StatisticTable';
import StatisticModalLayout from '../../../../generic/Layouts/StatisticModalLayout';
import selectBrodMigdal from '../../../../generic/utils/selectBrodMigdal';
import ColumModal from '../../../../generic/Layouts/ColumModal';
import SelectDriver from './SelectDriver';
import { Trucks } from '../../../../modules/API/Distribution/trucks';

export const MainWrapper = styled.div`
    width: 100%;
    height: 100vh;
    padding: 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    align-items: center;
    background: #eff3f4;
    @media screen and (max-height: 500px) {
        position: relative;
        min-height: 1000px;
        overflow-y: scroll;
    }
    `
export const HeadWrapper = styled.div`
    width: 90%;
    min-height: 4em;
    background: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 0 0.4em;
    gap: 0.5em;
`
const Main = styled.div`
    display: flex;
    width: 100%;
    height: calc(100% - 5em);
    background: #e9f3f2;
    gap: 1em;
    padding: 1em;
    border-radius: 30px;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
`
const Wrapper = styled.div`
    max-height: 100%;
    width: 100vw;
    overflow: auto;
    direction: rtl;
    border-radius: 30px;
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`
const Inner = styled.div`
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    gap: 1em;
    justify-content: flex-start;
    /* padding: 1em; */
    
    /* flex-direction: row-reverse; */
    /* direction: rtl; */

`
const StaticColumnWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 370px;
    padding: 0 0.5em;
    /* background: red; */
    border-radius: 30px;
    overflow: hidden auto;
    display: flex;
    /* flex-flow: row-reverse;
    justify-content: center; */
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    ::before {
        
        position: absolute;
        bottom: 0;
        left: -1em;
        height: 99%;
        width: 100px;
        content: '';
        background: #000;
        z-index: -1;
        border-radius: 30px;
        @media screen and (max-width: 770px) {
            /* display: none; */
        }
    }
    /* filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51)); */
`

export const FilterIndicator = styled.div`
    position: relative;
    height: 3.5em;
    min-width: 3.5em;
    background: #eff3f4 ;
    border-radius: 50%;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: #297f76;
    display: flex;
    justify-content: center;
    align-items: center;
    
    :hover {
        background: ${props => props.common ? '#eff3f4' : '#297f76'} ;
    }
    
`
export const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #eff3f4;
    border-radius: 30px;
    align-items: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    :hover {
        transform: translateX(3px) rotate(45deg) translateY(-3px);
    }
`

const FilterBodyWrapper = styled.div`
    position: absolute;
    bottom: -290px;
    right: 0;
    width: 300px;
    height: 290px;
    background: #eff3f4;
    z-index: 2;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1em;
    padding: 1em;
    border-radius: 30px;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    img {
        min-width: 300px;
    }

`
const FilterBodyItemContainer = styled.div`
    width: 100%;
    padding: 0.2em;
    background: #fff;
    border-radius: 30px;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
    gap: 0.5em;
    ::-webkit-scrollbar {
        width: 0;
    }
`



const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function TrucksMain(props) {
    const defaultsTable = useSelector(e => e.defaults?.tableOfTables);
    const { clientList, transportAreas } = useSelector(e => e.defaults);
    const transportAreaDict = transportAreas.dict
    // transportAreaDict[2].CarId = 905
    const sessionLogData = JSON.parse(sessionStorage.getItem('log'));
    const dispatch = useDispatch()
    const getToken = useSelector(token => token.mainReducer.token);
    const [palletList, setPalletList] = useState({
        current: null,
        defaultArray: null
    });
    console.log(palletList)
    const defaults = useSelector(e => e.defaults?.defaults);
    const ignorDirection = selectBrodMigdal(defaults, 593, 'Num_Value')
    const [modal, setModal] = useContext(ContextModal);
    const [date, setDate] = useState(null);
    const [selectedPallets, setSelectedPallets] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isOpenLog, setIsOpenLog] = useState(false);
    const [logData, setLogData] = useState(sessionLogData || []);
    const [isOpenDashboard, setIsOpenDeshboard] = useState(false);
    const [isOpenTable, setIsOpenTable] = useState(false)
    const [selectedKav, setSelectedKav] = useState(null);
    const [isOpenChangeDriver, setIsOpenChangeDriver] = useState(false)
    // filter state control
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const marketAreaList = defaultsTable.filter(e => e.MainCode === 300 && e.SecondCode !== 0)
    const [selectedDistricts, setSelectedDistricts] = useState([])
    const [deliveryCodeList, setDeliveryCodeList] = useState([]);
    const [deliveryID, setDeliveryID] = useState(null);
    const [carsInfo, setCarsInfo] = useState({});
    const statusListAvalibleToCollectInPallet = [9, 4, 7]
    const carsDict = carsInfo?.dict
    const selectDelivery = (value) => {
        console.log(value)
        if (value) {
            setDeliveryID(+value)
        } else {
            setDeliveryID(null)
        }
    }


    // ! filter state control
    const collectDragPallets = (item) => {
        let flag = selectedPallets.find(e => e.Pallet_Num === item.Pallet_Num)
        let checkCurrentOrder = selectedPallets.length > 0 && selectedPallets[0]?.OrderID !== item.OrderID
        if (checkCurrentOrder) {
            setModal({
                open: true,
                value: 'select pallet from current order',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            return
        }
        if (!flag) {
            setSelectedPallets(prev => [...prev, item])

        } else {
            let elemIndex = selectedPallets.indexOf(item)
            selectedPallets.splice(elemIndex, 1)
            // console.log('deleted')
            setSelectedPallets(selectedPallets);
        }
        // console.log(flag)
        console.log(selectedPallets)
    }
    
    const combinePalletsByOrder = (arr, azmanot) => {
        let temp = arr?.reduce((acc, cur) => {
            if (!statusListAvalibleToCollectInPallet.some(e => e === cur.Status)) return acc
            acc[cur.PalletTransferZone] = acc[cur.PalletTransferZone]?.concat(cur) || [].concat(cur)
            return acc
        }, {})
        if (!temp[0]) {
            temp[0] = []
        }
        azmanot?.forEach(item => {
            if (!temp[item.TransferZone]) {
                temp[item.TransferZone] = []
            }
        })
        const result = Object.keys(temp).map(key => {
            let groupOrders = temp[key].reduce((acc, cur) => {
                acc[cur.OrderID] = acc[cur.OrderID]?.concat(cur) || [].concat(cur)
                return acc
            }, {})
            const addictional = []
            azmanot?.forEach(item => {
                // console.log(item.TransferZone , +key)
                if (item.TransferZone === +key && !groupOrders[item.OrderID]) {
                    let obj = {
                        custom: true,
                        OrderID: item.OrderID,
                        PalletsQty: item.Pallets,
                        NetWeight: item.Weight,
                        Customer_Name: clientList?.hashTable[item.CustomerId]?.Customer_Name,
                        Status: item.Status,
                        CustomerID: item.CustomerId,
                        Products: item.Packs
                    }
                    groupOrders[item.OrderID] = obj
                    addictional.push(obj)
                }
            })
            // console.log(temp[key])
            return {
                PalletTransferZone: Number(key),
                CustomerID: temp[key][0]?.CustomerID || temp[key]?.CustomerID, 
                NetWeight: Math.round(temp[key].reduce((acc, cur) =>
                    acc += cur.GrossWeight
                    , 0)) +
                    Math.round(addictional.reduce((acc, cur) =>
                        acc += cur.NetWeight
                        , 0)),
                OrderPallets: Object.keys(groupOrders).map(key => {
                    return {
                        OrderID: Number(key),
                        Custom: groupOrders[key].custom ? true : false,
                        Width: groupOrders[key].custom ? groupOrders[key].NetWeight : null,
                        PalletsQty: groupOrders[key].custom ? Math.ceil(groupOrders[key].PalletsQty) : groupOrders[key].reduce((acc, cur) => {
                            acc += cur.Total_Pallets
                            return acc
                        }, 0),
                        pallets: groupOrders[key].custom ? [] : groupOrders[key],
                        Customer_Name: groupOrders[key].custom ? groupOrders[key].Customer_Name : groupOrders[key][0]?.Customer_Name,
                        CustomerID: groupOrders[key].custom ? groupOrders[key].CustomerID : groupOrders[key][0]?.CustomerID,
                        Status: groupOrders[key].custom ? groupOrders[key].Status : groupOrders[key][0]?.Status,
                        Products: groupOrders[key].custom ? groupOrders[key].Products : groupOrders[key].reduce((acc, cur) => {
                            acc += cur.Total_Packages
                            return acc
                        }, 0)
                    }
                })
            }
        })
        // if (!transportAreaDict) return result
        // console.log(result)
        return result?.sort((a, b) => transportAreaDict[a.PalletTransferZone]?.Sort - transportAreaDict[b.PalletTransferZone]?.Sort)
    }

    const changeDriver = (item) => {
        setSelectedKav(item);
        setIsOpenChangeDriver(true);
    }

    const patchPallet = async (body) => {
        try {
            let res = await Trucks.changeKav(body)
            // console.log(res.data)
        } catch (err) {
            console.log(err)
            if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({type: LOGOUT, payload: false});
            }
        }
    }
    // const patchPallet = async (body) => {
    //     try {
    //         let res = await axios({
    //             method: 'patch',
    //             url: PATCH_PALLET,
    //             headers: {
    //                 'Authorization': 'Bearer ' + getToken

    //             },
    //             data: body
    //         })
    //         // console.log(res.data)
    //     } catch (err) {
    //         console.log(err)
    //         if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
    //             dispatch({type: LOGOUT, payload: false});
    //         }
    //     }
    // }
    const generalFilter = (input) => {
        const addictionalOrdersInDestinations = (kav) => {
            if(kav === 0) return true
            if (!selectedDistricts.length > 0) return false
            const reduced = [...palletList?.defaultArray, ...palletList?.azmanot].reduce((acc, cur) => {
                if(acc[cur.PalletTransferZone || cur.TransferZone]) return acc
                if (selectedDistricts.some(e => e.SecondCode === cur.Marketing_AreaID)) {
                    acc[cur.PalletTransferZone || cur.TransferZone] = true
                }
                return acc
            }, {})
            // console.log(reduced)
            return reduced[kav]
        }
        const filtered = palletList?.defaultArray?.filter(item =>
            (
                (
                    item.Customer_Name?.toLowerCase().includes(input.toLowerCase()) ||
                    transportAreaDict[item.PalletTransferZone]?.AreaDesc?.toLowerCase().includes(input.toLowerCase()) ||
                    item.PalletTransferZone === 0 ||
                        carsDict[transportAreaDict[item.PalletTransferZone]?.CarId]?.DriverName?.trim().toLowerCase().includes(input.toLowerCase())
                ) &&
                (deliveryID ? item.Delivery_Code === deliveryID : true) &&
                (
                    selectedDistricts.length > 0 ? 
                        selectedDistricts.some(e => e.SecondCode === item.Marketing_AreaID) || addictionalOrdersInDestinations(item.PalletTransferZone)
                            : true
                
                )
            )
        )
        const filterAzmanot = palletList?.azmanot?.filter(item =>
            (
                ((
                    clientList?.hashTable[item.CustomerId]?.Customer_Name?.toLowerCase().includes(input.toLowerCase()) ||
                    transportAreaDict[item.TransferZone]?.AreaDesc?.toLowerCase().includes(input.toLowerCase()) ||
                    item.TransferZone === 0 ||
                    carsDict[transportAreaDict[item.TransferZone]?.CarId]?.DriverName?.trim().toLowerCase().includes(input.toLowerCase())
                )) &&
                (deliveryID ? item.Delivery_Code === deliveryID : true) &&
                (
                    selectedDistricts.length > 0 ? selectedDistricts.some(e => e.SecondCode === item.Marketing_AreaID) || addictionalOrdersInDestinations(item.TransferZone)
                        : true
                )
            )
        )
        return combinePalletsByOrder(filtered, filterAzmanot)
    }
    const pageSearch = (input) => {
        setSearchInput(input)
        setPalletList({
            ...palletList,
            current: generalFilter(input)
        })
    }
    // console.log(palletList)
    const pageFilter = (input) => {
        setPalletList({
            ...palletList,
            current: generalFilter(input)
        })
    }

    const getDeliveryCodes = async () => {
        try {
            let res = await axios.get(GET_DELIVERYCODES, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            console.log(res.data)
            setDeliveryCodeList(res.data.deliveryCodes)
        } catch (err) {
            console.log(err.response)
        }
    }
    const getCars = async () => {
        try {
            let res = await axios.get(GET_CAR_ARIZOT, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            let newSet = {}
            res.data.cars.forEach(item => {
                newSet[item.CarID] = item
            })
            setCarsInfo({
                list: res.data.cars,
                dict: newSet
            })
        } catch (err) {
            console.log(err.response)
        }
    }

    const dataPalletCollection = async (date) => {
        try {
            let res = await axios.get(`${GET_PALLETS_COLLECTION}1?date=${date}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            getAzmanotDelivery(date, res.data.zones)
        } catch (err) {
            console.log(err.response)
        }
    }

    const getAzmanotDelivery = async (date, palletList) => {
        try {
            let res = await axios.get(`${GET_DELIVERY_AZMANOT}${date}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            let res1 = await axios.get(`
            https://my.agroline.co.il/v1/api/order/details?fromDate=${date}&toDate=${date}&orderType=2&lineType=2`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            console.log(res1)
            let azmanot = res.data.result.filter(
                item => item.Order_Type === 1 && !statusListAvalibleToCollectInPallet.some(e => e === item.Status)
                )
            console.log(res.data.result)
            setPalletList({
                defaultArray: palletList,
                current: combinePalletsByOrder(palletList, azmanot),
                azmanot: azmanot
            })
        } catch (err) {
            console.log(err)
        }
    }

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;
        console.log(destination)
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        const sourceIndex = source.index;
        const destIndex = destination.index;

        const sourceID = parseInt(source.droppableId)
        const destinationID = parseInt(destination.droppableId)
        const dragID = parseInt(draggableId)

        const itemMap = palletList.current.reduce((acc, cur) => {
            acc[cur.PalletTransferZone] = cur
            return acc
        }, {})

        let newArr = [...palletList.current]
        // console.log(itemMap[sourceID][sourceIndex].pallets.length === selectedPallets.length)
        // console.log(sourceIndex)
        const sourceOrderList = itemMap[sourceID].OrderPallets
        console.log(itemMap)
        const destenationOrderList = itemMap[destinationID].OrderPallets
        let data
        // if droped in the same column - reorder Order list
        if (sourceID === destinationID) {
            const reordered = reorder(
                sourceOrderList,
                sourceIndex,
                destIndex
            )
            newArr = newArr.map(e => {
                if (e.PalletTransferZone === sourceID) {
                    e.OrderPallets = reordered
                }
                return e
            })
            setPalletList({
                ...palletList,
                current: newArr
            })

        } else {
            // if dropped order in another column, without selected pallets inside
            let newSourceOrders = [...sourceOrderList];
            const [draggedItem] = newSourceOrders.splice(sourceIndex, 1);

            // copy of destination array
            let newDestOrders = [...destenationOrderList]
            let found = newDestOrders.find(e => e.OrderID === draggedItem.OrderID)

            // let checkDraw = newSourceOrders.find(e => e.OrderID === draggedItem.OrderID)
            console.log(draggedItem)
            console.log(draggedItem?.pallets?.length)
            console.log(selectedPallets.length)
            console.log(draggedItem?.pallets?.length === selectedPallets.length)
            if (selectedPallets.length === 0 || draggedItem?.pallets?.length === selectedPallets.length) {
                // copy of current array

                let editDefault = [...palletList.defaultArray.filter(item => !draggedItem.pallets.find(e => e.Pallet_Num === item.Pallet_Num))]
                    .concat(draggedItem.pallets.map(item => {
                        item.PalletTransferZone = destinationID
                        return item
                    }))
                if (found) {
                    // if Order already exist in the destination column, 
                    // delete order from source and concat pallets with Order pallets in destination
                    let indexOfFound = newDestOrders.indexOf(found)
                    const [concatedPallets] = newDestOrders.splice(indexOfFound, 1)
                    concatedPallets.pallets = [
                        ...concatedPallets.pallets,
                        ...draggedItem.pallets
                    ]
                    newDestOrders.splice(destIndex, 0, concatedPallets)
                    newArr = newArr.map(item => {
                        if (item.PalletTransferZone === sourceID) {
                            item.OrderPallets = newSourceOrders
                        } else if (item.PalletTransferZone === destinationID) {
                            item.OrderPallets = newDestOrders
                        }
                        return item
                    })
                    

                    // data = {
                    //     "distrOrder": destIndex,
                    //     "transferZone": destinationID,
                    //     "pallets": concatedPallets.pallets?.map(item => {
                    //         item = item.Pallet_Num
                    //         return item
                    //     }),
                    //     "orders": concatedPallets.pallets?.length > 0 ? [] : [concatedPallets.OrderID]
                    // }

                    console.log(concatedPallets.pallets?.length > 0 )

                } else {
                    //if Order isn't exist in destination column, delete order from source and paste in the destination 
                    newDestOrders.splice(destIndex, 0, draggedItem)
                    newArr = newArr.map(item => {
                        if (item.PalletTransferZone === sourceID) {
                            item.OrderPallets = newSourceOrders
                        } else if (item.PalletTransferZone === destinationID) {
                            item.OrderPallets = newDestOrders
                        }
                        return item
                    })
                }
                setPalletList({
                    ...palletList,
                    defaultArray: editDefault,
                    current: newArr
                })
                // data = {
                //     "distrOrder": destIndex,
                //     "transferZone": destinationID,
                //     "pallets": draggedItem.pallets.map(item => {
                //         item = item.Pallet_Num
                //         return item
                //     }),
                //     "orders": draggedItem.pallets?.length > 0 ? [] : [draggedItem.OrderID]
                // }
                data = {
                    "Orders": [
                        {
                          "OrderID": draggedItem.OrderID,
                          "Pallets": draggedItem.pallets?.map(item => {
                            item = item.Pallet_Num
                            return item
                        }),
                          "Type": "Order"
                        }
                      ],
                      "TransferZone": destinationID
                }

                console.log(data)
                
                let logElement = {
                    "SourceID": sourceID,
                    "Order": draggedItem.OrderID,
                    "Client": draggedItem?.Customer_Name?.trim(),
                    "DestinationID": destinationID,
                    "Pallets": [],
                    "Date": format(new Date(), 'HH:mm')
                }
                setLogData(prev => [...prev, logElement])

            } else {
                //delete selected pallets from source

                // divide selected and unselected pallets in the source order
                let updatedSource = draggedItem.pallets.filter(e => !selectedPallets.find(item => item.Pallet_Num === e.Pallet_Num))
                let updatedDest = draggedItem.pallets.filter(e => selectedPallets.find(item => item.Pallet_Num === e.Pallet_Num))

                let editDefault = [...palletList.defaultArray.filter(item => !selectedPallets.find(e => e.Pallet_Num === item.Pallet_Num))]
                    .concat(selectedPallets.map(item => {
                        item.PalletTransferZone = destinationID
                        return item
                    }))
                if (found) {

                    let newSourceOrder = {
                        ...draggedItem,
                        pallets: updatedSource
                    }

                    let indexOfFound = newDestOrders.indexOf(found)
                    const [concatedPallets] = newDestOrders.splice(indexOfFound, 1)
                    concatedPallets.pallets = [
                        ...concatedPallets.pallets,
                        ...updatedDest
                    ]

                    newSourceOrders.splice(sourceIndex, 0, newSourceOrder)
                    newDestOrders.splice(destIndex, 0, concatedPallets)

                    newArr = newArr.map(item => {
                        if (item.PalletTransferZone === sourceID) {
                            item.OrderPallets = newSourceOrders
                        } else if (item.PalletTransferZone === destinationID) {
                            item.OrderPallets = newDestOrders
                        }
                        return item
                    })
                    // data = {
                    //     "distrOrder": destIndex,
                    //     "transferZone": destinationID,
                    //     "pallets": concatedPallets.pallets.map(item => {
                    //         item = item.Pallet_Num
                    //         return item
                    //     }),
                    //     "orders": []
                    // }
                    data = {
                        "Orders": [
                            {
                              "OrderID": draggedItem.OrderID,
                              "Pallets": concatedPallets.pallets.map(item => {
                                item = item.Pallet_Num
                                return item
                            }),
                              "Type": "Order"
                            }
                          ],
                          "TransferZone": destinationID
                    }
                    console.log(draggedItem)
                } else {
                    console.log(draggedItem)

                    let newSourceOrder = {
                        ...draggedItem,
                        pallets: updatedSource
                    }
                    let newDestOrder = {
                        ...draggedItem,
                        pallets: updatedDest
                    }
                    newSourceOrders.splice(sourceIndex, 0, newSourceOrder)
                    newDestOrders.splice(destIndex, 0, newDestOrder)

                    newArr = newArr.map(item => {
                        if (item.PalletTransferZone === sourceID) {
                            item.OrderPallets = newSourceOrders
                        } else if (item.PalletTransferZone === destinationID) {
                            item.OrderPallets = newDestOrders
                        }
                        return item
                    })

                    // data = {
                    //     "distrOrder": destIndex,
                    //     "transferZone": destinationID,
                    //     "pallets": newDestOrder.pallets.map(item => {
                    //         item = item.Pallet_Num
                    //         return item
                    //     }),
                    //     "orders": []
                    // }
                    data = {
                        "Orders": [
                            {
                              "OrderID": draggedItem.OrderID,
                              "Pallets": newDestOrder.pallets.map(item => {
                                item = item.Pallet_Num
                                return item
                            }),
                              "Type": "Order"
                            }
                          ],
                          "TransferZone": destinationID
                    }
                    
                    // newDestOrders.splice(destIndex, 0, draggedItem)
                    console.log(updatedSource)
                    console.log(updatedDest)
                }
                setPalletList({
                    ...palletList,
                    defaultArray: editDefault,
                    current: newArr
                })
                console.log(editDefault)
                let logElement = {
                    "SourceID": sourceID,
                    "Order": draggedItem.OrderID,
                    "Client": draggedItem?.Customer_Name?.trim(),
                    "DestinationID": destinationID,
                    "Pallets": updatedDest,
                    "Date": format(new Date(), 'HH:mm')
                }
                setLogData(prev => [...prev, logElement])
            }
            patchPallet(data)
            // clear selected pallets list after drop
            setSelectedPallets([])
        }
        // console.log(sourceOrderList)
        // console.log(destenationOrderList)
        // console.log(dragID)
        // console.log(sourceID)
        // console.log(destinationID)
    }


    useEffect(() => {
        getDeliveryCodes();
        getCars();
    }, [])

    useEffect(() => {
        if (!date) return
        dataPalletCollection(date)
    }, [date])

    useEffect(() => {
        let data = JSON.stringify(logData)
        sessionStorage.setItem('log', data)
    }, [logData])

    useEffect(() => {
        if (!palletList.defaultArray) return
        pageFilter(searchInput);
    }, [deliveryID, selectedDistricts])


    const selectNewKav = (target) => {
        let targetInt = +target
        const found = palletList.current?.find(item => item.PalletTransferZone === targetInt);
        if (found) return
        let newArr = [...palletList.current]
        let currentObj = {
            NetWeight: 0,
            OrderPallets: [],
            PalletTransferZone: targetInt
        }
        console.log(palletList.current)
        let index = () => {
            let indexFinal = 0
            palletList.current.forEach((item, index, arr) => {
                indexFinal = index
                let curSort = transportAreaDict[item.PalletTransferZone]?.Sort
                if (!curSort){
                    curSort = 0
                }
                if(curSort >= transportAreaDict[targetInt].Sort) {
                    console.log('asdasdasdas')
                    indexFinal++
                    arr.length = index + 1
                }
            })
            return indexFinal
        }
        console.log(index())
        newArr.splice(index(), 0, currentObj)

        setPalletList({
            ...palletList,
            current: newArr
        })
    }

    const collectSelectedDistricts = (item, clear) => {
        if (clear) {
            setSelectedDistricts([])
            dispatch({ type: SELECT_MARKETING_AREA, payload: [] })
            return
        }
        let newArr = [...selectedDistricts]
        let flag = selectedDistricts.find(e => e.SecondCode === item.SecondCode)
        if (!flag) {
            newArr = [...newArr, item]
        } else {
            let elemIndex = newArr.indexOf(item)
            newArr.splice(elemIndex, 1)

        }
        setSelectedDistricts(newArr);
        dispatch({ type: SELECT_MARKETING_AREA, payload: newArr })

    }
    const patchDriver = async (id) => {  
        try {
            
            let res = await axios({
                method: 'PUT',
                url: `${TRANSPORT_AREAS}${selectedKav}`,
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    CarId: id
                }
            })
            console.log(res)
            transportAreaDict[selectedKav].CarId = id
            setIsOpenChangeDriver(false)
        } catch(err) {
            console.log(err.response)
        }
    }
    // console.log(palletList)
    const statisticTableP = {
        carList: carsInfo?.list,
        carsDict: carsDict,
        palletList: palletList,
        transportAreaDict: transportAreaDict,
        ignorDirection: ignorDirection
    }
    const selectDriverP = {
        kav: selectedKav,
        saveFunc: patchDriver,
        list: carsInfo?.list
    }

    return (
        <MainWrapper>
            {
                isOpenChangeDriver &&
                    <ColumModal 
                        title='שינוי נהג'
                        controlFunc={setIsOpenChangeDriver}
                        Children={SelectDriver}
                        childrenProps={selectDriverP}
                    />
            }
            {
                isOpenLog &&
                <Logger
                    controlFunc={setIsOpenLog}
                    data={logData}
                    transportAreaDict={transportAreaDict}
                />
            }
            {
                isOpenDashboard &&
                <DashboardTruck
                    controllFunc={setIsOpenDeshboard}
                    palletList={palletList}
                    transportAreaDict={transportAreaDict}
                    cars={carsDict}
                    ignorDirection={ignorDirection}
                />
            }

            {
                isOpenTable &&
                <StatisticModalLayout
                    controllFunc={setIsOpenTable}
                    Children={StatisticTable}
                    childrenProps={statisticTableP}
                    
                />
            }
            <HeadWrapper>
                <FilterIndicator
                    onClick={() => { setIsOpenLog(true) }}
                >
                    <ButtonWrapper >
                        <LogIcon />
                    </ButtonWrapper>
                </FilterIndicator>
                <FilterIndicator
                    onClick={() => { setIsOpenDeshboard(true) }}
                >
                    <ButtonWrapper >
                        <ChartIcon />
                    </ButtonWrapper>
                </FilterIndicator>
                <FilterIndicator
                    onClick={() => { setIsOpenTable(true) }}
                >
                    <ButtonWrapper >
                        <InvoiceIcon />
                    </ButtonWrapper>
                </FilterIndicator>
                <DatePickerModule
                    setSelectedDateStart={setDate}
                />
                <SelectFromTheList
                    lable='סבב הפצה - הכל'
                    list={deliveryCodeList}
                    size='large'
                    itemKey='Delivery_Code'
                    fieldValue='Delivery_Code'
                    dispalyFieldName='Delivery_Description'
                    selectFunction={selectDelivery}
                    selectedValue={deliveryID}
                />
                <MultiSelect
                    data={marketAreaList}
                    controllFunc={collectSelectedDistricts}
                    selectedDistricts={selectedDistricts}
                />
                <SearchInput
                    input={searchInput}
                    setInput={pageSearch}
                />
                <FilterIndicator onMouseEnter={() => { setIsOpenAdd(true) }}>
                    <ButtonWrapper >
                        <PlusIcon />
                    </ButtonWrapper>
                    <FilterBodyWrapper isOpen={isOpenAdd} onMouseLeave={() => { setIsOpenAdd(false) }}>
                        <img src={Delivery} alt='logo' />
                        <FilterBodyItemContainer>
                            <SelectFromTheList
                                lable='קו הפצה - הכל'
                                list={transportAreas?.array.sort((a, b) => a.Sort - b.Sort)}
                                size='full'
                                itemKey='AreaID'
                                fieldValue='AreaID'
                                dispalyFieldName='AreaDesc'
                                selectFunction={selectNewKav}
                            />
                        </FilterBodyItemContainer>
                    </FilterBodyWrapper>
                </FilterIndicator>
            </HeadWrapper>
            <Main>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Wrapper>
                        <Inner>
                            {
                                palletList?.current?.map((item, index) => {
                                    if (item.PalletTransferZone === 0) return ''
                                    return <MainGroupContainer
                                        collectDragPallets={collectDragPallets}
                                        key={item.PalletTransferZone}
                                        {...item}
                                        item={item}
                                        index={index}
                                        transportAreaDict={transportAreaDict}
                                        cars={carsDict}
                                        palletList={palletList}
                                        statusListAvalibleToCollectInPallet={statusListAvalibleToCollectInPallet}
                                        changeDriver={changeDriver}
                                    />
                                }
                                )
                            }
                        </Inner>
                    </Wrapper>
                    {
                        palletList?.current && palletList?.current[0]?.PalletTransferZone === 0 ?
                            <StaticColumnWrapper>
                                <MainGroupContainer
                                    collectDragPallets={collectDragPallets}
                                    key={0}
                                    {...palletList?.current[0]}
                                    item={palletList?.current[0]}
                                    reversed={true}
                                    transportAreaDict={transportAreaDict}
                                    cars={carsDict}
                                    palletList={palletList}
                                    statusListAvalibleToCollectInPallet={statusListAvalibleToCollectInPallet}
                                />
                            </StaticColumnWrapper>
                            : ''
                    }
                </DragDropContext>
            </Main>
        </MainWrapper>


    );
}
const ZeroElementsWrapper = styled.div`
    max-height: 100%;
`
export default TrucksMain;