import React, {useState} from 'react'
import styled from 'styled-components'
import { EditIcon, PackageIcon, PalletIcon, ShopBagIcon } from '../../../../../generic/icons/Icons'
import { IArizotLine } from '../../../../../modules/API/Packages/models/arizotLine'
import { IRemarkData } from './ArizotViewMain'
import RowInput from '../../../../../generic/inputs/RowInput'

interface IProps extends IArizotLine {
    openRemark?: (data: IRemarkData) => void, 
    isActive?: boolean,
    updateInput?: (value: number, id: string | number) => void,
    id: string,
    isForTeuda?: boolean,
    QtyForTeuda?: number
}
interface IButtonWrapper {
    isRemark?: boolean
}
interface IRawInner {
    isArizot?: boolean
}
function ArizotItem({
    Description, Remark, openRemark, Pack_Pallet, 
    Qty, PackID, isActive, updateInput, id, isForTeuda, QtyForTeuda
}: IProps) {
    const remarkData = {
        title: Description?.trim(),
        body: Remark?.trim(),
        id: id
    }
    const handleRemarkClick = (e: React.MouseEvent) => {
        if(!openRemark) return
        e.preventDefault();
        if(!Remark?.trim()) return
        openRemark(remarkData)
    }
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!updateInput) return
        let toNum = +e.target.value
        updateInput(toNum, id)
    }
    const isArizot = Pack_Pallet === 'א' ? <PackageIcon /> : <PalletIcon />
    const desc = 
        Description && Description?.trim().length > 17 ? 
        '...' + Description?.trim().slice(0, 17) : Description?.trim()
  return (
    <MainWrapper>
        <ContentWrapper>
            <RawWrapper>
                <RawInner>
                    <RowInput 
                        defValue={Qty}
                        handleInput={handleInput}
                        isDisabled={!isActive}
                        icon={isArizot}
                    />
                </RawInner>
                <RawInner isArizot={Pack_Pallet === 'א'}>
                    {desc}
                    {isArizot}
                </RawInner>
            </RawWrapper>
        </ContentWrapper>
            
            {
                isForTeuda ? 
                    <SideCircle>
                        {Qty}
                    </SideCircle> :
                        <ButtonWrapper 
                            isRemark={!!Remark?.trim()}
                            onClick={handleRemarkClick}
                        >
                            <EditIcon />
                        </ButtonWrapper>
            }
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 4em;
    padding: 0 0.5em;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-radius: 30px;
    background: #fff;
    color: #838484;
    box-shadow:  4px 4px 10px #c1c1c1,
             -7px -7px 10px #ffffff;


`
const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: blue; */
    display: flex;
    flex-flow: column;
`
const RawWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.5em;
    gap: 1em;
    @media screen and (max-width: 400px) {
        justify-content: space-between;
    }
`
const RawInner = styled.div<IRawInner>`
    display: flex;
    gap: 0.5em;
    width: 10em;
    justify-content: flex-end;
    i {
        color: ${props => props.isArizot ? "#A1E396" : '#7ab0ab'}
    }
`
const ButtonWrapper = styled.div<IButtonWrapper>`
    width: 4em;
    height: 100%;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in;
    cursor: ${props => props.isRemark ? 'pointer' : 'default'};
    i {
        display: ${props => props.isRemark ? 'block' : 'none'};
        color: #7ab0ab

    }
    :hover i {
        color: tomato;
        transform: scale(1.2);
    }
`

const SideCircle = styled.div`
    position: absolute;
    left: 0.4em;
    top: calc(50% - 1.5em);
    width: 3em;
    height: 3em;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));
    filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));
     
    ::after {
        position: absolute;
        top: -0.5em;
        right: -2em;
        content: 'הזמנה';
        padding: 0.1em 0.5em;
        background: #fff;
        border-radius: 30px;
        font-size: 0.9em;
    }
`

export default ArizotItem