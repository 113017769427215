import React, {useState, useMemo} from 'react'
import { IExistCustomersOrders } from '../../../../../modules/API/Orders/models/existCustomersOrders'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import CustomerItem from './CustomerItem'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import Title from '../../../../../generic/Title/Title'

type Props = {
    data: IExistCustomersOrders[];
    handleClick: (item: IExistCustomersOrders) => void;
}

const CustomersOrdered = ({data, handleClick}: Props) => {
    const [search, setSearch] = useState<string>("");
    const render = useMemo(() => {
        if(!data) return []
        return data.filter((item: IExistCustomersOrders) => 
          item.Customer_Name?.trim().includes(search) 
        )
    }, [data, search])
  return (
    <BodyScrollContainer>
        <Title label={'רשימת לקוחות עם מספר הזמנות'} color={'#297F76'} />
        <ControlSearchPannelLayout>
            <SearchInput 
                input={search}
                setInput={setSearch}
            />
        </ControlSearchPannelLayout>
        <TitlesList 
            list={render}
            uniqueKey={'CustomerID'}
            noPadding={false}
            Element={CustomerItem}
            additionalData={{handleClick: handleClick}}
        />
    </BodyScrollContainer>
  )
}

export default CustomersOrdered