import { IOrderRehesh, IOrderReheshDetailes } from "../../../../../../modules/API/AzmanotRehesh/models/order";
import { ForcastProductItem } from "../../../../../../modules/API/Forcasts/models/forcastProductitem";

export const convertFromForecastToOrderRehesh = (
  forecast: ForcastProductItem,
  warehouseID: number,
  date: string,
  customerID: number
  ): IOrderReheshDetailes => {

  return {
    GeneralProductID: forecast.GeneralProductID,
    GrowerID: forecast.GrowerID,
    GrowerName: forecast.GrowerName,
    OrderID: 0,
    OrderPacks: 0,
    OrderWeight: 0,
    Order_Pallets: 0,
    PackID: forecast.PackID,
    Pack_Name: forecast.Pack_Name,
    ProductDesc: forecast.ProductDesc,
    ProductID: forecast.ProductID,
    RecID: 0,
    SizeID: forecast.SizeID,
    SupplyQty: 0,
    SupplyWeight: 0,
    Supply_Pallets: 0,
    VarietyDesc: forecast.VarietyDesc,
    VarietyID: forecast.VarietyID,
    Status: 0,
    Remarks: "",
    Delivery_Date: date,
    WarehouseID: warehouseID,
    LineType: 2,
    Sent_SMS: false,
    CustomerID: customerID
  }
}