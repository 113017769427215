import React, {useState, useMemo} from 'react'
import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import styled from 'styled-components';
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead';
import Image from '../../../../../generic/assets/cogsPNG.png'
import CreateEntrieItem from '../Create/CreateEntrieItem';
import CircleNumericInput from '../../../../../generic/inputs/CircleNumericInput';
import ControlButton from '../../../../../generic/buttons/ControlButton';
import SearchInput from '../../../../../generic/inputs/inputSearch';

type Props = {
    item: IEntriesItem;
    saveFunc: (item: IEntriesItem, qty: number, restItem: IEntriesItem) => void
}

const DivideModal = ({
    item, saveFunc
}: Props) => {
    const [qty, setQty] = useState(0)
    console.log(qty)
    const render = useMemo(() => {
        let grossPerUnit = item.Gross / item.AmountPack
        let restGross = Math.round(item.Gross - (grossPerUnit * (qty)))
        let restPacks = item.AmountPack - qty
        return {
            rest: {
                ...item,
                Gross: restGross,
                AmountPack: restPacks,
                isSelected: false

            },
            current: {
                ...item,
                Gross: item.Gross - restGross,
                AmountPack: item.AmountPack - restPacks,
                isSelected: false
            }
        }
    }, [qty, item])
    const isAlowtoSave = qty > 0 && (item.AmountPack - qty > 0)
    const handleSave = () => {
        saveFunc(item, qty, render.rest)
    }
  return (
    <MainWrapper>
        <ImageComponentHead
            img={Image}
            large
        />
        <ContentWrapper>

            <MainContent>
                {/* <SideWrapper>
                    <CircleNumericInput 
                        defaultValue={qty} 
                        changeFunc={setQty}
                        />
                </SideWrapper> */}
                <SearchInput 
                    input={qty+""}
                    type={'number'}
                    setInput={setQty}
                    iconHide={true}
                    value={'כמות לפיצול'}
                />
                <CreateEntrieItem
                    {...render.rest}
                    item={item}
                    index={0}
                    
                    />
                    {
                        qty > 0 &&
                            <CreateEntrieItem
                                {...render.current}
                                item={item}
                                index={0}
                                
                            />
                    }
            </MainContent>
            {
                isAlowtoSave &&
                <ControlButton
                    lable={'שמור'}
                    handleClick={handleSave}
                />
            }
        </ContentWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    /* gap: 1em; */
    align-items: center;
    height: 100%;
`
const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;


`
const MainContent = styled.div`
    position: relative;
    display: flex;
    width: 90%;
    /* min-height: 100em; */
    flex-flow: column;
    justify-content: flex-start;
    gap: 0.5em;
    /* background: red; */
`

const SideWrapper = styled.div`
    position: absolute;
    top: calc(50% - 1.5em);
    left: 0.5em;
    z-index: 2;
`
export default DivideModal