import { useRef } from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../../../../../../generic/hooks/useDetectOutsideClick'
import ArizotClientItem from './arizotClientItem'


const Wrapper = styled.div`
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: -7em;
    height: 7em;
    overflow-y: auto;
    /* display: flex; */
    flex-flow: column;
    border-radius:30px;
    padding: 0 1em;
    transform: translateY(-0.2em);
    border-top: 1px solid rgba(0,0,0,0.2);
    z-index: 3;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
    ::-webkit-scrollbar {
        width: 0;
    }

`


export default function ArizotClientList({sender, setInputClient, setWrapperClientStatus, wrapperClientStatus, setClientIdRecip}){
    const ref = useRef()
    useDetectOutsideClick(ref, () => {setWrapperClientStatus(false)})
    let statusToggle = wrapperClientStatus ? 'flex' : 'none'
    return(
        <Wrapper ref={ref} style={{display: statusToggle}}>
            {sender.map(item => <ArizotClientItem key={item.ClientID} {...item} setInputClient={setInputClient} setWrapperClientStatus={setWrapperClientStatus} setClientIdRecip={setClientIdRecip}/>)}
        </Wrapper>
    )
}