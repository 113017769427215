import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick'


type Props = {
    controllFunc: (isOpen: boolean) => void,
    Children: any,
    childrenProps: object
}

interface IMainContainer {
    isShow: boolean
}
const MainContainer = styled.div<IMainContainer>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
    background: rgba(239,243,244, 0.5);
    transform: ${props => props.isShow ? 'translateX(0)': 'translateX(-100%)'} ;
    transition: 0.5s;
    z-index: 3;

`
const MainContentWrapper = styled.div`
    width: 70%;
    height: 95%;
    background: rgb(239,243,244);
    border-radius: 30px;
    padding: 0.5em;

`
const StatisticModalLayout = ({controllFunc, Children, childrenProps}: Props) => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null)
    // console.log(childrenProps)
    const closeFunc = ():void => {
        setIsShow(false);
        setTimeout(() => {
            controllFunc(false);
        }, 500)
    }
    useDetectOutsideClick(contentRef, closeFunc)
    useEffect(() => {
        setIsShow(true)
    }, [])
  return (
    <MainContainer isShow={isShow}>
        <MainContentWrapper ref={contentRef}>
            {
                Children &&
                    <Children {...childrenProps} />
            }
        </MainContentWrapper>
    </MainContainer>
  )
}
export default StatisticModalLayout