import { format } from "date-fns";
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { PackageIcon, TimeIcon } from "../../../../../../generic/icons/Icons";
import selectOnFocus from "../../../../../../modules/autoselect";

interface TimeInputProps {
  onChange: (time: string, valid: boolean) => void;
}
export const initialTime = () => {
    const time = new Date();
    const formated = format(time, 'HH:mm')
    return formated
}
const TimeInput: React.FC<TimeInputProps> = ({ onChange }) => {
  const [time, setTime] = useState(() => initialTime());
  const [isValid, setIsValid] = useState(true);
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputTime = event.target.value;
    if (inputTime.length <= 2) {
      setTime(inputTime);
      setIsValid(true);
    } else if (inputTime.length === 3) {
      const separatedTime = `${inputTime.slice(0, 2)}:${inputTime.slice(2)}`;
      setTime(separatedTime);
    } else if (inputTime.length === 5) {
      
      if (inputTime.match(timeRegex)) {
        setTime(inputTime);
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } 
  };
  useEffect(() => {
    if(time.match(timeRegex)) {
        setIsValid(true)
        onChange(time, true)    
    } else {
        setIsValid(false)
        onChange(time, false)
    }
  }, [time])
  return (
    <InputWrapper>
      <IconWrapper>
        <TimeIcon />
      </IconWrapper>
      <Input
        type="text"
        value={time}
        onChange={handleChange}
        placeholder="hh:mm"
        isValid={isValid}
        onFocus={selectOnFocus}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
  width: 9em;
  height: 3.5em;
  border-radius: 30px;
`;
const IconWrapper = styled.div`
  position: absolute;
  width: 3em;
  height: 3.5em;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #838484;
  }
`;
const Input = styled.input<{ isValid: boolean }>`
  width: 100%;
  height: 100%;
  font-size: 1.1em;
  border-radius: 30px;
  border: ${(props) =>
    props.isValid ? "2px solid #eff3f4" : "2px solid tomato"};
  padding: 0.5em 1em;
  letter-spacing: 0.5px;
  outline: none;
  -webkit-appearance: none;
  color: #8a97a5;
  direction: rtl;
  background: #eff3f4;
  :focus {
    animation: 2s pulse infinite;
  }
  @keyframes pulse {
    0% {
      border: 2px solid #eff3f4;
    }
    50% {
      border: 2px solid #80e081;
    }
  }
`;
export default TimeInput;
