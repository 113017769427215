
export const statusAzmanotList = [
    {id: 0, Desc: 'פתוחה', color: '#fdece0'},
    {id: 1, Desc: 'ליקוט', color: '#f9e1dc'},
    {id: 2, Desc: 'הזמנה נבדקה', color: '#ced3ff'},
    {id: 3, Desc: 'סיום ליקוט', color: '#deecea'},
    {id: 7, Desc: 'השלמות', color: '#ced3ff'},
    {id: 4, Desc: 'מוכנה למשלוח', color: '#ffface'},
    {id: 5, Desc: 'מוכנה לליקוט', color: '#ced3ff'},
    {id: 9, Desc: 'סגורה', color: '#ced3ff'},
    {id: -1, Desc: 'DELETED', color: '#ced3ff'},
]
export const statusArizotList = [
    {id: 0, Desc: 'פתוחה', color: '#fdece0'},
    {id: 1, Desc: 'מבוטל', color: '#f9e1dc'},
    {id: 7, Desc: 'בוגע', color: '#ffface'},
    {id: 9, Desc: 'סגורה', color: '#ced3ff'},
    {id: -1, Desc: 'DELETED', color: '#ced3ff'},
]

export const statusConverter = (Status) => {
    const filtered = statusAzmanotList.filter(item => item.id === Status)
    return {
        status: filtered[0]?.Desc,
        color: filtered[0]?.color
    }
}

export const statusConverterArizot = (Status) => {
    const filtered = statusArizotList.filter(item => item.id === Status)
    return {
        status: filtered[0]?.Desc,
        color: filtered[0]?.color
    }
}