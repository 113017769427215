import React, {useState, useMemo} from 'react'
import { IGrowerItem } from '../../../../../modules/API/Defaults/models/growers'
import styled from 'styled-components';
import Image from '../../../../../generic/assets/delivery.png'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList';
import SelectFromTheList from '../../../../../generic/customSearchComponents/SelectFromTheList';
import { statusOrderReheshArr } from '../helpers/statuses';
export interface IGrowerFilter {
    list: IGrowerItem[];
    selectedGrower: IGrowerItem | null;
    setSelectedGrower: (item: IGrowerItem | null) => void;
    selectedStatus: number | null;
    setSelectedStatus: (item: number) => void;
}

function GrowerFilter({
    list, selectedGrower, setSelectedGrower, selectedStatus, setSelectedStatus
}: IGrowerFilter) {
    const [input, setInput] = useState<string>(selectedGrower?.GrowerName.trim() || '')
    const [isOpenList, setIsOpenList] = useState<boolean>(false)
    console.log(statusOrderReheshArr)
    const renderList = useMemo(() => {
        return list.filter((item) => {
            return item.GrowerName.trim().includes(input)
        })
    }, [list, input])
    const handleSearch = (e: string) => {
        setIsOpenList(true)
        setInput(e)
        if(!e) {
            console.log(null)
            setSelectedGrower(null)
        }
    }
    const handleSelect = (item: IGrowerItem) => {
        setSelectedGrower(item)
        setInput(item.GrowerName.trim())
        setIsOpenList(false)
    }
    const handleSelectStatus = (item: number) => {
        setSelectedStatus(+item)
    }
return (
    <MainWrapper>
        <Img src={Image}  />
        <SelectFromTheList 
            selectedValue={selectedStatus}
            lable={'בחר סטטוס'}
            list={statusOrderReheshArr}
            dispalyFieldName='value'
            fieldValue='id'
            itemKey='id'
            selectFunction={handleSelectStatus}
            size={'full'}
        />
        <SearchFromTheList 
            isOpenList={isOpenList}
            input={input}
            placeHolder={'חיפוש מגדל'}
            list={renderList}
            itemDisplayName='GrowerName'
            isNeedFullObject={true}
            nameIdElement='GrowerID'
            searchFunc={handleSearch}
            selectFunc={handleSelect}
            size={'full'}
        />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    background: #fff;
    padding: 0.5em 1em;
    border-radius: 20px;
`
const Img = styled.img`
    max-width: 50px;
`
export default GrowerFilter