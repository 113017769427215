import React, { useState, useRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useDetectOutsideClick } from '../../../../../generic/hooks/useDetectOutsideClick';
import { CloseIcon } from '../../../../../generic/icons/Icons';

interface Option {
  value: number;
  label: string;
  
}

interface CustomSelectProps {
  options: Option[];
  placeholder?: string;
  defaultValue?: number;
  onSelect: (value: number | string) => void;
  icon: React.ReactNode,
  title: string
}
const MainWrapper = styled.div<{isDisable?: boolean}>`
  padding: 1em;
  pointer-events: ${props => props.isDisable ? 'none': 'auto'};
  opacity: ${props => props.isDisable ? '0.5': '1'};
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 0.5em;
`
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 1em;
  color: #7AB0AB;

`
const Dropdown = styled.div`
  position: relative;
  font-family: sans-serif;
  width: 20em;
@media screen and (max-width: 500px) {
    width: 17em;
  }
`;

const DropdownTrigger = styled.div<{isOpen?: boolean}>`
  input {
    width: 100%;
    padding: 0.5em 0.5em 0.5em 2em;
    border: 1px solid #297F76;
    border-radius: ${props => props.isOpen ? '10px 10px 0 0' : '10px'};
    direction: rtl;
    color: #297F76;
    &:focus {
      outline: none;
    }
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #297F76;
  border-top: none;
  border-radius: 0 0 10px 10px;
  background: white;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  
  ::-webkit-scrollbar {
    width: 0;
  
  }
`;

const OptionItem = styled.div<{ isSelected: boolean }>`
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? 'white' : '#297F76')};
  background-color: ${({ isSelected }) => (isSelected ? '#297F76' : 'white')};
  :last-child {
    border: none;
  }
  &:hover {
    background-color: #f2f2f2;
    color: #297F76;
  }
`;

const CustomSelectInput: React.FC<CustomSelectProps> = ({title, options, placeholder, defaultValue, onSelect, icon }) => {
  const isDisabled = options.length <= 1;
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleOpen = (e: React.MouseEvent<HTMLInputElement>) => {
    if(isDisabled) return;
    e.stopPropagation();
    setIsOpen(true)
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(isDisabled) return;
    setSearchTerm(e.target.value);
    if (!isOpen) setIsOpen(true); // Open the dropdown when typing
  }
  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setSearchTerm(option.label); // Set the searchTerm to the selected label
    setIsOpen(false);
    onSelect(option.value)
  };

  // Filter options based on the search term
  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpen(true);
    setSearchTerm('');
  }
  useEffect(() => {
    let res = options.find(option => option.value === defaultValue)
    setSelectedOption(res || null)
    setSearchTerm(res ? res.label : '')
  }, [options, defaultValue])

  useDetectOutsideClick(dropdownRef, () => setIsOpen(false));
  return (
    <MainWrapper ref={dropdownRef}  isDisable={isDisabled}>
      <Title>{title}</Title>
    <Dropdown >
      <DropdownTrigger 
        onClick={() => setIsOpen(!isOpen)} 
        isOpen={isOpen}
      >
        <FloatingIcon>
          {
           !isDisabled ? searchTerm.length > 0 ? <CloseIcon handler={handleClear} /> : icon: ''
          }
        </FloatingIcon>
        <input
          type="text"
          value={searchTerm}
          placeholder={placeholder}
          onChange={handleSearch}
          onClick={toggleOpen} // Prevents the dropdown from closing when clicking the input
        />
      </DropdownTrigger>
      <DropdownContent isOpen={isOpen} >
      {filteredOptions.length > 0 ? (
        
        filteredOptions.map((option) => (
          <OptionItem
            key={option.value}
            isSelected={selectedOption?.value === option.value}
            onClick={() => handleOptionClick(option)}
          >
            {option.label}
          </OptionItem>
        ))
      ) : (
        <OptionItem isSelected={false}>לא נמצא דבר</OptionItem>
      )}
      </DropdownContent>
    </Dropdown>
    </MainWrapper>

  );
};
const FloatingIcon = styled.div`
  position: absolute;
  top: calc(50% - 0.5em);
  left: 0.5em;
  color: #297F76;
  font-size: 1em;
`
export default CustomSelectInput;