const viewWindow = {
  "packageOrder_ViewWindow_title": 'הזמנה/החזרה אריזה {{defID}}',
}
export const packageOrderTranslationHE = {
  packageOrder: {
    "packageOrder_orders_title": 'הזמנה/החזרה - אריזה',
    "packageOrder_orders_search_placeholder": 'חיפוש',
    "packageOrder_modal_teuda_title": 'הזמנה אריזה: {{num}}',
    "packageOrder_modal_teuda_checkbox": 'שלח אימייל',
    "packageOrder_modal_remark_title": 'מיכל פלסטיק דולב',
    "packageOrder_modal_print_title": 'הדפסה',
    "packageOrder_modal_print_order": 'הזמנה',
    "packageOrder_modal_print_certificate": 'תעודה',
    "packageOrder_NewOrder_lable_date": 'תאריך',
    "packageOrder_NewOrder_lable_sender": 'שולח',
    "packageOrder_NewOrder_lable_receipient": 'מקבל',
    "packageOrder_NewOrder_lable_car": 'רכב',
    "packageOrder_NewOrder_lable_refference": 'אסמכתא',
    "packageOrder_NewOrder_placeholder_sender": ' בחר שולח',
    "packageOrder_NewOrder_placeholder_receipient": ' בחר מקבל',
    "packageOrder_NewOrder_placeholder_car": ' בחר רכב',
    "packageOrder_NewOrder_placeholder_refference": 'אסמכתא',
    "packageOrder_NewOrder_checkbox": 'מבקש הובלה',
    ...viewWindow
  }
}