import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[];
  
}

const BodyLayout = ({children}: Props) => {
  return (
    <MainWrapper>
      {children}
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 0;
  padding: 1em 1em 0 1em;
  border-radius: 30px;
  display: flex;
  gap: 1em;
  overflow-x: auto; /* Enable horizontal scroll */
  direction: rtl;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default BodyLayout