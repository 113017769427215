import React, {useMemo, useState, useContext} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IGrowerItem, IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList'
import { Cefi } from '../../../../../modules/API/Cefi/cefi'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import { IProductCefi, IProductResponseCefi } from '../../../../../modules/API/Cefi/models/product'
import CefiProductItem from './CefiProductItem'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_CEFI_STATE, LOAD, LOAD_END, SELECT_DATA_FOR_CEFI, SELECT_DATE_FOR_CEFI, SELECT_GROWER_FOR_CEFI } from '../../../../../../store/actions/actionsTypes'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import CefiDetailesModal from './CefiDetailesModal'
import { ICreateCefi } from '../../../../../modules/API/Cefi/models/req'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import CustomBlockLoader from '../../knisot/generic/CustomBlockLoader'


function CreateCefiMain() {
    const dispatch = useDispatch();
    const defaultState = useSelector((state: RootState) => state.cefiReducer)
    const [modal, setModal] = useContext(ContextModal);
    const history = useHistory();
    const [date, setDate] = useState<string>("");
    const [searchProduct, setSearchProduct] = useState<string>('');
    const [searchGrower, setSearchGrower] = useState<string>(defaultState.grower?.GrowerName?.trim() || '');
    const [selectedGrower, setSelectedGrower] = useState<IGrowerItem | null>(() => defaultState.grower || null);
    const [isOpenGrowerList, setIsOpenGrowerList] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [isOpenCefiDetailsModal, setIsOpenCefiDetailsModal] = useState<boolean>(false);
    
    const handleCefiDetailsModal = () => {
        setIsOpenCefiDetailsModal(!isOpenCefiDetailsModal);
    } 
    const {
        data: growers,
        isLoading: isLoadingGrowers,
    } = useDataFetcher<IGrowerNodeGetResponse>(
        Defaults.getGrowerList, 'asd'
    )
    const {
        data: productsRehesh,
        isLoading: isLoadingProductsRehesh,
        setData: setProductsRehesh
    } = useDataFetcher<IProductResponseCefi>(
        Cefi.getProducts, `${date}/${selectedGrower?.GrowerID}`
    )
    console.log(growers?.growers)
   
    const handleSelectGrower = (grower: IGrowerItem) => {
        setSelectedGrower(grower);
        setIsOpenGrowerList(false);
        setSearchGrower(grower.GrowerName?.trim());
        dispatch({type: SELECT_GROWER_FOR_CEFI, payload: grower})
        dispatch({type: SELECT_DATA_FOR_CEFI, payload: null})
    }
    const handleSearchGrower = (e:string) => {
        setSearchGrower(e);
        setIsOpenGrowerList(true);
        if(!e) {
            setSelectedGrower(null);
            dispatch({type: SELECT_DATA_FOR_CEFI, payload: null})

        }
    }
    const renderGrowers = useMemo(() => {
        if(!growers?.growers) return [];
        if(growers.growers.length === 1){
            setSelectedGrower(growers.growers[0]);
            setSearchGrower(growers.growers[0].GrowerName?.trim());
            setIsDisabled(true)
            return []
        };
        return growers.growers.filter((grower) => {
            if(!searchGrower) return true;
            return grower.GrowerName?.trim()
                .toLowerCase().includes(searchGrower.toLowerCase());
        })
    }, [growers?.growers, searchGrower])

    const renderProducts = useMemo(() => {
        if(!productsRehesh?.recommendations) return [];
        const list: IProductCefi[] = defaultState?.products || productsRehesh.recommendations;
        return list.filter((product) => {
            if(!searchProduct) return true;
            return product.ProductDesc?.trim()
                .toLowerCase().includes(searchProduct.toLowerCase());
        })
    }, [
        productsRehesh?.recommendations,
        searchProduct,
        defaultState?.products
    ])

    const handleChangeProductsRehesh = (value: number, recID: number, type: "pallets" | "packs" | "weight") => {
        if(!productsRehesh?.recommendations) return;
        const newProducts = productsRehesh.recommendations.map((product) => {
            if(product.RecID === recID){
                if(type === "pallets"){
                    product.Order_Pallets = value;
                } else if(type === "packs"){
                    product.OrderPacks = value;
                } else if(type === "weight"){
                    product.OrderWeight = value;
                }
            }
            return product;
        })
        const prodData = {
            ...productsRehesh,
            recommendations: newProducts
        }
        dispatch({type: SELECT_DATA_FOR_CEFI, payload: newProducts})
        setProductsRehesh(prodData)
    }
    const createCefi = async (ref: number, curNum: number, driverName: string) => {
        if(!selectedGrower) return;
        const products = productsRehesh?.recommendations || [];
        dispatch({type: LOAD})
        
        try {
            const data: ICreateCefi = {
                GrowerID: selectedGrower?.GrowerID,
                Reference: ref,
                EntryDate: date,
                LeadName: driverName,
                OrderID: 12,
                TransportID: curNum,
                Remark: '',
                Products: products.map((product) => {
                    return {
                        ProductID: product.ProductID,
                        VarietyID: product.VarietyID,
                        SizeID: product.SizeID,
                        Pallets: product.Order_Pallets,
                        Packs: product.OrderPacks,
                        Weight: product.OrderWeight,
                        Remark: product.Remarks,
        
                    }
                })
            }

            await Cefi.createCefiOrder(data)
            dispatch({type: CLEAR_CEFI_STATE})
            history.push(LINKS.cefi.cefi.mainURL)
            setModal({
                open: true,
                value: `נוצר בהצלחה`,
                mood: true
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
        } catch (err) {
            setModal({
                open: true,
                value: `משהו השתבש`,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
        } finally {      
            dispatch({type: LOAD_END})
        }
    }
    const productProps = {
        editProduct: handleChangeProductsRehesh
    }
    const cefiDetailsModalProps = {
        saveFunc: createCefi
    }
    const handleDate = (e: object) => {
        dispatch({type: SELECT_DATE_FOR_CEFI, payload: e})
      }
  return (
    <OrderlikeLayout
        label='תעודה למחסן'
    >
        {
            isOpenCefiDetailsModal &&
            <ColumModal 
                title='פרטי תעודה'
                childrenProps={cefiDetailsModalProps}
                controlFunc={handleCefiDetailsModal}
                Children={CefiDetailesModal}
            />
        }
        <ControlSearchPannelLayout>
            {/* <SearchInput 
                input={searchProduct}
                value={'חפש מוצר'}
                setInput={setSearchProduct}
                size={"full"}
            /> */}
            <SearchFromTheList 
                input={searchGrower}
                placeHolder={'חפש ספק'}
                searchFunc={handleSearchGrower}
                list={renderGrowers}
                itemDisplayName={"GrowerName"}
                selectFunc={handleSelectGrower}
                nameIdElement={'GrowerID'}
                isOpenList={isOpenGrowerList}
                disabled={isDisabled}
                isNeedFullObject
                size={'full'}
            />
            <DatePickerModule 
                setSelectedDateStart={setDate}
                setSelectedDateEnd={false}
                multyDate={false}
                isNeedWithoutFormated={true}
                saveWithoutFormat={handleDate}
                disabled={false}
                selected={defaultState?.date? new Date(defaultState.date) : false}
                ignorBrod
                defEndDate={false}
            />
        </ControlSearchPannelLayout>
        <BodyScrollContainer>
        {
            isLoadingProductsRehesh ?
                <CustomBlockLoader /> :
                    <TitlesList 
                        list={renderProducts}
                        uniqueKey={'RecID'}
                        Element={CefiProductItem}
                        noPadding={false}
                        additionalData={productProps}
                    />
        }
        </BodyScrollContainer>
        {
            productsRehesh?.recommendations && 
            productsRehesh?.recommendations?.length > 0 &&
            selectedGrower &&
                <ControlButton 
                    lable={'המשך'}
                    handleClick={handleCefiDetailsModal}
                />
        }
    </OrderlikeLayout>
  )
}

export default CreateCefiMain