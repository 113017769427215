import { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import PresetsList from './presetsList'
import { format } from "date-fns";
import { useEffect } from "react";
import { getAzmanot } from '../../../../../../modules/requests/getAzmanot'
import { useDispatch, useSelector } from "react-redux";
import { LOAD, LOAD_END, LOGOUT } from '../../../../../../../store/actions/actionsTypes'
import { GET_ORDER } from '../../../../../../modules/API/settings'
import { addIDForTheList } from "../../../../../../generic/utils/addUIDForTheList";



const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.9);
    z-index: 99;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Container = styled.div`
    height: 400px;
    width: 80%;
    background: #ffff;
    padding: 1em 0.5em 1em;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 9px;
    position: relative;
    border: 2px solid #ced3ff;
    -webkit-box-shadow: 6px 6px 2px -3px #80b3ae; 
    box-shadow: 6px 6px 2px -3px #80b3ae;
    ::after{
        content: '';
        width: 5em;
        height: 5em;
        background: #ced3ff;
        position: absolute;
        bottom: -2.5em;
        left: -1.5em;
        z-index: 2;
        border-radius: 50%;
        -webkit-box-shadow: 6px -5px 11px -8px rgba(0,0,0,0.64); 
        box-shadow: 6px -5px 11px -8px rgba(0,0,0,0.64);
       
    }
    ::before {
        content: '';
        width: 3em;
        height: 3em;
        background: #ced3ff;
        position: absolute;
        bottom: 0.3em;
        left: 1.2em;
        z-index: 2;
        border-radius: 50%;
        
    }
    
    @media screen and (min-width: 475px) {
        width: 375px;
    }
    
`
const ItemWrapper = styled.div`
    width: 60%;
    height: 2.5em;
    /* background: blueviolet; */
    cursor: pointer;
    margin-bottom: 1em;
    position: relative;
    /* z-index: 0; */
    .datepicker-preset {
        min-height: 2.25em;
        width: 100%;
        /* opacity: 0.5; */
        border-radius: 5px;
        border: none;
        text-align: right;
        padding: 0 0.5em;
        font-size: 1.1em;
        border: 2px solid #ced3ff;
       
        
        
    }
    :first-of-type {
        margin-bottom: 2em;
    }
    :last-of-type {
        margin-top: 7em;
    }

`
const Text = styled.p`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    color: rgba(0,0,0, 0.7);
    font-size: 1.3em;
    z-index: 2;
    /* background: red; */
    text-transform: uppercase;
    position: relative;
    
`
const BackText = styled.span`
    position: absolute;
    width: 4.5em;
    right: -0.2em;
    bottom: -0.2em;
    height: 50%;
    background: #ced3ff;
    z-index: -1;
    border-radius: 1px;
    
`
const IconContainer = styled.div`
    position: absolute;
    top: -0.5em;
    left: -0.5em;
    height: 1.5em;
    width: 1.5em;
    background: #ced3ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.9em;
    
`
const Button = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    /* border: 2px solid #80e081; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fdfdfd;
    font-weight: 600;
    background: #80b3ae;
    transition: 0.3s;
    :hover{
        background: #297f76
    }
`
export default function PresetModal({ setPresetWindow, modal, setModal, customerId, filterGoodName, setGoodsListDefault, setGoodsList,
    presetWindow, setProduct, setProductDefault, setShowGoodsList,
    setFilterGoodsDefault, product, neededStatus, windowRef, goodsListDefault, goodsListTemp, filterGoodsValue, getPresetBrod }) {
    const [date, setDate] = useState(new Date());
    const [azmanotList, setAzmanotList] = useState([]);
    const [orders, setOrders] = useState([]);
    const getToken = useSelector(token => token.mainReducer.token);

    const dispatch = useDispatch();
    const axios = require('axios');
    // console.log(goodsListTemp)

    const onChageDate = (dates) => {
        setDate(dates)
    }
    const formedDate = format(date, 'yyyyMMdd');
    const salectedDay = format(date, 'EEEE')

    const dayToHe = {
        "Sunday": 'א',
        "Monday": 'ב',
        "Tuesday": 'ג',
        "Wednesday": 'ד',
        "Thursday": 'ה',
        "Friday": 'ו',
        "Saturday": 'ש',
    }
    // console.log(dayToHe[salectedDay])

    // date shifter
    const selectedDate = () => {
        let newdate = new Date(Number(date));
        newdate.setDate(newdate.getDate() - 7);
        setDate(newdate);
    }

    useEffect(() => {
        selectedDate();
    }, [])
    // /date shifter
    console.log(+filterGoodsValue)
    const getGoods = async (OrderID) => {
        console.log('start')
        setShowGoodsList(true);
        dispatch({ type: LOAD });
        setProduct([]);
        setProductDefault([]);
        setGoodsList([]);
        setGoodsListDefault([]);
        setFilterGoodsDefault([]);
        try {
            let response = await axios.get(GET_ORDER + OrderID, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })

            let concatArr = addIDForTheList(response.data.recommendations.concat(response.data.orderDetails));
            

            const filtered = concatArr.filter(item => {
                if (neededStatus) {
                    // show goods filtered by selected group
                    return item.GroupArray.some(e => e === +filterGoodsValue)
                }
                else if (!neededStatus && getPresetBrod) {
                    console.log(item)
                    return item
                }
                else {
                    return item.id
                }
            })
            // console.log(addedIdArr)

            let sharePrice = () => {
                let currentArr = []
                for (let i = 0; i < filtered.length; i++) {
                    for (let j = 0; j < goodsListTemp.length; j++) {
                        if ((filtered[i].VarietyID + filtered[i].ProductID + filtered[i].SizeID) === (goodsListTemp[j].VarietyID + goodsListTemp[j].ProductID + goodsListTemp[j].SizeID)) {
                            filtered[i].Price = goodsListTemp[j].Price
                            currentArr.push(filtered[i])
                        }
                    }
                }

                return currentArr
            }
            let temp = sharePrice();
            let newProd = await temp.reduce((acc, cur) => {
                console.log(cur)
                return acc.concat({
                    "id": cur.id,
                    "RecID": cur.RecID,
                    "VarietyID": cur.VarietyID,
                    "Packs": cur.OrderPacks || 0,
                    "PackID": cur.PackID,
                    "ProductID": cur.ProductID,
                    "SizeID": cur.SizeID,
                    "Remark": cur.Remarks || '',
                    "Mlai": cur.OrderWeight || 0,
                    "Price": cur.Price || 0,
                    "GroupID": +filterGoodsValue || 0
                })
            }, [])
            // console.log(sharePrice())
            // console.log(goodsListTemp)
            // setShowGoodsList(true)
            setGoodsList(temp.sort(function (a, b) {
                return a.OrderPacks - b.OrderPacks
            }).reverse());
            setGoodsListDefault(temp.sort(function (a, b) {
                return a.OrderPacks - b.OrderPacks
            }).reverse());
            setFilterGoodsDefault(temp.sort(function (a, b) {
                return a.OrderPacks - b.OrderPacks
            }).reverse());

            // console.log(filtered)
            console.log(newProd)
            setProduct(newProd)
            dispatch({ type: LOAD_END });
            setPresetWindow(false)

        } catch (err) {
            dispatch({ type: LOAD_END });
            if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({type: LOGOUT, payload: false});
            }
            // setShowGoodsList(true);
        }




    }

    useEffect(() => {
        getAzmanot(getToken, formedDate, formedDate, setAzmanotList, setAzmanotList, modal, setModal);
    }, [date]);

    useEffect(() => {
        if (neededStatus) {
            
            setOrders(azmanotList.filter(item => {
                return item.CustomerID === customerId && item.GroupDesc.trim() === filterGoodName.trim()
            }))
        }
        else if (!neededStatus && getPresetBrod) {
            setOrders(azmanotList.filter(item => {
                return item.CustomerID === customerId 
            }))
        }
        else {
            setOrders(azmanotList.filter(item => {
                return item.CustomerID === customerId
            }))
        }
        
    }, [azmanotList])

    // console.log(orders)
    return (
        <Wrapper >
            <Container ref={windowRef}>
                <ItemWrapper>
                    <Text>
                        <BackText />
                        הזמנות קודמות
                    </Text>
                </ItemWrapper>
                <ItemWrapper>
                    <IconContainer style={{ zIndex: '2' }}>
                        {dayToHe[salectedDay]}
                    </IconContainer>
                    <DatePicker
                        // locale={he}
                        className={"datepicker-preset"}
                        selected={date}
                        onChange={onChageDate}
                        startDate={date}
                        // endDate={endDate}
                        // selectsRange
                        // shouldCloseOnSelect={false}
                        dateFormat="dd/MM/yyyy"
                    />
                </ItemWrapper>
                <ItemWrapper >
                    {orders.length > 0 ? <IconContainer>{orders.length}</IconContainer> : ''}
                    <PresetsList
                        orders={orders}
                        getGoods={getGoods}
                        setShowGoodsList={setShowGoodsList}
                    />
                </ItemWrapper>
                <ItemWrapper>
                    <Button onClick={() => { setPresetWindow(false); setShowGoodsList(true) }} >חזרה</Button>
                </ItemWrapper>

            </Container>

        </Wrapper>
    );
}