
const likutResons = {
  "likut_reasons_0": "",
  "likut_reasons_1": "не соответствие веса",
  "likut_reasons_2": "подтверждение менеджера",
  "likut_reasons_3": "недостаток товара",
  "likut_reasons_4": "разделение паллета",
  "likut_reasons_5": "замена товара",
  "likut_reasons_6": "комплект"
}


const likutProblebsVarietions = {
  "likut_problem_tag_notExistInAzmana": "товара нет в заказе",
  "likut_problem_tag_moreThenNeed": "количество выше заказа",
  "likut_problem_tag_lessThenNeed": "количество ниже заказа",
  "likut_problem_variations_1": "соответствие веса",
  "likut_problem_variations_2": "подтверждение менеджера",
  "likut_problem_variations_3": "недостаток товара",
  "likut_problem_variations_4": "разделение паллета",
  "likut_problem_variations_5": "замена товара",
  "likut_problem_variations_6": "комплект"

}

export const likutTranslationRU = {
  likut: {
    "likut_orders_search_placeholder": 'поиск заказа',
    "likut_pallets_title": "Паллеты",
    "likut_pallets_button_save": "Завершить сбор",
    "likut_pallets_button_new": "Новый паллет",
    "likut_modal_sium_likut_title": "Сводка заказа",
    "likut_modal_printers_printer": "Выберите принтер",
    "likut_modal_printers_format": "Формат печати",
    "likut_sectionmain_title_new": "Новый паллет",
    "likut_sectionmain_title_withpallet": "Паллет: {{pallet}}",
    "likut_modal_remarks_title": "Примечание",
    "likut_sectionmain_inpallet_title": "В паллете",
    "likut_sectionmain_product_swiperight_text": 'Проблема?',
    "likut_sectionmain_product_swiperight_button": 'Нет в наличии',
    "likut_sectionmain_product_swipeleft_text": 'Без сканирования',
    "likut_sectionmain_product_rest_packages_text": ':остаток упаковок',
    "likut_sectionmain_product_rest_text": ':остаток',
    "likut_sectionmain_product_problem": ':проблема',
    "likut_sectionmain_pallet_product_qty": 'кол-во',
    "likut_sectionmain_pallet_product_weight": 'вес',
    "likut_modal_checkpallet_title": "Проверка паллета",
    "likut_modal_checkpallet_weight": "Данные на весах",
    "likut_modal_addpackage_title": "Добавить упаковку",
    "likut_modal_addpackage_selector_title": "- Выберите упаковку -",
    ...likutResons,
    ...likutProblebsVarietions
  }

}



