import React from 'react'
import styled from 'styled-components'
import ArizotItem from './ArizotItem'


const MaitWrapper = styled.div`
    width: 100%;
    height: 3.5em;
    /* background: red; */
    border-radius: 30px;
`
const Select = styled.select`
    width: 100%;
    height: 100%;
    background: #eff3f4;
    border-radius: 30px;
    direction: rtl;
    padding: 0 1em;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    font-size: 1.1em;
    color: #8a8aad;
    border: none;
    cursor: pointer;
`


export default function ArizotList({list, setArizaID, refSelect, placeholder}) {
  return (
    <MaitWrapper>
        <Select onChange={e => setArizaID(+e.target.value)} ref={refSelect}>
            <option value={null}>{placeholder}</option>
            {list?.map(item => <ArizotItem key={item.PackID} {...item} />)}
        </Select>
    </MaitWrapper>
  )
}
