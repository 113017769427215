export const langList = [
  {
    code: 'en',
    name: 'English',
    contryCode: 'us',
    diraction: 'ltr'
  },
  {
    code: 'he',
    name: 'עברית',
    contryCode: 'il',
    diraction: 'rtl'
  },
  {
    code: 'ar',
    name: 'العربية',
    contryCode: 'sa',
    diraction: 'rtl'
  },
  {
    code: 'ru',
    name: 'Русский',
    contryCode: 'ru',
    diraction: 'ltr'
  },
  {
    code: 'th',
    name: 'ไทย',
    contryCode: 'th',
    diraction: 'ltr'
  }
]