import React from 'react'
import styled from 'styled-components'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { IFlowerPalletItem } from '../../../../../../modules/API/Flowers/models/flowerPallet'
import { CalendatDay, PackageIcon, PalletIcon, PlaneIcon, ScalesIcon, ShipIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import { convertToDate } from '../../../../../../modules/convertDateString'

interface Props extends IFlowerPalletItem {
  item: IFlowerPalletItem
  handleClick: (item: IFlowerPalletItem) => void
}

const TitleItem = ({
  Pallet_Num,
  Customer_Name,
  Pallet_Date,
  Trasportation_Method,
  GrossWeight,
  Total_Pallets,
  item,
  handleClick
}: Props) => {

  const handleSelect = () => {
    handleClick(item)
  }
  return (
    <TitleLayout
      onClick={handleSelect}
    >
      <SideWrapper>
        {
          Trasportation_Method === 1 ? <PlaneIcon /> : 
          Trasportation_Method === 1 ? <ShipIcon/> : ''
        }
      </SideWrapper>
      <TitleRow>
        <TitleRowItem
          name={`${convertToDate(Pallet_Date)}`}
          icon={<CalendatDay />}
        />
        <TitleRowItem
          name={`${Pallet_Num}`}
          icon={<PalletIcon />}
        />
      </TitleRow>
      <TitleRow>
        <TitleRowItem
          name={`${Customer_Name.trim()}`}
          icon={<UserIcon />}
        />
      </TitleRow>
      <TitleRow>
        <TitleRowItem
          name={`${GrossWeight.toFixed(2)}`}
          icon={<ScalesIcon />}
        />
        <TitleRowItem
          name={`${Total_Pallets}`}
          icon={<PackageIcon />}
        />

      </TitleRow>
    </TitleLayout>
  )
}

const SideWrapper = styled.div`
  position: absolute;
  left: 0.5em;
  top: calc(50% - 0.6em);
  font-size: 1.5em;
  color: #297F76;
`
export default TitleItem