import React from 'react';
import styled from 'styled-components';
import SearchItem from './search-item'

export const ListContainerClient = styled.div`
    position: absolute;
    top: 3.5em;
    max-width: 300px;
    margin: 0 auto;
    border-radius: 30px 0 20px 30px;
    max-height: 100px;
    overflow: auto;
    z-index: 1;
    background: #fdfdfd;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    
    /* padding: 0.2em 0.5em; */
    ::-webkit-scrollbar {
      width: 0;
    }
`
const ClientList = ({ clientList, setInput, setGoodsList, setGoodsListDefault,
                      presetList,clientListDefault, setShowSearchGoods, setCustomerId,
                      setPresetGoodsListDefault, setProduct, setDeliveryDate, 
                      dateFunction, getGoodsList, setGeneralRemark, setWeek, setHandleTime, getPackeges, fieldClietns }) => {
  return (
    <ListContainerClient style={{display: fieldClietns}}>
      { clientList.map((data, id) => <SearchItem key={data.CustomerID}{...data}
        setInput={setInput}
        setGoodsList={setGoodsList}
        setGoodsListDefault={setGoodsListDefault}
        setShowSearchGoods={setShowSearchGoods}
        setCustomerId={setCustomerId}
        setProduct={setProduct}
        setDeliveryDate={setDeliveryDate}
        dateFunction={dateFunction}
        getGoodsList={getGoodsList}
        clientListDefault={clientListDefault}
        presetList={presetList}
        setPresetGoodsListDefault={setPresetGoodsListDefault}
        setGeneralRemark={setGeneralRemark}
        setWeek={setWeek}
        setHandleTime={setHandleTime}
        getPackeges={getPackeges}
        data={data}
      />)}
    </ListContainerClient>
  );
}

export default ClientList