import React, { useState } from 'react'
import styled from 'styled-components'
import { vibrationFunc } from '../../../../../../modules/vibrationModule'

const PassFieldWrapper = styled.form`
    width: 80%;
    height: 2em;
    position: relative;
    display: ${props => props.primal ? 'flex' : 'none'};
`
const IconWrapper = styled.div`
    position: absolute;
    left: 0;
    /* background: red; */
    width: 3em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    color: ${props => props.primal ? '2px solid tomato' : '2px solid #81b4af'};

`
const InnerInput = styled.input`
    max-width: 100%;
    height: 100%;
    padding: 0 0.5em;
    text-align: right;
    border-radius: 30px;
    border: ${props => props.primal ? '2px solid tomato' : '2px solid #e9f0f0'} ;
    outline: none;
    :focus {
        border: ${props => props.primal ? '2px solid tomato' : '2px solid #81b4af'} ;
    }
`
export default function PassFieldReson({resonId, setInputPass, artPass, acceptFunc}) {
    const [toggle, setToggle] = useState(false);
    return (
        <PassFieldWrapper primal={resonId === 2}>
            <IconWrapper><i className="fas fa-eye"></i></IconWrapper>
            <InnerInput 
                primal={toggle}
                placeholder='סיסמה' 
                type='password'
                autoComplete="new-password"
                onChange={e => {setInputPass(e.target.value)}} 
                onKeyPress={(e) =>{
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        if(artPass !== e.target.value ) {
                            vibrationFunc();
                            setToggle(true);
                            setTimeout(() => {
                                setToggle(false)
                            }, 2000)
                            return
                        }
                        else {
                            acceptFunc()
                        }
                    }
                
                }} 

            />
        </PassFieldWrapper>
    )
}
