import React from 'react'
import { ISizeItem } from '../../../../../../modules/API/Products/models/size'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  list: ISizeItem[]
  defaultID: string
  setSizeID: React.Dispatch<React.SetStateAction<string>>
}

const SearchSize = ({
  list, defaultID, setSizeID
}: Props) => {
  const defaultSize = list.filter(item => item.Description === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = React.useState<boolean>(false)
  const [selectedSize, setSelectedSize] = React.useState<ISizeItem | null>(defaultSize || null)
  const [search, setSearch] = React.useState<string>(defaultSize?.Description.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedSize(null)
      setIsOpenDropDown(false)
      setSearch('')
      setSizeID('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: ISizeItem) => {
    setSearch(item.Description.trim())
    setSelectedSize(item)
    setIsOpenDropDown(false)
    setSizeID(item.Description)
  }
  const render = React.useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.Description.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])
  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={'חפש גודל'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='Description'
      nameIdElement='Description'
      size={'full'}
    />
  )
}

export default SearchSize