import React, { useState } from "react";
import {IOrderRehesh} from "../../../../../modules/API/AzmanotRehesh/models/order";
import styled from "styled-components";
import TitleRow from "../../kosherTeudot/generic/TitleRow";
import TitleRowItem from "../../kosherTeudot/generic/TitleRowItem";
import {
    AppleIcon,
    EditIcon,
    InvoiceIcon,
    PackageIcon,
    PalletIcon,
    ScalesIcon,
} from "../../../../../generic/icons/Icons";
import TitlesList from "../../../../../generic/titlesRowItem/TitlesList";
import AzmanotReheshNestedItem from "./AzmanotReheshNestedItem";
import { EXIST_PATH } from "../../../../../modules/API/settings";
import { IOrderReheshSelected } from "../AzmanotReheshMain";

interface IProps extends IOrderRehesh {
    index: number;
    item: IOrderRehesh;
    // isStopAnimation: boolean;
    handleSelectItemForStatus: (item: IOrderReheshSelected) => void;
    isGrower: boolean;
    handleSelectProductItem?: (item: IOrderRehesh) => void;
}

const AzmanotReheshItem = ({
    GrowerName,
    ProductDesc,
    DetailsByGrowers,
    VarietyDesc,
    index,
    handleSelectItemForStatus,
    isGrower,
    OrderID,
    OrderPacks,
    OrderWeight,
    Order_Pallets,
    GeneralProductID,
    RecID,
    handleSelectProductItem,
    item,
}: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isAppearEdit, setIsAppearEdit] = useState<boolean>(false);
    // const [isAppear, setIsAppear] = useState<boolean>(isStopAnimation ? true : false);
    const weight = Math.round(OrderWeight).toLocaleString();
    const packs = Math.round(OrderPacks).toLocaleString();
    
    const growerString =
        ProductDesc && ProductDesc?.trim().length > 14
            ? "..." + ProductDesc?.trim().slice(0, 14)
            : ProductDesc?.trim();

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectProduct = (e: React.MouseEvent) => {
        if (!handleSelectProductItem) return;
        e.stopPropagation();
        handleSelectProductItem(item);
    };

    const handleSwitch = (isAppear: boolean) => {
        setIsAppearEdit(isAppear);
    };

    const img =
        GeneralProductID !== 0
            ? `${EXIST_PATH}/images/products/${GeneralProductID}.png`
            : "/emptyBox.png";

    return (
        <BodyWrapper>
            <BodyHeadWrapper onClick={handleOpen}>
                <PictureWrapper
                    onMouseOverCapture={() => handleSwitch(true)}
                    onMouseOutCapture={() => handleSwitch(false)}
                    onClick={handleSelectProduct}
                >
                    {
                        !!handleSelectProductItem ? (
                            !isAppearEdit ? (
                                <Img src={img} />
                            ) : (
                                <EditBtn>
                                    <EditIcon />
                                </EditBtn>
                            )
                        ) : (
                            <Img src={img} />
                        )
                    }
                </PictureWrapper>
                <TitleRow>
                    <TitleRowItem
                        name={`${growerString} ${VarietyDesc?.trim()}`}
                        icon={<AppleIcon />}
                    />
                    <TitleRowItem name={`${OrderID}`} icon={<InvoiceIcon />} />
                </TitleRow>
                <TitleRow>
                    <TitleRowItem name={`${packs}`} icon={<PackageIcon />} />
                    <TitleRowItem name={`${weight}`} icon={<ScalesIcon />} />
                    <TitleRowItem name={`${Order_Pallets}`} icon={<PalletIcon />} />
                </TitleRow>
            </BodyHeadWrapper>
            {DetailsByGrowers.length > 0 && (
                <BodyNestedWrapper isOpen={isOpen}>
                    <TitlesList
                        list={DetailsByGrowers}
                        uniqueKey={"RecID"}
                        Element={AzmanotReheshNestedItem}
                        noPadding={false}
                        backgroundColor={"#EFF3F4"}
                        additionalData={{
                            handleSelectItemForStatus: handleSelectItemForStatus,
                            isGrower: isGrower,
                            parrentID: RecID,
                        }}
                    />
                </BodyNestedWrapper>
            )}
        </BodyWrapper>
    );
};

const EditBtn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297f76;
`;

const BodyWrapper = styled.div`
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-flow: column;
  gap: 1em;
  z-index: 1;
  transition: all 0.5s ease;
  cursor: pointer;
  -webkit-filter: drop-shadow(4px 3px 3px rgba(41, 127, 118, 0.51));
  filter: drop-shadow(4px 3px 3px rgba(41, 127, 118, 0.51));
`;

const BodyHeadWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`;

const BodyNestedWrapper = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  height: max-content;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-flow: column;
  gap: 0.5em;
`;

const PictureWrapper = styled.div`
  position: absolute;
  top: calc(50% - 2em);
  width: 4em;
  height: 4em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const Img = styled.img`
  user-select: none;
  width: 100%;
`;

export default AzmanotReheshItem;
