import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFocus from '../../../../../generic/hooks/useFocus'
import axios from 'axios'
import { GET_PALLET_EXIST, SAVE_COMMENT_ON_PALLET } from '../../../../../modules/API/settings'
import { LOAD, LOAD_END, LOGOUT } from '../../../../../../store/actions/actionsTypes'
import { BarCodeIcon } from '../../../../../generic/icons/Icons'
import { ContentWrapper, Decorator, MainWrapper, SectionMain } from '../../secondbar/mainbar2'
import { IconWrapper, InputLikut, InputWrapperLikut } from '../likutSection/LikutSectionMain'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { SelectPanelWrapper } from '../../secondbar/azmanotmain/SelectBar'
import { ButtonWrapper } from '../likutExpressMain'
import Title from '../../../../../generic/Title/Title'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import LikutExpressCheckItem from './LikutExpressCheckItem'
import selectOnFocus from '../../../../../modules/autoselect'
import ControlButton from '../../../../../generic/buttons/ControlButton'

export default function LikutExpressCheckPalletMain() {
    const [modal, setModal] = useContext(ContextModal);
    const [inputRef, setInputFocus] = useFocus()
    const [inputSearch, setInputSearch] = useState(null);
    const [palletData, setPaletData] = useState([]);
    const [commentData, setCommentData] = useState([])
    const getToken = useSelector(token => token.mainReducer.token);
    const dispatch = useDispatch();

    const saveComment = async (data) => {
        try {
            let res = await axios({
                method: 'patch',
                url: `${SAVE_COMMENT_ON_PALLET}${inputSearch}`,
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    "detailsDelivery": commentData,
                    "printerNum": 33
                }
            })
            // console.log(res.data)
        } catch (err) {
            console.log(err.response.data.message)
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({type: LOGOUT, payload: false});
            }
        }
    }
    const palletDetailes = async (code) => { // 99086
        dispatch({ type: LOAD });
        try {
            let res = await axios.get(`${GET_PALLET_EXIST}${code}`, { 
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            dispatch({ type: LOAD_END })
            if (Array.isArray(res.data.detailsPallet)) {
                setPaletData(res.data.detailsPallet)
            }

            // console.log(res.data.detailsPallet)
        } catch (err) {
            dispatch({ type: LOAD_END })
            if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({type: LOGOUT, payload: false});
            }
            console.log(err)
        }

    }

    useEffect(() => {
        setInputFocus()
    }, [])
    return (
        <SectionMain>
            <MainWrapper>
                <Decorator />
                <Title label={'check pallet'} />
                <SelectPanelWrapper >
                    <InputWrapperLikut>
                        <IconWrapper onClick={() => { setInputFocus() }}><BarCodeIcon /></IconWrapper>
                        <InputLikut
                            type={'number'}
                            onFocus={selectOnFocus}
                            ref={inputRef}
                            // value={inputSearch}
                            onChange={(e) => { setInputSearch(e.target.value) }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.target.blur();
                                    setInputSearch(e.target.value);
                                    palletDetailes(e.target.value);
                                }
                            }}
                        />
                    </InputWrapperLikut>
                </SelectPanelWrapper>
                <ContentWrapper>
                    <TitlesList
                        list={palletData}
                        uniqueKey={'RecID'}
                        Element={LikutExpressCheckItem}
                        additionalData={{
                            setCommentData: setCommentData,
                            commentData: commentData
                        }}
                    />
                </ContentWrapper>
                <ButtonWrapper>
                    <ControlButton
                        lable='לשמור ולהדפיס'
                        handleClick={saveComment}
                    />
                </ButtonWrapper>
            </MainWrapper>
        </SectionMain>
    )
}
