
export default function ConfermDelete({OrderID, removeAzmanot}){
    return(
        <div 
            onClick={() => {removeAzmanot(OrderID);}}
            className="conferm-delete-string" 
            style={{background: 'tomato', height: '5em', width: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', paddingRight: '5px'}}>
            <i style={{color: "#fff"}} className="far fa-trash-alt"></i>
        </div>
    );
}