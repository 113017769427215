import React from 'react'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead';
import { IOrderPackageTitle } from '../../../../../modules/API/Packages/models/createPackageOrderModel'
import Image from '../../../../../generic/assets/agroline.document.svg'
import { useTranslation } from 'react-i18next';

type Props = {
    title: IOrderPackageTitle;
    saveFunc: (item: number, isTeuda?: boolean) => void,
    closeFunc: () => void
}

const PrintModal = ({ title, saveFunc, closeFunc }: Props) => {
    const [t] = useTranslation('packageOrder')
    const handlePrint = (id: number, isTeuda?: boolean) => {
        try {
            isTeuda ? saveFunc(title.PD_Num, true) : saveFunc(title.PO_Num)
            closeFunc()
        } catch (err) {
            return
        }

    }
    return (
        <MainWrapper>
            <ImageContainer>
                <ImageComponentHead
                    img={Image}
                    large
                />
            </ImageContainer>
            <BodyWrapper>
                <PrintItem
                    onClick={() => { handlePrint(title.PO_Num) }}
                >
                    {t('packageOrder_modal_print_order')}
                </PrintItem>
                <PrintItem
                    onClick={() => { handlePrint(title.PD_Num, true) }}
                >
                    {t('packageOrder_modal_print_certificate')}
                </PrintItem>
            </BodyWrapper>
        </MainWrapper>
    )
}

export default PrintModal

const MainWrapper = styled.div`
    /* background: red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`
const ImageContainer = styled.div`
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const BodyWrapper = styled.div`
    width: 100%;
    height: 45%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
    /* background: red; */
`
const PrintItem = styled.div`
    padding: 0.7em 1em;
    width: 80%;
    background: #7ab0ab;
    border-radius: 30px;
    transition: 0.4s;
    cursor: pointer;
    font-size: 1.1em;
    color: #fff;

    :hover {
        background: #297f76;
    }

`