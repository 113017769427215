import React, {useState, useCallback} from 'react'
import styled from 'styled-components'
import PhoneInputWithMask from './generic/PhoneInputWithMask';
import selectOnFocus from '../../../../../../modules/autoselect';

type Props = {
    isSelected: number | null;
    selectButton: (id: number) => void;
    handlePhone: (e: string) => void;
    handleNewClientName: (e: string) => void;
    defaultName?: string;
    isDisableNewCustomer?: boolean;
}

const SlectNewClientGeneral = ({
    isSelected, selectButton, handlePhone, handleNewClientName, defaultName, isDisableNewCustomer
}: Props) => {
    const [name, setName] = useState(defaultName || '');
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        handleNewClientName(e.target.value)
    }

    const handleChangePhone = (e: string) => {
        handlePhone(e)
    }

    const handleSelectRagil = () => {
        selectButton(1)
    }

    const handleSelectCash = () => {
        selectButton(0)
    }

  return (
    <SelectorWrapper>
        <Title>
            סוג הלקוח
        </Title>
        <SelectItemWrapper>
            {
                !isDisableNewCustomer && 
                <Button 
                    isPrimal 
                    isSelected={isSelected === 1}
                    onClick={handleSelectRagil}
                >
                    הקפה
                </Button>
            }
            <Button 
                isSelected={isSelected === 0}
                onClick={handleSelectCash}
            >
                מזדמן
            </Button>
        </SelectItemWrapper>
        {
            isSelected !== null ? 
            <>
                <Title>
                    שם הלקוח
                </Title>
                    <Input 
                        defaultValue={defaultName || name}
                        onChange={handleChangeName}
                        onFocus={selectOnFocus}
                        placeholder='שם הלקוח'
                    />
                <Title>
                    מספר טלפון
                </Title>
                <PhoneInputWithMask 
                    handleChange={handleChangePhone}
                />  
                
            </> : null
        }
    </SelectorWrapper>
  )
}
const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  background: rgb(233,240,240, 0.2)
`
const SelectItemWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  /* background: red; */
  border-radius: 30px;
  justify-content: space-between;
`
const Title = styled.div`
  text-align: end;
  color: #297F76;
  font-weight: 600;
  padding: 0 0.5em;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse

`
const Input = styled.input<{alert?: boolean}>`
  width: 100%;
  height: 3em;
  border-radius: 30px;
  background: #EFF3F4;
  border: ${props => props.alert ? '2px solid #FF0000' : 'none'};
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1.2em;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
    font-size: 0.9em;
  }
`
const Button = styled.button<{isPrimal?: boolean, isSelected: boolean}>`
    width: 100%;
    padding: 0.5em;
    border: ${props => props.isSelected ? '2px solid #80E081' : '2px solid #757575'};
    cursor: pointer;
    font-size: 1.2em;
    color: ${props => props.isSelected ? '#80E081' : '#757575'};
    border-radius: ${props => props.isPrimal ? '30px 10px 10px 30px' : '10px 30px 30px 10px'};
`
const SlectNewClient = React.memo(SlectNewClientGeneral)
export default SlectNewClient