import React from 'react'
import styled from 'styled-components';

type Position = 'left' | 'right';
type Props = {
    text: string;
    icon?: React.ReactNode;
    position?: Position;
    isPrimal?: boolean;
}

const SwipeContent = ({
    text, position = 'left', isPrimal
}: Props) => {
  return (
    <MainWrapper
      position={position}
      isPrimal={isPrimal}
    >
        {text}
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{position?: Position, isPrimal?: boolean}>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${props => props.position === 'right' ? "flex-end" : "flex-start"};
    background: ${props => props.isPrimal ? 'tomato' : '#64A19C'};
    border-radius: 20px;
    color: #fff;
    font-weight: 600;
    padding: 0 1em;
`

export default SwipeContent