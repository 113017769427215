import React from 'react'
import styled from 'styled-components'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { ITableOfTableItem } from '../../../../../modules/API/Defaults/models/tableOfTables'
import { ICustomerItem } from '../../../../../modules/API/Drivers/models/kavData'
import { AcceptIcon } from '../../../../../generic/icons/Icons'

type Props = {
  openPackageOverlay: () => void,
  customerData: ICustomerItem,
  list: ITableOfTableItem[],
  saveAction: (id: number) => void,
  isOffice?: boolean
}

const PackagesPortalModal = ({
  openPackageOverlay, customerData, list, saveAction,
  isOffice
}: Props) => {
  const handleClick = (action: number) => {
    if(isOffice) return 
    if(action === 1) {
      openPackageOverlay()
    } else {
      saveAction(action)
    }
  }
  console.log(customerData)
  return (
    <MainWrapper>
      <ErrorsWrapper>
        {
          list?.map((e, index) => 
            // index !== 0 &&
            <ErrorItem
              key={index}
              isFalse={!e.Flag}
              onClick={() => handleClick(e.SecondCode)}
            >
              {
                customerData.LastAction.Action === e.SecondCode &&
                 <SideButton isFalse={!e.Flag}>
                    <AcceptIcon />
                 </SideButton>
              }
              {e.Description?.trim()}
            </ErrorItem>
          )
        }
      </ErrorsWrapper>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
`

const ErrorsWrapper = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  background: #fff;
  border-radius: 20px;
`

const ErrorItem = styled.div<{isFalse?: boolean, isSelected?: boolean}>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  border-radius: 20px;
  background: ${props => props.isFalse ? 'tomato' : '#ccf3cd'};
  color: ${props => props.isFalse ? '#fff' : '#838484'};
  font-size: 1.2em;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`
const SideButton = styled.div<{isFalse?: boolean}>`
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  left: 0.3em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: ${props => props.isFalse ? 'tomato' : '#7ab0ab'};
`

export default PackagesPortalModal