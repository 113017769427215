import React from 'react'
import { returnResonBynum, useResonDescByNum } from '../../../../../../generic/artcoded/resonslikut'
import { ScalesIcon, ShopBagIcon } from '../../../../../../generic/icons/Icons'
import { EXIST_PATH } from '../../../../../../modules/API/settings'
import { DetalisContainer, DetalisInnerItem, DetalisItem, ImageContainer, ItemContainer, RemarkElement, TitleContainer } from '../../likutSection/LikutSectionGoodsItem'
import { useTranslation } from 'react-i18next'
export default function SiumLikutItem({
    ImageUrl, ProductDesc, VarietyDesc, ChangeReason, 
    SizeID, OrderPacks, SupplyQty, defaultPackageList, 
    PackID, isWeightCalculation, SupplyWeight, OrderWeight
}) {
    const [t] = useTranslation(['likut']);
    const reason = useResonDescByNum(+ChangeReason);
    const selectIconForPalletType = () => {
        if (PackID === 0) return ''
        let filtered = defaultPackageList.filter(e => e.PackID === PackID);
        let result = filtered[0]?.Pack_Type
        return result
    }
    let showIcon = selectIconForPalletType === '1' ? <ScalesIcon /> : selectIconForPalletType === '2' ? <ShopBagIcon /> : ''
    let bacPic = ImageUrl === '' ?
        'url("https://pngicon.ru/file/uploads/otkrytaja-korobka-128x85.png") center center no-repeat' :
        `url("${EXIST_PATH}${ImageUrl}") center center no-repeat`
    return (
        <ItemContainer>
            {
                !reason || ChangeReason === 4 ?
                    <RemarkElement>
                        !
                    </RemarkElement>
                    : ''
            }
            <ImageContainer style={{ background: bacPic, backgroundSize: 'contain' }}/>
            <TitleContainer>
                {ProductDesc?.trim()} {VarietyDesc?.trim()} {SizeID?.trim()}
            </TitleContainer>
            <DetalisContainer style={{marginBottom: '0.5em'}}>
                
                <DetalisItem>
                    <DetalisInnerItem style={{ color: 'tomato' }} >{showIcon}</DetalisInnerItem>
                    {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                </DetalisItem>
                <DetalisItem>
                    <DetalisInnerItem primal>{OrderPacks - SupplyQty}</DetalisInnerItem>
                    {/* <DetalisInnerItem>{SupplyQty}</DetalisInnerItem> */}
                </DetalisItem>
                <DetalisItem>
                    <DetalisInnerItem primal>
                        {t('likut_sectionmain_product_rest_text')}
                    </DetalisInnerItem>
                    {/* <DetalisInnerItem>{OrderPacks}</DetalisInnerItem> */}
                </DetalisItem>
            </DetalisContainer>
            {
                isWeightCalculation ?
                <DetalisContainer style={{marginBottom: '0.5em'}}>
                    <DetalisItem>
                        <DetalisInnerItem>
                            <ScalesIcon />
                        </DetalisInnerItem>
                        {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                    </DetalisItem>
                    <DetalisItem>
                        <DetalisInnerItem primal>{(OrderWeight - SupplyWeight).toFixed(1)}</DetalisInnerItem> 
                        {/* <DetalisInnerItem>{SupplyQty}</DetalisInnerItem> */}
                    </DetalisItem>
                    <DetalisItem>
                        <DetalisInnerItem primal>
                            {t('likut_sectionmain_product_rest_text')}
                        </DetalisInnerItem>
                        {/* <DetalisInnerItem>{OrderPacks}</DetalisInnerItem> */}
                    </DetalisItem>
                </DetalisContainer> : ''
            }
            {
                reason ?
                    <DetalisContainer>
                        <DetalisItem>
                            <DetalisInnerItem style={{ color: 'tomato' }} >{reason}</DetalisInnerItem>
                            {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                        </DetalisItem>
                        <DetalisItem>
                            <DetalisInnerItem >
                                {t('likut_sectionmain_product_problem')}
                            </DetalisInnerItem>
                            {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                        </DetalisItem>
                    </DetalisContainer>
                    : ''
            }
        </ItemContainer>
    )
}
