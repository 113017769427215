import React from 'react'
import { ForcastProductItem } from '../../../../../../modules/API/Forcasts/models/forcastProductitem'
import styled from 'styled-components'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PackageIcon, PalletIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import RowInput from '../../../../../../generic/inputs/RowInput'

interface Props extends ForcastProductItem {
  index: number
  item: ForcastProductItem
  handleChange: (forecastsItem: ForcastProductItem) => void,
  calculationMethod: number
}

const AddProductNestedItem = ({
  item,
  index,
  GrowerName,
  GrowerID,
  ActualAmnt,
  ForecastAmnt,
  ActualPallets,
  ForecastPallets,
  ActualWeight,
  ForecastWieght,

  handleChange,
  isNew,
  calculationMethod
}: Props) => {
 
  const handleChangeForecasts = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({
      ...item,
      ActualPallets: calculationMethod === 2 ? Number(e.target.value) : ActualPallets,
      ActualAmnt: calculationMethod === 1 ? Number(e.target.value) : ActualAmnt,
      ActualWeight: calculationMethod === 3 ? Number(e.target.value) : ActualWeight
    
    })
 }
  
  return (
    <MainWrapper>
      <TitleRow>
        <TitleRowItem 
          name={`${GrowerName.trim()}`}
          icon={<UserIcon />}
        />
      </TitleRow>
      <TitleRow>
        {
          calculationMethod === 2 ?
          <>
            <TitleRowItem 
              name={`${ActualPallets} / ${ForecastPallets}`}
              icon={<PalletIcon />}
            />
            <RowInput 
              icon={<PalletIcon />}
              // value={ForecastPallets}
              defValue={isNew ? ActualPallets : 0}
              handleInput={handleChangeForecasts}
              isDisabled={false}
            />
          </>
          : calculationMethod === 3 ?
          <>
            <TitleRowItem 
              name={`${ActualWeight} / ${ForecastWieght}`}
              icon={<PalletIcon />}
            />
            <RowInput 
              icon={<PalletIcon />}
              // value={ForecastWieght}
              defValue={isNew ? ActualWeight : 0}
              handleInput={handleChangeForecasts}
              isDisabled={false}
            />
          </> : 
          <>
            <TitleRowItem 
              name={`(${(ForecastAmnt - ActualAmnt).toLocaleString()}) - יתרא תחזית`}
              icon={<PackageIcon />}
            />
            <RowInput 
              icon={<PackageIcon />}
              // value={ForecastAmnt}
              defValue={isNew ? ActualAmnt : 0}
              handleInput={handleChangeForecasts}
              isDisabled={false}
            />
          </>

        } 
      </TitleRow>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;

`
export default AddProductNestedItem