import React from 'react'
import styled from 'styled-components'

type Props = {
  list: string[],
  selected: string,
  setSelected: (selected: string) => void
}

const TabsSelector = ({
  list, selected, setSelected
}: Props) => {
  return (
    <TabContainer>
      {list.map((type) => (
        <Tab
          key={type}
          active={selected === type}
          onClick={() => setSelected(type)}
        >
          {type}
        </Tab>
      ))}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  /* background: #eff3f4; */
  z-index: 1;
  border-radius: 30px 30px 0 0;
`

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  z-index: 1;
  ${props =>
    props.active ?
    `
      background: #fff;
      border-bottom: none;
      color: #7ab0ab;
    `:
    `
      background: rgba(255, 255, 255, 0.3);
      border-bottom: none;
      color: #7ab0ab;
    `
  }
`
export default TabsSelector