import { useRef } from "react";
import styled from "styled-components";


const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 10em;
    bottom: 5em;
    /* background: red; */
    justify-content: center;
    align-items: center;
    transition: 0.3s;
`

const Container = styled.div`
    /* background: #ffced3; */
    width: 20em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px
`
const RemarkPlace = styled.textarea`
    height: 60%;
    width: 80%;
    border-radius: 5px;
    outline: none;
    appearance: none;
    border: 2px solid #80b3ae;
    text-align: right;
    padding: 0.2em 0.5em;
    font-size: 1.1em;
    font-family: sans-serif;
    background: #fdfdfd;
    resize: none;
`


export default function ModalRemark({showRemark, setShowRemark, remarkButtonRef, setGeneralRemark, generalRemark}){
    
    const remarkRef = useRef(null)
    let remarkToggle = showRemark ? 'flex' : 'none'

    return(
        <Wrapper ref={remarkRef} style={{display: remarkToggle}}>
            <Container>
                <RemarkPlace placeholder={'הערה'} value={generalRemark} onChange={(e)=>{setGeneralRemark(e.target.value)}}/>
            </Container>
            
        </Wrapper>
    );
}