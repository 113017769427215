import React, { useCallback, useMemo } from 'react'
import { IAddProductItem } from '../types/addProductItem'
import styled from 'styled-components'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, CloseIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import AddProductNestedItem from './AddProductNestedItem'
import { ForcastProductItem } from '../../../../../../modules/API/Forcasts/models/forcastProductitem'
import SearchGrower from '../../../knisot/Create/Components/SearchGrower'
import CircleNumericInput from '../../../../../../generic/inputs/CircleNumericInput'
import SearchInput from '../../../../../../generic/inputs/inputSearch'
import { IGrowerItem } from '../../../../../../modules/API/Defaults/models/growers'
import { useTranslation } from 'react-i18next'
import AddGrower from './AddGrower'

interface Props extends IAddProductItem {
  item: IAddProductItem,
  handleChange: (item: IAddProductItem, forecastsItem: ForcastProductItem) => void
  growerList: IGrowerItem[]
  handleAddForecast: (item: IAddProductItem, forecastItem: ForcastProductItem) => void
  calculationMethod: number
}

const AddProductItem = ({
  item,
  growerList,
  ProductDesc,
  VarietyDesc,
  GeneralProductID,
  orderedWeight,
  orderedPackages,
  orderedPallets,
  forecastList,
  handleChange,
  handleAddForecast,
  calculationMethod,
  OrdersPacks,
  OrdersPallets,
  OrdersWieght,
  MlaiPacks,
  MlaiPallets,
  MlaiWieght,
  ProductID
}: Props) => {
  const [t] = useTranslation(['buttons'])
  const [isOpenNestedList, setIsOpenNestedList] = React.useState<boolean>(false)
  const [isOpenAddGrowerMenu, setIsOpenAddGrowerMenu] = React.useState<boolean>(false)
  const [growerID, setGrowerID] = React.useState<number>(0)
  const [growerQty, setGrowerQty] = React.useState<number>(0)

  const handleControlNestedList = () => {
    setIsOpenNestedList(!isOpenNestedList)
  }
  const handleChangeForecasts = useCallback((forecast: ForcastProductItem) => {
    handleChange(item, forecast)
  }, [item, handleChange])
  // console.log(item)
  const handleControlGrowerMenu = () => {
    setIsOpenAddGrowerMenu(!isOpenAddGrowerMenu)
  }

  const handleAddGrowerAsForecast = () => {
    if (growerID === 0 || growerQty === 0) return

    /*
    @params ForcastProductItem

    ActualAmnt: number,
        ActualPallets: number,
        ActualWeight: number,
        Collection_Date: string,
        Comments: string,
        CustomerID: number,
        Customer_Name: string,
        ForecastAmnt: number,
        ForecastPallets: number,
        ForecastWieght: number,
        GrowerID: number,
        GrowerName: string,
        MarketingArea: number,
        OrderId: number,
        Order_Type: number,
        PackID: number,
        Pack_Name: string,
        ProductDesc: string,
        ProductID: number,
        RecID: number,
        Rev_Price: number,
        Season: number,
        ShipmentType: number,
        SizeDesc: string,
        SizeID: string,
        Status: number,
        TransferZone: number,
        VarietyDesc: string,
        VarietyID: number,
        GeneralProductID: number
    */
    const newForecast = {
      ActualAmnt: calculationMethod === 1 ? +growerQty : 0,
      ActualPallets: calculationMethod === 2 ? +growerQty : 0,
      ActualWeight: calculationMethod === 3 ? +growerQty : 0,
      Collection_Date: "",
      Comments: '',
      CustomerID: 0,
      Customer_Name: '',
      ForecastAmnt: calculationMethod === 1 ? +growerQty : 0,
      ForecastPallets: calculationMethod === 2 ? +growerQty : 0,
      ForecastWieght: calculationMethod === 3 ? +growerQty : 0,
      GrowerID: growerID,
      GrowerName: growerList.find(item => item.GrowerID === growerID)?.GrowerName || '',
      MarketingArea: 0,
      OrderId: 0,
      Order_Type: 0,
      PackID: 0,
      Pack_Name: '',
      ProductDesc: ProductDesc,
      ProductID: item.ProductID,
      RecID: 0,
      Rev_Price: 0,
      Season: 0,
      ShipmentType: 0,
      SizeDesc: '',
      SizeID: '',
      Status: 0,
      TransferZone: 0,
      VarietyDesc: VarietyDesc,
      VarietyID: item.VarietyID,
      GeneralProductID: GeneralProductID,
      isNew: true
    }
    // item.forecastList.push(newForecast)
    handleAddForecast(item, newForecast)
    console.log(item)
    setGrowerID(0)
    setGrowerQty(0)
    handleControlGrowerMenu()
  }

  return (
    <MainWrapper>
      <HeadWrapper
        onClick={handleControlNestedList}
      >
        <SideWrapper>
          <ProductImage
            id={GeneralProductID}

          />
        </SideWrapper>

        <TitleRow>
          <TitleRowItem
            name={`${ProductDesc.trim()} ${VarietyDesc.trim()}`}
            icon={<AppleIcon />}
          />
        </TitleRow>
        <TitleRow>
          <TitleRowItem
            name={
              <>
              {
                calculationMethod === 2 ? Math.round(MlaiPallets) :
                calculationMethod === 3 ? Math.round(MlaiWieght) :
                Math.round(MlaiPacks)
              } {
                 calculationMethod === 2 ? <PalletIcon /> :
                 calculationMethod === 3 ? <ScalesIcon /> :
                   <PackageIcon />
              } מלאי
              </>
              
            }
            icon={<></>}
          />
          <TitleRowItem 
            name={
              <>
              {
                calculationMethod === 2 ? OrdersPallets :
                calculationMethod === 3 ? OrdersWieght :
                OrdersPacks
              } {
                calculationMethod === 2 ? <PalletIcon /> :
                calculationMethod === 3 ? <ScalesIcon /> :
                <PackageIcon />
              } הוזמן
              </> 
            }
            icon={<></>} 
          />
        </TitleRow>
        <TitleRow>
          {
            calculationMethod === 2 ?
              <TitleRowItem 
                name={
                  <>
                  {orderedPallets} <PalletIcon /> רכש
                  </>
                } 
                icon={<></>}
              />
              : calculationMethod === 3 ?
                <TitleRowItem 
                  name={
                    <>
                    {orderedWeight} <ScalesIcon /> רכש
                    </>
                  } 
                  icon={<></>} 
                />
                : 
                <TitleRowItem 
                  name={
                    <>
                    {orderedPackages} <PackageIcon /> רכש
                    </>
                  } 
                  icon={<></>} 
                />
          }
          <TitleRowItem
            name={`${item.forecastList.length}`}
            icon={<UserIcon />}
            iconColor={item.forecastList.length > 0 ? 'tomato' : ''}
          />
        </TitleRow>

      </HeadWrapper>
      {
        isOpenNestedList &&
        <BodyWrapper>
          {
            forecastList.map((item, index) =>
              <AddProductNestedItem
                key={item.RecID}
                item={item}
                {...item}
                index={index}
                handleChange={handleChangeForecasts}
                calculationMethod={calculationMethod}
              />

            )
          }
          <AddGrower
            growerID={growerID}
            setGrowerID={setGrowerID}
            setQty={setGrowerQty}
            qty={growerQty}
            growerList={growerList}
            ProductID={ProductID}
            handleClose={handleControlGrowerMenu}
            handleSave={handleAddGrowerAsForecast}
            isOpenAddGrowerMenu={isOpenAddGrowerMenu}
          />
 
        </BodyWrapper>
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5em;
  gap: 0.5em;
  flex-flow: column;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
`
const HeadWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const SideWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 3em;
  height: 3em;
`
const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  padding: 0.5em;
  background: #f9f9f9;
  border-radius: 20px;
`
const AddButton = styled.button`
  width: 100%;
  padding: 0.5em;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #8CC53D;
  border-radius: 20px;
  color: #fff;
  opacity: 0.7;
  transition: all 0.5s ease;
  font-size: 1.05em;
  &:hover {
    opacity: 1;
  }
`

const GrowerMenuWrapper = styled.div`
position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 20px;
  background: #fff;
`
const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  padding: 0.5em;

`
const CloseButton = styled.div`
  position: absolute;
  top: -0.3em;
  right: -0.3em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f9f9f9;
  color: #8A8AAD;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
`
export default AddProductItem