import React from 'react'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import { IClientBillsDetails } from '../../../../../../modules/API/Bills/models/clientBills'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, InvoiceIcon, LinkIcon, PackageIcon, PalletIcon, ScalesIcon, ShekelIcon } from '../../../../../../generic/icons/Icons'
import styled from 'styled-components'

interface Props extends IClientBillsDetails {}

const ClientBillDetailsItem = ({
  GeneralProductID, ProductDesc, VarietyDesc, SizeDesc, Delivery_Num, 
  Delivery_Sub, Reference, Price, Weight, Pallets_Quantity, Quantity
}: Props) => {
  return (
    <TitleLayout>
      <ImageWrapper>
        <ProductImage size='sm' id={GeneralProductID} />
      </ImageWrapper>
      <TitleRow>
        <TitleRowItem name={`${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeDesc?.trim()}`} icon={<AppleIcon />}/>
      </TitleRow>
      <TitleRow>
        <TitleRowItem name={`${Reference}`} icon={<LinkIcon />}/>
        <TitleRowItem name={`${Delivery_Num}-${Delivery_Sub}`} icon={<InvoiceIcon />}/>
      </TitleRow>
      <TitleRow>
        <TitleRowItem name={`${Price.toFixed(1)}`} icon={<ShekelIcon />}/>
        <TitleRowItem name={`${Math.round(Weight)}`} icon={<ScalesIcon />}/>
        <TitleRowItem name={`${Quantity}`} icon={<PackageIcon />}/>
      </TitleRow>
    </TitleLayout>
  )
}
const ImageWrapper = styled.div`
  position: absolute;
  left: 0.5em;
  top: 0.3em;
  height: 100%;
`
export default ClientBillDetailsItem