import React from 'react'
import styled from 'styled-components'
import selectOnFocus from '../../../modules/autoselect'
import { EditIcon, PackageIcon, PalletIcon } from '../../icons/Icons'

type Props = {
    isArizot: boolean,
    defaultQty: number,
    Description: string,
    isActiveButton?: boolean,
    handleButton?: () => void,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    disableInput?: boolean
}

const ArizotItemLayout = ({
    isArizot, defaultQty, Description, isActiveButton, handleButton, onChange, disableInput
}: Props) => {
    const renderIcon = isArizot ? <PackageIcon /> : <PalletIcon />
    console.log(isArizot)
    const desc = 
        Description && Description?.trim().length > 17 ? 
        '...' + Description?.trim().slice(0, 17) : Description?.trim()
  return (
    <MainWrapper>
        <ContentWrapper>
            <RawWrapper>
                {/* <RawInner>
                    {defaultQty} - 
                </RawInner> */}
                <InputWrapper>
                    <InputIcon>{renderIcon}</InputIcon>
                    <Input 
                        type={'number'} 
                        min={0}
                        max={999}
                        defaultValue={defaultQty}
                        onChange={onChange}
                        disabled={disableInput}
                        onFocus={selectOnFocus}
                    />
                </InputWrapper>
                <RawInner isArizot={isArizot}>
                    {desc} 
                </RawInner>
            </RawWrapper>
        </ContentWrapper>
        <ButtonWrapper 
            isActive={isActiveButton}
            onClick={handleButton}
        >
            <EditIcon />
        </ButtonWrapper>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    width: 100%;
    min-height: 4em;
    padding: 0 0.5em;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-radius: 30px;
    background: #fff;
    color: #838484;
    box-shadow:  4px 4px 10px #c1c1c1,
             -7px -7px 10px #ffffff;


`
const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: blue; */
    display: flex;
    flex-flow: column;
`
const RawWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.5em;
    gap: 1em;
    @media screen and (max-width: 400px) {
        justify-content: space-between;
    }
`
const RawInner = styled.div<{isArizot?: boolean}>`
    display: flex;
    width: 10em;
    justify-content: flex-end;
    /* background: red; */
    gap: 0.5em;
    i {
        color: ${props => props.isArizot ? "#A1E396" : '#7ab0ab'}
    }
`
const ButtonWrapper = styled.div<{isActive?: boolean}>`
    width: 4em;
    height: 100%;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in;
    cursor: ${props => props.isActive ? 'pointer' : 'default'};
    i {
        display: ${props => props.isActive ? 'block' : 'none'};
        color: #7ab0ab

    }
    :hover i {
        color: tomato;
        transform: scale(1.2);
    }
`
const InputWrapper = styled.div`
    position: relative;
    width: 4.2em;
    height: 2em;
    /* background: red; */
`
const InputIcon = styled.div`
    position: absolute;
    height: 2em;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
`
const Input = styled.input`
    direction: rtl;
    width: 100%;
    height: 100%;
    padding: 0.2em 0.5em;
    outline: none;
    border: none;
    color:#7ab0ab;
    border-bottom: 2px solid #7ab0ab;
    :disabled {
        opacity: 0.7;
        background: #fff;
    }
`
export default ArizotItemLayout