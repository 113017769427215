import React, {useState, useEffect, useMemo} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import DriversReportMenuModal from './Components/Modal/DriversReportMenuModal'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { SettingMiksher } from '../../../../../generic/icons/Icons'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IFreightCompanyItem } from '../../../../../modules/API/Defaults/models/freightCompany'
import styled from 'styled-components'

type Props = {}

const DriversReportMain = (props: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(true)
  const {
    data: freightCompanyList
  } = useDataFetcher<IFreightCompanyItem[]>(
    Defaults.getTblFreightCompany, 'data'
  )
  const handleMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }
  const menuProps = {
    freightCompanyList: freightCompanyList
  }
  return (
    <OrderlikeLayout label='דוחות הפצה'>
      {
        isOpenMenu &&
        <ColumModal
          title='דוחות הפצה'
          Children={DriversReportMenuModal}
          childrenProps={menuProps}
          controlFunc={handleMenu}
        />
      }
      <ControlSearchPannelLayout>
        <CircleActiveFilterBtn
          func={handleMenu}
        >
          <SettingMiksher />
        </CircleActiveFilterBtn>
        <SearchInput />
      </ControlSearchPannelLayout>
    </OrderlikeLayout>
  )
}

export default DriversReportMain