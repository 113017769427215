import React from 'react'
import styled from 'styled-components'
import TeudaItem from '../../../../kosherTeudot/TeudaItem'
import { ITeudotItem } from '../../../../../../../../models/RequestsModel/teudot'
import { ITeudotSmsData } from '../../../../../../../modules/API/Certificates/models/smsData'

interface Props extends ITeudotItem {
  item: ITeudotItem,
  isMashgiah: boolean
  print: (CODE: number, SUBCODE: number) => void,
  fetchItem: (deliveryNum: number, deliverySub: number) => void
  sendSms: (data: ITeudotSmsData) => void,
  isAllowToSendSms: boolean
}

const TeudaItemMarket = ({
  item, isMashgiah, print, fetchItem, sendSms, isAllowToSendSms
}: Props) => {
  const handleFetchItem = () => {
    fetchItem(item.Delivery_Num, item.Delivery_Sub)
  }
  return (
    <MainWrapper
      onClick={handleFetchItem}
    >
      <TeudaItem 
        item={item}
        {...item}
        isMashgiah={isMashgiah}
        sign={() => {}}
        print={print}
        sendSms={sendSms}
        isAllowToSendSms={isAllowToSendSms}
      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
`
export default TeudaItemMarket