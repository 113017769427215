import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
import { BasketIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import Title from '../../../../../../generic/Title/Title'
import TitlesList from '../../../../../../generic/titlesRowItem/TitlesList'
import { ContentWrapper, Decorator } from '../../../secondbar/mainbar2'
import { HeaderInformationInnerItem, HeaderInformationItem, HeaderInformationWrapper, HeaderItemInfo } from '../../likutSection/LikutSectionMain'
import SiumLikutItem from './SiumLikutItem'
import { useTranslation } from 'react-i18next'

const MainWrapperSium = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: rgba(0,0,0, 0.3);
    z-index: 99;
`
const ContentWrapperSium = styled.div`
    width: 100%;
    height: 100%;
    /* background: blue; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0.5em;
    
    @media screen and (min-width: 600px) {
        width: 500px;
    }
`
const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #e9f0f0;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.5em 1em;
    gap: 0.5em;
`
export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
`
export default function SiumLikutModal({
    azmanaDetails, azmanotListForSiumLikut, siumLikut, 
    siumModalIsOpen, setSiumModalIsOpen, isWeightCalculation
}) {
    const [t] = useTranslation(['likut', 'buttons']);
    const likutInfo = useSelector(info => info.likut);

    return (
        <MainWrapperSium>
            <ContentWrapperSium>
                <MainContainer >
                    <Decorator />
                    <Title label={t('likut_modal_sium_likut_title')} />
                    <HeaderInformationWrapper style={{ width: '100%' }}>
                        <HeaderInformationInnerItem>
                            <HeaderInformationItem >
                                <HeaderItemInfo>
                                    {likutInfo.azmanaNum}
                                </HeaderItemInfo>
                                <HeaderItemInfo>
                                    <BasketIcon />
                                </HeaderItemInfo>
                            </HeaderInformationItem>
                            <HeaderInformationItem >
                                <HeaderItemInfo>
                                    {azmanaDetails?.Customer_Name}
                                </HeaderItemInfo>
                                <HeaderItemInfo>
                                    <UserIcon />
                                </HeaderItemInfo>
                            </HeaderInformationItem>
                        </HeaderInformationInnerItem>
                    </HeaderInformationWrapper>
                    <ContentWrapper>
                        <TitlesList 
                            list={azmanotListForSiumLikut} 
                            Element={SiumLikutItem}
                            uniqueKey={'id'}
                            additionalData={{
                                isWeightCalculation: isWeightCalculation
                            }}
                        />
                    </ContentWrapper>
                    <ButtonContainer>
                        <ControlButton 
                            lable={t("cancel", {ns: 'buttons'})}
                            handleClick={() => {setSiumModalIsOpen(false)}}
                        />
                        {
                            azmanotListForSiumLikut?.some(e => e.ChangeReason === 0 || e.ChangeReason === 4) ? 
                                '' 
                                    : 
                                        <ControlButton 
                                            lable={t("save", {ns: 'buttons'})}
                                            handleClick={() => {
                                                siumLikut(likutInfo.azmanaNum)
                                            }}
                                        />
                        }
                    </ButtonContainer>
                </MainContainer>
            </ContentWrapperSium>
        </MainWrapperSium>
    )
}
