import { useState } from "react";
import { useRef } from "react";
import { useInterval } from '../../../modules/autoCarusel'
import styled from "styled-components";



const Section = styled.section`
    width: 100%;
    height: 100vh;
    position: relative;
    

    
`

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    transform: translateX(0%);
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    
   
`
const WindowSliderContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
`
const ShoresContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;

`

const Shore = styled.div`
    width: 100%;
    height: 25%;
    background: #fff;
    opacity: 0.4;
    z-index: 1;
    transition: 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 50px;
`
const ShoreOne = styled(Shore)`
    opacity: 0.6;
    transition: 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`
const ShoreTwo = styled(Shore)`
    opacity: 0.7;
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`
const ShoreThree = styled(Shore)`
    opacity: 0.9;
    transition: 1.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`

const ControlWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 4em;
    /* background: blue; */
    bottom: -2em;
    /* opacity: 0.5; */
    z-index: 4;
`
const ControlContainer = styled.div`
    width: 7em;
    height: 100%;
    /* background: purple; */
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #473a91;
    border-radius: 10px;
    box-shadow: 4px 4px 4px 3px rgba(34, 60, 80, 0.3);
`
const ControlButton = styled.button`
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 2px solid #fff;
    background: none;
    transition: 0.2s ease;
    cursor: pointer;
    
    :hover {
        background: #fff;
        border: 2px solid #fff;
        transform: scale(1.2);
    }
`
const Container = styled.div`
    min-width: 100%;
    height: 100%;
    background: ${props => props.primary ? "#0fa958" : props.new ? '#c7b9ff' : '#ffc700'};
    background-size: cover;
    
`
const ContainerItemWrapper = styled.div`
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    /* background: blue; */
    @media screen and (max-width: 850px){
        flex-flow: column;
        justify-content: center;
        align-items: center;
         
    }
`
const ContainerInner = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 1em;

    h1 {

        font-size: 5em;
        /* background: yellow; */
        /* padding: 0.2em 0; */
        text-align: right;
        position: relative;
        z-index: 1;
        margin-bottom: 0.5em;
    }

    span {
        font-size: 3em;
        text-align: right;
        font-weight: 600;
    }

    p {
        font-size: 1.5em;
        text-align: right;
    }

    @media screen and (max-width: 850px){
        /* margin-top: 1em; */
        height: 30%;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 400px){
        margin-bottom: 2.5em;

        h1 {
            font-size: 3em;
            text-align: center;
        }

        span {
            width: 100%;
            font-size: 1.5em;
            text-align: center;
            margin-bottom: 0.5em;
        
        }

        p {
            font-size: 1em;
            text-align: center;
        }
    }
`
const OutDiv = styled.div`
    position: absolute;
    bottom: 0.2em;
    width: 3em;
    height: 0.5em;
    background: ${props => props.primary ? '#6c93f1' : props.second ? '#c7b9ff' : '#ffc700'};
    z-index: -1;
`

const ContainerItem = styled.div`
    height: 100%;  
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    :first-of-type {
        justify-content: flex-start;
        @media screen and (max-width: 850px){
        margin-top: 3em;
        height: 30%;
        justify-content: center;
        align-items: center;
         
        }
    }
    
    /* background: red; */
    /* border: 1px solid black; */
    img {
        min-width: 500px;
        @media screen and (max-width: 1100px) {
            min-width: 300px;
        }

        @media screen and (max-width: 500px) {
            min-width: 200px;
        }
    }
`


export default function MainCarusel(){
    const [position, setPosition] = useState(0);
    const [animationShore, setAnimationShore] = useState(false);
    const refCarusel = useRef(null)
    const caruselToggle = (position) => {
        // console.log(refCarusel.current.style)
        // position === -200? setPosition(0) : setPosition(position - 100)
        refCarusel.current.style.transform = `translateX(${position}%)`
    }
    const transDiv = () => {
        position === -200 ? setPosition(0) : setPosition(position - 100)
        setAnimationShore(!animationShore)
    }
    useInterval(transDiv, 10000)

    // const currentWidth = refCarusel.current !== null ? refCarusel.current.offsetWidth : 0
    // console.log(currentWidth)
    // const actionWidth = currentWidth * .3
   
        // const currentWidth = refCarusel.current !== null ? refCarusel.current.offsetWidth : 0;
        // const actionWidth = currentWidth * .3;
        // window.addEventListener('touchmove', (e) => {
            
        //     // console.log(e.targetTouches[0].pageX)
        //     refCarusel.current.style.transform = `translateX(${e.targetTouches[0].pageX}px)`
        //     // console.log(actionWidth)
        // })
 
    let animationToggle = animationShore? 'translateX(100%)' : 'translateX(-100%)'
    return(
        <Section>
            <ControlWrapper>
                <ControlContainer>
                    <ControlButton style={{background: position === 0 ? '#fff': 'none', transform: position === 0 ? 'scale(1.2)' : 'scale(1)'}} 
                                   onClick={() => {caruselToggle(0); setPosition(0); setAnimationShore(!animationShore)}} />
                    <ControlButton style={{background: position === -100 ? '#fff': 'none', transform: position === -100 ? 'scale(1.2)' : 'scale(1)'}}
                                   onClick={() => {caruselToggle(-100); setPosition(-100); setAnimationShore(!animationShore)}} />
                    <ControlButton style={{background: position === -200 ? '#fff': 'none', transform: position === -200 ? 'scale(1.2)' : 'scale(1)'}}
                                   onClick={() => {caruselToggle(-200); setPosition(-200); setAnimationShore(!animationShore)}} />

                </ControlContainer>
            </ControlWrapper>
            <WindowSliderContainer>
                {/* n */}
                <ShoresContainer>
                    <Shore style={{transform: animationToggle}}></Shore>
                    <ShoreOne style={{transform: animationToggle}}></ShoreOne>
                    <ShoreTwo style={{transform: animationToggle}}></ShoreTwo>
                    <ShoreThree style={{transform: animationToggle}}></ShoreThree>
                </ShoresContainer>
                <MainWrapper draggable={false}  ref={refCarusel} style={{transform: `translateX(${position}%)`}}>
                    <Container new >
                        <ContainerItemWrapper>
                            <ContainerItem><img src="/images/buhgalteriya.png" alt={'count'} /></ContainerItem>
                            <ContainerItem>
                                <ContainerInner>
                                    <h1>מודול <br/>הנה"ח <OutDiv></OutDiv></h1> 
                                    <span>מענה מקיף לנושא הכספי</span>
                                    <p>ורווחיות של העסק, התחשבנות מלאה מול כל הצדדים</p> 
                                </ContainerInner>
                                
                            
                            </ContainerItem>
                        </ContainerItemWrapper>
                    </Container>
                    <Container >
                        <ContainerItemWrapper>
                            <ContainerItem><img src="/images/Otcheti.svg" alt={'reports'} /></ContainerItem>
                            <ContainerItem>
                                <ContainerInner>
                                    <h1>תעודות <br/>משלוח <OutDiv primary></OutDiv></h1>
                                    <span>הפקת תעודה בקלות</span>
                                    <p>מהמחשב וגם מהנייד ברמת פרוט שלא הכרתם</p>
                                </ContainerInner>
                                
                            </ContainerItem>
                        </ContainerItemWrapper>
                    </Container>
                    <Container primary >
                        <ContainerItemWrapper>
                            <ContainerItem><img src="/images/FirstScreen.svg" alt={'data'} /></ContainerItem>
                            <ContainerItem>
                                <ContainerInner>
                                    <h1>BI<br/>מובנה <OutDiv second></OutDiv></h1>
                                    <span style={{letterSpacing: '-1px'}}>שאילתות במגוון חתכים </span>
                                    <p> כולל השוואות עם תוצאות בשניות </p>
                                </ContainerInner>
                            </ContainerItem>
                        </ContainerItemWrapper>
                    </Container>
                    
                </MainWrapper>
            </WindowSliderContainer>
        </Section>
    )
}