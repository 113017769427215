import DatePicker from "react-datepicker";
import { useState } from "react";
import { SelectPanelWrapper } from "../../secondbar/azmanotmain/SelectBar";
import { DateWrapper } from "../../secondbar/modalAzmanot/modalAzmanot";
import SearchInput from "../../../../../generic/inputs/inputSearch";
import { useTranslation } from "react-i18next";

export default function ArizotHead({ arizotListDefault, setArizotList, startDate, setStartDate, endDate, setEndDate }) {
    const [t] = useTranslation('packageOrder')
    const [input, setInput] = useState('')
    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const filterArizot = (input) => {
        const filtered = arizotListDefault.filter(item => {
            return item.Vehicle_Num?.toString().toLowerCase().includes(input.toLowerCase()) || item.RecipientName?.toLowerCase().includes(input.toLowerCase())
        })
        setInput(input)
        setArizotList(filtered)
    }
    return (
        <SelectPanelWrapper>
            <DateWrapper >
                <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    className="datePicker"
                    selectsRange
                    shouldCloseOnSelect={false}
                    dateFormat="dd/MM/yy"
                />
            </DateWrapper>
            <SearchInput value={t('packageOrder_orders_search_placeholder')} input={input} setInput={filterArizot} />
        </SelectPanelWrapper>
    );
}