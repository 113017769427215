import React from 'react'
import styled from 'styled-components'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { IOrderReheshDetailes } from '../../../../../../modules/API/AzmanotRehesh/models/order'
import { PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import RowInput from '../../../../../../generic/inputs/RowInput'

interface Props extends IOrderReheshDetailes {
  handleChangeQty: (qty: number, index: number) => void
  index: number
  calculationMethod: number
}

const EditProductNestedItem = ({
  GrowerName,
  OrderPacks,
  Order_Pallets,
  OrderWeight,
  handleChangeQty,
  index,
  calculationMethod
}: Props) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    handleChangeQty(value, index)
  }
  return (
    <NestedItemWrapper>
      <TitleRow>
        <TitleRowItem
          icon={<UserIcon />}
          name={`${GrowerName.trim()}`}
        />
      </TitleRow>
      <TitleRow>
        <RowInput 
          icon={
            calculationMethod === 2 ? <PalletIcon /> :
            calculationMethod === 3 ? <ScalesIcon /> :
            <PackageIcon />
          }
          value={
            calculationMethod === 2 ? Order_Pallets :
            calculationMethod === 3 ? OrderWeight :
            OrderPacks
          }
          handleInput={handleChange}
          isDisabled={false}
        />
      </TitleRow>
    </NestedItemWrapper>
  )
}
const NestedItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  background: #fff;
  border-radius: 20px;
`
export default EditProductNestedItem