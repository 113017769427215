
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { EmptyFlag, FilledFlag } from '../../../../../generic/icons/Icons'
import { IconWrapperOrdersSecond } from './LikutSetWorkers'
import { useDispatch } from 'react-redux'

export const InnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-flow: column;
    color: #75759e;
    padding: ${props => props.primal ? '0 4em' : '0 2.5em'} ;
`
export const ItemContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 5em;
    background: #fff;
    border-radius: 20px;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
    /* transition: 1s ease; */
`

export const CheckboxWrapper = styled.div`
    position: absolute;
    left: 0;
    height: 100%;
    width: 4em;
    /* background: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.primal ? '#fff' : '#297f76'};
    cursor: pointer;

`
export const FlexItem = styled.div`
    width: 100%;
    height: 1.7em;
    /* background: blue; */
    display: flex;
    justify-content: ${props => props.primal ? 'center' : 'flex-end'};
    align-items: center;
    gap: 0.5em;
`

export default function LikutWorkerItem({
    WorkerName, WorkerId, onSelectWorker,
    setWorkerId, workerId, setConfermedWorkers,
    confermedWorkers, confermed, workersList,
    setWorkersList, setConfermedWorkersDefault,
    confermedWorkersDefault, azmanotListDefault,
    currentWorkerAzmanot, azmanotList, droppableItem

}) {
    const dispatch = useDispatch();
    const [isSelect, setIsSelect] = useState(false)
    const [qtyAzmanotWorkers, setQtyAzmanotWorker] = useState(null)
    const [sumPackages, setSumPackages] = useState(null);
    const [azmanotCompleted, setAzmanotCompleted] = useState(null)
    
    const handleConferm = () => {
        let found = workersList.find(item => item.WorkerId === WorkerId);
        let foundInconf = confermedWorkers.find(item => item.WorkerId === WorkerId);
        let foundInconfDef = confermedWorkers.find(item => item.WorkerId === WorkerId);
        console.log(confermedWorkersDefault)
        if (found && !foundInconf) {
            console.log(workersList)
            setWorkersList(workersList.filter(e => e.WorkerId !== found.WorkerId))
            workersList.splice(found, 1)

            setConfermedWorkers([
                ...confermedWorkers,
                {
                    ...found,
                    confermed: !confermed
                }
            ])
            setConfermedWorkersDefault([
                ...confermedWorkersDefault,
                {
                    ...found,
                    confermed: !confermed
                }
            ])

        }
        if (!found && foundInconf) {
            console.log('123')
            // console.log(confermedWorkers.filter(e => e.WorkerId === foundInconf.WorkerId))
            setConfermedWorkers(confermedWorkers.filter(e => e.WorkerId !== foundInconf.WorkerId));
            setConfermedWorkersDefault(confermedWorkers.filter(e => e.WorkerId !== foundInconf.WorkerId));
            confermedWorkers.splice(foundInconf, 1);
            confermedWorkersDefault.splice(foundInconfDef, 1);
            setWorkersList([
                ...workersList,
                {
                    ...foundInconf,
                    confermed: !confermed
                }
            ])
        }

        // let indx = workersList.indexOf(found)
        // console.log(indx)
    }
    const quantityAzmanotByWorker = () => {
        const filtered = WorkerId === workerId ? currentWorkerAzmanot : azmanotListDefault.filter(azmana => azmana.WorkerID === WorkerId);
        const sum = filtered.reduce((acc, cur) => {
            return acc += cur.Packs
        }, 0)
        const completed = filtered.filter(item => item.Status === 3 || item.Status === 9)
        setAzmanotCompleted(completed.length)
        setSumPackages(sum)
        // console.log('gogo')
        setQtyAzmanotWorker(filtered.length)
    }
    
    useEffect(() => {
        quantityAzmanotByWorker();
    }, [azmanotListDefault, droppableItem, currentWorkerAzmanot])
    return (
        <ItemContainer
            style={{ background: isSelect && workerId === WorkerId ? 'rgba(41,127,118, 0.7)' : '#fff' }}
            onClick={() => {
                if (confermed) {
                    console.log('+')
                    onSelectWorker(WorkerId);
                    setIsSelect(true);
                    setWorkerId(WorkerId)
                }

            }}
        >
            <CheckboxWrapper primal={isSelect && workerId === WorkerId} onClick={handleConferm} >
                {confermed ? <FilledFlag /> : <EmptyFlag />}
            </CheckboxWrapper>
            {
                qtyAzmanotWorkers > 0 ?
                    <IconWrapperOrdersSecond style={{top: '1em'}} primal={true} >
                        {qtyAzmanotWorkers}
                    </IconWrapperOrdersSecond>
                    :
                    ''
            }
            <InnerWrapper style={{color: (isSelect && workerId === WorkerId) ? '#fff' : ''}} primal>
                <FlexItem >
                    {WorkerName}
                </FlexItem>
                <FlexItem >
                    {`${azmanotCompleted}/${qtyAzmanotWorkers}`} :לקוט
                </FlexItem>
                <FlexItem >
                    {sumPackages} :אריזות
                </FlexItem>
            </InnerWrapper>
            
        </ItemContainer>
    )
}
