import React from 'react'
import styled from 'styled-components';

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

function Table({
    children
}: Props) {
  return (
    <TableWrapper>
        <TableStyle>
            {children}
        </TableStyle>
    </TableWrapper>
  )
}
const TableWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 30px;
    ::-webkit-scrollbar {
      width: 0;
    }
`

const TableStyle = styled.table`
    position: relative;
    direction: rtl;
    width: 100%;
    background: #E9F0F0;
    padding: 0.5em;
    border-radius: 30px;
`
export default Table