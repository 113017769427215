import React, {useState, useMemo} from 'react'
import styled from 'styled-components'
import Image from '../../../../../generic/assets/collect.png'
import { IOrderItem } from '../../../../../modules/API/Drivers/models/kavData';
import ControlButton from '../../../../../generic/buttons/ControlButton';
type Props = {
  item: IOrderItem;
  saveFunc: (likut: string, hafa: string) => void;
}
const remarksList = ['ליקוט', 'הפצה']
function RemarksControl({item, saveFunc}: Props) {

  const [activeTab, setActiveTab] = useState<string | null>(remarksList[1]);
  const [likutInput, setLikutInput] = useState<string>(item?.Collect_Comm || '');
  const [hafazaInput, setHafazaInput] = useState<string>(item?.DistributNote || '');
  
  const defaultInput = useMemo(() => {
    return activeTab === remarksList[0] ? likutInput : hafazaInput;
  }, [activeTab, likutInput, hafazaInput]) 
  
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if(activeTab === remarksList[0]) {
      setLikutInput(e.target.value)
    } else {
      setHafazaInput(e.target.value)
    }
  }
  
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  }
  
  const handlButtonSave = () => {
    saveFunc(likutInput, hafazaInput)
  }

  return (
    <MainWrapper>
        <Img
          src={Image}
        />
        <ContentWrapper>
          <TabContainer>
          {remarksList.map((type) => (
            <Tab
              key={type}
              active={activeTab === type}
              onClick={() => handleTabClick(type)}
            >
              {type}
            </Tab>
          ))}
          </TabContainer>
          <TextFieldWrapper>

            <TextField 
              cols={30}
              rows={11}
              // readOnly={!isActive}
              value={defaultInput}
              // defaultValue={defaultInput}
              onChange={handleOnChange}
            />
          </TextFieldWrapper>
        </ContentWrapper>
        <ControlButton 
          lable={'שמור'} 
          handleClick={handlButtonSave}
        />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 0.5em;
`
const Img = styled.img`
  min-width: 250px;
`
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #eff3f4;
  display: flex;
  flex-flow: column;
  border-radius: 30px ;
`
const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
  border-radius: 30px 30px 0 0;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${(props) =>
    props.active
      ? `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
      : `
            
            `}
`;
const TextFieldWrapper = styled.div`
  padding: 0.5em;
`
const TextField = styled.textarea`
    padding: 1em 0.5em;
    letter-spacing: 0.5px;
    border-radius: 30px;
    font-size: 1.2em;
    direction: rtl;
    border: 2px solid #7ab0ab;
    outline: none;
    color: #757575;
    resize: none;
    ::-webkit-scrollbar {
        width: 0
    }
    :disabled {
        background: #ffff;
    }
`
export default RemarksControl