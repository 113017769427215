import React from 'react'
import styled from 'styled-components'
import { ArrowIcon } from '../../../../../../../generic/icons/Icons'

type Props = {
  handleSelect: (id: number) => void,
  defaultType?: number
  disabled?: boolean
}

const types = [
  {
    id: 0,
    desc: 'משקל',
  },
  {
    id: 1,
    desc: 'כמות',
  },
]
const TypeCalcSelect = ({
  handleSelect, defaultType, disabled
}: Props) => {
  
  const handle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(+e.target.value === -1) return
    handleSelect(+e.target.value)
  }
  console.log('defaultType', defaultType)
  return (
    <MainWrapper>
      <SideIcon>
        <ArrowIcon position={'bottom'} />
      </SideIcon>
      <Select
        onChange={handle}
        defaultValue={defaultType}
        disabled={disabled}
      >
        <option value={-1}>בחר סוג חישוב</option>
        {
          types.map(e => 
            <option value={e.id}>{e.desc}</option>
          )
        }
      </Select>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  padding: .2em;
  background: #EFF3F4;
  border-radius: 30px;
  position: relative;
`
const Select = styled.select`
  width: 100%;
  direction: rtl;
  border-radius: 30px;
  padding: 0.2em 0.5em;
  font-size: 1.1em;
  border: none;
  outline: none;
  appearance: none; 
  background-color:#EFF3F4;
  color: #838484;
  cursor: pointer; 

`
const SideIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 2.2em;
  height: 2.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #838484;
  font-size: 0.9em;
`
export default TypeCalcSelect