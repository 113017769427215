import React, { useMemo } from 'react'
import { ISupplyGrowerDataProduct } from '../../../../../../modules/API/Forcasts/models/seller'
import styled from 'styled-components'
import ProductLayout from '../generic/ProductLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PackageIcon, PalletIcon, ScalesIcon } from '../../../../../../generic/icons/Icons'

interface Props extends ISupplyGrowerDataProduct {
  item: ISupplyGrowerDataProduct
}

const GrowerProductItem = ({
  Product, 
  item, 
  OrderPacks, 
  OrderWeight, 
  Order_Pallets,
  SupplyAllQty,
  SupplyAll_Pallets,
  SupplyAllWeight,
  SupplyQty,
  SupplyWeight,
  Supply_Pallets
}: Props) => {

  const color = useMemo(() => {
    const colorObg = {
      pallet: Math.round(Order_Pallets - SupplyAll_Pallets) === 0 ? 
        '#8CC53D' : 
        'orange',
      weight: Math.round(OrderWeight - SupplyAllWeight) === 0 ?
        '#8CC53D' : 
        'orange',
      qty: OrderPacks - SupplyAllQty === 0 ?
        '#8CC53D' : 
        'orange'
    }
    return colorObg

  }, [
    SupplyAllQty, 
    OrderPacks, 
    SupplyAllWeight, 
    OrderWeight, 
    SupplyAll_Pallets, 
    Order_Pallets
  ])

  return (
    <MainWrapper>
      <ProductLayout
        product={Product}
      >
        <RowWrapper>

          <TitleRow>
            <TitleRowItem 
              name={`${Math.round(Order_Pallets - SupplyAll_Pallets)}`}
              icon={<PalletIcon />}
              iconColor={color.pallet}
            />
            {/* <TitleRowItem 
              name={`${Math.round(OrderWeight - SupplyAllWeight)}`}
              icon={<ScalesIcon />}
              iconColor='orange'
            /> */}
            <TitleRowItem 
              name={`${OrderPacks - SupplyAllQty}`}
              icon={<PackageIcon />}
              iconColor={color.qty}
            />
          </TitleRow>
        </RowWrapper>

        <TitleRow>
          <TitleRowItem 
            name={`${Math.round(Supply_Pallets)} / ${Math.round(Order_Pallets)}`}
            icon={<PalletIcon />}
            
          />
          {/* <TitleRowItem 
            name={`${SupplyWeight} / ${Math.round(OrderWeight)}`}
            icon={<ScalesIcon />}
          /> */}
          <TitleRowItem 
            name={`${SupplyQty} / ${OrderPacks}`}
            icon={<PackageIcon />}
          />
          
        </TitleRow>
      
      </ProductLayout>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  /* background: ; */
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  cursor: pointer;
`
const RowWrapper = styled.div`
  width: 100%;
  padding: 0.2em;
  background: #f0f0f0;
  border-radius: 10px;
  /* margin-left: 1em; */
  /* display: flex; */

  /* justify-items: flex-start; */

`
export default GrowerProductItem