import React, { useMemo, useState } from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import DatePickerISO from '../../forcasts/Distribution/Components/DatePickerISO'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IFlowerPalletItem } from '../../../../../modules/API/Flowers/models/flowerPallet'
import { Flowers } from '../../../../../modules/API/Flowers/flowers'
import { format } from 'date-fns'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import TitleItem from './Components/TitleItem'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'


const TitlesMain = () => {
  const history = useHistory()
  const [search, setSearch] = useState<string>('')
  const [date, setDate] = useState<Date>(new Date())
  const {
    data: palletList
  } = useDataFetcher<IFlowerPalletItem[]>(
    Flowers.getPalletList, format(date, 'yyyyMMdd')
  )

  const render = useMemo(() => {
    if(!palletList) return []
    return palletList.filter(pallet => 
      pallet.Pallet_Num.toString().includes(search) ||
      pallet.Customer_Name.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      pallet.GrowerName.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [palletList, search])

  const handleSelectPallet = (pallet: IFlowerPalletItem) => {
    history.push(LINKS.mishtahim.likutPrahim.editByID + pallet.Pallet_Num)
  }
  const listProps = {
    handleClick: handleSelectPallet
  }
  return (
    
    <OrderlikeLayout
      label='title'
    >
      <ControlSearchPannelLayout>
        <DatePickerISO 
          selected={date}
          onChange={setDate}
        />
        <SearchInput 
          input={search}
          setInput={setSearch}
          value={'חיפוש משטח'}
        />
      </ControlSearchPannelLayout>
      <TitlesList 
        list={render}
        uniqueKey={'Pallet_Num'}
        additionalData={listProps}
        Element={TitleItem}
        noPadding={false}
      />
    </OrderlikeLayout>
  )
}

export default TitlesMain