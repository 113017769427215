import React from 'react'
import styled from 'styled-components'
import PropsTypes from 'prop-types'

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
`
const Img = styled.img`
    min-width: ${props => props.small ? '100px' : props.large ? '300px' : '200px'};
`
export default function ImageComponentHead({ img, small, large }) {
    return (
        <ImageWrapper>
            <Img
                src={img}
                small={small}
                large={large}
            />
        </ImageWrapper>
    )
}

ImageComponentHead.propTypes = {
    img: PropsTypes.string,
    small: PropsTypes.bool,
    large: PropsTypes.bool
}


