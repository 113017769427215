import React from 'react'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import { IExistCustomersOrders } from '../../../../../modules/API/Orders/models/existCustomersOrders'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { InvoiceIcon, UserIcon } from '../../../../../generic/icons/Icons'

interface Props extends IExistCustomersOrders {
  handleClick: (item: IExistCustomersOrders) => void;
  item: IExistCustomersOrders;
}

const CustomerItem = ({Customer_Name, OrdersCount, item, handleClick}: Props) => {
  const handleSelect = () => {
    handleClick(item)
  }
  return (
    <TitleLayout onClick={handleSelect}>
        <TitleRow >
            <TitleRowItem name={Customer_Name.trim()}  icon={<UserIcon />}/>
            <TitleRowItem name={`${OrdersCount}`}  icon={<InvoiceIcon />}/>
        </TitleRow>
    </TitleLayout>
  )
}

export default CustomerItem