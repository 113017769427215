import { useState } from 'react'
import styled from 'styled-components'
import {useInterval} from '../../../modules/autoCarusel'
const Section = styled.section`
    padding: 0 0.5em;
    margin-bottom: 100px;
    overflow: hidden;
`
const Wrapper = styled.div`
    max-width: 1400px;
    /* height: 50vh; */
    
    margin: 0 auto;
    margin-bottom: 1em
`
const ContainerMain = styled.div`
    /* background: paleturquoise; */
    display: flex;
    flex-flow: row-reverse wrap;
    /* height: 100%; */
    width: 100%;
    justify-content: center;
`

const ItemContainer = styled.div`
    /* background: purple; */
    width: 450px;
    height: 450px;
    display: flex;
    align-items: center;
    
    @media screen and (max-width: 1052px){
       align-items: flex-end;
       width: 550px;
       height: 250px;
    }
`
const ItemContainerFeed = styled(ItemContainer)`
    height: 450px;
    width: 550px;
    overflow-x: hidden;
`

const ItemContainerMiddle = styled.div`
    height: 450px;
    width: 20px;
    background: #fff;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 0 0.5em;
    span{
        display:flex;
        flex-flow: column;

    }

    @media screen and (max-width: 1052px){
        width: 550px;
        height: 20px;
        /* margin: 0 5em; */
        flex-flow: row;

        span{
            display:flex;
            flex-flow: row;

        }
    }
    @media screen and (max-width: 650px){
        margin: 0em;
    }

`
const ItemContainerInner = styled.div`
    width: 100%;
    height: 60%;
    background: #ffc700;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: flex-start;
    font-family: sans-serif;
    padding-top: 1em;
    padding-right: 1em;
    border-radius: 5px;
    
    h1 {
        font-size: 4em;
        text-align: right;
        @media screen and (max-width: 600px){
            font-size: 3.5em;
        }
    }
    p {
        padding-top: 0.2em;
        font-size: 1.5em;
    }
    @media screen and (max-width: 1052px){
        height: 100%;
        justify-content: flex-end;
        padding-top: 0em;
        padding-bottom: 1em;
        margin-bottom: 1em;
    }
`
const WrapperFeed = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1.5s;
    transform: translateX(0);
`
const ItemContainerInnerFeed = styled.div`
    min-width: 100%;
    height: 90%;
    background: #fff;
    display: grid;
    grid-template-columns:75% 25% ;
    grid-template-rows: 25% 75%;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.5em;
    }
    .col-1 {
        /* justify-content: flex-end; */
        padding-right: 2em;
        flex-flow: column;
        align-items: flex-end;
        font-family: sans-serif;
        h1 {
            text-align: right;
        }

    }
    .col-2 {
        
    }
    .col-3 {
        justify-content: flex-end;
        grid-row-start: 2;
        padding-right: 2em;
        background: #f2f2f2;
        overflow: hidden;
        p{
            padding-right: 30px;
            transform: translateX(50px);
            text-align: right;
            overflow-x: hidden;
            overflow-y: scroll;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 20px;     /* fallback */
            max-height: 500px;      /* fallback */
            -webkit-line-clamp: 10; /* number of lines to show */
            -webkit-box-orient: vertical;
            @media screen and (max-width: 600px){
                font-size: 0.9em;
            }
        }
    }
    .col-4 {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
    }
    .bg-1 {
        background: url('/images/man-min.jpg') center center;
        
        background-size: cover;
    }
    .bg-2 {
        background: url('/images/woman-min.jpg') center center;
        background-size: cover;
    }
`

export default function FeedBack() {
    const [position, setPosition] = useState(0)

    const transDiv = () => {
        position === -200 ? setPosition(0) : setPosition(position - 100)
    }

    // function useInterval(callback, delay) {
    //     const savedCallback = useRef();

    //     // Remember the latest callback.
    //     useEffect(() => {
    //         savedCallback.current = callback;
    //     }, [callback]);

    //     // Set up the interval.
    //     useEffect(() => {
    //         function tick() {
    //             savedCallback.current();
    //         }
    //         if (delay !== null) {
    //             let id = setInterval(tick, delay);
    //             return () => clearInterval(id);
    //         }
    //     }, [delay]);
    // }

    useInterval(transDiv, 10000)


    return (
        <Section>
            <Wrapper>
                <ContainerMain>
                    <ItemContainer>
                        <ItemContainerInner data-aos-once="true" data-aos="fade-right">
                            <h1>מה <br/>אומרים <br/>עלינו</h1>
                            {/* <p>who already with us:</p> */}
                        </ItemContainerInner>
                    </ItemContainer>
                    <ItemContainerMiddle data-aos-once="true" data-aos="fade-up">
                        <span >
                            <i className="far fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                        </span>
                        <span><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="far fa-star"></i></span>
                    </ItemContainerMiddle>
                    <ItemContainerFeed onClick={() => { transDiv() }} data-aos-once="true" data-aos="fade-left">
                        <WrapperFeed style={{ transform: `translateX(${position}%)` }}>
                            <ItemContainerInnerFeed >
                                <div className="col-1">
                                    <h1>אבי פיין</h1>
                                    <p>מנהל בית אריזה</p>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3">
                                    <p>מערכת ידידותית מאוד למשתמש וגמישה להפליא , כך שהיא מתאימה למגוון רחב שעוסקים בתחום החקלאות . אני למשל כמגדל משתמש בה לצרכים שלי וגם מנהל איתה בית אריזה משותף . ממליץ בחום  </p>
                                </div>
                                <div className="col-4 bg-1" ></div>
                            </ItemContainerInnerFeed>
                            <ItemContainerInnerFeed>
                                <div className="col-1">
                                    <h1>יעל מרגולין</h1>
                                    <p>מנהלת מערכות מידע</p>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3">
                                    <p>
                                        לאחר עבודה משותפת של יותר משנה כאחראית מערכת המידע ואפיונה , אני יודעת לומר שאתם צוות לעניין. שוקלים כל רעיון פיתוחי בצורה מקצועית ולא מוותרים לעצמכם. משרתת את עובדי מועצה מכל מחלקות החברה ומגדלים רבים באופן יומיומי 24/7 ללא תקלות. מסמכי הייצוא יוצאים כיום באופן אוטומטי ממערכת אגרוליין בלחיצת כפתור. וכמובן שישנם בקנה פיתוחים עתידיים שעוד ירקמו עור וגידים. בתקווה להמשך שיתוף עבודה פורה ומוצלח
                                    </p>
                                </div>
                                <div className="col-4 bg-2"></div>
                            </ItemContainerInnerFeed>
                            <ItemContainerInnerFeed>
                                <div className="col-1">
                                    <h1>שלומי עדי</h1>
                                    <p>סמנכ"ל אראל תוצרת חקלאית בע"מ</p>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3">
                                    <p>
                                    ברצוני להודות לך על המערכת הנפלאה שאנו עובדים איתה כבר מספר שנים.
                                    מערכת פשוטה וקלה לשימוש אך עם זאת מערכת מורכבת שנותנת פתרון לכל הצרכים הייחודיים של תחום הפירות והירקות .
                                    תודה על המענה המהיר לכל דרישה או בקשה שהצבנו בפניך גם בשעות לא מקובלות ,
                                    במהלך השנים פיתחנו ושידרגנו תוך שיתוף בעולה פורה וטוב את התוכנה כך שתתאים לצרכים של מרגלו"ג בסדר גודל שלנו. 
                                    היכולת של המערכת להתעדכן ולהשתדרג כל הזמן זה הפלוס הגדול ביותר בעבודה אתך ועל כך שאפו כל הכבוד. 
                                    בימים אלו בעזרתך אני שוקדים על המשך פיתוח מערכת חכמה של EDI מול הספקים ומערכת הזמנות משודרגת בסמרטפון להזמנות תוצרת מול הלקוחות 
                                    בעזרתך פיתחנו מערכת ייחודית לתחום התמרים אשר תתאים לצרכים של חברת שיווק וזאת תוך התרעה של זמן קצר מאוד.
                                    </p>
                                </div>
                                <div className="col-4 bg-2"></div>
                            </ItemContainerInnerFeed>
                        </WrapperFeed>
                    </ItemContainerFeed>
                </ContainerMain>
            </Wrapper>
        </Section>
    )
}