import selectOnFocus from '../../../../../../modules/autoselect'
import styled from 'styled-components'

const Input = styled.input`
      /* max-width:190px;
      border: none;
      padding:0.5rem;
      border-radius: 5px;
      letter-spacing: 0.5px;
      outline: none;
      text-align: right;
      z-index: 5;
      @media screen and (max-width: 600px){
        width: 300px;
      } */

`

const SearchBarGoods = ({input:keyword, onChange:setKeyword, imputGoods, value, id, rightsToggle}) => {
    const BarStyling = {
      maxWidth:"190px", 
      border:"none", 
      padding:"0.5rem", 
      borderRadius: "5px", 
      letterSpacing: "0.5px", 
      outline: "none",
      textAlign: 'right',
      zIndex: '0'
    };
    // console.log(rightsToggle)
    return (
      <Input 
          id={id}
          style={BarStyling}
          key="random2"
          value={imputGoods}
          placeholder={value || "חיפוש"}
          onChange={(e) => setKeyword(e.target.value)}
          onFocus={selectOnFocus}
          readOnly={rightsToggle}
      />
    );
  }
  
  export default SearchBarGoods