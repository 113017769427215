import React from 'react'
import styled from 'styled-components';
import SearchInput from '../../../../../generic/inputs/inputSearch';
import AzmanotDataPicker from '../../../../../modules/datepicker/datepicker';

export const SelectPanelWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 4.5em;     
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    background: #fdfdfd;
    padding: 0 0.5em;
    border-radius: 40px;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
`
const DatePickerWrapper = styled.div`
    
    
`

export default function SelectBar({setDate, setAzmanotDefault, input, setInput, value, dayJump, setSelectedDateStart, setSelectedDateEnd}) {
    return (
        <SelectPanelWrapper>
            <DatePickerWrapper>
                <AzmanotDataPicker 
                    setDate={setDate} 
                    setAzmanotDefault={setAzmanotDefault} 
                    dayJump={dayJump} 
                    setSelectedDateStart={setSelectedDateStart}
                    setSelectedDateEnd={setSelectedDateEnd}    
                />
            </DatePickerWrapper>
            <SearchInput input={input} setInput={setInput} />
        </SelectPanelWrapper>
    )
}
