import React from 'react'
import { IPalletDetails } from '../../../../../modules/API/Pallets/models/palletDetails'
import { IPalletTypeItem } from '../../../../../modules/API/Defaults/models/pallets'
import styled from 'styled-components'
import SelectFromTheList from '../../../../../generic/customSearchComponents/SelectFromTheList'
import ControlButton from '../../../../../generic/buttons/ControlButton'

type Props = {
  palletTypeList: IPalletTypeItem[]
  exitsData: IPalletDetails[]
  handleChange: (e: number, RecID: number) => void,
  handleClose: () => void
}

const ChangePalletModal = ({
  palletTypeList, exitsData, handleChange, handleClose
}: Props) => {

  const handleSelect = (e: string, RecID: number) => {
    handleChange(Number(e), RecID)
  }

  return (
    <MainWrapper>
      <BodyContainer>
        {
          exitsData.map((item, index) => {
            return <ItemWrapper>

              <SelectFromTheList
                size={'full'}
                lable={`משטח ${index + 1}`}
                key={item.RecID} 
                list={palletTypeList}
                selectedValue={item.Pallet_Type.toString()}
                dispalyFieldName='Pallete_Desc'
                itemKey={'Pallete_Type'}
                fieldValue='Pallete_Type'
                selectFunction={(e: string) => handleSelect(e, item.RecID)}
              />
            </ItemWrapper>
          })
        }
      </BodyContainer>
      <ControlButton handleClick={handleClose} lable={'שמור'} />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`

const BodyContainer = styled.div`
  width: 80%;
  max-height: 500px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
  margin: 0 auto;
  overflow-y: auto;
  padding: 0.5em;
  ::-webkit-scrollbar {
    width: 0
  }
`

const ItemWrapper = styled.div`
  height: 3.5em;
  width: 100%;
`

export default ChangePalletModal