import React from 'react'
import { IRecommendationItem } from '../../../../../../modules/API/Recommendation/models/recomendationItem'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import styled from 'styled-components'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../../../knisot/Components/SwipeContent'
import ConfirmDelate from '../../../controlPallets/Components/ConfirmDelate'
import { IRecommendationModifiedItem } from '../types/recommendationModified'
import RowInput from '../../../../../../generic/inputs/RowInput'

interface Props extends IRecommendationModifiedItem {
  index: number,
  item: IRecommendationItem,
  calculationMethod: number,
  // createForecastFromRec?: (recItem: IRecommendationItem) => void
  deleteFunc?: (recID: number) => void,
  handleChangeRecItem?: (index: number, amount: number, pallets: number, weight: number) => void
}

const RecommendationItem = ({
  GeneralProductID, VarietyDesc, item, deleteFunc,
  ProductDesc, index, GrowerName, handleChangeRecItem, RecID,
  calculationMethod
}: Props) => {
  const [isOpenDelete, setIsOpenDelete] = React.useState<boolean>(false)
  const [pallets, setPallets] = React.useState<number>(0)
  const [weight, setWeight] = React.useState<number>(0)
  const [amount, setAmount] = React.useState<number>(0)

  // const handleForecastReq = () => {
  //   if (!createForecastFromRec) return
  //   createForecastFromRec(item)
  // }
  const handleConfirmShow = () => {
    setIsOpenDelete(!isOpenDelete)
  }
  const handleDelete = () => {
    if (!deleteFunc) return
    deleteFunc(RecID)
  }
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(+e.target.value)
    if (handleChangeRecItem) {
      handleChangeRecItem(index, +e.target.value, pallets, weight)
    }
  }
  const handlePalletsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPallets(+e.target.value)
    if (handleChangeRecItem) {
      handleChangeRecItem(index, amount, +e.target.value, weight)
    }
  }
  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(+e.target.value)
    if (handleChangeRecItem) {
      handleChangeRecItem(index, amount, pallets, +e.target.value)
  }
}
  return (
    <TitleLayout
      // onClick={handleForecastReq}
      isDraggable={!!deleteFunc}

    >
      <SwipeableListItem
        swipeLeft={{
          content: <SwipeContent position='right' isPrimal text={'מחק'} />,
          action: handleConfirmShow
        }}
        blockSwipe={!deleteFunc}
      >
        <MainWrapper>
          <BodyTitle>
            <SideImage>
              <ProductImage
                id={GeneralProductID}
              />
            </SideImage>
            <TitleRow>
              <TitleRowItem
                name={`${ProductDesc.trim()} ${VarietyDesc.trim()}`}
                icon={<AppleIcon />}
              />
            </TitleRow>
            {
              !handleChangeRecItem &&
              <TitleRow>
                <TitleRowItem
                  name={`${GrowerName.trim()}`}
                  icon={<UserIcon />}
                />
              </TitleRow>
            }
            {
              !!handleChangeRecItem &&
                <TitleRow>
                  {
                    calculationMethod === 2 ?
                      <RowInput 
                        icon={<PalletIcon />}
                        value={pallets}
                        isDisabled={false}
                        handleInput={handlePalletsChange} 
                      /> :
                      calculationMethod === 3 ?
                      <RowInput 
                        icon={<ScalesIcon />}
                        value={weight}
                        isDisabled={false}
                        handleInput={handleWeightChange}
                      /> :
                      <RowInput 
                        icon={<PackageIcon />}
                        value={amount}
                        isDisabled={false}
                        handleInput={handleAmountChange}
                      />

                  }
                  
                 
                 
                </TitleRow>
            }
          </BodyTitle>
        </MainWrapper>
        {
          isOpenDelete &&
          <ConfirmDelate
            handleClick={handleDelete}
          />
        }
      </SwipeableListItem>

    </TitleLayout>
  )
}
const SideImage = styled.div`
  position: absolute;
  left: 0;
  top: calc(50% - 1.5em);
  width: 3em;
  height: 3em;
`

const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    /* background: red; */
`

const BodyTitle = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
export default RecommendationItem