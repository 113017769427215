import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

const TableBodyCell = ({
    children
}: Props) => {
  return (
    <TableBodyCellStyle>
        {children}
    </TableBodyCellStyle>
  )
}
const TableBodyCellStyle = styled.td`
    max-width: max-content;
    direction: ltr;
    text-align: right;
    overflow: hidden;
    padding: 0.2em ;
    background: #fff;
    color: rgba(0,0,0,0.7);
    i {
        margin-left: 0.5em;
        color: #81B4AF;
    }
`
export default TableBodyCell