import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[];
}

const NavLayout = ({children}: Props) => {
  return (
    <MainWrapper>
      {children}
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5em;
  background: #fff;
  border-radius: 20px;
  -webkit-filter: drop-shadow(4px 10px 0px #E9F3F2);
  filter: drop-shadow(4px 10px 0px #E9F3F2);
`;
export default NavLayout