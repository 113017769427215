import {useState} from 'react'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../Routs/config'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../knisot/Components/SwipeContent'
import TitleLayout from '../kosherTeudot/generic/TitleLayout'
import TitleRow from '../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../kosherTeudot/generic/TitleRowItem'
import UserIcon from '../../../../generic/icons/IconLib/UserIcon'
import PalletIcon from '../../../../generic/icons/IconLib/PalletIcon'
import { CalendatDay, LocationIcon, PrintIcon } from '../../../../generic/icons/Icons'
import { convertToDate } from '../../../../modules/convertDateString'
import styled from 'styled-components'
import ConfirmDelate from './Components/ConfirmDelate'

export default function ControlPalletsTitle({
  selectClientFunc, Pallet_Num, CustomerDesc, 
  Pallet_Date, TranzitDesc, onClick, handleDelete,
  printPallet
}) {
  const [isOpenDelate, setIsOpenDelate] = useState(false)
  const [isDelated, setIsDelated] = useState(false)
  const history = useHistory()
  const date = convertToDate(Pallet_Date)
  const handleAction = () => {
    selectClientFunc(CustomerDesc)
    history.push(`${LINKS.mishtahim.control.create}${Pallet_Num}`)
  }
  const handleOpenConfirm = () => {
    setIsOpenDelate(!isOpenDelate)
  }
  const handleDeleteItem = () => {
    setIsDelated(true)
    return new Promise((resolve, reject) => {
      resolve(
        setTimeout(() => {
          handleDelete(Pallet_Num)
          
        }, 500)
      )
      reject(
        setTimeout(() => {
          setIsDelated(false)
          setIsOpenDelate(false)
        }, 500)
      )
    })
  }
  const handlePrint = () => {
    printPallet(Pallet_Num)
  }
  return (
      <TitleLayout isDraggable isDelete={isDelated}> 
        <SwipeableListItem
            swipeRight={{
              content: <SwipeContent text='עדכון' />,
              action: handleAction,
            }}
            swipeLeft={{
              content: <SwipeContent text='מחק' isPrimal position='right' />,
              action: handleOpenConfirm
            }}
            threshold={0.4}
        >
          <TitleLayout disableShadow>
            <SideIconWrapper onClick={handlePrint}>
              <PrintIcon />
            </SideIconWrapper>
              <TitleRow>
                <TitleRowItem icon={<UserIcon fill />} name={CustomerDesc.trim()} />
              </TitleRow>
              <TitleRow>
                <TitleRowItem icon={<CalendatDay fill />} name={date} />
                <TitleRowItem icon={<PalletIcon fill />} name={Pallet_Num} />
              </TitleRow>
              <TitleRow>
                <TitleRowItem icon={<LocationIcon />} name={TranzitDesc} />
              </TitleRow>
          </TitleLayout> 
          {
            isOpenDelate &&
            <ConfirmDelate
              handleClick={handleDeleteItem}
            />
          }
        </SwipeableListItem>
      </TitleLayout>
  )
}

const SideIconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 1.5em);
  width: 3em;
  /* background-color: red; */
  color: #297F76;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3em;
  cursor: pointer;
  :hover {
    i {
      color: tomato;
    }
  }
  @media screen and (max-width: 768px) {
    width: 1em;
  }
`
