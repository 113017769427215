import React from 'react'
import styled from 'styled-components'

interface IRowItem  {
    name: string | React.ReactNode
    icon: JSX.Element;
    iconColor?: string;
}

const TitleRowItem = ({name, icon, iconColor}: IRowItem) => {
  return (
    <MainWrapper>
        <InnerRowItem>
            {name}
        </InnerRowItem>
        <InnerRowItemIcon iconColor={iconColor}>
            {icon}
        </InnerRowItemIcon>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    display: flex;
    gap: 0.5em;
    color: #75759e;
    font-size: 1.1em;
    max-width: 100%;
`
const InnerRowItem = styled.div`
    text-overflow: ellipsis;
    overflow: hidden; 
    /* min-width: 0;  */
    flex: 1;
    i {
        color: #297f76;
    }
    white-space: nowrap;
`
const InnerRowItemIcon = styled.div<{iconColor?: string}>`
    color: ${props => props.iconColor ? props.iconColor : '#297f76'};
    /* flex-shrink: 0; */
`

export default TitleRowItem