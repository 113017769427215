import { useContext } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ContextModal } from '../modules/modal/modalContext'
import { LOGIN_SUCCESS, LOGIN_FAIL, LOAD_DEFAULTS, LOAD_USER, TABLE_OF_TABLES, LOAD_MODULES } from '../../store/actions/actionsTypes';
import { CURRENT_PATH } from '../modules/API/settings'
import history from '../../history'
import { loadDefaults } from '../modules/requests/loadDefaults'
import { vibrationFunc } from "../modules/vibrationModule";
import Logo from "../generic/imageComponents/Logo";
import { Auth } from "../modules/API/Auth/auth";
import { Defaults } from "../modules/API/Defaults/defaults";


const Section = styled.section`
    width: 100%;
    height: 100%;
    background: #e9f0f0;
    position: fixed;

`
const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
    
    
`
const Wrapper = styled.div`
    color: #1F1F1F;
    width: 100%;
    height: 550px;
    background: #fdfdfd;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    z-index: 2;
    @media screen and (min-width: 400px) {
        width: 400px;
    }
    /* ::before {
        content: "";
        position: absolute;
        width: 5%;
        height: 85%;
        background: #e9f3f2;
        left: -5%;
        top: 2.2em;
        border-radius: 30px 0 0 30px;
        z-index: -1;


    } */
`
const MainBlock = styled.div`
    width: 100%;
    height: 80%;
    /* background: blue; */
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    
`
export const ContentWrapper = styled.div`
    width: 80%;
    height: ${props => props.logo ? '15%' : props.button ? '12%' : '20%'};
    display:flex;
    flex-flow: ${props => props.row ? 'row' : 'column'};
    align-items: ${props => props.row ? 'center' : 'flex-end'} ;
    justify-content: ${props => props.row ? 'flex-end' : 'center'};
    
`
const ContentWrapperP = styled(ContentWrapper)`
    position: absolute;
    bottom: -2.5em;
    right: calc(50% - 160px);

    height: 3em;

`
const Text = styled.div`
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 3em;
    color: #1f1f1f;
    font-weight: 600;
    
`
export const InputContainer = styled.div`
    position: relative;
    width: 100%;
    height: 60%;
    /* background: blueviolet; */
    margin-top: 0.3em;
`
export const Input = styled.input`
    height: 100%;
    width: 100%;
    border-radius: 30px;
    padding: 0.2em 0.5em;
    outline: none;
    text-align: right;
    border: 2px solid #7ab0ab;
    font-size: 1.2em;
    -webkit-appearance: none;
    background: #f2f2f2;
    @keyframes border-pulsate {
        
        50%  { border-color: rgba(128,224,129,1); }
        
    }
    &:focus{
        animation: border-pulsate 2s infinite;
    } 
`
export const IconInput = styled.div`
    position: absolute;
    height: 100%;
    width: 2.5em;
    /* background: red; */
    i {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.5s ease;
        color: #1F1F1F;
    }
`
const CheckBox = styled.div`
    height: 1.5em;
    width: 1.5em;
    border: 2px solid #7ab0ab;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 0.5em;
    i {
        color: #fff;
        font-size: 1.2em;
    }
`
const Button = styled.button`
    width: 100%;
    height: 100%;
    background: #7ab0ab;
    border: none;
    border-radius: 30px;
    font-size: 1.2em;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease;

    :active{
        background: #297f76;
    }

    :disabled{
        p {
            display: none;
        }
        i {
            display: block;
            // padding: 0.2em 1em;
        }
    }
    i {
        display: none;
        animation: spin 1s infinite ;
        @keyframes spin {
            0% {transform: rotate(0);}
            100% {transform: rotate(360deg);}
        }
    }
`
export const Tag = styled.label`
    font-size: ${props => props.logo ? '2em' : '1em'};
    font-family: sans-serif;
    position: relative;
    z-index: 2;
`
export const BackBox = styled.div`
    position: absolute;
    width: 2.5em;
    left: -0.4em;
    top: 0.5em;
    height: 50%;
    background: rgba(122,176,171, 0.7);
    z-index: -1;
`
const IconWrapper = styled.div`
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* background: blue; */
    left: calc(50% - 40px);
    top: -5.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    /* filter: hue-rotate(-990deg);
    filter: grayscale(0.4); */
    img {
        width: 151px;

    }
`



export default function LoginPage() {
    const [modal, setModal] = useContext(ContextModal);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [border, setBorder] = useState(false);
    const dispatch = useDispatch();
    const [uName, setUname] = useState('');
    const [uPass, setUPass] = useState('');
    const [uType, setUType] = useState(false);
    const [iconPass, setIconPass] = useState(false);
    const [iconCheckBox, setIconCheckbox] = useState(false);

    const funcToggle = () => {
        setIconPass(!iconPass)
    }
    const logIn = async () => {
        setLoadSpinner(true);
        try {
            let res = await Auth.login({
                "Login": uName,
                "Password": uPass,
                "Internal": uType
            })
            setLoadSpinner(false);
            setModal({
                open: true,
                value: ` ברוכים הבאים ${uName}`,
                mood: true
            })
            setTimeout( () =>{ 
                setModal({
                open: false,
                value: '2'

            })}
            , 3000);
            dispatch({ type: LOGIN_SUCCESS, payload: res });

            let modules = await Defaults.getAvailableModules()
            dispatch({type: LOAD_MODULES, payload: modules})
            await loadDefaults(res.token, LOAD_DEFAULTS, TABLE_OF_TABLES, dispatch)

        } catch (err) {
            // console.log(err.response.data)
            vibrationFunc('warning')
            dispatch({ type: LOGIN_FAIL });
            setLoadSpinner(false);
            if(err.response.status === 404){
                setModal({
                    open: true,
                    value: 'שם משתמש או סיסמא שגויים',
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        open: false,
                        value: '2'
    
                    })
                }
                , 3000)
            } else {
                setModal({
                    open: true,
                    value: 'משהו השתבש, נסה שוב',
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        open: false,
                        value: '2'
    
                    })
                }
                , 3000)
            }
            setTimeout(() => {
                setBorder(false);
            }, 3000)
        }
    }

    let errorBorders = border ? '2px solid tomato' : '2px solid rgba(122,176,171, 0.7)';
    let passIconToggle = iconPass ? <i onClick={() => { funcToggle() }} className="fas fa-eye"></i> : <i onClick={() => { funcToggle() }} className="fas fa-eye-slash"></i>;
    let passInputVisible = iconPass ? 'text' : 'password';
    let checkBoxToggle = iconCheckBox ? <i className="fas fa-check"></i> : '';
    let checkBoxToggleBg = iconCheckBox ? '#80e801' : 'none';
    return (
        <Section>
            <Container>
                <Wrapper onKeyPress={e => {
                    if (e.key === 'Enter') {
                        logIn() 
                    }
                }}>
                    <IconWrapper><img width="151" height="85" src="/logo.png" alt={'logo'} /></IconWrapper>
                    <MainBlock>
                        <ContentWrapper logo>
                            <Tag htmlFor="login" logo>
                                כניסה
                                <BackBox />
                            </Tag>
                        </ContentWrapper>
                        <ContentWrapper>
                            <Tag htmlFor="login">שם משתמש</Tag>
                            <InputContainer>
                                <IconInput><i className="fas fa-user"></i></IconInput>
                                <Input id="login" style={{border: errorBorders}} onChange={(e) => { setUname(e.target.value) }} type="text" />
                            </InputContainer>
                        </ContentWrapper>
                        <ContentWrapper>
                            <Tag htmlFor="pass">סיסמה</Tag>
                            <InputContainer>
                                <IconInput>{passIconToggle}</IconInput>
                                <Input id="pass" style={{border: errorBorders}} onChange={(e) => { setUPass(e.target.value) }} type={passInputVisible} />
                            </InputContainer>
                        </ContentWrapper>
                        <ContentWrapper row button>
                            <Tag>? פנימי</Tag>
                            <CheckBox style={{ background: checkBoxToggleBg }} onClick={() => { setUType(!uType); setIconCheckbox(!iconCheckBox) }}>{checkBoxToggle}</CheckBox>
                        </ContentWrapper>
                        <ContentWrapper button>
                            <Button disabled={loadSpinner} onClick={() => { logIn() }}>
                                <p>כניסה</p>
                                <i className="fas fa-spinner"></i>
                            </Button>
                        </ContentWrapper>
                        <ContentWrapperP row>
                                <Logo />
                        </ContentWrapperP>
                    </MainBlock>
                </Wrapper>
            </Container>
        </Section>
    );
}