
import styled from "styled-components";
import TitlesList from "../../../../../generic/titlesRowItem/TitlesList";
import { AzmanotListWrapper, ContentWrapper } from "../../secondbar/mainbar2";
import ArizotBodyItem from "./arizotBodyItem";


const ContainerImg = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {

}
`

export default function ArizotBody({
    arizotList, deleteArizot, printFunc, 
    handleOpenModalFunc, isAllowTeuda
}){
    let showPic = arizotList.length < 1 ? 'flex' : 'none'
    return(
        <ContentWrapper>
            <AzmanotListWrapper >
                {arizotList.length < 1 ? 
                    <ContainerImg style={{display: showPic}}>
                        <img src="/images/no-data.png" alt="empty data"/>
                    </ContainerImg>
                    :
                    <TitlesList
                        list={arizotList} 
                        uniqueKey={'PO_Num'}
                        Element={ArizotBodyItem}
                        additionalData={{
                            deleteArizot: deleteArizot,
                            printFunc: printFunc,
                            handleOpenModalFunc: handleOpenModalFunc,
                            isAllowTeuda: isAllowTeuda
                        }}

                    />
                }
            </AzmanotListWrapper>
        </ContentWrapper>
    );
}