import React, {useState} from 'react'
import styled from 'styled-components'
import Image from '../../../../../../../generic/assets/cogsPNG.png'
import ImageComponentHead from '../../../../../../../generic/imageComponents/ImageComponentHead'
import SearchBlockWithTitle from '../../../Q8/Layouts/SearchBlockWithTitle'
import DatePickerModule from '../../../../../../../generic/datePicker/datePicker'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import { IReportListItem } from '../../../../../../../modules/API/Reports/models/reportList'
import SearchReport from '../SearchReport'
import SearchOrderType from '../SearchOrderType'
import SearchLineType from '../SearchLineType'

type Props = {
  saveFunc: (date1: string, date2: string, repID: number, orderType: number, lineType: number, status: number) => void
  reportList: IReportListItem[]
}

const ModalQ10 = ({
  saveFunc,
  reportList
}: Props) => {
  const [t] = useTranslation('buttons')
  const [dateStart, setDateStart] = useState<string>('')
  const [dateEnd, setDateEnd] = useState<string>('')
  const [selectedReportID, setSelectedReportID] = useState<number>(0)
  const [selectedOrderType, setSelectedOrderType] = useState<number>(1)
  const [selectedLineType, setSelectedLineType] = useState<number>(1)
  
  const handleSave = () => {
    saveFunc(
      dateStart, 
      dateEnd, 
      selectedReportID, 
      selectedOrderType, 
      selectedLineType, 
      99
    )
  }

  return (
    <MainWrapper>
      <ImageWrapper>
        <ImageComponentHead 
          img={Image}
        />
      </ImageWrapper>
      <ContentWrapper>
        <SearchBlockWithTitle
          title={'בחר תאריכים'}
        >
          <DatePickerModule 
            selected={false}
            defEndDate={false}
            multyDate
            setSelectedDateStart={setDateStart}
            setSelectedDateEnd={setDateEnd}
            ignorBrod={false}
            saveWithoutFormat={false}
            large
            disabled={false}          
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={'בחר דוח'}
        >
          <SearchReport 
            list={reportList}
            defaultID={selectedReportID}
            setReportID={setSelectedReportID}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={['סוג הזמנה', 'ד']}
        >
          <SearchLineType 
            defaultID={selectedLineType}
            setLineType={setSelectedLineType}
          />
          <SearchOrderType 
            defaultID={selectedOrderType}
            setOrderType={setSelectedOrderType}
          />
        </SearchBlockWithTitle>
      </ContentWrapper>
      <ControlButton 
        lable={t('save')}
        handleClick={handleSave}
      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
export default ModalQ10