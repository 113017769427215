import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TSalesRecordList } from "../../../../../models/RequestsModel/salesRecord";
import { LOAD, LOAD_END } from "../../../../../store/actions/actionsTypes";
import { RootState } from "../../../../../store/reducers/rootReducer";
import DatePickerModule from "../../../../generic/datePicker/datePicker";
import { CalendatDay } from "../../../../generic/icons/Icons";
import SearchInput from "../../../../generic/inputs/inputSearch";
import ColumModal from "../../../../generic/Layouts/ColumModal";
import Title from "../../../../generic/Title/Title";
import TitlesList from "../../../../generic/titlesRowItem/TitlesList";
import { TDateRangerList } from "../../../../generic/utils/forDate/stateModel";
import { GET_PRICE_DIAPASON } from "../../../../modules/API/settings";
import { MainWrapper } from "../secondbar/mainbar2";
import { MainSection } from "../secondbar/modalAzmanot/modalAzmanot";
import {
  ButtonWrapper,
  FilterIndicator,
  HeadWrapper,
} from "../trucks/TrucksMain";
import RecordItem, { TitleContainer } from "./RecordItem";
import SelectDateModal from "./SelectDateModal";
import { ICustomerGroup } from "../../../../modules/API/Defaults/models/customersGroup";
import { Defaults } from "../../../../modules/API/Defaults/defaults";
import { IMultySelectState } from "./MultySelect";
import { TableSelect } from "../../../../modules/API/TableSelect/tableSelect";
import { IPostTableSelect } from "../../../../modules/API/TableSelect/models/postTableSelect";

interface ISalesDateState {
  current: TSalesRecordList | null;
  default: TSalesRecordList | null;
}

interface IDateRange {
  START: string;
  END: string;
}
export interface IDate {
  FIRST: IDateRange;
  SECOND: IDateRange;
  THIRD: IDateRange;
}

const MainWrapperCurrent = styled(MainWrapper)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const TitleFOrScroll = styled.div`
  position: relative;
  width: 100%;
  padding: 0 2em;
  border-radius: 30px 30px 0 0;
  display: flex;
  background: #fff;
  color: #297f76;
  ::after {
    bottom: 0;
    left: 0;
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 3%,
      rgba(41, 127, 118, 1) 49%,
      rgba(255, 255, 255, 1) 97%
    );
  }
`;
const BodyContainer = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 0 0 30px 30px;
  overflow: hidden;
`;

const formated = (date: TDateRangerList) => {
  if (!date) return null
  return {
    first: {
      startDate: format(date?.selection1.startDate, 'yyyyMMdd'),
      endDate: format(date?.selection1.endDate, 'yyyyMMdd'),
    },
    second: {
      startDate: format(date?.selection2.startDate, 'yyyyMMdd'),
      endDate: format(date?.selection2.endDate, 'yyyyMMdd'),
    },
    third: {
      startDate: format(date?.selection3.startDate, 'yyyyMMdd'),
      endDate: format(date?.selection3.endDate, 'yyyyMMdd'),
    },
  }
}

function ReportPriceMain() {
  const getToken = useSelector((state: RootState) => state.mainReducer.token);
  const dispatch = useDispatch();
  const [isOpenDateSelector, setIsOpenDateSelector] = useState<boolean>(true);
  const [isOpenDetailes, setIsOpenDetailes] = useState<boolean>(false);

 
  const [date, setDate] = useState<TDateRangerList>();
  const [salesData, setSalesData] = useState<ISalesDateState>({
    current: null,
    default: null,
  });
  const [salesDetales, setSalesDetales] = useState<ISalesDateState>({
    current: null,
    default: null,
  });
  const [detailesTitle, setDetailesTitle] = useState('');
  const [customersGroup, setCustomersGroup] = useState<IMultySelectState[]>([]);
  const [multySelection, setMultySelection] = useState<IMultySelectState[]>([]);
  console.log(customersGroup)
  const getCustomerGroup = async () => {
    try {
        let res: {groups: ICustomerGroup[]} = await Defaults.getCustomersGroup()
          
        setCustomersGroup(res.groups.map(item => {
          return {
            id: item.GroupID,
            desc: item.GroupDesc?.trim()
          }
        }))
        // console.log(res.data)
    } catch (err) {
        console.log()
    }
  }

  const handleSelectCustomers = (item: IMultySelectState) => {
    const isExist = multySelection.some(group => group.id === item.id)
    if(isExist) return
    setMultySelection(prev => [...prev, item])
  }
  const handleDeleteFunction = (item: IMultySelectState) => {
    setMultySelection(multySelection.filter(e => e.id !== item.id))
  }
  const getSelectedGroupsCustomers = async () => {
    try {
      let res: IPostTableSelect[] = await TableSelect.getExistingRow(147);
      setMultySelection(res.map(item => {
        return {
          id: item.RowID,
          desc: item.Description
        }
      }))
      // console.log(res)
    } catch (err){

    }
  }
  const postSelectedRowsCustomers = async () => {
    const formData = multySelection.map(e => 
       {
        return {
          RowID: e.id,
          Description: e.desc,
          TableID: 147
        }
       
      }  
    )
    try {
      let res = await TableSelect.postRows(147, formData)
    } catch (err) {

    }
  }
  const handleTitle = (desk: string) => {
    setDetailesTitle(desk.trim())
  }
  
  // console.log(formatedDate)
  const getPriceSummary = async () => {
    console.log(date)
    if(!date) return
    const formatedDate = formated(date)
    dispatch({type: LOAD})
    try {
      await postSelectedRowsCustomers();
      let res = await axios({
        url: `${GET_PRICE_DIAPASON}fromDate1=${formatedDate?.first.startDate}&toDate1=${formatedDate?.first.endDate}&fromDate2=${formatedDate?.second.startDate}&toDate2=${formatedDate?.second.endDate}&fromDate3=${formatedDate?.third.startDate}&toDate3=${formatedDate?.third.endDate}`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + getToken,
        },
      });
      setDate(date);
      sessionStorage.setItem('DateRange', JSON.stringify(date))
      const cleanArr = res.data.sales.ROOT.Temp4.reduce(
        (acc: any, cur: { $: object }): TSalesRecordList => {
          acc.push(cur["$"]);
          return acc;
        },
        []
      );
      setSalesData({
        current: cleanArr,
        default: cleanArr,
      });
      console.log('1')
      setIsOpenDateSelector(false);
    } catch (err) {
      console.log(err);
      
    } finally {
      dispatch({type: LOAD_END})
    }
  };

  const getPriceDetaules = async (id: number) => {
    dispatch({type: LOAD})
    const formatedDate = date ? formated(date) : null
    try {
      let res = await axios({
        url: `${GET_PRICE_DIAPASON}fromDate1=${formatedDate?.first.startDate}&toDate1=${formatedDate?.first.endDate}&fromDate2=${formatedDate?.second.startDate}&toDate2=${formatedDate?.second.endDate}&fromDate3=${formatedDate?.third.startDate}&toDate3=${formatedDate?.third.endDate}&customerId=${id}`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + getToken,
        },
      });
      const cleanArr = res.data.sales.ROOT.Temp4.reduce(
        (acc: any, cur: { $: object }): TSalesRecordList => {
          acc.push(cur["$"]);
          return acc;
        },
        []
      );
      setIsOpenDetailes(true)
      console.log(res);
      console.log(cleanArr);
      setSalesDetales({
        current: cleanArr,
        default: cleanArr,
      });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({type: LOAD_END})
    }
  };
  const [searchInput, setSearchInput] = useState<string>("");
  const searchFunc = (input: string) => {
    if (salesData.default) {
      const filtered = salesData.default.filter((item) =>
        item.Desc1.trim()
          .toLocaleLowerCase()
          .includes(input.toLocaleLowerCase())
      );
      setSalesData({
        ...salesData,
        current: filtered,
      });
    }
    setSearchInput(input);
  };

  const selectDateProps = {
    date: date,
    setDate: setDate,
    getPriceSummary: getPriceSummary,
    customersGroup: customersGroup,
    multySelection: multySelection,
    handleSelectCustomers: handleSelectCustomers,
    handleDeleteFunction: handleDeleteFunction

  };
  const detailesProps = {
    list: salesDetales.current,
    Element: RecordItem,
    uniqueKey: "RecID",
    additionalData: {
      date: date,
      detailes: true
    },
    noPadding: false

  }
  useEffect(() => {
    getCustomerGroup();
    getSelectedGroupsCustomers()
  }, [])
  return (
    <MainSection>
      {isOpenDateSelector && (
        <ColumModal
          controlFunc={() => {
            setIsOpenDateSelector(false);
          }}
          Children={SelectDateModal}
          childrenProps={selectDateProps}
          title={"תאריכי מכר"}
          acceptFunction={getPriceSummary}

        />
      )}
      {
        isOpenDetailes && 
          <ColumModal
            controlFunc={() => {
              setIsOpenDetailes(false);
            }}
            Children={TitlesList}
            childrenProps={detailesProps}
            title={detailesTitle}
          />
      }
      <MainWrapperCurrent style={{ gap: "0" }}>
        <Title label={"נתוני מכר"} color={"#297f76"} />
        <HeadWrapper style={{ marginBottom: "0.5em" }}>
          <FilterIndicator>
            <ButtonWrapper
              onClick={() => {
                setIsOpenDateSelector(true);
              }}
            >
              <CalendatDay />
            </ButtonWrapper>
          </FilterIndicator>
          <SearchInput
            input={searchInput}
            setInput={searchFunc}
            // value={'search'}
          />
        </HeadWrapper>
        <TitleFOrScroll>
          <TitleContainer
            style={{ width: "40%", justifyContent: "flex-start", gap: "3em" }}
          >
            <div>פחת</div>
            <div>מכר</div>
          </TitleContainer>
          <TitleContainer style={{ justifyContent: "flex-end", width: "60%" }}>
            יעד
          </TitleContainer>
        </TitleFOrScroll>
        <BodyContainer>
          <TitlesList
            list={salesData.current}
            Element={RecordItem}
            uniqueKey={"RecID"}
            additionalData={{
              date: date,
              getPriceDetaules: getPriceDetaules,
              handleTitle: handleTitle
              
            }}
            noPadding={false}
          />
        </BodyContainer>

      </MainWrapperCurrent>
    </MainSection>
  );
}

export default ReportPriceMain;
