import React, {useState, useEffect} from 'react'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList'
import SelectFromTheList from '../../../../../generic/customSearchComponents/SelectFromTheList'

type Props = {
  defaultID: number
  setSelectID: React.Dispatch<React.SetStateAction<number>>
}
export const filterVar = [
  
  {
    label: 'נקלט',
    value: 1
  },
  {
    label: 'לא נקלט',
    value: 2
  }
]

const Filter = ({
  defaultID, setSelectID
}: Props) => {
  const selectFunctiom = (id: string) => {
    console.log('id', id)
    // if(!+id) return setSelectID(0)
    setSelectID(+id)
  }
  return (
    <SelectFromTheList 
      lable={'הכול'}
      selectFunction={selectFunctiom}
      itemKey='value'
      dispalyFieldName='label'
      selectedValue={defaultID}
      list={filterVar}
      fieldValue='value'
    />
    
  )
}

export default Filter