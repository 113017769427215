import React, { useState, useEffect, useMemo, useContext } from 'react'
import FullSizeLayout from '../../../../../generic/Layouts/FullSizeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import BodyScrollContainer from '../Distribution/Layouts/BodyScrollContainer'
import DatePickerISO from '../Distribution/Components/DatePickerISO'
import styled from 'styled-components'
import ButtonDateControl from '../Distribution/Components/ButtonDateControl'
import { getWeekDetailsSupply } from './hooks/getWeekDetailsSupply'
import { dateChangeWeek } from '../Distribution/utils/dateChangeWeek'
import SupplyDayColumnItem from './Components/SupplyDayColumnItem'
import { ISupplyMainList } from './types/supplyMainList'
import { Orders } from '../../../../../modules/API/Orders/orders'
import { Rehesh } from '../../../../../modules/API/AzmanotRehesh/azmanotRehesh'
import { useDispatch, useSelector } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { Product } from '../../../../../modules/API/Products/products'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IProductsWithVarietyItem } from '../../../../../modules/API/Defaults/models/compliteProducts'
import { IOrderRehesh } from '../../../../../modules/API/AzmanotRehesh/models/order'
import { IForecastsItem } from '../../../../../modules/API/Drivers/models/kavData'
import { Forecasts } from '../../../../../modules/API/Forcasts/forcasts'
import { IAddProductItem } from './types/addProductItem'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import ModalNewProducts from './Components/Modals/ModalNewProducts'
import SearchWarehouse from '../../knisot/Create/Components/SearchWarehouse'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { convertProductListToOrderRehesh } from './utils/convertProductListToOrderRehesh'
import { useKnisaConfig } from '../../knisot/Create/config'
import ModalEditProduct from './Components/Modals/ModalEditProduct'
import { convertFromForecastToOrderRehesh } from './utils/convertFromForecastToOrderRehesh'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { SettingsIcon, UserIcon, WarehouseIcon } from '../../../../../generic/icons/Icons'
import FilterModal from './Components/Modals/FilterModal'
import { IGrowerItem, IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import FilterCustomerModal from './Components/Modals/FilterCustomerModal'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import { useParams } from 'react-router-dom'
import { IProductRecWithCount } from '../../../../../modules/API/Forcasts/models/productRecWithCount'
import { CountsAddData } from './types/countsAddData'




const SupplyMain = () => {
  const dispatch = useDispatch()
  const [modal, setModal] = useContext(ContextModal)
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const defaults = useSelector((state: RootState) => state.defaults.defaults)
  const defaultCustomerID = selectBrodMigdal(defaults, 314, "Num_Value")
  const calculationMethod = selectBrodMigdal(defaults, 344, "Num_Value")
  const defaultTranzit: number = useKnisaConfig().create.title.tranzit[1].value

  const [isOpenNewProductModal, setIsOpenNewProductModal] = useState<boolean>(false)
  const [isOpenEditProductModal, setIsOpenEditProductModal] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedOrder, setSelectedOrder] = useState<CountsAddData | null>(null)
  const [weekSupplyList, setWeekSupplyList] = useState<ISupplyMainList[]>([])
  const [selectedDay, setSelectedDay] = useState<ISupplyMainList | null>(null)
  const [warehouseID, setWarehouseID] = useState<number>(defaultTranzit || 0)
  // TO DO: add customerID from brod migdal
  // const [forecastsForSelectedDay, setForecastsForSelectedDay] = useState<IForecastsItem[]>([])
  const [addProductList, setAddProductList] = useState<IAddProductItem[]>([])
  const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false)
  const [isOpenFilterCustomerModal, setIsOpenFilterCustomerModal] = useState<boolean>(false)
  const [productRecDict, setProductRecDict] = useState<Record<string, IProductRecWithCount> | null>(null)
  const [weekRangeHebrew, formattedRange] = useMemo(() => {
    const [weekRangeHebrew, formattedRange] = getWeekDetailsSupply(selectedDate)
    setWeekSupplyList(weekRangeHebrew)
    return [weekRangeHebrew, formattedRange]
  }, [selectedDate])

  const {
    data: warehouseList,
  } = useDataFetcher<IWarhouseListItem[]>(
    Defaults.getWarhouseList, 'data'
  )
  const {
    data: productsWithVariety,
  } = useDataFetcher<IProductsWithVarietyItem[]>(
    Defaults.getProductsWithVariety, 'data'
  )

  const {
    data: growerList
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, 'data'
  )
  const productFinalListForSelect = (selectedDayData: ISupplyMainList, list: IProductRecWithCount[]) => {
    // if (!productsWithVariety) return []

    // create hash for handle already exist products in selected day
    const dayProdHash = selectedDayData.list.reduce((acc: Record<string, IOrderRehesh>, item) => {
      acc[`${item.ProductID} ${item.VarietyID}`] = item
      return acc
    }, {})
    
    // return available products for adding to selected day
    return list.filter((item) => {
      return (
        !dayProdHash[`${item.ProductID} ${item.VarietyID}`] &&
        (warehouseID !== 0 ? dayProdHash[`${item.ProductID} ${item.VarietyID}`]?.WarehouseID !== warehouseID : true)
      )
    })

  }
  const getProductsRecWithCount = async (date: string) => {
    try {
      const res = await Forecasts.getProductWithCounts(date)
      return res
    } catch(err) {
      return []
    }
  }

  const getProductsRecWithCountByID = async (date: string, prodID: number, varID: number) => {
    try {
      const res = await Forecasts.getProductWithCountsByProdVarID(date, prodID, varID)
      return res
    } catch(err) {
      return []
    }
  }
  const sendSms = async (day: ISupplyMainList) => {
    if(!warehouseID) return
    dispatch({ type: LOAD })
    try {
      const newArr = [...weekSupplyList]
      const index = newArr.findIndex((item) => item.dateRequestFormat === day.dateRequestFormat)
      
      await Forecasts.sendSms(day.dateRequestFormat, defaultCustomerID, warehouseID)

      newArr[index].list = [
        ...newArr[index].list.map((listItem) => {
          return {
            ...listItem,
            Sent_SMS: true
          }
        }),
      ]
      setWeekSupplyList(newArr)
      setModal({
        value: "הודעת SMS נשלחה בהצלחה",
        open: true,
        mood: true
      })
      setTimeout(() => {
        setModal({
          open: false
        })
      
      }, 3000)
    } catch (error) {
      setModal({
        value: "שגיאה בשליחת ההודעה",
        open: true,
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false
        })
      
      }, 3000)
    } finally {
      dispatch({ type: LOAD_END })
    }
  }
  const handleSelectDay = async (day: ISupplyMainList) => {
    dispatch({ type: LOAD })
    try {
      const res = await Forecasts.getForecastsByDateRange(`DateFrom=${day.dateRequestFormat}&DateTo=${day.dateRequestFormat}`)
      const resProductWithCounts = await getProductsRecWithCount(day.dateRequestFormat)
      const productListRest = productFinalListForSelect(day, resProductWithCounts)
      // console.log('productListRest', productListRest)
      let prodDict = productRecDict || {}
      if(day.dateRequestFormat !== selectedDay?.dateRequestFormat) {
        prodDict = resProductWithCounts.reduce((acc: Record<string, IProductRecWithCount>, item) => {
            acc[`${item.ProductID} ${item.VarietyID}`] = item
          return acc
        }, {})
        setProductRecDict(prodDict)
      }
      const addProductList: IAddProductItem[] = productListRest.map((item) => {
        const forecastList = res.filter((forecast) => forecast.ProductID === item.ProductID && forecast.VarietyID === item.VarietyID)
        const couts = prodDict[`${item.ProductID} ${item.VarietyID}`]
        return {
          ...item,
          orderedPackages: 0,
          orderedWeight: 0,
          orderedPallets: 0,
          MlaiPacks: couts?.MlaiPacks || 0, 
          MlaiPallets: couts?.MlaiPallets || 0, 
          MlaiWieght: couts?.MlaiWieght || 0,
          OrdersPacks: couts?.OrdersPacks || 0,
          OrdersPallets: couts?.OrdersPallets || 0,
          OrdersWieght: couts?.OrdersWieght || 0,
          forecastList: forecastList,
          // CalculationMethod: 0
          
        }
      }).sort((a,b) => b.forecastList.length - a.forecastList.length)

      setAddProductList(addProductList)
      setSelectedDay(day)
      handleControlNewProductModal()

    } catch (error) {

    } finally {
      dispatch({ type: LOAD_END })
    }
  }
  const handleSelectProduct = async (dayItem: ISupplyMainList, item: IOrderRehesh) => {
    try {
      const res = await Forecasts.getForecastsByDateRange(`DateFrom=${dayItem.dateRequestFormat}&DateTo=${dayItem.dateRequestFormat}`)
      const forecastList = res.filter((forecast) =>
        (forecast.ProductID === item.ProductID && forecast.VarietyID === item.VarietyID)
        && (!item.DetailsByGrowers.some((grower) => grower.GrowerID === forecast.GrowerID))
      )
      const resCoutns = await getProductsRecWithCountByID(dayItem.dateRequestFormat, item.ProductID, item.VarietyID)
      
      const reheshItem: CountsAddData = {
        ...item,
        OrdersPacks: resCoutns[0]?.OrdersPacks || 0,
        OrdersPallets: resCoutns[0]?.OrdersPallets || 0,
        OrdersWieght: resCoutns[0]?.OrdersWieght || 0,
        MlaiPacks: resCoutns[0]?.MlaiPacks || 0,
        MlaiPallets: resCoutns[0]?.MlaiPallets || 0,
        MlaiWieght: resCoutns[0]?.MlaiWieght || 0,
        DetailsByGrowers: [
          ...item.DetailsByGrowers,
          ...forecastList.map((forecast) => {
            return convertFromForecastToOrderRehesh(forecast, item.WarehouseID, item.Delivery_Date, item.CustomerID)
          })
        ]
      }

      setSelectedDay(dayItem)
      setSelectedOrder(reheshItem)
      handleControlEditProductModal()
    } catch (err) { }
    finally {

    }
  }

  useEffect(() => {
    (async () => {
      dispatch({ type: LOAD })
      try {
        const newArr = [...weekRangeHebrew]
        const res = await Rehesh.getOrders(`DateFrom=${formattedRange[0]}&DateTo=${formattedRange[1]}`)

        res.forEach((item) => {
          const index = newArr.findIndex((day) => day.dateRequestFormat === item.Delivery_Date)
          if (index !== -1) {
            newArr[index].list.push(item)
          }

        })
        setWeekSupplyList(newArr)
      } catch (error) {

      } finally {
        dispatch({ type: LOAD_END })
      }
    })()

  }, [weekRangeHebrew, formattedRange])

  const render = useMemo(() => {
    if (!weekSupplyList) return []
    return weekSupplyList.map(item => {
      return {
        ...item,
        list: item.list.filter(listItem =>
          (warehouseID !== 0 ? listItem.WarehouseID === warehouseID : true)

        )
      }
    })
  }, [weekSupplyList, warehouseID])

  const handleSaveProduct = async (items: IAddProductItem[]) => {
    dispatch({ type: LOAD })

    try {
      const formedData: IOrderRehesh[] = items.filter(e => e.orderedPackages > 0 || e.orderedPallets > 0 || e.orderedWeight > 0).map((item) => {
        return convertProductListToOrderRehesh(item, selectedDay?.dateRequestFormat || '', warehouseID, defaultCustomerID || 0)
      })
      const res = await Rehesh.postOrderSupplyDetails(formedData)
      const newArr = [...weekSupplyList]
      const index = newArr.findIndex((item) => item.dateRequestFormat === selectedDay?.dateRequestFormat)
      newArr[index].list = [
        ...res,
        ...newArr[index].list,
      ]
      setWeekSupplyList(newArr)
      handleControlNewProductModal()
    } catch (err) {

    } finally {
      dispatch({ type: LOAD_END })
    }
  }

  const handleSaveEditedProduct = async (item: IOrderRehesh) => {
    dispatch({ type: LOAD })
    try {
      const res = await Rehesh.postOrderSupplyDetails([item])
      const newArr = [...weekSupplyList]
      const index = newArr.findIndex((item) => item.dateRequestFormat === selectedDay?.dateRequestFormat)
      newArr[index].list = [
        ...newArr[index].list.map((listItem) => {
          console.log(listItem.RecID , res[0].RecID)
          if (listItem.RecID === res[0].RecID || res[0].RecID === 0) {
            console.log('res[0]', res[0])
            return res[0]
          }
          return listItem
        }).filter((itemFilter) => itemFilter.RecID !== 0),
      ]
      setWeekSupplyList(newArr)
      handleControlEditProductModal()
    } catch (err) {

    } finally {

      dispatch({ type: LOAD_END })
    }
  }
  const handleChangeWeekDate = (dir: "back" | "up") => {
    setSelectedDate(dateChangeWeek(selectedDate, dir))
  }

  const handleControlNewProductModal = () => {
    if(!warehouseID) return handleControlFilterModal()
    setIsOpenNewProductModal(!isOpenNewProductModal)
  }
  const handleControlEditProductModal = () => {
    setIsOpenEditProductModal(!isOpenEditProductModal)
  }
  const handleControlFilterModal = () => {
    setIsOpenFilterModal(!isOpenFilterModal)
  }
  const handleControlFilterCustomerModal = () => {
    setIsOpenFilterCustomerModal(!isOpenFilterCustomerModal)
  }
  const newProductModalProps = {
    list: addProductList,
    setList: setAddProductList,
    selectedDay: selectedDay,
    handleSaveProduct: handleSaveProduct,
    growerList: growerList?.growers || [],
    calculationMethod: calculationMethod
  }
  const editProductModalProps = {
    item: selectedOrder,
    saveFunc: handleSaveEditedProduct,
    calculationMethod: calculationMethod,
    growerList: growerList?.growers || []
  }
  console.log(calculationMethod)
  const filterModalProps = {
    list: warehouseList || [],
    defaultID: warehouseID && warehouseID !== 0 ? warehouseID : defaultTranzit,
    setWarehouseID: setWarehouseID
  }
  
  return (
    <FullSizeLayout
      label='תוכנית רכש שבועית'
    >
      
      {
        isOpenNewProductModal &&
        <ColumModal
          title={`הוספת פריט ל` + `${selectedDay?.dateDesc || ''}`}
          Children={ModalNewProducts}
          childrenProps={newProductModalProps}
          controlFunc={handleControlNewProductModal}
        />
      }
      {
        isOpenEditProductModal &&
        <ColumModal
          title='עדכון כמויית לפריט'
          Children={ModalEditProduct}
          childrenProps={editProductModalProps}
          controlFunc={handleControlEditProductModal}

        />
      }
      {
        isOpenFilterModal &&
          <ColumModal 
            Children={FilterModal}
            controlFunc={handleControlFilterModal}
            childrenProps={filterModalProps}
            title='בחירת מחסן'
          />
      }
      <ControlSearchPannelLayout>
        
        <CircleActiveFilterBtn
          func={handleControlFilterModal}
        >
          <SettingsIcon />
        </CircleActiveFilterBtn>
       
        <DateWrapper>
          <ButtonDateControl
            position='left'
            handleChangeWeekDate={handleChangeWeekDate}
          />
          <DatePickerISO
            selected={selectedDate}
            onChange={(date: Date) => setSelectedDate(date)}
          />
          <ButtonDateControl
            position='right'
            handleChangeWeekDate={handleChangeWeekDate}
          />
        </DateWrapper>
      </ControlSearchPannelLayout>
      <BodyScrollContainer>
        {
          render.map((day, index) =>
            <SupplyDayColumnItem
              index={index}
              item={day}
              handleSelectDay={handleSelectDay}
              handleSelectProduct={handleSelectProduct}
              sendSms={sendSms}
            />
          )
        }
      </BodyScrollContainer>
    </FullSizeLayout>
  )
}

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`
const TranzitWrapper = styled.div`
  max-width: 200px;
`
export default SupplyMain