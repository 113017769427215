import React, { useState, useMemo, useEffect } from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IPalletDetails, IPalletsDetailsSum } from '../../../../../modules/API/Pallets/models/palletDetails'
import { Certificates } from '../../../../../modules/API/Certificates/certificates'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import CustomBlockLoader from '../../knisot/generic/CustomBlockLoader'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import TeudotDetailsItem from './Components/TeudotDetailsItem'
import TeudotDetailsInfoTitle from './Components/TeudotDetailsInfoTitle'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import useDebounce from './helpers/useDebounce'
import LoaderInfinitScroll from './Components/LoaderInfinitScroll'
import { IPatchItem } from '../../../../../modules/API/Certificates/models/patchItem'
import DropdownByIcon from '../../azmanotRehesh/Layouts/DropdownByIcon'
import { InvoiceIcon, InvoiceMoneyIcon, SettingMiksher, ShekelIcon } from '../../../../../generic/icons/Icons'
import PriceFilter from './Components/PriceFilter'
import InvoiceFilter from './Components/InvoiceFilter'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import Info from './Components/Info'
import { IRemarkData } from '../../arizot/View/ArizotViewMain'
import RemarkModal from '../../arizot/View/RemarkModal'
import UserIcon from '../../../../../generic/icons/IconLib/UserIcon'
import CustomerFilter from './Components/CustomerFilter'
import SettingsMain from './Components/SettingsMain'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { IGrowerItem, IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'

const OFFSET = 100

export interface ItemForRemark extends IRemarkData {
    itemData: IPalletDataForPatch
}

export interface IPalletDataForPatch {
    BuyPrice: number,
    SalePrice: number,
    WeightBuy: number,
    WeightNeto: number,
    Type_BuyCalculation: number,
    Type_SaleCalculation: number,
    Price_Guide_From: number,
    Price_Guide_To: number,
    Remarks: string
}
const TeudotDetailsMain = () => {
    const userRights = useSelector((state: RootState) => state.mainReducer.user?.rights)
    const xTotalCount = useSelector((state: RootState) => state.crossModulesQueries.xTotalCountTeudotDetails)
    const defaults = useSelector((state: RootState) => state.defaults.defaults)
    const isAllowToShowPriceForGrower = selectBrodMigdal(defaults, 298, "Logical_Value")
    const isAllowToAutoEditPriceKniya = selectBrodMigdal(defaults, 17, "Logical_Value")
    const isAllowToAutoEditWeightKniya = selectBrodMigdal(defaults, 18, "Logical_Value")
    const [dateStart, setDateStart] = useState<string>("");
    const [dateEnd, setDateEnd] = useState<string>("");
    const [searchInput, setSearchInput] = useState<string>("");
    const [pageNum, setPageNum] = useState(1);

    const [loading, setLoading] = useState(false);
    const [allItemsLoaded, setAllItemsLoaded] = useState(false);
    const [diferecePrice, setDiferecePrice] = useState<boolean>(false)

    const [priceFilterMihira, setPriceFilterMihira] = useState<number>(0);
    const [priceFilterKniya, setPriceFilterKniya] = useState<number>(0);
    const [invoiceFilterMihira, setInvoiceFilterMihira] = useState<number>(0);
    const [invoiceFilterKniya, setInvoiceFilterKniya] = useState<number>(0);
    const [customerID, setCustomerID] = useState<number>(0);
    const [growerID, setGrowerID] = useState<number>(0);
    const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);
    const [remarkData, setRemarkData] = useState<ItemForRemark | null>(null);
    const [isOpenRemarksModal, setIsOpenRemarksModal] = useState<boolean>(false);
    const [isOpenSettingsModal, setIsOpenSettingsModal] = useState<boolean>(false);
    
    
    const { 
        data: dataDetailes, 
        isLoading: isLoadingDetails 
    } = useDataFetcher<IPalletDetails[]>(
            Certificates.getTeudotDetailsByDateRange,
            `DateFrom=${dateStart}&DateTo=${dateEnd}&Page=${pageNum}&ItemsPerPage=${OFFSET}&GrowerID=${growerID}&CustomerID=${customerID}&SearchString=${searchInput}&InvoiceSale=${invoiceFilterMihira}&InvoiceBuy=${invoiceFilterKniya}&SalePrice=${priceFilterMihira}&BuyPrice=${priceFilterKniya}`,
            pageNum > 1
        )

    const { 
        data: dataSum, 
        isLoading: isLoadingSum 
    } = useDataFetcher<IPalletsDetailsSum>(
        Certificates.getTeudotDetailsSum,
        `DateFrom=${dateStart}&DateTo=${dateEnd}&GrowerID=${growerID}&CustomerID=${customerID}&SearchString=${searchInput}&InvoiceSale=${invoiceFilterMihira}&InvoiceBuy=${invoiceFilterKniya}&SalePrice=${priceFilterMihira}&BuyPrice=${priceFilterKniya}`,
        false,
        diferecePrice
    )

    const {
        data: growerList,
    } = useDataFetcher<IGrowerNodeGetResponse>(
        Defaults.getGrowerList, 'data'
    )
    const handleOpenRemarksModal = () => {
        setIsOpenRemarksModal(prev => !prev)
    }
    const patchDetails = async (id: number, data: IPatchItem, isNeedToRecalculateHead: number) => {
        try {
            await Certificates.patchTeudotDetailsItem(id, data)
            isNeedToRecalculateHead && setDiferecePrice(prev => !prev)
        } catch (err) {
        }
    }

    const updateFields = (id: number, data: IPatchItem, index: number) => {
        if (!dataDetailes) return
        patchDetails(id, data, index);
    }

    const handleInputChange = useDebounce({
        callback: (e: string | number) => {
            setPageNum(1)
            if (typeof e === 'string') {
                setSearchInput(e)
            }
        },
        delay: 1000
    })

    useEffect(() => {
        if (!xTotalCount) return
        if (dataDetailes && dataDetailes?.length >= +xTotalCount) {
            setAllItemsLoaded(true)
        } else {
            setAllItemsLoaded(false)
        }
    }, [xTotalCount, dataDetailes])

    useEffect(() => {
        if (!dateStart || !dateEnd) return
        setPageNum(1)
    }, [dateStart, dateEnd])

    const handleIntersection: IntersectionObserverCallback = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoadingDetails) {
            loadMoreData();
        }
    };

    const loadMoreData = () => {
        if (allItemsLoaded) return
        setLoading(true);
        //   Simulate an API call to fetch new data
        setTimeout(() => {
            // if (newData.length === 0) {
            //   setAllItemsLoaded(true);
            // }
            setLoading(false);
            setPageNum((prevPages) => prevPages + 1);

        }, 500);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        });
        if (dataDetailes && dataDetailes.length > 0) {
            const target = document.querySelector('#last-item');
            if (target) {
                observer.observe(target);
            }
        }
        return () => {
            observer.disconnect();
        };
    }, [dataDetailes, allItemsLoaded]);

    const render = useMemo(() => {
        if (!dataDetailes) return []
        return dataDetailes
        // .filter((item) => {
        //     return item.Delivery_Num.toString().includes(searchInput) ||
        //         item.ProductDesc.trim().includes(searchInput)
        // })
    }, [dataDetailes])

    const rights = useMemo(() => {
        if (!userRights) return ''
        const hashRights: Record<string, string> = {
            'ADMIN': 'ADMIN',
            'GROWER': 'GROWER',
            'OFFICE': 'ADMIN',
            "CUSTOMER": "CUSTOMER",
            "SELLER": "SELLER",
            "WORKER": "WORKER",
            "DEV": "ADMIN"
        }
        return hashRights[userRights[0]]
    }, [userRights])


    const additionalDataInfoModal = {

    }

    const handleChange = (e: string) => {
        handleInputChange(e)

    }
    const handleChangePriceFilter = (e: number, isMihira: boolean) => {
        setPageNum(1);
        if (isMihira) {
            setPriceFilterMihira(e)
            return
        }
        setPriceFilterKniya(e)
    }
    const handleChangeInvoiceFilter = (e: number, isMihira: boolean) => {
        setPageNum(1);
        if (isMihira) {
            setInvoiceFilterMihira(e)
            return
        }
        setInvoiceFilterKniya(e)
        // setInvoiceFilter(e)
    }
    const handleControlInfoModal = () => {
        setIsOpenInfoModal(prev => !prev)
    }
    const handleSettingsModal = () => {
        setIsOpenSettingsModal(prev => !prev)
    }
    const handleSelectRemark = (item: ItemForRemark, RecID: number) => {
        setRemarkData({
            itemData: item.itemData,
            title: 'הערה לשורת תעודת משלוח',
            id: RecID,
            body: item.body,
        })
        handleOpenRemarksModal()
    }
 
    const handleSaveRemark = (input: string) => {
        if (!remarkData) return
        patchDetails(+remarkData.id, { ...remarkData.itemData, Remarks: input }, 0)
        handleOpenRemarksModal()
    }
    const remarkWindowProps = {
        data: remarkData,
        handleSave: handleSaveRemark,
        isActive: true,
    }

    const additionalData = {
        userRights: rights,
        isAllowToShowPriceForGrower: isAllowToShowPriceForGrower,
        updateFields: updateFields,
        setDiferecePrice: setDiferecePrice,
        isAllowToAutoEditPriceKniya: isAllowToAutoEditPriceKniya,
        isAllowToAutoEditWeightKniya: isAllowToAutoEditWeightKniya,
        handleSelectRemark: handleSelectRemark

    }
    return (
        <OrderlikeLayout label='שורות תעודות משלוח'>
            {
                isOpenSettingsModal &&
                <ColumModal
                    title='הגדרות'
                    controlFunc={() => setIsOpenSettingsModal(prev => !prev)}
                    childrenProps={{
                        // customer
                        selectedID: customerID,
                        setCustomerID: setCustomerID,
                        //gorwer
                        growerList: growerList?.growers || [],
                        growerID: growerID,
                        setGrowerID: setGrowerID,
                        //invice
                        valueM: invoiceFilterMihira,
                        valueK: invoiceFilterKniya,
                        setValueInvoice: handleChangeInvoiceFilter,
                        rights: rights,
                        //price
                        pvalueM: priceFilterMihira,
                        pvalueK: priceFilterKniya,
                        setValuePrice: handleChangePriceFilter,

    
                    }}
                    Children={SettingsMain}
                />
            }
            {
                isOpenRemarksModal &&
                <ColumModal
                    title={'הערה לשורת תעודת משלוח'}
                    Children={RemarkModal}
                    controlFunc={handleOpenRemarksModal}
                    childrenProps={remarkWindowProps}
                />
            }
            {
                isOpenInfoModal &&
                <ColumModal
                    title=' (!) מקרא'
                    controlFunc={handleControlInfoModal}
                    childrenProps={additionalDataInfoModal}
                    Children={Info}
                />
            }
            <TeudotDetailsInfoTitle
                detailsList={dataSum}
                date1={dateStart}
                date2={dateEnd}
                rights={rights}
                onInfoClick={handleControlInfoModal}
            />
            <ControlSearchPannelLayout>
                <CircleActiveFilterBtn
                    required={
                        invoiceFilterKniya !== 0 || invoiceFilterMihira !== 0 ||
                        priceFilterMihira !== 0 || priceFilterKniya !== 0 ||
                        customerID !== 0 || growerID !== 0
                    }
                    func={handleSettingsModal}
                >
                    <SettingMiksher />
                </CircleActiveFilterBtn>
                
                <SearchInput
                    setInput={handleChange}
                />
                <DatePickerModule
                    multyDate={true}
                    setSelectedDateStart={setDateStart}
                    setSelectedDateEnd={setDateEnd}
                    selected={false}
                    defEndDate={false}
                    disabled={false}
                    // large
                />
            </ControlSearchPannelLayout>
            <BodyScrollContainer>
                {
                    isLoadingDetails && (!dataDetailes || dataDetailes.length === 0) ?
                        <CustomBlockLoader /> :
                        <TitlesList
                            uniqueKey={'RecID'}
                            list={render}
                            Element={TeudotDetailsItem}
                            additionalData={additionalData}
                            noPadding={false}
                        />
                }
                {
                    isLoadingDetails && dataDetailes && dataDetailes?.length > 0 &&
                    <LoaderInfinitScroll />
                }
            </BodyScrollContainer>
        </OrderlikeLayout>
    )
}


export default TeudotDetailsMain