import React, { useEffect, useMemo, useState, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux';
import DataBaseList from './dataBaseList/dataBaseList'
import InternalCompaniesList from './internalCompanies/internalCompaniesList'
import DbList from './dataBaseList/dbList'
import { useSelector } from 'react-redux'
import { USER_LOADED, LOAD_USER, LOAD, LOAD_END, LOAD_DEFAULTS } from '../../../../store/actions/actionsTypes'
import { GET_DB, PATCH_DB, GET_INTERNAL_COMPANIES, CHANGE_INTERNAL_COMPANIES, CURRENT_PATH } from '../../../modules/API/settings'
import history from '../../../../history'
import { ContextModal } from "../../../modules/modal/modalContext"
import styled from 'styled-components';
import selectOnFocus from './../../../modules/autoselect'
import { loadDefaults } from '../../../modules/requests/loadDefaults';
import { Auth } from '../../../modules/API/Auth/auth';

const Section = styled.section`
    width: 100%;
    height: 100%;
    padding: 0 0.5em;
    /* background: red; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 2.5em;
`

const Container = styled.div`
    height: 70%;
    /* background: blue; */
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 600px){
        width: 80%;
    }
    @media screen and (min-width: 1000px){
        width: 50%;
    }

`
const ContainerTitle = styled.div`
    height: 15%;
    width: 100%;
    background: #a07968;
    border-radius: 5px;
    padding: 0.5em 0.5em;
    display: flex;
    justify-content: flex-end;
    align-items: center
`
const ContainerInner = styled.div`
    height: 80%;
    width: 100%;
    background: #e1cba2;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 1em 0;
    border-radius: 5px;
`
const Span = styled.span`
    position: absolute;
    width: 2em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
    left: 0;
    i {
        font-size: 0.9em;
    }
`
const Wrapper = styled.div`
    /* background: red; */
    height: 2.5em;
    width: 15em;
    position: relative;
    margin-bottom: 1em;
    :hover{
        .far, .fas{
            animation: shake 0.5s ease;
                @keyframes shake {
                    0% {
                        transform: translateX(0);
                    }
                    50% {
                        transform: translateX(-5px);
                    }
                    75% {
                        transform: translateX(3px);
                    }
                    90% {
                        transform: translateX(-2px);
                }
            }
        }
    }
`
const Input = styled.input`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 2px solid #80e081;
    outline: none;
    appearance: none;
    text-align: right;
    padding: 0 0.5em;
    cursor: pointer;
    font-size: 1em;

`
const TitleWrapper = styled.div`
    width: 20%;
    height: 100%;
    background: #e1cba2;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        font-size: 1.5em;
        cursor: pointer;
        @media screen and (min-width: 600px){
            font-size: 2em;
    }
    }
`

function MainBarMain() {
    const getID = useSelector(token => token.mainReducer.user.company_id);
    const getYear = useSelector(year => year.mainReducer.user.year);
    const getToken = useSelector(token => token.mainReducer.token)
    const dispatch = useDispatch();
    const axios = require('axios')
    const CancelToken = axios.CancelToken.source();

    const [modal, setModal] = useContext(ContextModal)
    const [dataBase, setDataBase] = useState([])
    const [dataBaseDefault, setDataBaseDefault] = useState([])
    const [chooseDb, setChooseDb] = useState('')
    const [input, setInput] = useState('')
    const [inputDefault, setInputDefault] = useState('')

    // useEffect(()=>{console.log(chooseDb)}, [])


    const [showDbStatus, setShowDbStatus] = useState(false)
    const refDbList = useRef(null)

    //internal
    const [internalList, setInternalList] = useState([]);
    const [internalId, setInternalId] = useState('');
    // useEffect(() => console.log(internalId), [internalId])
    const [year, setYear] = useState('')
    // const getID = useSelector(token => token.mainReducer.user.company_id);
    let existDb = getID ? getID : ''
    let showDbList = showDbStatus ? 'block' : 'none'
    window.onclick = (e) => {
        if (e.target !== refDbList.current) {
            setShowDbStatus(false)
        }
    }

    const getDb = async () => {
        axios.get(GET_DB, {
            headers: {
                'Authorization': 'Bearer ' + getToken
            }
        },
            {
                cancelToken: CancelToken.token
            })
            .then(function (response) {
                // console.log(response.data)
                setDataBase(response.data)
                setDataBaseDefault(response.data)
                // const filtered = response.data.filter((item) => {
                //     return item.id === getID
                // })
                // setInput(filtered[0].label)
                // console.log(filtered[0].label)
                // console.log(filtered[0].label)
            })

    }


    const patchDB = async () => {
        dispatch({ type: LOAD });
        axios({
            method: 'patch',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken
            },
            data: {
                'dbId': chooseDb
            },

            url: PATCH_DB

        },
            {
                cancelToken: CancelToken.token
            })
            .then(function (response) {
                // console.log(response);
                dispatch({ type: USER_LOADED, payload: response.data.token});
                dispatch({ type: LOAD_END });
                localStorage.setItem('user', JSON.stringify(response.data));
                // dispatch({ type: LOAD_USER, payload: response.data.token })
                
                loadUser(response.data.token)

            })

            .catch(err => {
                // console.log(err.response.data.message)
                dispatch({ type: LOAD_END });
                if (err.response.data.message === 'jwt malformed' || err.response.data.message === 'jwt expired' || err.response.data.message === 'קונטקסט לא פעיל, כנס מחדש למערכת') {
                    setModal({
                        open: true,
                        value: 'התנתקת מהמערכת, אנא כנס מחדש',
                        mood: false
                    })
                    setTimeout(() => {
                        setModal({
                            ...modal,
                            open: false
                        })
                    }, 3000)

                }
            })
            
    };
    const loadUser = async (getToken) => {
        dispatch({ type: LOAD });
        try {
            let response = await Auth.loadUser(getToken)
            // console.log(response.data.user);
            dispatch({ type: LOAD_USER, payload: response.data.user })
            getInternalCompanies()
            // console.log('data int')
        } catch (err) {
            // console.log(err)
        } finally {
            dispatch({ type: LOAD_END });
        }
    }
    // useEffect(() => { patchDB(); return () => { CancelToken.cancel("axios request cancelled") } }, [chooseDb])
    useEffect(() => { patchDB(); return () => { CancelToken.cancel("axios request cancelled") } }, [chooseDb])
    useEffect(() => { 
        getDb(); 
        return () => { CancelToken.cancel("axios request cancelled") } 
    }, [])



    const serchDb = (input) => {
        const filtered = dataBaseDefault.filter((item) => {
            return item.label.toLowerCase().includes(input.toLowerCase())
        })
        setInput(input);
        setDataBase(filtered)
    }

    const getInternalCompanies = async () => {
        dispatch({ type: LOAD });
        try {
            let response = await axios.get(`${GET_INTERNAL_COMPANIES}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken,
                    'Content-Type': 'application/json'
                }
            })
            // localStorage.setItem('user', JSON.stringify(response.data));
            // dispatch({ type: USER_LOADED });
            setInternalList(response.data)
            dispatch({ type: LOAD_END });
        } catch (err) {
            // console.log(err.response.data.message)
            dispatch({ type: LOAD_END });
        }
    }

    const upDateInternalCompanies = async () => {
        dispatch({ type: LOAD });
        try {
            let response = await axios({
                method: 'put',
                url: `${CHANGE_INTERNAL_COMPANIES}`,
                headers: {
                    'Authorization': 'Bearer ' + getToken,
                    'Content-Type': 'application/json'
                },
                data: {
                    "year": year,
                    "company_internal": internalId
                }
            })
            dispatch({ type: LOAD_END });
            localStorage.setItem('user', JSON.stringify(response.data));
            dispatch({ type: USER_LOADED, payload: response.data.token });
           
            loadUser(getToken)
        } catch (err) {
            dispatch({ type: LOAD_END });
            // console.log(err.response.data.message)
        }
    }
    useEffect(() => {
        upDateInternalCompanies()
    }, [internalId])
    useEffect(() => { getInternalCompanies() }, [])
    // useMemo(loadUser, [chooseDb])
    return (
        
        <Section>
        
            {/* <div className="dropdown__container" >
                        <DataBaseList dataBase={dataBase} setChooseDb={setChooseDb} patchDB={patchDB} chooseDb={chooseDb} />
                    </div> */}
            <Container>
                <ContainerTitle>
                    <TitleWrapper>
                        <i className="fas fa-user-cog"></i>
                    </TitleWrapper>
                </ContainerTitle>
                <ContainerInner>
                    <Wrapper>
                        <Span ><i className="fas fa-database"></i></Span>
                        <Input onClick={() => { setShowDbStatus(true) }} onFocus={selectOnFocus} value={input === ''?inputDefault: input} onChange={(e) => { serchDb(e.target.value) }} ref={refDbList} />
                        <DbList dataBase={dataBase} setChooseDb={setChooseDb} patchDB={patchDB} chooseDb={chooseDb}
                            inputDefault={inputDefault} setInputDefault={setInputDefault} showDbStatus={showDbStatus} showDbList={showDbList}
                            setInput={setInput} input={input} />
                    </Wrapper>
                    <Wrapper>
                        <Span ><i className="fas fa-laptop-house"></i></Span>
                        <InternalCompaniesList
                            internalList={internalList}
                            setInternalId={setInternalId}
                        />
                    </Wrapper>
                    <Wrapper>
                        <Span ><i className="far fa-calendar-alt"></i></Span>
                        <Input style={{cursor: 'default'}} readOnly={true} defaultValue={getYear} />
                    </Wrapper>
                </ContainerInner>    
            </Container>  
        </Section>
    )
}

export default MainBarMain;