import React, {useState, useMemo} from 'react'
import { IPrintersItem } from '../../../modules/API/Defaults/models/printers'
import SearchFromTheList from '../../customSearchComponents/SearchFromTheList'

type Props = {
  list: IPrintersItem[]
  defaultID: number,
  setPrinterID: (id: number) => void,
  required?: boolean,
  placeholder?: string
}

const SearchPrinter = ({
  list, defaultID, setPrinterID, placeholder

}: Props) => {
  const defaultPrinter = list.filter(item => item.NumWeight === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedPrinter, setSelectedPrinter] = useState<IPrintersItem | null>(defaultPrinter || null)
  const [search, setSearch] = useState<string>(defaultPrinter?.DescWeights?.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedPrinter(null)
      setPrinterID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IPrintersItem) => {
    setPrinterID(item.NumWeight)
    setSearch(item.DescWeights.trim())
    setSelectedPrinter(item)
    setIsOpenDropDown(false)
  }

  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.DescWeights?.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [list, search])
  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={placeholder || 'חפש מדפסת'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='DescWeights'
      nameIdElement='NumWeight'
      required={false}
      size={'full'}
    
    />
  )
}

export default SearchPrinter