import { format } from 'date-fns'
import React, {useState, useMemo} from 'react'
import { useSelector } from 'react-redux'
import { IWorkerItem } from '../../../../../modules/API/Defaults/models/workers'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { ICreateSortingData } from '../../../../../modules/API/Miun/models/fetchData'
import { Miun } from '../../../../../modules/API/Miun/miun'
import { IProductListItemNode } from '../../../../../modules/API/Defaults/models/productListNode'
import styled from 'styled-components'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { IGrowerItem } from '../../../../../modules/API/Defaults/models/growers'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IVarietyListItem, IVarietyListResponse } from '../../../../../modules/API/Defaults/models/varietyList'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import SelectFromTheList from '../../../../../generic/customSearchComponents/SelectFromTheList'
import selectOnFocus from '../../../../../modules/autoselect'
import { ISortingListItem } from '../../../../../modules/API/Miun/models/sortingList'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import SearchWarehouse from '../../knisot/Create/Components/SearchWarehouse'
import SearchProduct from '../../forcasts/Products/Components/SearchProduct'
import SearchVaiety from '../../forcasts/Products/Components/SearchVaiety'
import SearchGrower from '../../knisot/Create/Components/SearchGrower'

type Props = {
    productList: IProductListItemNode[];
    dataGrowers: IGrowerItem[];
    warhouseList: IWarhouseListItem[];
    closeFunc: () => void;
    uppendData: (data: ISortingListItem) => void;
}

const CreateSorting = ({
    productList, dataGrowers, closeFunc, warhouseList, uppendData
}: Props) => {

    // Sorting create
    const defaults = useSelector((state: RootState) => state.defaults.defaults)
    const defaultProduct: number = selectBrodMigdal(defaults, 7, 'Num_Value')
    const defaultVariety: number = selectBrodMigdal(defaults, 8, "Num_Value")
    const defaultTransit: number = selectBrodMigdal(defaults, 5, 'Num_Value')
    const user = useSelector((state: RootState) => state.mainReducer.user)
    const workerList: IWorkerItem[] = useSelector((state: RootState) => state.defaults.workerList)
    const [sortingCreateDate, setSortingCreateDate] = useState<string>("");
    const [grower, setGrower] = useState<number>(0);
    const [product, setProduct] = useState<number>(defaultProduct || 0);
    const [variety, setVariety] = useState<number>(defaultVariety || 0);
    const [remarks, setRemarks] = useState<string>("");
    const [sortingReference, setSortingReference] = useState<number>(0);
    const [warhouse, setWarhouse] = useState<number>(defaultTransit || (user? Number(user.tranzit) : 0));

    // VARIETY
    const {
        data: varietyList,
        isLoading: varietyListIsLoading
    } = useDataFetcher<IVarietyListResponse>(
        Defaults.getVarietyByProductID, (product || 0).toString() 
    )

    const worker = useMemo(() => {
      if(!workerList || !user?.code_internal) return ""
      return workerList.find(item => item.WorkerId === +user?.code_internal)?.WorkerName || "Office"
    }, [user, workerList])
    
    const isAllowToCreate = product && grower && warhouse && sortingReference

    const postCreateSorting = async () => {
        if(!isAllowToCreate) return
      const regTime = format(new Date(), 'HHmmss')
      const data: ICreateSortingData = {
        Date_Sorting: sortingCreateDate,
        GrowerID: grower,
        ProductID: product,
        Remarks: remarks,
        Sorting_Reference: sortingReference,
        Time_Sorting: regTime,
        VarietyID: variety,
        WarehouseID: warhouse,
        WorkerName: worker
      }
      try {
        const res: ISortingListItem = await Miun.createSorting(data);
        uppendData(res)
        closeFunc()
      } catch (err) {
       
      }
    }

  return (
    <MainWrapper>
        <SelectWrapper>
            <SelectorWrapper>
                <Title>בחר מגדל</Title>
                <SelectItemWrapper>
                    <DatePickerModule 
                        multyDate={false}
                        setSelectedDateStart={setSortingCreateDate}
                        ignorBrod={true}
                        disabled={false}
                        selected={false}
                        defEndDate={false}
                    />
                    <SearchGrower 
                        list={dataGrowers}
                        defaultID={grower}
                        setGrowerID={setGrower}
                        required={!grower}
                    />
                </SelectItemWrapper>
            </SelectorWrapper>
            <SelectorWrapper>
                <Title>בחר טרנזיט</Title>
                <SearchWarehouse 
                  list={warhouseList || []}
                  setWarehouseID={setWarhouse}
                  defaultID={warhouse || defaultTransit || (user? Number(user.tranzit) : 0)}
                  required={!warhouse}
                />
            </SelectorWrapper>
            <SelectorWrapper>
                <Title>בחר מוצר</Title>   
                <SearchProduct 
                    list={productList || []}
                    defaultProductID={product || defaultProduct || 0}
                    setDefaultProductID={setProduct}
                    required={!product}
                />
            </SelectorWrapper>
            {
                product ?
                    <>
                        <SelectorWrapper>
                            <Title>בחר זן</Title>   
                            <SearchVaiety 
                                list={varietyList?.variety || []}
                                defaultID={variety || defaultVariety || 0}
                                setID={setVariety}
                                required={!variety}
                            />
                        </SelectorWrapper>
                    </> : null
            }
            <SelectorWrapper>
                <Title>אםמכתה</Title>   
                <Input 
                    value={sortingReference}
                    type='number'
                    onChange={(e) => setSortingReference(+e.target.value)}
                    placeholder={"אםמכתה"}
                    onFocus={selectOnFocus}
                />
            </SelectorWrapper>
        </SelectWrapper>
        {
            isAllowToCreate ?
                <ControlButton 
                    handleClick={postCreateSorting}
                    lable={"סיום"}
                /> : null
        }
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  padding-bottom: 0.5em;
  overflow: hidden;
`

const SelectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
  padding: 0.5em; 
  overflow-y: auto;
  ::-webkit-scrollbar{
    width: 0;
  }
`
const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  background: rgb(233,240,240, 0.2)
`
const SelectItemWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  justify-content: space-between;
`
const Input = styled.input`
  width: 100%;
  height: 3em;
  border-radius: 30px;
  background: #EFF3F4;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1.2em;
  color: #757575;
`
const Title = styled.div`
  text-align: end;
  color: #297F76;
  font-weight: 600;
  padding: 0 0.5em;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse

`
export default CreateSorting