import React from 'react'
import Imgage from '../../../../../../generic/assets/collect.png'
import styled from 'styled-components'
import SelectFromTheList from '../../../../../../generic/customSearchComponents/SelectFromTheList'
type Props = {
    valueM: number,
    valueK: number,
    setValue: (e: number, isMihira: boolean) => void;
    rights: string
}

const data = [
    // {id: 0, name: 'כל המוצרים'},
    { id: 1, name: 'עם מחיר' },
    { id: 2, name: 'ללא מחיר' },
]
const PriceFilter = ({ valueM, valueK, setValue, rights }: Props) => {

    const handleSelectM = (e: string) => {
        setValue(+e, true)
    }
    const handleSelectK = (e: string) => {
        setValue(+e, false)
    }
    return (
        <MainWrapper>
            {
                rights !== "GROWER" &&
                <>
                    <Text>מחיר מכירה</Text>
                    <SelectFromTheList
                        list={data}
                        // defaulValue={0+""}
                        selectedValue={valueM}
                        dispalyFieldName='name'
                        itemKey='id'
                        lable={'הכל'}
                        selectFunction={handleSelectM}
                        size={'full'}
                        
                    />
                </>
            }
            {
                rights !== "CUSTOMER" &&
                <>
                    <Text>מחיר קניה</Text>
                    <SelectFromTheList
                        list={data}
                        // defaulValue={0+""}
                        selectedValue={valueK}
                        dispalyFieldName='name'
                        itemKey='id'
                        lable={'הכל'}
                        selectFunction={handleSelectK}
                        size={'full'}
                        
                    />
                </>
            }
        </MainWrapper>
    )
}
const MainWrapper = styled.div`
    padding: 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    /* max-width: 150px; */
    /* overflow: hidden; */
    background: #fff;
    border-radius: 30px;
`
const Img = styled.img`
    width: 150px;
`
const Text = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5em;
`
export default PriceFilter