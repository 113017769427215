import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { InvoiceIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../generic/icons/Icons';
import TitlesList from '../../../../generic/titlesRowItem/TitlesList';
import PalletItem from './PalletItem';

const MainWrapper = styled.div`
    width: 100%;
    user-select: none;
    border-radius: 30px;
    background: ${props => props.hasPallet ? 'rgba(41,127,126, 0.8)' : '#eff3f4'} ;
    display: flex;
    flex-flow: column;
    padding: 0.5em;
    gap: 0.5em;
    /* filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51)); */
    
`

const TitleWrapper = styled.div`
    width: 100%;
    min-height: 5em;
    background: #fff;
    display: flex;
    flex-flow: column;
    border-radius: 20px;
    padding: .5em;
    gap: 0.3em;

`
const TitleItem = styled.div`
    width: 100%;
    /* padding: 0 0.5em; */
    max-height: 1.1em;
    overflow: hidden;
    text-align: start;
    /* background: yellow; */
    display: inline-flex;
    justify-content: flex-end;
    color: #75759e;
    flex-flow: ${props => props.reversed ? 'row' : 'row-reverse'};
    gap: 0.5em;
   

`
const Checker = styled.div`
    position: absolute;
    width: 2em;
    height: 2em;
    background: red;
`

const NestedItemsContainer = styled.div`
    /* height: 100%; */
    padding: 0.5em 0;
    /* background: rgba(41,127,118,0.51); */
    /* border-radius: 0 0 20px 20px; */
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-flow: column;
    gap: 0.5em;
`

function InnerGroupContainer({ 
    index, OrderID, pallets, collectDragPallets, PalletTransferZone, Customer_Name, reversed, 
    Status, PalletsQty, Width, Custom, Products, statusListAvalibleToCollectInPallet
}) {
    const [isOpen, setIsOpen] = useState(false);
    const widthCalc = Math.round(pallets?.reduce((acc, cur) => {
        acc += cur.GrossWeight
        return acc
    }, 0))
    const {calcPalletsQty, calcProductsQty} = pallets.reduce((acc, cur) => {
        
        acc['calcProductsQty'] = acc['calcProductsQty'] ? acc['calcProductsQty'] + cur.Total_Packages : cur.Total_Packages
        acc['calcPalletsQty'] = acc['calcPalletsQty'] ? acc['calcPalletsQty'] + cur.Total_Pallets : cur.Total_Pallets
        return acc
    }, {})
    
    const addictionalData = {
        collectDragPallets: collectDragPallets
    }
    // console.log(Status)
    const allowToDragPallet = (status) => {
        return statusListAvalibleToCollectInPallet.some(e => e === status)
    }
    
    const isAllowForDrag = allowToDragPallet(Status);
    return (
        <Draggable
            draggableId={OrderID?.toString() + PalletTransferZone}
            index={index}
        >
            {
                (provided, snapshot) =>
                    <MainWrapper
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        hasPallet={isAllowForDrag}
                        
                    >


                        <TitleWrapper
                            isOpen={isOpen && isAllowForDrag}
                            onClick={() => {setIsOpen(!isOpen)}}
                        >
                            <TitleItem reversed={reversed} data-tip data-for={`customerNameTip${OrderID}`}>
                                {Customer_Name?.trim()}
                                <UserIcon />
                            </TitleItem>
                            <ReactTooltip id={`customerNameTip${OrderID}`} place="top" effect="solid">
                                {Customer_Name?.trim()}
                            </ReactTooltip>
                            <TitleItem reversed={reversed}>
                                {OrderID}
                                <InvoiceIcon />
                            </TitleItem>
                            <TitleItem reversed={reversed}>
                                {Custom ? Products : calcProductsQty}
                                <PackageIcon />

                                {Custom ? PalletsQty : calcPalletsQty}
                                <PalletIcon />
                            </TitleItem>
                            <TitleItem reversed={reversed}>
                                {Width || widthCalc}
                                <ScalesIcon />
                            </TitleItem>
                        </TitleWrapper>
                        <NestedItemsContainer
                            isOpen={isOpen && isAllowForDrag}
                        >
                            <TitlesList
                                list={pallets}
                                uniqueKey={'Pallet_Num'}  
                                Element={PalletItem}  
                                additionalData={addictionalData} 
                            />
                        </NestedItemsContainer>

                    </MainWrapper>

            }
        </Draggable>
    );
}

export default InnerGroupContainer;