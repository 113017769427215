import React, {useState, useMemo} from 'react'
import { IAddProductItem } from '../../types/addProductItem'
import { ISupplyMainList } from '../../types/supplyMainList'
import styled from 'styled-components'
import SearchInput from '../../../../../../../generic/inputs/inputSearch'
import AddProductItem from '../AddProductItem'
import { ForcastProductItem } from '../../../../../../../modules/API/Forcasts/models/forcastProductitem'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import { IGrowerItem } from '../../../../../../../modules/API/Defaults/models/growers'

type Props = {
  list: IAddProductItem[],
  setList: React.Dispatch<React.SetStateAction<IAddProductItem[]>>
  selectedDay: ISupplyMainList
  handleSaveProduct: (data: IAddProductItem[]) => void
  growerList: IGrowerItem[],
  calculationMethod: number
}

// calculationMethod === 2 ? 
//           <TitleRowItem name={`${ActualPallets} / ${ForecastPallets}`} icon={<PalletIcon />} />
//           : calculationMethod === 3 ?
//           <TitleRowItem name={`${ActualWeight} / ${ForecastWieght}`} icon={<ScalesIcon />} />
//           : <TitleRowItem name={`${ActualAmnt} / ${ForecastAmnt}`} icon={<PackageIcon />} />
const ModalNewProducts = ({
  list, 
  selectedDay, 
  setList, 
  handleSaveProduct, 
  growerList,
  calculationMethod
}: Props) => {
  const [t] = useTranslation(['buttons'])
  // const [defaultList, setDefaultList] = useState<IAddProductItem[]>(list)
  const [searchProduct, setSearchProduct] = useState<string>('')
  console.log(growerList)
  const handleAddForecast = (item: IAddProductItem, forecastItem: ForcastProductItem) => {
    const newList = [...list].map((listItem) => {
      if(listItem.ProductID === item.ProductID && listItem.VarietyID === item.VarietyID) {
        return {
          ...item,
          forecastList: [...item.forecastList, forecastItem],
          orderedPackages: item.orderedPackages + forecastItem.ActualAmnt,
          orderedWeight: item.orderedWeight + forecastItem.ActualWeight,
          orderedPallets: item.orderedPallets + forecastItem.ActualPallets
        }
      }
      return listItem
    })
    setList([...newList])
  }
  const handleModifyList = (item: IAddProductItem, forecastItem: ForcastProductItem) => {
    const newList = [...list].map((listItem) => {
      if(listItem.ProductID === item.ProductID && listItem.VarietyID === item.VarietyID) {
        const amount = listItem.forecastList.reduce((acc, itemForecast) => {
          if(itemForecast.GrowerID === forecastItem.GrowerID ) {
            const neededQty = 
              calculationMethod === 2 ? forecastItem.ActualPallets : 
              calculationMethod === 3 ? forecastItem.ActualWeight : 
              forecastItem.ActualAmnt
            
            return acc + neededQty
            
          }
          const neededQty = 
            calculationMethod === 2 ? itemForecast.ActualPallets :
            calculationMethod === 3 ? itemForecast.ActualWeight :
            itemForecast.ActualAmnt

          return acc + neededQty
        }
        , 0) 
        return {
          ...item,
          orderedPackages: amount,
          forecastList: item.forecastList.map((forecastListItem) => {
            if(forecastListItem.ProductID === forecastItem.ProductID && forecastListItem.VarietyID === forecastItem.VarietyID && forecastListItem.GrowerID === forecastItem.GrowerID) {
              if(calculationMethod === 2) {
                return {
                  ...forecastItem,
                  ActualPallets: forecastItem.ActualPallets
                }
              } else if(calculationMethod === 3) {
                return {
                  ...forecastItem,
                  ActualWeight: forecastItem.ActualWeight
                }
              } else {
                return {
                  ...forecastItem,
                  ActualAmnt: forecastItem.ActualAmnt
              }

              }
            }
            return forecastListItem
          })

        }
      }
      return listItem
    })
    setList([...newList])
  }
  const renderList = useMemo(() => {
    if(!searchProduct) return list
    return list.filter(item => 
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`
        .toLowerCase()
          .includes(searchProduct.toLowerCase())
      )
  }, [searchProduct, list])
  return (
    <MainWrapper>
      <SearchInput 
        input={searchProduct}
        setInput={setSearchProduct}
        value={'חיפוש פריט'}
      />
      <BodyWrapper>
        {
          renderList.map((item) => 
            <AddProductItem 
              key={`${item.ProductID} ${item.VarietyID}`}
              {...item}
              item={item}
              handleChange={handleModifyList}
              growerList={growerList}
              handleAddForecast={handleAddForecast}
              calculationMethod={calculationMethod}
            />
          )
        }
      </BodyWrapper>
      <ControlButton 
        lable={t("save")}
        handleClick={() => handleSaveProduct(list)}
      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const BodyWrapper = styled.div`
  height: 70%;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default ModalNewProducts