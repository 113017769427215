import { requests } from "../config";
import { IResponseSrikaGo } from "./models/srika";

export const Gathering = {
    gatheringStats: (dateStart: string, dateEnd: string) => 
        requests.get(`monitoring/gathering_stats?DateFrom=${dateStart}&DateTo=${dateEnd}`, 'go'),
    getKnisaByBarcode: (barcode: string | number, orderNum?: number) => 
        requests.get(`likut/entry/${barcode}/${orderNum || 0}`, 'node'),
    getPermanentKnisaByBarcode: (barcode: string): Promise<IResponseSrikaGo> => 
        requests.get(`/barcode/${barcode}`, 'go'),
}