import { requests } from "../config";
import { IPatchItem, IPutItem } from "./models/patchItem";
import { IPostData } from "./models/postData";
import { ITeudotSmsData } from "./models/smsData";

export const Certificates = {
    getCertsByDate: (date: string) => requests.get(`delivery?${date}`, 'go'),
    getCertsByKosher: (date: string, id: number) => requests.get(`delivery?${date}&supervision=${id}`, 'go'),
    setStamp: (data: any) => requests.put(`delivery/bulk_mashgiah_update`, 'go', data),
    createCert: (data: IPostData) => requests.post(`delivery/16`, 'node', data),
    updateDeliveryByOrderOrPallet: (data: IPutItem) => requests.put(`delivery/16`, 'node', data),
    /*
        @param rangeQuery: string - query string with the following format:
        DateFrom=2&DateTo=3
    */
    getTeudotDetailsByDateRange: (rangeQuery: string) => requests.get(`pallet/details_list?${rangeQuery}`, 'go'),
    
    /*
        @param rangeQuery: string - query string with the following format:
        DateFrom=2&DateTo=3&SearchString=2
    */
    getTeudotDetailsSum: (rangeQuery: string) => requests.get(`pallet/details_sum?${rangeQuery}`, 'go'),
    patchTeudotDetailsItem: (id: number, data: IPatchItem) => requests.put(`pallet/detail/${id}`, 'go', data),
    sendCertBySms: (data: ITeudotSmsData) => requests.post(`delivery/sms`, 'go', data),
}