import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { IDate } from "./ReportPriceMain";
import ControlButton from "../../../../generic/buttons/ControlButton";
import ReactDatePicker from "../../../../generic/datePicker/ReactDatePicker";
import { initialStateBuilder } from "../../../../generic/utils/forDate/initialStateBuilder";
import { IDateRanger, TDateRangerList } from "../../../../generic/utils/forDate/stateModel";
import { format, parseISO } from "date-fns";
import { ICustomerGroup } from "../../../../modules/API/Defaults/models/customersGroup";
import MultySelect, { IMultySelectState } from "./MultySelect";

const MainWrapper = styled.div`
position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
  /* justify-content: space-between; */
`

const ControlPannel = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
`

const CustomersGroupOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: red;
  z-index: 1;
  transition: 0.5s;

`

type Props = {
  date: IDate;
  setDate: React.Dispatch<React.SetStateAction<TDateRangerList>>;
  closeFunc: () => void;
  getPriceSummary: (thenFunc: () => void, date: TDateRangerList) => void;
  customersGroup: IMultySelectState[];
  multySelection: IMultySelectState[];
  handleSelectCustomers: (item: IMultySelectState) => void;
  handleDeleteFunction: (item: IMultySelectState) => void;
}


function SelectDateModal({ 
  setDate, date, closeFunc, getPriceSummary,
  customersGroup, multySelection, handleSelectCustomers,
  handleDeleteFunction
}: Props) {
  const sessionDate = sessionStorage.getItem('DateRange')
  const parsed = useMemo(() => {
    const date: TDateRangerList = sessionDate && JSON.parse(sessionDate)
    if (!date) return null
    return Object.values(date).reduce((acc: TDateRangerList, cur, index: number): TDateRangerList => {
      acc = {
        ...acc,
        [`selection${index + 1}`]: {
          startDate: parseISO(cur.startDate),
          endDate: parseISO(cur.endDate),
          key: cur.key
        }
      }
      return acc
    }, {})
  }, []) 
  
  const initialDateState = parsed || initialStateBuilder()
  const [state, setState] = useState<TDateRangerList>(initialDateState)
  useEffect(() => {
    setDate(state)
  }, [state]);
  const handleDates = (item:TDateRangerList ) => {
    console.log(item)
    if(item['selection1']) {
      // const dayForFormat = +format(new Date(), 'd') - 1
      let newState: TDateRangerList = {}
      let startDate: Date = item['selection1'].startDate
      let endDate: Date = item['selection1'].endDate
      let delta = 0
      for(let i = 0; i < 3; i++) {
        newState[`selection${i + 1}`] = {
          startDate: startDate,
          endDate: endDate,
          key: `selection${i + 1}`
        }
        console.log(i)
        startDate = new Date(startDate.setMonth(startDate.getMonth() - delta))
        endDate = new Date(endDate.setMonth(endDate.getMonth() - delta))
        delta += 0.5
      }
      
      console.log(delta)
      console.log(newState)
      setState(newState)
    } else {
      setState({ ...state, ...item })

    }
    console.log()
  }
  return (
    <MainWrapper>
        {/* <CustomersGroupOverlay>

        </CustomersGroupOverlay> */}
        <ReactDatePicker 
          onChange={handleDates}
          range={Object.values(state)}
        />
        <MultySelect 
          state={multySelection}
          list={customersGroup}
          handleSelectFunction={handleSelectCustomers}
          handleDeleteFunction={handleDeleteFunction}
          lable="קבוצת לקוח"
        />
    </MainWrapper>
  );
}

export default SelectDateModal;
