import React from 'react'
import styled from 'styled-components'
import { PackageIcon, SpeedIcon } from '../../../../../../generic/icons/Icons'
import { IGrowerItem } from '../../../../../../modules/API/Drivers/models/kavData'

interface IGrowerItemMain extends IGrowerItem {
    selectDataForPackageOrder: (item: IGrowerItem) => void,
    item: IGrowerItem
}

const GroverMain = ({
    GrowerName, item, selectDataForPackageOrder, PO_Num
}: IGrowerItemMain) => {
    
    const handleSelectPackages = () => {
        selectDataForPackageOrder(item)
    }
  return (
    <MainWrapper>
        <ButtonsWrapper>
            <Button isEdit={PO_Num !== 0} onClick={handleSelectPackages}>
                <PackageIcon />
            </Button>
            <Button>
                <SpeedIcon color={''} />
            </Button>
        </ButtonsWrapper>
        <ContentWrapper>
            {GrowerName}

        </ContentWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    border-radius: 30px;
    background: #eff3f4;

`
const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    background: blueviolet;
`
const ButtonsWrapper = styled.div`
    min-width: 4em;
    /* background: red; */
    height: 100%;
    display: flex;
    align-items: center;
`
const Button = styled.div<{isEdit?: boolean}>`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        color: ${props => props.isEdit ? '#7ab0ab' : 'tomato'} ;
    }
    cursor: pointer;


`
export default GroverMain