import React from 'react'
import styled from 'styled-components'

type Props = {
    title: string | string[] | React.ReactNode | React.ReactNode[];
    children: React.ReactNode | React.ReactNode[];
}

const SearchBlockWithTitle = ({
    title, children
}: Props) => {
    return (
        <SelectorWrapper >
            <Title>
                {
                    Array.isArray(title) ? title.map((item, index) => (
                        <p key={index}>{item}</p>
                    )) : <p>{title}</p>
                }
                
            </Title>
            <SelectItemWrapper>
                {children}
            </SelectItemWrapper>
        </SelectorWrapper>
    )
}
const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  background: rgb(233,240,240, 0.2)
`
const SelectItemWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
`

const Title = styled.div`
  text-align: end;
  color: #297F76;
  font-weight: 600;
  padding: 0 0.5em;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
 
`
export default SearchBlockWithTitle