import { AnyAction } from "redux"
import { CLEAR_KABALOT_STATE, CLEAR_REHESH_STATE, SELECT_DATA_FOR_KABALOT, SELECT_DATA_FOR_REHESH } from "../actions/actionsTypes"

export interface IKabalotState{
    date: string | null
}

const initialState: IKabalotState = {
    date: null
}

const kabalot = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_DATA_FOR_KABALOT:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_KABALOT_STATE:
            return {
                ...initialState
            }
        default: return state
    }
}

export default kabalot