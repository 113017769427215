import React, { useState } from 'react'
import styled from 'styled-components'
import { IRemarkData } from './ArizotViewMain'
import Image from '../../../../../generic/assets/collect.png'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'

type Props = {
    data: IRemarkData,
    close: () => void,
    isActive?: boolean,
    handleSave?: (input: string) => void
}

const RemarkModal = ({data, close, isActive, handleSave}: Props) => {
    const [t] = useTranslation(['packageOrder', 'buttons'])
    const [input, setInput] = useState<string>(data?.body?.trim() || '')
    const remarkHandle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput(e.target.value)
    }
    const handleSaveRemark = () => {
        if(!handleSave) return
        handleSave(input)
    }
  return (
    <MainWrapper>
        {/* {data.body} */}
        <Img
            src={Image}
            alt='order gathering'
        />
        <TextField 
            cols={30}
            rows={11}
            readOnly={!isActive}
            defaultValue={input}
            onChange={remarkHandle}
        />
        {
            isActive &&
                <ControlButton 
                    lable={t('buttons:save')}
                    handleClick={handleSaveRemark}
                />
        }   
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

`
const Img = styled.img`
    min-width: 300px;
`

const TextField = styled.textarea`
    padding: 1em 0.5em;
    letter-spacing: 0.5px;
    border-radius: 30px;
    font-size: 1.2em;
    direction: rtl;
    border: 2px solid #7ab0ab;
    outline: none;
    color: #757575;
    resize: none;
    ::-webkit-scrollbar {
        width: 0
    }
    :disabled {
        background: #ffff;
    }
`
export default RemarkModal