import React from 'react'
import { IDataQ4, IMetaDataQ4 } from '../../../../../../modules/API/Reports/models/q4';
import styled from 'styled-components';

interface Props {
    metaData: IMetaDataQ4;
    data: IDataQ4[];
}

const Q4Table = ({
    metaData,
    data
}: Props) => {
    const metaArr = Object.entries(metaData)
    console.log(metaArr)
    const calcPrice = (priceSum: number, weight: number) => {
        if(!priceSum || !weight) return ""
        return (priceSum / weight).toFixed(2)
    }
    const formatNumber = (num: number) => {
        let temp = num.toString().split(".")
        let [int, dec] = temp
        if(!int) return 0
        if(!dec) return `${Number(int).toLocaleString()}.00`
        return `${Number(int).toLocaleString()}.${(dec[0] || 0)}${(dec[1] || 0)}`
    }
    
    return (
        <TableWrapper>

            <Table>
                <TableHead>
                    <TableRow>
                        {metaArr?.map((field, index) =>
                            field[1].trim() ?
                                (
                                    <TableHeadCell key={index}>{field[1]}</TableHeadCell>
                                ) : null)}
                        <TableHeadCell>
                            אריזות
                        </TableHeadCell>
                        <TableHeadCell>
                            משטחים
                        </TableHeadCell>
                        <TableHeadCell>
                            משקל
                        </TableHeadCell>
                        <TableHeadCell>
                            מחיר
                        </TableHeadCell>
                        <TableHeadCell>
                            פדיון
                        </TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((rowData, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {
                                Object.entries(rowData).map((cellData, cellIndex) => {
                                    let [key, value] = cellData
                                   
                                    const allow = () => {
                                        let item: string = key.split("_")[0]
                                        if(key.includes("Desc")){
                                            if (!item) return false
                                            let filter = metaArr.filter((pair) => (pair[1] && pair[0] === item))
                                            if (filter && filter.length > 0 && value) return true
                                            // return filter && filter.length > 0
                                            if(filter && filter.length > 0 && !value) {
                                                let find = Object.entries(rowData).filter(itema => itema[0] === item)
                                                if (find.length > 0) {
                                                    value = find[0][1]
                                                    return true
                                                }
                                                return false
                                            }
                                        }                                        
                                    }
                                    if (!allow()) return null
                                    return (
                                        <TableBodyCell key={cellIndex}>{value}</TableBodyCell>
                                    )
                                }
                                )
                            }
                            <TableBodyCell>{rowData.AmountPack}</TableBodyCell>
                            <TableBodyCell>{rowData.AmountPallets}</TableBodyCell>
                            <TableBodyCell>{rowData.Weight.toLocaleString()}</TableBodyCell>
                            <TableBodyCell>{calcPrice(rowData.Price, rowData.Weight)}</TableBodyCell>
                            <TableBodyCell>{formatNumber(rowData.Price)}</TableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableWrapper>
    )
}
// const allow = () => {
//     if (typeof value !== 'string' || !key.includes("Desc")) return false
//     let item: string = key.split("_")[0]
//     if (!item) return false
//     let filter = metaArr.filter((pair) => (pair[1] && pair[0] === item))
//     return filter && filter.length > 0
// }
const TableWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0;
    }
`

const Table = styled.table`
    position: relative;
    direction: rtl;
    width: 100%;
    background: #E9F0F0;
    padding: 0.5em;
    border-radius: 30px;
`
const TableHead = styled.thead`
    width: 100%;
    height: 1em;
    position: sticky;
    top: 0;
`
const TableRow = styled.tr`
    width: 100%;
    height: 1em;
    :last-child {
        td {
            border-radius: 0 0 15px 15px;
        }
    }
`
const TableHeadCell = styled.th`
    min-width: 60px;
    height: 1em;
    border-radius: 15px 15px 0 0;
    padding: 0.2em;
    background: #81B4AF;
    color: #fff;
`

const TableBody = styled.tbody`

`
const TableBodyCell = styled.td`
    max-width: max-content;
    text-align: right;
    overflow: hidden;
    padding: 0.2em ;
    background: #fff;
    color: rgba(0,0,0,0.7);
`
export default Q4Table