import { AnyAction } from "redux"
import { CHASH_WORKERS_LISTS, CLEAR_WORKER_ORDERS_STATE } from "../actions/actionsTypes"
import { IWorkerItem } from "../../components/modules/API/Defaults/models/workers"

export interface IWorkerOrders {
  selectedWorkers: IWorkerItem[],
  restWorkers: IWorkerItem[]

}
const initialState: IWorkerOrders = {
  selectedWorkers: [],
  restWorkers: [],
}

const workerOrders = (state = initialState, action: AnyAction) => {
  switch(action.type) {
  
      case CHASH_WORKERS_LISTS:
          return {
            selectedWorkers: action.payload[0],
            restWorkers: action.payload[1]
          }
     
      case CLEAR_WORKER_ORDERS_STATE:
          return initialState
      default: return state
  }
}

export default workerOrders