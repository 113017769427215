import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import history from "../../../../../../history";
import { IS_NEW_PALLET, SELECT_PALLET } from "../../../../../../store/actions/actionsTypes";
import { AcceptIcon, InvoiceIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from "../../../../../generic/icons/Icons";
import { LINKS } from "../../../../../Routs/config";
import { AzmanotItemWrapper, BodyContainer, StatusWrapper, TitelContent, TitleWrapper, Item } from "../azmanotSection/likutExpressAzmanotItem";
import { SET_IS_OPEN } from "../../../../../../store/reducers/printer";

export const ItemWrapper = styled.div`
    width: 100%;
    margin-bottom: 0.5em;
    background: #eff3f4;
    border-radius: 30px;
    overflow: hidden;
    min-height: 5em;
    
`
export const InnerContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    
`
const IconWrapper = styled.div`
    position: absolute;
    left: 1em;
    bottom: 2.5em;
    height: 3em;
    width: 3em;
    border-radius: 30px;
    background: #fff;
    color: #7ab0ab;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));

    filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));
`

export default function LikutExpressPalletItem({
    Pallet_Num,
    printPallet,
    CheckTime, CheckedBy,
    index, Quantity, FillPercent, WeightNeto, setNumMishtah,
    CheckDate, likutInfo
}) {
    const dispatch = useDispatch();
    const handlePrint = (e) => {
        e.stopPropagation();
        printPallet(Pallet_Num);
        
    }
   
    return (
        <AzmanotItemWrapper
            onClick={(e) => {
                if (CheckTime === '000000' || likutInfo.azmanaStatus === 7) {
                    setNumMishtah(index + 1)
                    history.push(LINKS.likut.likutExperess.likutWorkSection)
                    e.preventDefault();
                    // if(!flag) {
                    //     dispatch({ type: IS_NEW_PALLET, payload: false });
                    // }
                    dispatch({ type: IS_NEW_PALLET, payload: likutInfo.azmanaStatus === 7 ? false : true });
                    dispatch({
                        type: SELECT_PALLET, payload: {
                            palletNum: Pallet_Num,
                            palletDesc: +`${Pallet_Num}`
                        }
                    })
                }
            }}
        >
            <TitleWrapper>
                <TitelContent>
                    {Pallet_Num}
                </TitelContent>
            </TitleWrapper>
            <StatusWrapper style={{ color: 'black' }}>
                {
                    CheckedBy?.trim() ? <p>{CheckedBy?.trim()}</p> : ''
                }
            </StatusWrapper>
            <IconWrapper onClick={handlePrint}>
                <InvoiceIcon />
            </IconWrapper>
            <BodyContainer>
                <Item>
                    {index + 1}
                    <p style={{ fontSize: '1.1em', paddingLeft: '0.5em' }}>:משטח</p>
                    <PalletIcon />
                </Item>
            </BodyContainer>
            <BodyContainer>
                <Item>
                    {Math.floor(FillPercent)}%
                    <AcceptIcon />
                </Item>
                <Item>
                    {WeightNeto?.toFixed(2)}
                    <ScalesIcon />
                </Item>
                <Item>
                    {Quantity}
                    <PackageIcon />
                </Item>
            </BodyContainer>

        </AzmanotItemWrapper>
    )
}