export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOAD_WORKER_TYPE = 'LOAD_WORKER_TYPE';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOAD_USER = 'LOAD_USER';
export const LOAD = 'LOAD';
export const UPDATE_AZMANOT = 'UPDATE_AZMANOT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOAD_END = 'LOAD_END'
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOAD_MODULES = 'LOAD_MODULES'
export const RESET_STATE = 'RESET_STATE'


export const LOAD_DEFAULTS = 'LOAD_DEFAULTS'
export const LOAD_PACKAGELIST = 'LOAD_PACKAGELIST'
export const LOAD_PRODUCT_GROUPS = 'LOAD_PRODUCT_GROUPS'
export const LOAD_WORKERS = 'LOAD_WORKERS'
export const TABLE_OF_TABLES = 'TABLE_OF_TABLES'
export const LOAD_CLIENTS = 'LOAD_CLIENTS'
export const LOAD_TRANSPORT_AREAS = 'LOAD_TRANSPORT_AREAS'

//likut
export const SELECT_AZMANA = 'SELECT_AZMANA'
export const SAVE_CLIENTNAME = 'SAVE_CLIENTNAME'
export const SELECT_PALLET = 'SELECT_PALLET'
export const LIKUT_WITHOUT_AZMANA = 'LIKUT_WITHOUT_AZMANA'
export const LIKUT_WITHOUT_AZMANA_CLIENT = 'LIKUT_WITHOUT_AZMANA_CLIENT'
export const PATCH_PALLET_NUM = 'PATCH_PALLET_NUM'
export const IS_NEW_PALLET = 'IS_NEW_PALLET'
export const CHECK_ATTEMPTS = 'CHECK_ATTEMPTS'
export const SELECT_CURRENT_ORDER = 'SELECT_CURRENT_ORDER'


// control Pallet
export const SELECT_CLIENT = 'SELECT_CLIENT'
export const CONTROL_PALLETS_SELECT_PALLET = 'CONTROL_PALLETS_SELECT_PALLET'
export const CONTROL_PALLETS_EDIT_GOOD = 'CONTROL_PALLETS_EDIT_GOOD'
export const CONTROL_PALLETS_CLEAR_STATE = 'CONTROL_PALLETS_CLEAR_STATE'
export const CONTROL_PALLET_NEW_CLEAR_ITEM = 'CONTROL_PALLET_NEW_CLEAR_ITEM'
export const CONTROL_PALLET_LOAD_PREVIOUS_STATE_FOR_GOOD = 'CONTROL_PALLET_LOAD_PREVIOUS_STATE_FOR_GOOD'
export const CONTROL_PALLET_LOAD_NEW_CLEAR_ITEM = 'CONTROL_PALLET_LOAD_NEW_CLEAR_ITEM'

export const SELECT_MARKETING_AREA = 'SELECT_MARKETING_AREA'


// KOSHER TEUDOT
export const SELECT_DRIVER_KOSHER = 'SELECT_DRIVER_KOSHER'
export const SELECT_CLIENT_COSHER = 'SELECT_CLIENT_COSHER'
export const CLEAR_STATE_KOSHER = `CLEAR_STATE_KOSHER`
export const SELECT_TEUDA_TITLE = `SELECT_TEUDA_TITLE`
export const SELECT_TEUDA_DATE = `SELECT_TEUDA_DATE`

//DRIVER
export const SELECT_DATE_DRIVERS = 'SELECT_DATE_DRIVERS'
export const CLEAR_DRIVER_STATE = 'CLEAR_DRIVER_STATE'
export const SELECT_DATA_FOR_ORDER_PACKAGE= 'SELECT_DATA_FOR_ORDER_PACKAGE'

//MIUN
export const SELECT_SORTING_DATA = 'SELECT_SORTING_DATA'
export const UPDATE_ENTRIES = 'UPDATE_ENTRIES'
export const SELECT_SORTING_DATE = 'SELECT_SORTING_DATE'

// Entry

export const SELECT_DATA_FOR_ENTRY = 'SELECT_DATA_FOR_ENTRY'
export const CLEAR_ENTRY_STATE = 'CLEAR_ENTRY_STATE'
export const CREATE_ENTRIE_TITLE = 'CREATE_ENTRIE_TITLE'
export const CREATE_ENTRIE_DETAILS = 'CREATE_ENTRIE_DETAILS'

// Rehesh
export const SELECT_DATA_FOR_REHESH = 'SELECT_DATA_FOR_REHESH'
export const CLEAR_REHESH_STATE = 'CLEAR_REHESH_STATE'

// KABALOT
export const SELECT_DATA_FOR_KABALOT = 'SELECT_DATA_FOR_KABALOT'
export const CLEAR_KABALOT_STATE = 'CLEAR_KABALOT_STATE'
// Receipts
export const SELECT_DATA_FOR_CREATE_RECEIPT = 'SELECT_DATA_FOR_CREATE_RECEIPT'
export const CLEAR_RECEIPTS_STATE = 'CLEAR_RECEIPTS_STATE'

// Cross Modueles Queries
export const SELECT_DATA_FOR_CROSS_MODULE = 'SELECT_DATA_FOR_CROSS_MODULE'
export const CLEAR_CROSS_MODULE_STATE = 'CLEAR_CROSS_MODULE_STATE'
export const SET_XTOTAL_TEUDOT_DETAILS = 'SET_XTOTAL_TEUDOT_DETAILS'


// Client Bills
export const SELECT_DATA_CLIENT_BILLS = 'SELECT_DATA_CLIENT_BILLS'
export const CLEAR_CLIENT_BILLS = 'CLEAR_CLIENT_BILLS'

// Procedures
export const SELECT_REPORT_PROCEDURES_Q4 = 'SELECT_REPORT_PROCEDURES_Q4'
export const SELECT_DATE_PROCEDURES_Q4 = 'SELECT_DATE_PROCEDURES_Q4'
export const SELECT_REPORT_PROCEDURES_Q2 = 'SELECT_REPORT_PROCEDURES_Q2'
export const SELECT_DATE_PROCEDURES_Q2 = 'SELECT_DATE_PROCEDURES_Q2'
export const SELECT_CUSTOMER_PROCEDURES_Q8 = 'SELECT_CUSTOMER_PROCEDURES_Q8'
export const SELECT_DATE_PROCEDURES_Q8 = 'SELECT_DATE_PROCEDURES_Q8'

// Market
export const SELECT_DATA_PRODUCT = 'SELECT_DATA_PRODUCT'
export const SELECT_DATE_FOR_MARKET = 'SELECT_DATE_FOR_MARKET'
export const CLEAR_MARKET_STATE = 'CLEAR_MARKET_STATE'


//Cefi
export const SELECT_DATA_FOR_CEFI = 'SELECT_DATA_FOR_CEFI'
export const CLEAR_CEFI_STATE = 'CLEAR_CEFI_STATE'
export const SELECT_DATE_FOR_CEFI = 'SELECT_DATE_FOR_CEFI'
export const SELECT_GROWER_FOR_CEFI = 'SELECT_GROWER_FOR_CEFI'


//PAckages

export const SELECT_DATE_FOR_PACKAGES = 'SELECT_DATE_FOR_PACKAGES'
export const CLEAR_PACKAGES_STATE = 'CLEAR_PACKAGES_STATE'


// Worker Orders

export const CHASH_WORKERS_LISTS = 'CHASH_WORKERS_LISTS'
export const CLEAR_WORKER_ORDERS_STATE = 'CLEAR_WORKER_ORDERS_STATE'