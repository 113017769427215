import React, {useState, useMemo, useEffect} from 'react'
import { IClientsItem } from '../../../../../../../modules/API/Defaults/models/customers'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../../../generic/assets/cogsPNG.png'
import DatePickerModule from '../../../../../../../generic/datePicker/datePicker'
import SearchBlockWithTitle from '../../Layouts/SearchBlockWithTitle'
import SearchInput from '../../../../../../../generic/inputs/inputSearch'
import { useDispatch, useSelector } from 'react-redux'
import { SELECT_CUSTOMER_PROCEDURES_Q8, SELECT_DATE_PROCEDURES_Q8 } from '../../../../../../../../store/actions/actionsTypes'
import { RootState } from '../../../../../../../../store/reducers/rootReducer'
import SearchFromTheList from '../../../../../../../generic/customSearchComponents/SearchFromTheList'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { IClientItem } from '../../../../../../../modules/API/Defaults/models/clients'
type Props = {
  customerList: IClientItem[],
  acceptFunc: (customerID: number, dateFrom: string, dateTo: string) => void
}

const SelectDateAndClient = ({
  customerList,
  acceptFunc
}: Props) => {
  const dispatch = useDispatch()
  const defaultData = useSelector((state: RootState) => state.procedures?.Q8)
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const isGrower = user?.rights?.includes('GROWER')
  const defaultClient = isGrower ? customerList[0] : defaultData?.selectedCustomer
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
console.log(customerList)
  const [searchClient, setSearchClient] = useState<string>(defaultClient?.ClientName?.trim() || "");
  const [selectedClient, setSelectedClient] = useState<IClientItem | null>(defaultClient)
  const [isOpenListClient, setIsOpenListClient] = useState<boolean>(false)
  console.log(selectedClient)
  const handleDate = (e: object | object[]) => {
    console.log(e)
        dispatch({type: SELECT_DATE_PROCEDURES_Q8, payloadDate: e}
    )
  } 
  const handleDispatchReport = (item: IClientItem) => {
    dispatch({type: SELECT_CUSTOMER_PROCEDURES_Q8, payloadCustomer: item})

  }
  const selectedDate =  Array.isArray(defaultData?.date) ? defaultData?.date : new Date(defaultData?.date)

  const handleSelectClient = (item: IClientItem) => {
    setSelectedClient(item)
    setSearchClient(item?.ClientName?.trim())
    handleDispatchReport(item)
    setIsOpenListClient(false)
  }
  const handleSearchClient = (e: string) => {
    setSearchClient(e)
    setIsOpenListClient(true)
    if(!e) {
      setSelectedClient(null)
    }
  }

  const handleSave = () => {
    if(!selectedClient) return
    acceptFunc(selectedClient.ClientID, startDate, endDate)
  }
  const renderList = useMemo(() => {
    if(!customerList) return []
    return customerList.filter((item: IClientItem) => {
      return item.ClientName?.trim()?.toLowerCase()?.includes(searchClient.toLowerCase()?.trim())
    })
  }, [customerList, searchClient])
  useEffect(() => {
    console.log(defaultClient)
    setSelectedClient(defaultClient)
    setSearchClient(defaultClient?.ClientName?.trim() || '')
  }, [defaultClient, defaultData])
  return (
    <MainWrapper>
      <ImageComponentHead 
        img={Image}
        // large
        
      />
      <SelectWrapper>
        <SearchBlockWithTitle
          title='בחר תאריכים'
        >
          <DatePickerModule 
            multyDate
            setSelectedDateStart={setStartDate}
            setSelectedDateEnd={setEndDate}
            ignorBrod
            disabled={false}
            selected={defaultData?.date ? selectedDate : false}
            defEndDate={false}
            cashFunc={handleDate}
            large
          />
         
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title='בחר לקוח'
        >
          
          <SearchFromTheList 
            isNeedFullObject
            list={renderList}
            searchFunc={handleSearchClient}
            selectFunc={handleSelectClient}
            placeHolder={'חיפוש לקוח'}
            input={searchClient}
            itemDisplayName='ClientName'
            nameIdElement='ClientID'
            size={'full'}
            isOpenList={isOpenListClient}
            disabled={isGrower}
          />
        </SearchBlockWithTitle>
      </SelectWrapper>
      {
        selectedClient &&
        <ControlButton 
          handleClick={handleSave}
          lable={'הצג'}
        />
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 0.5em; */

`
const SelectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
  padding: 0.5em; 
  overflow-y: auto;
  ::-webkit-scrollbar{
    width: 0;
  }
`

export default SelectDateAndClient