import { AnyAction } from "redux"
import { CLEAR_REHESH_STATE, SELECT_DATA_FOR_REHESH } from "../actions/actionsTypes"

export interface IReheshStae{
    date: string | null
}

const initialState: IReheshStae = {
    date: null
}

const orderRehesh = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_DATA_FOR_REHESH:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_REHESH_STATE:
            return {
                ...initialState
            }
        default: return state
    }
}

export default orderRehesh