import React, {useState, useMemo} from 'react'
import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../generic/inputs/inputSearch'
import DatePickerModule from '../../../../generic/datePicker/datePicker'
import useDataFetcher from '../knisot/hooks/useFetchData'
import { Knisot } from '../../../../modules/API/Knisot/knisot'
import BodyScrollContainer from '../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import ExternalListItem from './Components/ExternalListItem'
import TitlesList from '../../../../generic/titlesRowItem/TitlesList'
import { IExternalPallet } from '../../../../modules/API/Knisot/models/externalPallet'
import ColumModal from '../../../../generic/Layouts/ColumModal'
import ModalExternalPaletItem from './Modals/ModalExternalPaletItem'
import { Defaults } from '../../../../modules/API/Defaults/defaults'
import { IGrowerNodeGetResponse } from '../../../../modules/API/Defaults/models/growers'
import { IProductListNodeResponse } from '../../../../modules/API/Defaults/models/productListNode'
import { IPalletTypeItem } from '../../../../modules/API/Defaults/models/pallets'
import { IPackagesItemResponse } from '../../../../modules/API/Defaults/models/packages'
import { useKnisaConfig } from '../knisot/Create/config'
import { IPostExternalEntryDetail } from '../../../../modules/API/Knisot/models/postExternalEntryDetail'
import { useDispatch } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../store/actions/actionsTypes'
import RemarkModal from '../arizot/View/RemarkModal'
import { IRemarkData } from '../arizot/View/ArizotViewMain'
import { IWarhouseListItem } from '../../../../modules/API/Defaults/models/warhouseList'
import DropdownByIcon from '../azmanotRehesh/Layouts/DropdownByIcon'
import { SettingMiksher } from '../../../../generic/icons/Icons'
import Filter from './Components/Filter'



const KnisotExternalMain = () => {
  const dispatch = useDispatch()
  const config = useKnisaConfig()
  // const defaultTranzit = config.create.title.tranzit[1].value
  const [date, setDate] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<IExternalPallet | null>(null)
  const [isOpenedExternalPalletModal, setIsOpenedExternalPalletModal] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [remarkData, setRemarkData] = useState<IRemarkData | null>(null)
  const [isOpenRemarkModal, setIsOpenRemarkModal] = useState<boolean>(false)
  const [knisotFilter, setKnisotFilter] = useState<number>(2)
  const {
    data: externalPallets,
    setData: setExternalPallets
  } = useDataFetcher(
    Knisot.getExternalPallets, `EntryDate=${date}`
  )
  const {
    data: growerList
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, 'data'
  )

  const {
    data: productList
  } = useDataFetcher<IProductListNodeResponse>(
    Defaults.getProductListNode, 'data'
  )

  const {
    data: palletTypeList
  } = useDataFetcher<{pallets: IPalletTypeItem[]}>(
    Defaults.getPalletTypes, 'data'
  )

  const {
    data: packageList
  } = useDataFetcher<IPackagesItemResponse>(
    Defaults.getPackages, 'data'
  )

  const {
    data: tranzitList
  } = useDataFetcher<IWarhouseListItem[]>(
    Defaults.getWarhouseList, 'data'
  )
  console.log(packageList)

  const scanPallet = async (externalPallet: string) => {
    console.log(123)
    const entryAsNumber = parseInt(externalPallet)
    if(isNaN(entryAsNumber)) return

    const findedItem = externalPallets?.filter((item) => item.ExternalPallet === entryAsNumber)
    // if item found and it's only one
    if(findedItem && findedItem?.length > 0 && findedItem?.length === 1) {
      setSelectedItem(findedItem[0])
      setIsOpenedExternalPalletModal(true)
      return
    } else if (findedItem && findedItem?.length > 1) {
      // if item found and it's more than one
      // show filtered list of items
      return
    }
    try {
      const res = await Knisot.getExternalPallets(`ExternalPallet=${entryAsNumber}`)
      const dataPallet = externalPallets || []
      if(res.length === 1) {
        
        console.log(dataPallet)
        setExternalPallets([
          res[0],
          ...dataPallet,
        ])
        setSelectedItem(res[0])
        setIsOpenedExternalPalletModal(true)
      } else if (
        res.length > 1
      ) {
        setExternalPallets([
          ...res,
          ...dataPallet
        ])
      }
  
    } catch (err) {

    } finally {

    }
  }
  
  const createLocalEntryDetail = async (data: IPostExternalEntryDetail) => {
    dispatch({type: LOAD})
    const postData = data
    try {
      const res = await Knisot.postExternalEntryDetail(postData)
      const newArr = (externalPallets || []).map((item) => {
        if(item.ExternalPallet === res.ExternalPallet && item.EntryNum === res.EntryNum) {
          return res
        }
        return item
      
      })
      setExternalPallets(newArr)
      handleControlExternalPalletModal()
    } catch (err) {
      
    } finally {
      dispatch({type: LOAD_END})

    }
  }

  const handleSelectItem = (item: IExternalPallet) => {
    setSelectedItem(item)
    setIsOpenedExternalPalletModal(true)
  } 

  const handleControlExternalPalletModal = () => {
    setIsOpenedExternalPalletModal(!isOpenedExternalPalletModal)
  }
  const handleSelectItemForRemark = (item: IExternalPallet) => {
    // title: string,
    // body: string,
    // id: string | number
    const data = {
      title: 'הערה',
      body: item.DetailRemark.trim() || '',
      id: item.ExternalPallet
    }
    setRemarkData(data)
    handleControlRemarkModal()
  }
  const handleControlRemarkModal = () => {
    setIsOpenRemarkModal(!isOpenRemarkModal)
  }
  const eternalPalletModalProps = {
    item: selectedItem,
    growerList: growerList?.growers || [],
    productList: productList?.products || [],
    palletTypeList: palletTypeList?.pallets || [],
    packageList: packageList?.packages || [],
    createLocalEntryDetail: createLocalEntryDetail,
    tranzitList: tranzitList || []
  }

  const render = useMemo(() => {
    if(!externalPallets) return []
    return externalPallets.filter((item) => 
      (
        item.ExternalPallet.toString().includes(search) ||
        item.GrowerDesc.trim().includes(search) ||
        item.GrowerNameLocal.trim().includes(search) ||
        item.Reference.toString().includes(search)
      ) && 
      (knisotFilter === 0 ? true : knisotFilter === 1 ? item.DetailStatus !== 0 : item.DetailStatus === 0)
  
    )
  }, [externalPallets, search, knisotFilter])

  return (
    <OrderlikeLayout
      label='כניסות חיצוניות'
    >
      {
        isOpenRemarkModal &&
        <ColumModal 
          title='הערה'
          childrenProps={{
            data: remarkData
          }}
          controlFunc={handleControlRemarkModal}
          Children={RemarkModal}
        />
      }
      {
        isOpenedExternalPalletModal && 
          <ColumModal 
            title={`${selectedItem?.ExternalPallet.toString() || ''} :משטח`}
            childrenProps={eternalPalletModalProps}
            controlFunc={handleControlExternalPalletModal}
            Children={ModalExternalPaletItem}
          />
      }
      <ControlSearchPannelLayout>
        <DatePickerModule 
          selected={false}
          setSelectedDateStart={setDate}
          multyDate={false}
          defEndDate={false}
          ignorBrod={false}
          saveWithoutFormat={false}
          large
          disabled={false}
        />
        <SearchInput 
          input={search}
          setInput={setSearch}
          enterFunc={scanPallet}
        />
        <DropdownByIcon
          icon={<SettingMiksher />}
          isSelected={knisotFilter !== 0}
          isOreintaionRight
        >
          <Filter 
            defaultID={knisotFilter}
            setSelectID={setKnisotFilter}
          />
        </DropdownByIcon>
      </ControlSearchPannelLayout>
      
      <BodyScrollContainer>
        <TitlesList 
          list={render}
          uniqueKey={'ExternalPallet'}
          additionalData={{
          
            handleSelectItem: handleSelectItem,
    handleRemark: handleSelectItemForRemark

          }}
          Element={ExternalListItem}
          noPadding={false}
        />
      </BodyScrollContainer>
    </OrderlikeLayout>
  )
}

export default KnisotExternalMain