import { useContext } from 'react'
import styled from 'styled-components'
import { ContextModal } from './modalContext'

const Div = styled.div`
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 60px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2em;
    z-index: 999;
    cursor: pointer;
    padding: 0.2em 0.5em;
    transition: 0.4s ease;   
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: blue;
    border-radius: 5px;
    transition: 0.2s ease;
`

export default function ModalTest() {
    const [modal, setModal] = useContext(ContextModal)
    const closeModal = () => {
        setModal({
            ...modal,
            open: false
        })
    }
    let backgroundModal = modal.mood ? '#80e081' : 'tomato'
    let displaModal = modal.open ? 'flex' : 'none'
    let dispPosition = modal.open ? 'translateY(0)' : 'translateY(100%)'
    return (
        <Div onClick={() => { closeModal() }} style={{ transform: dispPosition }} id='testModal'>
            <Container style={{ display: displaModal, background: backgroundModal }}>
                {modal.value}
            </Container>
        </Div>
    )
}