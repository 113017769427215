import { useSelector } from "react-redux";
import styled from "styled-components";

const Option = styled.option`
    
`

export default function InternalCompaniesItem({label, id, value}){

const getInternal = useSelector(internal => internal.mainReducer.user.company_internal)
let internalToggle = getInternal === id ? 'selected' : ''
    return(
        <Option
            value={id} 
            selected={internalToggle}
        >
            {label}
        </Option>
    )
}