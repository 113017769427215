import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const BodyHorizontalScrollLayout = ({
  children
}: Props) => {
  return (
    <BodyProductWrapper>
        {children}
    </BodyProductWrapper>
  )
}
const BodyProductWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px 10px 10px 30px;
  background: #fff;
  overflow: auto;
  display: flex;
  direction: rtl;
  gap: 0.5em;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default BodyHorizontalScrollLayout