import React, {useMemo, useState} from 'react'
import useDataFetcher from '../../../knisot/hooks/useFetchData';
import { Market } from '../../../../../../modules/API/Market/market';
import styled from 'styled-components';
import { CloseIcon, PackageIcon, PlusIcon, UserIcon } from '../../../../../../generic/icons/Icons';
import SearchInput from '../../../../../../generic/inputs/inputSearch';
import { IGrowerItem } from '../../../../../../modules/API/Defaults/models/growers';
import SearchGrower from '../../../knisot/Create/Components/SearchGrower';
import { useTranslation } from 'react-i18next';
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn';
import RowInput from '../../../../../../generic/inputs/RowInput';

type Props = {
  ProductID: number;
  setGrowerID: React.Dispatch<React.SetStateAction<number>>;
  growerID: number;
  growerList: IGrowerItem[]
  qty: number;
  setQty: React.Dispatch<React.SetStateAction<number>>;
  handleClose: () => void;
  handleSave: () => void;
  isOpenAddGrowerMenu: boolean;
}

const AddGrower = ({
  ProductID,
  setGrowerID,
  growerID,
  growerList,
  qty,
  handleClose,
  setQty,
  handleSave,
  isOpenAddGrowerMenu
}: Props) => {
  const [t] = useTranslation(['buttons'])
  const [isDefaultGrowerList, setIsDefaultGrowerList] = useState<boolean>(false)
  
  const {
    data: growerListByProductID,
    isLoading,
  } = useDataFetcher<IGrowerItem[]>(
    Market.getGrowerListByProductID, `${ProductID}`
  )
  
  const handleSwitchList = () => {
    setIsDefaultGrowerList(!isDefaultGrowerList)
  }

  const render = useMemo(() => {
    return isDefaultGrowerList ? growerList : growerListByProductID || []
  }, [growerListByProductID, growerList, isDefaultGrowerList])
  return (
    isOpenAddGrowerMenu ?
    <GrowerMenuWrapper>
      <CloseButton onClick={handleClose}>
        <CloseIcon handler={() => { }} />
      </CloseButton>

      <InputsWrapper>
        {/* <SearchInput
          input={qty.toString()}
          setInput={setQty}
          type={'number'}
          size={'small'}
        /> */}
        
        <SearchGrower
          required
          list={render}
          defaultID={growerID}
          setGrowerID={setGrowerID}
          disabled={isLoading}
        />
        <CircleActiveFilterBtn 
          withAction
          func={handleSwitchList}
        >
          <UserIcon />
        </CircleActiveFilterBtn>
      </InputsWrapper>
      <InputsWrapper>
        <RowInput 
          isDisabled={isLoading}
          defValue={qty}
          value={qty}
          handleInput={(e) => setQty(+e.target.value)}
          icon={<></>}
        />
      </InputsWrapper>
      <AddButton
        onClick={handleSave}
      >
        {t('buttons:save')}
      </AddButton>

    </GrowerMenuWrapper>
    :
    <GrowerMenuWrapper>

      <AddButton
        onClick={handleClose}
      >
      <PlusIcon /> ספק
      </AddButton>
    </GrowerMenuWrapper>
  )
}
const AddButton = styled.button`
  width: 100%;
  padding: 0.5em;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #8CC53D;
  border-radius: 20px;
  color: #fff;
  opacity: 0.7;
  transition: all 0.5s ease;
  font-size: 1.05em;
  &:hover {
    opacity: 1;
  }
`

const GrowerMenuWrapper = styled.div`
position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 20px;
  background: #fff;
`
const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  padding: 0.5em;
  justify-content: center;
  align-items: center;

`
const CloseButton = styled.div`
  position: absolute;
  top: -0.3em;
  left: -0.3em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f9f9f9;
  color: #8A8AAD;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
`
export default AddGrower