import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../../../../../generic/hooks/useDetectOutsideClick'
import Message from './Message'

const MainWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 30em;
    background: rgba(239,243,244, 0.7);
    height: 100%;
    z-index: 2;
    display: flex;
    padding: 2em;
    transform: ${props => props.isShow ? 'translateX(0)': 'translateX(-100%)'} ;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s; 
`
const Canvas = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    padding: 0.5em;
`

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0;
    }
`



export default function Logger({controlFunc, data, transportAreaDict}) {
    const [isShow, setIsShow] = useState(false);
    const layoutRef = useRef();
    const scrollRef = useRef();
    const scroller = () => {
        scrollRef?.current?.scrollIntoView({
            behavior: 'smooth'
        })
    }
    const closeWindow = () => {
        setIsShow(false)
        setTimeout(() => {
            controlFunc(false);
        }, 500)
    }
    useEffect(() => {
        setIsShow(true)
    }, [])
    useEffect(() => {
        scroller()
        console.log('action')
    }, [data])
    useDetectOutsideClick(layoutRef, closeWindow)
  return (
    <MainWrapper
        isShow={isShow}
        
        
    >
        {/* <OpenCloseButton
            close
            func={closeWindow}
        /> */}
        <Canvas ref={layoutRef}>
            <ContentContainer>
                {
                    data?.map((item, index) => 
                        <Message 
                            {...item} 
                            key={index} 
                            transportAreaDict={transportAreaDict}
                            refLink={(data?.length - 1) === index ? scrollRef : null}
                            index={index}
                        />
                    )
                }
            </ContentContainer>
        </Canvas>
    </MainWrapper>
  )
}
