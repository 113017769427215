
import React from 'react'
import styled from 'styled-components'
import { ArrowIcon } from '../icons/Icons'

type Props = {
    value: number,
    mood: boolean,
    reverse?: boolean
}

interface IBlock {
    mood: boolean
}
const MainWrapper = styled.div<IBlock>`
    min-width: 3em;
    padding: 0.2em 0.5em;
    background: ${props => props.mood ? '#80e081' : 'tomato'};
    color: #fff;
    border-radius: 30px;
    display: flex;
    gap: 0.3em;
    i {
        font-size: .7em;
        /* background: black; */
        /* transform: translateX(3px); */
    }
`
const PercentsBlock = ({value, mood, reverse}: Props) => {
    
  return (
    <MainWrapper mood={mood}>
        {/* <ArrowIcon position={arrowPositionSwitcher()} /> */}
        {Math.abs(value)}%
    </MainWrapper>
  )
}

export default PercentsBlock