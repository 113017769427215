import { requests } from "../config";
import { IChangeEntrieData, ICreateSortingData, IMoveEntrieData, IPutDivideData } from "./models/fetchData";

export const Miun = {
    getSortingByDates: (dateStart: string, dateEnd: string) => 
        requests.get(`sorting_results?DateFrom=${dateStart}&DateTo=${dateEnd}`, 'go'),
    getEtriesForSortingByNum: (sortingNum: number) => 
        requests.get(`sorting_results/${sortingNum}/suitable_entries`, 'go'),
    putDivideEntrie: (data: IPutDivideData) => requests.put('sorting_results/divide_entry', 'go', data),
    linkEntrie: (data: IMoveEntrieData) => requests.put('sorting_results/link_entry', 'go', data),
    unLinkEntrie: (data: IMoveEntrieData) => requests.put('sorting_results/unlink_entry', 'go', data),
    changeEntrieData: (data: IChangeEntrieData) => requests.put('entry/detail', 'go', data),
    createSorting: (data: ICreateSortingData) => requests.post('sorting_results', 'go', data),
}