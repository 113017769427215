import React from 'react'
import styled from 'styled-components'
import { ISupplyDataCustomerItem } from '../../../../../../modules/API/Forcasts/models/seller'
import { UserIcon } from '../../../../../../generic/icons/Icons'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import CustomerProductItem from './CustomerProductItem'

interface Props extends ISupplyDataCustomerItem {
  item: ISupplyDataCustomerItem
}

const CustomerItem = ({
  item, Customer, Products
}: Props) => {
  return (
    <MainWrapper>
      <HeadWrapper>
        <TitleRowItem 
          name={Customer.Customer_Name.trim()}
          icon={<UserIcon />}
        />
      </HeadWrapper>
      <BodyProductWrapper>
        {Products.map((product, index) => (
          <CustomerProductItem
            {...product}
            item={product}
            key={index}
          />
        ))}
      </BodyProductWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  position: relative;
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  /* background:red; */
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.2em;
  direction: ltr;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(41,127,118, 0.4));
  filter: drop-shadow(0px 5px 5px rgba(41,127,118, 0.4));
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
const HeadWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  padding: 0.4em .5em;
  border-radius: 20px;
  background: #f0f0f0;
  display: flex;
  justify-content: flex-end;
`
const BodyProductWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  /* background: #ffff; */
  padding: 0.2em;
`

export default CustomerItem