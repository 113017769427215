import React, {useState, useMemo} from 'react'
import { IPackagesItem } from '../../../../../../modules/API/Defaults/models/packages'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  list: IPackagesItem[]
  defaultID: number
  setPackageID: React.Dispatch<React.SetStateAction<number>>
  required?: boolean
}

const SearchPackage = ({
  list, defaultID, setPackageID,required
}: Props) => {
  const defaultPackage = list.filter(item => item.PackID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedPackage, setSelectedPackage] = useState<IPackagesItem | null>(defaultPackage || null)
  const [search, setSearch] = useState<string>(defaultPackage?.Pack_Name.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedPackage(null)
      setIsOpenDropDown(false)
      setSearch('')
      setPackageID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IPackagesItem) => {
    setSearch(item.Pack_Name.trim())
    setSelectedPackage(item)
    setIsOpenDropDown(false)
    setPackageID(item.PackID)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.Pack_Name.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  
  }, [search, list])
  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={'חפש סוג אריזה'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='Pack_Name'
      nameIdElement='PackID'
      required={required}
      size={'full'}
    />
  )
}

export default SearchPackage