import React, {useState} from 'react'
import styled from 'styled-components'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import SearchBlockWithTitle from '../../procedures/Q8/Layouts/SearchBlockWithTitle'
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../generic/assets/cogsPNG.png'
type Props = {
    saveFunc: (ref: number, curNum: number, driverName: string) => void;
}

const CefiDetailesModal = ({
    saveFunc
}: Props) => {
    const [refference, setRefference] = useState<number>();
    const [carNumber, setCarNumber] = useState<number>();
    const [driverName, setDriverName] = useState<string>('');

    const handleChangeRefference = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRefference(Number(e.target.value));
    }
    const handleChangeCarNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCarNumber(Number(e.target.value));
    }
    const handleChangeDriverName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDriverName(e.target.value);
    }
    const handleSave = () => {
        saveFunc(refference || 0, carNumber || 0, driverName);
    }
  return (
    <MainWrapper>
        <ImageComponentHead 
            img={Image}
        />
        <DataWrapper>
            <SearchBlockWithTitle title='אסמכתא'>
                <Input 
                    placeholder='אסמכתא'
                    type='number'
                    value={refference}
                    onChange={handleChangeRefference}
                />
            </SearchBlockWithTitle>
            <SearchBlockWithTitle title='רכב'>
                <Input 
                    placeholder='מספר רכב'
                    type='number'
                    value={carNumber}
                    onChange={handleChangeCarNumber}
                />
            </SearchBlockWithTitle>
            <SearchBlockWithTitle title='שם נהג'>
                <Input 
                    placeholder='נהג'
                    type='text'
                    value={driverName}
                    onChange={handleChangeDriverName}
                />
            </SearchBlockWithTitle>
        </DataWrapper>
        <ControlButton 
            lable={'שמור'}
            handleClick={handleSave}
        />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 1.5em;
`
const DataWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`
const Input = styled.input`
  width: 100%;
  height: 3em;
  border-radius: 30px;
  background: #EFF3F4;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1.2em;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
`
export default CefiDetailesModal