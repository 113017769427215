import React from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import { useHistory, useParams } from 'react-router-dom'
import useDataFetcher from '../../knisot/hooks/useFetchData';
import { Cefi } from '../../../../../modules/API/Cefi/cefi';
import { IGetCefiByIDResponse } from '../../../../../modules/API/Cefi/models/req';
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout';
import SearchInput from '../../../../../generic/inputs/inputSearch';
import { convertToDate } from '../../../../../modules/convertDateString';
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer';
import CustomBlockLoader from '../../knisot/generic/CustomBlockLoader';
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList';
import CefiItemView from './CefiItemView';
import ControlButton from '../../../../../generic/buttons/ControlButton';


function CefiViewMain() {
    const {id} = useParams<{id: string}>();
    const history = useHistory();
    const {
        data: cefiData,
        isLoading: isCefiLoading
    } = useDataFetcher<IGetCefiByIDResponse>(
        Cefi.getCefiByID, id
    )
    const dateConferted = convertToDate(cefiData?.expectTitle.EntryDate)
    const addictioanlData = {

    }
  return (
    <OrderlikeLayout
        label={`${id} - תעודה למחסן`}
    >
        <ControlSearchPannelLayout>
            <SearchInput 
                disabled
                input={dateConferted}
                setInput={() => {}}
            />
            <SearchInput 
                input={cefiData?.expectTitle.GrowerName.trim() || ''}
                setInput={() => {}}

                disabled
            />
        </ControlSearchPannelLayout>
        <BodyScrollContainer>
            {
                isCefiLoading ? <CustomBlockLoader /> :
                 <TitlesList 
                    list={cefiData?.expectLines || []}
                    uniqueKey={"Product"}
                    additionalData={addictioanlData}
                    noPadding={false}
                    Element={CefiItemView}
                 />
            }
        </BodyScrollContainer>
        <ControlButton 
            lable={'חזרה'}
            handleClick={() => history.goBack()}
        />
    </OrderlikeLayout>
  )
}

export default CefiViewMain