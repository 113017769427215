import React from 'react'
import { RootState } from '../../../../../store/reducers/rootReducer';
import { useSelector } from 'react-redux';
import selectBrodMigdal from '../../../../generic/utils/selectBrodMigdal';


const useConfig = () => {
  const defaults = useSelector((e: RootState) => e.defaults?.defaults);

  const isAddIdToDesc: boolean = selectBrodMigdal(defaults, 637, "Logical_Value");

  return [isAddIdToDesc] as const
}

export default useConfig