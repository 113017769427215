import { AnyAction } from "redux"
import { SELECT_CUSTOMER_PROCEDURES_Q8, SELECT_DATE_PROCEDURES_Q2, SELECT_DATE_PROCEDURES_Q4, SELECT_DATE_PROCEDURES_Q8, SELECT_REPORT_PROCEDURES_Q2, SELECT_REPORT_PROCEDURES_Q4 } from "../actions/actionsTypes"
import { IReportListItem } from "../../components/modules/API/Reports/models/reportList";
import { IClientItem } from "../../components/modules/API/Defaults/models/clients";


export interface IProcedures {
    Q4: {
        FromDate: string | null;
        ToDate: string | null;
        selectedReport: IReportListItem | null,
        date: string | null | [string | null, string | null]
    },
    Q2: {
        FromDate: string | null;
        ToDate: string | null;
        selectedReport: IReportListItem | null,
        date: string | null | [string | null, string | null]
    },
    Q8: {
        FromDate: string | null;
        ToDate: string | null;
        selectedCustomer: IClientItem[] | null,
        date: string | null | [string | null, string | null]
    }
}

const initialState: IProcedures = {
    Q4: {
        FromDate: null,
        ToDate: null,
        selectedReport: null,
        date: null
    },
    Q2: {
        FromDate: null,
        ToDate: null,
        selectedReport: null,
        date: null
    },
    Q8: {
        FromDate: null,
        ToDate: null,
        selectedCustomer: null,
        date: null
    }
}

const procedures = (state = initialState, action: AnyAction) => {
    switch(action.type) {
    
        case SELECT_REPORT_PROCEDURES_Q4:
            return {
                ...state,
                Q4: {
                    ...state.Q4,
                    selectedReport: action.report
                }
            }
        case SELECT_DATE_PROCEDURES_Q4:
            return {
                ...state,
                Q4: {
                    ...state.Q4,
                    date: action.payloadDate
                }
            }
            
        case SELECT_REPORT_PROCEDURES_Q2:
            return {
                ...state,
                Q2: {
                    ...state.Q2,
                    selectedReport: action.report
                }
            }
        case SELECT_DATE_PROCEDURES_Q2:
            return {
                ...state,
                Q2: {
                    ...state.Q2,
                    date: action.payloadDate
                }
            }

        case SELECT_CUSTOMER_PROCEDURES_Q8:
            return {
                ...state,
                Q8: {
                    ...state.Q8,
                    selectedCustomer: action.payloadCustomer
                }
            }
        case SELECT_DATE_PROCEDURES_Q8:
            return {
                ...state,
                Q8: {
                    ...state.Q8,
                    date: action.payloadDate
                }
            }
        default: return state
    }
}

export default procedures