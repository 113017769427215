import React, { useState, useEffect, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { LOAD, LOAD_END, SELECT_TEUDA_DATE } from "../../../../../store/actions/actionsTypes";
import DatePickerModule from "../../../../generic/datePicker/datePicker";
import SearchInput from "../../../../generic/inputs/inputSearch";
import ControlSearchPannelLayout from "../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import TitlesList from "../../../../generic/titlesRowItem/TitlesList";
import { RootState } from "../../../../../store/reducers/rootReducer";
import {
  GET_PDF_TEUDOT,
  GET_TEUDOT_V2,
  SET_SIGN_V2,
} from "../../../../modules/API/settings";
import { TTeudotList } from "../../../../../models/RequestsModel/teudot";
import TeudaItem from "./TeudaItem";
import BodyScrollContainer from "../../../../generic/Layouts/Wrappers/BodyScrollContainer";
import { Certificates } from "../../../../modules/API/Certificates/certificates";
import CircleActiveFilterBtn from "../../../../generic/particles/CircleActiveFilterBtn";
import { StampIcon } from "../../../../generic/icons/Icons";
import SelectByTwo from "../../../../generic/modals/SelectByTwo";
import { ContextModal } from "../../../../modules/modal/modalContext";
import { Defaults } from "../../../../modules/API/Defaults/defaults";
import SelectFromTheList from "../../../../generic/customSearchComponents/SelectFromTheList";
import { ITableOfTableItem } from "../../../../modules/API/Defaults/models/tableOfTables";
import ControlButton from "../../../../generic/buttons/ControlButton";
import { Link } from "react-router-dom";
import { IWorkerItem } from "../../../../modules/API/Defaults/models/workers";
import { LINKS } from "../../../../Routs/config";
import { ITeudotSmsData } from "../../../../modules/API/Certificates/models/smsData";
import selectBrodMigdal from "../../../../generic/utils/selectBrodMigdal";

type Props = {};
type TlistInterface = {
  current: TTeudotList,
  default: TTeudotList,
}
type TreqSign = {
  Delivery_Sub: number,
  Delivery_Num: number
}
const KosherTeudotBody = (props: Props) => {
  const dispatch = useDispatch();
  const defaults = useSelector((state: RootState) => state.defaults.defaults);
  const isAllowToSendSms = selectBrodMigdal(defaults, 273, "Text_Value") !== "";
  const {token, user, workerType} = useSelector((state: RootState) => state.mainReducer);
  const [modal, setModal] = useContext(ContextModal);
  const isAllowToPrint = !user?.rights.includes("GROWER") 
                         
  const isMashgiah = useMemo(() => {
    if (!workerType) return false 
    return workerType === 6
  }, [workerType])

  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dateString = `DateFrom=${date}&DateTo=${endDate}`

  const [input, setInput] = useState("");
  const [listState, setListState] = useState<TlistInterface>();
  const [isFilteredStamp, setIsFilteredStamp] = useState(false)
  const [filterKoserList, setFilterKosherList] = useState<ITableOfTableItem[]>();
  const [selectedKosherID, setSelectedKosherID] = useState<number>()

  const [isOpenSelectByTwo, setIsOpenSelectByTwo] = useState(false);
  const setDefaultDate = useSelector((state: RootState) => state.kosherTeudotReducer.date)

  const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
  
  const handleDate = (e: object | object[]) => {
          dispatch({type: SELECT_TEUDA_DATE, payload: e}
      )
    } 
  const getTeudotKosherVariations = async () => {
    try {
      let res = await Defaults.getDataFromTableOfTables(307);
      setFilterKosherList(res)
    } catch (err) {}
  }

  const handleFilterKosher = (id: string) => {
    setSelectedKosherID(+id)
    if(date) {
      getTeudot(dateString, +id)
    }
  }
  const sendSms = async (data: ITeudotSmsData) => {
    if(!isAllowToSendSms) return
    dispatch({ type: LOAD });
    try { 
      await Certificates.sendCertBySms(data);
      setModal({
        open: true,
        value: 'הודעה נשלחה בהצלחה',
        mood: true
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
    } catch (err: any) {
      console.log(err)
      setModal({
        open: true,
        value: err.response?.data?.error === 'Client has no phone' ? 'לא נמצא מספר טלפון ללקוח' : err.response?.data?.error,
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
    } finally {
      dispatch({ type: LOAD_END });
    }
  }
  const printPdf = async (CODE: number, SUBCODE: number) => {
    if (!isAllowToPrint) return
    dispatch({ type: LOAD });
    try {
      let response = await fetch(
        `${GET_PDF_TEUDOT}deliveryNum=${CODE}&deliverySub=${SUBCODE}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.blob();
      // Receive PDF Steam as as Blob
      const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
      const fileURL = URL.createObjectURL(file); //Build a URL from the file
      window.open(fileURL); //Open the URL on new Window
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: LOAD_END });
    }
  };

  const getTeudot = async (date: string, id?: number) => {
    dispatch({ type: LOAD });
    try {
      let res
      if(id === 0 || !id) {
        res = await Certificates.getCertsByDate(date)
      } else {
        res = await Certificates.getCertsByKosher(date, id)
      }
      setListState({
        current: res,
        default: res,
      });
      console.log(res);
    } catch (err: any) {
      console.log(err.response);
    } finally {
      dispatch({ type: LOAD_END });
    }
  };

  const modyfiTeudaAfterSign = (data: any) => {
    const delivery = data.Deliveries
      if(delivery.length === 1 && listState) {
        const temp = [...listState?.current]
        let found = temp.find(
          e => e.Delivery_Num === delivery[0].Delivery_Num &&
            e.Delivery_Sub === delivery[0].Delivery_Sub
        )
        if (found) {
          let indexOfitem = temp.indexOf(found)
          let tempElement = temp.splice(indexOfitem, 1)
          tempElement[0].Mashgiah = data.Mashgiah
          temp.splice(indexOfitem, 0, tempElement[0])
          setListState({
            ...listState,
            current: temp,
          })
        }

      }
  }
  
  const setSign = async (item: TreqSign[], mashgiah?: number ) => {
    // dispatch({ type: LOAD });
    const data = {
      Mashgiah: mashgiah ? 0 : user ? +user.code_internal : 0,
      Deliveries: item
    }
    try {
      let res = await Certificates.setStamp(data)
      modyfiTeudaAfterSign(data)
    } catch (err:any) {
      console.log(err.response)
      setModal({
        open: true,
        value: err.response?.data?.message,
        mood: false
    })
    setTimeout(() => {
        setModal({
            open: false,
            value: '2'
        })
    }, 3000)
    } finally {
      // dispatch({ type: LOAD_END });

    }
  }
  const printAll = () => {
    const data = listState?.default.reduce((acc: TreqSign[], cur) => {
      if(cur.Mashgiah === 0) {
        const obj = {
          Delivery_Num: cur.Delivery_Num, 
          Delivery_Sub: cur.Delivery_Sub
        }
        acc.push(obj)
      }
      return acc
    }, [])
    if (data) {
      setSign(data).then(() => {
        if(date) {
          getTeudot(dateString, selectedKosherID)
        }
      })
    }
    setIsOpenSelectByTwo(false)
    console.log(123123123)
  }
  

  const renderList = useMemo(() => {
    console.log('123')
    const filtered = listState?.current.filter(
      (item) =>
        item.Delivery_Num.toString().includes(input) ||
        item.Customer_Name.toLocaleLowerCase().includes(
          input.toLocaleLowerCase()
        )
    );
    return filtered
  }, [listState?.current, input])

  const checkForUnSign = useMemo(() => {
    return listState?.default.find(item => item.Mashgiah === 0)
  }, [listState])

  const handleStampFilter = () => {
      // if (listState) {
      //     const updated = Object.assign(listState);
      //     if (!isFilteredStamp) {
      //       const filtered = listState?.default.filter(
      //         (item) =>
      //           item.Mashgiah === 0
      //       );
      //       updated.current = filtered;
      //     } else {
      //       updated.current = updated.default
      //     }
      //     setListState(updated);
      //     setIsFilteredStamp(!isFilteredStamp)
      // }
      if(!checkForUnSign) {
        setModal({
            open: true,
            value: '',
            mood: false
        })
        setTimeout(() => {
            setModal({
                open: false,
                value: '2'
            })
        }, 3000)
        return
      }
      setIsOpenSelectByTwo(!isOpenSelectByTwo);
  }
  useEffect(() => {
    getTeudotKosherVariations()
  }, []);
  useEffect(() => {
    if (!date) return;
    getTeudot(dateString, selectedKosherID);
  }, [dateString]);

  const PList = {
    print: printPdf,
    sign: setSign,
    isAllowToPrint: isAllowToPrint,
    isMashgiah: isMashgiah,
    sendSms: sendSms,
    isAllowToSendSms: isAllowToSendSms
};


return (
    <MainWrapper>
      {
        isOpenSelectByTwo && 
          <SelectByTwo 
            label={'חותמת כשרות'}
            open={isOpenSelectByTwo} 
            closeFunc={() => {setIsOpenSelectByTwo(!isOpenSelectByTwo)}}
            selectFuncTwo={() => {setIsOpenSelectByTwo(false)}}
            selectFuncOne={printAll}
            chooseLableOne="לחתום את הכול"
            chooseLabeTwo="חזרה"
          />
      }
      <ControlSearchPannelLayout>
        <DatePickerModule
          multyDate
          setSelectedDateStart={setDate}
          defEndDate={false}
          disabled={false}
          selected={setDefaultDate ? selectedDate : new Date()}
          cashFunc={handleDate}
          ignorBrod={true}
          setSelectedDateEnd={setEndDate}
          large
          key={1}
        />
        {
          (isMashgiah && checkForUnSign && selectedKosherID &&
            <CircleActiveFilterBtn 
              key={2} 
              children={<StampIcon />} 
              func={handleStampFilter} 
            />) || ''
        }
        {
          isMashgiah &&
            <SelectFromTheList 
              lable={'הכול'}
              list={filterKoserList}
              dispalyFieldName={'Description'}
              itemKey={'SecondCode'}
              fieldValue={'SecondCode'}
              selectFunction={handleFilterKosher}
              selectedValue={selectedKosherID}
              key={3}
            />
        }
        <SearchInput input={input} setInput={setInput} key={4} />
      </ControlSearchPannelLayout>
      <BodyScrollContainer>
        <TitlesList
          list={renderList}
          uniqueKey={"Delivery_Num"}
          Element={TeudaItem}
          queryRender={() => true}
          additionalData={PList}
          noPadding={false}
          backgroundColor={'#eff3f4'}
        />
      </BodyScrollContainer>
      <Link to={LINKS.teudot.teudotMishloah.create}>
        {
          ( 
            user?.rights.includes("OFFICE") || 
              user?.rights.includes("ADMIN") || 
                user?.rights.includes("DEV") ) &&
                  <ControlButton 
                    lable={"חדשה"}
                  />
        }
      </Link>
    </MainWrapper>
  );
};

export default KosherTeudotBody;

const MainWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  gap: 0.5em;
  z-index: 1;
`;
