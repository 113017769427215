import React from 'react'
import NavLayout from '../../Layouts/NavLayout'
import { PalletIcon, SlidersIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import NavItem from './Components/NavItem'
import { useTranslation } from 'react-i18next'
// import UserIcon from '../../../../../../generic/icons/IconLib/UserIcon'

export interface INavItem {
  name: string
  icon: React.ReactNode
  path: string
}

const SettingsNav = () => {
  const [t, i18n] = useTranslation(['settings'])

  return (
    <NavLayout>
      
      <NavItem 
        name={t('account')}
        icon={<UserIcon />}
        path='/main/settings/account'
      />
      <NavItem 
        name={t('preferences')}
        icon={<SlidersIcon />}
        path='/main/settings/preferences'
      />
    </NavLayout>
  )
}

export default SettingsNav