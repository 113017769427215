
export default function WrapperItem({PackID, Pack_Name}){

    return(
        <option
        //  selected={defToggle} 
         style={{textAlign: 'right'}} value={PackID}>
             {Pack_Name.trim()}
         </option>
    )
}