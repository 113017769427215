import { AnyAction } from "redux"
import { CLEAR_RECEIPTS_STATE, SELECT_DATA_FOR_CREATE_RECEIPT } from "../actions/actionsTypes"

export interface IReceiptState{
    date: string | null
}

const initialState: IReceiptState = {
    date: null
}

const receipts = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_DATA_FOR_CREATE_RECEIPT:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_RECEIPTS_STATE:
            return {
                ...initialState
            }
        default: return state
    }
}

export default receipts
