import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { statusAzmanotList } from '../../../../../../generic/artcoded/statusAzmanot'

import WorkersSelectList from '../workersSelectList/WorkersSelectList'
import FilterAzmanotList from './selects/FilterAzmanotList'



export const MainModalWrapper = styled.div`
    position: absolute;
    width: 105%;
    /* height: 20em; */
    top: 4em;
    left: -2.5%;
    border-radius: 20px;
    background: #eff3f4;
    z-index: 2;
    display:  ${props => props.primal ? 'flex' : 'none'};
    flex-flow: column;
    align-items: center;
    padding: 0.5em;
    gap: 0.5em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
`

export const FilterContainer = styled.div`
    width: 90%;
    min-height: 4em;
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
`
export const AddBtn = styled.button`
    width: 6em;
    height: 2em;
    background: rgba(41,127,118, 0.7);
    border: none;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
`
export default function ModalFilterAzmanot({
    deliveryCodes, transportAreas, setIsOpenOrderFilter, isOpenOrderFilter,
    azmanotListDefault, setAzmanotList, workerGroup, customerGroup, sellerList,
    currentWorkerAzmanot, customerList, prodTypes, setFilterFunc, marketAreaList
}) {

    const [deliveryId, setDeliveryId] = useState(null);
    const [transportAreaId, setTransportAreaId] = useState(null);
    const [workerID, setWorkerID] = useState(null);
    const [customerGroupID, setCustomerGroupID] = useState(null);
    const [statusAzmanaID, setStatusAzmanaID] = useState(null);
    const [sellerID, setSellerID] = useState(null);
    const [kwucaID, setKwucaID] = useState(null);
    const [customersIdArray, setCustomersIdArray] = useState([]);
    const [areaID, setAreaID] = useState(null);
    const customersDict = customerList.reduce((acc, cur) => {
        acc[cur['CustomerID']] = cur
        return acc
    }, {})
    // console.log(customersDict)
    const handleFilterAzmanot = (array) => {

        const filtered = array.filter(item =>
            item.WorkerID === 0 &&
            (deliveryId ? item.Delivery_Code === +deliveryId : true) &&
            (transportAreaId ? item.TransferZone === +transportAreaId : true) &&
            (workerID ? item.PickupGroup === +workerID : true) &&
            (customerGroupID ? customersDict[item.CustomerID]?.Customer_GroupID === +customerGroupID : true) &&
            (statusAzmanaID ? item.Status === +statusAzmanaID : true) &&
            (sellerID ? customersIdArray.includes(item.CustomerID) : true) &&
            (kwucaID ? item.GroupID === +kwucaID || item.GroupID === 0 : true) &&
            (areaID ? customersIdArray.includes(item.CustomerID) : true)

        )
        console.log(filtered)
        setAzmanotList(filtered)
        setIsOpenOrderFilter(false)
        setFilterFunc({
            get: handleFilterAzmanot
        })
    }

    const filterWorkers = (value) => {
        setWorkerID(+value)
    }

    const filterCustomersBySellerID = (sellerID) => {
        setSellerID(sellerID);
        const filter = customerList.filter(item => item.ResellerID === +sellerID).reduce((acc, cur) => {
            return acc.concat(cur.CustomerID)
        }, [])
        
        setCustomersIdArray(filter)

    }
    const filterCustomersByMarketArea = (areaID) => {
        setAreaID(areaID);
        const filter = customerList.filter(item => item.Marketing_AreaID === +areaID).reduce((acc, cur) => {
            return acc.concat(cur.CustomerID)
        }, [])
        setCustomersIdArray(filter)

    }
    // console.log(transportAreaId)
    return (
        <MainModalWrapper primal={isOpenOrderFilter} >
            <FilterContainer>
                {/* <TransportAreaList transportAreas={transportAreas} setTransportAreaId={setTransportAreaId} /> */}
                <FilterAzmanotList
                    setId={setTransportAreaId}
                    list={transportAreas}
                    label={'קו הפצה - הכל'}
                    id={'AreaID'}
                    desc={'AreaDesc'}
                    selectedValue={transportAreaId}
                />
                <FilterAzmanotList
                    setId={setDeliveryId}
                    list={deliveryCodes}
                    label={'סבב הפצה - הכל'}
                    id={'Delivery_Code'}
                    desc={'Delivery_Description'}
                    selectedValue={deliveryId}
                />
            </FilterContainer>
            {/* <FilterContainer>
                <DeliveryList deliveryCodes={deliveryCodes} setDeliveryId={setDeliveryId} />
            </FilterContainer> */}
            <FilterContainer>
                <WorkersSelectList
                    workerGroup={workerGroup}
                    filterWorkers={filterWorkers}
                />
                <FilterAzmanotList
                    setId={filterCustomersBySellerID}
                    list={sellerList}
                    label={'משווק -  הכל'}
                    id={'SellerID'}
                    desc={'Seller_Desc'}
                    selectedValue={sellerID}
                />
            </FilterContainer>
            <FilterContainer>
                {/* <CustomerGroupList
                    customerGroup={customerGroup}
                    setCustomerGroupID={setCustomerGroupID}

                /> */}
                <FilterAzmanotList
                    setId={setCustomerGroupID}
                    list={customerGroup}
                    label={'קבוצת לקוח - הכל'}
                    id={'GroupID'}
                    desc={'GroupDesc'}
                    selectedValue={customerGroupID}
                />
                <FilterAzmanotList
                    setId={setStatusAzmanaID}
                    list={statusAzmanotList}
                    label={'סטטוס - הכל'}
                    id={'id'}
                    desc={'Desc'}
                    selectedValue={statusAzmanaID}
                />
                {/* <StatusAzmanotList
                    setStatusAzmanaID={setStatusAzmanaID}

                /> */}
            </FilterContainer>
            <FilterContainer>
                <FilterAzmanotList
                    setId={setKwucaID}
                    list={prodTypes}
                    label={'קבוצת הזמנה - הכל'}
                    id={'GroupID'}
                    desc={'GroupDesc'}
                    selectedValue={kwucaID}
                />
                <FilterAzmanotList
                    setId={filterCustomersByMarketArea}
                    list={marketAreaList}
                    label={'איזורי שיווק - הכל'}
                    id={'SecondCode'}
                    desc={'Description'}
                    selectedValue={areaID}
                />

            </FilterContainer>
            <AddBtn onClick={() => (handleFilterAzmanot(azmanotListDefault))}>סנן</AddBtn>
        </MainModalWrapper>
    )
}
