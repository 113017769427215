import React, { useState } from 'react'
import styled from 'styled-components'
import { AcceptIcon, BasketIcon, CloseIcon, InvoiceIcon, PackageIcon, PalletIcon, PrintIcon, ScalesIcon } from '../../../../../generic/icons/Icons'
import { IOrderItem } from '../../../../../modules/API/Drivers/models/kavData'

interface IProps extends IOrderItem {
    printFunc?: (CODE: number, SUBCODE: number) => void,
    isNotMain?: boolean,
    handleSelectOrder?: (item: IOrderItem) => void
    item: IOrderItem
}
interface Ibutton {
    isDisabled?: boolean;
}

const OrderItem = ({
    Delivery_Num, Delivery_Sub, OrderID, Pallets, DeliveryStat, handleSelectOrder,
    Packs, Weight, PalletsList, printFunc, isNotMain, GroupDesc, Selected, item,
}: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const isTeuda = Delivery_Num !== 0
    const isDelivered = item.LastAction?.Action === 1
    const isExistProblem = item.LastAction?.Action > 1
    const palletsQty = PalletsList.length > 0 ? PalletsList.reduce((acc, cur) => acc + cur.PallQty, 0) : Pallets
    const handlePrint = (e: React.MouseEvent) => {
        if(!Delivery_Num) return
        e.stopPropagation()
        if(printFunc) {
            printFunc(+Delivery_Num, +Delivery_Sub)
        }
    }
    const handleTogglePalletList = (e: React.MouseEvent) => {
        e.stopPropagation()
        setIsOpen(!isOpen)
        handleSelect(e)
    }
    const handleSelect = (e: React.MouseEvent) => {
        e.stopPropagation()
        if(!handleSelectOrder) return
        handleSelectOrder(item)
    }

    const colorForItem = () => {
        if(isDelivered) {
            return '#297F76'
        } else if(isExistProblem) {
            return 'tomato'
        } else {
            return ''
        }
    }
    console.log(Delivery_Num && !isNotMain )
  return (
    <MainWrapper onClick={handleTogglePalletList} customBG={colorForItem()}>
        <SideIndicator color={isDelivered ? '#297F76' : 'tomato'}>
           
            {
                isNotMain && Selected ?
                    <AcceptIcon /> : ''
            }
            {
                    Delivery_Num && !isNotMain ?
                        <ButtonSide
                        
                            onClick={handlePrint}
                        >
                            <PrintIcon/>
                        </ButtonSide>  :
                        !isNotMain && isTeuda && isDelivered ?
                            <AcceptIcon /> : isTeuda && isExistProblem && !isNotMain?
                            <CloseIcon handler={() => {}} /> : ''
            }
        </SideIndicator>
        
        <ContenWrapper>
            <Title>
                    <TitleItem>
                        {Delivery_Num ? Delivery_Num : 'לא קיימת תעודה'}
                        <InvoiceIcon />
                    </TitleItem> 
                
                
                <TitleItem>
                    {OrderID}
                    <BasketIcon handle={() => {}} />
                </TitleItem>
            </Title>
            <Title>
                <TitleItem>
                    {palletsQty?.toFixed(2)} 
                    <PalletIcon />
                </TitleItem>
                <TitleItem>
                    {GroupDesc?.trim()}
                </TitleItem>
                
            </Title>
            {
                isOpen && PalletsList.length > 0 && !isNotMain &&
                    <BodyWrapper>
                        {
                            PalletsList.map((item, index) => 
                                <Title key={index}>
                                    <TitleItem>
                                        {Math.round(item.Gross)}
                                        <ScalesIcon />
                                    </TitleItem>
                                    <TitleItem>
                                        {item.Qty}
                                        <PackageIcon />
                                    </TitleItem>
                                    <TitleItem>
                                        {item.Pallet_Num} - 
                                        <PalletIcon />
                                    </TitleItem>
                                </Title>
                            )
                        }
                    </BodyWrapper>
            }
        </ContenWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{customBG?: string}>`
position: relative;
    width: 100%;
    background: ${props => props.customBG ? props.customBG : '#e9f0f0'} ;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    color: ${props => props.customBG ? '#fff' : '#838484'} ;
    cursor: pointer;
    i {
        transition: 0.3s ease-in-out;
        color: ${props => props.customBG ? '#ffff' : '#7ab0ab'} ;
        /* color: #7ab0ab; */
    }
`
const ContenWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`
const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`
const TitleItem = styled.div`
    display: flex;
    gap: 0.3em;
`
const Kwuca = styled.div<{isKwuca?: boolean}>`
    background: ${props => props.isKwuca ? '' : ''}
`
const BodyWrapper = styled.div`
    padding: 1em 0;
    border-top: 2px solid #7ab0ab;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
const ButtonSide = styled.div<Ibutton>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 3em;
    ;
    opacity: ${props => props.isDisabled ? '0.4' : '1'};
    cursor: ${props => props.isDisabled ? 'default': 'pointer'};   
   
    :hover {
        i {
            color:#297F76;
        }
    }
    @media screen and (max-width: 400px) {
        min-width: 2em
    }
`
const SideIndicator = styled.div<{color?: string}>`
    position: absolute;
    top: calc(50% - 1em);
    left: 1em;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    i {
        color: ${props => props.color ? props.color : '#7ab0ab'};
    }
    /* background: #7ab0ab;
    border-radius: 20px 0 0 20px; */

`
export default OrderItem