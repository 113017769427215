import React from 'react'
import styled from 'styled-components'
import { ArrowIcon } from '../../../../../../../generic/icons/Icons'
import { IGrowerItem } from '../../../../../../../modules/API/Defaults/models/growers'

type Props = {
  growers: IGrowerItem[],
  setGrowerID: React.Dispatch<React.SetStateAction<number>>,
  defaultGrower?: number
  disabled?: boolean
}

const SelectGrower = ({
  growers, setGrowerID, defaultGrower, disabled
}: Props) => {
  const handle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(+e.target.value === -1) return
    setGrowerID(+e.target.value)
  }
  // console.log(defaultGrower)
  return (
    <MainWrapper>
    <SideIcon>
      <ArrowIcon position={'bottom'} />
    </SideIcon>
    <Select
      onChange={handle}
      defaultValue={defaultGrower}
      disabled={disabled}
    >
      <option value={-1}>בחר מגדל</option>
      {
        growers.map(e => 
          <option value={e.GrowerID}>{e.GrowerName?.trim()}</option>
        )
      }
    </Select>
  </MainWrapper>
  )
}
const MainWrapper = styled.div`
  padding: .2em;
  background: #EFF3F4;
  border-radius: 30px;
  position: relative;
`
const Select = styled.select`
  width: 100%;
  direction: rtl;
  border-radius: 30px;
  padding: 0.2em 0.5em;
  font-size: 1.1em;
  border: none;
  outline: none;
  appearance: none; 
  background-color:#EFF3F4;
  color: #838484;
  cursor: pointer; 

`
const SideIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 2.2em;
  height: 2.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #838484;
  font-size: 0.9em;
`
export default SelectGrower