import { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  AcceptIcon,
  EditIcon,
  InvoiceIcon,
  PackageIcon,
  PalletIcon,
  ScalesIcon,
  SpeedIcon,
  UserIcon,
} from "../../../../../generic/icons/Icons";
import {
  ICustomerItem,
  IOrderItem,
} from "../../../../../modules/API/Drivers/models/kavData";
import { LINKS } from "../../../../../Routs/config";
import OrderItem from "./OrderItem";

interface IProps extends ICustomerItem {
  printFunc?: (CODE: number, SUBCODE: number) => void;
  selectOrderForCloseDelivery?: (item: IOrderItem[], dataCustomer: ICustomerItem, areaID: number) => void;
  item: ICustomerItem;
  selectDataForPackageOrder?: (dataClient: ICustomerItem, kavID: number) => void;
  isOffice?: boolean;
  AreaID: number;
}

const ClientsOrderItem = ({
  Customer_Name,
  Orders,
  printFunc,
  selectOrderForCloseDelivery,
  item,
  selectDataForPackageOrder,
  PO_Num, PD_Num,
  Type, isOffice, LastAction, AreaID
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const delivered = Orders.filter(order => order.Delivery_Num !== 0).every((e) => e.LastAction.Action === 1);
  const notDelivered = Orders.filter(order => order.Delivery_Num !== 0).every((e) => e.LastAction.Action > 1);
  const qtyWithoutTeuda = Orders.filter(order => order.Delivery_Num === 0).length;
  const isExistTeudot =  (Type === 1 && Orders.length > 0) || (Orders.length > 0 && Orders.some((e) => e.Delivery_Num !== 0));
  const isExistOrderArizot = PO_Num !== 0;

  console.log(isExistTeudot);
  const { weight, pallets } = useMemo(() => {
    let { weight, pallets } = Orders.reduce(
      (acc: Record<string, number>, cur: IOrderItem) => {
        if(cur.PalletsList.length === 0) {
          acc.weight = (acc.weight ? acc.weight : 0) + cur.Weight;
          acc.pallets = (acc.pallets ? acc.pallets : 0) + cur.Pallets;
        }  else {
          acc.weight = (acc.weight ? acc.weight : 0) + cur.PalletsList.reduce((sum, curP) => {
            return sum + curP.Gross
          }, 0);
          acc.pallets = (acc.pallets ? acc.pallets : 0) + cur.PalletsList.reduce((sum, curP) => {
            return sum + curP.PallQty
          }, 0);
        }
        return acc;
      },
      {}
    );
    return { weight: weight || 0, pallets: pallets || 0 };
  }, [Orders]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleButtonArizot = (e: React.MouseEvent) => {
    // if(isOffice) return
    e.stopPropagation();
    if (isExistOrderArizot) {
      if(!selectDataForPackageOrder) return
      selectDataForPackageOrder(item, AreaID);
      history.push(LINKS.drivers.quests.mainURL + `?id=${PO_Num}`);
      
    } else {
      if(!selectDataForPackageOrder) return
      selectDataForPackageOrder(item, AreaID);
    }
  };
  const handleButtonConfirmDelivery = (e: React.MouseEvent) => {
    if(isOffice) return
    e.stopPropagation();
    if (!selectOrderForCloseDelivery) return;
    console.log(selectOrderForCloseDelivery)
    selectOrderForCloseDelivery(Orders, item, AreaID);
  };
  console.log(weight, pallets, ' - ', Orders)
  const isRehesh = Type === 1;
  const setPackageColor = () => {
    if(PD_Num !== 0  || PO_Num !== 0) return "#a1e396"
    // if(PO_Num !== 0) return "#FFF466"
    return LastAction.Action !== 0 ? "orange" : "tomato"
  }
  return (
    <MainWrapper 
      onClick={handleToggle}
      bg={!isRehesh}
    
    >
      <ClientTitle>
        <TitleRow isHead>
          
          <ButtonsWrapper>
                <Button
                  onClick={handleButtonArizot}
                  isDone={setPackageColor()}
                >
                    {
                      PD_Num !== 0 ?
                        <InvoiceIcon /> :
                      <PackageIcon />
                    }
                </Button>
              
            {
                  isExistTeudot && 
                  <>
                    <Button
                        onClick={handleButtonConfirmDelivery}
                        isDone={
                          delivered ? "#a1e396" : notDelivered ? "orange" : "tomato"
                        }
                        >
                        {<SpeedIcon color={""} />}
                    </Button>
                    {qtyWithoutTeuda > 0 && <span>-{qtyWithoutTeuda}</span>}
                  </>
              }
              
              </ButtonsWrapper> 

          <TitleItem>
            {Customer_Name.trim()}
            <UserIcon />
          </TitleItem>
        </TitleRow>
        <TitleRow>
          <TitleItem>
            {pallets?.toFixed(2)}
            <PalletIcon />
          </TitleItem>
          <TitleItem>
            {Math.round(weight)}
            <ScalesIcon />
          </TitleItem>
        </TitleRow>
        {
          isOffice && LastAction.Action > 1 &&
          <TitleRow isProblem={LastAction.Action > 1}>
            <TitleItem>
              {LastAction?.ActionDescription?.trim()}
              <PackageIcon />
            </TitleItem>
          </TitleRow>
        }
      </ClientTitle>

      <BodyWrapper isOpen={isOpen && Orders.length > 0}>
        <Sideline />
        {Orders.map((item, index) => (
          <OrderItem 
            item={item} 
            key={index} 
            {...item} 
            printFunc={printFunc} 
          />
        ))}
      </BodyWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div<{bg?: boolean}>`
  background: ${props => props.bg ? '#fff' : '#ccf3cd'};
  border-radius: 30px;
  padding: 0.5em 1em;
  display: flex;
  flex-flow: column;
  gap: 1em;
  cursor: pointer;
  -webkit-box-shadow: -2px 8px 2px -6px rgba(41, 127, 118, 0.31);
  box-shadow: -2px 8px 2px -6px rgba(41, 127, 118, 0.31);
  i {
    color: #7ab0ab;
  }
`;
export const ButtonsWrapper = styled.div`
  width: 4em;
  display: flex;
  justify-content: space-between;
  /* background: red; */
`;
export const Button = styled.button<{ isDone?: string }>`
  outline: none;
  appearance: none;
  border: none;
  font-size: 1.2em;
  background: none;
  cursor: pointer;
  padding: 0 0.5em;
  :hover {
    transform: scale(1.1);
  }
  i {
    color: ${(props) => (props.isDone ? props.isDone : "tomato")};
  }
`;
const ClientTitle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  color: #918484;
`;
const TitleRow = styled.div<{ isHead?: boolean, isProblem?: boolean }>`
  width: 100%;
  display: flex;
  color: ${props => props.isProblem ? 'tomato' : ''};
  i {
  color: ${props => props.isProblem ? 'orange' : ''};

  }
  gap: 1em;
  padding-left: 1em;
  justify-content: ${(props) => (props.isHead ? "space-between" : "flex-end")};
`;
const TitleItem = styled.div<{}>`
  display: flex;
  gap: 0.4em;
`;
const BodyWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-flow: column;
  gap: 0.5em;
  padding: 0 1em;
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;
const Sideline = styled.div`
  position: absolute;
  top: 10%;
  right: 0.5em;
  width: 1px;
  height: 80%;
  background: #918484;
  opacity: 0.6;
  border-radius: 50%;
  @media screen and (max-width: 400px) {
    right: -0.4em;
  }
`;
export default ClientsOrderItem;
