import React from 'react'
import styled from 'styled-components'
import { AsteriksIcon } from '../icons/Icons'
const RequiredMarkWrapper = styled.div`
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    color: tomato;
    z-index: 1;
    transform: rotate(90deg);
`
export default function RequiredFieldMark({ visible }) {
    return visible ? (
        <RequiredMarkWrapper>
            <AsteriksIcon />
        </RequiredMarkWrapper>
    ) : ''
}
