// import {useEffect} from 'react';
// import {useSelector} from 'react-redux';

import './acordion.scss';

function Acordion(){

    return(
        <section className="main-acordion" >
            <div className="acordion-container">
                <div className="acordion-container__item item-one inner">
                    <div className="inner__block">
                        <div className="wrapper">
                            <h2 className="inner__block-title"><i className="fas fa-globe"></i></h2>
                            <h2 className="inner__block-title">עבודה בענן</h2>
                            <p className="inner__block-text">מערכת זמינה בכל מקום בעולם.
                                עבודה במחשבgit, טאבלט וטלפון 
                                <br /> התקנה קלה ועבודה מיידית תוך דקה אחת
                            </p>
                        </div>
                    </div>
                </div>
                <div className="acordion-container__item item-two inner">
                    <div className="inner__block">
                        <div className="wrapper">
                            <h2 className="inner__block-title"><i className="fas fa-tools"></i></h2>
                            <h2 className="inner__block-title">גמישות</h2>
                            <p className="inner__block-text">
                                גמישות גבוהה עם הרשאות לכל סוגי המשתמשים וגם טבלת הגדרות 
                                בהתאמה אישית לכל סוג עסק
                            </p>
                        </div>
                    </div>
                </div>
                <div className="acordion-container__item item-three inner">
                    <div className="inner__block">
                        <div className="wrapper">
                            <h2 className="inner__block-title"><i className="far fa-smile"></i></h2>
                            <h2 className="inner__block-title">ידידותית למשתמש</h2>
                            <p className="inner__block-text">
                                תפעול המערכת קל ונוח , אחידות בממשק המשתמש בכל המסכים
                                <br/>תחילת עבודה כבר אחרי 10 דקות הדרכה
                            </p>
                        </div>
                    </div>
                </div>
                <div className="acordion-container__item item-four inner">
                    <div className="inner__block">
                        <div className="wrapper">
                            <h2 className="inner__block-title"><i className="fas fa-infinity"></i></h2> 
                            <h2 className="inner__block-title">פתרון מקיף</h2>
                            
                            <p className="inner__block-text"> 
                                פתרון מקיף לכל תהליכי העבודה
                                החל מקליטת תוצרת חקלאית ועד קבלת הזמנות מלקוחות, ביצוע רכש, שיווק, ליקוט והפקת תעודות משלוח.
                                התחשבנות מלאה מול לקוחות וספקים בארץ ובחו"ל. 
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="acordion-container__item item-five inner">
                    <div className="inner__block">
                        <div className="wrapper">
                            <h2>Hello</h2>
                            <p>Fish text fish text</p>
                        </div>
                    </div>
                    
                    
                </div> */}
            </div>
        </section>

    );
}

export default Acordion