import React, { useState, useContext } from 'react'
import { IMarketPalletDetails, IUpdateProduct } from '../../../../../../modules/API/Market/models/marketRequests'
import styled from 'styled-components'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PackageIcon, ScalesIcon, ShekelIcon } from '../../../../../../generic/icons/Icons'
import RowInput from '../../../../../../generic/inputs/RowInput'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
import TypeCalcSelect from './generic/TypeCalcSelect'
import { IPalletTypeItem } from '../../../../../../modules/API/Defaults/models/pallets'
import { IGrowerItem } from '../../../../../../modules/API/Defaults/models/growers'
import SelectPallet from './generic/SelectPallet'
import SelectGrower from './generic/SelectGrower'
import CheckBoxWithTitle from '../../../../../../generic/particles/CheckBox'
import { ContextModal } from '../../../../../../modules/modal/modalContext'

type Props = {
    data: IMarketPalletDetails,
    updateProduct: (data: IUpdateProduct) => void
    pallets: IPalletTypeItem[]
    growers: IGrowerItem[],
    isWorker: boolean
}

const ProductModal = ({
     data, updateProduct, pallets, growers,
     isWorker
    }: Props) => {
    const isDisableEdit = data.InvoiceSale !== 0
    const [modal, setModal] = useContext(ContextModal)
    const [salePrice, setSalePrice] = useState<string>(data.SalePrice.toFixed(2));
    const [quantity, setQuantity] = useState<number>(data.Quantity);
    const [weightBrutto, setWeightBrutto] = useState<string>(data.WeightBrutto.toFixed(1));
    const [calcType, setCalcType] = useState<number>(data.Type_SaleCalculation) // [0, 1, 2
    const [palletID, setPalletID] = useState<number>(data.Pallet_Type)
    const [growerID, setGrowerID] = useState<number>(data.GrowerID)
    const [isNeedtoCalcPallet, setIsNeedtoCalcPallet] = useState<boolean>(true)
    
    const [tareWeight, setTareWeight] = useState<number>(() => 
        pallets.find(p => p.Pallete_Type === data.Pallet_Type)?.Tare_Weight || 0
    )

    const palletDict = pallets.reduce((acc: Record<number, IPalletTypeItem>, curr) => {
        acc[curr.Pallete_Type] = curr
        return acc
    }, {})

    const handleUpdateProduct = () => {
        if(isDisableEdit) {
            setModal({
                mood: false,
                value: 'לא ניתן לערוך מוצר עם חשבונית מכירה',
                open: true
            })
            setTimeout(() => {
                setModal({
                    mood: false,
                    value: '',
                    open: false
                })
            }, 3000)
            return
        }
        const product: IUpdateProduct = {
            BuyPrice: +salePrice,
            Quantity: quantity,
            Quantity_Pallets: data.Quantity_Pallets,
            Remarks: data.Remarks,
            SalePrice: +salePrice,
            SizeID: data.SizeID,
            isIncludePallet: isNeedtoCalcPallet,
            WeightBrutto: isNeedtoCalcPallet ? +weightBrutto : +weightBrutto + tareWeight,
            Type_SaleCalculation: calcType,
            Type_BuyCalculation: calcType,
            Pallet_Type: palletID,
            GrowerID: growerID,
        }
        updateProduct(product)
    }

    const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;

        // Allow empty input, numbers, and one decimal point
        if (/^\d*\.?\d*$/.test(input)) {
            setWeightBrutto(input)

        }
    }

    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = Number(e.target.value);
        setQuantity(val)
    }

    const handlePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;

        // Allow empty input, numbers, and one decimal point
        if (/^\d*\.?\d*$/.test(input)) {
            setSalePrice(input)

        }
     
    }
    const handleTypeCalc = (e: number) => {
        setCalcType(e)
    }
    const handlePallet = (e: number) => {
        const delta = Math.round(palletDict[e].Tare_Weight - palletDict[palletID].Tare_Weight)
        setTareWeight(Math.round(palletDict[e].Tare_Weight))
        setWeightBrutto(prev => prev + delta)
        setPalletID(e)
    }
    return (
        <MainWrapper>
            <ImageWrapper>
                <ProductImage id={data.GeneralProductID} />
            </ImageWrapper>
            <TitleLayout>
                {/* <TitleRow>
                    <TitleRowItem name={`מגדל - ${data.GrowerName?.trim()}`} icon={<UserIcon />} />
                </TitleRow> */}
                <TitleRow>
                    <SelectWrapper>
                        <SelectGrower 
                            defaultGrower={growerID}
                            setGrowerID={setGrowerID}
                            growers={growers}
                            disabled={isDisableEdit}
                        />
                        מגדל
                    </SelectWrapper>
                </TitleRow>
                <TitleRow>
                    <SelectWrapper>
                        <SelectPallet 
                            defaultType={palletID}
                            setPalletID={handlePallet}
                            pallets={pallets}
                            disabled={isDisableEdit}

                        />
                        סוג משטח
                    </SelectWrapper>
                </TitleRow>
                {/* <TitleRow>
                    <CheckBoxWithTitle
                        title="כולל טרת משטח"
                        defaultState={isNeedtoCalcPallet}
                        stateFunc={setIsNeedtoCalcPallet}
                    />
                </TitleRow> */}
                <TitleRow>
                    <SelectWrapper>
                        <TypeCalcSelect 
                            defaultType={data.Type_SaleCalculation}
                            handleSelect={handleTypeCalc}
                            disabled={isDisableEdit}

                        />
                        סוג חישוב
                    </SelectWrapper>
                </TitleRow>
                <TitleRow>
                    <RowInput
                        handleInput={handleQuantity}
                        icon={<PackageIcon />}
                        bgColor='#fff'
                        isDisabled={isDisableEdit}
                        value={quantity}
                        iconColor='#297F76'
                        
                    />
                    <TitleRowItem name={`${data.Pack_Name?.trim()}`} icon={<></>} />
                </TitleRow>
                {
                    !isWorker &&
                        <TitleRow>
                            <RowInput
                                handleInput={handlePrice}
                                icon={<ShekelIcon />}
                                bgColor='#fff'
                                isDisabled={isDisableEdit}
                                value={salePrice}
                                iconColor='#297F76'
                            />
                            <TitleRowItem name={`מחיר`} icon={<></>} />
                        </TitleRow>

                }
                <TitleRow>
                    {
                        tareWeight > 0 &&
                        <CheckBoxWithTitle
                            title="כולל טרת משטח"
                            defaultState={isNeedtoCalcPallet}
                            stateFunc={setIsNeedtoCalcPallet}
                            
                        />

                    }
                    <RowInput
                        handleInput={handleWeight}
                        icon={<ScalesIcon />}
                        bgColor='#fff'
                        isDisabled={isDisableEdit}
                        value={weightBrutto}
                        iconColor='#297F76'
                        width={5}
                    />
                    <TitleRowItem name={`משקל`} icon={<></>} />
                </TitleRow>
                {/* <TitleRow>
                    <TitleRowItem name={`${data.Quantity_Pallets} - ${data.Pallete_Desc?.trim()}`} icon={<PalletIcon />} />
                </TitleRow> */}
                {/* <TitleRow>
                    <TitleRowItem name={`משקל נטו - ${data.WeightNeto.toFixed(2)}`} icon={<ScalesIcon />} />
                </TitleRow> */}
            </TitleLayout>
            <ButtonWrapper>
                {
                    data.InvoiceSale === 0 &&
                    <ControlButton 
                        lable={'עדכן'}
                        handleClick={handleUpdateProduct}
                    />
                }
                
            </ButtonWrapper>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 4em;
`

const ImageWrapper = styled.div`
    width: 100%;
`

const ButtonWrapper = styled.div`
    /* margin-top: 3em; */
`

const SelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.1em;
    color: #7D7DA4;
    gap: 1em;
`

export default ProductModal