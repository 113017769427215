import { requests } from "../config";
import { IReportQ10GETData, IReportQ10Response } from "./models/q10";
import { IReportQ4 } from "./models/q4";
import { IReportQ8 } from "./models/q8";

export const Reports = {
    getReportList: (data: string | number) => requests.get(`report/report_list?ReportType=${data}`, 'go'),
    getReportQ4: (data: IReportQ4) => requests.get(
        `report/q4?FromDate=${data.FromDate}&ToDate=${data.ToDate}&RepID=${data.RepID}&PriceType=0&InvoiceType=0`, 
        'go'
    ),
    getReportQ2: (data: IReportQ4) => requests.get(
        `report/q2?FromDate=${data.FromDate}&ToDate=${data.ToDate}&RepID=${data.RepID}&WeightType=0&WithInvoince=0`, 
        'go'
    ),
    getReportQ8: (data: IReportQ8) => requests.get(`report/q8?ClientID=${data.ClientID}&DateFrom=${data.DateFrom}&DateTo=${data.DateTo}`, 'go'),
    getReportQ10: (data: IReportQ10GETData): Promise<IReportQ10Response> => requests.get(`report/q10?Date1=${data.Date1}&Date2=${data.Date2}&RepID=${data.RepID}&OrderType=${data.OrderType}&LineType=${data.LineType}&Status=${data.Status}`, 'go')
}