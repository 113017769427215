import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { PackageIcon, PalletIcon } from "../../../../../../generic/icons/Icons";
import TitlesList from "../../../../../../generic/titlesRowItem/TitlesList";
import { IPackTypeItem } from "../../../../../../modules/API/Packages/models/mlaiTypes";
import ArizotTypeItem from "./ArizotTypeItem";

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  /* background: blue; */

  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${props => 
        props.active ? 
            `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
            :
            `
            
            `
  }
`;


interface Props {
  list: IPackTypeItem[]  | undefined;
  props: any,
  disableInput?: boolean
}

const ArizotContentList = ({ list, props, disableInput }: Props) => {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  
  const render = useMemo(() => {
    if (!list) return
    if (activeTab) {
        return list.filter((item) => item.PD_Type === activeTab);
      } else {
        return list;
      }
  }, [activeTab, list])
  

  const uniqueTypes = Array.from(new Set(list?.map((item) => item.PD_Type)));
//   console.log(uniqueTypes)
  const addictionalData = {
    ...props,
    disableInput: disableInput
  }
  return (
    <Container>
      <TabContainer>
        {uniqueTypes.map((type) => (
          <Tab
            key={type}
            active={activeTab === type}
            onClick={() => handleTabClick(type)}
          >
            {type === 'מ' ? <PalletIcon /> : <PackageIcon />}
          </Tab>
        ))}
      </TabContainer>
      <TitlesList 
        list={render}
        uniqueKey={'id'}
        Element={ArizotTypeItem}
        noPadding={false}
        borderLess={true}
        additionalData={addictionalData}
      />
    </Container>
  );
};

export default ArizotContentList;



