import styled from "styled-components";


const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.3em 0.5em;
    :hover{
        background: #80e081;
    }
`

export default function ArizotCarItem({CarNum, DriverName, setInputRide, setWrapperStatus, setCarId, setCarNumber, setDriverName, CarID}){
    const onClick = (e) => {
        setInputRide(e.target.value)
    }
    return(
        <Wrapper onClick={() => {setInputRide(CarNum + ' ' + DriverName.trim()); setWrapperStatus(false); setCarId(CarID); setCarNumber(CarNum); setDriverName(DriverName.trim())}}>
           {CarNum} {DriverName.trim()}
        </Wrapper>
    )
}