import { useEffect, useState } from 'react';

interface FetchFunction<T> {
  (date: string): Promise<T>;
}

interface DataFetcherResult<T> {
  data: T | null;
  isLoading: boolean;
  error: string | null;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
  errorCode: number | null;
}

function useDataFetcher<T>(fetchFunc: FetchFunction<T>, date: string, isConcat?: boolean, ...rest: any): DataFetcherResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  // console.log(date)
  useEffect(() => {
    if(!date) return
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      setErrorCode(null);
      try {
        const result = await fetchFunc(date);
        if(isConcat) {
          setData((prev: any) => {
            if(!prev) return result
            return prev.concat(result);
          })
          return
        }
        setData(result);
      } catch (err: any) {
        setError(err.response?.data?.error || err.message);
        setErrorCode(err.response?.status);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fetchFunc, date, isConcat, ...rest]);

  return { data, isLoading, error, setData, errorCode};
}

export default useDataFetcher;
