import React from 'react'
import styled from 'styled-components'
import bg from '../icons/no-inet.gif';
const MainWrapper = styled.div`
    z-index: 99;
    background: rgba(255,255,255,0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    display: ${props => props.isOnline ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;

`
const ImgWrapper = styled.div`
    height: 100%;
    width: 100%;
    /* background: url('/images/no-inet.gif') center center no-repeat; */
    /* background: tomato; */
    color: #fff;
    font-size: 1.5em;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Btn = styled.div`
    position: absolute;
    bottom: 2em;
    left: calc(50% - 75px);
    width: 150px;
    padding: 1em;
    background: tomato;
    color: #fff;
    border-radius: 30px;
    font-size: 1.1em;
    font-weight: 600;
    z-index: 100;
`
const Img = styled.img`
    min-width: 500px;
    
`
export default function IfDisconnect({status, action}) {
  return (
    <MainWrapper isOnline={status}>
        <ImgWrapper>
            <Img  src={bg} alt='no-internet' /> 
           
        </ImgWrapper>
        <Btn onClick={action}>לטעון מחדש</Btn>
    </MainWrapper>
  )
}
