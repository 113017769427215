import React, { useState } from 'react'
import styled from 'styled-components'
import Image from '../../../../../generic/assets/collect.png'
import OpenCloseButton from '../../../../../generic/particles/OpenCloseButton'
type Props = {
    Collect_Comm: string,
    closeFunc: () => void,
    readOnly: boolean | undefined,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    saveFunc: (item: string) => void
}

function LikutRemarkModal({Collect_Comm, closeFunc, readOnly, saveFunc}: Props) {
    
    const [input, setInput] = useState(Collect_Comm || '')
    const isDisable = readOnly ? true : false
    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput(e.target.value)
    }
    console.log(input)
  return (
    <MainWrapper>
        <OpenCloseButton close func={closeFunc} />
        {
            !isDisable &&
                <OpenCloseButton func={() => {saveFunc(input)}} />
        }
        <Img
            src={Image}
            alt='order gathering'
        />
        <TextField 
            onChange={handleOnChange}
            cols={30}
            rows={15}
            readOnly={isDisable}
            defaultValue={input}
        />
    </MainWrapper>
  )
}

export default LikutRemarkModal

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

`
const Img = styled.img`
    min-width: 300px;
`

const TextField = styled.textarea`
    padding: 1em 0.5em;
    letter-spacing: 0.5px;
    border-radius: 30px;
    font-size: 1.2em;
    direction: rtl;
    border: 2px solid #7ab0ab;
    outline: none;
    ::-webkit-scrollbar {
        width: 0
    }
    :disabled {
        background: #ffff;
    }
`
