import React, {useState, useMemo} from 'react'
import { ISellerItem } from '../../../../../../modules/API/Defaults/models/seller'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultID: number
  setSellerID: React.Dispatch<React.SetStateAction<number>>,
  required?: boolean
  list: ISellerItem[]
}

const SearchSeller = ({
    list, defaultID, required, setSellerID
}: Props) => {
  const defaultSeller = list.filter(item => item.SellerID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedSeller, setSelectedSeller] = useState<ISellerItem | null>(defaultSeller || null)
  const [search, setSearch] = useState<string>(defaultSeller?.Seller_Desc?.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedSeller(null)
      setSellerID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: ISellerItem) => {
    setSellerID(item.SellerID)
    setSearch(item.Seller_Desc.trim())
    setSelectedSeller(item)
    setIsOpenDropDown(false)
  }

  const render = useMemo(() => {
      if(!list) return []
      return list.filter(item => 
        item.Seller_Desc?.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
  }, [list, search])


  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={'חפש מוכר'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='Seller_Desc'
      nameIdElement='SellerID'
      required={required}
      size={'full'}
    
    />
  )
}

export default SearchSeller