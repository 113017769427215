import React from 'react'
import styled from 'styled-components'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'

type Props = {
  children: React.ReactNode | React.ReactNode[]
  title?: string
}

const SideBarProductListTayout = ({
  children,
  title='קטלוג מוצרים'
}: Props) => {
  return (
    <SideListWrapper>
        <SideListTitle>
          <TitleRowItem
            name={title}
            icon={<></>}
          />
        </SideListTitle>
        <SideListBody>
          {children}
        </SideListBody>
      </SideListWrapper>
  )
}
const SideListWrapper = styled.div`
  height: 100%;
  min-width: 350px;
  border-radius: 10px 30px 30px 10px;
  background: #fff;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const SideListTitle = styled.div`
  width: 100%;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SideListBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.2em;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default SideBarProductListTayout