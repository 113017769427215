import { SELECT_MARKETING_AREA } from "../actions/actionsTypes"

const initialState = {
    SelectedMarketingArea: null
}

const truckReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_MARKETING_AREA:
            return {
                ...state,
                SelectedMarketingArea: action.payload
            }
        default: return state
    }
}

export default truckReducer