import { IChangeKavData } from "../../../../../modules/API/Distribution/models/fetchData"
import { IOrderItem } from "../../../../../modules/API/Drivers/models/kavData"

export const helperFormatSourceSelectedItemForDrag = (array: IOrderItem[]): IOrderItem[] => {
    return array.filter(item => item.Selected).map(order => {
        order = {
            ...order,
            PalletsList: order.PalletsList.filter(pallet => pallet.Selected).map(e => {
                e = {
                    ...e,
                    Selected: false
                    
                }
                return e
            }),
            Selected: false
        }
        return order
       
    })
  }
  export const helperFormatSourceSelectedItemForRest = (array: IOrderItem[]): IOrderItem[] => {
    return array.map(order => {
        if(!order.Selected) return order
        order = {
            ...order,
            PalletsList: order.PalletsList.filter(e => !e.Selected),
        }
        return order
    }).filter(e => !e.Selected || e.PalletsList.length > 0).map(item => {
        return {
            ...item,
            Selected: false
        }
    })
  }

  export const halperMergeOrders = (orderListSource: IOrderItem[], orderListIncom: IOrderItem[]) => {
     
    const reduced = orderListSource.reduce((acc: Record<number, IOrderItem>, cur) => {
       acc[cur.OrderID] = cur
       return acc
    }, {})
    orderListIncom.forEach(order => {
       if(reduced[order.OrderID]) {
           reduced[order.OrderID] = {
               ...reduced[order.OrderID],
               PalletsList: reduced[order.OrderID].PalletsList.concat(order.PalletsList)
           }
       } else {
           reduced[order.OrderID] = order
       }
    })
    return Object.keys(reduced).map(key => {
       return reduced[+key]
    })
 }

 export const helperApiDataCollector = (data: IChangeKavData, ref: IOrderItem[]) => {
    let copy = data;
    ref.forEach((order) => {
      copy.Orders.push({
        OrderID: order.OrderID,
        Pallets: [...order.PalletsList.map((pallet) => pallet.Pallet_Num)],
        Type: order.Order_Type === 1 ? "Order" : "OrderDetail",
      });
    });

    return copy;
  };
  