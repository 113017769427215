import React from 'react'
import './button.css'
import {

    // Redirect,
    // withRouter,
    Link
   
} from "react-router-dom"

function Button() {
    return (
        <div className="header-sign button-text-in">
            <div className="">
                <Link to="/login" className="header-button header-button--gooey " onClick={() => {
                    // const modal = document.getElementById('modalAction');
                    //     //Opening modal
                    //     const getWindow = function() {
                    //         // console.log('1')
                    //         modal.style.display='block'
                    //         }
                    //         //Close modal on side click
                    //         window.onclick = function(event) {
                    //             if (event.target === modal) {
                    //             modal.style.display = "none";
                    //         }
                    //     }
                    //     getWindow()
                        }
                    }
                    >
                כניסה
                    <div className="header-button__blobs">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default Button;