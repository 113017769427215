
import { Defaults } from '../API/Defaults/defaults';
import {GET_DEFAULTS} from '../API/settings'

export const loadDefaults  = async (getToken, LOAD_DEFAULTS,TABLE_OF_TABLES, dispatch) => {
    const axios = require('axios')
    const CancelToken = axios.CancelToken.source();
    try {
        let response = await Defaults.getDefaults()
        dispatch({ type: LOAD_DEFAULTS, payload: response.defaults})
        dispatch({ type: TABLE_OF_TABLES, payload: response.tableOfTables})
    } catch(err){
        console.log(err.response.data.message)
    }
    
}