import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { IBankItem } from '../../../../../../../modules/API/Defaults/models/banks'
import { ITableOfTableItem } from '../../../../../../../modules/API/Defaults/models/tableOfTables'
import { IPaymentListItem } from '../ReceiptPaymentLine'
import { IPaymentDetailesListItem } from '../ReceiptCreate'
import SearchFromTheList from '../../../../../../../generic/customSearchComponents/SearchFromTheList'
import SelectFromTheList from '../../../../../../../generic/customSearchComponents/SelectFromTheList'
import DatePickerModule from '../../../../../../../generic/datePicker/datePicker'
import { ShekelIcon } from '../../../../../../../generic/icons/Icons'
import selectOnFocus from '../../../../../../../modules/autoselect'
import { PaymentType } from '../../../generic/getDetailesByPaymentType'
import { IClientItem } from '../../../../../../../modules/API/Defaults/models/clients'
import { convertToDate } from '../../../../../../../modules/convertDateString'

type Props = {
  handleModal: () => void,
  bankListTransfer: IBankItem[],
  bankListChequesCards: ITableOfTableItem[],
  paymentsType: IPaymentListItem[],
  addNewPaymentLine: (item: IPaymentDetailesListItem) => void,
  selectedClient: IClientItem,
  defDate: string
  patchPaymentLine: (index: number, paymentLine: IPaymentDetailesListItem) => void
}

const AddlineModal = ({
  handleModal, bankListChequesCards,
  bankListTransfer, paymentsType,
  addNewPaymentLine, patchPaymentLine,
  selectedClient, defDate
}: Props) => {
  // console.log(convertToDate(defDate))
  const dateProps = convertToDate(defDate).split('/')
  console.log(dateProps)
  console.log(`20${dateProps[2]}`)
  console.log(`20${dateProps[2]}`, +dateProps[1] - 1, 2024)
  const [startData, setStartData] = useState<string>(defDate)
  const [paymentType, setPaymentType] = useState<PaymentType>('מ')
  const [bankId, setBankId] = useState<number>(0)
  const [sum, setSum] = useState<number>(0)
  const [chequeNum, setChequeNum] = useState<number>(0)
  const [branch, setBranch] = useState<number>(0)
  const [account, setAccount] = useState<number>(0)
  const [remarks, setRemarks] = useState<string>("")

  const handleChangePaymentType = (e: PaymentType) => {
    setPaymentType(e)
    setBankId(0)
    setChequeNum(0)
    setBranch(0)
    setAccount(0)
  }
  const handleChangeTransferBank = (e: string) => {
    setBankId(Number(e))
  }
  const handleChangeChequeCard = (e: string) => {
    setBankId(Number(e))
  }
  const handleChangeChequeNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChequeNum(Number(e.target.value))
  }
  const handleChangeBranch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBranch(Number(e.target.value))
  }
  const handleChangeAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccount(Number(e.target.value))
  }
  const handleChangeSum = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    console.log('trigger')
    setSum(Number(e.target.value))
  }

  const handleSave = () => {
    const item: IPaymentDetailesListItem = {
      Sum: sum,
      PaymentType: paymentType,
      BankId: bankId,
      Branch: branch,
      Account: account,
      Cheque: chequeNum,
      ValueDate: startData,
      Comments: remarks
    }
    if(paymentType === 'ה' || paymentType === 'כ') {
      if (!bankId) return
    }
    if(paymentType === 'ש') {
      if (!bankId || !branch || !account || !chequeNum) return
    }
    addNewPaymentLine(item)
    handleModal()
  }
  useEffect(() => {
    if (paymentType === 'ש') {
      setBankId(selectedClient.BankCode)
      setBranch(selectedClient.BankBranch)
      setAccount(selectedClient.AccountNumber)
      handleChangeAccount({ target: { value: selectedClient.AccountNumber.toString() } } as React.ChangeEvent<HTMLInputElement>)
      handleChangeBranch({ target: { value: selectedClient.BankBranch.toString() } } as React.ChangeEvent<HTMLInputElement>)
      handleChangeChequeCard(selectedClient.BankCode.toString())
    }
  }, [paymentType, selectedClient])
  return (
    <MainWrapper>
      <ContentWrapper>
        {/* <SelectorWrapper>
          <Title>
            בחר תהריך תשלום     
          </Title>
            
        </SelectorWrapper> */}
        <SelectorWrapper>
          <Title>
            <p>
            אופן תשלום
            </p>
            <p>
              תאריך פרעון
            </p>
          </Title>
          <SelectItemWrapper>
            <DatePickerModule
              setSelectedDateStart={setStartData}
              defEndDate={false}
              selected={new Date(+`20${dateProps[2]}`, +dateProps[1] - 1, +dateProps[0])}
              ignorBrod={true}
              disabled={false}
              large
            />
            <SelectFromTheList
              size={'full'}
              required
              lable={'- בחר -'}
              list={paymentsType}
              selectFunction={handleChangePaymentType}
              dispalyFieldName='name'
              fieldValue='code'
              itemKey='code'
              tabIndex={1}
              selectedValue={paymentType}
            />
          </SelectItemWrapper>
        </SelectorWrapper>
        {
          paymentType === "ה" || paymentType === 'כ' ?
            <SelectorWrapper>
              <Title>
                חשבון בנק
              </Title>
              <SelectFromTheList
                tabIndex={2}
                size={'full'}
                required
                lable={'- בחר -'}
                list={bankListTransfer}
                selectFunction={handleChangeTransferBank}
                dispalyFieldName='BankName'
                fieldValue='BankId'
                itemKey='BankId'
                selectedValue={bankId}
              />
            </SelectorWrapper> :
            paymentType === "ש" ?
              <>
                <SelectorWrapper>
                  <Title>
                    בנק
                  </Title>
                  <SelectFromTheList
                    tabIndex={3}
                    size={'full'}
                    required
                    lable={'- בחר -'}
                    list={bankListChequesCards}
                    selectFunction={handleChangeChequeCard}
                    dispalyFieldName='Description'
                    fieldValue='SecondCode'
                    itemKey='SecondCode'
                    selectedValue={bankId}
                    dispalyFieldNameSecond={'SecondCode'}
                  />
                  {/* <Select
                    childred={<OptionListCheque list={bankListChequesCards} />}
                    handleChange={handleChangeChequeCard}
                    defaultValue={BankId?.toString() || ''}
                  /> 
                  <Input
                    type='number'
                    placeholder='משפר השיק'
                    onChange={handleChangeChequeNum}
                  />
                  <Input
                    type='number'
                    placeholder='סניף'
                    onChange={handleChangeBranch}
                  />
                  <Input
                    type='number'
                    placeholder='חשבון'
                    onChange={handleChangeAccount}
                  /> */}
                </SelectorWrapper>
                <SelectorWrapper>

                  <Title>
                    <p>
                      סניף
                    </p>
                    <p>
                      חשבון
                    </p>
                  </Title>
                  <SelectItemWrapper>
                    <Input
                      defaultValue={selectedClient.AccountNumber || ''}
                      type='number'
                      placeholder='חשבון'
                      onChange={handleChangeAccount}
                      tabIndex={5}
                      onWheel={(e) => e.currentTarget.blur()}

                    // value={selectedClient.AccountNumber || ''}
                    />
                    <Input
                      defaultValue={selectedClient.BankBranch || ''}
                      tabIndex={4}
                      type='number'
                      placeholder='סניף'
                      onChange={handleChangeBranch}
                      onWheel={(e) => e.currentTarget.blur()}
                      
                    // value={selectedClient.BankBranch || ''}
                    />

                  </SelectItemWrapper>
                  <SelectorWrapper>
                    <Title>
                      מספר שיק
                    </Title>
                    <Input
                      type='number'
                      placeholder='מספר שיק'
                      onChange={handleChangeChequeNum}
                      tabIndex={6}
                      onWheel={(e) => e.currentTarget.blur()}
                      

                    />
                  </SelectorWrapper>
                </SelectorWrapper>
              </> : ''
        }
        {
          paymentType &&
          <SelectorWrapper>
            <SideIcon>
              <ShekelIcon />
            </SideIcon>
            <Title>
              סכום
            </Title>
            <Input
              type='number'
              placeholder='סכום'
              onChange={handleChangeSum}
              onClick={selectOnFocus}
              tabIndex={7}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </SelectorWrapper>
        }
        <TextAreaWrapper>
          <Title>
            הערות
          </Title>
          <TextArea
            placeholder='הערות'
            tabIndex={8}
            onChange={(e) => setRemarks(e.target.value)}
          />

        </TextAreaWrapper>
        
      </ContentWrapper>
      {
        paymentType && sum ?
          <ControlButton
            lable={'שמור'}
            tabIndex={9}
            handleClick={handleSave}
          /> : ''
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;


`
const ContentWrapper = styled.div`
  display: flex;
  width: 90%;
  height: 100%;
  flex-flow: column;
  gap: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const SelectorWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  background: rgb(233,240,240, 0.2)
`

const SelectItemWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  justify-content: space-between;
`

const Input = styled.input`
  width: 100%;
  height: 3em;
  border-radius: 30px;
  background: #EFF3F4;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1.2em;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
`

const Title = styled.div`
  text-align: end;
  color: #297F76;
  font-weight: 600;
  padding: 0 0.5em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse
`
const SideIcon = styled.div`
  position: absolute;
  bottom: 1.3em;
  left: 1em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7ab0ab;

`
const TextAreaWrapper = styled.div`
  background: #FBFCFC;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  color: #297F76;

`
const TextArea = styled.textarea`
  appearance: none;
  border-radius: 30px;
  border: none;
  width: 100%;
  background: #EFF3F4;
  padding: 0.5em 1em;
  font-size: 1.1em;
  font-family: 'Assistant', sans-serif;
  outline: none;
  direction: rtl;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default AddlineModal