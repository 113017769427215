import React, {useImperativeHandle, useRef, forwardRef, MutableRefObject} from 'react'
import styled from 'styled-components'
import Title from '../Title/Title'

type Props = {
    children: React.ReactNode ,
    label: string,
    titleColor?: string,
    isDisableDecorator?: boolean,
}

const OrderlikeLayout = forwardRef<MutableRefObject<HTMLDivElement | null>, Props>(({
    children, label, titleColor, isDisableDecorator
}: Props, ref) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => divRef);
  return (
    <SectionMain ref={divRef}>
        <MainWrapper>
            {
                !isDisableDecorator && <Decorator />
            }
            <Title label={label} color={titleColor} />
            {children}
        </MainWrapper>
    </SectionMain>
  )
})

export default OrderlikeLayout

export const SectionMain = styled.div`
    position: fixed;
    background: #e9f0f0;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    @media screen and (min-width: 770px) {
        position: relative;
    }
    @media screen and (max-width: 400px) {
        padding: 0;
    }
    @media screen and (max-height: 500px) {
        position: relative;
        min-height: 1000px;
        overflow-y: scroll;
    }
    
`
export const MainWrapper = styled.div`
    position: relative;
    max-width: 1000px;
    height: 100%;
    background: #e9f0f0;
    border-radius: 30px;
    margin: 0 auto;
    padding: 0 0.5em 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    overflow: hidden;
    @media screen and (max-width: 400px) {
        border-radius: 0;
    }
    
`
export const Decorator = styled.div`
    position: absolute;
    top: -50em;
    right: -15em;
    height: 1000px;
    width: 1500px;
    /* z-index: 0; */
    border-radius: 40%;
    background: ${props => props.color ? props.color : 'rgba(76,148,141, 0.7)'};
    @media screen and (max-width: 550px) {
        top: -40em;
        right: -15em;
        height: 800px;
        width: 1000px;
    }
    
`
    