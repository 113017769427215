import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { SELECT_SORTING_DATA } from '../../../../../store/actions/actionsTypes'
import { AppleIcon, BarCodeIcon, CalendatDay, EditIcon, InvoiceIcon, PackageIcon, ScalesIcon, UserIcon } from '../../../../generic/icons/Icons'
import { ISortingListItem } from '../../../../modules/API/Miun/models/sortingList'
import { LINKS } from '../../../../Routs/config'
import { convertToDate } from '../../../../modules/convertDateString'

interface Props extends ISortingListItem {
    item: ISortingListItem
}

const MiunSortingItem = ({
    VarietyDesc, ProductDesc, GrowerName, Entries, item,
    Sorting_Num, Entry_WeightNeto, Date_Sorting, Entry_Qty, Lines,
    Entry_WeightBruto, Sorted_WeightNeto,
}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const isEntriesFilled = Lines.length > 0
    const handleOpen = () => {
        setIsOpen(!isOpen);
    }
    const handleLink = (e: React.MouseEvent) => {
        e.stopPropagation();
        history.push(LINKS.miun.control.create)
        dispatch({type: SELECT_SORTING_DATA, payload: item})
    }
    const convertedDate = convertToDate(Date_Sorting || '00000000')
    const Description = ` ${ProductDesc.trim()} ${VarietyDesc.trim()}`
    const descProd = 
        Description && Description?.trim().length > 9 ? 
        '..' + Description?.trim().slice(0, 9) : Description?.trim()
    const descGrower = 
        GrowerName && GrowerName?.trim().length > 9 ? 
        '..' + GrowerName?.trim().slice(0, 9) : GrowerName?.trim()
  return (
    <MainWrapper onClick={handleOpen}>
        <TitleWrapper>
            <QtyWrapper
                onClick={handleLink}
            >
                 <EditIcon /> 
            </QtyWrapper>
            <TitleRow>
                <TitleItem>
                    {convertedDate}
                    <CalendatDay />
                </TitleItem>
                <TitleItem>
                    {Sorting_Num}
                    <InvoiceIcon />
                </TitleItem>
                <TitleItem isProd>
                    {descGrower}
                    <UserIcon />
                </TitleItem>
            </TitleRow>
            <TitleRow>
                
                <TitleItem>
                    {Entry_Qty}
                    <PackageIcon />
                </TitleItem>
                <TitleItem isProd>
                    {descProd}
                    <AppleIcon />
                </TitleItem>
            </TitleRow>
            <TitleRow>
                <TitleItem isProd>
                    {`${Sorted_WeightNeto} ממוין`}  
                </TitleItem>
                <TitleItem isProd>
                    {`${Entry_WeightNeto} נטו`} <ScalesIcon />
                </TitleItem>
            </TitleRow>
        </TitleWrapper>
        {
            isOpen && isEntriesFilled &&
                <BodyWrapper >
                    {
                        Lines.map((line, index) => 
                            <BodyItemWrapper>
                                <SideWrapper>
                                    <PercentWrapper>
                                        {Math.round((line.WeightNeto / item.Sorted_WeightNeto) * 100)}%
                                    </PercentWrapper>
                                </SideWrapper>
                                <BodyItemRow>
                                    <BodyItem>
                                        {`${line.Qty} ${line.QualityDesc.trim()} :${line.SizeDesc.trim()}`}                                      
                                        <PackageIcon />
                                    </BodyItem>
                                </BodyItemRow>
                                <BodyItemRow>
                                    <BodyItem>
                                        {Math.round(line.WeightNeto)} -
                                        <ScalesIcon />
                                    </BodyItem>
                                    <BodyItem>
                                        {line.Pack_Name.trim()}
                                        <PackageIcon />
                                    </BodyItem>
                                </BodyItemRow>
                            </BodyItemWrapper>
                        )
                    }
                </BodyWrapper>
        }
    </MainWrapper>
  )
}
const SideWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 3em;
    height: 3em;
    background: #7ab0ab;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

`

const PercentWrapper = styled.div`
    width: 3.5em;
    height: 3.5em;
    padding: 0.5em;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
`
const QtyWrapper = styled.div`
    position: absolute;
    top: -0.2em;
    left: -0.5em;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7ab0ab;
    background: none;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    :hover{
        color: tomato;
        i {
            
            color: tomato;
        }
    }
    /* -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%); */
`
const MainWrapper = styled.div`
    min-height: auto;
    width: 100%;
    background: #eff3f4;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    gap: 1em;
    padding: 0.5em 1em;
    color: #838484;
    i {
        color: #7ab0ab;
    }
    
`
const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`
const TitleRow = styled.div`
    display: flex;
    justify-content: flex-end;
    
`
const TitleItem = styled.div<{isProd?: boolean}>`
    display: flex;
    gap: 0.5em;
    width: ${props => props.isProd ? '8em' : '3.5em'};
    justify-content: flex-end;
`
const BodyWrapper = styled.div<{isOpen?: boolean}>`
    /* min-height: 50em; */
    display: flex;
    flex-flow: column;
    gap: 0.5em;

    padding: 0.5em;
    background: #fff;
    border-radius: 20px;

    overflow: hidden;
`
const BodyItemWrapper = styled.div`
    position: relative;

    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    border-bottom: 1px solid gray;
    padding: 0.5em;
    /* :last-child {
        border-bottom: none;
    } */
`
const BodyItemRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`
const BodyItem = styled.div`
    display: flex;
    gap: 0.5em;
`

export default MiunSortingItem