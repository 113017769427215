import React from 'react'
import styled from 'styled-components'
import WorkersSelectItem from './WorkersSelectItem'

export const Wrapper = styled.div`
    /* background: red; */
    width: 11em;
    height: 4em;
    border-radius: 30px;
    position: relative;
    padding: 0.5em;
`
export const Select = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    outline: none;
    border: none;
    font-size: 1em;
    background: #eff3f4;
    padding: 0 1em;
    appearance: none;
    direction: rtl;
    cursor: pointer;
    color: #297f76;
`
export default function WorkersSelectList({workerGroup, filterWorkers}) {
    return (
        <Wrapper>
            <Select onChange={(e) => {filterWorkers(e.target.value)}}>
                <option value='' >קבוצת ליקוט - הכל</option>
                {workerGroup.map(item => <WorkersSelectItem key={item.SecondCode} {...item} />)}
            </Select>
        </Wrapper>
    )
}
