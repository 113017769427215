import React, { FC } from 'react'
import styled from 'styled-components'
import { MarketingAreaObjectP } from '../../../../../models/tableOfTables/marketingArea'
import { ITableOfTableItem } from '../../../../modules/API/Defaults/models/tableOfTables'

export type DestinationItemProps = {
    SecondCode?: number,
    controlFunc: (item: ITableOfTableItem | null, clear?: boolean) => void,
    item: ITableOfTableItem,
    Description?: string,
    selectedDistricts: ITableOfTableItem[]
}
type RowWrapperP = {
    isSelected: boolean
}
export const RowWrapper = styled.div<RowWrapperP>`
    cursor: pointer;
    background: ${props => props.isSelected ? '#297f76' : ''};
    color: ${props => props.isSelected ? '#fff' : ''};
    width: 100%;
    padding: 0.2em;
    display: flex;
    justify-content: flex-end;
`
const DestinationItem: FC<DestinationItemProps> = ({SecondCode, controlFunc, item, Description, selectedDistricts}) => {
    const found = selectedDistricts.some(e => e.SecondCode === SecondCode)
  return (
    <RowWrapper isSelected={found} onClick={():void => {controlFunc(item)}}>
       {Description} 
    </RowWrapper>
  )
}

export default DestinationItem