import { useContext } from "react";
import { useState } from "react";
import styled from "styled-components";
import { ContextModal } from '../../../../../../modules/modal/modalContext'
import RemarkButton from "../remark/remarkButton";

const Wrapper = styled.div`
    position: fixed;
    width: 6em;
    height: 10em;
    /* background: red; */
    z-index: 2;
    right: 0;
    top: 15em;
    display: flex;
    transform: translateX(50%);
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
`
const FlexContainer = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
`

const FlexItemConteiner = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    background: #80b3ae;
    font-size: 1.4em;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    
    i {
        color: #fff;
        cursor: pointer;
    }
`

const MoveButton = styled.div`
    /* background: blueviolet; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #297f76;
    cursor: pointer;
    i {
        transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        /* transform: rotate(180deg); */
        
    }
    
`


export default function FloatContainer({setPresetWindow, customerId, filterGoodsValue, setShowRemark, showRemark, remarkButtonRef, getPresetBrod, neededStatus}) {
    const [switcher, setSwitcher] = useState(false);
    const [modal, setModal] = useContext(ContextModal);

    const presetOpener = () => {
        if((filterGoodsValue !== '101' && filterGoodsValue !== null && customerId !== '')){
            setPresetWindow(true); 
            setSwitcher(false)
            console.log(filterGoodsValue)
        }
        else if (getPresetBrod && !neededStatus) {
            setPresetWindow(true); 
            setSwitcher(false)
            console.log(filterGoodsValue)
        }
        else {
            setModal({
                open: true,
                value: `בחר לקוח/קבוצה להמשך`,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
    }

    let positionToggle = switcher ? 'translateX(0)' : 'translateX(50%)';
    let buttonSwitcherToggle = switcher ? 'rotate(180deg)' : 'rotate(0)';

    return(
        <Wrapper style={{transform: positionToggle}}>
            <FlexContainer>
                    <MoveButton onClick={() => {setSwitcher(!switcher)}}>
                        <i style={{transform: buttonSwitcherToggle}} className="fas fa-chevron-left"></i>
                    </MoveButton>
            </FlexContainer>
            <FlexContainer>
                    <FlexItemConteiner>
                        <i onClick={() => {presetOpener()}} className="fas fa-layer-group"></i>
                        <RemarkButton
                            setShowRemark={setShowRemark}
                            showRemark={showRemark}
                            remarkButtonRef={remarkButtonRef}
                        />
                    </FlexItemConteiner>
            </FlexContainer>
        </Wrapper>
    );
}