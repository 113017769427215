import React from 'react'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead'

type Props = {
    img: string;
    size?: "small" | "large";
}

const ImageWrapper = ({img, size}: Props) => {

  return (
    <Wrapper>
        <ImageComponentHead
            img={img}
            large={size === "large" ? true : false}
        />
    </Wrapper>
  )
}
const Wrapper = styled.div`
    width: 100%;
    height: 35%;
`
export default ImageWrapper