import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CloseIcon } from '../../../../../generic/icons/Icons'
import { CloseWindow, InnerWrapper, MainWrapper, ImageWrapper, Img, BodyWrapper, Title } from './arizot/ArizotModal'
import PackageImage from '../../../../../generic/assets/package.png'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import history from '../../../../../../history'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import CheckArizotItem from './CheckArizotItem'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { CHECK_ATTEMPTS, IS_NEW_PALLET } from '../../../../../../store/actions/actionsTypes'
import AdminCheckField from './AdminCheckField'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { LINKS } from '../../../../../Routs/config'
import { useTranslation } from 'react-i18next'
const AWrapperMain = styled(InnerWrapper)`
    height: 100%;
    justify-content: flex-start;
    /* background: red; */
    overflow-y: auto;
    @media screen and (min-width: 500px) {
        height: 670px;
    }
`
const BodyWrapperA = styled(BodyWrapper)`
    min-height: 300px;
    /* overflow-y: auto; */
    justify-content: flex-start;
    gap: 0;
    margin-bottom: 1em;
    ::-webkit-scrollbar {
        width: 0;
    }
`

// const delta = 10

export default function CheckArizot({
    isOpenCheckArizot, setIsOpenCheckArizot, packQty, saveFunc, delta,
    saveCheckedPalletInfo, getPallet, likutInfo, artPass, palletDetails
}) {
    const [t] = useTranslation(['likut', 'buttons']);
    const [modal, setModal] = useContext(ContextModal);
    const dispatch = useDispatch();
    const arizotSet = packQty()
    const [arizotSetTemp, setArizotSetTemp] = useState({});
    const [weightInput, setWeightInput] = useState(0);
    const [adminPass, setAdminPass] = useState('');
    const attemptsLeft = 3 - (likutInfo?.checkAttempts && likutInfo?.checkAttempts[likutInfo?.pallet?.palletNum] ? likutInfo?.checkAttempts[likutInfo?.pallet?.palletNum] : 0)
    const givenWeight = palletDetails.reduce((acc, item) => {
        return acc + (item?.WeightBrutto || 0)
    }
    , 0)
    console.log(givenWeight)
    // alert(JSON.stringify(likutInfo?.checkAttempts))
    // console.log(likutInfo?.checkAttempts)
    // console.log(likutInfo?.checkAttempts[likutInfo?.pallet?.palletNum])
    // console.log(likutInfo?.pallet?.palletNum)
    // console.log(attemptsLeft)
    const calcAttempts = () => {
        let checkCollection = likutInfo.checkAttempts || {};
        let palletNumber = likutInfo?.pallet?.palletNum
        checkCollection[palletNumber] = (checkCollection[palletNumber] + 1) || 1
        return checkCollection
    }
   
    const updateSetTemp = (key, value) => {
        setArizotSetTemp({
            ...arizotSetTemp,
            [key]: value
        })
        
    }
    const check = () => {
        let keys = Object.keys(arizotSet)
        for(let i in keys) {
            // console.log(arizotSet[keys[i]] !== arizotSetTemp[keys[i]])
            if(arizotSet[keys[i]] !== arizotSetTemp[keys[i]]) return false
        }
        return true
    }

    const checkWeight = () => {
        const difference = Math.abs(givenWeight - weightInput)
        return delta ? !(difference > delta) : !(difference > 0)
    }
    console.log(checkWeight())
    const save = async () => {
        // console.log(arizotSet)
        // console.log(arizotSetTemp)
        if ( attemptsLeft > 0 ? delta ? checkWeight() : check() : adminPass === artPass) {
            setIsOpenCheckArizot(!isOpenCheckArizot);
            await saveCheckedPalletInfo([{RecID: 0, CheckComment: 'arizotSetTemp'}]);
            saveFunc();
            // dispatch({ type: IS_NEW_PALLET, payload: false });
            history.push(LINKS.likut.likutExperess.palletSection)
            console.log('yea')
        }
        else {
            if (attemptsLeft > 0){    
                dispatch({
                    type: CHECK_ATTEMPTS, 
                    payload: calcAttempts()
                })
            } else {
                setModal({
                    open: true,
                    value: `סיסמה שגויה`,
                    mood: false
                });
                setTimeout(  () =>{ 
                    setModal({
                        open: false,
                        value: ''
                    });
                }, 3000);
                return
            }             
            setModal({
                open: true,
                value: `כמות לא נכונה, נשאר עוד ${2 - (likutInfo.checkAttempts[likutInfo?.pallet?.palletNum] ? likutInfo.checkAttempts[likutInfo?.pallet?.palletNum] : 1)} נסיון`,
                mood: false
            });
            setTimeout(  () =>{ 
                setModal({
                    open: false,
                    value: ''
                });
            }, 3000);
        }
    }
  return (
    <MainWrapper style={{padding: '0.5em'}} open={isOpenCheckArizot}>
        <AWrapperMain>
            <CloseWindow onClick={() => {setIsOpenCheckArizot(!isOpenCheckArizot)}}>
                <CloseIcon />
            </CloseWindow>
            <ImageWrapper>
                <Img src={PackageImage} alt='check'/>
            </ImageWrapper>
            <Title style={{minHeight: '2em'}}>
                {t('likut_modal_checkpallet_title')}
            </Title>
            {
                delta ?
                <BodyWrapperA>
                    
                     {   
                        attemptsLeft > 0 ? 
                            <InputWparper>
                                    <Tag>
                                        {t('likut_modal_checkpallet_weight')}
                                    </Tag>
                                <SearchInput 
                                    iconHide
                                    value={'משקל'}
                                    input={weightInput}
                                    setInput={setWeightInput}
                                    // isDisableFocusSelect={false}
                                    type={'number'}
                                />
                            </InputWparper>
                                :
                                    <AdminCheckField
                                        setHandlePass={setAdminPass}
                                    />
                
                    }
                </BodyWrapperA> :
                <BodyWrapperA>
                    {   
                        attemptsLeft > 0 ? 
                            Object.keys(arizotSet).map(item =>
                                <CheckArizotItem key={item} desc={item} qty={arizotSet[item]} func={updateSetTemp}  />
                            )
                                :
                                    <AdminCheckField
                                        setHandlePass={setAdminPass}
                                    />
                
                    }
                </BodyWrapperA>
            }
            <ControlButton
                lable={t('save', {ns: 'buttons'})}
                handleClick={save}
            />
        </AWrapperMain>
    </MainWrapper>
  )
}
const InputWparper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap:0.2em;
`
const Tag = styled.div`
    width: 100%;
    display: flex;
    padding: 0 0.5em;
    justify-content: flex-end;
    color: #868787;
`
