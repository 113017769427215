import styled from "styled-components";
import {convertToDate} from '../../../../../../modules/convertDateString'

const Wrapper = styled.option`
    /* padding: 1em; */

`

export default function PresetsItem({OrderID, Reg_Date}) {

    return(
        <Wrapper value={OrderID}>{`${OrderID} ${convertToDate(Reg_Date)}`}</Wrapper>
    );
}