import React from 'react'
import styled from 'styled-components'
import { ArrowIcon, InvoiceIcon, PalletIcon, SpeedIcon, UserIcon } from '../../../../../generic/icons/Icons'

const MessageWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 0.2em;
    background: #eff3f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    color: #75759e;
    border-radius: 15px;
    i {
        color: tomato;
    }
`

const MessagePart = styled.div`
    display: flex;
    flex-flow: column;
    padding: 0.2em;
    width: 40%;
    gap: 0.2em;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
`

const TimeBlock = styled.div`
    position: absolute;
    border-radius: 10px;
    padding: 0.2em;
    top: -0.2em;
    left: .5em;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
`

export default function Message({ transportAreaDict, SourceID, DestinationID, Order, Pallets, refLink, Date, Client }) {
    const destinationName = transportAreaDict[DestinationID] ? transportAreaDict[DestinationID]?.AreaDesc : 'none'
    const sourceName = transportAreaDict[SourceID] ? transportAreaDict[SourceID]?.AreaDesc : 'none'
    // console.log(Pallets)
    return (
        <MessageWrapper ref={refLink}>
            <TimeBlock>
                {Date}
            </TimeBlock>
            <MessagePart>
                <Row>
                    {destinationName}
                    <SpeedIcon />
                </Row>
            </MessagePart>
            <ArrowIcon position={'left'} />
            <MessagePart>
                <Row>
                    {sourceName}
                    <SpeedIcon />
                </Row>
                <Row>
                    {Client}
                    <UserIcon />
                </Row>
                <Row>
                    {
                        Order?.length > 0 ? 
                            Order.map(e => 
                                <div
                                    key={e}
                                >
                                    {e}
                                </div>
                            ) :
                                Order

                    }
                    <InvoiceIcon />
                </Row>
                {
                    Pallets?.length > 1 && 
                    <Row>
                        {
                            Pallets?.map((item, index) => <div key={index}>{item.Pallet_Num || item}</div>)
                        }
                        <PalletIcon />
                    </Row>
                }

            </MessagePart>
        </MessageWrapper>
    )
}
