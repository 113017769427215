import React, {useState, useMemo} from 'react'
import styled from 'styled-components'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'
import { IGrowerItem } from '../../../../../../modules/API/Defaults/models/growers';

type Props = {
  growerList: IGrowerItem[];
  growerID: number | null;
  setGrowerID: React.Dispatch<React.SetStateAction<number>>;
}

const GrowerFilter = ({
  growerList, growerID, setGrowerID
}: Props) => {
  const defGrower = growerList?.filter(item => item.GrowerID === growerID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [inputGrower, setInputGrower] = useState<string>(defGrower?.GrowerName?.trim() || '')
  const [selectedGrower, setSelectedGrower] = useState<IGrowerItem | null>(defGrower || null)
  const handleSearchGrower = (e: string) => {
    if(e === '') {
      setSelectedGrower(null)
      setGrowerID(0)
      
    }
    setIsOpenDropDown(true)
    setInputGrower(e)
  } 
  console.log(growerID)
  const handleSelectGrower = (item: IGrowerItem) => {
console.log(item)
    setSelectedGrower(item)
    setGrowerID(item.GrowerID)
    setInputGrower(item.GrowerName?.trim())
    setIsOpenDropDown(false)
  } 

  const render = useMemo(() => {
    if(!growerList) return []
    return growerList.filter(item => {
      return item.GrowerName?.trim()?.toLowerCase().includes(inputGrower.toLowerCase())
    })
  }, [growerList, inputGrower])
  return (
    <MainWrapper>
      <Text>
        מגדל
      </Text>
      <SearchFromTheList
        list={render}
        input={inputGrower}
        placeHolder={'חפש מגדל'}
        searchFunc={handleSearchGrower}
        selectFunc={handleSelectGrower}
        isOpenList={isOpenDropDown}
        nameIdElement='GrowerID'
        // selectedValue={selectedID}
        size={'full'}
        itemDisplayName='GrowerName'
        isNeedFullObject
      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    padding: 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    /* max-width: 150px; */
    /* overflow: hidden; */
    background: #fff;
    border-radius: 30px;
`
const Text = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5em;
`
export default GrowerFilter