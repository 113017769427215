export const passOrderRowsIntoOrder = (arr) => {
        
    let reducedDetalis = arr.reduce((acc, cur) => {
        if(cur.SupplyQty > 0 || cur.ChangeReason !== 0) {
            acc[cur.OrderID] = acc[cur.OrderID]?.concat(cur) || [].concat(cur)
        }
        return acc
    }, {})
 
    return reducedDetalis
}

export const passOrderRowsIntoOrderByWeight = (arr) => {
            
        let reducedDetalis = arr.reduce((acc, cur) => {
            if(cur.SupplyWeight > 0 || cur.ChangeReason !== 0) {
                acc[cur.OrderID] = acc[cur.OrderID]?.concat(cur) || [].concat(cur)
            }
            return acc
        }, {})
    
        return reducedDetalis
}

export const passTeudotIntoOrder = (arr) => {
    let reduced = arr.reduce((acc, cur) => {
        acc[cur.Order_Num] = acc[cur.Order_Num]?.concat(cur) || [].concat(cur)
        return acc
    }, {})

    return reduced
}