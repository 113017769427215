import { DollarIcon, EuroIcon, ShekelIcon } from "../icons/Icons"

type AvailableCurrency = 'EUR' | 'USD' | 'NIS';
type CurrencyIconType = JSX.Element; // Assuming your icons are JSX elements
// Define the type for the object with an index signature
type CurrencyCodesType = {
  [key: string]: CurrencyIconType;
};

const currencyCodes: CurrencyCodesType  = {
  'EUR': <EuroIcon />,
  'USD': <DollarIcon />,
  'NIS': <ShekelIcon />,
}
export const currencyIconByCode = (code: string): CurrencyIconType  => {
  return currencyCodes[code] || <ShekelIcon />
}