import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[];
}

const BodySettingsLayout = ({children}: Props) => {
  return (
    <MainWrapper>
      {children}
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  max-width: 100%;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  background: #E9F3F2;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 0.5em;
  border-radius: 20px;
  padding: 0.5em 0.3em;
  margin-top: 0.5em;
`
export default BodySettingsLayout