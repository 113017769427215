import React, { useState } from "react";
import styled from "styled-components";
import DeliveriPNG from "../../../../generic/assets/delivery.png";
import SearchFromTheList, {
  SearchWrapper,
} from "../../../../generic/customSearchComponents/SearchFromTheList";
import { ICarItem, TCarList } from "../../../../../models/Defaults/carList";
import { IWorkerItem } from "../../../../modules/API/Defaults/models/workers";
import ControlButton from "../../../../generic/buttons/ControlButton";
import { IKavDataItem } from "../../../../modules/API/Drivers/models/kavData";
import CheckBoxWithTitle from "../../../../generic/particles/CheckBox";

type Props = {
  driverName: string;
  kav: IKavDataItem;
  listCars: TCarList;
  listCarsDrivers: IWorkerItem[];
  defaultCar: ICarItem;
  saveFunc: (carID: number, DriverID: number, driverName: string, isPermanent?: boolean) => void;
};

const ChangeDriverAndSave = ({
  listCars,
  listCarsDrivers,
  defaultCar,
  saveFunc,
  kav, driverName
}: Props) => {
  const currentCur =
    listCars
      .filter((item) => item.CarID === kav.CarId)[0]
      ?.CarNum?.toString() || "";
  const [carInput, setCarInput] = useState(`${driverName?.trim()} - ${currentCur}`);
  const [carRender, setCarRender] = useState<TCarList>(listCars);
  const [isOpenCarsList, setIsOpenCarsList] = useState<boolean>(false);
  const [carInfo, setCarInfo] = useState<ICarItem | null>(defaultCar || null);

  const [driverInput, setDriverInput] = useState(kav.DriverName?.trim());
  const [driverRender, setDriverRender] =
    useState<IWorkerItem[]>(listCarsDrivers);
  const [isOpenDriverList, setIsOpenDriverList] = useState<boolean>(false);
  const [driverInfo, setDriverInfo] = useState<IWorkerItem | null>(null);

  const [isPemanent, setIsPermanent] = useState<boolean>(false)

  const searchCarFunc = (input: string) => {
    setCarInput(input);
    const filtered = listCars.filter((item) =>
      `(${item.CarID}) ${item.DriverName.trim()} ${item.CarNum}`.includes(input.toLowerCase()) 
    );
    console.log(filtered);
    console.log(listCars);
    setCarRender(filtered);
    setIsOpenCarsList(filtered.length > 0 && input ? true : false);
    if (!input.trim()) {
      // setDriverID(null);
      setCarInfo(null);
    }
  };
  const selectCar = (item: ICarItem): void => {
    // setDriverID(item.CarID)
    setCarInput(item.CarNum.toString());
    setIsOpenCarsList(false);
    setCarInfo(item);
  };

  const searchDriverFunc = (input: string) => {
    setDriverInput(input);
    const filtered = listCarsDrivers.filter((item) =>
      item.WorkerName.toString().includes(input.toLowerCase())
    );
    console.log(filtered);
    console.log(listCars);
    setDriverRender(filtered);
    setIsOpenDriverList(filtered.length > 0 && input ? true : false);
    if (!input.trim()) {
      // setDriverID(null);
      setDriverInfo(null);
    }
  };
  const selectDriver = (item: IWorkerItem): void => {
    // setDriverID(item.CarID)
    setDriverInput(item.WorkerName.trim());
    setIsOpenDriverList(false);
    setDriverInfo(item);
  };
  return (
    <MainWrapper>
      <TitleImg src={DeliveriPNG} alt="Driver" />
      בחר רכב
      <SearchWrapper>
        <SearchFromTheList
          size={"full"}
          input={carInput}
          placeHolder={"חיפוש רכב"}
          list={carRender}
          searchFunc={searchCarFunc}
          selectFunc={selectCar}
          isOpenList={isOpenCarsList}
          itemDisplayName={"CarNum"}
          nameIdElement={"CarID"}
          isNeedFullObject
          itemSecondaryName={"DriverName"}
          itemThirdName={"CarID"}
        />
      </SearchWrapper>
      בחר נהג
      <SearchWrapper>
        <SearchFromTheList
          size={"full"}
          input={driverInput}
          placeHolder={"חיפוש נהג"}
          list={driverRender}
          searchFunc={searchDriverFunc}
          selectFunc={selectDriver}
          isOpenList={isOpenDriverList}
          itemDisplayName={"WorkerName"}
          nameIdElement={"WorkerId"}
          isNeedFullObject
        />
      </SearchWrapper>
      <CheckBoxWithTitle 
        title="שינוי נהג לרכב"
        stateFunc={setIsPermanent}
      />
      {
        <ControlButton
          primal
          lable={"עדכן"}
          handleClick={() => {
            saveFunc(
              carInfo?.CarID || kav.CarId,
              driverInfo?.WorkerId || kav.DriverWorkerId,
              driverInfo?.WorkerName || kav.DriverName,
              isPemanent
            );
          }}
        />
      }
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5em;
  gap: 1em;
  color: #3d8b83;
`;
const TitleImg = styled.img`
  min-width: 400px;
`;
export default ChangeDriverAndSave;
