import styled from 'styled-components'
import './ReferalBlock.scss'

const Wrapper = styled.section`
    background: #fff;
    width: 100%;
    ${'' /* height: 80vh; */}
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    
`

const WrapperInner = styled.div`
    ${'' /* background: blue; */}
    max-width: 1480px ;
    /* height: 60vh; */
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    @media screen and (max-width: 600px){
        height: 50vh;

    }
`

const Container = styled.div`
    ${'' /* background: yellow; */}
    width: 100%;
    display: flex;
    height: 100%;
    flex-flow: row-reverse wrap;
    justify-content: center;
    ${'' /* border: 1px solid black; */}
    ${'' /* overflow: scroll;
    padding-left: 15rem;
    overflow-x: hidden; */}
    @media screen and (max-width: 600px){
        overflow:hidden;

    }
`

const ContainerUp = styled(Container)`
    flex-flow: column;
    height: 20%;

`

const Item = styled.div`
    min-width: 120px;
    min-height: 120px;
    ${'' /* background: purple; */}
    margin: 0.5em 0.3em;
    border-radius: 5px;
    ${'' /* border: 2px solid black; */}
    @media screen and (max-width: 600px){
        overflow:hidden;
        min-width: 100px;
        min-height: 100px;
        
    }
    
`

const Title = styled.h1`
    ${'' /* background: blue; */}
    font-size: 2em;
    color: #1A1918;
    margin-bottom: 1em;
    ${'' /* height: 100px; */}
`

export default function ReferalBlock() {

    return(
        <Wrapper>
            <WrapperInner>
                <ContainerUp>
                    <Title>סומכים עלינו</Title>
                    {/* <Discription>
                        disc
                    </Discription> */}
                </ContainerUp>
                <Container>
                    <Item className="ref-img ref-img1"></Item>
                    <Item className="ref-img ref-img2"></Item>
                    <Item className="ref-img ref-img3"></Item>
                    <Item className="ref-img ref-img4"></Item>
                    <Item className="ref-img ref-img5"></Item>
                    <Item className="ref-img ref-img6"></Item>
                    <Item className="ref-img ref-img7"></Item>
                    <Item className="ref-img ref-img8"></Item>
                    <Item className="ref-img ref-img9"></Item>
                    <Item className="ref-img ref-img10"></Item>
                    <Item className="ref-img ref-img11"></Item>
                    <Item className="ref-img ref-img12"></Item>
                    <Item className="ref-img ref-img13"></Item>
                    <Item className="ref-img ref-img14"></Item>
                    <Item className="ref-img ref-img15"></Item>
                    <Item className="ref-img ref-img16"></Item>
                    <Item className="ref-img ref-img17"></Item>
                    <Item className="ref-img ref-img18"></Item>
                    <Item className="ref-img ref-img19"></Item>
                    <Item className="ref-img ref-img20"></Item>
                    <Item className="ref-img ref-img21"></Item>
                    <Item className="ref-img ref-img22"></Item>
                </Container>
            </WrapperInner>
        </Wrapper>
    );
}