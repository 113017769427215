import PresetsItem from './presetsItem'
import styled from "styled-components";


const Wrapper = styled.div`
   /* background: red; */
   min-width: 100%;
   height: 100%;
   
   select {
       width: 100%;
       height: 100%;
       border: 2px solid #ced3ff;
       border-radius: 5px;
       outline: none;
       appearance: none;
       cursor: pointer;
       text-align-last: right;
       padding: 0 0.5em;
       font-size: 1em;
        :active {
        border: 2px solid #ced3ff;
       }
       direction: rtl;
   }
`


export default function PresetsList({orders, setCurrentPresset, setProduct, getGoods}){
  
    // useEffect(()=> {setCurrentPresset(tValue)}, [presetList])
    return(
        <Wrapper>
            <select onChange={e => {
                getGoods(e.target.value)
                // console.log(e.target.value) ; setCurrentPresset(e.target.value); setTValue(e.target.value);
                }}>
                <option key={0} value={'0'}>בחר הזמנה</option>
                {orders.map((item) => <PresetsItem key={item.OrderID}{...item} />)}
            </select>
            
        </Wrapper>
    );
}