import React from 'react'
import styled from 'styled-components'
import { DeleteIcon } from '../../../../../generic/icons/Icons'

type Props = {
  handleClick: () => void
}

const ConfirmDelate = ({handleClick}: Props) => {
  return (
    <MainWrapper onClick={handleClick} >
      <DeleteIcon />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  height: 100%;
  width: 5em;
  border-radius: 0 0.5em 0.5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: tomato;
  margin-left: 0.5em;
  cursor: pointer;
`
export default ConfirmDelate