import React from 'react'
import styled from 'styled-components'

const MainWrapper = styled.div`
    background: ${props => props.rigth ? '#7ab0ab' : 'tomato'};
    height: 5.5em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${props => props.rigth ? 'flex-end' : 'flex-start'};
    padding: 0 2em;
    color: ${props => props.hidden ? 'tomato' : '#fff'};
    font-weight: 600;
    
`
export default function SwipeRight({label, poseition, disable}) {
    return (
        <MainWrapper rigth={poseition} hidden={disable}>
            {label}
        </MainWrapper>
    )
}
