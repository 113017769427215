import React, {useState, useContext, useMemo, useEffect} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import BodyColumnLayout from '../../palletLocation/Layouts/BodyColumnLayout'
import ImageWrapper from '../../palletLocation/Components/ImageWrapper'
import Image from '../../../../../generic/assets/cogsPNG.png'
import SearchBlockWithTitle from '../../procedures/Q8/Layouts/SearchBlockWithTitle'
import PalletNumSwitcher from '../../palletLocation/Components/PalletNumSwitcher'
import PalletInput from '../../palletLocation/Components/PalletInput'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { Pallets } from '../../../../../modules/API/Pallets/pallets'
import { IPalletListWithDetails } from '../../../../../modules/API/Pallets/models/palletList'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { IClientsItem } from '../../../../../modules/API/Defaults/models/customers'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import ControlButton from '../../../../../generic/buttons/ControlButton'
type Props = {}

const EditCustomerMain = (props: Props) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useContext(ContextModal);
    const customerList: IClientsItem[] = useSelector((state: RootState) => state.defaults.clientList.list)
    const [searchCustomer, setSearchCustomer] = useState<string>("")
    const [isOpenCustomerList, setIsOpenCustomerList] = useState<boolean>(false)
    const [selectedCustomer, setSelectedCustomer] = useState<IClientsItem | null>(null)
    const [actriveToggle, setActriveToggle] = useState<boolean>(false);
    const [palletNumber, setPalletNumber] = useState<number>(0);
    const [palletFound, setPalletFound] = useState<IPalletListWithDetails | null>(null);


    const getPalletByID = async () => {
        try {
            const toggle = actriveToggle ? 1 : 0
            const res = await Pallets.getPalletWithDitailesByPalletNum(palletNumber, toggle)
            setPalletFound(res)

            setModal({
                open: true,
                value: "משטח נמצא",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)

        } catch (error) {
            console.log(error)
            setModal({
                open: true,
                value: "משטח לא נמצא",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
    }
    const clearData = () => {
        setPalletNumber(0)
        setPalletFound(null)
        setSelectedCustomer(null)
        setSearchCustomer('')
    }
    const putCustomerToPallet = async () => {
        dispatch({ type: LOAD })
        const data = {
            CustomerID: selectedCustomer?.CustomerID || 0,
        }
        try {
            Pallets.putCustomerToPallet(palletNumber, data)
            setModal({
                open: true,
                value: "לקוח עודכן בהצלחה",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            clearData()
        } catch (error) {
            setModal({
                open: true,
                value: "לקוח לא עודכן",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const handleChangePalletNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPalletNumber(Number(e.target.value))
    }
   
    const handleToggle = () => {
        setActriveToggle(!actriveToggle)
    }

    const handleSelectCustomer = (item: IClientsItem) => {
        setSelectedCustomer(item)
        setSearchCustomer(item.Customer_Name?.trim())
        setIsOpenCustomerList(false)
    }
    const handleChangeCustomer = (e: string) => {
        setSearchCustomer(e)
        setIsOpenCustomerList(true)
        if (!e) {
            setSelectedCustomer(null)
        }
    }
    useEffect(() => {
        if(!palletFound) return
        let customer = customerList.find((item) => item.CustomerID === palletFound.CustomerID);
        setSelectedCustomer(customer || null)
        setSearchCustomer(customer?.Customer_Name?.trim() || '')
    }, [palletFound, customerList])

    const renderClients = useMemo(() => {
        if(!customerList) return []
        return customerList.filter((item) => {
            return item.Customer_Name?.trim().toLowerCase()
                .includes(searchCustomer.toLowerCase())
        })

    }, [customerList, searchCustomer])
    return (
        <OrderlikeLayout label='עדכון יעד למשטח'>
            <BodyColumnLayout>
                <ImageWrapper
                    size='large'
                    img={Image}
                />
                <SearchBlockWithTitle
                    title='מספר משטח'
                >
                    <PalletNumSwitcher
                        handleToggle={handleToggle}
                        actriveToggle={actriveToggle}
                    />
                    <PalletInput
                        isShowIcon={palletNumber !== 0}
                        value={palletNumber}
                        handleChangePalletNumber={handleChangePalletNumber}
                        handleFunction={getPalletByID}
                        iconVariationSwitcher={actriveToggle}
                    />
                </SearchBlockWithTitle>
                {
                    palletFound &&
                    <SearchBlockWithTitle
                        title='לקוח'
                    >
                        <SearchFromTheList 
                            placeHolder={'הכנס לקוח'}
                            input={searchCustomer}
                            itemDisplayName='Customer_Name'
                            isNeedFullObject
                            nameIdElement='CustomerID'
                            list={renderClients}
                            selectFunc={handleSelectCustomer}
                            searchFunc={handleChangeCustomer}
                            size={'full'}
                            isOpenList={isOpenCustomerList}
                        
                        />
                    </SearchBlockWithTitle>
                }
                {
                    selectedCustomer && palletFound &&
                    <ControlButton 
                        handleClick={putCustomerToPallet}
                        lable={'עדכן'}
                    />
                }
            </BodyColumnLayout>
        </OrderlikeLayout>
    )
}

export default EditCustomerMain