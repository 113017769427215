import { useState } from 'react'
import styled from 'styled-components'
import ArizotCarItem from './arizotCarItem'

const Wrapper = styled.div`
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: -7em;
    max-height: 7em;
    overflow-y: auto;
    /* display: flex; */
    flex-flow: column;
    border-radius:30px;
    padding: 0 1em;
    transform: translateY(-0.2em);
    border-top: 1px solid rgba(0,0,0,0.2);
    z-index: 3;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
    ::-webkit-scrollbar {
        width: 0;
    }
`


export default function ArizotCarList({riderList, setInputRide, inputRider, wrapperRiderStatus, setWrapperRiderStatus, setCarId, setCarNumber, setDriverName}){
    

    let statusToggle = wrapperRiderStatus ? 'flex' : 'none'
    
    return(
        <Wrapper style={{display: statusToggle}}>
            {riderList.map(item => <ArizotCarItem key={item.CarID} {...item}
                setInputRide={setInputRide} setWrapperStatus={setWrapperRiderStatus}
                setCarId={setCarId} setCarNumber={setCarNumber} setDriverName={setDriverName}
             />)}
        </Wrapper>
    )
}