

import './modalDelete.scss'

export default function ModalDelete({callModal, background, flex, border}) {
    

    return(
        <div className="delete-modal-selector" 
        style={{background: background, justifyContent: flex, borderRadius: border ? '30px' : '10px' }}
        >
            <button className="delete-modal-selector__edit"><div>{callModal}</div></button>
        </div>
    );
}