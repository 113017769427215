import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../../../../../generic/hooks/useDetectOutsideClick';
import OpenCloseButton from '../../../../../generic/particles/OpenCloseButton';

const MainWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    padding: 2em;
    background: rgba(233,243,242, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${props => props.isShow ? 'translateX(0)': 'translateX(-100%)'} ;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s; 
    z-index: 3;
`

const DashContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #e9f3f2;
    border-radius: 30px;
    padding: 1em;
    
    `

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    display: inline-flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    /* align-items: flex-start; */
    /* flex-flow: row-reverse wrap; */
    gap: 1em;
    padding: 2em;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0;
    }
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));

`

const ContentBox = styled.div`
    width: 280px;
    height: 240px;
    border-radius: 30px;
    background: #eff3f4;
    /* align-self: center; */
    margin: auto;
    display: flex;
    flex-flow: column;
    padding: 1em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
`
const Header = styled.div`
    width: 100%;
    height: 3.5em;
    background: #fff;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808179;
    font-weight: 600;
`
const ContentMainSection = styled.div`
  position: relative;
  /* background: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
  filter: drop-shadow(2px 3px 3px rgb(215,231,242));
`
const InnetText = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #297f76;
  /* background: red; */
`
const Svg = styled.svg`
  position: relative;
  width: 150px;
  height: 150px;

  /* justify-content: center;
  align-items: center;
  background: red; */
  
  
`
const Circle = styled.circle`
  fill: none;
  stroke-width: 10;
  stroke: #000;
  transform: translate(15px, 15px);
  stroke-dasharray: 375;
  stroke-dashoffset: 375;
  :nth-child(1) {
    stroke-dashoffset: 0;
    stroke: #fff;
  }
  :nth-child(2) {
    stroke-dashoffset: ${props => props.primal ? `calc(375 - (375 * ${props.primal}) / 100)` : '375'};
    stroke: ${props => props.over ? 'tomato' : '#81b4af'} ;
    border-radius: 50%;
    stroke-linecap: round;
  }

`

export default function DashboardTruck({controllFunc, palletList, transportAreaDict, cars, ignorDirection}) {
    const [isShow, setIsShow] = useState(false);
    const dashboardRef = useRef();
    console.log(palletList)
    
    const uniqCars = () => {
        let reduced = palletList?.current?.reduce((acc, cur) => {
            if(cur.PalletTransferZone === 0 || cur.PalletTransferZone === ignorDirection) return acc
            acc = [...acc, transportAreaDict[cur.PalletTransferZone]?.CarId]
            return acc
        }, [])
        const newSet = new Set(reduced)
        console.log([...newSet].filter(e => e))
        console.log(reduced)
        console.log(transportAreaDict)
        return [...newSet].filter(e => e)?.length
    }
    // uniqCars()
    const uniqCarsQty = uniqCars()
    
    const carsInfo = () => {
        const widthInfo = palletList?.current?.reduce((acc, cur) => {
            if(cur.PalletTransferZone === 0 || cur.PalletTransferZone === ignorDirection) return acc
            const carID = transportAreaDict[cur.PalletTransferZone]?.CarId
            let curObj = {
                CurrentWeight: currentCarWeight(cur.OrderPallets),
                TruckWeight: cars[carID]?.Max_Weight || 0,
                CurrentPallets: currentCarPallets(cur.OrderPallets),
                TruckPallets: cars[carID]?.Pallets || 0,
            }
            acc = [...acc, curObj]
            return acc
        }, [])
        const {OverWeightQty, WeightPrercentsArr, PalletsQty} = widthInfo.reduce((acc, cur) => {
            let overWidth = (cur.CurrentWeight - cur.TruckWeight > 0) || (cur.CurrentPallets - cur.TruckPallets > 0)
            let weightInPercents = (cur.CurrentWeight / (cur.TruckWeight ? cur.TruckWeight : 1)) * 100
            acc = {
                OverWeightQty: acc['OverWeightQty'] ? acc['OverWeightQty'] += overWidth ? 1 : 0 : overWidth ? 1 : 0,
                WeightPrercentsArr: [...acc['WeightPrercentsArr'] || [], Math.round(weightInPercents)],
                PalletsQty: acc['PalletsQty'] ? acc['PalletsQty'] += cur.CurrentPallets : cur.CurrentPallets
            }
            return acc
        }, {})
        const middlePercent = Math.round(WeightPrercentsArr.reduce((acc, cur) => {
            acc += cur
            return acc
        }, 0) / WeightPrercentsArr.length)
        const minPercent = Math.min(...WeightPrercentsArr)
        const unUsedPallets = palletList?.current?.reduce((acc, cur) => {
            if(cur.PalletTransferZone !== 0) return acc
            acc += currentCarPallets(cur.OrderPallets)
            return acc
        }, 0)
        console.log(unUsedPallets)
        console.log(widthInfo)
        console.log(OverWeightQty)
        console.log(WeightPrercentsArr)
        console.log(minPercent)
        return {
            OverWeightQty: OverWeightQty,
            PalletsQty: PalletsQty,
            WeightPrercents: middlePercent,
            MinLoadInPercent: minPercent,
            UnUsedPallets: unUsedPallets
        }
    }
    const {
        OverWeightQty,
        PalletsQty,
        WeightPrercents,
        MinLoadInPercent,
        UnUsedPallets
    } = carsInfo()
    const overPercent = (num) => num > 100 
    const overweightDirections = (OverWeightQty / ( palletList?.current?.length - 1)) * 100
    const uniqCarsPrecents = (uniqCarsQty / ( palletList?.current?.length - 1)) * 100
    const unUsedPalletsInPercets = Math.round(UnUsedPallets / (PalletsQty + UnUsedPallets) * 100)
    const usedPalletsInPercets = Math.round(PalletsQty / (PalletsQty + UnUsedPallets) * 100)
    console.log(usedPalletsInPercets)
    const closeWindow = () => {
        setIsShow(false);
        setTimeout(() => {
            controllFunc(false)
        }, 500)
    }
    useEffect(() => {
        setIsShow(true)
    }, [])
    useDetectOutsideClick(dashboardRef, closeWindow)
  return (
    <MainWrapper
        isShow={isShow}
    >
        <DashContainer ref={dashboardRef}>
            <ContentWrapper>
                <ContentBox>
                    <Header>
                        כמות קווים
                    </Header>
                    <ContentMainSection>
                        <InnetText>
                            {palletList?.current?.length - 1}
                        </InnetText>
                    <Svg>
                        <Circle primal={100} cx="60" cy="60" r='60'></Circle>
                        <Circle primal={100} cx="60" cy="60" r='60'></Circle>
                    </Svg>
                    </ContentMainSection>
                    
                    {}
                </ContentBox>
                <ContentBox>
                    <Header>
                        כמות משאיות 

                    </Header>
                    <ContentMainSection>
                        <InnetText>
                            {uniqCarsQty}
                        </InnetText>
                    <Svg>
                        <Circle primal={uniqCarsPrecents} cx="60" cy="60" r='60'></Circle>
                        <Circle primal={uniqCarsPrecents} cx="60" cy="60" r='60'></Circle>
                    </Svg>
                    </ContentMainSection>
                </ContentBox>
                <ContentBox>
                    <Header>
                     ניצול משאיות
                    </Header>
                    <ContentMainSection>
                        <InnetText>
                            {WeightPrercents}%
                        </InnetText>
                    <Svg>
                        <Circle primal={WeightPrercents} cx="60" cy="60" r='60'></Circle>
                        <Circle over={overPercent(WeightPrercents)} primal={overPercent(WeightPrercents) ? 100 : WeightPrercents} cx="60" cy="60" r='60'></Circle>
                    </Svg>
                    </ContentMainSection>
                </ContentBox>
                <ContentBox>
                    <Header>
                        הניצול הנמוך ביותר

                    </Header>
                    <ContentMainSection>
                        <InnetText>
                            {MinLoadInPercent}%
                        </InnetText>
                    <Svg>
                        <Circle primal={MinLoadInPercent} cx="60" cy="60" r='60'></Circle>
                        <Circle over={overPercent(MinLoadInPercent)} primal={overPercent(MinLoadInPercent) ? 100 : MinLoadInPercent} cx="60" cy="60" r='60'></Circle>
                    </Svg>
                    </ContentMainSection>
                </ContentBox>
                <ContentBox>
                    <Header>
                        קוים חורגים
                    </Header>
                    <ContentMainSection>
                        <InnetText>
                            {OverWeightQty}
                        </InnetText>
                    <Svg>
                        <Circle primal={overweightDirections} cx="60" cy="60" r='60'></Circle>
                        <Circle over={overweightDirections} primal={overweightDirections} cx="60" cy="60" r='60'></Circle>
                    </Svg>
                    </ContentMainSection>
                </ContentBox>
                
                
                <ContentBox>
                    <Header>
                        משטחים בקווים
                    </Header>
                    <ContentMainSection>
                        <InnetText>
                            {PalletsQty}
                        </InnetText>
                    <Svg>
                        <Circle primal={usedPalletsInPercets} cx="60" cy="60" r='60'></Circle>
                        <Circle primal={usedPalletsInPercets} cx="60" cy="60" r='60'></Circle>
                    </Svg>
                    </ContentMainSection>
                    {PalletsQty}
                </ContentBox>
                <ContentBox>
                    <Header>
                        משטחים ללא קו

                    </Header>
                    <ContentMainSection>
                        <InnetText>
                            {UnUsedPallets}
                        </InnetText>
                    <Svg>
                        <Circle primal={unUsedPalletsInPercets} cx="60" cy="60" r='60'></Circle>
                        <Circle primal={unUsedPalletsInPercets} cx="60" cy="60" r='60'></Circle>
                    </Svg>
                    </ContentMainSection>
                </ContentBox>
            </ContentWrapper>
        </DashContainer>
    </MainWrapper>
  )
}

const currentCarWeight = (orders) => {
    let calc = orders.reduce((acc, cur) => {
        if (cur.Width) {
            acc += cur.Width
        } else {
            acc += cur.pallets.reduce((acc, cur) => {
                acc += cur.NetWeight
                return acc
            }, 0)
        }
        return acc
    }, 0)
    return Math.round(calc)
}
export const currentCarPallets = (orders) => {
    let generalPalletQty = orders.reduce((acc, cur) => {
        if(cur.Custom) {
            acc += cur.PalletsQty
        } else {
            acc += cur?.pallets?.reduce((acc, cur) =>
            acc += cur.Total_Pallets
            , 0)
        }
        return acc
    }, 0)
    return Math.round(generalPalletQty)
}