import React, {useState, useMemo, useEffect} from 'react'
import OrderlikeLayout from '../../../../../../generic/Layouts/OrderlikeLayout'
import useDataFetcher from '../../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../../modules/API/Defaults/defaults'
import { ITableOfTableItem } from '../../../../../../modules/API/Defaults/models/tableOfTables'
import { IBankItem } from '../../../../../../modules/API/Defaults/models/banks'
import ControlSearchPannelLayout from '../../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../../generic/inputs/inputSearch'
import { ControlButtonWrapper } from '../../../secondbar/modalAzmanot/buttons/ButtonStyle'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
import { ButtonContainer } from '../../../likutExpress/palletSection/siumLikut/SiumLikutModal'
import { IClientItem } from '../../../../../../modules/API/Defaults/models/clients'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'
import DatePickerModule from '../../../../../../generic/datePicker/datePicker'
import { PAYMENT_TYPE, PaymentType } from '../../generic/getDetailesByPaymentType'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { BankIcon, InvoiceIcon, PlusIcon, ShekelIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import TitlesList from '../../../../../../generic/titlesRowItem/TitlesList'
import ReceiptCreateLayout from './ReceiptCreateLayout'
import ReceiptPaymentLine from './ReceiptPaymentLine'
import AddLine from './Components/AddLine'
import BodyScrollContainer from '../../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Reciept } from '../../../../../../modules/API/Reciept/reciept'
import { IReceiptPostRequest } from '../../../../../../modules/API/Reciept/models/requests'
import ColumModal from '../../../../../../generic/Layouts/ColumModal'
import AddlineModal from './Components/AddlineModal'
import ReceipAddLine from './Components/ReceipAddLine'
import AddClientModalMemo from '../../../market/Collect/Components/AddClientModal'
import { IAddCustomer } from '../../../market/Collect/MarketCollectMain'
import { Market } from '../../../../../../modules/API/Market/market'
import { IPostCustomer } from '../../../../../../modules/API/Market/models/marketRequests'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_RECEIPTS_STATE, LOAD, LOAD_END, SELECT_DATA_FOR_CREATE_RECEIPT } from '../../../../../../../store/actions/actionsTypes'
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn'
import selectBrodMigdal from '../../../../../../generic/utils/selectBrodMigdal'
import { RootState } from '../../../../../../../store/reducers/rootReducer'
import CheckBoxWithTitle from '../../../../../../generic/particles/CheckBox'
import { IRecieptItem, IRecieptTitleIltem } from '../../../../../../modules/API/Reciept/models/receipt'

type Props = {
  handlePushData: (item: IRecieptTitleIltem) => void
}
export interface IPaymentDetailesListItem {
  Sum: number
  PaymentType: PaymentType
  BankId: number,
  Branch: number,
  Account: number,
  Cheque: number,
  ValueDate: string 
  Comments: string
}
type TClientResponse = {
  ClientID: number
  CustomerID: number
  Customer_Name: string
  Phone: string
}
const ReceiptCreate = ({
  handlePushData
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = useSelector((state: RootState) => state.mainReducer.token)
  // const defDate = useSelector((state: RootState) => state.receipts.date)
  const [isNeedToPrint, setIsNeedToPrint] = useState<boolean>(false)
  const defaults = useSelector((state: RootState) => state.defaults.defaults)
  const clientCash: number = selectBrodMigdal(defaults, 611, "Num_Value")
  const [startData, setStartData] = useState<string>("")
  const [selectedClient, setSelectedClient] = useState<IClientItem | null>(null)
  const [searchClient, setSearchClient] = useState<string>("")
  const [isOpenCustomerList, setIsOpenClientList] = useState<boolean>(false)
  const [paymentsList, setPaymentsList] = useState<IPaymentDetailesListItem[]>([])
  const [isOpenAddLine, setIsOpenAddLine] = useState<boolean>(false)
  const [isOpenAddClientModal, setIsOpenAddClientModal] = useState<boolean>(false)
  const [toggleClient, setToggleClient] = useState<boolean>(false)
  const [newClient, setNewClient] = useState<TClientResponse | null>(null)
  const [customName, setCustomName] = useState<string | null>(null)
  const handleModal = () => {
    setIsOpenAddLine(!isOpenAddLine)
  }
  
  const {
    data: bankListChequesCards // cheques, creditCards
  } = useDataFetcher<ITableOfTableItem[]>(
    Defaults.getStandartTableItems, '120'
  )
  const {
    data: bankListTransfer // bank transfer
  } = useDataFetcher<IBankItem[]>(
    Defaults.getBankList, '2'
  )
  const {
    data: clientList, // bank transfer
  } = useDataFetcher<IClientItem[]>(
    Defaults.getClientsGo, `?ClientType=2`, toggleClient
  )

  const {
    data: debt
  } = useDataFetcher<{Debt: number}>(
    Reciept.getDebt, (selectedClient?.ClientID || 0).toString()
  )
  const paymentsType = Object.values(PAYMENT_TYPE)
  console.log(paymentsType)
  // Clients
  const handleSelectCustomer = (item: IClientItem) => {
    setSelectedClient(item)
    setSearchClient(item.ClientName?.trim())
    setIsOpenClientList(false)
    if(item.ClientID === clientCash) {
      console.log('cash')
      setCustomName(item.ClientName?.trim())
    } else {
      setCustomName(null)
    
    }

}
const handleChangeCustomer = (e: string) => {
    setSearchClient(e)
    setIsOpenClientList(true)
    if (!e) {
        setSelectedClient(null)
    }
}
const handleDate = (e: object) => {
  console.log(e);
  dispatch({ type: SELECT_DATA_FOR_CREATE_RECEIPT, payload: e });
};
const renderClient = useMemo(() => {
  if(!clientList) return []
  return clientList.filter((item) => {
      return item.ClientName?.includes(searchClient)
  })
}, [clientList, searchClient])

const addNewPaymentLine = (item: IPaymentDetailesListItem) => {
  setPaymentsList([...paymentsList, item])

}
const patchPaymentLine = (index: number, paymentLine: IPaymentDetailesListItem) => {
  setPaymentsList([...paymentsList.slice(0, index), paymentLine, ...paymentsList.slice(index + 1)])

}
const deletePaymentLine = (index: number) => {
  setPaymentsList([...paymentsList.slice(0, index), ...paymentsList.slice(index + 1)])

}
const paymentLineProps = {
  bankListTransfer: bankListTransfer,
  bankListChequesCards: bankListChequesCards,
  paymentsType: paymentsType,
  patchPaymentLine: patchPaymentLine,
  addNewPaymentLine: addNewPaymentLine,
  deleteLine: deletePaymentLine
}
const handleSave = async () => {
  const postData: IReceiptPostRequest = {
    CustomerID: selectedClient?.ClientID || 0,
    DateReg: startData,
    CustomerName: selectedClient?.ClientID === clientCash ? customName : null ,
    Lines: paymentsList.map((item) => {
      let result = {
        Sum: item.Sum,
        PaymentType: item.PaymentType,
        BankCode: item.BankId || 0,
        Branch: item.Branch || 0,
        Account: item.Account || 0,
        Cheque: item.Cheque || 0,
        ValueDate: item.ValueDate || startData,
        Comments: item.Comments || ''
      }
      return result
    })
  }
  try {
    const res: IRecieptItem = await Reciept.postReceipt(postData)
    if(isNeedToPrint) {
      await printPDF(res.ReceiptNum)
    }

    history.push('/main/bills/receipt')
    const {Lines, ...rest} = res
    handlePushData(rest)
  } catch (error) {
    console.log(error)
  } finally {

  }
}
const addLineModalProps = {
  handleModal: handleModal,
  clientList: clientList,
  bankListTransfer: bankListTransfer,
  bankListChequesCards: bankListChequesCards,
  paymentsType: paymentsType,
  patchPaymentLine: patchPaymentLine,
  addNewPaymentLine: addNewPaymentLine,
  selectedClient: selectedClient,
  defDate: startData
}
const handleBack = () => {
  history.goBack()
}

const summCalc = useMemo(() => {
  if(paymentsList.length === 0) return 0
  return paymentsList.reduce((acc, item) => {
    return acc + item.Sum
  }, 0)
}, [paymentsList])
const handleClientModal = () => {
  setIsOpenAddClientModal(!isOpenAddClientModal)
}

const handleSaveNewClient = async ({
  customer, ClientID, CustomerID, Customer_Name, Phone
} : IAddCustomer) => {
  console.log(customer)
  dispatch({type: LOAD})
  dispatch({type: LOAD})
  try {

    const formatedPhone = Phone.split('').filter(item => {
      return /[0-9]/.test(item)
    }).join('')

    let reqData: IPostCustomer = {
      ClientID: ClientID,
      CustomerID: CustomerID,
      Customer_Name: Customer_Name,
      Phone: formatedPhone,
    }
    
    let res: TClientResponse = await Market.createCustomer(reqData)
    setNewClient(res)
    setToggleClient(!toggleClient)
    if(res.ClientID === clientCash) {
      setCustomName(Customer_Name?.trim() || '')
    }

    handleClientModal()
  } catch (err) {
    alert(err)
  } finally {
    dispatch({type: LOAD_END})
  }
  
}

const printPDF = async (receiptID: number) => {
  try {
    let response = await fetch(
      `https://my.agroline.co.il/v2/receipt/${receiptID}/pdf?Original=1`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await response.blob();
    // Receive PDF Steam as as Blob
    const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
    const fileURL = URL.createObjectURL(file); //Build a URL from the file
    window.open(fileURL); //Open the URL on new Window
  } catch (error) {
    console.log(error)
  }
}
useEffect(() => {
  if(!newClient || !clientList) return
  const foundClientIndex = clientList.findIndex((item) => {
    return item.ClientID === newClient.ClientID
  })
  if(foundClientIndex !== -1) {
    setSelectedClient(clientList[foundClientIndex])
    setSearchClient(clientList[foundClientIndex].ClientName?.trim() || '')
  }
},[newClient, clientList])

const debtToShow = (debt?.Debt || 0) < 0 ? `${Math.abs(debt?.Debt || 0).toLocaleString()} זכות` : `${Math.abs(debt?.Debt || 0).toLocaleString()} חובה`
  return (
    <OrderlikeLayout label='קבלה חדשה'>
      {
        isOpenAddClientModal &&
        <ColumModal 
          title='הוספת לקוח חדש'
          Children={AddClientModalMemo}
          childrenProps={
            {
              handleSave: handleSaveNewClient,
              clientList: [],
              isOnlyCreateNew: true
            }
          }
          controlFunc={handleClientModal}
        />
         
      }
      {
        isOpenAddLine &&
          <ColumModal 
            Children={AddlineModal}
            childrenProps={addLineModalProps}
            controlFunc={handleModal}
            title='הוספת שורה'
          />
      }
      <TitleLayout>
        {
          (selectedClient?.ClientID || 0) !== 0 ?
            <TitleRow>
              <TitleRowItem 
                icon={<ShekelIcon />} 
                name={debtToShow}
              /> 
            </TitleRow>
            : ''
          

        }
        <TitleRow>
       
          <TitleRowItem 
            icon={<ShekelIcon />} 
            name={`${summCalc.toLocaleString()} סה"כ`}
          />
        </TitleRow>
      </TitleLayout>
      <ControlSearchPannelLayout>
        <DatePickerModule
          setSelectedDateStart={setStartData}
          setSelectedDateEnd={false}
          defEndDate={false}
          selected={false}
          ignorBrod={true}
          disabled={false}
          saveWithoutFormat={handleDate}
          isNeedWithoutFormated
          large
        />
          {
            customName !== null &&
            <ClientNameWrapper>

            <SearchInput 
              input={customName}
              setInput={setCustomName}
              isEnableFocusSelect
              // disabled={(selectedClient?.ClientID === clientCash) || newClient?.ClientID ===}
              />
              </ClientNameWrapper>
  
          }
        <SearchFromTheList 
          placeHolder={'הכנס לקוח'}
          input={searchClient}
          itemDisplayName='ClientName'
          isNeedFullObject
          nameIdElement='ClientID'
          list={renderClient}
          selectFunc={handleSelectCustomer}
          searchFunc={handleChangeCustomer}
          size={'full'}
          isOpenList={isOpenCustomerList}
        />
        <CircleActiveFilterBtn
          func={handleClientModal}
        >
          <PlusIcon />
        </CircleActiveFilterBtn>
      </ControlSearchPannelLayout>
      <ReceiptCreateLayout>
        <BodyScrollContainer>
        {/* <MainTitle>
          <TitleItem>
            {`סה"כ `} <ShekelIcon />
          </TitleItem>
          <TitleItem>
            {'פרטי הנמען'} <BankIcon />
          </TitleItem>
          <TitleItem>
            {'סוג תשלום'} <InvoiceIcon />
          </TitleItem>
        </MainTitle> */}
        <TitlesList
          noPadding={false}
          list={paymentsList}
          Element={ReceipAddLine}
          additionalData={paymentLineProps}
          borderLess
          />
          {
            selectedClient && startData && 
            <>
              <AddLine
                handleClick={handleModal}
                />

                <MainTitle>
                  <CheckBoxWithTitle
                    defaultState={isNeedToPrint}
                    stateFunc={setIsNeedToPrint}
                    title='הדפסה'
                    />

                </MainTitle>
            </>
          }
        </BodyScrollContainer>
      </ReceiptCreateLayout>
      <ButtonContainer>
        <ControlButton
          lable='חזרה'
          handleClick={handleBack}
        />
        {
          selectedClient && startData && paymentsList.length > 0 &&
          <>
            <ControlButton
              lable='שמירה'
              handleClick={handleSave}
            />
          </>
        }
      </ButtonContainer>
    </OrderlikeLayout>
  )
}
const MainTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
 
  padding: 0.8em 1em;
  
`
const TitleItem = styled.div`
  min-width: 20%;
  display: flex;
  justify-content: flex-end;
  color: #297F76;
  gap: 0.5em;
  border-left: 1px solid #297F76;
  :nth-child(1) {
    border-left: none;
  }
  :nth-child(3) {
    /* border-right: 1px solid #297F76; */
    /* padding-right: 0.5em; */
  }
  /* padding-bottom: 0.5em; */
`
const ClientNameWrapper = styled.div`
  position: relative;
  width: 100%;
  ::before {
      position: absolute;
      z-index: 1;
      width: fit-content;
      right: 0;
      top: -0.7em;
      padding: 0.2em;
      border-radius: 10px 10px 10px 0;
      background-color: #E9F0F0;
    content: 'שם לקוח';
    color: #297F76;
    box-shadow: 0 3px 5px 1px #7AB0AB;
  }
`
export default ReceiptCreate