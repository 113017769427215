import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode,
    onClick?: () => void;
    disableShadow?: boolean;
    isDraggable?: boolean;
    isDelete?: boolean;
    borderColor?: string;
}
export interface ItemRowComp {
    name: string;
    icon: JSX.Element;
    iconColor?: string;
}

const TitleLayout = ({
  children, onClick, disableShadow, 
  isDraggable, isDelete, borderColor
}: Props) => {
  return (
    <MainWrapper 
      onClick={onClick} 
      disableShadow={disableShadow}
      isDraggable={isDraggable}
      isDelete={isDelete}
      borderColor={borderColor}
    >
        {children}
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{
  disableShadow?: boolean, 
  isDraggable?: boolean,
  isDelete?: boolean
  borderColor?: string
  }>`
    position: relative;
    width: 100%;
    padding: 0.5em 1em;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    z-index: 1;
    border-right: ${props => props.borderColor ? `4px solid ${props.borderColor}` : 'none'};
    transition: all 0.5s;
    transform: ${props => props.isDelete ? 'rotateY(90deg) scale(0.8)' : 'rotateY(0) scale(1)'};
    cursor: ${props => props.isDraggable ? 'grab' : 'default'};
    ${
      props => !props.disableShadow && `
        -webkit-filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
        filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
      `
    }

    `
export default TitleLayout