import React from 'react'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import { IProductCefi } from '../../../../../modules/API/Cefi/models/product'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon, PalletIcon, ScalesIcon } from '../../../../../generic/icons/Icons'
import ProductImage from '../../queries/TeudotDetails/Components/ProductImage'
import styled from 'styled-components'
import RowInput from '../../../../../generic/inputs/RowInput'

interface IProps extends IProductCefi {
    editProduct: (value: number, recID: number, type: "pallets" | "packs" | "weight") => void
}

const CefiProductItem = ({
    ProductDesc, ProductID, VarietyDesc, VarietyID, SizeID, ImageUrl, 
    OrderPacks, OrderWeight, Order_Pallets, Remarks, editProduct, RecID
}: IProps) => {
    const img = ImageUrl ? Number(`${ImageUrl}`.split("/").at(-1)) ?? 0 : 0;

const handlePacks = (e: React.ChangeEvent<HTMLInputElement>) => {
    editProduct(Number(e.target.value), RecID, "packs")
}
const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    editProduct(Number(e.target.value), RecID, "weight")
    console.log(e.target.value)
}
const handlePallets = (e: React.ChangeEvent<HTMLInputElement>) => {
    editProduct(Number(e.target.value), RecID, "pallets")
    console.log(e.target.value)
}
  return (
    <TitleLayout>
        <SideWrapper>
            <ProductImage 
                id={img}
                size='sm'
            />
        </SideWrapper>
        <TitleRow>
            <TitleRowItem 
                name={`${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeID?.trim()}`}
                icon={<AppleIcon />}
            />
        </TitleRow>
        <TitleRow>
            <RowInput 
                handleInput={handlePallets}
                icon={<PalletIcon />}
                bgColor='#fff'
                isDisabled={false}
                value={Order_Pallets}
                iconColor='#297F76'

            />
            <RowInput 
                handleInput={handleWeight}
                icon={<ScalesIcon />}
                bgColor='#fff'
                isDisabled={false}
                value={OrderWeight}
                iconColor='#297F76'

            />
            <RowInput 
                handleInput={handlePacks}
                icon={<PackageIcon />}
                bgColor='#fff'
                isDisabled={false}
                value={OrderPacks}
                iconColor='#297F76'

            />
        </TitleRow>
        <TitleRow>
            <></>
        </TitleRow>
    </TitleLayout>
  )
}
const SideWrapper = styled.div`
    position: absolute;
    left: 1em;
    top: 0;
`
export default CefiProductItem