import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const QtyBoxWrapper = styled.div`
    
    width: ${props => props.size === 'md' ? '3.5em'
        : props.size === 'lg' ? '4em'
            : props.size === 'sm' ? '3em'
                : 'none'
    };
    height: ${props => props.size === 'md' ? '3.5em'
        : props.size === 'lg' ? '4em'
            : props.size === 'sm' ? '3em'
                : 'none'
    };
    background: ${props => props.problem ? 'tomato' 
        : props.content >= 100 ? '#297f76' 
            : 'rgba(41,127,118, 0.1)'
    };
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    `
const QtytBox = styled.div`
    height: 85%;
    width: 85%;
    border-radius: 50%;
    background: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    color: #297f76;
    
`
export default function CirclePercentElement({ problem, content, size }) {
    return (
        <QtyBoxWrapper
            size={size}
            problem={problem}
            content={content}
        >
            <QtytBox>
                {content}
            </QtytBox>
        </QtyBoxWrapper>
    )
}

CirclePercentElement.propTypes = {
    problem: PropTypes.bool,
    content: PropTypes.number,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

CirclePercentElement.defaultProps = {
    size: 'md',
    problem: false,
    content: 100
}



