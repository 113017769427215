import React, {useState} from 'react'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import { IPaymentItem } from '../../../../../../modules/API/Bills/models/payments'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { CalendatDay, ChequeIcon, ShekelIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import { convertToDate } from '../../../../../../modules/convertDateString'
import styled from 'styled-components'
import { currencyIconByCode } from '../../../../../../generic/artcoded/currency'

interface IProps extends IPaymentItem  {}

const PaymentItem = ({
  MutavName, ValueDate, PaySum, Currency, Details, Details1, Details2,
  Details3, PaymentType, ChequeNum
}: IProps) => {
  console.log(PaymentType)
  const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false)
  const remarkList = () => {
    return [Details, Details1, Details2, Details3].filter((item) => item.trim()) as string[]
  }
  const handleOpenCloseDetails = () => setIsOpenDetails(!isOpenDetails)
  const isCheck = () => {
    return PaymentType === 0 ? "שיק" : 'העברה'
  }
  return (
    <TitleLayout onClick={handleOpenCloseDetails}>
      <TitleRow>
        <TitleRowItem 
          name={MutavName?.trim()} 
          icon={<UserIcon />} 
        />
        <TitleRowItem 
          name={convertToDate(ValueDate)} 
          icon={<CalendatDay />} 
        />
      </TitleRow>
      <TitleRow>
        <TitleRowItem 
          name={`#${ChequeNum} - ${isCheck()}`} 
          icon={<ChequeIcon />} 
        />
      </TitleRow>
      <TitleRow>
        <TitleRowItem 
          name={`${PaySum.toLocaleString()}`} 
          icon={currencyIconByCode(Currency)} 
        />
      </TitleRow>
      {
        isOpenDetails && remarkList().length > 0 &&
          <RemarkWrapper>
            {
              remarkList().map((item, index) => 
                <RemarkItem key={index}>{item} ·</RemarkItem>
              )
            }
          </RemarkWrapper>
        
      }
    </TitleLayout>
  )
}
const RemarkWrapper = styled.div`
  width: 100%;
  background:#E9F0F0;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em 0;
  border-radius: 15px;
  
`
const RemarkItem = styled.div`
  width: 100%;
  padding: 0 0.5em;
  display: flex;
  justify-content: flex-end;
  font-size: 1.1em;
  color: #297F76;
`
export default PaymentItem