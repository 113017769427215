import React from 'react'
import styled from 'styled-components'
import { ISupplyMainList } from '../types/supplyMainList'
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn'
import { PhoneIcon, PlusIcon } from '../../../../../../generic/icons/Icons'
import AzmanotReheshItem from '../../../azmanotRehesh/Components/AzmanotReheshItem'
import { IOrderRehesh } from '../../../../../../modules/API/AzmanotRehesh/models/order'

type Props = {
  item: ISupplyMainList
  sendSms: (item: ISupplyMainList) => void
  index: number,
  handleSelectDay: (day: ISupplyMainList) => void
  handleSelectProduct: (day: ISupplyMainList, item: IOrderRehesh) => void
}

const SupplyDayColumnItem = ({
  item,
  index,
  handleSelectDay,
  handleSelectProduct,
  sendSms

}: Props) => {

  const handleSelect = () => {
    handleSelectDay(item)
  }
  const handleSelectProductItem = (prodItem: IOrderRehesh) => {
    handleSelectProduct(item, prodItem)
  }
  const handleSendSms = () => {
    sendSms(item)
  }
  return (
    <MainWrapper>
      <Header isToday={false}>
        {item.dateDesc}
        <IconAddWrapper>
            <CircleActiveFilterBtn
              func={handleSelect}
              >
              <PlusIcon />
            </CircleActiveFilterBtn>
          </IconAddWrapper>
          {
            item.list.length > 0 &&
            <IconAddWrapper
              isPrimal
            >
                <CircleActiveFilterBtn
                  func={handleSendSms}
                  >
                  <PhoneIcon />
                </CircleActiveFilterBtn>
              </IconAddWrapper>

          }
      </Header>
      <Body>
        {
          item.list.map((item, index) => 
            <AzmanotReheshItem 
              key={item.RecID}
              item={item}
              {...item}
              index={index}
              isGrower={false}
              handleSelectItemForStatus={() => {}}
              handleSelectProductItem={handleSelectProductItem}
            />
          )
        }
      </Body>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  min-width: 360px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-flow: column;
  gap: 1em;
  padding: 0.3em;
  margin-bottom: 1em;
`
const Header = styled.div<{isToday: boolean}>`
position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6em 1em;
  background: ${props => props.isToday ? '#8CC53D' : '#EFF3F4'};
  border-radius: 20px;
  font-size: 1.1em;
  color: ${props => props.isToday ? '#fff' : '#297F76'};
`
const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  direction: ltr;
  gap: 0.5em;
  padding: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
const IconAddWrapper = styled.div<{isPrimal?: boolean}>`
  position: absolute;
  left: ${props => props.isPrimal ? "none" : '0.2em'};
  right: ${props => props.isPrimal ? "0.2em" : 'none'};
  top: calc(50% - 1.04em);
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
`
export default SupplyDayColumnItem