 import styled from "styled-components";
import { useSelector } from 'react-redux'
import { useEffect } from "react";

const Wrapper = styled.div`
    padding: 0.2em 0.5em;
    text-align: right;
    border-radius: 5px;
    &:hover{
        background: #80e081;
    }    

`

export default function DbItem({id, label, patchDB, setChooseDb, setInput, input, setInputDefault, getID}) {
    //  console.log(id)
    // useEffect(() => {id === getID? setInputDefault(label) : setInputDefault('')}, [])
    // setInput(id === getID? label : '')
    if (id === getID){
        setInputDefault(label)
    }
    return(
        <Wrapper onClick={()=>{setInput(label); setChooseDb(id); patchDB(); console.log(id); setInputDefault(label)}}>
            {label}
        </Wrapper>
    );
}