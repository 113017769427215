import React from 'react'
import styled from 'styled-components'

type Props = {
    handleToggle: () => void;
    actriveToggle: boolean;
}

const PalletNumSwitcher = ({
    handleToggle,
    actriveToggle
}: Props) => {
    return (
        <ToggleWrapper onClick={handleToggle}>
            <InnterToggle active={actriveToggle}>
                <TextWrapper active={actriveToggle}>
                    פנימי
                </TextWrapper>
                <TextWrapper active={actriveToggle}>
                    חיצוני
                </TextWrapper>

            </InnterToggle>
        </ToggleWrapper>
    )
}
const ToggleWrapper = styled.div`
    width: 10em;
    height: 3.5em;
    border-radius: 30px;
    background: #EFF3F4;
    overflow: hidden; 
    display: flex;
`

const InnterToggle = styled.div<{active?: boolean}>`
    height: 100%;
    min-width: 100%;
    display: flex;
    transition: all 0.7s ease-in-out;
    transform: ${props => props.active ? 'translateX(-100%)' : 'translateX(0)'};
    cursor: pointer;
`

const TextWrapper = styled.div<{active?: boolean}>`
    position: relative;
    height: 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    user-select: none;
    
    :nth-child(1) {
        color: #297F76;
        opacity: ${props => props.active ? '0' : '1'};
        ::before {
            content: '';
            position: absolute;
            width: 2em;
            height: 2em;
            left: -0.3em;
            top: -0.5em;
            background: #297F76;
            border-radius: 50%;
            opacity: 0.7;
            transition: all 0.3s ease-in-out;
            transform: ${props => !props.active ? 'translateX(0)' : 'translateX(200%)'};
        }
        ::after {
            content: '';
            position: absolute;
            width: 2em;
            height: 2em;
            right: -0.3em;
            bottom: -0.5em;
            background: #297F76;
            border-radius: 50%;
            opacity: 0.7;
            transition: all 0.2s ease-in-out;
            transform: ${props => !props.active ? 'translateX(0)' : 'translateX(-200%)'};
        }
        
    }
    :nth-child(2) {
        color: tomato;
        opacity: ${props => !props.active ? '0' : '1'};
        ::before {
            content: '';
            position: absolute;
            width: 2em;
            height: 2em;
            left: -0.3em;
            top: -0.5em;
            background: tomato;
            border-radius: 50%;
            opacity: 0.7;
            transition: all 0.3s ease-in-out;
            transform: ${props => props.active ? 'translateX(0)' : 'translateX(200%)'};
        }
        ::after {
            content: '';
            position: absolute;
            width: 2em;
            height: 2em;
            right: -0.3em;
            bottom: -0.5em;
            background: tomato;
            border-radius: 50%;
            opacity: 0.7;
            transition: all 0.2s ease-in-out;
            transform: ${props => props.active ? 'translateX(0)' : 'translateX(-200%)'};
        }
    }
`
export default PalletNumSwitcher