import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
}

const ReceiptCreateLayout = ({children}: Props) => {
  return (
    <MainWrapper>
      <InnerWrapper>
          {children}
      </InnerWrapper>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

`
const InnerWrapper = styled.div`
  z-index: 0;
  width: 100%;
  /* max-width: 800px; */
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  align-items: center;
`

export default ReceiptCreateLayout