import React, {useEffect, useState, useContext, useMemo} from 'react'
import styled from 'styled-components'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout';
import ColumModal from '../../../../../generic/Layouts/ColumModal';
import ArizotAddBody from '../../arizot/arizotAddMenu/arizotAddBody';
import { ControlPanelWrapper } from '../../secondbar/modalAzmanot/modalAzmanot';
import ControlButton from '../../../../../generic/buttons/ControlButton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation  } from 'react-router-dom';
import { RootState } from '../../../../../../store/reducers/rootReducer';
import { ContextModal } from '../../../../../modules/modal/modalContext';
import ArizotViewMain, { IRemarkData } from '../../arizot/View/ArizotViewMain';
import { IPackPalletDefaultClient, IPackPalletItem } from '../../../../../modules/API/Packages/models/arizotLine';
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes';
import { Packages } from '../../../../../modules/API/Packages/packages';
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList';
import { IPackageTeudaData, IPacksPallets } from '../../../../../modules/API/Packages/models/createPackageOrderModel';
import RemarkModal from '../../arizot/View/RemarkModal';
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout';
import TeudotModalBody from '../../arizot/Modals/TeudotModalBody';
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal';
import CheckWindow from './CheckWindow';

interface IProps  {
    getDriverDate: () => void;
    controlFunc: () => void;
    saveToLogPackage: (action: number, areaID: number) => void
}


const PackageOverlay = ({getDriverDate, controlFunc, saveToLogPackage}: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const defaults = useSelector((state: RootState) => state.defaults.defaults);
    const isCreateTeudaImidiate = selectBrodMigdal(defaults, 617, 'Logical_Value')
    // const { id } = useParams<IParams>();
    console.log(isCreateTeudaImidiate)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const handleControlFunc = () => {
      getDriverDate()
      controlFunc()
      searchParams.delete('id');
      history.replace({
        search: searchParams.toString()
      });
    }
    const { TransferZone, carID, sender, reference, date, clientName, isReverseSender, finalFunc } = useSelector(
      (state: RootState) => state.driverDerucer.orderPackage
    );
    const [isOpenRemarkWindow, setIsOpenRemarkWindow] = useState<boolean>(false);
    const [isOpenCheckWindow, setIsOpenCheckWindow] = useState<boolean>(false);
    
    const [modal, setModal] = useContext(ContextModal);
    // const [isOpenTeudaWindow, setIsOpenTeudaWindow] = useState<boolean>(false);
    const [remarkData, setRemarkData] = useState<IRemarkData>();
    const [packages, setPackages] = useState<IPackPalletItem[]>();
    const [clientDefaultReciver, setClientDefaultReciver] =
      useState<IPackPalletDefaultClient[]>();
  
    const isAllowSave = useMemo(() => {
      const isAllowSave = packages?.some((e) => {
        if (!e.Qty) return false;
        return e.Qty > 0;
      });
      return isAllowSave;
    }, [packages]);
    // const handleOpenTeudaWindow = () => {
    //   setIsOpenTeudaWindow(!isOpenTeudaWindow);
    // }
    const getPackages = async () => {
      dispatch({ type: LOAD });
      try {
        const res = await Packages.getPackPalletList();
        const resModified = addIDForTheList(res.recommendations);
        setPackages(resModified);
        setClientDefaultReciver(res.defaultClient);
      } catch (err) {
      } finally {
        dispatch({ type: LOAD_END });
      }
    };


    const createTeuda = async (postData: IPackageTeudaData) => {
    
      try{
          // console.log(selectedDetailesList)
          let res = await Packages.createTeuda(postData)
          sendEmail(res.PD_Num)
          setModal({
              open: true,
              value: "תעודה נוצרה בהצלחה",
              mood: true
          })
          setTimeout(() => {
              setModal({
                  ...modal,
                  open: false
              })
          }, 3000)
          
    
      } catch (err) {
          setModal({
              open: true,
              value: "משהו השתבש",
              mood: false
          })
          setTimeout(() => {
              setModal({
                  ...modal,
                  open: false
              })
          }, 3000)
      } finally {

      }
  }
    const saveOrder = async () => {
      if (!packages) return;
      dispatch({ type: LOAD });
  
      const postPacs = packages.reduce((acc: IPacksPallets[], cur) => {
        console.log(cur);
        if (!cur?.Qty) return acc;
        const item = {
          Pack_Pallet: cur.Pack_Pallet,
          PackID: cur.PackID,
          Qty: cur.Qty,
          Remark: cur.Remark || "",
          Price: 0,
          Line_Total: 0,
        };
        acc.push(item);
        return acc;
      }, []);
      
      const reciever = clientDefaultReciver ? 
      clientDefaultReciver[0].ClientID : 0

      const postData = {
        ClientID_Recipient: isReverseSender ? sender: reciever,
        ClientID_Sender: isReverseSender ? reciever: sender ,
        Reference: '',
        PD_Num: 0,
        PO_Date: date,
        Qty: 0,
        Vehicle_Code: carID,
        Vehicle_Num: 0,
        Driver_Name: '',
        PO_Status: 0,
        Remarks: "",
        PO_Time: "0",
        Packs_Pallets: postPacs,
        TransferZone: TransferZone,
        Pickup: true
      };

      
      try {
          const res = await Packages.createPackageOrder(postData)

          const postDataTeuda = {
            PO_Num: res.PO_Num,
            ClientID_Recipient: res.ClientID_Recipient,
            ClientID_Sender: res.ClientID_Sender,
            PD_Date: date,
            Vehicle_Num: res.Vehicle_Num?.toString(),
            Driver_Name: res.Driver_Name,
            Remarks: "",
            Packs_Pallets: postPacs.map(pack => {
                let item = {
                  Line_Total: pack.Line_Total,
                      PackID: pack.PackID,
                      Pack_Pallet: pack.Pack_Pallet,
                      Price: pack.Price,
                      Qty: pack.Qty || 0,
                }
                
               
                return item
            })
          }
          if(isCreateTeudaImidiate) {
           await createTeuda(postDataTeuda);
           
          }

          if(finalFunc) {
            finalFunc(res)
          }
          saveToLogPackage(1, TransferZone)
          // history.goBack()
          getDriverDate()
          controlFunc()
          setModal({
              open: true,
              value: 'ההזמנה נוצרה בהצלחה',
              mood: true
          })
          setTimeout(() => {
              setModal({
                  ...modal,
                  open: false
              })
          }, 3000)
      } catch (err) {
          setModal({
              open: true,
              value: "משהו השתבש",
              mood: false,
            });
            setTimeout(() => {
              setModal({
                open: false,
                value: "2",
              });
            }, 3000);
      } finally {
          dispatch({ type: LOAD_END });
      }
    };

  const sendEmail = async (teudaNum: number) => {
        try {
            const res = await Packages.sendEmail(teudaNum);
            setModal({
                open: true,
                value: `דוא"ל נשלח בהצלחה`,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } catch (err: any) {
            console.log(err.response)
            if(err.response.status === 405) {
                setModal({
                    open: true,
                    value: "אין מייל",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            } else {
                setModal({
                    open: true,
                    value: "משהו השתבש",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
        }
    }
    const handleControlOpenCheckWindow = () => {
      setIsOpenCheckWindow(!isOpenCheckWindow);
    }
    const checkWindowProps = {
      save: saveOrder,
      list: packages?.filter(e => e.Qty && e.Qty > 0) || [],
      close: handleControlOpenCheckWindow
    }
    const handlePatchState = (qty: number, id: string) => {
      if (!packages) return;
      const newState = [...packages];
      const found = newState.find((e) => e.id === id);
      if (!found) return;
      const indexOfItem = newState.indexOf(found);
      newState[indexOfItem].Qty = qty;
      setPackages(newState);
    };
  
    const handleSave = (inputRemark: string) => {
      if (!packages) return;
      const newState = [...packages];
      const found = newState.find((e) => e.id === remarkData?.id);
      console.log(remarkData?.id);
      if (!found) return;
      const indexOfItem = newState.indexOf(found);
      newState[indexOfItem].Remark = inputRemark;
      setPackages(newState);
      handleRemarkModal();
    };
  
    const handleRemarkModal = () => {
      setIsOpenRemarkWindow(!isOpenRemarkWindow);
    };
  
    useEffect(() => {
      getPackages();
    }, []);
    const remarkWindowProps = {
      data: remarkData,
      close: handleRemarkModal,
      handleSave: handleSave,
      isActive: true,
    };
    console.log(12123)
    return (
        <MainWrapper>
           {
              isOpenCheckWindow && 
                <ColumModal 
                  title={"בדיקת פרטים"} 
                  Children={CheckWindow} 
                  controlFunc={handleControlOpenCheckWindow} 
                  childrenProps={checkWindowProps}
                />
           }
            {
              !id ?
                <OrderlikeLayout label={`החזרת אריזה - ${clientName}`}>
                 
                    {isOpenRemarkWindow && (
                    <ColumModal
                      title={remarkData?.title || ""}
                      Children={RemarkModal}
                      controlFunc={handleRemarkModal}
                      childrenProps={remarkWindowProps}
                    />
                    )}
                    <ControlSearchPannelLayout>

                    </ControlSearchPannelLayout>
                    <ArizotAddBody
                      sideToggle={"none"}
                      packs={packages}
                      patchState={handlePatchState}
                      handleRemarkModal={handleRemarkModal}
                      setRemarkData={setRemarkData}
                    />
                    <ControlPanelWrapper>
                    
                        <ControlButton lable={"חזרה"} handleClick={controlFunc} />
                    
                    {isAllowSave && (
                        <ControlButton lable={"שמור"} handleClick={handleControlOpenCheckWindow} />
                        )}
                    </ControlPanelWrapper>
                    
                </OrderlikeLayout>
                :
                <ArizotViewMain
                  searchParam={id} 
                  closeFunc={handleControlFunc}
                  // handleOpenTeudaWindow={handleOpenTeudaWindow}
                  isAllowTeuda={true}
                />
            }
        </MainWrapper>
    );
  };
const MainWrapper = styled.div`
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;

`
export default PackageOverlay