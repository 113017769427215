import React from 'react';
import { IconProps } from './model/IconInterface';

const UserIcon: React.FC<IconProps> = ({ size = 20, color = '#297F76', fill }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill ? color : 'none'}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="7" r="4" />
      <path d="M12 14c-2.33 0-7 1.17-7 3.5V21h14v-3.5c0-2.33-4.67-3.5-7-3.5z" />
    </svg>
  );
};

export default UserIcon;