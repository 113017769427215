import styled from "styled-components";

export const ResonWrapper = styled.div`
    position: relative;
    height: 35em;
    width: 100%;
    border-radius: 30px;
    background: hsl(174,11,60);
    /* background: red; */
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    padding: 1em 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* overflow: hidden; */
`

export const ResonContentWrapper = styled.div`
    background: #fff;
    width: 100%;
    height: 85%;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    color: #297f76;
    padding: 0 1em;
    z-index: 1;
`
export const ResonContentItemWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: yellow; */
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3em;
    /* gap: 0.5em; */
`
export const ResonContentItemTitle = styled.div`
    font-size: 1.5em;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transform: translateY(-1.5em);
    background: ${props => props.primal? 'tomato' : '#297f76'} ;
    padding: 0.5em;
    border-radius: 30px ;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    /* border-bottom: 2px solid #297f76; */
    
`
export const ResonContentItemElement = styled.div`
    width: 80%;
    /* height: 100%; */
    background: #fff;
    padding: 0.5em 0;
    border-radius: 30px;
    font-size: 0.9em;
    /* margin-bottom: 0.5em; */
    transform: translateY(-1.5em);
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    
`
export const ResonBtnAcceptWrapper = styled.div`
    /* height: 2em; */
    width: 60%;
    padding-bottom: 0.5em;
    transform: translateY(-1em);
    /* background: blue; */
`
