import React, {useState} from 'react'
import styled from 'styled-components'

type Props = {
    isDisabled?: boolean,
    defaulValue: number,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;

}

const data = [
    {id: 0, name: 'חישוב לפי משקל'},
    {id: 1, name: 'חישוב לפי כמות'},
]

const SelectType = ({
    isDisabled, defaulValue, onChange
}: Props) => {
    const [value, setValue] = useState<number>(defaulValue);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(Number(e.target.value));
        onChange(e);
    }
  return (
    <MainSelect
        disabled={isDisabled}
        value={value}
        onChange={handleChange}
    >
        {
            data.map(item => 
                <Option key={item.id} value={item.id}>{item.name}</Option>
            )
        }
    </MainSelect>
  )
}

const MainSelect = styled.select`
    width: 150px;
    position: relative;
    padding-right: 0.5em;
    text-align: right;
    direction: rtl;
    font-size: 1em;
    border-radius: 10px;
    border: 2px solid #297f76;
    color: #75759e;
    outline: none;
    cursor: pointer;
`
const Option = styled.option``

export default SelectType