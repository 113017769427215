import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

const TableHeadCell = ({
    children
}: Props) => {
  return (
    <TableHeadCellStyle>
        {children}
    </TableHeadCellStyle>
  )
}
const TableHeadCellStyle = styled.th`
    min-width: 60px;
    height: 1em;
    border-radius: 15px 15px 0 0;
    padding: 0.2em;
    background: #81B4AF;
    color: #fff;
    /* text-align: right; */
`
export default TableHeadCell