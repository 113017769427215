import React from 'react'
import { IRecieptItemDetailes, IRecieptTitleIltem } from '../../../../../modules/API/Reciept/models/receipt'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import { convertToDate } from '../../../../../modules/convertDateString'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { CalendatDay, DeleteIcon, InvoiceIcon, PrintIcon, ShekelIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../../knisot/Components/SwipeContent'
import styled from 'styled-components'

interface IProps extends IRecieptTitleIltem {
  Lines?: IRecieptItemDetailes[],
  handleSelectForEdit?: (id: number) => void,
  clientCash?: number,
  printPDF?: (id: number) => void
  handleDelete?: (id: number) => void
}

const ReceiptTitleLine = ({
  DateReg, CustomerName, ReceiptNum, TotalSum, FilledBy, 
  handleSelectForEdit, clientCash, CustomerID, Canceled, printPDF,
  handleDelete
}: IProps) => {
  const date = convertToDate(DateReg)
  console.log(date)
  const [isSHowDelete, setIsShowDelete] = React.useState<boolean>(false)
  const handleOpenDetails = () => {
    if (!handleSelectForEdit) return
    handleSelectForEdit(ReceiptNum)
  }
  const handlePrintPDF = () => {
    if (!printPDF) return
    printPDF(ReceiptNum)
  }
  const handleDeleteFunc = () => {
    if (!handleDelete) return
    handleDelete(ReceiptNum)
  }
  console.log(clientCash, CustomerID)
  return (
    <TitleLayout isDraggable>
      <SwipeableListItem
        swipeRight={{
          content: <SwipeContent text={'עיון'}  />,
          action: handleOpenDetails
        }}
        swipeLeft={{
          content: <SwipeContent text={'מחיקה'} isPrimal position='right' />,
          action: () => {setIsShowDelete(!isSHowDelete)}
        }}
        blockSwipe={!handleSelectForEdit}
        threshold={0.3}
      >
        <MainWrapper>
          <IconWrapper
            onClick={handlePrintPDF}
          > 
            <PrintIcon />
          </IconWrapper>
          <BodyTitle>
            <TitleRow>
              {/* <TitleRowItem name={`נוצר על ידי - ${FilledBy.trim()}`} icon={<UserIcon />}/> */}
              <TitleRowItem name={date} icon={<CalendatDay />}/>
              <TitleRowItem 
                name={`${ReceiptNum}`} 
                icon={<InvoiceIcon />}
                iconColor={Canceled ? 'tomato' : ''}
              />
            </TitleRow>
            <TitleRow>
              <TitleRowItem 
                name={`${CustomerName.trim()}`} 
                icon={<UserIcon />}
                iconColor={clientCash === CustomerID ? 'tomato' : ''}
              />
              <TitleRowItem name={TotalSum.toLocaleString()} icon={<ShekelIcon />}/>
            </TitleRow>
          </BodyTitle>
          {
            isSHowDelete &&
              <DeleteButton
                onClick={handleDeleteFunc}
              >
                <DeleteIcon />
              </DeleteButton>

          }
        </MainWrapper>
      </SwipeableListItem>
    </TitleLayout>
  )
}
const MainWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    /* background: red; */
`
const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  /* background: red; */
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297F76;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    i {
      transform: scale(1.1);
    }
    
  }
`
const BodyTitle = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
const DeleteButton = styled.div`
  cursor: pointer;
  height: 3em;
  width: 3em;
  margin-left: 1em;
  border-radius: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: tomato;
`
export default React.memo(ReceiptTitleLine)