import React, {useState, useMemo} from 'react'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultID: number
  setLineType: React.Dispatch<React.SetStateAction<number>>
  required?: boolean
}

const LINE_TYPE = [
  {
    ID: 1,
    Desc: '1'
  },
  {
    ID: 2,
    Desc: '2'
  },
]

const SearchLineType = ({
  defaultID, setLineType, required
}: Props) => {
  const defLineType = LINE_TYPE.find((item) => item.ID === defaultID)
  const [inputLineType, setInputLineType] = useState<string>(defLineType?.Desc.trim() || "")
  const [selectedLineType, setSelectedLineType] = useState<{ID: number, Desc: string} | null>(() => defLineType || null)
  const [isOpenListLineType, setIsOpenListLineType] = useState<boolean>(false)

  const handleSearchLineType = (e: string) => {
    setIsOpenListLineType(true)
    setInputLineType(e)
    if (!e) {
      setSelectedLineType(null)
    }
  }
  const handleSelectLineType = (item: {ID: number, Desc: string}) => {
    setSelectedLineType(item)
    setInputLineType(item.Desc.trim())
    setIsOpenListLineType(false)
    setLineType(item.ID)
  }
  const renderLineTypeList = useMemo(() => {
    if (!LINE_TYPE) return []
    return LINE_TYPE.filter((item) => {
      return item.Desc.trim().toLowerCase().includes(inputLineType.toLowerCase().trim())
    })
  }, [inputLineType])

  return (
    <SearchFromTheList 
      input={inputLineType}
      list={renderLineTypeList}
      itemDisplayName='Desc'
      nameIdElement='ID'
      isNeedFullObject
      placeHolder={'בחר סוג שורה'}
      searchFunc={handleSearchLineType}
      selectFunc={handleSelectLineType}
      isOpenList={isOpenListLineType}
      size={'full'}
      required={!selectedLineType}
    />
  )
}

export default SearchLineType