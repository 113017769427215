import { requests } from "../config";

export const Bills = {
    // DateFrom=1&DateTo=1 - query string
    getBills: (reqString: string) => requests.get(`/invoice/grower/?${reqString}`, 'go'),
    getBillDetailsByID: (id: string | number) => requests.get(`/invoice/grower/${id}`, 'go'),
    getCustomerBills: (reqString: string) => requests.get(`/invoice/customer/?${reqString}`, 'go'),
    getCustomerBillDetailsByID: (id: string | number) => requests.get(`/invoice/customer/${id}`, 'go'),
    printCustomerBill: (id: string | number) => requests.get(`/invoice/customer/${id}/pdf`, 'go'),
    printGrowerBill: (id: string | number) => requests.get(`/invoice/grower/${id}/pdf`, 'go'),
    //DateFrom=2&DateTo=3
    getPaymentsByDateRange: (reqString: string) => requests.get(`cheque?${reqString}`, 'go'),
}