import React, { useContext } from 'react'
import styled from 'styled-components'
import { LikutModals } from '../../../../../../modules/Context/CefiNum'
import PassFieldReson from './PassFieldReson'
import ResonItem from './ResonItem'

const ResonsListWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
`
export default function ResonsList({resons, setResonId, resonId, setInputPass, artPass, acceptFunc}) {
    const [resonState, setResonState] = useContext(LikutModals);
   
    return (
        <ResonsListWrapper>
            {
                resonState?.props?.packType === '2' ? //// if pack = ihidot =>
                    resons?.map((item, index) => {
                            return item.id !== 1 ? <ResonItem key={index} {...item} setResonId={setResonId} resonId={resonId} /> : ''  
                        }
                    ) :
                    resons?.map((item, index) => <ResonItem key={index} {...item} setResonId={setResonId} resonId={resonId} />)
            }
            
            <PassFieldReson resonId={resonId} setInputPass={setInputPass} artPass={artPass} acceptFunc={acceptFunc}/>
        </ResonsListWrapper>
    )
}
