import React from 'react'
import styled from 'styled-components';

type Props = {
    defValue: number
}

function DetailsInput({defValue}: Props) {
  return (
    <Input
        defaultValue={defValue}
        type="number"
        inputMode="decimal"
        readOnly={true}
    />
  )
}

export default DetailsInput

const Input = styled.input`
  width: 70%;
  border-radius: 30px;
  background: #fff;
  direction: rtl;
  padding: 0 0.5em;
  outline: none;
  appearance: none;
  border: 2px solid #fff;
  color: #75759e;
`;