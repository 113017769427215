import React from 'react'
import styled from 'styled-components'
import { ArrowIcon, PackageIcon, TrophyIcon } from '../../../../../../generic/icons/Icons'
import { IStatisticWorkerItem } from '../../../../../../modules/API/Gathering/models/statisticWorker'

interface Props extends IStatisticWorkerItem {
    index: number,
    avgCollection: number
}

const TopWorkersItem = ({
    index, Qty, WorkerName, avgCollection
}: Props) => {
    const moreThenAvg = Math.round(Qty / avgCollection * 100)
    console.log()
  return (
    <MainWrapper>
        <AwardWrapper position={index}>
            <TrophyIcon />
            <AwardParagraph>
                {WorkerName}
            </AwardParagraph>
        </AwardWrapper>
        <ContentWrapper>
            {/* <RowItem>
                <p>{Qty} <PackageIcon /></p>
            </RowItem> */}
            <RowItem>
                <p>ממוצע <ArrowIcon position={'top'} /> {moreThenAvg}%</p>
            </RowItem>
            <RowItem>
                <p>{Qty} <PackageIcon /></p>
            </RowItem>
        </ContentWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 30%;
    background: #fff;
    border-radius: 30px;
    display: flex;
    -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
    filter: drop-shadow(2px 3px 3px rgb(215,231,242));

`
const AwardWrapper = styled.div<{position: number}>`
    width: 50%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 1em;
    justify-content: center;
    align-items: center;
    
    i {
        font-size: 7em;
        color: ${props => props.position === 0 ? '#f7ba00' : 
                    props.position === 1 ? '#bababa' : '#c77b30'
        };
        -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
        filter: drop-shadow(2px 3px 3px rgb(215,231,242));
        
    }
`
const AwardParagraph = styled.div`
    font-size: 1.3em;
    color: #297f76;
`

const ContentWrapper = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 0 1.5em 0 0;
`
const RowItem = styled.div`
    color: #297f76;
    width: 100%;
    display: flex;
    gap: 0.1em;
    justify-content: flex-end;
    align-items: center;
    /* background: yellow; */
    font-size: 1.2em;
    i {
        font-size: 0.9em;
    }
`
export default TopWorkersItem