import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContextModal } from '../../../modules/modal/modalContext'
import { Formik, Field, Form } from 'formik';
import './Forma.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD, LOAD_END } from '../../../../store/actions/actionsTypes'

// const FilterScreen = styled.div`
//     position: absolute;
//     background: rgba(61,119,235,0.4);
//     /* background: rgba(169,255,208, 0.2); */
//     width: 100%;
//     height: 100%;
//     z-index: 0;
//     transform: translateX(-150px)
// `

const WrapperMain = styled.div`
    shadow: none;
    position: relative;
    min-height: 80vh;
    /* background: red; */
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    width: 100%;
    padding-left: 150px;
    @media screen and (max-width: 1200px){
        padding-left: 0;
    }
`

const Title = styled.h1`
    font-size: 2.5em;
    color: #573d16;
    z-index: 2;
    ${'' /* background: red; */}
    ${'' /* margin-right: 28%; */}
`
const Label = styled.label`
    z-index: 2;
    color: ${props => props.primary ? '#573d16' : '#573d16'};
    font-size: 1.7em;
    margin-bottom: 0.5em;
`

const Input = styled.input`
    z-index: 2;
    border: none;
    font-size: 1.5em;
    padding: 0.5em 1.2em;
    max-width: 300px;
    border-radius: 5px;
    -webkit-appearance: none;
    border: 2px solid white;
    text-align: right;
    ::placeholder{
        color: rgba(0,0,0,0.3);
        font-size: 0.8em;
    }
    @keyframes border-pulsate {
    0%   { border-color: rgba(128,224,129,1); }
    50%  { border-color: rgba(128,224,129,0); }
    100% { border-color: rgba(128,224,129,1); }
    }   

    &:focus {
        border: 2px solid #80e081;
        color: #80e081;
        outline: none;
        animation: border-pulsate 2s infinite;
        Label {
            display: none;
        }
    }
    @media screen and (max-width: 600px){
        max-width: 300px;
    }
    @media screen and (max-width: 380px){
        
    }
`

const Wrapper = styled.section`
    ${'' /* background: linear-gradient(90deg, rgba(128,224,129,1) 0%, rgba(128,224,129,1) 50%, rgba(255,255,255,0) 50%); */}
    ${'' /* min-height: 100vh; */}
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    @media(max-width: 600px){
        min-height: 100vh;
    }
    
     
`
const Container = styled.div`
    width: 35%;
    height: 150px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    
    @media(max-width: 600px){
        width: 100%;
        /* height: 250px; */
    }
    
`

const ContainerBtn = styled(Container)`
    width: 100%;
    height: 50px;
    margin-top: 50px;
`
const Button = styled.button`
    background: white;
    /* padding: 0.7em 1.5em; */
    font-size: 1em;
    font-weight: 600;
    border: 3px solid rgba(128,224,129,1);
    border-radius: 5px;
    color: #80e081;
    cursor: pointer;
    transition: 0.2s;
    width: 6em;
    height: 4em;
    z-index: 2;
    p{
        display: block;
    }
    i{
        display: none;
        animation: spin 1s infinite ;
        @keyframes spin {
            0% {transform: rotate(0);}
            100% {transform: rotate(360deg);}
        }
    }
    :disabled{
        p {
            display: none;
        }
        i {
            display: block;
        }
    }
    &:focus {
        outline: none;
    }
    &:hover {
        background: rgba(128,224,129,1);
        color: white;
        ${'' /* border-color: white */}
    }
    
`
const FormaContainer = styled.div`
    /* display: none; */
    /* style={{ display: 'flex', flexFlow: 'row-reverse wrap', justifyContent: 'space-around', alignItems: 'center', maxWidth: '1280px', height: '100%'}} */
    height: 100%;
    max-width: 700px;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-around;
    align-items: center;
    
`

export default function Forma() {
    const loadStatus = useSelector(status => status.mainReducer.loading)
    const axios = require('axios')
    const dispatch = useDispatch()

    const [modal, setModal] = useContext(ContextModal)


    AOS.init();
    return (
        
        <WrapperMain className="main-forma-confermed" id="form">
            {/* <FilterScreen></FilterScreen> */}
            {/* <PicWrapper><img src="/images/mailbox.png" /></PicWrapper> */}
            <Wrapper>
            
                <Title>צור קשר</Title>
                <Formik
                    initialValues={{
                        firstName: '',
                        textAdd: '',
                        email: '',
                        tel: '',
                    }}
                    onSubmit={async (values) => {
                        dispatch({ type: LOAD })
                        // console.log(JSON.stringify(values, null, 2))
                        axios({
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            url: 'https://formspree.io/f/xayllvqd',
                            method: 'post',
                            data: JSON.stringify(values, null, 2)
                        })
                            .then(res => {
                                // console.log(res)
                                dispatch({ type: LOAD_END })
                                if (res.status === 200) {
                                    // console.log('thanks')
                                    setModal({
                                        open: true,
                                        value: "Thanx for reg",
                                        mood: true
                                    })
                                    setTimeout(() => {
                                        setModal({
                                            open: false,
                                            value: '2'

                                        })
                                    }
                                        , 3000)
                                }
                            })
                            .catch(err => {
                                // console.log(err.response.data.error)
                                dispatch({ type: LOAD_END })
                                if (err.response.status === 400) {
                                    setModal({
                                        open: true,
                                        value: err.response.data.error,
                                        mood: false
                                    })
                                    setTimeout(() => {
                                        setModal({
                                            open: false,
                                            value: '2'

                                        })
                                    }
                                        , 3000)
                                }
                            })
                    }}
                >
                    <Form >
                    
                        <FormaContainer>
                        
                            <Container>
                                <Label primary as={Label} htmlFor="firstName" data-aos-once="true" data-aos="fade-left">שם</Label>
                                <Field primary
                                    data-aos="fade-right"
                                    data-aos-once="true"
                                    as={Input}
                                    id="firstName"
                                    name="firstName"
                                    placeholder="שם"

                                />
                            </Container>
                            <Container>
                                <Label htmlFor="tel" data-aos-once="true" data-aos="fade-left">טלפון</Label>
                                <Field
                                    data-aos="fade-right"
                                    data-aos-once="true"
                                    as={Input}
                                    id="tel"
                                    name="tel"
                                    placeholder="050 000 0000"
                                    type="tel"
                                    maxLength="12"
                                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                />
                            </Container>
                            <Container>
                                <Label primary htmlFor="email" data-aos-once="true" data-aos="fade-left">אימייל</Label>
                                <Field
                                    data-aos="fade-right"
                                    data-aos-once="true"
                                    require
                                    primary
                                    as={Input}
                                    id="email"
                                    name="email"
                                    placeholder="template@agroline.co.il"
                                    type="email"
                                />
                            </Container>
                            <Container>
                                <Label htmlFor="textAdd" data-aos-once="true" data-aos="fade-left">פרטים נוספים</Label>
                                <Field
                                    data-aos="fade-right"
                                    data-aos-once="true"
                                    as={Input}
                                    id="textAdd"
                                    name="textAdd"
                                    placeholder="השאר הודעה"
                                    min="2"
                                />
                            </Container>
                            <ContainerBtn>
                                <Button disabled={loadStatus} type="submit" >
                                    <p>שלח</p>
                                    <i className="fas fa-spinner"></i>
                                </Button>
                            </ContainerBtn>
                        </FormaContainer>
                    </Form>
                </Formik>
            </Wrapper>
        </WrapperMain>
    );
}