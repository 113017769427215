import { useRef, RefObject } from "react";

type FocusHook = [RefObject<HTMLElement | null>, () => void];

const useFocus = (): FocusHook => {
  const htmlElRef = useRef<HTMLElement | HTMLInputElement | null>(null);

  const setFocus = () => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  };

  return [htmlElRef, setFocus];
};

export default useFocus;