import React, {useState, useCallback, useMemo, useEffect} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Packages } from '../../../../../modules/API/Packages/packages'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import FilterPackageMovments from './Components/FilterPackageMovments'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { IClientsItem } from '../../../../../modules/API/Defaults/models/customers'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import CustomBlockLoader from '../../knisot/generic/CustomBlockLoader'
import Table from '../../procedures/Q8/Layouts/Table/Table'
import { PACKAGE_MOVEMENTS_TITLE } from './Components/TableMovementsTitle'
import TableHeadCell from '../../procedures/Q8/Layouts/Table/TableHeadCell'
import { IPackMovementsitem } from '../../../../../modules/API/Packages/models/packsMovments'
import TableBodyMovements from './Components/TableBodyMovements'
import TableHead from '../../procedures/Q8/Layouts/Table/TableHead'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { CalendatDay, PackageIcon, PalletIcon } from '../../../../../generic/icons/Icons'
import { LOAD, LOAD_END, SELECT_DATE_FOR_PACKAGES } from '../../../../../../store/actions/actionsTypes'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { convertToDate } from '../../../../../modules/convertDateString'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IClientItem } from '../../../../../modules/API/Defaults/models/clients'
import { IGetReuqestResponseNode, IPalletTypeItem } from '../../../../../modules/API/Defaults/models/pallets'
import HeartIcon from '../../../../../generic/icons/IconLib/HeartIcon'
import InvoiceIcon from '../../../../../generic/icons/IconLib/InvoiceIcon'
import FilterIcon from '../../../../../generic/icons/IconLib/FilterIcon'
import UserIcon from '../../../../../generic/icons/IconLib/UserIcon'


const PacksMovements = () => {
  const dispatch = useDispatch()
  // DateFrom=1&DateTo=1&ClientID=2&PackIDs=1,2 get query
  const customerList: IClientsItem[] = useSelector((state: RootState) => state.defaults.clientList.list)
  const packageList: IPackagesItem[] = useSelector((state: RootState) => state.defaults.packageList)
  const {
    data: clientList,
    isLoading: isLoadingClients
  } = useDataFetcher<IClientItem[]>(
    Defaults.getClientsGo, `?ClientType=0`
  )
  const {
    data: palletList,
    isLoading: isLoadingPallets
  } = useDataFetcher<IGetReuqestResponseNode>(
    Defaults.getPalletTypes, `data`
  )

  const defClient = () => {
    if(!clientList || clientList.length > 1) return null
    return clientList[0]
  }
  useEffect(() => {
    if(clientList?.length === 1) {
      setSelectedClient(clientList[0])
    }
    if(clientList) {
      setIsOpenFilter(true)
    }
  }, [clientList])
  
  const [pachagesData, setPachagesData] = useState<IPackMovementsitem[] | null>(null)
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [searchPackage, setSearchPackage] = useState<string>("");
  
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")

  //Client
  const [selectedClient, setSelectedClient] = useState<IClientItem | null>(() => defClient());
  const [selectedPackage, setSelectedPackage] = useState<IPackagesItem | null>(null);
  const [selectedPallet, setSelectedPallet] = useState<IPalletTypeItem | null>(null);
  const handleSelectPallet = useCallback((e: IPalletTypeItem | null) => {
    setSelectedPallet(e)
  }, [])
  const handleSelectClient = useCallback((e: IClientItem | null) => {
    setSelectedClient(e)
  }, [])
  const handleSelectPackage = useCallback((e: IPackagesItem | null) => {
    setSelectedPackage(e)
  }, [])


  const handleSearch = useCallback((e: string) => {
    setSearchPackage(e)
  }, []) 

  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter)
  }
  
  const getPackageList = async () => {
    dispatch({type: LOAD})
    const string = !selectedPackage && !selectedPallet ?
      `DateFrom=${startDate}&DateTo=${endDate}&ClientID=${selectedClient?.ClientID}`
        : selectedPackage && selectedPallet ?
          `DateFrom=${startDate}&DateTo=${endDate}&ClientID=${selectedClient?.ClientID}&PackIDs=${selectedPackage?.PackID}&PalletIDs=${selectedPallet?.Pallete_Type}`
            : selectedPackage && !selectedPallet ?
              `DateFrom=${startDate}&DateTo=${endDate}&ClientID=${selectedClient?.ClientID}&PackIDs=${selectedPackage?.PackID}`
                : selectedPallet && !selectedPackage ?
                  `DateFrom=${startDate}&DateTo=${endDate}&ClientID=${selectedClient?.ClientID}&PalletIDs=${selectedPallet?.Pallete_Type}`
                    : `DateFrom=${startDate}&DateTo=${endDate}&ClientID=${selectedClient?.ClientID}`
    try {
      const res = await Packages.packageMovements(string)
      setPachagesData(res)
      handleOpenFilter()
    } catch( err) {
      
    } finally {
      dispatch({type: LOAD_END})

    }
  }
  const filterProps = {
    customerList: clientList,
    packageList: packageList,
    palletList: palletList?.pallets,
    handleSelectPallet: handleSelectPallet,
    handleSelectClient: handleSelectClient,
    handleSelectPackage: handleSelectPackage,
    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate,
    fetchFunc: getPackageList,
    selectedPallet: selectedPallet,
    selectedPackage: selectedPackage,
    selectedClient: selectedClient,
    defClient: defClient(),
    
  }

  const renderTable = useMemo(() => {
    if(!pachagesData) return []
    return pachagesData.filter((item) => 
      item.SenderName.trim().toLowerCase().includes(searchPackage.toLowerCase())
        || item.RecieverName.trim().toLowerCase().includes(searchPackage.toLowerCase())
          || item.Pack_Name.trim().toLowerCase().includes(searchPackage.toLowerCase())
    )

  }, [pachagesData, searchPackage])
  return (
    <OrderlikeLayout
      label="תנועות אריזה"
    >
      <TitleLayout>
        <TitleRow>
          <TitleRowItem 
            name={`${convertToDate(startDate)} - ${convertToDate(endDate)}`}
            icon={<CalendatDay />}
          />
        </TitleRow>
        <TitleRow>
          <TitleRowItem 
            name={`${selectedClient?.ClientName?.trim() || ""}`}
            icon={<UserIcon fill />}
          />
        </TitleRow>
        {
          selectedPackage &&
          <TitleRow>
            <TitleRowItem 
              name={`${selectedPackage?.Pack_Name?.trim() || ""}`}
              icon={<PackageIcon />}
            />
          </TitleRow>
        }
        {
          selectedPallet &&
          <TitleRow>
            <TitleRowItem 
              name={`${selectedPallet?.Pallete_Desc?.trim() || ""}`}
              icon={<PalletIcon />}
            />
          </TitleRow>
        }
      </TitleLayout>
      {
        isOpenFilter &&
          <ColumModal 
            title='סינון'
            childrenProps={filterProps}
            Children={FilterPackageMovments}
            controlFunc={handleOpenFilter}
          />
      }
      <ControlSearchPannelLayout>
        <CircleActiveFilterBtn
          withAction={false}
          func={handleOpenFilter}
        >
          <FilterIcon />
        </CircleActiveFilterBtn>
        <SearchInput 
          input={searchPackage}
          setInput={handleSearch}
        />
      </ControlSearchPannelLayout>
      {
        !pachagesData ? null : 
          <Table>
            <TableHead>
              {
                PACKAGE_MOVEMENTS_TITLE.map((item, index) => 
                <TableHeadCell key={index}>
                    {item}
                  </TableHeadCell>
                )
              }
            </TableHead>
            <TableBodyMovements 
              list={renderTable}
            />
          </Table>
      }
    </OrderlikeLayout>
  )
}

export default PacksMovements