import { AnyAction } from "redux"
import { CLEAR_CROSS_MODULE_STATE, SET_XTOTAL_TEUDOT_DETAILS } from "../actions/actionsTypes"

export interface IQueriesState{
    xTotalCountTeudotDetails: string | null;
}

const initialState: IQueriesState = {
    xTotalCountTeudotDetails: null,
   
}

const crossModulesQueries = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SET_XTOTAL_TEUDOT_DETAILS:
            return {
                ...state,
                xTotalCountTeudotDetails: action.payload
            }
        case CLEAR_CROSS_MODULE_STATE:
            return {
                ...initialState
            }
        default: return state
    }
}

export default crossModulesQueries