
import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';

import * as serviceWorker from './serviceWorker.js';
import { Provider } from 'react-redux';
import store from './store/store';


import './i18n';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element')
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>
) 

// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
