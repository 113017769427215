import React from 'react'
import { IPalletDetailes } from '../../../../../modules/API/Pallets/models/palletList'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import styled from 'styled-components'
import ProductImage from '../../queries/TeudotDetails/Components/ProductImage'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon, PalletIcon, ScalesIcon } from '../../../../../generic/icons/Icons'

interface IPropsProductPallet extends IPalletDetailes {

}

const PalletDetailsProductItem = ({
    GeneralProductID, ProductDesc, VarietyDesc, SizeID, Quantity, 
    Quantity_Pallets, Pack_Name, WeightNeto,
}: IPropsProductPallet) => {
  return (
    <TitleLayout>
        <SidleWrapper>
            <ProductImage 
                id={GeneralProductID}
            />

        </SidleWrapper>
        <TitleRow>
            <TitleRowItem 
                name={`${ProductDesc.trim()} ${VarietyDesc.trim()} ${SizeID.trim()}`}
                icon={<AppleIcon />}
            />
        </TitleRow>
        <TitleRow>
            <TitleRowItem 
                name={`${Pack_Name.trim()} - ${Quantity}`}
                icon={<PackageIcon />}
            />
            
        </TitleRow>
        <TitleRow>
            <TitleRowItem 
                name={`${WeightNeto.toFixed(2)}`}
                icon={<ScalesIcon />}
            />
            
        </TitleRow>
    </TitleLayout>
  )
}
const SidleWrapper = styled.div`
    position: absolute;
    left: 0;
    width: 4.5em;
    height: 4.5em;
    top: calc(50% - 2em);
`
export default PalletDetailsProductItem