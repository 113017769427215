import React from 'react'
import styled from 'styled-components'
import { AzmanotListWrapper } from '../../secondbar/mainbar2'
import LikutSectionExistGoodsItem from './existGoods/LikutSectionExistGoodsItem'
import LikutSectionGoodsItem from './LikutSectionGoodsItem'
import { useTranslation } from 'react-i18next'
const DivideSection = styled.div`
    width: 100%;
    height: 3em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
    font-weight: 600;
    color: #8a8aad;
    margin-bottom: 0.5em;
`

export default function LikutSectionGoodsList({
    azmanotList, existGoodsInPallet, longPressEvent,
    defaultPackageList, selectIconForPalletType, 
    setPackageType, selecteble, setDefaultPacks, 
    setRecID, recID, editOrderRow, selectGoodForLikut, 
    defaultPacks, deleteFromPallet, likutWithoutAzmana, setGoodsCode,
    isWeightCalculation, isAddIdToDesc
    
}) {
    const [t] = useTranslation(['likut']);
    return existGoodsInPallet?.length > 0 ? (
        <AzmanotListWrapper>
            {azmanotList.map((item, index )=> 
                <LikutSectionGoodsItem 
                    key={item.id}
                    {...item} 
                    isWeightCalculation={isWeightCalculation}
                    item={item} 
                    defaultPackageList={defaultPackageList} 
                    setDefaultPacks={setDefaultPacks} 
                    setPackageType={setPackageType} 
                    selecteble={selecteble} 
                    setRecID={setRecID}
                    recID={recID}
                    editOrderRow={editOrderRow}
                    selectGoodForLikut={selectGoodForLikut}
                    index={index}
                    defaultPacks={defaultPacks}
                    setGoodsCode={setGoodsCode}
                    isAddIdToDesc={isAddIdToDesc}
                />)}
            <DivideSection>{t('likut_sectionmain_inpallet_title')}</DivideSection>
            {existGoodsInPallet.map(item => 
                <LikutSectionExistGoodsItem 
                    key={item.RecID} 
                    {...item} 
                    item={item} 
                    defaultPackageList={defaultPackageList}  
                    selecteble={selecteble} 
                    setPackageType={setPackageType} 
                    deleteFromPallet={deleteFromPallet}
                    likutWithoutAzmana={likutWithoutAzmana}
                    setGoodsCode={setGoodsCode}
                    
                />)}

        </AzmanotListWrapper>
    ) 
    :
    (
        <AzmanotListWrapper>
            {azmanotList.map((item, index) => 
                <LikutSectionGoodsItem 
                    key={item.id} 
                    {...item}
                    index={index} 
                    item={item} 
                    defaultPackageList={defaultPackageList} 
                    setDefaultPacks={setDefaultPacks} 
                    selecteble={selecteble} 
                    setPackageType={setPackageType} 
                    setRecID={setRecID}      
                    selectGoodForLikut={selectGoodForLikut}
                    recID={recID}
                    editOrderRow={editOrderRow}
                    setGoodsCode={setGoodsCode}
                    isWeightCalculation={isWeightCalculation}
                    isAddIdToDesc={isAddIdToDesc}
                />)}
            {/* <DivideSection>במשתח</DivideSection>
            {existGoodsInPallet.map(item => <LikutSectionExistGoodsItem key={item.RecID} {...item} />)} */}

        </AzmanotListWrapper>  
    )
}
