import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ControlButton from '../buttons/ControlButton';
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick';
import OpenCloseButton from '../particles/OpenCloseButton';

const ModalWrapper = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.open ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0)'} ;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
   
    transition: 0.4s;

`
const ModalContentWindowContainer = styled.div`
    position: relative;
    width: 300px;
    height: 200px;
    background: ${props => props.visible ? 'rgba(239,243,244, 1)' : 'rgba(239,243,244, 0)'} ;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    padding: 0.5em 1em;
    transform: ${props => props.visible ? 'translateY(0)' : 'translateY(300%)'} ;
    transition: 0.4s;

`
const ModalTitle = styled.div`
    width: 100%;
    padding: 0.2em 2em;
    /* background: green; */
    color: #297f76;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
`

const ModalBodyContainer = styled.div`
    height: 70%;
    width: 100%;
    padding: 0.5em;
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
`

function SelectByTwo({
    open, closeFunc, selectFuncOne, label,
    selectFuncTwo, chooseLableOne, chooseLabeTwo
}) {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef()
    const closeWindow = () => {
        setIsVisible(false);
        setTimeout(() => {
            closeFunc()        
        }, 300)
    }

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 200)
    }, [])
    useDetectOutsideClick(ref, closeWindow)
    return (
        <ModalWrapper  open={open}>
            <ModalContentWindowContainer ref={ref} visible={isVisible}>
            <OpenCloseButton
                close
                func={closeWindow}
            />
                <ModalTitle>
                    {label}
                </ModalTitle>
                <ModalBodyContainer>
                    <ControlButton
                        lable={chooseLableOne}
                        handleClick={selectFuncOne}
                    /> 
                    <ControlButton
                        lable={chooseLabeTwo}
                        handleClick={selectFuncTwo}
                    />
                </ModalBodyContainer>
            </ModalContentWindowContainer>
        </ModalWrapper>
    );
}

export default SelectByTwo;