import axios from 'axios'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DELETE_FROM_PALLET, EDIT_ORDER_ROW, GET_GOODS_ENTRY, GET_ORDER, GET_PALLET_EXIST, SAVE_COMMENT_ON_PALLET } from '../../../../../modules/API/settings'
import { Decorator, MainWrapper } from '../../secondbar/mainbar2'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { IS_NEW_PALLET, LOAD, LOAD_END, LOGOUT } from '../../../../../../store/actions/actionsTypes'
import Title from '../../../../../generic/Title/Title'
import LikutSectionGoodsList from './LikutSectionGoodsList'
import { SelectPanelWrapper } from '../../secondbar/azmanotmain/SelectBar'
import styled from 'styled-components'
import { BarCodeIcon, BasketIcon, EditIcon, ErrorIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../generic/icons/Icons'
import selectOnFocus from '../../../../../modules/autoselect'
import LikutEntryModal from './likutEntry/LikutEntryModal'
import { vibrationFunc } from '../../../../../modules/vibrationModule'
import useFocus from '../../../../../generic/hooks/useFocus'
import { Link, useHistory } from 'react-router-dom'
import { LikutModals } from '../../../../../modules/Context/CefiNum'
import AzmanaRowModal from './AzmanaRowModal'
import useLongPress from '../../../../../generic/hooks/useLongPress'
import ArizotModal from './arizot/ArizotModal'
import { checkPackType } from '../../../../../generic/utils/checkPackType'
import CheckArizot from './CheckArizot'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import LikutRemarkModal from './LikutRemarkModal'
import { LINKS } from '../../../../../Routs/config'
import ScanInput from '../../kosherTeudot/Components/ScanInput'
import useConfigLefiMishkal from '../useConfigLefiMishkal'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import TitleModal from './Modals/TitleModal'
import RemarkProductModal from './Modals/RemarkProductModal'
import LoaderSpinner from '../../../../../modules/loadSpinner/LoaderSpinner'
import useScannedDataHandler from '../../kosherTeudot/Hooks/useScannedDataHandler'
import useConfig from '../useConfig'
import LikutSectionTitle from '../Components/LikutSectionTitle'
export const InputWrapperLikut = styled.div`
    position: relative;
    width: 100%;
    height: 3.5em;
    border-radius: 30px;
    background: #eff3f4;
    display: flex;
    justify-content: flex-end;

`
export const InputLikut = styled.input`
    width: 90%;
    height: 100%;
    background: none;
    border-radius: 30px;
    outline: none;
    border: none;
    text-align: right;
    padding: 0 1em;
    color: #8a8aad;
    font-size: 1.1em;
`
export const IconWrapper = styled.div`
    position: absolute;
    top: 0.25em;
    left: 0.25em;
    height: 2.4em;
    width: 2.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* background: red; */
    font-size: 1.2em;
    color: #8a8aad;

`
const SelectLikutWrapper = styled.div`
    min-width: 6em;
    height: 3.5em;
    border-radius: 30px;
    background: #eff3f4;
`

const SelectLikut = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    direction: rtl;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.1em;
    color: #8a8aad;
    padding: 0 0.5em;
`
const LikutOption = styled.option`

`
const PersenceWrapper = styled.div`
    position: relative;
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    border: 1px solid #7ab0ab;
    overflow: hidden;
`
const PersenceInner = styled.div`
    position: relative;
    width: 3.5em;
    height: 3.5em;
    background: red;
    /* transform: translateY(${100 - 50}%); */
    border-top-right-radius: 16%;
    /* border-top-left-radius: 12%; */
    transition: 0.7s;
    /* -webkit-clip-path: ellipse(99% 86% at 42% 86%);
    clip-path: ellipse(99% 86% at 42% 86%); */
    ::after {
        position: absolute;
        content: '';
        width: 3em;
        height: 1em;
        top: -0.75em;
        left: 0em;
        border-radius: 50%;
        z-index: 2;
        background: #fdfdfd;
    }
    
`
const PersenceIndicator = styled.div`
    position: absolute;
    top: 0;
    /* background: red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
`

export const HeaderInformationWrapper = styled.div`
    background: #fdfdfd;
    height: 8em;
    z-index: 2;
    border-radius: 30px;
    padding: 0.7em;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
`
export const HeaderInformationInnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    /* background: #eff3f4; */
    border-radius: 25px;
    display: flex;

`
export const HeaderInformationInnerItem = styled.div`
    height: 100%;
    width: ${props => props.primal ? '30%' : '100%'};
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: ${props => props.primal ? 'flex-start' : 'flex-end'};
    gap: 0.5em;
`
export const HeaderInformationItem = styled.div`
    /* background: blue; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
    color: #8a8aad;
    font-size: ${props => props.primal ? '1em' : '1.2em'};
`
export const HeaderItemInfo = styled.div`
    /* font-size: ${props => props.isGrow ? '1.1em' : '1em'}; */
    border: ${props => props.isGrow ? '1px solid tomato' : 'none'};
    padding: ${props => props.isGrow ? '0 0.5em' : '0'};
    border-radius: 10px;
    color: ${props => props.isGrow ? 'tomato' : ''};
`
const ArizotSwitcherWrapper = styled.div`
    min-width: 3em;
    height: 3em;
    border-radius: 50%;
    background: #eff3f4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8a8aad;
    cursor: pointer;

`
const TitleSideButton = styled.div`
    position: absolute;
    bottom: 0em;
    left: 1em;
    padding: 0.1em;
    /* background: red; */
    color: #8A8AAD;
    cursor: pointer;
`

export default function LikutSectionMain({ getExistPalletInfo, getPallet, printPallet, setPrintersModal, printerNum, inputClients, numMishtah, palletAzmana, activeArizotCalc }) {
    const [t] = useTranslation(['likut', 'buttons']);
    const [cookies] = useCookies(['i18next'])
    const [isWeightCalculation, calcWeightPerUnit] = useConfigLefiMishkal()
    const [isAddIdToDesc] = useConfig()
    const getToken = useSelector(token => token.mainReducer.token);
    const user = useSelector(token => token.mainReducer.user);
    const getAzmanaNum = useSelector(num => num.likut.azmanaNum);
    const likutInfo = useSelector(info => info.likut);
    const Collect_Comm  = useSelector(info => info.likut?.order?.Collect_Comm);
    const packageList = useSelector(packages => packages?.defaults?.packageList);
    const defaultPackageList = useSelector(info => info.defaults.packageList);
    const defaults = useSelector(e => e.defaults?.defaults);
    const activeResons = selectBrodMigdal(defaults, 572, 'Logical_Value') && !likutInfo?.withoutAzmana;
    const deltaWeight = selectBrodMigdal(defaults, 431, 'Num_Value');
    const testForInitialPAcksOfAmount = selectBrodMigdal(defaults, 318, 'Logical_Value');
    const defaultArizaProductID = selectBrodMigdal(defaults, 414, 'Num_Value');
    const defaultArizaGrowerID = selectBrodMigdal(defaults, 412, 'Num_Value');
    const defaultArizaTranzit = selectBrodMigdal(defaults, 5, 'Num_Value');
    // const isWeightCalculation = !selectBrodMigdal(defaults, 619, 'Logical_Value');
    const artPass = selectBrodMigdal(defaults, 577, 'Text_Value')?.trim();
    const isAllowToPrint = selectBrodMigdal(defaults, 591, 'Logical_Value');
    const dispatch = useDispatch();
    const [inputRef, setInputFocus] = useFocus();
    const [modal, setModal] = useContext(ContextModal);
    const [azmanotList, setAzmanotList] = useState([]);
    const [azmanotListDefault, setAzmanotListDefault] = useState([]);
    const [existGoodsInPallet, setExistGoodsInPallet] = useState([]);
    const [resonState, setResonState] = useContext(LikutModals);
    const [selectAzmanaRowModal, setSelectAzmanaRowModal] = useState(false);
    const [orderTitle, setOrderTitle] = useState(null)
    const [isLoadingProductList, setIsLoadingProductList] = useState(false)
    const [isOpenArizotModal, setIsOpenArizotModal] = useState(false);
    const [isOpenRemarkModal, setIsOpenRemarkModal] = useState(Collect_Comm?.trim() ? true : false)
    const [isOpenProductRemarkModal, setIsOpenProductRemarkModal] = useState(false)
    const [remarkState, setRemarkState] = useState({
        remark: ''
    })

    // const [packageList, setPackageList] = useState([]);

    const [resonStateForEmptyGood, setResonStateForEmptyGood] = useState(null);
    const [problemStateForEmptyGood, setProblemStateForEmptyGood] = useState(null);
    const [recID, setRecID] = useState(null);
    const [goodsCode, setGoodsCode] = useState('')
    const [entryInformation, setEntryInformation] = useState([])

    const [likutModalToggle, setLikutModalToggle] = useState(false)
    const [defaultPacks, setDefaultPacks] = useState('')
    const [persents, setPersents] = useState(0);
    const [progressColor, setProgressColor] = useState('')
    const [packageType, setPackageType] = useState(null)

    const [existArizotQty, setExistArizotQty] = useState(null)
    const [existWeigthQty, setExistWeigthQty] = useState(null)

    const [isOpenCheckArizot, setIsOpenCheckArizot] = useState(false);


    const [locationKeys, setLocationKeys] = useState([])
    const history = useHistory()

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)

                    // Handle forward event

                } else {
                    setLocationKeys((keys) => [location.key, ...keys])
                    getPallet(likutInfo?.clientId || 0);
                    // Handle back event

                }
            }
        })
    }, [locationKeys])

    const saveCheckedPalletInfo = async (data) => {
        try {
            let res = await axios({
                method: 'patch',
                url: `${SAVE_COMMENT_ON_PALLET}${likutInfo?.pallet?.palletNum}`,
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    "detailsDelivery": data,
                    "printerNum": 33
                }
            })
            dispatch({ type: IS_NEW_PALLET, payload: false });
            // console.log(res.data)
        } catch (err) {
            // console.log(err.response.data.message)
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        }
    }
    // useEffect(() => {
    //     console.log(existGoodsInPallet)
    // }, [existGoodsInPallet])
    const checkGivenPacks = () => {
        // console.log(existGoodsInPallet)
        let totalArizot = existGoodsInPallet.reduce((acc, cur) => {
            let check = checkPackType(defaultPackageList, cur.PackID);
            // console.log(check)
            let temp = check !== '1' && check !== '2';
            if (temp) {
                if (check === 'ק') {
                    acc['קרטון'] = acc['קרטון'] ? acc['קרטון'] + cur?.Quantity : cur?.Quantity
                }
                else {
                    acc[cur.Pack_Name?.trim()] = acc[cur.Pack_Name?.trim()] ? acc[cur.Pack_Name?.trim()] + cur?.Quantity : cur?.Quantity
                }
                // קרטון 
                // console.log(acc)
            }

            return acc
        }, {})
        // console.log(totalArizot)
        return totalArizot
    }
    // const packsQty = checkGivenPacks();
    // console.log(packsQty)
    useEffect(() => {
        setInputFocus()
        // checkGivenPacks()
    }, [])

    const selectGoodForLikut = (index) => {
        setDefaultPacks(azmanotList[index]);
        setLikutModalToggle(true);
    }
    const cancelToken = axios.CancelToken.source();

    
    const openGoodOreder = (defArr) => {
        if(!isWeightCalculation) {
            const filtered = defArr.filter(item => {
                return (item.OrderPacks - item.SupplyQty) > 0
            })
            setAzmanotList(filtered)
        } else {
            const filtered = defArr.filter(item => {
                const unit = calcWeightPerUnit(item)
                const pers = ((item.SupplyWeight || 1) / item.OrderWeight) * 100
                // console.log(pers)
                return Math.abs(item.SupplyWeight - item.OrderWeight) >= unit || item.ScanProblem !== 0
            })
            setAzmanotList(filtered)
        }
    }

    const deleteItemFromPallet = async (id, item) => {
        let newArr = [...azmanotList].map(e => {
            if(e.RecID === item.OrderLineRef){
                let temp = e.SupplyQty + item.Quantity
                e.SupplyQty = e.SupplyQty - item.Quantity
                e.SupplyWeight = e.SupplyWeight - item.WeightNeto
                if(temp === e.OrderPacks) {
                    e.ScanProblem = 0
                    e.ChangeReason = 0
                }
            }
            return e
        })
        
        dispatch({ type: LOAD })
        try {
            let res = await axios({
                method: 'delete',
                headers: {

                    'Authorization': 'Bearer ' + getToken

                },
                url: DELETE_FROM_PALLET + id
            })
            const filtered = existGoodsInPallet.filter(item => item.RecID !== id);
            setExistGoodsInPallet(filtered);
            setAzmanotList(newArr)
            
            setModal({
                open: true,
                value: res?.data?.message,
                mood: true
            });
            setTimeout(() => {

                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
            // if (!likutInfo.withoutAzmana) {
            //     getAzmanotDetailes(getAzmanaNum);
            // }
        } catch (err) {
            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);

            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        } finally {
            dispatch({ type: LOAD_END })

        }
    }

    const editOrderRow = async (problem, reson) => {
        dispatch({ type: LOAD })
        try {
            let res = axios({
                method: 'patch',
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                url: EDIT_ORDER_ROW,
                data: {
                    "RecID": recID,
                    "ScanProblem": problem,
                    "ChangeReason": reson
                }
            })
            // setIsOpenModalForEmptyGood(false);
            palletDetailes(likutInfo.pallet.palletNum)
            if (!likutInfo.withoutAzmana) {
                getAzmanotDetailes(+likutInfo?.azmanaNum);
            }

        } catch (err) {

            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            });

            setTimeout(() => {

                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);

            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        } finally {
            dispatch({ type: LOAD_END })
        }
    }
    const getAzmanotDetailes = async (OrderID) => {
        setIsLoadingProductList(true)
        try {
            let response = await axios.get(`${GET_ORDER}${OrderID}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken,
                    'Accept-Language': cookies.i18next || 'he'
                },
                
            })
            let concatArr = addIDForTheList(response.data.orderDetails);
            let sorted = concatArr.sort((a, b) => b.CollectorSort - a.CollectorSort)
            let arrIsue = []
            let arrWithoutIsue = []
            sorted.forEach(e => {
                if (e.ChangeReason === 3) {
                    arrIsue.push(e)
                } else {
                    arrWithoutIsue.push(e)
                }
            })
            setOrderTitle(response.data.orderTitle);
            setAzmanotList(arrWithoutIsue.concat(arrIsue))
            openGoodOreder(arrWithoutIsue.concat(arrIsue))
            setAzmanotListDefault(arrWithoutIsue.concat(arrIsue))
        } catch (err) {
            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }

        } finally {
            setIsLoadingProductList(false)
        }

    }
    const errAlert = (err, param) => {
        if (err.length === 1) {
            setModal({
                open: true,
                value: param ? err[0][param] : err[0],
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });

            }, 3000);

        } else {
            setModal({
                open: true,
                value: param ? err[0][param] : err[0],
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);

            return setTimeout(() => {
                errAlert(err?.slice(1), param)
            }, 4000)
        }
        setInputFocus();
    }



    const saveArizotRequest = async (Qty, packID) => {
        if (Qty === 0 || !packID) {
            setModal({
                open: true,
                value: 'בחר אריזה',
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
            return
        }
        dispatch({ type: LOAD })
        try {
            let res = await axios({
                method: 'post',
                url: SAVE_COMMENT_ON_PALLET,
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    "Quality": 0,
                    "Warehouse": defaultArizaTranzit || 0,
                    "GrowerID": defaultArizaGrowerID,
                    "ProductID": defaultArizaProductID,
                    'PalletNum': +likutInfo?.pallet?.palletNum,
                    'OrderNum': likutInfo?.withoutAzmana ? 0 : +likutInfo?.azmanaNum,
                    'Qty': Qty,
                    'PackID': packID,
                    // 'PalletType': +likutInfo?.pallet?.palletNum === 999999 ? likutInfo?.pallet?.palletType : defaultWithoutMishtah,
                    // 'OrderRowRecID': defaultPacks?.RecID || 0,
                    "CustomerID": likutInfo?.withoutAzmana ? likutInfo?.clientId : 0,
                }
            })
            setModal({
                open: true,
                value: res?.data?.message,
                mood: true
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
            updateExistPallets(likutInfo.pallet.palletNum);
        } catch (err) {
            // console.log(err.response)
            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const getGoodsInfoEntry = async (num) => {
        dispatch({ type: LOAD })
        setResonState({
            resonTag: null,
            action: null,
            active: false,
            props: {}
        })
        try {

            let res = await axios.get(`${GET_GOODS_ENTRY}${num}/${getAzmanaNum}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            dispatch({ type: LOAD_END })
            let resultObj
            const foundFull = azmanotListDefault.find(item => {
                return +item.ProductID === +res.data.entryLine[0]?.ProductID && 
                    +item.VarietyID === +res.data.entryLine[0]?.VarietyID &&
                        item.SizeID?.trim() === res.data.entryLine[0]?.SizeDesc?.trim()

            })
            if (foundFull) {
                resultObj = foundFull
            } else {
                const foundWithoutSize = azmanotListDefault.find(item => {
                    return +item.ProductID === +res.data.entryLine[0]?.ProductID && 
                        +item.VarietyID === +res.data.entryLine[0]?.VarietyID
                })
                const foundWithoutVariety = azmanotListDefault.find(item => {
                    return +item.ProductID === +res.data.entryLine[0]?.ProductID && 
                        +item.SizeID?.trim() === +res.data.entryLine[0]?.SizeDesc?.trim()
                })
                const foundWithProductID = azmanotListDefault.find(item => {
                    return +item.ProductID === +res.data.entryLine[0]?.ProductID
                })
                resultObj = foundWithoutSize || foundWithoutVariety || foundWithProductID
            }
            
            
            //(+item.ProductID === +res.data.entryLine[0]?.ProductID && +item.VarietyID === 0)
            if (activeResons && !resultObj) {
                setEntryInformation(res.data.entryLine);
                setDefaultPacks(resultObj);
                setResonState({
                    ...resonState,
                    resonTag: 'notExistInAzmana',
                    active: true,
                    action: setLikutModalToggle,
                    props: {
                        srika: res.data.entryLine[0],
                        setDefProd: setDefaultPacks,
                        setRowModal: setSelectAzmanaRowModal
                    }
                })
                //notExistInAzmana
                return
            }
            if (res.data?.message?.length > 0) {
                vibrationFunc('criticalError')
                errAlert(res.data?.message);
                setGoodsCode('')

            } else {
                // console.log(res.data.message);
                vibrationFunc('success')
                setEntryInformation(res.data.entryLine)
                // search equal good in goodslist by id from entry request and update the state
                // const filtered = azmanotListDefault.filter(item => {
                //     return +item.ProductID === +res.data.entryLine[0]?.ProductID && +item.VarietyID === +res.data.entryLine[0]?.VarietyID
                // })
                
                setDefaultPacks(resultObj);
                // ! search equal good in goodslist by id from entry request and update the state

                setLikutModalToggle(true);
                if(resultObj.Remarks?.trim()) {
                    setRemarkState({
                        remark: resultObj.Remarks?.trim()
                    })
                    handleProductRemarkModal()
                }
            }
        } catch (err) {
            dispatch({ type: LOAD_END });
            if (!err.response?.data?.message) {
                // setModal({
                //     open: true,
                //     value: 'קרתה תקלה נסה שוב',
                //     mood: false
                // });
                // setTimeout(() => {
                //     setModal({
                //         open: false,
                //         value: '2'
                //     });
                // }, 3000);

            } else {
                setModal({
                    open: true,
                    value: err.response?.data?.message,
                    mood: false
                });
                setTimeout(() => {
                    setModal({
                        open: false,
                        value: '2'
                    });
                }, 3000);
            }
            setInputFocus();
            
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            } 

            // setModal({
            //     open: true,
            //     value: err,
            //     mood: false
            // });
            // setTimeout( () =>{ 
            //     setModal({
            //         open: false,
            //         value: '2'
            //     });
            // }, 3000);
        }
    }

    const checkForSiumLikut = () => {
        const filtered = azmanotListDefault.filter(item => (item.OrderPacks - item.SupplyQty !== 0) && (item.ChangeReason === 0))
        // console.log(filtered)
        if (filtered.length === 0) {
            console.log('ok')
        }
        else {
            console.log('is not ready yet')
        }
    }

    // console.log()
    // calculation of packs quantity
    const collectAndSortExist = () => {
        let sorted = existGoodsInPallet?.reduce((sum, cur) => {
            // console.log(defaultPackageList.filter(e => e.PackID === cur.PackID))
            let findPack = defaultPackageList.filter(e => e.PackID === cur.PackID)[0]
            if (findPack?.Pack_Type === '1' || findPack?.Pack_Type === '2') return sum
            return sum + (cur.Quantity / (findPack?.Volume || 60) * 100)
        }, 0)
        // console.log(existGoodsInPallet)
        setProgressColor(Math.floor(sorted) < 25 ? '#79BD8F' :
            Math.floor(sorted) >= 25 && Math.floor(sorted) < 50 ? '#B2C77F' :
                Math.floor(sorted) >= 50 && Math.floor(sorted) < 75 ? '#BD9E79' :
                    '#B2372D'
        )
        setPersents(Math.floor(sorted))

    }
    useEffect(() => {
        collectAndSortExist()
    }, [existGoodsInPallet])

    const palletDetailes = async (code) => { // 99086
        // dispatch({ type: LOAD });
        setIsLoadingProductList(true)
        try {
            let res = await axios.get(`${GET_PALLET_EXIST}${code}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            if (Array.isArray(res.data.detailsPallet)) {
                // setPaletData(res.data.detailsPallet)
                let concatArr = addIDForTheList(res.data.orderDetails)
                setAzmanotList(concatArr)
                // openGoodOreder(addedIdArr)
                setAzmanotListDefault(concatArr)

            }

        } catch (err) {
            // console.log(err)
        } finally {
            // dispatch({ type: LOAD_END })
        setIsLoadingProductList(false)

        }

    }
    const updateExistPallets = async (palletNum) => {
        setExistGoodsInPallet(await getExistPalletInfo(palletNum));
    }

    useEffect(() => {
        if (!likutInfo.withoutAzmana) {
            getAzmanotDetailes(getAzmanaNum);
        }
        else if (likutInfo.withoutAzmana) {
            palletDetailes(likutInfo.pallet.palletNum)
        }
        updateExistPallets(likutInfo.pallet.palletNum);

        return () => {
            cancelToken.cancel('unmounted')
        }
    }, [])
    // console.log(orderTitle)
    useEffect(() => {
        let weigthQty = existGoodsInPallet?.reduce((acc, cur) => {
            return acc += cur.WeightNeto
        }, 0)
        let packsQty = existGoodsInPallet?.reduce((acc, cur) => {
            return acc += cur.Quantity
        }, 0)
        setExistArizotQty(packsQty);
        setExistWeigthQty(weigthQty);
    }, [existGoodsInPallet])

    const selectFunc = (value) => {
        if (value === 'def') {
            openGoodOreder(azmanotListDefault);
        }
        else if (value === 'all') {
            setAzmanotList(azmanotListDefault);
        }
        else if (value === 'inPallet') {
            setAzmanotList([]);
        }

    }
    const savePallet = () => {
        getPallet(likutInfo?.clientId || 0);
        // console.log(printerNum)
        if (printerNum) {
            printPallet(likutInfo?.pallet?.palletNum)
        }

        else {
            setPrintersModal()
        }
    }

    
    
    const handleProductRemarkModal = () => {
        setIsOpenProductRemarkModal(!isOpenProductRemarkModal)
    }
   
    
    let likutModalWindow = likutModalToggle ?
        <LikutEntryModal
            setLikutModalToggle={setLikutModalToggle}
            goodsCode={goodsCode}
            entryInformation={entryInformation[0]}
            azmanotListDefault={azmanotListDefault}
            defaultPacks={defaultPacks}
            setDefaultPacks={setDefaultPacks}
            likutInfo={likutInfo}
            getToken={getToken}
            updateExistPallets={updateExistPallets}
            getAzmanotDetailes={getAzmanotDetailes}
            setInputFocus={setInputFocus}
            defaultPackageList={defaultPackageList}
            packageType={packageType}
            checkForSiumLikut={checkForSiumLikut}
            errAlert={errAlert}
            orderTitle={orderTitle}
            activeResons={activeResons}
            user={user}
            persents={persents}
            isWeightCalculation={isWeightCalculation}
            testForInitialPAcksOfAmount={testForInitialPAcksOfAmount}

        />
        :
        ''
    const PRemarkLikut = {
        Collect_Comm: Collect_Comm,
        close: () => {setIsOpenRemarkModal(!isOpenRemarkModal)},
        readOnly: true
    }
    const refBody = useRef(null)
    
    useEffect(() => {
        const handleScannedData = (event) => {
          const barcodeData = event.code;
          // Process the scanned barcode data herea\

          if(barcodeData === '') {
                refBody.current?.focusInput();
                // searchEnterPallet()
                setTimeout(() => {
                    refBody.current?.blurInput()
                }, 1000)
               
          }
        };
       
        window.addEventListener('keypress', handleScannedData);
        // window.addEventListener('paste', handlePaste as EventListener);
    
    
        return () => {
          window.removeEventListener('keypress', handleScannedData);
        };
      }, []);
      const closeRowsModal = () => {
        setSelectAzmanaRowModal(false)
      }
    return (
        <MainWrapper style={{ minHeight: '30em' }} >
            <CheckArizot
                saveFunc={savePallet}
                packQty={checkGivenPacks}
                isOpenCheckArizot={isOpenCheckArizot}
                setIsOpenCheckArizot={setIsOpenCheckArizot}
                saveCheckedPalletInfo={saveCheckedPalletInfo}
                getPallet={getPallet}
                likutInfo={likutInfo}
                artPass={artPass}
                delta={deltaWeight}
                palletDetails={existGoodsInPallet}
            />
          
            {
                isOpenProductRemarkModal &&
                <ColumModal
                    Children={RemarkProductModal}
                    childrenProps={remarkState}
                    title={t('likut_modal_remarks_title')}
                    controlFunc={handleProductRemarkModal}
                />
            }
            {
                isOpenRemarkModal &&
                <ColumModal
                    Children={LikutRemarkModal}
                    childrenProps={PRemarkLikut}
                    title={t('likut_modal_remarks_title')}
                    controlFunc={() => { setIsOpenRemarkModal(!isOpenRemarkModal) }}
                />
            }
            {selectAzmanaRowModal ?
                <AzmanaRowModal
                    azmanotListDefault={azmanotListDefault}
                    defaultPackageList={defaultPackageList}
                    setPackageType={setPackageType}
                    handleClose={closeRowsModal}
                    entryLine={entryInformation[0]}
                />
                :
                ''
            }
            {isOpenArizotModal ?
                <ArizotModal
                    show={isOpenArizotModal}
                    setShow={setIsOpenArizotModal}
                    list={packageList}
                    saveFunc={saveArizotRequest}
                /> : ''
            }
            <Decorator />
            <Title label={
                likutInfo?.pallet?.palletNum !== 999999 ?
                    t('likut_sectionmain_title_withpallet', {pallet: `${+numMishtah || palletAzmana?.length + 1} (${likutInfo?.pallet?.palletNum})`})
                        : t('likut_sectionmain_title_new')
                } 
            />
            {likutModalWindow}
            <LikutSectionTitle 
                likutInfo={likutInfo}
                orderTitle={orderTitle}
                isAddIdToDesc={isAddIdToDesc}
                persents={persents}
                progressColor={progressColor}
                existWeigthQty={existWeigthQty}
                existArizotQty={existArizotQty}
            />
           
            <SelectPanelWrapper>
                <SelectLikutWrapper>
                    <SelectLikut onChange={(e) => { selectFunc(e.target.value) }}>
                        <LikutOption value={'def'}>לקוט</LikutOption>
                        <LikutOption value={'all'}>הכל</LikutOption>
                        <LikutOption value={'inPallet'}>במשטח</LikutOption>
                    </SelectLikut>

                </SelectLikutWrapper>
                {
                    likutInfo?.pallet?.palletNum !== 999999 ?
                        <ArizotSwitcherWrapper onClick={() => { setIsOpenArizotModal(!isOpenArizotModal) }}>
                            <PackageIcon />
                        </ArizotSwitcherWrapper>
                        : ''
                }
                {
                    Collect_Comm?.trim() &&
                        <ArizotSwitcherWrapper
                            onClick={() => { setIsOpenRemarkModal(!isOpenRemarkModal) }}
                        >
                            <EditIcon />
                        </ArizotSwitcherWrapper>

                }
                <InputWrapperLikut>
                    <ScanInput 
                        input={goodsCode}
                        setInput={setGoodsCode}
                        ref={refBody}
                        icon={<BarCodeIcon />}
                        enterFunc={() => getGoodsInfoEntry(goodsCode)}
                    />
                </InputWrapperLikut>
            </SelectPanelWrapper>
            <ContentWrapper>
                {
                    isLoadingProductList ?
                    <LoaderWrapper>
                        <LoaderSpinner />
                    </LoaderWrapper>
                    :
                    <LikutSectionGoodsList
                        azmanotList={azmanotList}
                        existGoodsInPallet={existGoodsInPallet}
                        defaultPackageList={defaultPackageList}
                        setPackageType={setPackageType}
                        setRecID={setRecID}
                        recID={recID}
                        editOrderRow={editOrderRow}
                        selectGoodForLikut={selectGoodForLikut}
                        defaultPacks={defaultPacks}
                        deleteFromPallet={deleteItemFromPallet}
                        likutWithoutAzmana={likutInfo?.withoutAzmana}
                        setGoodsCode={setGoodsCode}
                        isWeightCalculation={isWeightCalculation}
                        isAddIdToDesc={isAddIdToDesc}
                    />

                }
                {/* <LikutSectionExistGoodsList existGoodsInPallet={existGoodsInPallet} /> */}
            </ContentWrapper>

            {
                likutInfo?.pallet?.palletNum !== 999999 ?
                    activeArizotCalc && (likutInfo?.isNewPallet || likutInfo.azmanaStatus !== 7) ?
                        <ControlButton
                            lable={t("save", {ns: "buttons"})}
                            handleClick={() => { setIsOpenCheckArizot(true) }}
                        />
                        :
                        <Link style={{ width: '100%' }} to={LINKS.likut.likutExperess.palletSection}>
                            <ControlButton
                                lable={t("save", {ns: "buttons"})}
                                handleClick={
                                    () => {
                                        getPallet(likutInfo?.clientId || 0);
                                        // console.log(printerNum)
                                        if (isAllowToPrint) {
                                            if (printerNum) {
                                                printPallet(likutInfo?.pallet?.palletNum)
                                            }

                                            else {
                                                setPrintersModal()
                                            }

                                        }
                                    }
                                }
                            />
                        </Link>
                    : ''

            }

            {/* <button onClick={checkForSiumLikut}>123</button> */}
        </MainWrapper>
    )
}


const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    border-radius: 30px;
    overflow: hidden;
    background: #e9f3f2;
    z-index: 0;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
`

const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
