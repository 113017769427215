import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import { GET_CEFI } from '../../../../modules/API/settings'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useContext } from 'react';
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { ContextModal } from '../../../../modules/modal/modalContext'
import { AzmanotListWrapper, ContainerImg, ContentWrapper, Decorator, MainWrapper, SectionMain, TitleListWrapper } from '../secondbar/mainbar2';
import Title from '../../../../generic/Title/Title';
import { SelectPanelWrapper } from '../secondbar/azmanotmain/SelectBar';
import { DateWrapper } from '../secondbar/modalAzmanot/modalAzmanot';
import SearchInput from '../../../../generic/inputs/inputSearch';
import { LOGOUT } from '../../../../../store/actions/actionsTypes';
import TitlesList from '../../../../generic/titlesRowItem/TitlesList';
import ControlButton from '../../../../generic/buttons/ControlButton';
import { LINKS } from '../../../../Routs/config';
import CreateCefiMain from './CefiCreate/CreateCefiMain';
import CefiViewMain from './cefiView/CefiViewMain';
import CefiMainItem from './cefiMainpageItems/CefiMainItem';
import { Cefi } from '../../../../modules/API/Cefi/cefi';


export default function CefiMain() {
    const axios = require('axios');
    const dispatch = useDispatch()
    const getToken = useSelector(token => token.mainReducer.token)
    const {path} = useRouteMatch()
    const [modal, setModal] = useContext(ContextModal)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [cefiData, setCefiData] = useState([])
    const [cefiDataDefault, setCefiDataDefault] = useState([])
    const [input, setInput] = useState('')
    const data = format(startDate, "yyyyMMdd")
    const dataEnd = endDate ? format(endDate, "yyyyMMdd") : data
    const CancelToken = axios.CancelToken.source();

    const getCefi = async () => {
        try {
            let response = await axios.get(GET_CEFI + `?fromDate=${data}&toDate=${dataEnd}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            },
                {
                    cancelToken: CancelToken.token
                })
            setCefiData(response.data.expects)
            setCefiDataDefault(response.data.expects)

        } catch (err) {
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({type: LOGOUT, payload: false});
            }
        }
        return () => { CancelToken.cancel("axios request cancelled") }
    }
    useEffect(() => {
        getCefi()
        return () => {
            CancelToken.cancel("Component got unmounted");
        };
    }, [endDate])

    const filterAzmanot = async (input) => { // 
        const filtered = cefiDataDefault.filter(item => {
            const guess = item.Reference + ''

            return guess.toLowerCase().includes(input.toLowerCase()) || item.GrowerName.toLowerCase().includes(input.toLowerCase())
        })
        setInput(input);
        setCefiData(filtered);
    }

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const removeCefi = EntryNum => {
        setCefiData(() => cefiData.filter(cefi => {
            return cefi.EntryNum !== EntryNum
        }))
    }

    const handleDelete = async (EntryNum) => {
        try {
            await Cefi.deleteCefiOrder(EntryNum);
            removeCefi(EntryNum)
            setModal({
                open: true,
                value: "הצפי נמחק בהצלחה",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } catch (err) {
            setModal({
                open: true,
                value: "משהו השתבש, נסה שוב",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
    }
    let showPic = cefiData.length < 1 ? 'flex' : 'none'
    return (
        <Switch>
            <Route path={path} exact>
                <SectionMain>
                    <MainWrapper>
                        <Decorator />
                        <Title label={'תעודות משלוח למחסן'} />
                        <SelectPanelWrapper>
                            <DateWrapper>
                                <DatePicker
                                    className={'datePicker'}
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    shouldCloseOnSelect={false}
                                    dateFormat="dd/MM/yy"
                                />
                            </DateWrapper>
                            <SearchInput input={input} setInput={filterAzmanot} />
                        </SelectPanelWrapper>
                        <ContentWrapper>
                            <AzmanotListWrapper>
                                <ContainerImg style={{display: showPic}}>
                                    <img src="/images/no-data.png" alt="empty data"/>
                                </ContainerImg>
                                <TitlesList 
                                    list={cefiData}
                                    uniqueKey={'EntryNum'}
                                    Element={CefiMainItem}
                                    additionalData={{
                                        handleDelete: handleDelete,
                                    }}
                                />
                            </AzmanotListWrapper>
                            
                        </ContentWrapper>
                        <Link to={LINKS.cefi.cefi.create}>
                            <ControlButton 
                                lable='חדש'
                            />
                        </Link>
                    </MainWrapper>
                </SectionMain>
            </Route>
            <Route path={LINKS.cefi.cefi.create} component={CreateCefiMain} />
            <Route path={LINKS.cefi.cefi.editGeneral} component={CefiViewMain} />
        </Switch>

    )
}