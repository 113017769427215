import React from 'react'
import { IOrderTitle } from '../types/orderTitle'
import styled from 'styled-components'
import Image from "../../../../../../generic/assets/cefi.png"
import ImageComponentHead from '../../../../../../generic/imageComponents/ImageComponentHead'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PhoneIcon, UserIcon } from '../../../../../../generic/icons/Icons'
type Props = {
  orderTitle: IOrderTitle
}

const TitleModal = ({orderTitle}: Props) => {
  return (
    <MainWrapper>
      <ImageWrapper>
        <ImageComponentHead img={Image} />
      </ImageWrapper>
      <BodyWrapper>
        <TitleRowItem icon={<UserIcon />} name={`משווק: ${orderTitle.Seller_Desc?.trim()}`} />
        <TitleRowItem icon={<PhoneIcon />} name={`${orderTitle.Phone?.trim()}`} />
      </BodyWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 10em;
`
const BodyWrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  align-items: flex-end;
`
export default TitleModal