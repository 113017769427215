import React, { useContext } from 'react'
import styled from 'styled-components';
import ControlButton from '../../../../../generic/buttons/ControlButton';
import { LikutModals } from '../../../../../modules/Context/CefiNum';
import CloseBtnReson from './parts/CloseBtnReson';
import { ResonBtnAcceptWrapper, ResonContentItemTitle, ResonContentItemWrapper, ResonContentWrapper, ResonWrapper } from './ResonWindowsStyles'

const ImgSuccess = styled.div`
    position: absolute;
    top: -2em;
    left: calc(50% - 4.5em);
    width: 9em;
    height: 9em;
    border-radius:50%;
    background: url('/images/successimg.png') #fff;
    background-size: contain;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));

    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
`

export default function SuccessModal({mood, title, text}) {
    const [resonState, setResonState] = useContext(LikutModals);
    const handleClick = () => {setResonState({...resonState, 'active': false})}
    const buttonLabel = mood ? 'הבנתי'
                                :
                                    'אחלה'
    return (
        <ResonWrapper>
            <CloseBtnReson />
            {/* <ImgSuccess /> */}
            <ResonContentWrapper>
                <ResonContentItemWrapper >
                    <ResonContentItemTitle style={mood ? {background: 'tomato', color: '#ffff'}: {}}>
                        {title ? title : 'WOW!'}
                    </ResonContentItemTitle>
                    {text ? text : '! המשטח נשלח להדפסה '}
                        
                </ResonContentItemWrapper>
                <ResonBtnAcceptWrapper>
                    <ControlButton 
                        handleClick={handleClick}
                        lable={buttonLabel}
                    />
                </ResonBtnAcceptWrapper>
            </ResonContentWrapper>
        </ResonWrapper>
    )
}
