import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

function TableRow({
    children
}: Props) {
  return (
    <TableRowStyle>
        {children}
    </TableRowStyle>
  )
}
const TableRowStyle = styled.tr`
    width: 100%;
    height: 1em;
    :last-child {
        td {
            border-radius: 0 0 15px 15px;
        }
    }
`
export default TableRow