import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers/rootReducer";

const useWebSocket = (url: string) => {
  const { token } = useSelector((state: RootState) => state.mainReducer);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [error, setError] = useState<Event | null>(null);
  const [message, setMessage] = useState<any>(null);
  const wasConnectedBeforeBackground = useRef(false); // Track if the socket was connected before going into the background

  useEffect(() => {
    let ws: WebSocket | null = null;

    // Function to create and open the WebSocket connection
    const createWebSocket = () => {
      ws = new WebSocket(`${url}?token=${token}`);
      setSocket(ws);

      ws.onopen = (event) => {
        console.log('WebSocket connection opened:', event);
        wasConnectedBeforeBackground.current = true;
      };

      ws.onerror = (event) => {
        setError(event);
      };

      ws.onmessage = (event) => {
        setMessage(event.data);
      };

      ws.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
      };
    };

    // Create the WebSocket connection initially
    createWebSocket();

    // Handle visibility change to disconnect/reconnect WebSocket
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Close the WebSocket when the page goes to the background
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.close();
          setSocket(null); // Clear the socket state
        }
      } else if (!document.hidden && wasConnectedBeforeBackground.current) {
        // Recreate WebSocket when the page comes back to the foreground
        createWebSocket();
      }
    };

    // Listen to visibility change events
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup event listeners and WebSocket connection on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [url, token]);

  const sendMessage = useCallback(
    (message: string) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      } else {
        console.error("WebSocket is not open. Unable to send message:", message);
      }
    },
    [socket]
  );

  return { socket, error, message, sendMessage };
};

export default useWebSocket;
