import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { CLEAR_STATE_KOSHER, LOAD, LOAD_END, SELECT_CLIENT_COSHER, SELECT_DRIVER_KOSHER } from "../../../../../../store/actions/actionsTypes";
import { RootState } from "../../../../../../store/reducers/rootReducer";
import ControlButton from "../../../../../generic/buttons/ControlButton";
import SearchFromTheList from "../../../../../generic/customSearchComponents/SearchFromTheList";
import DatePickerModule from "../../../../../generic/datePicker/datePicker";
import SearchInput from "../../../../../generic/inputs/inputSearch";
import OrderlikeLayout from "../../../../../generic/Layouts/OrderlikeLayout";
import ControlSearchPannelLayout from "../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import TitlesList from "../../../../../generic/titlesRowItem/TitlesList";
import { IClientsItem } from "../../../../../modules/API/Defaults/models/customers";
import { IPalletListWithDetails } from "../../../../../modules/API/Pallets/models/palletList";
import { Pallets } from "../../../../../modules/API/Pallets/pallets";
import PalletItem from "./PalletItem";
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList.js'
import { Certificates } from "../../../../../modules/API/Certificates/certificates";
import { Defaults } from "../../../../../modules/API/Defaults/defaults";
import { ICarItem } from "../../../../../modules/API/Defaults/models/cars";
import ColumModal from "../../../../../generic/Layouts/ColumModal";
import SelectDriver from "../../trucks/SelectDriver";
import { SpeedIcon } from "../../../../../generic/icons/Icons";
import { ControlPanelWrapper } from "../../secondbar/modalAzmanot/modalAzmanot";
import { IPackagesItem } from "../../../../../modules/API/Defaults/models/packages";
import styled from "styled-components";
import { ContextModal } from "../../../../../modules/modal/modalContext";
import { LINKS } from "../../../../../Routs/config";
import ScanInput from "../Components/ScanInput";
import useScannedDataHandler from "../Hooks/useScannedDataHandler";

interface IPalletEdited extends IPalletListWithDetails {
    isSelected?: boolean
}
interface IButton {
    isSelected: boolean;
}
interface IRef {
    focusInput: () => void;
    blurInput: () => void;
  }
const TAB_LIST = ['נבחרו', 'מלאי']
function CreateTeuda() {
    const dispatch = useDispatch();
    const history = useHistory()

    const defaultCreateState = useSelector(
        (state: RootState) => state.kosherTeudotReducer
    )

    const packages = useSelector(
        (packages: RootState): IPackagesItem[] => packages.defaults.packageList
    );

    const packageHash = useMemo(() =>
        packages.reduce((acc: Record<number, IPackagesItem>, cur) => {
            acc[cur.PackID] = cur
            return acc
        }, {})
        , [packages])

    const clients = useSelector((state: RootState): IClientsItem[] => state.defaults?.clientList?.list);
    const clientList = addIDForTheList(clients)
    const [modal, setModal] = useContext(ContextModal)
    const [activeTab, setActiveTab] = useState<string | null>(TAB_LIST[1]);

    const [date, setDate] = useState<string | null>(null);
    const [carList, setCarList] = useState<ICarItem[]>([]);
    const [car, setCar] = useState<ICarItem | null>(defaultCreateState.create?.carInfo || null)
    const [isOpenSelectDriver, setIsOpenSelectDriver] = useState(false);
    const [isOpenPalletObject, setIsOpenPalletObject] = useState(false);

    const [isOpenClientList, setIsOpenClientList] = useState(false);
    const [clientInput, setClientInput] = useState(defaultCreateState?.create?.clientName || '');
    const [selectedClient, setSelectedClient] = useState<number | null>(defaultCreateState?.create?.clientID || null);


    const [palletList, setPalletList] = useState<IPalletEdited[]>([]);
    const [palletInput, setPalletInput] = useState<string>('');

    const [tempPalletObject, setTempPalletObject] = useState<IPalletEdited | null>(null)
    const refBody = useRef<IRef | null>(null);
    const getCars = async () => {
        try {
            let res = await Defaults.getCarList()
            setCarList(res.cars)
        } catch (err) {

        }
    }

    const getPallets = async () => {
        if (!selectedClient) return
        dispatch({ type: LOAD })
        try {
            let res = await Pallets.getPalletListWithDetails(selectedClient);
            setPalletList(res.map((item: IPalletEdited) => {
                item.isSelected = false
                return item
            }))
        } catch (err) {
            console.log(err)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }
    const searchClient = (e: string) => {
        setClientInput(e)
    }

    const selectClient = (id: number, name: string) => {
        dispatch({ type: SELECT_CLIENT_COSHER, clientID: id, clientName: name })
        setSelectedClient(id);
        setClientInput(name);
        setTimeout(() => {
            setIsOpenClientList(false);
        })
    }

    const renderClientList = useMemo(() => {
        const filtered = clientList.filter(item => item.Customer_Name?.trim().toLowerCase().includes(clientInput.toLowerCase()))
        return filtered
    }, [clientList, clientInput])

    const renderPalletList = useMemo(() => {
        if (!palletList) return
        const filtered = palletList.filter(item =>
            (activeTab === TAB_LIST[0] ? item.isSelected : !item.isSelected) &&
            (
                item.Pallet_Num?.toString().includes(palletInput.trim())
                ||
                item.Details.filter(e => 
                    `${e.ProductDesc?.trim()} ${e.VarietyDesc?.trim()}`.toLocaleLowerCase().includes(palletInput)
                    ).length > 0
                )
        )
        return filtered
    }, [palletList, palletInput, activeTab])

    useEffect(() => {
        if (!clientInput) {
            setIsOpenClientList(false)
            setSelectedClient(null)
            setPalletList([])
        } else {
            setIsOpenClientList(true)
        }
    }, [clientInput])

    useEffect(() => {
        getPallets();
    }, [selectedClient])

    useEffect(() => {
        getCars()
    }, [])


    const handleSelectPallet = useCallback((index: number, palletNum: number) => {
        if (!palletList) return
        let newArr = [...palletList]
        if (palletList.length === renderPalletList?.length) {
            if (newArr[index].isSelected) {
                newArr[index].isSelected = false
            } else {
                newArr[index].isSelected = true
            }
        } else {
            let item = newArr.find(e => e.Pallet_Num === palletNum)
            if (!item) return
            const indexOfItem = newArr.indexOf(item)
            if (newArr[indexOfItem].isSelected) {
                newArr[indexOfItem].isSelected = false
            } else {
                newArr[indexOfItem].isSelected = true
            }
        }
        setPalletList(newArr)
        setPalletInput('');

    }, [palletList, renderPalletList])

    const selectDriver = (item: ICarItem) => {
        dispatch({ type: SELECT_DRIVER_KOSHER, payload: item })
        setCar(item);
        setIsOpenSelectDriver(false)
    }

    const createTeuda = async () => {
        dispatch({ type: LOAD })
        const palletsArray = palletList?.reduce((acc: number[], cur: IPalletEdited) => {
            if (cur.isSelected) {
                acc.push(cur.Pallet_Num)
            }
            return acc
        }, [])

        const dataForTeuda = {
            "PalletNums": palletsArray || [],
            "OrderNum": 0,
            "CustomerID": selectedClient || 0,
            "DeliveryDate": date || '',
            "DeliveryTime": "",
            "Tranzit": 0,
            "Vehicle_Code": car?.CarID || 0
        }

        try {
            await Certificates.createCert(dataForTeuda);
            setModal({
                open: true,
                value: 'תעודה נוצרה בהצלחה',
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            dispatch({ type: CLEAR_STATE_KOSHER })
            history.push(LINKS.teudot.teudotMishloah.mainURL)
        } catch (err: any) {

            setModal({
                open: true,
                value: 'משהו השתבש',
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const getPalletByNum = async () => {
        dispatch({ type: LOAD })
        try {
            let res = await Pallets.getPalletWithDitailesByPalletNum(palletInput)
            setTempPalletObject({
                ...res,
                isSelected: false
            })
            setIsOpenPalletObject(true)
        } catch (err: any) {
            console.log(err.response.data.error)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }
    // console.log(tempPalletObject)
    const searchEnterPallet = () => {
        // alert(123)
        const found = palletList?.find(item => item.Pallet_Num === +palletInput)
        if (found) {

        } else {
            if (typeof +palletInput !== 'number') return
            getPalletByNum()
        }
    }

    const savePalletinTheList = async () => {
        try {
            setPalletInput('');
            if (tempPalletObject) {
                let temp: IPalletEdited = {
                    ...tempPalletObject,
                    isSelected: true,
                    CustomerID: selectedClient || 0
                }
                setPalletList(prev => {
                    return [temp, ...prev]
                })
                setIsOpenPalletObject(false)
            }
        } catch (err) { }
    }

    const PropsForPalletItem = {
        handleSelectPallet: handleSelectPallet,
        packageHash
    }

    const selectDriverP = {
        saveFunc: selectDriver,
        list: carList,
        defaultCar: car,
        isFullObject: true
    }

    const isSaveAllow = useMemo(() => {
        const finder = palletList?.find(e => e.isSelected)
        return selectedClient && finder
    }, [palletList, selectedClient])

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    useScannedDataHandler(refBody, searchEnterPallet)

    return (
        <OrderlikeLayout label="תעודה חדשה" >
            {
                isOpenSelectDriver &&
                <ColumModal
                    title='בחר נהג'
                    controlFunc={() => { setIsOpenSelectDriver(false) }}
                    Children={SelectDriver}
                    childrenProps={selectDriverP}
                />
            }
            {
                isOpenPalletObject &&
                <ColumModal
                    title={"אישור שליפת משטח"}
                    controlFunc={() => { setIsOpenPalletObject(false) }}
                    Children={() =>
                        <TitlesList
                            list={[tempPalletObject]}
                            uniqueKey={'Pallet_Num'}
                            Element={PalletItem}
                            noPadding={false}
                            additionalData={{
                                isDisabled: true
                            }}
                        />
                    }
                    childrenProps={{}}
                    acceptFunction={savePalletinTheList}
                />
            }
            <ControlSearchPannelLayout>
                <DatePickerModule
                    multyDate={false}
                    setSelectedDateStart={setDate}
                    defEndDate={false}
                    disabled={false}
                    selected={false}
                    ignorBrod={true}
                    setSelectedDateEnd={false}
                    large={true}
                />
                <SearchFromTheList
                    input={clientInput}
                    placeHolder={'בחר לקוח'}
                    size={'full'}
                    list={renderClientList}
                    isOpenList={isOpenClientList}
                    searchFunc={searchClient}
                    itemDisplayName={'Customer_Name'}
                    nameIdElement={'CustomerID'}
                    selectFunc={selectClient}
                />
            </ControlSearchPannelLayout>
            {
                selectedClient &&
                <ControlSearchPannelLayout >
                    <ScanInput
                        input={palletInput}
                        setInput={setPalletInput}
                        enterFunc={searchEnterPallet}
                        ref={refBody}
                    />
                    <ButtonWithIconAndDesc
                        isSelected={!!car}
                        onClick={() => { setIsOpenSelectDriver(!isOpenSelectDriver) }}
                    >
                        <SpeedIcon color={false} />
                        <p>
                            {
                                !!car ? car.DriverName?.trim() : 'בחר נהג'
                            }
                        </p>
                    </ButtonWithIconAndDesc>
                </ControlSearchPannelLayout>
            }
            <Container>
                {
                    selectedClient &&
                    <TabContainer>
                        {TAB_LIST.map((type, index) => (
                            <Tab
                                key={index}
                                active={activeTab === type}
                                onClick={() => handleTabClick(type)}
                            >
                                {type}
                            </Tab>
                        ))}
                    </TabContainer>
                }

                <TitlesList
                    list={renderPalletList}
                    uniqueKey={'Pallet_Num'}
                    Element={PalletItem}
                    noPadding={false}
                    additionalData={PropsForPalletItem}
                    borderLess
                />
            </Container>
            <ControlPanelWrapper>
                <Link to={LINKS.teudot.teudotMishloah.mainURL} style={{ width: '100%' }}>
                    <ControlButton lable={"חזרה"} />
                </Link>
                {
                    isSaveAllow &&
                    <ControlButton lable={"שמור"} handleClick={createTeuda} />
                }
            </ControlPanelWrapper>

        </OrderlikeLayout>
    );
}
const ButtonWithIconAndDesc = styled.div<IButton>`
    min-width: 7em;
    height: 3.5em;
    background: #eff3f4;
    color: #297f76;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5em;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.5s;
    i {
        color: ${props => props.isSelected ? 'tomato' : '#297f76'}
    }
    :hover{
        i {
            color: tomato;
            transform: scale(1.1);
        }
    }

`
const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
  border-radius: 30px 30px 0 0;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${(props) =>
        props.active
            ? `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
            : `
            
            `}
`;

export default CreateTeuda;
