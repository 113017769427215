import React from 'react'
import styled from 'styled-components'
import { ISupplyCustomerDataGrower } from '../../../../../../modules/API/Forcasts/models/seller'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../../generic/icons/Icons'

interface Props extends ISupplyCustomerDataGrower {
  item: ISupplyCustomerDataGrower
}

const CustomerGrowerItem = ({
  item, Grower, Forecast, OrderDetail
}: Props) => {
  const { GrowerName, GrowerID } = Grower
  const { OrderPacks, OrderWeight, Order_Pallets } = OrderDetail
  return (
    <MainWrapper>
      <LineWrapper >
        {
          Order_Pallets > 0 &&
          <TitleRowItem 
            name={Math.round(Order_Pallets)}
            icon={<PalletIcon />}
          />
        }
        {
          OrderWeight > 0 &&
          <TitleRowItem 
            name={Math.round(OrderWeight)}
            icon={<ScalesIcon />}
          />
        }
        {
          OrderPacks > 0 &&
          <TitleRowItem 
            name={Math.round(OrderPacks)}
            icon={<PackageIcon />}
          />
        }
        <TitleRowItem 
          name={GrowerName.trim()}
          icon={<UserIcon />}
        />
      </LineWrapper>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0.2em;
  /* border-bottom: 1px solid #297F76; */
  :nth-last-of-type() {
    border-bottom: none;
  }
`
const LineWrapper = styled(TitleRow)`
  
`
export default CustomerGrowerItem