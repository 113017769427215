import React from 'react'
import { IExternalPallet } from '../../../../../modules/API/Knisot/models/externalPallet'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { convertToDate } from '../../../../../modules/convertDateString'
import { AppleIcon, BarCodeIcon, CalendatDay, EditIcon, PalletIcon, UserIcon } from '../../../../../generic/icons/Icons'
import styled from 'styled-components'

interface IProps extends IExternalPallet {
  item: IExternalPallet
  handleSelectItem: (item: IExternalPallet) => void
  handleRemark: (item: IExternalPallet) => void
}

const ExternalListItem = ({
  GrowerDesc, TypePalletDesc, Quantity_Packs_Main,
  SizeDesc, ProductDesc, OriginCountryDesc, 
  EntryDate, EntryNum, VarietyDesc, EntryStatus, 
  item, handleSelectItem,
  TypePackLocal, TypePalletNameLocal,
  ProductLocal, VarietyNameLocal, ProductNameLocal,
  SizeNameLocal, ExternalPallet, DetailStatus, handleRemark,
  DetailRemark
}: IProps) => {

  const handleClick = () => {
    handleSelectItem(item)
  }

  const handleRemarkClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleRemark(item)
  }
  
  return (
    <TitleLayout
      onClick={handleClick}
      borderColor={DetailStatus !== 0 ? '#8CC53D' : 'tomato'}
    >
      {
        DetailRemark.trim() !== '' &&
        <SideWrapper onClick={handleRemarkClick}>
          <EditIcon />
        </SideWrapper>
      }
      <TitleRow>
        {
          ProductLocal !== 0 ?
            <TitleRowItem 
              name={`${ProductNameLocal.trim()} ${VarietyNameLocal.trim()} ${SizeNameLocal.trim()}`}
              icon={<AppleIcon />}
            />
            :
            <TitleRowItem 
              name={`${ProductDesc.trim()} ${VarietyDesc.trim()} ${SizeDesc.trim()}`}
              icon={<AppleIcon />}
              iconColor='tomato'
            />
        }
      </TitleRow>
      <TitleRow>
        <TitleRowItem 
          name={`${ExternalPallet}`}
          icon={<BarCodeIcon />}
        />
        {
          TypePackLocal !== 0 ?
            <TitleRowItem 
              name={`${TypePalletNameLocal.trim()}`}
              icon={<PalletIcon />}
            />
            :
            <TitleRowItem 
              name={`${TypePalletDesc.trim()}`}
              icon={<PalletIcon />}
              iconColor='tomato'
            />
        }
      </TitleRow>
      <TitleRow>
        <TitleRowItem 
          name={GrowerDesc.trim()} 
          icon={<UserIcon />} 
        />
        <TitleRowItem 
          name={convertToDate(EntryDate)} 
          icon={<CalendatDay />} 
        />
      </TitleRow>
    </TitleLayout>
  )
}
const SideWrapper = styled.div`
  position: absolute;
  height: 4em;
  width: 4em;
  left: 0.5em;
  top: calc(50% - 1em);
  cursor: pointer;
  color: #297F76;
  transition: 0.3s;
  :hover {
    color: tomato;
  }

`
export default ExternalListItem