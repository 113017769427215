import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import { AcceptIcon, CloseIcon } from '../icons/Icons';

export const CloseWindow = styled.div`
    position: ${props => props.position === 'absolute' ? 'absolute' 
        : props.position === 'reletive' ? 'reletive'
            : 'none'
    };
    top: .5em;
    right: ${props => props.close ? '.5em' : 'none'};
    left: ${props => !props.close ? '.5em' : 'none'};
    width: ${props => props.size === 'lg' ? '3.5em'
        : props.size === 'md' ? '3em'
            : props.size === 'sm' ? '2.5em'
                : 'none'
    };
    height: ${props => props.size === 'lg' ? '3.5em'
        : props.size === 'md' ? '3em'
            : props.size === 'sm' ? '2.5em'
                : 'none'
    };
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #7ab0ab;
    cursor: pointer;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    z-index: 2;

`
function OpenCloseButton({position, close, func, size}) {
    const currentIcon = close ? <CloseIcon/> : <AcceptIcon/>  
    return (
        <CloseWindow 
            position={position}
            close={close}
            onClick={func}
            size={size}
        >
            {currentIcon}
        </CloseWindow>
    );
}

export default OpenCloseButton;

OpenCloseButton.propTypes = {
    position: PropTypes.oneOf(['absolute', 'reletive']),
    close: PropTypes.bool,
    func: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
}
OpenCloseButton.defaultProps = {
    position: 'absolute',
    close: false,
    size: 'md',
    func: () => console.log('action'),
}
