import styled from 'styled-components'

const Button = styled.a`

    padding: 0.4em 1em;
    font-size: 1.2em;
    letter-spacing: 0.1em;
    color: #80e081;
    border-radius: 8px;
    border: 2px solid #80e081;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        background: #80e081;
        color: #fff;
    }
    outline: none;
`

export default function ButtonPerformance() {
    return(
        <Button  href="#form" >פרטים נוספים</Button>
    );
}