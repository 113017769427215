import React from 'react'
import { IMlaiDetailsItem } from '../../../../../../modules/API/Mlai/models/details'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import { AppleIcon, PackageIcon, ScalesIcon } from '../../../../../../generic/icons/Icons'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import styled from 'styled-components'

interface Props extends IMlaiDetailsItem {}

const DetailsItem = ({
   WeightActual,
   NumPackagesActulal, GeneralProductID,
   SizeID, ProductDesc, VarietyDesc, 
}: Props) => {
  return (
    <TitleLayout>
      <SidleWrapper>
            <ProductImage
                id={GeneralProductID}
            />
        </SidleWrapper>
      <TitleRow>
        <TitleRowItem name={`${ProductDesc.trim()} ${VarietyDesc.trim()} ${SizeID}`}  icon={<AppleIcon />}/>
      </TitleRow>
      <TitleRow>
        <TitleRowItem name={`${WeightActual.toLocaleString()}`}  icon={<ScalesIcon />}/>
        <TitleRowItem name={`${NumPackagesActulal}`}  icon={<PackageIcon />}/>
        {/* <TitleRowItem name={`${NumPalletsActual}`}  icon={<PalletIcon />}/> */}
      </TitleRow>
    </TitleLayout>
  )
}

const SidleWrapper = styled.div`
    position: absolute;
    left: 0.5em;
    width: 3.5em;
    height: 3.5em;
    top: calc(50% - 1.75em);
`
export default DetailsItem