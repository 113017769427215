import { AnyAction } from "redux"
import { CLEAR_ENTRY_STATE, CREATE_ENTRIE_DETAILS, CREATE_ENTRIE_TITLE, SELECT_DATA_FOR_ENTRY } from "../actions/actionsTypes"
import { IEntrie, IEntriesKnisotItem } from "../../components/modules/API/Knisot/models/entrie"

export interface IDriverState{
    date: string | null | [string | null, string | null]
    create: {
        title: IEntrie | null,
        details: IEntriesKnisotItem[] | null
    }
}

const initialState: IDriverState = {
    date: null,
    create: {
        title: null,
        details: null
    }
}

const entryReducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_DATA_FOR_ENTRY:
            return {
                ...state,
                date: action.payload
            }

        case CREATE_ENTRIE_TITLE:
            return {
                ...state,
                create: {
                    ...state.create,
                    title: action.payload,
                }
            }
        case CREATE_ENTRIE_DETAILS:
            return {
                ...state,
                create: {
                    ...state.create,
                    details: [
                        ...action.payload

                    ]
                }
            }    
        case CLEAR_ENTRY_STATE:
            return {
                ...initialState
            }
        default: return state
    }
}

export default entryReducer