import React, {useMemo, useState} from 'react'
import { ICarItem } from '../../../../../../modules/API/Defaults/models/cars'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultID: number
  setCarID: React.Dispatch<React.SetStateAction<number>>
  list: ICarItem[],
  required?: boolean,
  inputState?: (input: number) => void
}

const SearchCar = ({
  defaultID, setCarID, list, required, inputState
}: Props) => {
  const defaultCar = list.filter(item => item.CarID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedCar, setSelectedCar] = useState<ICarItem | null>(defaultCar || null)
  const [search, setSearch] = useState<string>(defaultCar?.CarNum?.toString()?.trim() || '')
  
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedCar(null)
      setCarID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
    if(inputState) {
      inputState(+e)
    }
  }
  const handleSelect = (item: ICarItem) => {
    setCarID(item.CarID)
    setSearch(item.CarNum.toString())
    setSelectedCar(item)
    setIsOpenDropDown(false)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      `${item.CarNum} ${item.DriverName.trim()}`.toString().includes(search)
    )
  }, [list, search])

  return (
    <SearchFromTheList
      isOpenList={isOpenDropDown}
      placeHolder={'חפש רכב'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='CarNum'
      nameIdElement='CarID'
      required={required}
      size={'full'}
      itemSecondaryName={'DriverName'}
    />
  )
}

export default SearchCar