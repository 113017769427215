import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'
import { PackageIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import { IStatisticWorkerItem } from '../../../../../../modules/API/Gathering/models/statisticWorker'

interface Props extends IStatisticWorkerItem {
    index: number
}

const RestWorkersItem = ({
    Qty, WorkerName, index
}: Props) => {
    const listRef = useRef(null);
    const desc = 
        WorkerName && WorkerName?.trim().length > 10 ? 
        '...' + WorkerName?.trim().slice(0, 10) : WorkerName?.trim()
  return (
    <MainWrapper>
        <RowItem>
            {index + 4} #
        </RowItem>
        <RowItem>
            {Qty}
            <PackageIcon />
        </RowItem>
        <RowItem>
            {desc}
            <UserIcon />
        </RowItem>
        
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    padding: 0.5em 1em;
    background: #fff;
    border-radius: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    font-size: 1.2em;
    color: #297f76;
    i {
        color: #81b4af;
        font-size: 1em;
    }
    -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
    filter: drop-shadow(2px 3px 3px rgb(215,231,242));

`
const RowItem = styled.div`
    display: flex;
    width: 30%;
    display: flex;
    justify-content: flex-end;
    gap: 0.4em;
    :first-child {
        width: 15%;
        justify-content: flex-start;
        
    }
    :last-child {
        width: 50%;
        /* background: red; */
    }
`
export default RestWorkersItem