import React from 'react'
import styled from 'styled-components';

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

function TableBody({
    children
}: Props) {
  return (
    <TableBodyStyle>
        {children}
    </TableBodyStyle>
  )
}
const TableBodyStyle = styled.tbody`

`
export default TableBody