import { AnyAction } from "redux"
import { ISortingListItem } from "../../components/modules/API/Miun/models/sortingList"
import { SELECT_SORTING_DATA, SELECT_SORTING_DATE, UPDATE_ENTRIES } from "../actions/actionsTypes"

export interface IMiunData {
    selectedSortingItem: ISortingListItem | null,
    date: string | null | [string | null, string | null]
}

const initialState: IMiunData = {
    selectedSortingItem: null,
    date: null
}

const miunReducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_SORTING_DATA:
            return {
                ...state,
                selectedSortingItem: action.payload
            }
     
        case UPDATE_ENTRIES:
            return {
                ...state,
                selectedSortingItem: {
                    ...state.selectedSortingItem,
                    Entries: action.payload
                }
            }
        case SELECT_SORTING_DATE:
            return {
                ...state,
                date: action.payload
            }
        default: return state
    }
}

export default miunReducer