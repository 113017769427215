import { likutTranslationEN } from "./en/likut"
import { likutTranslationHE } from "./he/likut"
import { likutTranslationAR } from "./ar/likut"
import { likutTranslationRU } from "./ru/likut"
import { likutTranslationTH } from "./th/likut"
import {settingsTranslationEN} from "./en/settings"
import {settingsTranslationHE} from "./he/settings"
import {settingsTranslationAR} from "./ar/settings"
import {settingsTranslationRU} from "./ru/settings"
import {settingsTranslationTH} from "./th/settings"
import {buttonsTranslationEN} from "./en/buttons"
import {buttonsTranslationRU} from "./ru/buttons"
import {buttonsTranslationHE} from "./he/buttons"
import {buttonsTranslationAR} from "./ar/buttons"
import {buttonsTranslationTH} from "./th/buttons"
import { packageOrderTranslationHE } from "./he/packageOrder"
import { packageOrderTranslationEN } from "./en/packageOrder"
import { packageOrderTranslationRU } from "./ru/packageOrder"
import { packageOrderTranslationAR } from "./ar/packageOrder"
import { packageOrderTranslationTH } from "./th/packageOrder"


export const translation = {
  en: {
    ...buttonsTranslationEN,
    ...likutTranslationEN,
    ...settingsTranslationEN,
    ...packageOrderTranslationEN,

  },
  he: {
    ...buttonsTranslationHE,
    ...likutTranslationHE,
    ...settingsTranslationHE,
    ...packageOrderTranslationHE,
  },
  ru: {
    ...buttonsTranslationRU,
    ...likutTranslationRU,
    ...settingsTranslationRU,
    ...packageOrderTranslationRU,

  },
  ar: {
    ...buttonsTranslationAR,
    ...likutTranslationAR,
    ...settingsTranslationAR,
    ...packageOrderTranslationAR,

  },
  th: {
    ...buttonsTranslationTH,
    ...likutTranslationTH,
    ...settingsTranslationTH,
    ...packageOrderTranslationTH,

  }
}