import React, { useContext } from 'react'
import styled from 'styled-components'
import { CloseIcon } from '../../../../../../generic/icons/Icons'
import { LikutModals } from '../../../../../../modules/Context/CefiNum';

export const CloseButtonReson = styled.div`
    position: absolute;
    top: .7em;
    left: .7em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #81b4af;
    font-size: 1.1em;
    cursor: pointer;
    z-index: 1;
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
`

export default function CloseBtnReson() {
    const [resonState, setResonState] = useContext(LikutModals);
    return (
        <CloseButtonReson onClick={() => {setResonState({...resonState, 'active': false})}}><CloseIcon /></CloseButtonReson>
    )
}
