import React from 'react'
import styled from 'styled-components'
import { ErrorIcon } from '../icons/Icons'

const MainWrapper = styled.div`
    width: 100%;
    height: 100px;
    /* background: yellowgreen; */
    display: flex;
    justify-content: flex-start;
    color: tomato;
   
`
const MessageIndicatorContainer = styled.div`
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: blue; */
    font-size: 2em;
`
const MessageTextContainer = styled.div`
    width: 70%;
    height: 100%;
    /* background: yellow; */
    padding: 0 1em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    gap: 0.2em;
`
const Text = styled.p`

`
export default function WarningMessage({messageArray}) {
    return (
        <MainWrapper>
            <MessageIndicatorContainer>
                <ErrorIcon />
            </MessageIndicatorContainer>
            <MessageTextContainer>
                {
                    messageArray?.map((e, index) => 
                        <Text key={index}>{e}</Text>
                    )
                }
            </MessageTextContainer>
        </MainWrapper>
    )
}
