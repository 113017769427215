import React, { useMemo, useState, useRef } from 'react'
import styled from 'styled-components';
import { CloseIcon, SearchIcon } from '../../../../generic/icons/Icons';
import selectOnFocus from '../../../../modules/autoselect';
import { useDetectOutsideClick } from '../../../../generic/hooks/useDetectOutsideClick';

type Props = {
    list: IMultySelectState[];
    state: IMultySelectState[];
    lable: string;
    handleSelectFunction: (item: IMultySelectState) => void;
    handleDeleteFunction: (item: IMultySelectState) => void;
}
export interface IMultySelectState {
    id: number, 
    desc: string
}

function MultySelect({
    list, state, handleSelectFunction, handleDeleteFunction, lable
}: Props) {
    const [input, setInput] = useState<string>('');
    const [isOpenList, setIsOpenList] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null)
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
    }
    const handleClearStateSearch = () => {
        setInput('');
    }
    const handleControlList = () => {
        setIsOpenList(false);
    }
    const formatedList = useMemo(() => {
        const filter = list.filter(item => 
            !state.some(e => e.id === item.id) && 
                item.desc.toLowerCase().includes(input.toLowerCase())
        )
        return filter
    }, [list, state, input])
    console.log(list)
    useDetectOutsideClick(ref, handleControlList)
    console.log(ref)
  return (
    <MainWrapper>
        {/* <Title>asd</Title> */}
        <SearchWrapper>
            <SearchIndicator>
                <SearchIcon />
            </SearchIndicator>
            {
                input && 
                    <CloseIndicator onClick={handleClearStateSearch}>
                        <CloseIcon handler={() => {}} />
                    </CloseIndicator>
            }
            <SearchListWrapper ref={ref}>
                {
                    isOpenList &&
                    formatedList.map((item, index) => 
                        <DropDownItem
                           
                            key={item.id}
                            onClick={() => handleSelectFunction(item)}
                        >
                            {item.desc}
                        </DropDownItem>    
                    )
                }
            </SearchListWrapper>
            <Input 
                type='text'
                value={input}
                placeholder={lable}
                onChange={handleSearch}
                onFocus={selectOnFocus}
                onClick={() => setIsOpenList(true)}
            />
        </SearchWrapper>
        {
            state.length > 0 &&
                <SelectedFieldWrapper>
                    {
                        state.map(item => 
                            <SelectedItem
                                key={item.id}
                                onClick={() => handleDeleteFunction(item)}
                            >
                                {item.desc}
                            </SelectedItem>    
                        )
                    }
                </SelectedFieldWrapper>
        }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    min-width: 3em;
    width: 90%;
    background: #f2f2f2;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    padding: 0.5em;
    gap: 0.5em;

`
const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    color: #757575;
    font-size: 0.9em;
`
const SearchWrapper = styled.label`
    position: relative;
    width: 100%;
    /* background: yellow; */
`
const Input = styled.input`

    width: 100%;
    height: 2em;
    border-radius: 10px;
    border: none;
    outline: none;
    direction: rtl;
    padding: 0 2em;
    color: #757575;
`
const SearchIndicator = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
    i {
        color: #81b4af
    }

`
const CloseIndicator = styled(SearchIndicator)`
    right: none;
    left: 0;
    cursor: pointer;
`

const SearchListWrapper = styled.div`

    position: absolute;
    bottom: 2em;
    right: 0;
    max-height: 7em;
    width: max-content;
    padding: 0 0 0.5em 0;
    background: #f2f2f2;
    display: flex;
    flex-flow: column;
    gap: 0.2em;
    border-radius: 20px 10px 0 0;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0;
    }
`

const DropDownItem = styled.div`
    width: 100%;
    padding: 0.2em 0.5em 0.2em 1em;
    display: flex;
    justify-content: flex-end;
    :hover {
        background: #80e081;
    }
    cursor: pointer;
`
const SelectedFieldWrapper = styled.div`
    width: 100%;
    padding: 0.5em;
    display: flex;
    flex-flow: row-reverse wrap;
    gap: 0.5em;
    background: #fff;
    border-radius: 10px;

`
const SelectedItem = styled.div`
    position: relative;
    padding: 0.4em;
    background: #f2f2f2;
    border-radius: 10px;
    cursor: pointer;
`
const DeleteOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
    /* z-index: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
`
export default MultySelect