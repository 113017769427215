import { useTranslation } from 'react-i18next';

export const returnResonBynum = (id) => {
    const resons = {
        0: '',
        1: 'התאמת משקל',
        2: 'אישור מנהל',
        3: 'מחסור במלאי',
        4: 'פיצול בין משטחים',
        5: 'החלפת מוצר',
        6: 'משטח קומפלט'
    }
    return resons[id]
}

export const useResonDescByNum = (num) => {
    const [t] = useTranslation(['likut']);
    const table =  {
        0: t('likut_reasons_0'),
        1: t('likut_reasons_1'),
        2: t('likut_reasons_2'),
        3: t('likut_reasons_3'),
        4: t('likut_reasons_4'),
        5: t('likut_reasons_5'),
        6: t('likut_reasons_6')
    }
    return table[num]
}