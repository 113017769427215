import React, { useState } from "react";
import styled from "styled-components";
import {
  ArrowIcon,
  PackageIcon,
  ScalesIcon,
  ShekelIcon,
  ShopBagIcon,
  UserIcon,
} from "../../../../../generic/icons/Icons";
import { stylePackByID } from "../../../../../generic/utils/stylePackByPackType";
import { IPackagesItem } from "../../../../../modules/API/Defaults/models/packages";
import { IPalletDetails } from "../../../../../modules/API/Pallets/models/palletDetails";
import DetailsInput from "./DetailsInput";

interface IPropsTeudaDetailes extends IPalletDetails {
  packageHash: Record<number, IPackagesItem>;
  isEdit: string | undefined;
}

interface IMainWrapper {
  decorationColor?: string;
}


type TSwitcher = {
    isTranslated?: boolean;
};
type TSwitchBtnWrapper = {
    isEdit?: string | undefined;
};




const ViewItem = ({
  Pack_Name, ProductDesc, VarietyDesc,
  SizeID, PackID, packageHash,
  GrowerName, WeightNeto, Quantity,
  SalePrice, BuyPrice, WeightBuy, isEdit
}: IPropsTeudaDetailes) => {
  const [isSwitched, setIsSwitched] = useState(false);
  const handleSwitch = () => {
    if (!isEdit) return
    setIsSwitched(!isSwitched);
  };
  const packTypeCase = packageHash[PackID]?.Pack_Type;
  
  const { color, component } = stylePackByID(packTypeCase);
  const growerString = GrowerName && GrowerName?.trim().length > 25 ? '...' + GrowerName?.trim().slice(0, 25) : GrowerName?.trim()
  return (
    <MainWrapper decorationColor={color}>
      <Title>
        {ProductDesc?.trim()} {VarietyDesc?.trim()} {SizeID?.trim()}
      </Title>
      <BodyContainer>
        <RowItemContainer decorationColor={color}>
          {Quantity} - {Pack_Name}{component}
        </RowItemContainer>
        <RowItemContainer>
          {growerString}
          <UserIcon />
        </RowItemContainer>
        <SwitcherContainer>
          <SwitchElementContainer isTranslated={isSwitched}>
            <SwitchBtnWrapper onClick={handleSwitch} isEdit={isEdit}>
              <ArrowIcon position={"right"} />
            </SwitchBtnWrapper>
            <DataContainer>
              <RowItemContainer>מכירה</RowItemContainer>
              <RowItemContainer>
                <DetailsInput defValue={WeightNeto} />
                <IconWrapper>
                  <ScalesIcon />
                </IconWrapper>
              </RowItemContainer>
               {
                    isEdit &&
                    <RowItemContainer>
                        <DetailsInput defValue={SalePrice} />
                        <IconWrapper>
                            <ShekelIcon />
                        </IconWrapper>
                  </RowItemContainer>
               }
            </DataContainer>
          </SwitchElementContainer>
          <SwitchElementContainer isTranslated={isSwitched}>
            <SwitchBtnWrapper onClick={handleSwitch} isEdit={isEdit}>
              <ArrowIcon position={"left"} />
            </SwitchBtnWrapper>
            <DataContainer>
              <RowItemContainer>קניה</RowItemContainer>
              <RowItemContainer>
                <DetailsInput defValue={WeightBuy} />
                <IconWrapper>
                  <ScalesIcon />
                </IconWrapper>
              </RowItemContainer>
              <RowItemContainer>
                <DetailsInput defValue={BuyPrice} />
                <IconWrapper>
                  <ShekelIcon />
                </IconWrapper>
              </RowItemContainer>
            </DataContainer>
          </SwitchElementContainer>
        </SwitcherContainer>
      </BodyContainer>
    </MainWrapper>
  );
};

export default ViewItem;


const MainWrapper = styled.div<IMainWrapper>`
  position: relative;
  width: 300px;
  height: 220px;
  background: #ffff;
  border-radius: 30px;
  color: #75759e;
  padding: 0.5em;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  font-size: 1.1em;
  overflow: hidden;
  box-shadow: 5px 5px 10px #c1c1c1, -5px -5px 10px #ffffff;
  -webkit-box-shadow: 5px 5px 10px #c1c1c1, -5px -5px 10px #ffffff;
  ::after {
    position: absolute;
    content: "";
    top: -0.2em;
    left: -1.2em;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${(props) => props.decorationColor};
    /* z-index: 3; */
  }
  ::before {
    position: absolute;
    content: "";
    bottom: -3.2em;
    right: -1.2em;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${(props) => props.decorationColor};
    /* z-index: 3; */
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  z-index: 1;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  /* background: red; */
  padding: 0.5em 0.2em;
  z-index: 1;
`;

const RowItemContainer = styled.label<IMainWrapper>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
  i {
    color: ${(props) => props.decorationColor};
  }
`;

const SwitcherContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgb(239, 243, 244);
  border-radius: 25px;
  /* box-shadow: 1px 1px 8px #c1c1c1, -1px -1px 8px #ffffff; */
  

`;

const SwitchElementContainer = styled.div<TSwitcher>`
  min-width: 100%;
  height: 100%;
  /* background: blue; */
  display: flex;
  justify-content: space-between;
  transform: ${(props) =>
    props.isTranslated ? "translateX(-100%)" : "translateX(0)"};
  transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

const DataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.2em 1em;
  /* background: red; */
`;

const SwitchBtnWrapper = styled.div<TSwitchBtnWrapper>`
  position: relative;
  height: 100%;
  min-width: 4em;
  display: ${props => props.isEdit ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    transition: 0.5s;
  }
  :hover {
    i {
      color: tomato;
    }
  }
`;

const IconWrapper = styled.div`
  min-width: 1.5em;
  min-height: 1.5em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #75759e;
  i {
    font-size: 0.7em;
  }
`;
