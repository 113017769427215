import styled from 'styled-components'
import { IOrderStatus } from '../../../../../../models/Defaults/orderSatuses'
import { AcceptIcon } from '../../../../../generic/icons/Icons'

type Props = {
    controlFunc: (id?: number | null, clear?: boolean) => void;
    state: Record<number, boolean>;
    item: IOrderStatus
}

interface ICheck {
    isSelected: boolean
}

const ItemWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 0.2em;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
`

const Check = styled.div<ICheck>`
    position: absolute;
    left: 0;
    display: ${props => props.isSelected ? 'flex' : 'none'}
`

const OrderSelectItem = ({controlFunc, state, item}: Props) => {
  return (
    <ItemWrapper 
        onClick={() => {controlFunc(item.id)}}
    >
        <Check isSelected={state[item.id]}>
            <AcceptIcon />
        </Check>
        {item.Desc?.trim()}
    </ItemWrapper>
  )
}

export default OrderSelectItem