import format from "date-fns/format";
import React, { useState } from "react";
import styled from "styled-components";
import { transform } from "typescript";
import { AppleIcon, PackageIcon } from "../../../../generic/icons/Icons";
import PercentsBlock from "../../../../generic/particles/PercentsBlock";
import {
  IDateRanger,
  TDateRangerList,
} from "../../../../generic/utils/forDate/stateModel";
import { convertToDate } from "../../../../modules/convertDateString";
import { IDate } from "./ReportPriceMain";

type Props = {
  Sum1: string;
  Sum2: string;
  Sum3: string;
  Sum4: string;
  Sum5: string;
  Sum6: string;
  Desc1: string;
  date: TDateRangerList;
  Code1: string;
  getPriceDetaules?: (id: number) => void;
  handleTitle?: (desk: string) => void;
  detailes?: boolean;
};
interface IRestObject {
  qty: number;
  perc: number;
}
interface ITitle {
  dropBox?: boolean;
}
interface IDropBox {
  isOpen: boolean;
}

const MainWrapper = styled.div`
  width: 100%;
  /* min-height: 2em; */
  background: #e9f0f0;
  border-radius: 30px;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em 0.7em;
  cursor: pointer;
  color: #297f76;
`;
export const TitleContainer = styled.div<ITitle>`
  width: 100%;
  height: 2em;
  background: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
  gap: 0.5em;
`;
type TTitleItem = {
  highlited?: boolean;
};
const TitleItem = styled.div<TTitleItem>`
  display: flex;
  gap: 0.5em;
  justify-content: space-between;
  ${(props) =>
    props.highlited
      ? {
          background: "#eff3f4",
          padding: "0.2em 0.5em",
          transform: "translateY(-2px)",
          borderRadius: "10px;",
          color: "#757591",
        }
      : ""}
`;
const HiddenBodyWrapper = styled.div<IDropBox>`
  width: 100%;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-flow: column;
  border-radius: 30px;
  gap: 0.5em;
  padding: 0.5em 0;
  background: #fff;
  transition: 1s;
`;
const RecordItem = ({
  Sum1,
  Sum2,
  Sum3,
  Sum4,
  Sum5,
  Sum6,
  Desc1,
  date,
  getPriceDetaules,
  handleTitle,
  Code1,
  detailes,
}: Props) => {
  const Sum1ToNumber = +Sum1;
  const Sum2ToNumber = +Sum2;
  const Sum3ToNumber = +Sum3;
  const Sum4ToNumber = +Sum4;
  const Sum5ToNumber = +Sum5;
  const Sum6ToNumber = +Sum6;
  // console.log(convertToDate(date.FIRST.START))
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const calcRestInPersents = (arg1: number, arg2: number): IRestObject => {
    let rest = Math.round(arg2 - arg1);
    return {
      qty: rest,
      perc: Math.round((rest / arg2) * 100),
    };
  };
  const restOfCurrent = calcRestInPersents(Sum1ToNumber, Sum2ToNumber);
  const restOfPrev = calcRestInPersents(Sum3ToNumber, Sum4ToNumber);
  const restOfLatest = calcRestInPersents(Sum5ToNumber, Sum6ToNumber);
  const salesVolumeChange = Math.round(
    ((Sum1ToNumber - Sum3ToNumber) / Sum1ToNumber) * 100
  );
  const restVolumeChange = Math.round(restOfCurrent.perc - restOfPrev.perc);
  console.log(Desc1, restOfCurrent.perc, restOfPrev.perc);
  const isValidRow = (value: number) => {
    return !isNaN(value) && value !== Infinity && value !== -Infinity;
  };
  const goodRecord = +((Sum6ToNumber / Sum5ToNumber) * 100).toFixed(2);
  const detailesData = () => {
    const rest = +(
      ((Sum2ToNumber - Sum1ToNumber) / Sum2ToNumber) *
      100
    ).toFixed(2);
    const restSalesOfPrev = +(
      ((Sum1ToNumber - Sum3ToNumber) / Sum3ToNumber) *
      100
    ).toFixed(2);
    const qtyOfStifSales = +((Sum1ToNumber / Sum4ToNumber) * 100).toFixed(2);
    const qtyOfSales = +((Sum6ToNumber / Sum5ToNumber) * 100).toFixed(2);

    return [rest, restSalesOfPrev, qtyOfStifSales, qtyOfSales];
  };
  const [rest, restSalesOfPrev, qtyOfStifSales, qtyOfSales] = detailesData();

  return (
    <MainWrapper
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <TitleContainer>
        {isValidRow(salesVolumeChange) ? (
          <TitleItem>
            {!detailes && (
              <>
                <PercentsBlock
                  mood={salesVolumeChange >= 0}
                  value={restVolumeChange}
                  reverse
                />
                <PercentsBlock
                  mood={salesVolumeChange >= 0}
                  value={salesVolumeChange}
                />
              </>
            )}
            {detailes && isValidRow(rest) && (
              <TitleItem>
                {/* <PercentsBlock
                                mood={false}
                                value={rest}
                                reverse
                            /> */}
                {rest}%
              </TitleItem>
            )}
          </TitleItem>
        ) : (
          <TitleItem></TitleItem>
        )}

        <TitleItem
          onClick={(e) => {
            if (getPriceDetaules) {
              e.stopPropagation();
              getPriceDetaules(+Code1);
              if (!handleTitle) return;
              handleTitle(Desc1);
            }
          }}
          // style={{width: '66%'}}
        >
          {detailes && (
              <TitleItem highlited>
                {Math.round(Sum1ToNumber)} <PackageIcon />{" "}
              </TitleItem>
            
          )}
          {Desc1?.trim()}
        </TitleItem>
      </TitleContainer>
      <HiddenBodyWrapper isOpen={isOpen}>
        {!detailes ? (
          <>
            <TitleContainer dropBox>
              {isValidRow(restOfCurrent.perc) ? (
                <TitleItem>
                  <PercentsBlock
                    mood={restOfCurrent.perc <= restOfPrev.perc}
                    value={restOfCurrent.perc}
                    reverse
                  />
                </TitleItem>
              ) : (
                <TitleItem></TitleItem>
              )}
              <TitleItem>
                <TitleItem highlited>
                  {Math.round(Sum1ToNumber)} <PackageIcon />
                </TitleItem>
                <TitleItem>
                  {convertToDate(format(date.selection1.endDate, "yyyyMMdd"))}-
                  {convertToDate(format(date.selection1.startDate, "yyyyMMdd"))}
                </TitleItem>
              </TitleItem>
            </TitleContainer>
            <TitleContainer dropBox>
              {isValidRow(restOfPrev.perc) ? (
                <TitleItem>
                  <PercentsBlock
                    mood={restOfPrev.perc <= restOfLatest.perc}
                    value={restOfPrev.perc}
                    reverse
                  />
                </TitleItem>
              ) : (
                <TitleItem></TitleItem>
              )}
              <TitleItem>
                <TitleItem highlited>
                  {Math.round(Sum3ToNumber)} <PackageIcon />
                </TitleItem>
                <TitleItem>
                  {convertToDate(format(date.selection2.endDate, "yyyyMMdd"))}-
                  {convertToDate(format(date.selection2.startDate, "yyyyMMdd"))}
                </TitleItem>
              </TitleItem>
            </TitleContainer>
            <TitleContainer dropBox>
              {isValidRow(restOfLatest.perc) ? (
                <TitleItem>
                  <PercentsBlock
                    mood={restOfLatest.perc < 50}
                    value={restOfLatest.perc}
                    reverse
                  />
                </TitleItem>
              ) : (
                <TitleItem></TitleItem>
              )}
              <TitleItem>
                <TitleItem highlited>
                  {Math.round(Sum5ToNumber)} <PackageIcon />
                </TitleItem>
                <TitleItem>
                  {convertToDate(format(date.selection3.endDate, "yyyyMMdd"))}-
                  {convertToDate(format(date.selection3.startDate, "yyyyMMdd"))}
                </TitleItem>
              </TitleItem>
            </TitleContainer>
          </>
        ) : (
          <>
            <TitleContainer dropBox>
              {isValidRow(restSalesOfPrev) ? (
                <TitleItem>
                  <PercentsBlock
                    mood={restSalesOfPrev > rest}
                    value={restSalesOfPrev}
                    reverse={restSalesOfPrev > rest}
                  />
                  {/* {restSalesOfPrev}% */}
                </TitleItem>
              ) : (
                <TitleItem></TitleItem>
              )}
              <TitleItem>
                <TitleItem highlited>
                  {Math.round(Sum3ToNumber)}
                  <PackageIcon />
                </TitleItem>
                <TitleItem>שינוי מכר</TitleItem>
              </TitleItem>
            </TitleContainer>
            <TitleContainer dropBox>
              {isValidRow(qtyOfStifSales) ? (
                <TitleItem>
                  {/* <PercentsBlock
                            mood={!!qtyOfStifSales}
                            value={qtyOfStifSales}
                            
                            /> */}
                  {qtyOfStifSales}%
                </TitleItem>
              ) : (
                <TitleItem></TitleItem>
              )}
              <TitleItem>
                {/* <TitleItem>{Math.round(Sum3ToNumber)}</TitleItem> */}
                <TitleItem>ממכר סניף</TitleItem>
              </TitleItem>
            </TitleContainer>
            <TitleContainer dropBox>
              {isValidRow(qtyOfSales) ? (
                <TitleItem>
                  {/* <PercentsBlock
                            mood={!!qtyOfSales}
                            value={qtyOfSales}
                            
                            /> */}
                  {qtyOfSales}%
                </TitleItem>
              ) : (
                <TitleItem></TitleItem>
              )}
              <TitleItem>
                {/* <TitleItem>{Math.round(Sum3ToNumber)}</TitleItem> */}
                <TitleItem>ממכר רשת</TitleItem>
              </TitleItem>
            </TitleContainer>
          </>
        )}
      </HiddenBodyWrapper>
    </MainWrapper>
  );
};

export default RecordItem;
