import React, { useMemo } from 'react'
import styled from 'styled-components'
import { returnResonBynum, useResonDescByNum } from '../../../../../generic/artcoded/resonslikut'
import { AppleIcon, EditIcon, PackageIcon, ScalesIcon, ShopBagIcon } from '../../../../../generic/icons/Icons'
import { EXIST_PATH } from '../../../../../modules/API/settings'
import ProductsList from './ProductsList'

const ItemDetailesWrapper = styled.div`
   background: rgba(239,243,244, 0.4);
   position: relative;
   min-height: 3em;
   border-radius: 30px;
   display: flex;
   flex-flow: column;
   color: #75759e; 
   -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
        box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
`
const ImageContainer = styled.div`
    position: absolute;
    left: 0.4em;
    top: 0.4em;
    background: yellow;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
`
const Title = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3em 1em 0.2em 5em;
    gap: 0.5em;
    p {
        font-weight: 600;
    }
`
const Body = styled(Title)`
    color: ${props => props.reason === 3 ? 'tomato' 
        : props.reason === 2 ? '#000' 
            : props.reason === 1 ? 'blue' 
                : props.reason === 5 ? 'green' 
                    : ''
    };
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1em;
`
export default function DetailesItem({
    ImageUrl, ProductDesc, VarietyDesc, SizeID, Pallets,selectProductForRemark,
    OrderPacks, SupplyQty, ChangeReason, selectIconForPalletType, item, RecID,
    PackID, Remarks, isWeightCalculation, SupplyWeight, OrderWeight
}) {
    const reason = useResonDescByNum(+ChangeReason);
    const handleCallRemark = (e) => {
        e.stopPropagation();
        if(!selectProductForRemark || !Remarks?.trim()) return;
        const data = {
            title: `${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeID?.trim()}` ,
            body: Remarks,
            id: RecID
        }
        selectProductForRemark(data);
    }
   
      // Expected output: "Motorcycle, Bus, and Car"
    const packType = useMemo(() => selectIconForPalletType(PackID), [PackID]) ;
    let bacPic = !ImageUrl ?
        'url("https://pngicon.ru/file/uploads/otkrytaja-korobka-128x85.png") center center no-repeat' :
        `url("${EXIST_PATH}${ImageUrl}") center center no-repeat`
    return (
        <ItemDetailesWrapper
            onClick={handleCallRemark}
        >
            <ImageContainer style={{ background: bacPic, backgroundSize: 'contain' }}></ImageContainer>
             {
                isWeightCalculation ?
                    <>
                        <LefiMishkalContainer>
                            {`${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeID?.trim()}`}<AppleIcon /> 
                        </LefiMishkalContainer>
                        <LefiMishkalContainer>
                            <p>{`${SupplyQty}/${OrderPacks}`} <PackageIcon /></p>
                            <p>{`${SupplyWeight.toFixed(1)}/${OrderWeight.toFixed(1)}`} <ScalesIcon /></p>

                        </LefiMishkalContainer>
                    </> :
                        <Title>
                            <p>{`${SupplyQty}/${OrderPacks}`}</p>
                            
                            {`${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeID?.trim()}`}
                        </Title>

             }
         
            <Body reason={ChangeReason}>
                {
                    Remarks?.trim() ?
                        <RemarkWrapper>
                            <EditIcon />
                        </RemarkWrapper> : null
                }
                <>
                    {
                        packType === '1' ?
                            <ScalesIcon />
                            : packType === '2' ?
                                <ShopBagIcon />
                                : ''
                    }
                </>
                <>
                    {ChangeReason !== 0 ? `סיבה: ${reason}` : ''}
                </>
            </Body>
            <ProductsList 
                list={Pallets} 
                reson={ChangeReason} 
            />
        </ItemDetailesWrapper>
    )
}
const RemarkWrapper = styled.div`
    
`

const LefiMishkalContainer = styled.div`
    display: flex;
    /* flex-flow: column; */
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em;
    gap: 0.5em;

`