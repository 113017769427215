import React, {useMemo} from 'react'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../generic/assets/delivery.png'
import { IOrderItem } from '../../../../../modules/API/Drivers/models/kavData'
import { BodyContainer } from '../../likutExpress/likutWorkers/LikutSetWorkers'
import OrderItem from '../ItemComponents/OrderItem'
import { ControlPanelWrapper } from '../../secondbar/modalAzmanot/modalAzmanot'
import { ITableOfTableItem } from '../../../../../modules/API/Defaults/models/tableOfTables'
interface IProps {
    confirmDeliveryOrder: (id: number) => void,
    selectedDeliveryOrder: IOrderItem[],
    defaultStatuses: ITableOfTableItem[],
    handleSelectOrder: (item: IOrderItem) => void,
    actionList: ITableOfTableItem[]
}

const DriverModal = ({
    confirmDeliveryOrder, selectedDeliveryOrder, 
    defaultStatuses, handleSelectOrder, actionList
}: IProps) => {
    console.log(selectedDeliveryOrder)
    const handleClick = (id: number) => {
        confirmDeliveryOrder(id)

    }
    const isDisabled = useMemo(() => {
        return !selectedDeliveryOrder.some(e => e.Selected)
    }, [selectedDeliveryOrder])
    return (
    <MainWrapper>
        <ImgContainer>
            <ImageComponentHead 
                img={Image}
                large
            />
        </ImgContainer>
        <BodySelectWrapper>
                {
                    selectedDeliveryOrder.map((e, index) => 
                        <ItemWithRemark>
                            <OrderItem
                                item={e}
                                handleSelectOrder={handleSelectOrder}
                                isNotMain 
                                key={e.OrderID} 
                                {...e} 
                             />
                            {
                                e.DistributNote?.trim() &&
                                    <Remark>{e.DistributNote}</Remark>
                            }
                        </ItemWithRemark>
                    )
                }
        </BodySelectWrapper>
        {
            actionList.map(status => 
                status.SecondCode !== 0 ? <BodyElement
                    key={status.SecondCode}
                    color={!status.Flag ? 'tomato' : ''}
                    onClick={() => {handleClick(status.SecondCode)}}
                    disabled={isDisabled}
                >
                    {status.Description.trim()}
                </BodyElement> : null
            )
        }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;

`
const ImgContainer = styled.div`
    height: 13em;
    width: 100%;
    display: flex;
    justify-content: center;
`
const BodySelectWrapper = styled.div`
    width: 80%;
    max-height: 40%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    /* background: red; */
    gap: 1em;
    padding: 0.5em 0;
    overflow: auto;
    ::-webkit-scrollbar{
        width: 0
    }
`
const ItemWithRemark = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  align-items: flex-end;

`
const Remark = styled.div`
    display: flex;
    justify-content: flex-end;

`
// 617
const BodyElement = styled.button<{color?: string}>`
    width: 80%;
    padding: 0.5em;
    font-size: 1em;
    background: ${props => props.color ? props.color : '#7ab0ab'};
    border-radius: 30px;
    border: none;
    font-weight: 600;
    color: #fff;
    opacity: 0.8;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    :hover {
        opacity: 1;
    }
    :disabled {
        opacity: 0.5;
        cursor: default;
    }
`

export default DriverModal