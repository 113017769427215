import React from 'react'
import styled from 'styled-components'
import Title from '../Title/Title'
import { Decorator } from './OrderlikeLayout'

type Props = {
    children: React.ReactNode ,
    label: string,
    titleColor?: string,
    decoratorColor?: string
}

const FullSizeLayout = ({label, titleColor, children, decoratorColor}: Props) => {
  return (
    <FullSizeWrapper>
        <ScreenContainer>
            <Decorator color={decoratorColor} />
            <Title label={label} color={titleColor} />
            {children}
        </ScreenContainer>
    </FullSizeWrapper>
  )
}

const FullSizeWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: #e9f0f0;
    padding: 0.5em;

`
const ScreenContainer = styled.div`
    position: relative;
    max-width: 1600px;
    height: 100%;
    background: #e9f0f0;
    border-radius: 30px;
    margin: 0 auto;
    padding: 0 0.5em 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    overflow: hidden;
    @media screen and (max-width: 400px) {
        border-radius: 0;
    }
`

export default FullSizeLayout