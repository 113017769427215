import React from 'react'
import { IPackageLogData } from '../../../../../modules/API/Drivers/models/packageLogData'
import styled from 'styled-components'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { InvoiceIcon, PackageIcon, PalletIcon } from '../../../../../generic/icons/Icons'

interface Props extends IPackageLogData {
  item: IPackageLogData

}

const PackageLogItem = ({
  item,
  Pack_Name,
  Qty,
  PD_Type,
  HasTeuda
}: Props) => {
  return (
    <MainWrappper>
      <TitleRowItem
        name={`${Qty}`}  
        icon={PD_Type === 'א' ? <PackageIcon /> : <PalletIcon />}

      />
      <TitleRowItem
        name={`${Pack_Name.trim()}`}
        icon={HasTeuda ? <InvoiceIcon /> : <></>}
      />
    </MainWrappper>
  )
}
  const MainWrappper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(41,127,118, 0.5);
    padding: 0.5em 1em;

  `
export default PackageLogItem