import React, {useMemo, useState} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import MarketMainLayout from '../../market/Collect/Layout/MarketMainLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Reports } from '../../../../../modules/API/Reports/reports'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { CalendatDay, PackageIcon, PalletIcon, ScalesIcon, SettingMiksher, ShekelIcon } from '../../../../../generic/icons/Icons'
import { IReportListItem } from '../../../../../modules/API/Reports/models/reportList'
import { IReportQ4, IReportQ4Response } from '../../../../../modules/API/Reports/models/q4'

import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { convertToDate } from '../../../../../modules/convertDateString'
import styled from 'styled-components'
import MainFilter from '../Q4/Components/MainFilter'
import Q4Layout from '../Q4/Layouts/Q4Layout'
import Q4Table from '../Q4/Components/Q4Table'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { SELECT_DATE_PROCEDURES_Q2, SELECT_DATE_PROCEDURES_Q4, SELECT_REPORT_PROCEDURES_Q2, SELECT_REPORT_PROCEDURES_Q4 } from '../../../../../../store/actions/actionsTypes'
import { ITeudotTypeItem } from '../../../../../modules/API/Defaults/models/teudotTypes'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'

type Props = {}

const MainQ2 = (props: Props) => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchReport, setSearchReport] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpenMainFilter, setIsOpenMainFilter] = useState<boolean>(true);
  const [q2Data, setQ2Data] = useState<IReportQ4Response | null>(null)

  const dispatch = useDispatch()
  const setDefaultDate = useSelector((state: RootState) => state.procedures.Q2.date)
  const selectedReport = useSelector((state: RootState) => state.procedures.Q2.selectedReport)
  const handleDate = (e: object | object[]) => {
    console.log(e)
        dispatch({type: SELECT_DATE_PROCEDURES_Q2, payloadDate: e}
    )
} 
  const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
  
  const {
    data: teudotTypeList
  } = useDataFetcher<ITeudotTypeItem[]>(
    Defaults.getTblTeudotTypes, 'כ'
  )

  const {
    data: reportList,
    isLoading: reportListIsLoading
  } = useDataFetcher<IReportListItem[]>(
    Reports.getReportList, "1",
  )

  const getQ2Data = async (RepID: number) => {
    handleControlMainFilter()
    setLoading(true)
    try {
      let reqData: IReportQ4 = {
        FromDate: startDate,
        ToDate: endDate,
        RepID: RepID,
      }
      let res = await Reports.getReportQ2(reqData);
      setQ2Data(res)
      console.log(res)
    } catch (error) {
      handleControlMainFilter()
      
    } finally {
      setLoading(false)
    }
  }
  const handleControlMainFilter = () => {
    setIsOpenMainFilter(!isOpenMainFilter)
  }
  const handleDispatchReport = (item: IReportListItem) => {
    dispatch({type: SELECT_REPORT_PROCEDURES_Q2, report: item})

  }
  const mainFilterProps = {
    setEndDate: setEndDate,
    setStartDate: setStartDate,
    reportList: reportList,
    handleSave: getQ2Data,
    selectedDate: selectedDate,
    handleDate: handleDate,
    setDefaultDate: setDefaultDate,
    cashReport: selectedReport,
    handleDispatchReport: handleDispatchReport
  }
  const dateStartToShow = convertToDate(startDate)
  const dateEndToShow = convertToDate(endDate)
  const renderTable = useMemo(() => {
    if(!q2Data) return [];
    return q2Data.Data.filter(item => 
        item.Field1_Desc?.trim().toLocaleLowerCase()
            .includes(searchReport.toLowerCase()) ||
        item.Field2_Desc?.trim().toLocaleLowerCase()
            .includes(searchReport.toLowerCase()) 
    )
  }, [q2Data, searchReport])
  console.log(q2Data)
  
  return (
    <MarketMainLayout size='lg' title='q2'>
      {
        isOpenMainFilter &&
          <ColumModal 
            title='שאילתת תעודות כניסה'
            childrenProps={mainFilterProps}
            controlFunc={handleControlMainFilter}
            Children={MainFilter}
          />
      }
        <TitleLayout disableShadow>
          <TitleRow>
            <TitleRowItem 
              name={`${dateEndToShow} - ${dateStartToShow}`} 
              icon={<CalendatDay />}
            />
            
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={q2Data?.Summary.AmountPack.toLocaleString() || "0"} 
              icon={<PackageIcon />}
            />
            <TitleRowItem 
              name={q2Data?.Summary.MiddlePrice.toFixed(2) || "0.00"} 
              icon={<ShekelIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={q2Data?.Summary.AmountPallets.toLocaleString() || "0"} 
              icon={<PalletIcon />}
            />
            <TitleRowItem 
              name={q2Data?.Summary.Weight.toLocaleString() || "0.00"} 
              icon={<ScalesIcon />}
            />
          </TitleRow>
          <SideWrapper>
            <CircleActiveFilterBtn
              withAction={false}
              func={handleControlMainFilter}
            >
              <SettingMiksher />
            </CircleActiveFilterBtn>
          </SideWrapper>
        </TitleLayout>
      <ControlSearchPannelLayout>
        <SearchInput 
            input={searchReport}
            setInput={setSearchReport}
        />
      </ControlSearchPannelLayout>
      <Q4Layout isLoading={loading}>
        {
          q2Data &&
            <Q4Table
              metaData={q2Data?.MetaData}
              data={renderTable}
            />
        }

      </Q4Layout>
    </MarketMainLayout>
  )
}
const SideWrapper = styled.div`
  position: absolute;
  left: 2em;
  top: 1.5em;

`
export default MainQ2