import React from 'react'
import styled from 'styled-components'
import FilterAzmanotItem from './FilterAzmanotItem'

export const Wrapper = styled.div`
    /* background: red; */
    width: 11em;
    height: 4em;
    border-radius: 30px;
    position: relative;
    padding: 0.5em;
`
export const Select = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    outline: none;
    border: none;
    font-size: 1em;
    background: #eff3f4;
    padding: 0 1em;
    appearance: none;
    direction: rtl;
    cursor: pointer;
    color: #297f76;
`
const ValueIndicatorElement = styled.div`
    position: absolute;
    width:1em;
    height: 1em;
    background: tomato;
    top: 0.2em;
    right: 0.4em;
    border-radius: 50%;
`

export default function FilterAzmanotList({list, setId, label, id, desc, selectedValue}) {
    return (
        <Wrapper onChange={(e) => {setId(e.target.value)}}>
            {selectedValue ? 
                <ValueIndicatorElement />
                    :
                        ''      
            }
            <Select>
                <option value={''}>{label}</option>
                {list?.map(item => 
                    <FilterAzmanotItem 
                        key={item[id]} 
                        desc={item[desc]} 
                        id={item[id]}
                    />
                )}
            </Select>
        </Wrapper>
    )
}
