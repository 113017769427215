import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.3em 0.5em;
    :hover{
        background: #80e081;
    }

`

export default function ArizotSenderItem({ClientName, setWrapperSenderStatus, setInputSender, setClientIdSender, ClientID}){

    return(
        <Wrapper onClick={()=>{ setInputSender(ClientName.trim()); setWrapperSenderStatus(false); setClientIdSender(ClientID)}}>
            {`${ClientName.trim()} (${ClientID})` }
        </Wrapper>
    )
}