import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { LikutModals } from '../../../../../modules/Context/CefiNum'
import MoreThenNeed from './MoreThenNeed'
import SuccessModal from './SuccessModal'
import useReasonsLikut from '../useReasonsLikut'

export const MainWrapperReson = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.3);
    z-index: 99;
    top: 0;
    left: 0;
    display: ${props => props.activated ? 'block' : 'none'}
`
export const ResonWrapper= styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    margin: 0 auto;
    padding: 1em 1em 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media screen and (min-width: 400px) {
        width: 400px;
    }
`

export default function ModalContainer() {
   const [resonState, setResonState] = useContext(LikutModals);
    const likutResons = useReasonsLikut()
    /* const likutResons = useSelector(item => item.likut?.components) */
    // console.log(likutResons)
    const components = {
        notExistInAzmana: {
            component: <MoreThenNeed resons={likutResons[resonState?.resonTag]}  />

        },
        moreThenNeed: {
            component: <MoreThenNeed resons={likutResons[resonState?.resonTag]}  />

        },
        lessThenNeed: {
            component: <MoreThenNeed resons={likutResons[resonState?.resonTag]}  />

        },
        success: {
            component: <SuccessModal title={resonState?.props?.title} text={resonState?.props?.text} mood={resonState.props?.mood} />
        }
    }
    // console.log(resonState)
    return (
        <MainWrapperReson activated={resonState?.active}>
            <ResonWrapper>
                {components[resonState?.resonTag]?.component}
            </ResonWrapper>   
        </MainWrapperReson>
    )
}
