import React, {useState} from 'react'
import { IOrderItem } from '../../../../../modules/API/Drivers/models/kavData'
import styled from 'styled-components';
import { TitleItem, TitleRow } from './ColumClientElem';
import { AcceptIcon, EditIcon, InvoiceIcon, PackageIcon, PalletIcon, ScalesIcon } from '../../../../../generic/icons/Icons';
import ColumnPalletItem from './ColumnPalletItem';
import { SelectWorkerButton } from '../../../../../generic/cards/OrderGethering/OrderGetheringItemWithDetailes';

interface IProps extends IOrderItem  {
    item: IOrderItem;
    ordersAllowToShow: number[];
    isRehesh?: boolean;
    handleSelectToDrag: (elementID: number, kavID: number, CustomerID: number, OrderID: number, isOrder?: boolean ) => void;
    handleClickSelectOrder: (item: IOrderItem, area: number, CustomerID: number) => void;
    area: number;
    CustomerID: number

}

const ColumnOrderElement = ({
    item, OrderID, ordersAllowToShow, PalletsList, Weight, isRehesh,
    Pallets, Packs, handleSelectToDrag, area, CustomerID, Selected,
    handleClickSelectOrder
}: IProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const calcWeightPallet = () => {
        let weightCalc, palletCalc, packagesCalc
        if(PalletsList.length === 0) {
            weightCalc = Math.round(Weight);
            palletCalc = +Pallets.toFixed(2);
            packagesCalc = Packs
        } else {
            const reduced = PalletsList.reduce((acc: Record<string, number>, cur) => {
                acc.weight = (acc.weight ? acc.weight : 0 ) + (cur.Gross || 0); 
                acc.pallets = (acc.pallets ? acc.pallets : 0 ) + (cur.PallQty || 0); 
                acc.packs = (acc.packs ? acc.packs : 0 ) + (cur.Qty || 0); 
                return acc    
            }, {})
            weightCalc = reduced.weight
            palletCalc = reduced.pallets
            packagesCalc = reduced.packs
        }
        return {weightCalc: Math.round(weightCalc), palletCalc, packagesCalc}
    } 

    const {weightCalc, palletCalc, packagesCalc} = calcWeightPallet()

    const handleOpenPallets = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    }
    const handleSelect = ((e: React.MouseEvent) => {
        e.stopPropagation();
        handleSelectToDrag(OrderID, area, CustomerID, OrderID, true)

    })
    const handleSelectOrderForRemark = (e: React.MouseEvent) => {
        e.stopPropagation()
        handleClickSelectOrder(item, area, CustomerID)
    }
  return (
    <MainWrapper 
        onClick={handleOpenPallets}
        // Selected={Selected}
    >   
        {
            !isRehesh &&
            <CheckBox
                onClick={handleSelect}
            >
                {
                    Selected &&
                    <AcceptIcon />

                }
            </CheckBox>
        }
        <TitleRow>
            <TitleItem>
                <InvoiceIcon />
                {OrderID}
                <SelectWorkerButton 
                    onClick={handleSelectOrderForRemark}
                >
                    <EditIcon />
                </SelectWorkerButton>
            </TitleItem>
        </TitleRow>
        <TitleRow>
            <TitleItem>
                <PalletIcon />
                {palletCalc}
            </TitleItem>
            <TitleItem>
                <ScalesIcon />
                {weightCalc}
            </TitleItem>
            <TitleItem>
                <PackageIcon />
                {packagesCalc}
            </TitleItem>
        </TitleRow>
            {
                isOpen && PalletsList.length > 0 &&
                    <BodyWrapper>
                        {
                            PalletsList.map((item, index) =>
                                <ColumnPalletItem 
                                    key={item.Pallet_Num}
                                    handleSelectToDrag={handleSelectToDrag}
                                    {...item}
                                    area={area}
                                    OrderID={OrderID}
                                    CustomerID={CustomerID}
                                />
                            )
                        }
                    </BodyWrapper>
            }
        
    </MainWrapper>
  )
}

const MainWrapper = styled.div<{Selected?: boolean}>`
    position: relative;
    width: 100%;
    background: #e9f3f2;
    background: ${props => props.Selected ? '#80e081' : '#e9f3f2'};

    border-radius: 10px;
    display: flex;
    flex-flow: column;
    padding: 0.5em 0.2em;
    gap: 0.3em;
`
const CheckBox = styled.div`
    position: absolute;
    left: 0.5em;
    padding: 0.3em;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    border: 2px solid #80e081;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #80e081;

`
const BodyWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    /* border-top: 1px solid gray; */
    border-radius: 20px;
    background: #fff;
    padding: 0.5em 0.2em;

`
export default ColumnOrderElement