import React from 'react'
import { ISupplyDataInnerProduct } from '../../../../../../modules/API/Forcasts/models/seller'
import styled from 'styled-components'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon } from '../../../../../../generic/icons/Icons'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'

type Props = {
  product: ISupplyDataInnerProduct
  children: React.ReactNode | React.ReactNode[]
}

const ProductLayout = ({
  product,
  children
}: Props) => {
  return (
    <MainWrapper>
      <SideWrapper>
        <ProductImage 
          id={product.GeneralProductID}
        />
      </SideWrapper>
      <TitleRow>
        <TitleRowItem 
          name={`${product.ProductDesc.trim()} ${product.VarietyDesc.trim()} ${product.SizeID.trim()}`}
          icon={<AppleIcon />}
        />
      </TitleRow>
      {children}
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.3em;
  border-radius: 10px;
`
const SideWrapper = styled.div`
  position: absolute;
  top: calc(50% - 1.6em);
  left: 0.3em;
  width: 3.5em;
  height: 3.5em;
`
export default ProductLayout