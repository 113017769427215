import React from 'react'
import styled from 'styled-components'
import Image from '../../../../../../generic/assets/collect.png'

type Props = {
  remark: string
}

const RemarkProductModal = ({remark}: Props) => {
  return (
    <MainWrapper>
        {/* {data.body} */}
        <Img
            src={Image}
            alt='order gathering'
        />
        <TextField 
            cols={30}
            rows={11}
            readOnly
            defaultValue={remark}
            // onChange={remarkHandle}
        />
         
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

`
const Img = styled.img`
    min-width: 300px;
`

const TextField = styled.textarea`
    padding: 1em 0.5em;
    letter-spacing: 0.5px;
    border-radius: 30px;
    font-size: 1.2em;
    direction: rtl;
    border: 2px solid #7ab0ab;
    outline: none;
    color: #757575;
    resize: none;
    ::-webkit-scrollbar {
        width: 0
    }
    :disabled {
        background: #ffff;
    }
`
export default RemarkProductModal