import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[] 
}

const BodyScrollContainer = ({children}: Props) => {
  return (
    <MainWrapper>
      <Wrapper>
        <Inner>

      {children}
        </Inner>
      </Wrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  z-index: 0;
  display: flex;
  width: 100%;
  height: calc(100% - 5em);
  background: #E9F0F0;
  gap: 1em;
  border-radius: 30px;
  -webkit-box-shadow: 0px 1px 2px 2px rgba(41,127,118, 0.1);
  box-shadow: 0px 1px 2px 2px rgba(41,127,118, 0.1);
  padding: 0 0.2em;

`
const Wrapper = styled.div`
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  direction: rtl;
  
  border-radius: 30px;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const Inner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 1em;
  padding: 1em 0.5em;
  justify-content: flex-start;
  /* padding: 1em; */

  /* flex-direction: row-reverse; */
  /* direction: rtl; */
`;
export default BodyScrollContainer