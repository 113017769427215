import React, {useState, useMemo} from 'react'
import { IClientsItem } from '../../../../../../modules/API/Defaults/models/customers';
import styled from 'styled-components';
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList';
import { IPackagesItem } from '../../../../../../modules/API/Defaults/models/packages';
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle';
import DatePickerModule from '../../../../../../generic/datePicker/datePicker';
import ControlButton from '../../../../../../generic/buttons/ControlButton';
import { IClientItem } from '../../../../../../modules/API/Defaults/models/clients';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers/rootReducer';
import { SELECT_DATE_FOR_PACKAGES } from '../../../../../../../store/actions/actionsTypes';
import { IPalletTypeItem } from '../../../../../../modules/API/Defaults/models/pallets';

type Props = {
  customerList: IClientItem[];
  handleSelectClient: (e: IClientItem | null) => void;
  handleSelectPackage: (e: IPackagesItem | null) => void;
  packageList: IPackagesItem[];
  startDate: string;
  setStartDate: (e: string) => void;
  endDate: string;
  setEndDate: (e: string) => void;
  fetchFunc: () => void;
  selectedPackage: IPackagesItem | null;
  selectedClient: IClientItem | null;
  defClient?: IClientItem | null;
  selectedPallet: IPalletTypeItem | null;
  handleSelectPallet: (e: IPalletTypeItem | null) => void;
  palletList: IPalletTypeItem[] | null;
}

const FilterPackageMovments = ({
  customerList, handleSelectClient, handleSelectPackage, packageList,
  startDate, setStartDate, endDate, setEndDate, fetchFunc,
  selectedPackage, selectedClient, defClient, selectedPallet,
  handleSelectPallet, palletList
}: Props) => {
  const dispatch = useDispatch()
  const defaultClient = defClient || selectedClient ? defClient || selectedClient : null;
  const [searchClient, setSearchClient] = useState<string>(defaultClient?.ClientName?.trim() ||"");
  const [isOpenClientList, setIsOpenClientList] = useState<boolean>(false);

  const [searchPackage, setSearchPackage] = useState<string>(selectedPackage?.Pack_Name?.trim() || "");
  const [isOpenPackageList, setIsOpenPackageList] = useState<boolean>(false);
  
  const [searchPallet, setSearchPallet] = useState<string>(selectedPallet?.Pallete_Desc?.trim() || "");
  const [isOpenPalletList, setIsOpenPalletList] = useState<boolean>(false);

  const handleSearchPallet = (e: string) => {
    setIsOpenPalletList(true)
    setSearchPallet(e)
    if(!e) {
      handleSelectPallet(null)
    }
  }
  const handleSelectPalletItem = (e: IPalletTypeItem) => {
    handleSelectPallet(e)
    setIsOpenPalletList(false)
    setSearchPallet((e.Pallete_Desc || "").trim())
  }

  const setDefaultDate = useSelector((state: RootState) => state.packageReducer.date)
  const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
  const handleDate = (e: object | object[]) => {
          console.log(e)
              dispatch({type: SELECT_DATE_FOR_PACKAGES, payload: e}
          )
        } 
       
 
  const handleSearchClient = (e: string) => {
    setIsOpenClientList(true)
    setSearchClient(e)
    if(!e) {
      handleSelectClient(null)
    }

  }
  const handleSelectClientItem = (e: IClientItem) => {
    handleSelectClient(e)
    setIsOpenClientList(false)
    setSearchClient((e.ClientName || "").trim())
    
  }

  const handleSelectPackageItem = (e: IPackagesItem) => {
    handleSelectPackage(e)
    setIsOpenPackageList(false)
    setSearchPackage((e.Pack_Name || "").trim())
  }

  const handleSearchPackage = (e: string) => {
    setIsOpenPackageList(true)
    setSearchPackage(e)
    if(!e) {
      handleSelectPackage(null)
    }
  }
  const handleFetch = () => {
    fetchFunc()
  }
  console.log(customerList)
  const renderClients = useMemo(() => {
    if(!customerList) return [];
    return customerList.filter((item: IClientItem) => {
      return item.ClientName?.trim()
        .toLowerCase().includes(searchClient.toLowerCase())
    })
  }, [customerList, searchClient])

  const renderPackages = useMemo(() => {
    if(!packageList) return [];
    return packageList.filter((item: IPackagesItem) => {
      return item.Pack_Name?.trim()
        .toLowerCase().includes(searchPackage.toLowerCase())
    })
  }, [packageList, searchPackage])
  const renderPallets = useMemo(() => {
    if(!palletList) return [];
    return palletList.filter((item: IPalletTypeItem) => {
      return item.Pallete_Desc?.trim()
        .toLowerCase().includes(searchPallet.toLowerCase())
    })
  }, [palletList, searchPallet])
  return (
    <MainWrapper>
      <ContentWrapper>
        <SearchBlockWithTitle title='תאריך'>
          <DatePickerModule 
            multyDate
            ignorBrod={true}
            setSelectedDateStart={setStartDate}
            setSelectedDateEnd={setEndDate}
            selected={setDefaultDate ? selectedDate : false}
            defEndDate={false}
            isDefault={false}
            disabled={false}
            isNeedWithoutFormated={true}
            cashFunc={handleDate}
            large
          
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle title='בחר לקוח'>
          <SearchFromTheList 
            placeHolder={'חיפוש לקוח'}
            input={searchClient}
            searchFunc={handleSearchClient}
            selectFunc={handleSelectClientItem}
            isNeedFullObject
            itemDisplayName='ClientName'
            nameIdElement='ClientID'
            isOpenList={isOpenClientList}
            list={renderClients}
            size={'full'}
            disabled={false}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle title='בחר אריזה'>
          <SearchFromTheList 
            placeHolder={'חיפוש אריזה'}
            input={searchPackage}
            searchFunc={handleSearchPackage}
            selectFunc={handleSelectPackageItem}
            isNeedFullObject
            itemDisplayName='Pack_Name'
            nameIdElement='PackID'
            isOpenList={isOpenPackageList}
            list={renderPackages}
            size={'full'}
            // isFocusSelect={false}
            // disabled={!!defaultClient}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle title='בחר משטח'>
          <SearchFromTheList 
            placeHolder={'חיפוש משטח'}
            input={searchPallet}
            searchFunc={handleSearchPallet}
            selectFunc={handleSelectPalletItem}
            isNeedFullObject
            itemDisplayName='Pallete_Desc'
            nameIdElement='Pallete_Type'
            isOpenList={isOpenPalletList}
            list={renderPallets}
            size={'full'}
            // disabled={!!defaultClient}
          />
        </SearchBlockWithTitle>

      </ContentWrapper>
      {

      
        selectedClient &&
        <ControlButton 
          lable={'הצג'}
          handleClick={handleFetch}
        />
      }
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;

`
export default FilterPackageMovments