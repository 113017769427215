import React, {useState, useEffect, useMemo, useContext} from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { LOAD, LOAD_END } from '../../../../../../../store/actions/actionsTypes'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
import SearchInput from '../../../../../../generic/inputs/inputSearch'
import OrderlikeLayout from '../../../../../../generic/Layouts/OrderlikeLayout'
import BodyScrollContainer from '../../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import ControlSearchPannelLayout from '../../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import TitlesList from '../../../../../../generic/titlesRowItem/TitlesList'
import { addIDForTheList } from '../../../../../../generic/utils/addUIDForTheList'
import { IMlaiTitlesItem, IPackTypeItem, ISfirotEditLine } from '../../../../../../modules/API/Packages/models/mlaiTypes'
import { IEditSfiraResponse } from '../../../../../../modules/API/Packages/models/responses'
import { Packages } from '../../../../../../modules/API/Packages/packages'
import { ContextModal } from '../../../../../../modules/modal/modalContext'
import { LINKS } from '../../../../../../Routs/config'
import { ControlPanelWrapper } from '../../../secondbar/modalAzmanot/modalAzmanot'
import ArizotContentList from '../create/ArizotContentList'

type Props = {

}

interface IUrl {
  id: string
} 
const ArizotEditMain = (props: Props) => {
  const {id} = useParams<IUrl>();
  const dispatch = useDispatch();
  const history = useHistory()
  const [modal, setModal] = useContext(ContextModal)

  const [lines, setLines] = useState<IPackTypeItem[]>()
  const [title, setTitle] = useState<IMlaiTitlesItem>()
  const [input, setInput] = useState<string>('')
  const [isEditActive, setIsEditActive] = useState<boolean>(false);

  const renderList = useMemo(() => {
    if (!lines) return
    const filtered = lines.filter(item => 
      item.Description.trim().toLowerCase().includes(input.toLowerCase())  
    )
    return filtered
  }, [lines, input])
  const getLines = async () => {
    dispatch({type: LOAD})
    try {
      let res = await Packages.getPackageLinesEdit(id);
      const {Lines, ...rest} = res
      setLines(addIDForTheList(Lines))
      setTitle(rest)
    } catch (err) {
      
    } finally {
      dispatch({type: LOAD_END})
    }
  }
  
  const editSfira = async () => {
    if(!lines) return
    dispatch({type: LOAD})
    const editedLine = lines?.map(e => {
            let {id, CalculatedQty, Season, PC_Num, ...rest} = e
            return {...rest}
        });
    const data = {
      Packs: editedLine
    }
    try {
      let res = await Packages.editSfira(+id, data)
      setModal({
        open: true,
        value: 'הספירה התנהלה בהצלחה',
        mood: true
    });
    setTimeout(() => {
        setModal({
            open: false,
            value: '2'
        });
    }, 3000);
      history.push(LINKS.arizot.arizaMlai.mainUrl)
    } catch (err) {
      setModal({
        open: true,
        value: 'משהו השתבש',
        mood: false
      });
      setTimeout(() => {
          setModal({
              open: false,
              value: '2'
          });
      }, 3000);
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const patchState = (qty: number, id: string) => {
    if (!lines) return
    const newState = [...lines]
    const found = newState.find(e => e.id === id);
    if (!found) return
    setIsEditActive(true)
    const indexOfItem = newState.indexOf(found);
    newState[indexOfItem].Qty = qty;

    setLines(newState)

  }

  console.log(lines)
  useEffect(() => {
    getLines()
  }, [id])

  const PItem = {
    updateInput: patchState
  }
  return (
    <OrderlikeLayout label={`ספירה - ${id}`}>
      <ControlSearchPannelLayout>
        <SearchInput 
          input={input}
          setInput={setInput}
        />
      </ControlSearchPannelLayout>
      <BodyScrollContainer>
        <ArizotContentList
          disableInput={title?.Status !== 0}
          props={PItem}
          list={renderList}
        />
      </BodyScrollContainer>
      <ControlPanelWrapper>
            <Link style={{width: '100%'}} to={LINKS.arizot.arizaMlai.mainUrl}>
                <ControlButton lable={'חזרה'} />
            </Link>
            {
                isEditActive && title?.Status === 0 &&
                    <ControlButton lable={'שמור'} handleClick={editSfira} />
            }
        </ControlPanelWrapper>
    </OrderlikeLayout>
  )
}

export default ArizotEditMain