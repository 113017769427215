import { requests } from "../config";
import { ILogStatusDriver, IPutData } from "./models/methodsData";
import { IPackageLogData } from "./models/packageLogData";

export const Drivers = {
    getDriverOrders: (date: string) => 
        requests.get(`driver/orders_tree?date=${date}`, 'go'),
    changeStatusDelivery: (data: IPutData[]) => requests.put('/driver/delivery_status', 'go', data),    
    logStatusDelivery: (data: ILogStatusDriver[]) => requests.post('driver/actions_log', 'go', data),
    logPdf: (date: string) => requests.get(`driver/actions_log/pdf?Date=${date}`, 'go'),
    getPackageLogByKav: (kav: number, date: string): Promise<IPackageLogData[]> => requests.get(`driver/packages?TransferZone=${kav}&Date=${date}`, 'go')
}
