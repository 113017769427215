import React, { useEffect, useState } from 'react'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import styled from 'styled-components'
const MainWrapper = styled.div`
    min-height: 6em;
    padding: 0.5em 0;
    /* background: red; */
    display: flex;
    flex-flow: column;
    align-items: flex-end;
`
const Title = styled.div`
/* background: yellow; */
font-size: 1.1em;
color: #8a8aad;
padding: 0.2em 1em;
`

export default function CheckArizotItem({ desc, qty, func }) {
    const [input, setInput] = useState(null);
    // console.log(func)
    useEffect(() => {
        func(desc?.trim(), +input)
    }, [input])
    
    return (
        <MainWrapper >
            <Title>
                {desc}
            </Title>
            <SearchInput
                iconHide={true}
                value={'כמות אריזות'}
                input={input}
                setInput={setInput}
                type={'number'} 

            />
        </MainWrapper>

    )
}
