import React from 'react'
import { IMarketCustomerWithDetails } from '../../../../../../../modules/API/Market/models/marketRequests'
import ClientDataItem from './ClientDataItem';

type Props = {
    list: IMarketCustomerWithDetails[];
    additionalProps?: any
}

const BodyDataList = ({ list, additionalProps }: Props) => {
    return (
        <>
            {
                list.map((item, index) =>
                    <ClientDataItem
                        key={item.CustomerSeller}
                        {...item}
                        {...additionalProps}
                        item={item}
                    />
                )
            }
        </>
    )
}

export default BodyDataList