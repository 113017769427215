import React, {useState, useMemo} from 'react'
import MarketMainLayout from '../../market/Collect/Layout/MarketMainLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { IReportQ10GETData, IReportQ10Response } from '../../../../../modules/API/Reports/models/q10'
import { Reports } from '../../../../../modules/API/Reports/reports'
import { useDispatch } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import ModalQ10 from './Components/Modals/ModalQ10'
import { FilterIcon } from '../../../../../generic/icons/Icons'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IReportListItem } from '../../../../../modules/API/Reports/models/reportList'
import HeadQ10 from './Components/HeadQ10'
import Table from '../Q8/Layouts/Table/Table'
import BodyQ10 from './Components/BodyQ10'

type Props = {}

const MainQ10 = (props: Props) => {
  const dispatch = useDispatch()
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true)
  const [dataReport, setDataReport] = useState<IReportQ10Response | null>(null)
  
  const {
    data: reportList,
    isLoading: reportListIsLoading
  } = useDataFetcher<IReportListItem[]>(
    Reports.getReportList, "10",
  )

  const getQ10Report = async (
    date1: string,
    date2: string,
    repID: number,
    orderType: number,
    lineType: number,
    status: number
  ) => {
    dispatch({type: LOAD})
    const data: IReportQ10GETData = {
      Date1: date1,
      Date2: date2,
      Status: status,
      RepID: repID,
      OrderType: orderType,
      LineType: lineType
    }
    try {
      const res = await Reports.getReportQ10(data)
      setDataReport(res)

      } catch (error) {
        console.log(error)
        } finally {
          dispatch({type: LOAD_END})
          
        }
  }

  const handleControlFilter = () => {
    setIsOpenFilter(!isOpenFilter)
  }
  const propsFilter = {
    saveFunc: getQ10Report,
    reportList: reportList || [],
  }
  return (
    <MarketMainLayout
      title="סיכום הזמנות Q10"
    >
      {
        isOpenFilter && (
          <ColumModal
            title='סנן לפי'
            controlFunc={handleControlFilter}
            Children={ModalQ10}
            childrenProps={propsFilter}
          />
        )
      }
      <ControlSearchPannelLayout>
        <CircleActiveFilterBtn
            withAction={false}
            func={handleControlFilter}
          >
            <FilterIcon />
        </CircleActiveFilterBtn>
        <SearchInput />  
      </ControlSearchPannelLayout> 
      {
        dataReport &&
          <Table>
            <HeadQ10 
              data={dataReport.MetaData}
            />
            <BodyQ10 
              head={dataReport.MetaData}
              data={dataReport.Data}
            />
          </Table>
      }
      
    </MarketMainLayout>
  )
}

export default MainQ10