import React, { useState } from 'react'
import styled from 'styled-components'
import { returnResonBynum, useResonDescByNum } from '../../../../../../generic/artcoded/resonslikut'
import { AppleIcon } from '../../../../../../generic/icons/Icons'

const MainWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, 0.5);
    z-index: 2;
    display: ${props => props.open ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    animation: ${props => props.closed ? 'close' : 'open' } .5s ease;
    -webkit-animation: ${props => props.closed ? 'close' : 'open' } .5s ease;
    @keyframes open {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes close {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
`
const InnerWrapper = styled.div`
    display: flex;
    background: #eff3f4;
    width: 300px;
    height: 400px;
    flex-flow: column;
    gap: 1em;
    justify-content: flex-start;
    align-items: center;
    padding: 1em 0.5em;
    border-radius: 30px;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
    /* transform: scale(0); */
    

`
const Title = styled.div`
    width: 80%;
    height: 3em;
    background: #fff;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: tomato;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
    `
const Body = styled.div`
    width: 80%;
    height: 12em;
    background: #fff;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 1em;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
`
const Item = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0.5em 1em 1em;
    border-bottom: 2px solid #80b3ae;
    background: #fff;
    /* border-radius: 30px; */
    color: #80b3ae;
`
const Element = styled.div`
    display: flex;
    gap: 0.3em;
`

export default function DetailesModal({open, data, close, title}) {
    const [isAnimated, setIsAnimated] = useState(false);
    const reason = useResonDescByNum(title);
    const closeFunc = () => {
        setIsAnimated(true);
        setTimeout(() => {
            setIsAnimated(false)
            close();
        }, 400)
    }
  return (
    <MainWrapper 
        open={open}
        onClick={closeFunc}
        closed={isAnimated}
    >
    <InnerWrapper>

    <Title >
        {reason}
    </Title>
    <Body>
        {data?.map((item, index) =>
            <Item key={index} >
                <Element>
                    {item.Qty}
                </Element>
                <Element>
                    {item?.Product?.trim()}
                    <AppleIcon />
                </Element>
            </Item>
        )}
    </Body>
    </InnerWrapper>
    </MainWrapper>
  )
}
