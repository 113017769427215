import { IOrderRehesh } from "../../../../../../modules/API/AzmanotRehesh/models/order";
import { IAddProductItem } from "../types/addProductItem";

export const convertProductListToOrderRehesh = (
  productList: IAddProductItem,
  date: string, 
  warehouseID: number,
  customerID: number
  ): IOrderRehesh => {

  return {
    GeneralProductID: productList.GeneralProductID,
    GrowerID: 0,
    GrowerName: '',
    OrderID: 0,
    OrderPacks: productList.orderedPackages,
    OrderWeight: productList.orderedWeight,
    Order_Pallets: productList.orderedPallets,
    PackID: 0,
    Pack_Name: '',
    ProductDesc: productList.ProductDesc,
    ProductID: productList.ProductID,
    RecID: 0,
    SizeID: '',
    SupplyQty: 0,
    SupplyWeight: 0,
    Supply_Pallets: 0,
    VarietyDesc: productList.VarietyDesc,
    VarietyID: productList.VarietyID,
    Status: 0,
    Remarks: '',
    Delivery_Date: date,
    WarehouseID: warehouseID,
    LineType: 1,
    Sent_SMS: false,
    CustomerID: customerID,
    DetailsByGrowers: productList.forecastList.filter((item) => item.ActualAmnt > 0 || item.ActualPallets > 0 || item.ActualWeight > 0).map((item) => {
      return {
        GeneralProductID: productList.GeneralProductID,
        GrowerID: item.GrowerID,
        GrowerName: '',
        OrderID: 0,
        OrderPacks: item.ActualAmnt,
        OrderWeight: item.ActualWeight,
        Order_Pallets: item.ActualPallets,
        PackID: 0,
        Pack_Name: '',
        ProductDesc: productList.ProductDesc,
        ProductID: productList.ProductID,
        RecID: 0,
        SizeID: '',
        SupplyQty: 0,
        SupplyWeight: 0,
        Supply_Pallets: 0,
        VarietyDesc: productList.VarietyDesc,
        VarietyID: productList.VarietyID,
        Status: 1,
        Remarks: '',
        Delivery_Date: date,
        WarehouseID: warehouseID,
        LineType: 2,
        Sent_SMS: false,
        CustomerID: customerID
      }
    })
  }
}