import React, { useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import selectOnFocus from '../../../../../../../modules/autoselect';

type Props = {
    handleChange: (e: string) => void;
}

const PhoneInputWithMask = ({
    handleChange
}: Props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isAlert, setIsAlert] = useState(false);

    const formatPhoneNumber = useCallback((input: string) => {
        const cleanedInput = input.replace(/[^0-9]/g, '');
        const firstPart = cleanedInput.slice(0, 3);
        const secondPart = cleanedInput.slice(3, 6);
        const thirdPart = cleanedInput.slice(6, 10);

        if (cleanedInput.length <= 3) {
            return firstPart;
        } else if (cleanedInput.length <= 6) {
            return `${firstPart} ${secondPart}`;
        } else {
            return `${firstPart} ${secondPart} ${thirdPart}`;
        }
    }, []);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.replace(/[^0-9-]/g, '');
        const result = formatPhoneNumber(input);
        handleChange(result);
        setPhoneNumber(result);
    }, [formatPhoneNumber, handleChange]);

    return (
        <Input
            alert={isAlert}
            type="text"
            value={phoneNumber}
            onChange={handleInputChange}
            // onBlur={handleBlur}
            placeholder="### ### ####"
            onFocus={selectOnFocus}
        />
    )
}
const Input = styled.input<{ alert?: boolean }>`
  width: 100%;
  height: 3em;
  border-radius: 30px;
  background: #EFF3F4;
  border: ${props => props.alert ? '2px solid #FF0000' : 'none'};
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1.2em;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
    font-size: 0.9em;
  }
`
export default PhoneInputWithMask