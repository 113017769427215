import { GET_GOODS_LIST } from '../../../../../../modules/API/settings'
import styled from "styled-components";

export const WrapperClients = styled.div`
    /* background: red; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.2em 0.5em;
    transition: 0.3s;
    color: #868787;
    &:hover{
        background: #80e081;
    }
`

export default function SearchItem({ 
    setInput, setCustomerId, 
    setProduct, Customer_Name, data,
    CustomerID, getGoodsList,
    setGeneralRemark, setWeek, setHandleTime,
    To_PickupTime, Monday,
    Tuesday, Wednesday, Thursday, 
    Friday, Saturday, Sunday, 
}) {
    // console.log(data)
  
    return (
        <WrapperClients
            tabIndex="0"
            onClick={() => {
                getGoodsList(GET_GOODS_LIST, CustomerID, data); 
                setInput(Customer_Name.trim()); 
                setCustomerId(CustomerID); 
                setGeneralRemark(''); 
                setProduct([])
                setHandleTime(To_PickupTime); 
                setWeek({
                    "Monday": Monday,
                    "Tuesday": Tuesday,
                    "Wednesday": Wednesday,
                    "Thursday": Thursday,
                    "Friday": Friday,
                    "Saturday": Saturday,
                    "Sunday": Sunday
                })
            }}
        >
            <div
                tabIndex="1"
                onKeyPress={(e) => { if (e.key === 'Enter') { getGoodsList(GET_GOODS_LIST, CustomerID, data) } }}
                onFocus={(e) => { setInput(e.target.value) }}
                id="comanyListItems"


            >{Customer_Name}</div>
        </WrapperClients>
    );
}