import React, {useState, useMemo} from 'react'
import { IProductListItemNode } from '../../../../../../modules/API/Defaults/models/productListNode'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultProductID: number
  setDefaultProductID: React.Dispatch<React.SetStateAction<number>>
  required?: boolean
  list: IProductListItemNode[]
}

const SearchProduct = ({
  defaultProductID,
  setDefaultProductID,
  list,
  required
}: Props) => {
  const defaultProduct = list?.filter(item => item.ProductID === defaultProductID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<IProductListItemNode | null>(defaultProduct || null)

  const [search, setSearch] = useState<string>(defaultProduct?.ProductDesc.trim() || '')

  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedProduct(null)
      setDefaultProductID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }

  const handleSelect = (item: IProductListItemNode) => {
    setDefaultProductID(item.ProductID)
    setSearch(item.ProductDesc.trim())
    setSelectedProduct(item)
    setIsOpenDropDown(false)
  }

  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => item.ProductDesc.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  }, [list, search])
  return (
    <SearchFromTheList 
      input={search}
      list={render}
      isOpenList={isOpenDropDown}
      placeHolder={'חפש פריט'}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      itemDisplayName='ProductDesc'
      nameIdElement='ProductID'
      required={required}
      size={'full'}

    />
  )
}

export default SearchProduct