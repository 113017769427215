import { requests } from "../config";
import { IPutPackageOrderData } from "./models/arizotLine";
import { IPackageOrderData, IPackageTeudaData } from "./models/createPackageOrderModel";
import { IPostDataArizot, IPutDataArizot } from "./models/mlaiTypes";


export const Packages = {
    getExistedOrder: (date: string, senderID: number, reference: number | string) => 
        requests.get(`package/order_count?ClientID_Sender=${senderID}&Reference=${reference}&PO_Date=${date}`, 'go'),
    createPackageOrder: (data: IPackageOrderData) => requests.post(`package/order`, 'go', data),
    getArizotLines: (id: string) => requests.get(`package/order/lines?PD_ID=${id}`, 'go'),
    getArizotLinesWithTitle: (id: string) => requests.get(`package/order/${id}`, 'go'),
    putPackageLines: (id: string | number, data: IPutPackageOrderData) => requests.put(`package/order/${id}`, 'go', data),
    getUsedTypes: (reciverID?: number) => requests.get(`/package/used_types${reciverID ? `?RecieverID=${reciverID}` : ''}`, 'go'),
    createSfira: (data: IPostDataArizot) => requests.post('package/count', 'go', data),
    editSfira: (pcNum: number, data: IPutDataArizot) => requests.put(`package/count/${pcNum}`, 'go', data),
    getSfirotList: (reciverID: number, date: string) => requests.get(
        `/package/count?RecieverID=${reciverID}&Date=${date}`, 'go'
    ),
    getPackageLinesEdit: (id: string | number) => requests.get(`package/count/${id}`, 'go'),
    deleteSfira: (id: string | number) => requests.delete(`package/count/${id}`, 'go'),
    printPackageOrder: (id: string | number) => requests.get(`package/order/${id}/pdf`, 'go'),
    getPackPalletList: (data?: string) => requests.get('order/pack', 'node'),
    createTeuda: (data: IPackageTeudaData) => requests.post(`package/delivery`, 'go', data),
    sendEmail: (teudaNum: string | number) => requests.get(`/package/delivery/${teudaNum}/send_email`, 'go'),
    // DateFrom=1&DateTo=1&ClientID=2&PackIDs=1,2,3 - query string
    packageMovements: (data: string) => requests.get(`package/movement?${data}`, 'go'),
}