import React, {useState, useCallback, useMemo, useEffect} from 'react'
import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../generic/inputs/inputSearch'
import DatePickerModule from '../../../../generic/datePicker/datePicker'
import useDataFetcher from '../knisot/hooks/useFetchData'
import { Reciept } from '../../../../modules/API/Reciept/reciept'
import { IRecieptTitleIltem } from '../../../../modules/API/Reciept/models/receipt'
import BodyScrollContainer from '../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../generic/titlesRowItem/TitlesList'
import ReceiptTitleLine from './Components/ReceiptTitleLine'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import ReceiptEdit from './Components/Edit/ReceiptEdit'
import { LINKS } from '../../../../Routs/config'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../store/reducers/rootReducer'
import { CLEAR_RECEIPTS_STATE, SELECT_DATA_FOR_KABALOT } from '../../../../../store/actions/actionsTypes'
import ReceiptCreate from './Components/Create/ReceiptCreate'
import ControlButton from '../../../../generic/buttons/ControlButton'
import selectBrodMigdal from '../../../../generic/utils/selectBrodMigdal'
import { IReceiptChangeStatus } from '../../../../modules/API/Reciept/models/requests'

type Props = {}

const ReceiptMain = (props: Props) => {
  let { path } = useRouteMatch();
  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector((state: RootState) => state.mainReducer.token)

  const defaults = useSelector((state: RootState) => state.defaults.defaults)
  const clientCash: number = selectBrodMigdal(defaults, 611, "Num_Value")
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [search, setSearch] = useState('')
  const setDefaultDate = useSelector((state: RootState) => state.kabalot.date)

const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
  
  const handleDate = (e: object | object[]) => {
      console.log(e)
          dispatch({type: SELECT_DATA_FOR_KABALOT, payload: e}
      )
    } 
  const {
    data: receiptData,
    setData: setReceiptData
  } = useDataFetcher<IRecieptTitleIltem[]>(
    Reciept.getReciepts, `DateFrom=${startDate}&DateTo=${endDate}`
  )
  const handlePushData = (item: IRecieptTitleIltem) => {
    setReceiptData([ item, ...receiptData || []])
  }
  const printPDF = async (receiptID: number) => {
    try {
      let response = await fetch(
        `https://my.agroline.co.il/v2/receipt/${receiptID}/pdf?Original=0`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.blob();
      // Receive PDF Steam as as Blob
      const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
      const fileURL = URL.createObjectURL(file); //Build a URL from the file
      window.open(fileURL); //Open the URL on new Window
    } catch (error) {
      console.log(error)
    }
  }
  const handleDeleteLine = async (id: number) => {
    try {
      const putData: IReceiptChangeStatus[] = [{
        ReceiptNum: id,
        Canceled: true
      }]
      let res = await Reciept.changeStatus(putData)
      if (!receiptData) return
      setReceiptData(receiptData.map((item) => {
        if (item.ReceiptNum === id) {
          item.Canceled = true
        }
        return item
      
      }).sort((a, b) => Number(a.Canceled) - Number(b.Canceled)))
    } catch (err) {

    }
  }
  const handleSelectForEdit = useCallback((id: number) => {
    history.push(`${LINKS.bills.receipt.editByID}${id}`)
  }, [])
  const handleCreateNew = () => {
    history.push(`${LINKS.bills.receipt.create}`)
  }

  const itemProps = {
    handleSelectForEdit: handleSelectForEdit,
    clientCash: clientCash,
    printPDF: printPDF,
    handleDelete: handleDeleteLine,
  }

  const render = useMemo(() => {
    if (!receiptData) return []
    return receiptData.filter((item) => 
      item.ReceiptNum.toString().includes(search) || 
      item.CustomerName.trim().toLocaleLowerCase().includes(search.toLowerCase()) || 
      item.CustomerID.toString().includes(search)
    )
  }, [receiptData, search])

  return (
    <Switch>
      <Route path={path} exact>

        <OrderlikeLayout label='קבלות'>
          <ControlSearchPannelLayout>
            <DatePickerModule 
              multyDate
              ignorBrod
              setSelectedDateStart={setStartDate}
              setSelectedDateEnd={setEndDate}
              selected={setDefaultDate ? selectedDate : new Date()}
              cashFunc={handleDate}
              defEndDate={false}
              disabled={false}
              large
              />
            <SearchInput 
              input={search}
              setInput={setSearch}
            />
          </ControlSearchPannelLayout>
          <BodyScrollContainer>
            <TitlesList 
              list={render}
              uniqueKey={'ReceiptNum'}
              Element={ReceiptTitleLine}
              additionalData={itemProps}
              borderLess={false}
              noPadding={false}
              />
          </BodyScrollContainer>
          <ControlButton
            lable={'קבלה חדשה'}
            handleClick={handleCreateNew}
          />
        </OrderlikeLayout>
      </Route>
      <Route path={LINKS.bills.receipt.editGeneral} component={ReceiptEdit} />
      <Route path={LINKS.bills.receipt.create} render={() => <ReceiptCreate handlePushData={handlePushData} />} />
    </Switch>
  )
}

export default ReceiptMain