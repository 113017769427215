import React, {useState, useMemo} from 'react'
import styled from 'styled-components'
import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import { AcceptIcon, BarCodeIcon, CloseIcon, DivideIcon, PackageIcon, PalletIcon, PlusIcon, ScalesIcon } from '../../../../../generic/icons/Icons';
import RowInput from '../../../../../generic/inputs/RowInput';

interface Props extends IEntriesItem {
  moveItem?: (item: IEntriesItem, given?: boolean) => void;
  index: number;
  item: IEntriesItem;
  selectForDivide?: (item: IEntriesItem) => void,
  changeWeight?: (item: IEntriesItem,qty: number) => void
}

const CreateEntrieItem = ({
  AmountPack, EntryNum, DetailNum, index, isSelected, selectForDivide, EntryDetailNum,
  ExternalPallet, Gross, Pack_Name, Invoice, moveItem, item, Given, changeWeight
}: Props) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [temGross, setTempGross] = useState(Math.round(Gross))
  const handleSelect = () => {
    if(!moveItem) return
    try {
      setIsDeleted(true)
      setTimeout(() => {
        moveItem(item, Given)
        
      }, 500)
    } catch(err) {
      setIsDeleted(false)
    }
  }
  const handleButtonClick = (e: React.MouseEvent) => {
    if(!selectForDivide) return
    e.stopPropagation()
    selectForDivide(item)
  }
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempGross(+e.target.value)
  }
  const onBlurHandle = () => {
    if(!changeWeight) return
    changeWeight(item, temGross)
  } 
  const renderGross = useMemo(() => {
    setTempGross(Math.round(Gross))
    return Math.round(Gross)
  },[Gross])

  const isAllowToDivide = !!selectForDivide && !Given && AmountPack > 1
  return (
    <MainWrapper 
      isDeleted={isDeleted}
    > 
          <ButtonsField >
              {
                selectForDivide ?
                !Given  ?
                  <Button  onClick={handleSelect}>
                    <AcceptIcon />
                  </Button> 
                    : 
                    <Button  onClick={handleSelect}>
                      <CloseIcon handler={() => {}} />
                    </Button> 
                     : ''
              }
              {
                 isAllowToDivide &&
                  <Button onClick={handleButtonClick}>
                    <DivideIcon />
                  </Button>
              }
            </ButtonsField>
    
        <MainContent>
          <Row>
            <RowItem>
              {`${EntryDetailNum}`}
              <BarCodeIcon />
            </RowItem>
            <RowItem>
              {ExternalPallet}
              <PalletIcon />
            </RowItem>
          </Row>
          <Row>
         
              <RowInput 
                icon={<ScalesIcon />}
                defValue={renderGross}
                handleInput={handleInput}
                isDisabled={!selectForDivide}
                bgColor='none'
                onBlur={onBlurHandle}
                value={temGross}
              />

            <RowItem>
            ({Pack_Name}) {AmountPack} - 
              <PackageIcon />
            </RowItem>
          </Row>
        </MainContent>
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{isDeleted: boolean}>`
    width: 100%;
    padding: 0.5em 1em;
    border-radius: 30px;
    display: flex;
    background: #eff3f4;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    transition: 0.5s ease-in-out;
    transform: ${props => props.isDeleted ? 'translate(-110%)': 'translate(0)'} ;
    cursor: pointer;
    i {
    color: #297f76;
  }
`

const MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  
`
const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;

`
const RowItem = styled.div`
  display: flex;
  gap: 0.5em;

`

const ButtonsField = styled.div`
  /* padding: 0.5em; */
  height: 100%;
  font-size: 1.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  
`
const Button = styled.div`
  padding: 0.5em;
  transition: 0.3s;
  :hover {
    transform: scale(1.1);
    i {
      color: tomato;
    }

  }
`
export default CreateEntrieItem