import React, { useEffect, useRef, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick'
import OpenCloseButton from '../particles/OpenCloseButton'
import Title from '../Title/Title'

type Props = {
    controlFunc: (flag?:boolean) => void,
    Children: any,
    childrenProps: object,
    title: string,
    acceptFunction?: () => void,
    size?: 'sm' | 'md' | 'lg'
}
interface IModalContainer {
    isShow?: boolean,
    isSave?: boolean,
    size?: 'sm' | 'md' | 'lg'
} 
const ModalContainer = styled.div<IModalContainer>`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${props => props.isShow ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0)'};
    z-index: 5;
    padding: 0 0.5em;
    @media screen and (max-width: 500px) {
        padding: 1em 0.5em;
    }
    
`
const BodyWrapper = styled.div<IModalContainer>`
    position: relative;
    width: ${props => props.size === 'md' ? '60%' : '400px'};
    height: 700px;
    background: #fff;
    border-radius: 30px;
    transform: ${props => props.isShow ? 'translateY(0)' : 'translateY(-200%)'};
    transition: 0.5s;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    padding: 0.5em;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 90%;
    }
`
const ColumModalGeneral = ({size,controlFunc, Children, childrenProps, title, acceptFunction}: Props) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const closeFunc = ():void => {
        setIsOpen(false);
        setTimeout(() => {
            controlFunc()
        }, 500)
    }
    const memoCloseFunc = useCallback(closeFunc, [])

    useEffect(() => {
        setIsOpen(true)
    }, [])

    useDetectOutsideClick(modalRef, closeFunc)
    console.log('render')
  return (
    <ModalContainer isShow={isOpen}>
        <BodyWrapper ref={modalRef} isShow={isOpen} size={size}>
        <OpenCloseButton close func={memoCloseFunc} size={'sm'} />
        {
            acceptFunction &&
                <OpenCloseButton func={acceptFunction} size={'sm'} />
        }
        <Title label={title} color={'#297f76'} />
            {
                Children && 
                    <Children 
                        {...childrenProps} 
                        closeFunc={memoCloseFunc} 
                    />
            }
        </BodyWrapper>
    </ModalContainer>
  )
}
const ColumModal = React.memo(ColumModalGeneral)

export default ColumModal