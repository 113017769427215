import { addDays, format, startOfMonth } from "date-fns"
import { IDateRanger } from "./stateModel"

export const initialStateBuilder = (iteratingNum=3) => {
    const obj: Record<string, IDateRanger> = {}
    const dayForFormat = +format(new Date(), 'd') - 1
    let start = startOfMonth(new Date())
    let delta = -30
    for (let i = 1; i <= iteratingNum; i++) {
      if(i === 1) {
        obj[`selection${i}`] = {
          startDate: start,
          endDate: new Date(),
          key: `selection${i}`
        }
      } else {
        obj[`selection${i}`] = {
          startDate: start,
          endDate: addDays(startOfMonth(addDays(new Date(), delta)), dayForFormat),
          key: `selection${i}`
        }
        delta = delta - 30
       
      } 
      console.log(start)
      start = startOfMonth(addDays(new Date(), delta))
    }
    return obj
}