import styled from "styled-components";
import history from '../../../../../../history'
import ArizotAddHead from './arizotAddHead'
import ArizotAddBody from './arizotAddBody'
import SaveAzmanotBtn from '../../secondbar/modalAzmanot/buttons/saveAzmanotBtn'
import CloseModalAzmanot from '../../secondbar/modalAzmanot/buttons/closeModalAzmanot'
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { GET_CAR_ARIZOT, GET_CLIENT_ARIZOT, GET_PACKS_ARIZOT, CREATE_ARIZOT_AZMANA, GET_DEFAULT_CLIENTS_ARIZOT } from '../../../../../modules/API/settings'
import { useDispatch, useSelector } from "react-redux";
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { format } from "date-fns";
import { Decorator, MainWrapper, SectionMain } from "../../secondbar/mainbar2";
import { ControlPanelWrapper } from "../../secondbar/modalAzmanot/modalAzmanot";
import { LOAD, LOAD_END, LOGOUT } from "../../../../../../store/actions/actionsTypes";
import { Packages } from "../../../../../modules/API/Packages/packages";
import { LINKS } from "../../../../../Routs/config";
import { addIDForTheList } from "../../../../../generic/utils/addUIDForTheList";
import ColumModal from "../../../../../generic/Layouts/ColumModal";
import RemarkModal from "../View/RemarkModal";
import { useTranslation } from "react-i18next";

const InnerContainerArizot = styled(MainWrapper)`
    position: relative;
    /* flex-flow: row; */
    /* background: #fdfdfd; */
    padding: 0.5em;
    height: 100%;
    /* filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));     */
`
const ContentWrapper = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
    gap: 0.5em;
    padding: 0 0 0.2em 0;
    overflow: hidden;
`

const ButtonWrapper = styled.div`

    position: absolute;
    bottom: 4.5em;
    right: 0.8em;
    width: 3em;
    height: 3em;
    /* background: paleturquoise; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7ab0ab;
    .fas, .far {
        transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        font-size: 2.5em;
        /* transform: rotate(90deg); */
    }
`


export default function ArizotAddMenuMain({getArizot}) {
    // const ctx = useSelector(ctx => ctx.mainReducer.user.ctx)
    const [t] = useTranslation('packageOrder')
    const [blockSave, setBlockSave] = useState(false);
    const rights = useSelector(user => user.mainReducer.user.rights.find(e => e === "CUSTOMER"))
    const rightsGrower = useSelector(user => user.mainReducer.user.rights.find(e => e === "GROWER"))
    const [rightsToggle, setRightsToggle] = useState(rights || rightsGrower ? true : false);
    const customers = useSelector(item => item.defaults.clientList)
    console.log(customers)
    const [modal, setModal] = useContext(ContextModal)
    const [isOpenRemarkWindow, setIsOpenRemarkWindow] = useState(false);
    const [remarkData, setRemarkData] = useState(null);
    const getToken = useSelector(token => token.mainReducer.token);
    // DATA COLLECTION
    const [palletPacks, setPalletPacks] = useState([])
    const [clientIdRecip, setClientIdRecip] = useState(null)
    const [clientIdSender, setClientIdSender] = useState(null)
    const [reference, setReference] = useState('')
    const [carId, setCarId] = useState(null)
    const [carNumber, setCarNumber] = useState(null)
    const [driverName, setDriverName] = useState('')
    const [isNeedToTransfer, setIsNeedToTransfer] = useState(false)

    const handleTransferCheckbox = () => {
        setIsNeedToTransfer(!isNeedToTransfer)
    }
    const dispatch = useDispatch();
    // const [collectDate, setCollectDate] = ('')
    useEffect(() => {
        console.log(palletPacks);

    }, [palletPacks])


    //DATE
    const [startDate, setStartDate] = useState(new Date)
    const data = format(startDate, "yyyyMMdd");

    // RIDERS
    const [inputRider, setInputRide] = useState('')
    const [wrapperRiderStatus, setWrapperRiderStatus] = useState(false)
    const [riderList, setRiderList] = useState([])
    const [riderListDefault, setRiderListDefault] = useState([])

    //CLIENTS
    const [inputClient, setInputClient] = useState('')
    const [wrapperClientStatus, setWrapperClientStatus] = useState(false)

    const [inputSender, setInputSender] = useState('')
    const [wrapperSenderStatus, setWrapperSenderStatus] = useState(false)

    const [sender, setSender] = useState([])
    const [senderDefault, setSenderDefault] = useState([])

    const initialDisableState = {
        sender: rights ? true : false,
        reciep: rightsGrower ? true : false
    }
    const [isDisabled, setIsDisabled] = useState(initialDisableState)


    // REF
    const [inputRef, setInputRef] = useState('')

    const refFilter = (input) => {
        setInputRef(input)
    }
    const [packs, setPacks] = useState([])
    //      switcher btn
    const [switcherBtnStatus, setSwitcherBtnStatus] = useState(true)
    const switcherFunc = () => {
        if ((window.innerWidth || document.documentElement.clientWidth) > 600) {
            setSwitcherBtnStatus(true)
        }
        else {
            setSwitcherBtnStatus(false)
        }
    }
    useEffect(() => { switcherFunc() }, [document.documentElement.clientWidth])
    //      /switcher btn


    const createAzmana = async () => {
        const postPacs = packs.reduce((acc, cur) => {
            console.log(cur)
            if(!cur?.Qty) return acc
            const item = {
                "Pack_Pallet": cur.Pack_Pallet,
                "PackID": cur.PackID,
                "Qty": cur.Qty,
                "Remark": cur.Remark || '',
                "Price": 0,
                "Line_Total": 0
            }
            acc.push(item)
            return acc
        }, [])
        const postData = {
            "ClientID_Recipient": clientIdRecip,
            "ClientID_Sender": clientIdSender,
            "Reference": inputRef,
            "PD_Num": 0,
            "PO_Date": data,
            "Qty": 0,
            "Vehicle_Code": carId,
            "Vehicle_Num": carNumber,
            "Driver_Name": driverName,
            "PO_Status": 0,
            "Remarks": "",
            "PO_Time": "0",
            "Packs_Pallets": postPacs,
            "Pickup": isNeedToTransfer
        }
        if (clientIdRecip === null || clientIdSender === null) {
            setModal({
                open: true,
                value: 'חובה לבחור אריזות או משטחים',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }

        else if (postPacs.length < 1) {
            setModal({
                open: true,
                value: 'חובה לבחור אריזות או משטחים',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
        else {
            setBlockSave(true);
            dispatch({ type: LOAD })
            try {
                let res = await Packages.createPackageOrder(postData)
                closeArizotAzmana()
                getArizot()
                setModal({
                    open: true,
                    value: 'ההזמנה שלך נוצרה בהצלחה',
                    mood: true
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            } catch (err) {
                // console.log(err.response)
                let error = err.response.data.error
                if (err.response.status === 405) {
                    error = `אסמכתא כבר נקלטה בתאריך ${err.response.data.error}`
                }
                setModal({
                    open: true,
                    value: error,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            } finally {
                dispatch({ type: LOAD_END })
                setBlockSave(false);
            }

        }
    }

    const closeArizotAzmana = () => {
        history.push(LINKS.arizot.arizaAhzara.mainUrl)
    }

    const clientFilter = (input) => {
        const filtered = senderDefault.filter(item => {
            return item.ClientName.toLowerCase().includes(input.toLowerCase()) ||
                item.ClientID.toString().includes(input)
        })
        setSender(filtered)
        setInputClient(input)
        setWrapperClientStatus(true)
    }

    const senderFilter = (input) => {
        const filtered = senderDefault.filter(item => {
            return item.ClientName.toLowerCase().includes(input.toLowerCase()) ||
                item.ClientID.toString().includes(input)
        })
        setSender(filtered)
        setInputSender(input)
        setWrapperSenderStatus(true)
    }

    const riderFilter = (input) => {
        const filtered = riderListDefault.filter(item => {
            return item.CarNum.toString().includes(input) || item.DriverName.toLowerCase().includes(input.toLowerCase())
        })
        setRiderList(filtered)
        setInputRide(input)
        setWrapperRiderStatus(true)
    }


    const getLists = async () => {

        try {
            let response = await axios.get(`${GET_DEFAULT_CLIENTS_ARIZOT}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            // console.log(response.data.clients)
            let list = response.data.clients
            setSender(list)
            setSenderDefault(list)

            let data =
            {
                ClientName: customers.list[0].Customer_Name?.trim(),
                ClientID: customers.list[0].ClientID

            }
            console.log(data)
            // check for rigths and set default values
            if (rights) {
                setInputSender(data.ClientName.trim())
                setClientIdSender(data.ClientID)
                // console.log(response)
            }

            if (rightsGrower) {
                // console.log('grower')
                data.ClientID = list[0]?.ClientID || 0
                data.ClientName = list[0]?.ClientName.trim() || ""
                console.log(list)
                console.log(data)
                setClientIdRecip(data.ClientID)
                setInputClient(data.ClientName.trim())
            }
        } catch (err) {
            // console.log(err.response.data.message)
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
            setModal({
                open: true,
                value: err?.response?.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }

    }

    const getRiders = async () => {
        try {
            let response = await axios.get(`${GET_CAR_ARIZOT}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            // console.log(response.data.cars)
            setRiderListDefault(response.data.cars)
            setRiderList(response.data.cars)
        } catch (err) {
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
            // console.log(err.response.data.message)
        }

    }

    const getPacks = async () => {
        let response = await axios.get(`${GET_PACKS_ARIZOT}`, {
            headers: {
                'Authorization': 'Bearer ' + getToken
            }
        })
        setPacks(addIDForTheList(response.data.recommendations))
        // check for rigths and set default values CUSTOMER rightsGrower
        if (rights) {
            setClientIdRecip(response.data.defaultClient[0].ClientID)
            setInputClient(response.data.defaultClient[0].ClientName.trim())
            // console.log(clientIdSender)
        }
        // check for rigths and set default values GROWER 
        if (rightsGrower) {
            setSender(response.data.defaultClient)
            setSenderDefault(response.data.defaultClient)

            setClientIdSender(response.data.defaultClient[0].ClientID)
            setInputSender(response.data.defaultClient[0].ClientName.trim())
        }



    }
    const handleRemarkModal = () => {
        setIsOpenRemarkWindow(!isOpenRemarkWindow)
    }
    const handleSave = (inputRemark) => {
        const newState = [...packs];
        const found = newState.find((e) => e.id === remarkData?.id);
        console.log(remarkData?.id)
        if (!found) return;
        const indexOfItem = newState.indexOf(found);
        newState[indexOfItem].Remark = inputRemark;
        setPacks(newState);
        setIsOpenRemarkWindow(!isOpenRemarkWindow)
    }
    const patchState = (qty, id) => {
        if (!packs) return;
        const newState = [...packs];
        const found = newState.find((e) => e.id === id);
        if (!found) return;
        const indexOfItem = newState.indexOf(found);
        newState[indexOfItem].Qty = qty;

        setPacks(newState);
    };

    const remarkWindowProps = {
        data: remarkData,
        close: handleRemarkModal,
        handleSave: handleSave,
        isActive: true
    }

    const switcher = () => {
        const data = {
            reciep: {
                name: inputClient,
                id: clientIdRecip
            },
            sender: {
                name: inputSender,
                id: clientIdSender
            },

        }
        let filtered
        // setRightsToggle(false)
        setIsDisabled({
            reciep: !isDisabled.reciep,
            sender: !isDisabled.sender
        })
        if (!data.sender.id && !data.reciep.id) return

        if (rights) {
            filtered = senderDefault.filter(item => item.ClientID !== data.sender.id)
            setClientIdRecip(data.sender.id);
            setInputClient(data.sender.name);
            setSender(filtered)
            setClientIdSender(data.reciep.id);
            setInputSender(data.reciep.name);
            // setWrapperSenderStatus(true)
        } else if (rightsGrower) {
            filtered = senderDefault.filter(item => item.ClientID !== data.reciep.id)
            setClientIdSender(data.reciep.id);
            setInputSender(data.reciep.name);
            setSender(filtered)
            setClientIdRecip(data.sender.id);
            setInputClient(data.sender.name);
            // setWrapperClientStatus(true)
        }
    }
    useEffect(() => {
        getPacks()
        getRiders()
        getLists()
    }, [])

    const [side, setSide] = useState(false)
    const arrowRef = useRef(null)
    const rotatinArrow = () => {
        side ? arrowRef.current.style.transform = 'rotate(0deg)' : arrowRef.current.style.transform = 'rotate(180deg)'
        setSide(!side)
    }

    let sideToggle = side ? 'translateX(-102%)' : 'translateX(0%)'
    // window.innerWidth || document.documentElement.clientWidth) > 770
    let switchBtnToggle = switcherBtnStatus ? 'none' : 'flex'
    return (
        <SectionMain>
            <InnerContainerArizot >
                <Decorator />
                {
                    isOpenRemarkWindow &&
                    <ColumModal
                        title={t('packageOrder_modal_remark_title')}
                        Children={RemarkModal}
                        controlFunc={handleRemarkModal}
                        childrenProps={remarkWindowProps}
                    />
                }
                <ContentWrapper>
                    {/* <button onClick={switcher}>asd</button> */}
                    <ArizotAddHead
                        sideToggle={sideToggle}
                        riderListDefault={riderListDefault} riderList={riderList} setRiderList={setRiderList}
                        inputRider={inputRider} setInputRide={setInputRide} riderFilter={riderFilter}
                        wrapperRiderStatus={wrapperRiderStatus} setWrapperRiderStatus={setWrapperRiderStatus}  // RIDER
                        switcher={switcher}
                        isDisabled={isDisabled}
                        startDate={startDate} setStartDate={setStartDate} //DATE

                        inputClient={inputClient} setInputClient={setInputClient} senderDefault={senderDefault}
                        sender={sender} setSender={setSender} clientFilter={clientFilter}
                        wrapperClientStatus={wrapperClientStatus} setWrapperClientStatus={setWrapperClientStatus}

                        inputSender={inputSender} setInputSender={setInputSender} senderFilter={senderFilter}
                        wrapperSenderStatus={wrapperSenderStatus} setWrapperSenderStatus={setWrapperSenderStatus} //CLIENTS

                        inputRef={inputRef} setInputRef={setInputRef} refFilter={refFilter} //refs
                        //DATA COLLECTION
                        setClientIdRecip={setClientIdRecip} setClientIdSender={setClientIdSender}
                        setCarId={setCarId} setCarNumber={setCarNumber} setDriverName={setDriverName}
                        setReference={setReference}

                        //other
                        rightsToggle={rightsToggle}
                        isNeedToTransfer={isNeedToTransfer}
                        handleTransferCheckbox={handleTransferCheckbox}
                    />
                    <ArizotAddBody
                        setRemarkData={setRemarkData}
                        patchState={patchState}
                        sideToggle={sideToggle}
                        packs={packs}
                        palletPacks={palletPacks}
                        setPalletPacks={setPalletPacks}
                        handleRemarkModal={handleRemarkModal}

                    />
                    <ButtonWrapper style={{ display: switchBtnToggle }} onClick={() => { rotatinArrow() }}>
                        <i ref={arrowRef} className="far fa-arrow-alt-circle-right"></i>
                        {/* <i class="far fa-arrow-alt-circle-right"></i> */}
                    </ButtonWrapper>
                </ContentWrapper>
                <ControlPanelWrapper >
                    <CloseModalAzmanot
                        closeModalAzmanot={closeArizotAzmana}
                    />
                    {clientIdRecip === null || clientIdSender === null ?
                        '' :
                        <SaveAzmanotBtn
                            blockSave={blockSave}
                            createAzmana={createAzmana}
                        />
                    }


                </ControlPanelWrapper>
            </InnerContainerArizot>


            {/* <button onClick={()=>{createAzmana()}}>123</button> */}
        </SectionMain>
    );
}