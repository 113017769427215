import React, {useState, useMemo} from 'react'
import styled from 'styled-components'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { Logs } from '../../../../../modules/API/LOGS/logs'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { IOrderLogDataItem, IOrderLogsResponse } from '../../../../../modules/API/LOGS/models/orderLogs'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { IClientsItem } from '../../../../../modules/API/Defaults/models/customers'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, CalendatDay, EditIcon, InvoiceIcon, PackageIcon, ShekelIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { format } from 'date-fns'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IProductListItemNode } from '../../../../../modules/API/Defaults/models/productListNode'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import { convertToDate } from '../../../../../modules/convertDateString'

type Props = {
    closeFunc: () => void
}

const LogsOrderModal = ({closeFunc}: Props) => {
    const customersHash: Record<number, IClientsItem> = useSelector((state: RootState) => state.defaults.clientList.hashTable)
    const packageList: IPackagesItem[] = useSelector((state: RootState) => state.defaults.packageList)
    console.log(packageList)
    const [search, setSearch] = useState('')
    const [logs, setLogs] = useState<IOrderLogDataItem>()
    
    const {
        data: dataProduct,
        isLoading: isLoadingProduct
    } = useDataFetcher<{message: string, products: IProductListItemNode[]}>(
        Defaults.getProductListNode, 'asd'
    )
    console.log(dataProduct)
    const productHash = useMemo(() => {
        if(!dataProduct) return {}
        return dataProduct.products.reduce((acc: Record<number, IProductListItemNode>, item) => {
            acc[item.ProductID] = item
            return acc
        }, {})
    }, [dataProduct])
    const packageHash = useMemo(() => {
        if(!packageList) return {}
        return packageList.reduce((acc: Record<number, IPackagesItem>, item) => {
            acc[item.PackID] = item
            return acc
        }, {})
    }, [packageList])

    const date = () => {
        if(!logs) return ""
        return format(new Date(logs?.date.date), 'hh:mm:ss a dd/LL/yyyy O')
    } 
    const getOrderLogs = async () => {
        try {
            let res: IOrderLogsResponse = await Logs.getOrderLogsByOrderId(search)
            if(!res.data) {
                alert("order is not found")
            } else {
                setLogs(res.data)
            }
        } catch (error) {
            
        }
    }
  return (
    <MainWrapper>
        <OrderlikeLayout label='LOGS'>
            <ControlSearchPannelLayout >
                <SearchInput 
                    value={'write OrderID and press enter'}
                    setInput={setSearch}
                    enterFunc={getOrderLogs}

                />
            </ControlSearchPannelLayout>
            <BodyScrollContainer>
                <ScrollContainer>

                {
                    logs &&
                    <TitleLayout>
                        <TitleRow>
                            <TitleRowItem name='GENERAL INFORMATION' icon={<></>}/>
                        </TitleRow>
                        <TitleRow>
                        </TitleRow>
                        <TitleRow>
                            <TitleRowItem 
                                name={
                                    `[${logs.user.rights}] - (${logs.user.company}) ${logs.user.name}`
                                } 
                                icon={<UserIcon />}
                            />
                        </TitleRow>
                        <TitleRow>
                            <TitleRowItem name={`${date()}`} icon={<CalendatDay />} />
                            <TitleRowItem name={`${logs.orderID}`} icon={<InvoiceIcon />} />
                        </TitleRow>
                        <TitleRow>
                        </TitleRow>
                        <TitleRow>
                        </TitleRow>
                        <TitleRow>
                            <TitleRowItem name='CREATED ORDER' icon={<></>}/>
                        </TitleRow>
                        <TitleRow>
                        </TitleRow>
                        <TitleRow>
                            <TitleRowItem name={`${customersHash[logs.payload.CustomerID].Customer_Name?.trim() || ""} - Customer`} icon={<UserIcon />} />
                        </TitleRow>
                        <TitleRow>
                            <TitleRowItem name={`${convertToDate(logs.payload.Delivery_Date)} - delivery`} icon={<CalendatDay />} />
                        </TitleRow>
                        <ProductsWrapper>
                            {
                                logs.payload.Products.map((item, index) => 
                                    <TitleLayout key={index}>
                                        <TitleRow>
                                            <TitleRowItem name={`${item.SizeID?.trim()} ${item.VarietyID} ${productHash[item.ProductID].ProductDesc?.trim() || ""}`} icon={<AppleIcon />} />
                                        </TitleRow>
                                        <TitleRow>
                                            <TitleRowItem name={`${item.Packs} - (${item.PackID === 0 ? "carton" : packageHash[item.PackID]?.Pack_Name?.trim()})`} icon={<PackageIcon />} />
                                        </TitleRow>
                                        <TitleRow>
                                            <TitleRowItem name={`${item.Mlai} - MLAI`} icon={<PackageIcon />} />
                                            <TitleRowItem name={`${item.Price}`} icon={<ShekelIcon />} />
                                        </TitleRow>
                                        <TitleRow>
                                            <TitleRowItem name={`price was updated - ${item.Update_Manual ? "YES" : "NO"} `} icon={<EditIcon />} />
                                        </TitleRow>

                                    </TitleLayout>
                                )
                            }
                        </ProductsWrapper>
                    </TitleLayout>

                }
                    </ScrollContainer>
            </BodyScrollContainer>
            <ControlButton lable={'חזרה'} handleClick={closeFunc} />
        </OrderlikeLayout>

    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 2;
`
const ScrollContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 0;
    }
    padding: 1em;
`
const ProductsWrapper = styled.div`
    width: 100%;
    padding: 1em;
    background: #EFF3F4;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    gap: 1em;
`
export default LogsOrderModal