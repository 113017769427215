import React from 'react'
import { IPackPalletItem } from '../../../../../modules/API/Packages/models/arizotLine'
import ArizotAddBody from '../../arizot/arizotAddMenu/arizotAddBody'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ControlButton from '../../../../../generic/buttons/ControlButton'

type Props = {
  list: IPackPalletItem[],
  save: () => void,
  close: () => void
}

const CheckWindow = ({
  list,
  save,
  close
}: Props) => {
  const [t] = useTranslation('buttons')
  return (
    <MainWrapper>
      <ArizotAddBody 
        packs={list}
        handleRemarkModal={() => {}}
        sideToggle={() => {}}
        patchState={() => {}}
        setRemarkData={() => {}}
        isDisabled
        isActiveButton={false}
      />
      <ButtonWrapper>
        <ControlButton
          lable={t('close')}
          handleClick={close}
        />
        <ControlButton
          lable={t('save')}
          handleClick={save}
          primal
        />
      </ButtonWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  border-radius: 30px;
  gap: 10px;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`
export default CheckWindow