import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Title from '../../../../../../generic/Title/Title'
import { SelectPanelWrapper } from '../../../secondbar/azmanotmain/SelectBar'
import { useTranslation } from 'react-i18next'
import { Container, InnerWrapper, ModalWindow } from '../palletType/LikutPalletTypeModal'
import LikutExpressPrintersList from './LikutExpressPrintersList'

const ControlWrapper = styled.div`
        position: absolute;
        margin: 0 auto;
        bottom: -1em;
        display: flex;
        gap: 1em;
`
const Button = styled.div`
    background: rgba(41,127,118, 0.8);
    width: 8em;
    padding: 0.5em;
    border-radius: 20px;
    color: #fdfdfd;
    cursor: pointer;
    transition: 0.3s;
    :hover{
        background: rgba(41,127,118, 1);
    }
`
export default function LikutExpressPrintersModal({
    printersList, setPrintersModal, saveToLocalPrinter,
    printerNum, printPallet, printerFormat, saveToLocalPrintFormat
}) {
    const [t] = useTranslation(['likut', 'buttons']);
    const palletNumRedux = useSelector(num => num.likut?.pallet?.palletNum);
    console.log(printersList)
    const formatList = [
        {NumWeight: 1, DescWeights: '10X15 עם פירוט'},
        {NumWeight: 2, DescWeights: '15X10 ללא פירוט'},
        {NumWeight: 3, DescWeights: '10X10 ללא פירוט'}
    ]
    return (
        <ModalWindow>
            <Container >
                <InnerWrapper style={{position: 'relative'}}>
                    <Title label={t("likut_modal_printers_printer")} />
                    <SelectPanelWrapper>
                        <LikutExpressPrintersList
                            setPrintersModal={setPrintersModal} 
                            printersList={printersList} 
                            saveToLocalPrinter={saveToLocalPrinter} 
                            printerNum={printerNum}

                        />
                    </SelectPanelWrapper>
                    <Title label={t('likut_modal_printers_format')} />
                    <SelectPanelWrapper>
                        <LikutExpressPrintersList
                            setPrintersModal={setPrintersModal} 
                            printersList={formatList} 
                            saveToLocalPrinter={saveToLocalPrintFormat} 
                            printerNum={printerFormat}
                            format={true}

                        />
                    </SelectPanelWrapper>
                    <ControlWrapper>
                        <Button onClick={() => {setPrintersModal(false)}}>{t('close', {ns: 'buttons'})}</Button>
                        {printerNum && printerFormat ? 
                            <Button onClick={() => {printPallet(palletNumRedux); setPrintersModal(false)}}>הדפס</Button> 
                                : 
                                    ''
                        }
                        
                    </ControlWrapper>
                    
                </InnerWrapper>
                
            </Container>
        </ModalWindow>
    )
}
