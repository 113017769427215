import { useSelector } from "react-redux"
import { RootState } from "../../../../../../store/reducers/rootReducer"
import selectBrodMigdal from "../../../../../generic/utils/selectBrodMigdal"
import React, { useState, useEffect, useMemo } from 'react'

export interface IKnisaConfigItem {
  refValue: 'boolean' | 'number'
  code: string
  value: boolean | number
}
export const useKnisaConfig = () => {
  const defaults = useSelector((state: RootState) => state.defaults.defaults)
  
  const isRequiredGrower = selectBrodMigdal(defaults, 4, "Logical_Value")
  const isReqiredCar = selectBrodMigdal(defaults, 618, "Logical_Value")
  const defaultCar = selectBrodMigdal(defaults, 395, "Num_Value")

  const isReqiredTranzit = selectBrodMigdal(defaults, 6, "Logical_Value")
  const defaultTranzit = selectBrodMigdal(defaults, 5, "Num_Value")

  const defaultPackage = selectBrodMigdal(defaults, 204, "Num_Value")

  const isReqieredWeight = selectBrodMigdal(defaults, 556, "Logical_Value")
  const isCopyLineWithWeight = selectBrodMigdal(defaults, 423, "Logical_Value")
  const isReqiredRef = selectBrodMigdal(defaults, 220, "Logical_Value")


  const config = useMemo(() => {
    return {
      create: {
        title: {
          grower: [
            {
              refValue: 'boolean',
              code: 'DF4',
              value: isRequiredGrower
            },
          ],
          car: [
            {
              refValue: 'boolean',
              code: 'DF618',
              value: isReqiredCar
            },
            {
              refValue: 'number',
              code: 'DF395',
              value: defaultCar
            }
          ],
          tranzit: [
            {
              refValue: 'boolean',
              code: 'DF6',
              value: isReqiredTranzit
            },
            {
              refValue: 'number',
              code: 'DF5',
              value: defaultTranzit
            }
          ],
          ref: [
            {
              refValue: 'boolean',
              code: 'DF220',
              value: isReqiredRef
            }
          ]      
        },
        details: {
          package: [
            {
              refValue: 'number',
              code: 'DF382',
              value: defaultPackage
            }
          ],
          weight: [
            {
              refValue: 'boolean',
              code: 'DF556',
              value: isReqieredWeight
            }
          ],
          multuplier: [
            {
              refValue: 'boolean',
              code: 'DF423',
              value: isCopyLineWithWeight
            }
          ],
          
          
        }
      }
    }
  }, [isRequiredGrower, isReqiredCar, defaultCar, isReqiredTranzit, defaultTranzit, isCopyLineWithWeight])

  return config
}