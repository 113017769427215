export const Q8_TABLE_HEAD = [
    "לקוח",
    "פריט",
    // "PD_Type",
    // "PackID",
    "יתרת פתיחה",
    "חיוב",
    "זיכוי",
    "לתקופה",
    "יתרת סגירה",
]