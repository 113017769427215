import React, { useState } from 'react'
import styled from 'styled-components';
import { AppleIcon, PackageIcon, ScalesIcon } from '../../../../generic/icons/Icons';
const Wrapper = styled.div`
  background: ${props => props.isSelected ? 'tomato' : '#eff3f4'};
  border-radius: 20px;
  padding: 0.5em;
  color: ${props => props.isSelected ? '#fff' : '#75759e'};
  display: flex;
  justify-content: space-between;
  
  
`
const Item = styled.div`
  display: flex;
  gap: 0.5em;
`
export default function PalletItem({Pallet_Num, collectDragPallets, item, NetWeight, Total_Packages}) {
    const [isSelected, setIsSelected] = useState(false);
  return (
    <Wrapper
        isSelected={isSelected}                                  
        onClick={() => { collectDragPallets(item); setIsSelected(!isSelected) }}
    > 
        <Item>
          <PackageIcon />
          {Total_Packages}
        </Item>
        <Item>
          <ScalesIcon />
          {Math.round(NetWeight)}
        </Item>
    </Wrapper>
  )
}
