// Mongo api
export const STATIC_MONGO = 'https://my.agroline.co.il/v3/'
export const CREATE_ORDER_MONOG = `${STATIC_MONGO}api/order-create`
export const UPDATE_STATUS_HISTORY = `${STATIC_MONGO}api/order-status`
export const UPDATE_ORDER_HISTORY = `${STATIC_MONGO}api/order-update`
//  {orderID: 101480} {"hisory.date": {$gt: "2022-05-08T10:38:06.655Z"}} {"date.date": {$gt: ISODate('2022-05-08T10:38:06.655Z')}}
//BORIS API
export const STATIC_PATH_V2_TEST = `https://my.agroline.co.il/v2stage/`
export const STATIC_PATH_V2 = `https://my.agroline.co.il/v2/`
export const STATIC_PATH_WSPALLETS = `https://my.agroline.co.il/wspallets/`
export const TRANSPORT_AREAS = `${STATIC_PATH_V2}transport_area/`

/*
    @ GET_TEUDOT_V2

    @required params - startDate, endDate
    @optional param - supervision

    @supervision variables:
        1) Only not kosher = 0
        2) Kosher segregation = [2,]
        3) All kosher (any kind) = 999

*/
export const GET_TEUDOT_V2 = `${STATIC_PATH_V2}delivery?`

export const SET_SIGN_V2 = `${STATIC_PATH_V2}delivery/bulk_mashgiah_update`

// old
// export const STATIC_PATH = 'http://62.90.247.238:8094/api/'

//new
export const STATIC_PATH = 'https://my.agroline.co.il/v1/api/'

// old
// export const EXIST_PATH = `https://62.90.247.238:8094`
//new
export const EXIST_PATH = `https://my.agroline.co.il/v1/`

export const CURRENT_PATH = `${STATIC_PATH}auth`
export const GET_ORDER = `${STATIC_PATH}order/`
export const GET_DB = `${STATIC_PATH}auth/database`
export const PATCH_DB = `${STATIC_PATH}auth`
export const GET_COMPANY = `${STATIC_PATH}auth/company`
export const GET_CUSTOMER_LIST = `${STATIC_PATH}customer`
export const GET_GOODS_LIST = `${STATIC_PATH}order/product?customer=`
export const CREATE_ORDER = `${STATIC_PATH}order`
export const GET_PACKAGE = `${STATIC_PATH}package`
export const LOAD_ORDERS = `${STATIC_PATH}order?`
export const LOAD_LIKUT_PODUCTS = `${STATIC_PATH}expect/product/`
export const SAVE_LIKUT = `${STATIC_PATH}expect`
export const GET_LIKUT_GROWERS = `${STATIC_PATH}grower`
export const GET_CEFI = `${STATIC_PATH}expect`
export const DELETE_CEFI = `${STATIC_PATH}expect/`
export const GET_CEFI_EXIST = `${STATIC_PATH}expect/`
export const GET_PRODUCT_TYPES = `${STATIC_PATH}product/group`

//view
export const GET_PRODUCT_VARIETY_SIZE = `${STATIC_PATH}view/268` 

//defaults
export const GET_DEFAULTS = `${STATIC_PATH}defaults`

// agdarot
export const GET_INTERNAL_COMPANIES = `${STATIC_PATH}auth/company`
export const CHANGE_INTERNAL_COMPANIES = `${STATIC_PATH}auth`

// arizot
export const GET_AZMANOT_ARIZOT = `${STATIC_PATH}order/packs?`
export const GET_PACKS_ARIZOT = `${STATIC_PATH}order/pack`
export const GET_CLIENT_ARIZOT = `${STATIC_PATH}client`
export const GET_CAR_ARIZOT = `${STATIC_PATH}car`
export const CREATE_ARIZOT_AZMANA = `${STATIC_PATH}order/pack`
export const DELETE_ARIZOT_AZMANA = `${STATIC_PATH}order/pack/` // + orderId
export const GET_DEFAULT_CLIENTS_ARIZOT = `${STATIC_PATH}client/pairing` 


//cefi
export const GET_CEFI_PRODUCTS_ADDSELF = `${STATIC_PATH}product/`
export const GET_CEFI_VARIETIES = `${STATIC_PATH}variety/`
export const GET_SIZES = `${STATIC_PATH}size`


// likut 
export const GET_PALLETS = `${STATIC_PATH}pallet`;
export const GET_PRINTERS = `${STATIC_PATH}likut/printer`;
export const GET_ENTRY = `${STATIC_PATH}likut/entry/`
export const GET_AZMANA_PALLETS = `${STATIC_PATH}likut/pallet/`; //id
export const GET_PALLET_EXIST = `${STATIC_PATH}delivery/15/` // num pallet
export const GET_GOODS_ENTRY = `${STATIC_PATH}likut/entry/` // goods id 1002-2
export const SAVE_SELECTED_GOOD = `${STATIC_PATH}likut` // patch
export const SAVE_COMMENT_ON_PALLET = `${STATIC_PATH}delivery/18/` // :palletNum patch
export const PRINT_PALLET = `${STATIC_PATH}likut/printer/` // palet num, put
export const WORKERS = `${STATIC_PATH}worker`
export const WORKER_GROUP = `${STATIC_PATH}worker/group`
export const GET_DELIVERYCODES = `${STATIC_PATH}customer/deliverycodes`
export const GET_TRANSPORTAREAS = `${STATIC_PATH}client/transportareas`
export const GET_CUSTOMER_GROUP = `${STATIC_PATH}customer/group`
export const GET_SELLERS = `${STATIC_PATH}seller` //get
export const EDIT_ORDER_ROW = `${STATIC_PATH}order/details` // patch
export const DELETE_FROM_PALLET = `${STATIC_PATH}delivery/18/` // id


// teudot
export const GET_TEUDOT = `${STATIC_PATH}delivery/16?`
export const GET_PDF_TEUDOT = `${STATIC_PATH}delivery/16/print?`
export const GET_TEUDOT_DETAILS = `${STATIC_PATH}delivery/18/`
export const PATCH_TEUDOT_DETAILS = `${STATIC_PATH}delivery/18/`
export const CREATE_TEUDOT = `${STATIC_PATH}delivery/16` 
// POST
// data : {
//     "PalletNums": [29628, 29629, 29630],
//       "OrderNum": 0,
//       "CustomerID": 9,
//       "DeliveryDate": "20221018",
//       "DeliveryTime": "011000" 
// }


// pallets control

export const GET_PALLETS_BY_DATE = `${STATIC_PATH}delivery/15?` // fromDate=&toDate
export const PATCH_PALLET = `${STATIC_PATH}delivery/15?`
export const GET_PALLETS_COLLECTION = `${STATIC_PATH}summary/transferzone/` // 1?date=20220627&deliveryCode=2&marketingArea=4
export const GET_DELIVERY_AZMANOT = `${STATIC_PATH}/view/267?deliveryDate=` // YYYYMMDD date


export const GET_PRICE_DIAPASON = `${STATIC_PATH}summary/sales/2?` 

