import React from 'react'
import { IPalletItem } from '../../../../../modules/API/Drivers/models/kavData'
import styled from 'styled-components'
import { InvoiceIcon, PackageIcon, PalletIcon } from '../../../../../generic/icons/Icons'

interface IProps extends IPalletItem {

    handleSelectToDrag: ( elementID: number, kavID: number, CustomerID: number, OrderID: number, isOrder?: boolean 
        ) => void;
    area: number;
    OrderID: number;
    CustomerID: number
}

const ColumnPalletItem = ({
    Qty, PallQty, Selected, handleSelectToDrag, Pallet_Num, 
    area, OrderID, CustomerID, Delivery_Num
}: IProps) => {
    const handleSelect = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleSelectToDrag(Pallet_Num, area, CustomerID, OrderID)
    }
  return (
    <MainWrapper 
        onClick={handleSelect}
        Selected={Selected}
    >
        <Item>
            <InvoiceIcon />
            {Delivery_Num}
        </Item>
        <Item>
            <PalletIcon />
            {PallQty}
        </Item>
        <Item>
            <PackageIcon />
            {Qty}
        </Item>
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{Selected?: boolean}>`
    width: 100%;
    padding: 0.5em ;
    display: flex;
    border-radius: 20px;
    justify-content: space-between;
    background: ${props => props.Selected ? '#80e081' : '#fff'}
`
const Item = styled.div`
    display: flex;
    gap: 0.5em;
`
export default ColumnPalletItem