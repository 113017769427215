import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

function TableHead({
    children
}: Props) {
  return (
    <TableHeadStyle>
        <TableRow>
            {children}
        </TableRow>
    </TableHeadStyle>
  )
}
const TableHeadStyle = styled.thead`
    width: 100%;
    height: 1em;
    position: sticky;
    top: 0;
`
const TableRow = styled.tr`
    width: 100%;
    height: 1em;
    :last-child {
        td {
            border-radius: 0 0 15px 15px;
        }
    }
`
export default TableHead