import axios from "axios"
import { statusConverter } from "../../generic/artcoded/statusAzmanot"
import { UPDATE_STATUS_HISTORY } from "../API/settings";

export const updateStatusAzmana = async (orderID, user, statusAzmana) => {
    let detailes = statusConverter(statusAzmana);
    
    try {
        let res = await axios({
            method: 'patch',
            url: UPDATE_STATUS_HISTORY,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                orderID: orderID,
                user: user,
                data: {
                    date: new Date(),
                    StatusCode: statusAzmana,
                    StatusDesc: detailes.status
                }
            }
        })
        console.log(res)
    } catch (err) {
        console.log(err)
    }
}