import React from 'react'
import styled from 'styled-components'
import { BarCodeIcon } from '../../../../../generic/icons/Icons';

type Props = {
    isShowIcon?: boolean;
    value: number | string;
    handleChangePalletNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleFunction: () => void;
    iconVariationSwitcher?: boolean; 
}

const PalletInput = ({
    isShowIcon, value, handleChangePalletNumber, 
    handleFunction, iconVariationSwitcher,

}: Props) => {
    const handleEnterFuncPallet = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleFunction()
        }
    }
    return (
        <InputWrapper>
            {
                isShowIcon &&
                <SideButton
                    onClick={handleFunction}
                    active={!iconVariationSwitcher}
                >
                    <BarCodeIcon />
                </SideButton>
            }
            <Input
                placeholder='הכנס משטח'
                type='number'
                value={value === 0 ? '' : value}
                onChange={handleChangePalletNumber}
                onKeyDown={handleEnterFuncPallet}
            />
        </InputWrapper>
    )
}

const InputWrapper = styled.div`
    position: relative;
`
const SideButton = styled.div<{active?: boolean}>`
    position: absolute;
    top: calc(50% - 1.25em) ;
    left: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    background: ${props => props.active ? '#64A19C' : 'tomato'} ;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
`
const Input = styled.input`
  width: 100%;
  height: 3em;
  border-radius: 30px;
  background: #EFF3F4;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1.2em;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
`
export default PalletInput