import { AnyAction } from "redux"
import { ICarItem } from "../../models/Defaults/carList"
import { CLEAR_STATE_KOSHER, SELECT_CLIENT_COSHER, SELECT_DRIVER_KOSHER, SELECT_TEUDA_DATE, SELECT_TEUDA_TITLE } from "../actions/actionsTypes"
import { ITeudotItem } from "../../models/RequestsModel/teudot"


export interface IStateKosherTeudot {
    create: ICreate,
    title: ITeudotItem | null
    date: string | null | [string | null, string | null]
}
interface ICreate {
    clientID: number | null,
    clientName: string | null,
    carInfo: ICarItem | null,
    
}
const initialState: IStateKosherTeudot = {
    create: {
        clientID: null,
        clientName: null,
        carInfo: null
    },
    title: null,
    date: null
}

const kosherTeudotReducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_CLIENT_COSHER:
            return {
                ...state,
                create: {
                    ...state.create,
                    clientID: action.clientID,
                    clientName: action.clientName,
                }
            }
        case SELECT_TEUDA_TITLE:
            return {
                ...state,
                title: action.payload
            }    
        case SELECT_DRIVER_KOSHER:
            return {
                ...state,
                create: {
                    ...state.create,
                    carInfo: action.payload
                }
            }
        case SELECT_TEUDA_DATE:
            return {
                ...state,
                date: action.payload
            }    
        case CLEAR_STATE_KOSHER:
            return initialState
            
        default: return state
    }
}

export default kosherTeudotReducer