import { useRef } from 'react'
import styled from 'styled-components'

const Section = styled.section`
    background: url("/images/lowscale.jpg") center center;
    background-size: cover;
    height: 100vh;
    width: 100%; 
    position: relative;
    cursor: default;
    scroll-snap-align: start;
    @media screen and (max-width: 600px){
        background: url("/images/mobile.jpg") center center;
    }
`
const FilterScreen = styled.div`
    position: absolute;
    background: rgba(61,119,235,0.4);
    /* background: rgba(169,255,208, 0.2); */
    width: 100%;
    height: 100%;
    z-index: 0;
`
const OutPanelWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 6em;
    bottom: -3em;
    /* opacity: 0.5;
    background: red; */
    z-index: 3;
    padding: 0 0.5em;
`
const OutPanelInnerContainer = styled.div`
    height: 100%;
    max-width: 550px;
    background: #473a91;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    box-shadow: 4px 4px 4px 3px rgba(34, 60, 80, 0.3);
    ul {
        height: 100%;
        display: flex;
        max-width: 400px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
        li {
            width: 5em;
            /* background: red; */
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                color: #ffff;
                font-size: 3em;
                i {
                    transition: 0.3s ease;
                    :hover {
                        -webkit-filter: drop-shadow(0 0 0.75rem #80e081);
                        filter: drop-shadow(0 0 0.75rem #80e081);
                    }
                }
            }
            @media screen and (max-width: 500px){
                width: 4em;
            }
        }
    }

`

const Wrapper = styled.div`
    
    max-width: 1400px;
    height: 100%;
    /* background: yellow; */
    margin: 0 auto;
    padding: 0 1em;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: center;

`
const ContainerTitle = styled.div`
    z-index: 2;
    color: #fff;
    /* background: blue; */
    height: 50%;
    width: 600px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 10em;
    @media screen and (max-width: 600px){
        margin-top: 1em;
    }
    h1 {
        font-family: sans-serif;
        font-size: 4.5em;
        text-align: right;
        display:flex;
        flex-flow: column;
        justify-content: flex-end;
        max-width: 100vw;
        
        span{
            font-size: 1.3em; 
            background: rgba(255,255,255,0.5);
            padding: 0 0.2em;
            color: #000;
            opacity: 0.9;
            border-radius: 5px;
            max-width: 100vw;
            
        }
        p {
            font-size: 1.5em;
            margin-bottom: 0.2em;
            
        }
        @media screen and (max-width: 600px){
            font-size: 2em;
            padding-left: 0.7em;
            p {
                font-size: 1.2em;
                
            }
            span {
                
            }

        }
    }
`
const ContainerDiscription = styled.div`
    z-index: 2;
    /* background: blue; */
    height: 30%;
    width: 600px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    p {
        font-size: 1.5em;
        color: #fff;
        padding: 0.5em 1em;
        font-family: sans-serif;
    }
    a {
        border:none;
        background: #80e081;
        color: #fff;
        border-radius: 5px;
        margin-right: 1em;
        margin-top: 3em;
        padding: 1em 1.5em;
        font-size: 1.1em;
        outline: none;
        cursor: pointer;
        transition: 0.3s;
        @media screen and (max-width: 600px){
            padding: 1em 1em;
            font-size: 1em;
        }
        :hover{
            filter: contrast(1.5)
        }
    }
    @media screen and (max-width: 600px){
        p {
            font-size: 1em;
            
        }
    }
`

export default function ManageBlock(){

    const addedRefs = useRef(null)
    const refSection = useRef(null)
    // console.log(addedRefs.current? addedRefs.current.offsetBottom  : '12')
    // console.log( )
    window.addEventListener('scroll', () => {
        if (addedRefs.current !== null) {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            // sticky box
            // if (st > addedRefs.current.offsetTop ) {
            //     // console.log(refSection.current.offsetHeight)
            //     addedRefs.current.style.position = 'fixed';
            //     addedRefs.current.style.top = '0px'
            // }
            // if (st < refSection.current.offsetHeight && addedRefs.current.style.position === 'fixed') {
            //     // console.log(refSection.current.offsetHeight)
            //     addedRefs.current.style.position = 'absolute';
            //     addedRefs.current.style.top = refSection.current.offsetHeight - 40 + 'px';
            //     // addedRefs.current.style.bottom = '881px'
            // }
            if (st === 0){
                addedRefs.current.style.transform = 'translateY(100%)'
            }
            else if (st > 0) {
                addedRefs.current.style.transform = 'translateY(0)'
            
            }
        }
        
    })
    
    return(
        <Section ref={refSection}>
        <OutPanelWrapper ref={addedRefs} data-aos-once="true" data-aos="fade-up" data-aos-duration="500">
            <OutPanelInnerContainer>
                <ul>
                    <li><a href="https://www.facebook.com/MagicLineSoftware/"><i className="fab fa-facebook-square" alt="facebook"></i></a></li>
                    <li><a href="https://twitter.com/intent/follow?original_referer=http%3A%2F%2Fwww.magicline.co.il%2F&ref_src=twsrc%5Etfw&region=follow_link&screen_name=magicline_&tw_p=followbutton"><i className="fab fa-twitter-square" alt="twitter"></i></a></li>
                    <li><a href="https://wa.me/972522680167"><i className="fab fa-whatsapp-square" alt="whatsapp"></i></a></li>
                    <li><a href="skype:magic.line"><i className="fab fa-skype" alt="skype"></i></a></li>
                </ul>        
            </OutPanelInnerContainer>
        </OutPanelWrapper>
        <FilterScreen></FilterScreen>
            <Wrapper>
                <ContainerTitle>
                    <h1>מובילים את השינוי  <br/> <p>הדיגיטלי</p> <span>בשיווק תוצרת החקלאית</span></h1>
                </ContainerTitle>
                <ContainerDiscription>
                    <p>בעזרת הכלים המתקדמים שפותחו  במיוחד עבורכם </p>
                    <a href="#form">פרטים</a>
                </ContainerDiscription>
            </Wrapper>
        </Section>
    )
}