import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const ButtonLayout = ({
  children
}: Props) => {
  return (
    <ButtonLayoutWrapper>
      {children}
    </ButtonLayoutWrapper>
  )
}
const ButtonLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  padding: 0 0.5em;
`
export default ButtonLayout