import React, {useState, useMemo} from 'react'
import { IFreightCompanyItem } from '../../../../../../../modules/API/Defaults/models/freightCompany'
import SearchFromTheList from '../../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  list: IFreightCompanyItem[]
  defaultID: number
  setFreightCompanyID: (id: number) => void
  required?: boolean

}

const SearchFreightCompany = ({
  list, defaultID, setFreightCompanyID, required
}: Props) => {
  const defalutValue = list?.find(item => item.FC_ID === defaultID)
  const [searchValue, setSearchValue] = useState<string>(defalutValue?.FC_Name.trim() || '')
  const [selected, setSelected] = useState<IFreightCompanyItem | null>(defalutValue || null)


  const handleSelect = (item: IFreightCompanyItem) => {
    setSearchValue(item.FC_Name.trim())
    setSelected(item)
    setFreightCompanyID(item.FC_ID)
  }

  const handleSearch = (value: string) => {
    if(value === '') {
      setSelected(null)
      setFreightCompanyID(0)
      setSearchValue('')
      return
    }

    setSearchValue(value)
  }

  const filteredList = useMemo(() => {
    if(!list) return []
    return list.filter(item => item.FC_Name.includes(searchValue))
  }, [list, searchValue])

  return (
    <SearchFromTheList 
      list={filteredList}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      input={searchValue}
      itemDisplayName='FC_Name'
      nameIdElement='FC_ID'
      isNeedFullObject
      size='full'
      placeHolder=' חיפוש חברת הובלה '
      // defaultValue={selectedID}
      required={required}
    
    />
  )
}

export default SearchFreightCompany