import React, {useState} from 'react'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead'
import { IArizotLine } from '../../../../../modules/API/Packages/models/arizotLine'
import { IOrderPackageTitle } from '../../../../../modules/API/Packages/models/createPackageOrderModel'
import Image from '../../../../../generic/assets/package.png'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ArizotItem from '../View/ArizotItem'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { ControlButtonWrapper } from '../../secondbar/modalAzmanot/buttons/ButtonStyle'
import { ControlPanelWrapper } from '../../secondbar/modalAzmanot/modalAzmanot'
import CheckBoxWithTitle from '../../../../../generic/particles/CheckBox'
import { useTranslation } from 'react-i18next'

type Props = {
    title: IOrderPackageTitle,
    list: IArizotLine[],
    saveFunc: (sendMail: boolean) => void,
    editFunc: (qty: number, id: string) => void
}

const TeudotModalBody = ({title, list, saveFunc, editFunc}: Props) => {
    const [isSendEmail, setIsSendEmail] = useState(true)
    const [t] = useTranslation(['packageOrder', 'buttons'])
    const itemProps = {
        updateInput: editFunc,
        isActive: true,
        isForTeuda: true
    }
    const handleSave = () => {
        saveFunc(isSendEmail)
    }
  return (
    <MainWrapper>
        <ImageContainer>
            <ImageComponentHead 
                img={Image}
                large
            />
        </ImageContainer>
        <BodyWrapper>
            <TitlesList 
                list={list}
                uniqueKey={'id'}
                Element={ArizotItem}
                additionalData={itemProps}
                noPadding={false}
            />
        </BodyWrapper>
        <ControlPanelWrapper>
            <CheckBoxWithTitle 
                stateFunc={setIsSendEmail}
                title={t('packageOrder_modal_teuda_checkbox')}
                defaultState={isSendEmail}
            />
        </ControlPanelWrapper>
            <ControlButton
                lable={t('buttons:save')}
                handleClick={handleSave}
            />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    /* background: red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    justify-content: flex-start;
`
const ImageContainer = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const BodyWrapper = styled.div`
    width: 100%;
    height: 45%;
    overflow-y: auto;

    /* background: red; */
`
export default TeudotModalBody