import React, {useState, useMemo} from 'react'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'
import { IReportListItem } from '../../../../../../modules/API/Reports/models/reportList'

type Props = {
  list: IReportListItem[]
  defaultID: number
  setReportID: (id: number) => void
  required?: boolean

}

const SearchReport = ({
  defaultID, setReportID, required, list
}: Props) => {
  const defRep = list.find((item: IReportListItem) => item.RepID === defaultID)
  const [inputReport, setInputReport] = useState<string>(defRep?.Rep_Name.trim() || "")
  const [selectedReport, setSelectedReport] = useState<IReportListItem | null>(() => defRep || null)
  const [isOpenListReport, setIsOpenListReport] = useState<boolean>(false)

  const handleSearchReport = (e: string) => {
    setIsOpenListReport(true)
    setInputReport(e)
    if (!e) {
      setSelectedReport(null)
    }
  }
  const handleSelectReport = (item: IReportListItem) => {
    setSelectedReport(item)
    setInputReport(item.Rep_Name.trim())
    setIsOpenListReport(false)
    setReportID(item.RepID)
  }
  const renderReportList = useMemo(() => {
    if (!list) return []
    return list.filter((item: IReportListItem) => {
      return item.Rep_Name.trim().toLowerCase().includes(inputReport.toLowerCase().trim())
    })
  }, [list, inputReport])
  return (
    <SearchFromTheList
          input={inputReport}
          list={renderReportList}
          itemDisplayName='Rep_Name'
          nameIdElement='RepID'
          isNeedFullObject
          placeHolder={'חיפוש דוח'}
          searchFunc={handleSearchReport}
          selectFunc={handleSelectReport}
          isOpenList={isOpenListReport}
          size={'full'}
          required={!selectedReport}

        />
  )
}

export default SearchReport