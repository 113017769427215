import React from 'react'
import styled from 'styled-components'
import { EXIST_PATH } from '../../../../../../modules/API/settings'

type Props = {
    id: number;
    size?: "md" | "lg" | "sm";
}

const ProductImage = ({id, size="md"}: Props) => {

    const img = id !== 0 ? 
        `${EXIST_PATH}/images/products/${id}.png` 
            : '/emptyBox.png'

  return (
    <Image 
        src={img} 
        alt='prod'
        size={size}
    />
  )
}

const Image = styled.img<{size: "md" | "lg" | "sm"}>`
    width: ${props => props.size === "md" ? '100%' : props.size === "sm" ? '90px' : '110%'};
`
export default ProductImage