import styled from 'styled-components';
import GoodsItem from './goodsItem'
export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: max-content;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    align-content: flex-start;
    gap: 0.7em;
    /* background: red; */
    ::-webkit-scrollbar {
        width: 0;
    }
`
const Overlay = styled.div`
    position: absolute;
    background: rgba(0,0,0, 0.05);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 99;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    img {
        min-width: 330px;
    }
`
export const TextWrapper = styled.div`
    padding: 1em;
    border-radius: 30px;
    background: ${props => props.main ? '#eff3f4' : '#fff'};
    color: #7d7b79;
`
export default function GoodsList({goodsList, setProduct, product, chengeAkpaca, packegeList,
     goodsListDefault, setProductDefault, getMlai, getMhir, presetWindow, filterClose, collectDataProduct,filterGoodsValue,
     dateForAmlaca, setOrderTotal, orderTotal, isEditArizot, allowCreateOrder, isEditPrice, getGoodPrice,
     isLoadPrice
    
    }) {
    return(
        <Wrapper >
            {
                !allowCreateOrder?.isAllow &&
                    <Overlay>
                        <TextWrapper>
                            ניתן לבצע הזמנה עד - {allowCreateOrder?.expiredTime}
                        </TextWrapper>
                    </Overlay>
            }
            {
            goodsList.map(item => <GoodsItem key={item.id}{...item}
              setProduct={setProduct} 
              presetWindow={presetWindow}
              setProductDefault={setProductDefault}
              goodsListDefault={goodsListDefault}
              product={product} chengeAkpaca={chengeAkpaca}
              goodsList={goodsList}
              getMlai={getMlai}
              getMhir={getMhir}
              packegeList={packegeList}
              filterClose={filterClose}    
              collectDataProduct={collectDataProduct}    
              filterGoodsValue={filterGoodsValue}
              dateForAmlaca={dateForAmlaca}
              setOrderTotal={setOrderTotal}
              orderTotal={orderTotal}
              isEditArizot={isEditArizot}
              isEditPrice={isEditPrice}
              item={item}
              getGoodPrice={getGoodPrice}
              isLoadPrice={isLoadPrice}
            />)}
            
        </Wrapper>
    );
}