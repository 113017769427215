import React, {useState, useMemo, useEffect} from 'react'
import { IVarietyListItem } from '../../../../../../modules/API/Defaults/models/varietyList'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  list: IVarietyListItem[],
  defaultID: number,
  setID: React.Dispatch<React.SetStateAction<number>>,
  required?: boolean,
  
}

const SearchVaiety = ({
  list, defaultID, setID, required

}: Props) => {
  const defaultVariety = list?.filter(item => 
    item.VarietyID === defaultID
  )[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedVariety, setSelectedVariety] = useState<IVarietyListItem | null>(defaultVariety || null)
  const [search, setSearch] = useState<string>(defaultVariety?.VarietyDesc?.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedVariety(null)
      setID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IVarietyListItem) => {
    setID(item.VarietyID)
    setSearch(`${item.VarietyDesc.trim()}`)
    setSelectedVariety(item)
    setIsOpenDropDown(false)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      `${item.VarietyDesc?.trim()}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [list, search])

  useEffect(() => {
    setSearch(defaultVariety?.VarietyDesc?.trim() || '')
  }, [defaultVariety])
  
  return (
    <SearchFromTheList 
      input={search}
      list={render}
      isOpenList={isOpenDropDown}
      placeHolder={'בחר זן'}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      itemDisplayName='VarietyDesc'
      nameIdElement='VarietyID'
      required={required}
      size={'full'}
    />
  )
}

export default SearchVaiety

// REFERENCE:

/*
import React, {useState, useMemo} from 'react'
import { IProductsWithVarietyItem } from '../../../../../../modules/API/Defaults/models/compliteProducts'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  list: IProductsWithVarietyItem[]
  defaultProductID: number
  defaultVarietyID: number
  setProductID: React.Dispatch<React.SetStateAction<number>>
  setVarietyID: React.Dispatch<React.SetStateAction<number>>
  required?: boolean
}

const SearchProductWithVariety = ({
  defaultProductID, defaultVarietyID, list, 
  setProductID, setVarietyID, required
}: Props) => {
  const defaultProduct = list?.filter(item => 
    item.ProductID === defaultProductID 
      && item.VarietyID === defaultVarietyID
  )[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<IProductsWithVarietyItem | null>(defaultProduct || null)
  const [search, setSearch] = useState<string>(`${defaultProduct?.ProductDesc?.trim() || ''} ${defaultProduct?.VarietyDesc?.trim() || ''}`.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedProduct(null)
      setProductID(0)
      setVarietyID(0)
      setIsOpenDropDown(false)
      setSearch('')
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IProductsWithVarietyItem) => {
    setProductID(item.ProductID)
    setVarietyID(item.VarietyID)
    setSearch(`${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`)
    setSelectedProduct(item)
    setIsOpenDropDown(false)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      `${item.ProductDesc?.trim()} ${item.VarietyDesc?.trim()}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [list, search])
  return (
    <SearchFromTheList 
      input={search}
      list={render}
      isOpenList={isOpenDropDown}
      placeHolder={'חפש פריט'}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      itemDisplayName='ProductDesc'
      itemSecondaryName={'VarietyDesc'}
      nameIdElement='ProductID'
      required={required}
      size={'full'}
    />
  )
}

export default SearchProductWithVariety
*/