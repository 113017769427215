import styled from "styled-components";
import DatePicker from "react-datepicker";
import ArizotCarList from './arizotCar/arizotCarList'
import ArizotClientList from './arizotClient/arizotClientList'
import ArizotSenderList from './arizotSender/arizotSenderList'
import { useState } from "react";
import SearchInput from "../../../../../generic/inputs/inputSearch";
import { SwapIcon } from "../../../../../generic/icons/Icons";
import CheckBoxWithTitle from "../../../../../generic/particles/CheckBox";
import { useTranslation } from "react-i18next";

export const Wrapper = styled.div`
    /* background: blue; */
    transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1); 
    min-width: 100%;
    height: 100%;
    border-radius: 30px;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
    @media screen and (min-width: 600px){
        min-width: 50%;
        /* padding: 1em 0 5em; */
    }
`
export const Container = styled.div`
    height: 100%;
    width: 100%;
    background: rgba(255,255,255, 0.8);
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 2em;
    @media screen and (min-width: 600px){
        
    }
`

export const ItemContainer = styled.div`
    position: relative;
    height: 5em;
    width: 15em;
    /* background: red; */
    display: flex;
    flex-flow: column;

`

export const TitleContainer = styled.div`
    height: 40%;
    width: 100%;
    /* background: blue; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1em;
    color: #83848c;
    font-weight: 600;
`

export const InputContainer = styled.div`
    /* position: relative; */
    height: 80%;
    width: 100%;
    background: #e9f0f0;
    padding: 0.2em;
    border-radius: 30px;
    display: flex;
    align-items: center;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
    input {
        /* font-size: 1.1em; */
        width: 100%;
        min-height: 100%;
        border-radius: 30px;
        background: #fdfdfd;
        color: #83848c;
    }
`
export const InputDateContainer = styled(InputContainer)`
        height: 85%;
    .arizot-date {
        border: none;
        width: 14.5em;
        height: 3.2em;
        /* border: 2px solid #80e081; */
        border-radius: 30px;
        appearance: none;
        outline: none;
        text-align: right;
        padding: 0 1em;
        font-size: 1em;
        color: #5f5353;
        z-index: 1;
    }

`
const ControlInputButton = styled.div`
    width: 3em;
    height: 100%;
    position: absolute;
    /* top: 0.5em; */
    /* background: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #83848c;
    
`
const Switcher = styled.div`
    position: absolute;
    right: -2.5em;
    bottom: 0.6em;
    /* padding: 0.5em; */
    width: 2.5em;
    height: 2.5em;
    display: ${props => props.isShow ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    background: tomato;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;

`



export default function ArizotAddHead({ riderList, setRiderList, riderListDefault, inputRider, setInputRide, riderFilter, wrapperRiderStatus, setWrapperRiderStatus,

    startDate, setStartDate, // DATE

    inputClient, setInputClient, sender, setSender, clientFilter, wrapperClientStatus, setWrapperClientStatus,
    senderDefault, inputSender, setInputSender, wrapperSenderStatus, setWrapperSenderStatus, senderFilter, //CLIENTS

    inputRef, setInputRef, refFilter, //REF
    sideToggle,
    //DATA COLLECTION
    setClientIdRecip, setClientIdSender, setCarId, setCarNumber, setDriverName, setReference,

    //other
    rightsToggle, switcher, isDisabled, isNeedToTransfer, handleTransferCheckbox

}) {
    const [t] = useTranslation(['packageOrder', 'buttons'])
    const [buttonSwitcher, setButtonSwitcher] = useState(false)
    let btnVisibilityToggle = !rightsToggle ? 'flex' : 'none';
    let btnRiderToggle = inputRider.length > 0 ? <i onClick={() => {
        setInputRide(''); setRiderList(riderListDefault);
        setWrapperRiderStatus(true); setCarId(null); setCarNumber(null); setDriverName('')
    }}
        className="fas fa-times"></i> :
        <i className="fas fa-search"></i>
    let btnClientToggle = inputClient.length > 0 ? <i onClick={() => { setInputClient(''); setSender(senderDefault); setWrapperClientStatus(true); setClientIdRecip(null) }}
        className="fas fa-times"></i> :
        <i className="fas fa-search"></i>
    let btnSenderToggle = inputSender.length > 0 ? <i onClick={() => { setInputSender(''); setSender(senderDefault); setWrapperSenderStatus(true); setClientIdSender(null) }}
        className="fas fa-times"></i> :
        <i className="fas fa-search"></i>
    let btnRefToggle = inputRef.length > 0 ? <i onClick={() => { setInputRef('') }}
        className="fas fa-times"></i> :
        <i className="fas fa-search"></i>
    return (
        <Wrapper style={{ transform: sideToggle }}>
            <Container>
                <ItemContainer>
                    <TitleContainer >
                        {t('packageOrder_NewOrder_lable_date')}
                    </TitleContainer>
                    <InputDateContainer>
                        <ControlInputButton style={{ zIndex: '2' }}><i className="far fa-calendar-alt"></i></ControlInputButton>
                        <DatePicker
                            className="arizot-date"
                            selected={startDate}
                            onChange={(date) => {
                                const start = date
                                setStartDate(start)
                            }}
                            startDate={startDate}
                            dateFormat="dd/MM/yyy"
                        />
                    </InputDateContainer>
                </ItemContainer>
                <ItemContainer>
                    <TitleContainer>
                        {t('packageOrder_NewOrder_lable_sender')}
                    </TitleContainer>
                    <InputContainer>
                        {/* <ControlInputButton style={{display:btnVisibilityToggle}}>{btnSenderToggle}</ControlInputButton> */}
                        <SearchInput
                            value={t('packageOrder_NewOrder_placeholder_sender')}
                            input={inputSender}
                            setInput={senderFilter}
                            disabled={isDisabled.sender}

                        />
                        <ArizotSenderList
                            sender={sender}
                            wrapperSenderStatus={wrapperSenderStatus}
                            setWrapperSenderStatus={setWrapperSenderStatus}
                            setInputSender={setInputSender}
                            setClientIdSender={setClientIdSender}
                        />
                        <Switcher isShow={rightsToggle} onClick={switcher}>
                            <SwapIcon />
                        </Switcher>
                    </InputContainer>
                </ItemContainer>
                <ItemContainer>
                    <TitleContainer>
                        {t('packageOrder_NewOrder_lable_receipient')}
                    </TitleContainer>
                    <InputContainer>
                        <ControlInputButton style={{ display: btnVisibilityToggle }}>{btnClientToggle}</ControlInputButton>
                        <SearchInput
                            value={t('packageOrder_NewOrder_placeholder_receipient')}
                            input={inputClient}
                            setInput={clientFilter}
                            disabled={isDisabled.reciep}
                        />
                        <ArizotClientList
                            sender={sender}
                            setInputClient={setInputClient}
                            wrapperClientStatus={wrapperClientStatus}
                            setWrapperClientStatus={setWrapperClientStatus}
                            setClientIdRecip={setClientIdRecip}
                        />
                    </InputContainer>
                </ItemContainer>
                <ItemContainer>
                    <TitleContainer>
                        {t('packageOrder_NewOrder_lable_car')}
                    </TitleContainer>
                    <InputContainer>
                        <ControlInputButton>{btnRiderToggle}</ControlInputButton>
                        <SearchInput value={t('packageOrder_NewOrder_placeholder_car')} input={inputRider} setInput={riderFilter} />
                        <ArizotCarList riderList={riderList} setInputRide={setInputRide} inputRider={inputRider}
                            wrapperRiderStatus={wrapperRiderStatus} setWrapperRiderStatus={setWrapperRiderStatus}
                            setCarId={setCarId} setCarNumber={setCarNumber} setDriverName={setDriverName}
                        />
                    </InputContainer>
                </ItemContainer>
                <ItemContainer>
                    <TitleContainer>
                        {t('packageOrder_NewOrder_lable_refference')}
                    </TitleContainer>
                    <InputContainer>
                        <ControlInputButton>{btnRefToggle}</ControlInputButton>
                        <SearchInput value={t('packageOrder_NewOrder_lable_refference')} input={inputRef} setInput={refFilter} />
                    </InputContainer>
                </ItemContainer>
                <ItemContainer>
                    <CheckBoxWithTitle
                        title={t('packageOrder_NewOrder_checkbox')}
                        defaultState={isNeedToTransfer}
                        stateFunc={handleTransferCheckbox}
                    />
                </ItemContainer>
            </Container>
        </Wrapper>
    );
}