import React, {useState, useMemo} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IClientBillsWithDetails } from '../../../../../../modules/API/Bills/models/clientBills'
import { Bills } from '../../../../../../modules/API/Bills/bills'
import useDataFetcher from '../../../knisot/hooks/useFetchData'
import OrderlikeLayout from '../../../../../../generic/Layouts/OrderlikeLayout'
import ClientBillTitleItem from './ClientBillTitleItem'
import CustomBlockLoader from '../../../knisot/generic/CustomBlockLoader'
import BodyScrollContainer from '../../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../../generic/titlesRowItem/TitlesList'
import ClientBillDetailsItem from './ClientBillDetailsItem'
import SearchInput from '../../../../../../generic/inputs/inputSearch'
import ControlSearchPannelLayout from '../../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
import { LINKS } from '../../../../../../Routs/config'

type Props = {
    backUrl: string;
    getByID?: (reqString: string) => Promise<any>;
}

const ClientBillDetailsMain = ({backUrl, getByID}: Props) => {
    const { id } = useParams<{ id: string }>()
    const [input, setInput] = useState<string>('')
    const history = useHistory();
    const { data, isLoading } = useDataFetcher<IClientBillsWithDetails>(
        getByID ? getByID : Bills.getBillDetailsByID, id
    )
    const render = useMemo(() => {
        if (!data) return []
        return data.Lines.filter(item => 
            item.ProductDesc?.trim().toLowerCase().includes(input) ||
                item.VarietyDesc?.trim().toLowerCase().includes(input) ||
                    item.SizeDesc?.trim().toLowerCase().includes(input) 
        )
    }, [data, input])

    const handleBack = () => {
        history.push(backUrl)
    }
    return (
        <OrderlikeLayout label={''}>
            {
                data ? <ClientBillTitleItem isDisableCOntroll {...data} /> :
                    null
            }
            <ControlSearchPannelLayout>
                <SearchInput 
                    input={input}
                    setInput={setInput}
                />
            </ControlSearchPannelLayout>
            <BodyScrollContainer>
                {
                    isLoading ? <CustomBlockLoader /> :
                    <TitlesList 
                        list={render}
                        uniqueKey={'RecID'}
                        noPadding={false}
                        Element={ClientBillDetailsItem}
                    />
                }
            </BodyScrollContainer>
            <ControlButton lable={'חזרה'} handleClick={handleBack} />
        </OrderlikeLayout>
    )
}

export default ClientBillDetailsMain