import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import React, { useState } from 'react'
import { DeleteIcon } from '../../../../../../generic/icons/Icons';
import { DetalisContainer, DetalisInnerItem, DetalisItem, EmptyGoodContainer, ItemContainer, SwipeWrapper, TitleContainer } from '../LikutSectionGoodsItem'
import SwipeRight from '../swipe/SwipeRight'
import { useTranslation } from 'react-i18next'

export default function LikutSectionExistGoodsItem({
    ProductDesc, Quantity, Pack_Name, WeightNeto, VarietyDesc, deleteFromPallet, RecID,
    likutWithoutAzmana, withoutOpacity, item, isWeightCalculation
}) {
    const [t] = useTranslation(['likut']);
    const [recID, setRecID] = useState(null);
    const [isOpenModalForEmptyGood, setIsOpenModalForEmptyGood] = useState(false);
    return (
        <SwipeWrapper style={{width: '100%'}}>
            <SwipeableListItem
                swipeRight={{
                    content: <SwipeRight disable={isOpenModalForEmptyGood} label={<DeleteIcon />} />,
                    action: () => {
                        console.log('swiped')
                        setRecID(RecID);
                        setIsOpenModalForEmptyGood(!isOpenModalForEmptyGood)
                    }
                }}
                threshold={0.35}
                // blockSwipe={!likutWithoutAzmana}
            >
                <EmptyGoodContainer 
                    onClick={() => {
                        deleteFromPallet(RecID, item);
                        // editOrderRow(3, 3);
                    }}
                    primal={recID === RecID && isOpenModalForEmptyGood}
                >
                    <DeleteIcon />
                </EmptyGoodContainer>
                <ItemContainer 
                    style={{
                            opacity: withoutOpacity ? '1' : '0.5', 
                            userSelect: 'none',
                            borderRadius: isOpenModalForEmptyGood ? '0 30px 30px 0': '30px'
                        }}
                >
                    <TitleContainer>
                        {ProductDesc?.trim()} {VarietyDesc?.trim()} {Pack_Name?.trim()} 
                    </TitleContainer>
                    <DetalisContainer>
                        <DetalisItem>
                            <DetalisInnerItem primal>
                                {t('likut_sectionmain_pallet_product_weight')}
                            </DetalisInnerItem>
                            <DetalisInnerItem>
                                {WeightNeto}
                            </DetalisInnerItem>
                        </DetalisItem>
                        <DetalisItem>
                            <DetalisInnerItem primal>
                                {t('likut_sectionmain_pallet_product_qty')}
                            </DetalisInnerItem>
                            <DetalisInnerItem>
                                {Quantity}
                            </DetalisInnerItem>
                        </DetalisItem>
                    </DetalisContainer>
                </ItemContainer>
            </SwipeableListItem>
        </SwipeWrapper>
    )
}
