import React from 'react'
import styled from 'styled-components'
import { ArrowIcon } from '../../../../../generic/icons/Icons'
import PropTypes from 'prop-types'

const MainWrapper = styled.div`
    position: relative;
    background: #eff3f4;
    display: ${props => props.open ? 'flex' : 'none'};
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.5em;
    border-radius: 0 0 30px 30px;
    gap: 0.4em;
`

const IconWrapper = styled.div`
    position: absolute;
    top: -1em;
    padding: 0.2em 0.5em;
    border-top: 2px solid #eff3f4;
`

const BodyContainer = styled.div`
    width: 100%;
    padding: 0.3em 0.5em 0.3em 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
`

const Item = styled.div`
    min-width: 2em;
`

export default function ProductsList({ list, reson }) {
    
    const listFlag = list.length > 1 || reson === 5 ;
    return (
        <MainWrapper open={listFlag}>
            <IconWrapper>
                <ArrowIcon position={'bottom'} />
            </IconWrapper>
            {
                list?.map((item, index) =>
                    <BodyContainer key={index}>
                        <Item>{item?.Qty}</Item>
                        <Item>{item?.Product?.trim()}</Item>
                    </BodyContainer>
                )
            }
        </MainWrapper>
    )
}

ProductsList.propTypes = {
    list: PropTypes.array,
    reson: PropTypes.number
}
