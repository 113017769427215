import React, {useState, useEffect} from 'react'
import ReceiptDetaileItem from '../../Edit/ReceiptDetaileItem'
import { IPaymentDetailesListItem } from '../ReceiptCreate'
import { IRecieptItemDetailes } from '../../../../../../../modules/API/Reciept/models/receipt'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import styled from 'styled-components'
import SwipeContent from '../../../../knisot/Components/SwipeContent'
import { DeleteIcon } from '../../../../../../../generic/icons/Icons'

interface Props extends IPaymentDetailesListItem {
  index: number,
  deleteLine: (index: number) => void
}

const ReceipAddLine = ({
  PaymentType,
  BankId,
  Sum,
  Branch,
  Account,
  Cheque,
  index,
  deleteLine

}: Props) => {
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false)
  const [isShowLine, setIsShowLine] = useState<boolean>(false)
  const handleSwipe = () => {
    setIsShowDelete(true)
  }
  const handleSwipeEnd = () => {
    setIsShowDelete(false)
  }
  const handleDelete = () => {
    setIsShowLine(false)
    setTimeout(() => {
      deleteLine(index)

    }, 300)
  }
  const dataProps: IRecieptItemDetailes = {
    Account: Account,
    BankCode: BankId,
    BankCommision: 0,
    BankName: "",
    Branch: Branch,
    Canceled: false,
    Cheque: Cheque,
    Comments: "",
    Company: 0,
    Currency: "",
    CurrencySum: 0,
    DepositNum: 0,
    DepositTerminalNum: 0,
    DepositType: "",
    Deposited: "",
    InvoiceProduced: false,
    PaymentType: PaymentType,
    RecID: 0,
    RecieptNum: 0,
    Sum: Sum,
    TransferNum: 0,
    ValueDate: ""
  }
  useEffect(() => {
    setIsShowLine(true)
  
  }, [])
  return (
    <MainWrapper isShow={isShowLine}>
    <SwipeableListItem
      swipeRight={{
        content: <SwipeContent text={'מחק'} isPrimal />,
        action: handleSwipe
      }}
      threshold={0.4}
      onSwipeStart={handleSwipeEnd}
      // onSwipeEnd={}
    >
      <MainWrapper isShow={isShowLine}>
        {
         isShowDelete &&
            <DeleteButton onClick={handleDelete}>
              <DeleteIcon />
            </DeleteButton>
        }
        <ReceiptDetaileItem
          {...dataProps}
          />  
        </MainWrapper>
    </SwipeableListItem>
      </MainWrapper>
  )
}
const MainWrapper = styled.div<{isShow: boolean}>`
  padding: 0.4em;
  width: 100%;
  border-radius: 30px;
  display: flex;
  transition: all 0.3s ease-in-out;
  transform: ${props => !props.isShow ? 'translateX(-100%)' : 'translate(0)'};
`
const DeleteButton = styled.div`
  position: absolute;
  z-index: 2;
  height: 2.3em;
  width: 3em;
  background-color: tomato;
  border-radius: 30px 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer; 
`
export default ReceipAddLine