import React from 'react'
import styled from 'styled-components';

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

const BodyColumnLayout = ({
    children
}: Props) => {
  return (
    <MainWrapper>
        <InnerContainer>
            {children}
        </InnerContainer>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
`
const InnerContainer = styled.div`
    max-width: 500px;
    height: 100%;
    background: blue;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 1em;
    padding: 1em;
    border-radius: 30px;
    background: #fff;
    -webkit-filter: drop-shadow(2px 1px 2px rgba(157,157,157, 0.2));
    filter: drop-shadow(2px 1px 2px rgba(157,157,157, 0.2));
`
export default BodyColumnLayout