import { ForcastProductItem } from "../../../../../../modules/API/Forcasts/models/forcastProductitem";
import { IRecommendationItem } from "../../../../../../modules/API/Recommendation/models/recomendationItem";
import { IRecommendationModifiedItem } from "../types/recommendationModified";

export const createForecastItemFromRec = (
  rec: IRecommendationModifiedItem,
  growerID: number
  
  ): ForcastProductItem => {
  const data = {
    ActualAmnt: 0,
    ActualPallets: 0,
    ActualWeight: 0,
    Collection_Date: "",
    Comments: "",
    CustomerID: 0,
    Customer_Name: "",
    ForecastAmnt: rec.amount,
    ForecastPallets: rec.pallets,
    ForecastWieght: rec.weight,
    GrowerID: growerID,
    GrowerName: rec.GrowerName,
    MarketingArea: 0,
    OrderId: 0,
    Order_Type: 0,
    PackID: rec.PackID,
    Pack_Name: rec.Pack_Name,
    ProductDesc: rec.ProductDesc,
    ProductID: rec.ProductID,
    RecID: 0,
    Rev_Price: 0,
    Season: 0,
    ShipmentType: 0,
    SizeDesc: rec.SizeDesc,
    SizeID: rec.SizeID,
    Status: 0,
    TransferZone: 0,
    VarietyDesc: rec.VarietyDesc,
    VarietyID: rec.VarietyID,
    GeneralProductID: rec.GeneralProductID
  }

  return data;
}