import React from 'react'
import { ISupplyDataGrowerItem } from '../../../../../modules/API/Forcasts/models/seller'
import styled from 'styled-components'
import BodyHorizontalScrollLayout from './generic/BodyHorizontalScrollLayout'
import SideBarProductListTayout from './generic/SideBarProductListTayout'
import GrowerItem from './Components/GrowerItem'

type Props = {
  list: ISupplyDataGrowerItem[]
  setList: (list: ISupplyDataGrowerItem[]) => void
}

const GrowerBody = ({
  list,
  setList
}: Props) => {
  return (
    <MainWrapper>
      <BodyHorizontalScrollLayout>
        {
          list.map((item, index) => (
            <GrowerItem 
              key={item.Grower.GrowerID}
              item={item}
              {...item}
            />
          ))
        }
      </BodyHorizontalScrollLayout>
      <SideBarProductListTayout>
        <>
        </>
      </SideBarProductListTayout>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5em;
  z-index: 1;
  border-radius: 30px;
  overflow-y: hidden;

`
export default GrowerBody