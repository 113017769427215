import React from 'react'
import './mainnav.css'
import { Link } from 'react-router-dom'

function MainNav({color}){
    return(
        <nav className="header-nav" id='navHeader' >
            <ul className="header-nav__list" >
                <li key={1}  className="header-nav__list-item"><a style={{color: color}} href="#form" className="nav-links">כתוב לנו</a></li>
                <li key={2} className="header-nav__list-item" >
                    <a  style={{color: color}}
                     href="#performance"
                     className="nav-links">
                        מודולים
                    </a></li>
                <li key={3} className="header-nav__list-item"><a  style={{color: color}} href="#mainFooter" className="nav-links">אודות</a></li>
            </ul>
        </nav>
    )

}

export default MainNav;