export const PACKAGE_MOVEMENTS_TITLE = [
//   PackID
// : 
// 1
// Quantity
// : 
// 120
// RecID
// : 
// 11220872
// RecieverID
// : 
// 1
// RecieverName
// : 
// "1"
// SenderID
// : 
// 262
// SenderName
// : 
// "262"
// Type
// : 
// "א"
// ValueDate
// : 
// "20220608"
  "תאריך",
  "שולח",
  "מקבל",
  "סוּג",
  "כמות",
]