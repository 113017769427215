import React, { useRef, useState } from 'react'
import './header.css'
import Button from './button/button'
import MainLogo from './mainlogo/mainlogo'
import MainNav from './mainnav/mainnav'
import LogOutBtn from './logoutBtn/logoutBtn'
import {useSelector} from 'react-redux'
// import { useState } from 'react/cjs/react.development'
// import doLogout from './logoutBtn/logoutBtn'

function HeaderMain(){
    const [color, setColor] = useState(true)
    // const modal = document.getElementById('modalEditAzmanot')
    const authCheck = useSelector(authState => authState.mainReducer.isAuthenticated);
    const header = useRef(null)
    let setupBlock = authCheck === true ? [ <Button key={'1'} />]  : <Button />
    //hide/show header when scroll
    let lastScrollTop = 0;

        // window.addEventListener("scroll", function(){  
        // let st = window.pageYOffset || document.documentElement.scrollTop;  
        // if (document.getElementById("header") !== null){
        //     if (st > lastScrollTop){
        //         document.getElementById("header").style.top = "-100%";
        //     } else {
        //         document.getElementById("header").style.top = "0";
        //     }
        // }   
        //     lastScrollTop = st;
        // }, false);
        window.addEventListener("scroll", function(){  
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (header.current !== null){
                if(st > 1600) {
                    // console.log(window.pageYOffset)
                    header.current.style.background = '#fff'
                    setColor(false)
                } else {
                    header.current.style.background = 'none'
                    setColor(true)
                }
            }
            }, false);
        
    let handlerColor = color ? '#ffff' : '#573d16'
    let handlerLogo = color ? '#80e081' : '#573d16';
    let handlerSpan = color ? '#ffff' : '#80e081';
    return  (
            <header className="header" id="header" ref={header}>
                <div className="header-container">
                    {/* <button onClick={()=> {console.log( document.getElementById('navHeader').style.color)}}>asd</button> */}
                    <MainLogo handlerLogo={handlerLogo} handlerSpan={handlerSpan} />
                    <MainNav color={handlerColor}/>
                    {setupBlock}
                    
                </div>
            </header>  
    );
}


export default HeaderMain