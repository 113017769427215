import React from 'react'
import { IReportQ8Response } from '../../../../../../../modules/API/Reports/models/q8'
import TableBody from '../../Layouts/Table/TableBody'
import TableRow from '../../Layouts/Table/TableRow'
import TableBodyCell from '../../Layouts/Table/TableBodyCell'
import { PackageIcon, PalletIcon } from '../../../../../../../generic/icons/Icons'

type Props = {
    list: IReportQ8Response[]
}

const Q8Table = ({
    list
}: Props) => {
  return (
    <TableBody>
        {
            list.map((item, index) => 
                <TableRow key={index}>
                    <TableBodyCell>{item.ClientName?.trim()}</TableBodyCell>
                    <TableBodyCell>
                        <>
                            {item.Description?.trim()}
                            {
                                item.PD_Type?.trim() === 'א' ?
                                    <PackageIcon />:
                                        <PalletIcon />
                            }
                        </>
                    </TableBodyCell>
                    {/* <TableBodyCell>{item.PD_Type}</TableBodyCell> */}
                    <TableBodyCell>{item.QuantityBefore}</TableBodyCell>
                    <TableBodyCell>{item.QuantityDebit}</TableBodyCell>
                    <TableBodyCell>{item.QuantityCredit}</TableBodyCell>
                    <TableBodyCell>{item.QuantityPeriod}</TableBodyCell>
                    <TableBodyCell>{item.QuantityAfter}</TableBodyCell>

                </TableRow>
            )
        }   
    </TableBody>
  )
}

export default Q8Table