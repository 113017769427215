const likutResons = {
  "likut_reasons_0": "",
  "likut_reasons_1": "weight mismatch",
  "likut_reasons_2": "manager confirmation",
  "likut_reasons_3": "out of stock",
  "likut_reasons_4": "pallet split",
  "likut_reasons_5": "product replacement",
  "likut_reasons_6": "complete"

}

const likutProblebsVarietions = {
  "likut_problem_tag_notExistInAzmana": "product not in order",
  "likut_problem_tag_moreThenNeed": "quantity higher than order",
  "likut_problem_tag_lessThenNeed": "quantity lower than order",
  "likut_problem_variations_1": "weight match",
  "likut_problem_variations_2": "manager confirmation",
  "likut_problem_variations_3": "out of stock",
  "likut_problem_variations_4": "pallet split",
  "likut_problem_variations_5": "product replacement",
  "likut_problem_variations_6": "complete"

}

export const likutTranslationEN = {
  likut: {
    "likut_orders_search_placeholder": 'order search',
    "likut_pallets_title": "Pallets",
    "likut_pallets_button_save": "Finish gathering",
    "likut_pallets_button_new": "New pallet",
    "likut_modal_sium_likut_title": "Order summary",
    "likut_modal_printers_printer": "Select printer",
    "likut_modal_printers_format": "Print format",
    "likut_sectionmain_title_new": "New pallet",
    "likut_sectionmain_title_withpallet": "Pallet: {{pallet}}",
    "likut_modal_remarks_title": "Remark",
    "likut_sectionmain_inpallet_title": "In Pallet",
    "likut_sectionmain_product_swiperight_text": 'Problem?',
    "likut_sectionmain_product_swiperight_button": 'Out of stock',
    "likut_sectionmain_product_swipeleft_text": 'No scan',
    "likut_sectionmain_product_rest_packages_text": ':rest packages',
    "likut_sectionmain_product_rest_text": ':rest',
    "likut_sectionmain_product_problem": ':problem',
    "likut_sectionmain_pallet_product_qty": 'qty',
    "likut_sectionmain_pallet_product_weight": 'weight',
    "likut_modal_checkpallet_title": "Pallet check",
    "likut_modal_checkpallet_weight": "Weight data",
    "likut_modal_addpackage_title": "Add empty package",
    "likut_modal_addpackage_selector_title": "- Select package -",
    ...likutResons,
    ...likutProblebsVarietions
  }
}