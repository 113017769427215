import React from 'react'
import styled from 'styled-components'

export const ResonItemWrapper = styled.div`
    position: relative;
    background: ${props => props.primal ? '#297f76' : '#e9f0f0'};
    color: ${props => props.primal ? '#fff' : 'rgba(0,0,0, 0.6)'};
    width: 80%;
    height: 2em;
    border-radius: 30px;
    padding: 0 0.5em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    filter: ${props => props.primal ? ' drop-shadow(4px 3px 3px rgba(41,127,118,0.51))' : 'none'};
`
export default function ResonItem({id, desc, setResonId, resonId}) {
   console.log(resonId, desc)
    return (
        <ResonItemWrapper onClick={() => {setResonId(id)}} primal={resonId === id}>
            {desc}
        </ResonItemWrapper>
    )
}
