import React from 'react'
import { IForecastsItem } from '../../../../../../modules/API/Drivers/models/kavData'
import { ForcastProductItem } from '../../../../../../modules/API/Forcasts/models/forcastProductitem'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon, PalletIcon, ScalesIcon } from '../../../../../../generic/icons/Icons'
import styled from 'styled-components'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import { IDataForEditForecastItem } from '../types/dataForEditForecastItem'

interface IProps extends ForcastProductItem {
  item: ForcastProductItem,
  index: number,
  columnIndex: number,
  selectDayForForecast: (data: IDataForEditForecastItem) => void
  calculationMethod: number
}

const ForecastItem = ({
  index,
  GrowerName,
  Pack_Name,
  ProductDesc,
  SizeDesc,
  VarietyDesc,
  GeneralProductID,
  ForecastPallets,
  ForecastAmnt,
  columnIndex,
  selectDayForForecast,
  item,
  ActualAmnt,
  ActualPallets,
  calculationMethod,
  ForecastWieght,
  ActualWeight

}: IProps) => {

  const handleSelect = () => {
    const data = {
      indexColumn: columnIndex,
      itemData: {
        index,
        item: item
      }
    }
    selectDayForForecast(data)
  }
  return (
    <TitleLayout
      onClick={handleSelect}
    >
      <SideImage>
        <ProductImage 
          id={GeneralProductID}
        />
      </SideImage>
      <TitleRow>
        <TitleRowItem name={`${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeDesc?.trim()}`} icon={<AppleIcon />} />
      </TitleRow>
      <TitleRow>
        {
          calculationMethod === 2 ? 
          <TitleRowItem name={`${ActualPallets} / ${ForecastPallets}`} icon={<PalletIcon />} />
          : calculationMethod === 3 ?
          <TitleRowItem name={`${ActualWeight} / ${ForecastWieght}`} icon={<ScalesIcon />} />
          : <TitleRowItem name={`${ActualAmnt} / ${ForecastAmnt}`} icon={<PackageIcon />} />
        }
        
      </TitleRow>
    </TitleLayout>
  )
}
const SideImage = styled.div`
  position: absolute;
  left: 0.2em;
  top: calc(50% - 1.5em);
  width: 3em;
  height: 3em;
  overflow: hidden;
`
export default ForecastItem