import { AnyAction } from "redux"
import { CLEAR_CEFI_STATE, SELECT_DATA_FOR_CEFI, SELECT_DATE_FOR_CEFI, SELECT_GROWER_FOR_CEFI } from "../actions/actionsTypes"
import { IGrowerItem } from "../../components/modules/API/Defaults/models/growers";
import { IProductCefi } from "../../components/modules/API/Cefi/models/product";

export interface ICefiData {
    date: string | null;
    grower: IGrowerItem | null;
    products: IProductCefi[] | null;

}

const initialState: ICefiData = {
    grower: null,
    date: null,
    products: null
}

const cefiReducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_GROWER_FOR_CEFI:
            return {
                ...state,
                grower: action.payload
            }
     
        case SELECT_DATA_FOR_CEFI:
            return {
                ...state,
                products: action.payload
            }
        case SELECT_DATE_FOR_CEFI:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_CEFI_STATE:
            return initialState

        default: return state
    }
}

export default cefiReducer