import React from 'react'
import styled from 'styled-components'

type Props = {
    children?: React.ReactNode | React.ReactNode[]
}

const TitleRow = ({children}: Props) => {
  return (
    <MainWrapper>
        {children}
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`
export default TitleRow