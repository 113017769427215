import styled from 'styled-components'
import AddArizotItem from './addArizotItem'

const Wrapper = styled.div`
    /* background: red; */
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    ::-webkit-scrollbar {
    width: 0;
    /* background-color: transparent; */
    } 
    @media screen and (max-width: 600px){
        /* padding-top: 5em; */
    }
    
`


export default function AddArizotList({packs, palletPacks, setPalletPacks}){

    return (
        <Wrapper>
            {packs.map(item => <AddArizotItem key={item.PackID} {...item} palletPacks={palletPacks} setPalletPacks={setPalletPacks} />)}
        </Wrapper>
    )
}
