import React, {useState,useMemo} from 'react'
import { reportTypes } from '../../config'
import SearchFromTheList from '../../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultID: number,
  setDocTypeID: (id: number) => void,
  required?: boolean
}

const SearchDocTypeDriverReport = ({
  defaultID, setDocTypeID, required
}: Props) => {
  const typesList = reportTypes
  const defalutValue = typesList?.find(item => item.id === defaultID)
  const [searchValue, setSearchValue] = useState<string>(defalutValue?.desc.trim() || '')
  const [selected, setSelected] = useState(defalutValue || null)

  const handleSelect = (item: any) => {
    setSearchValue(item.desc.trim())
    setSelected(item)
    setDocTypeID(item.id)
  }
  
  const handleSearch = (value: string) => {
    if(value === '') {
      setSelected(null)
      setDocTypeID(0)
      setSearchValue('')
      return
    }

    setSearchValue(value)
  }

  const filteredList = useMemo(() => {
    if(!typesList) return []
    return typesList.filter(item => item.desc.includes(searchValue))
  }, [typesList, searchValue])
  return (
    <SearchFromTheList 
      list={filteredList}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      input={searchValue}
      itemDisplayName='desc'
      nameIdElement='id'
      isNeedFullObject
      size='full'
      placeHolder='חפש סוג דוח'
      required={required}
    
    />
  )
}

export default SearchDocTypeDriverReport