import { useTranslation } from 'react-i18next'

const useReasonsLikut = () => {
  const [t] = useTranslation('likut')

  const data = {
    notExistInAzmana: {
        num: 1,
        tag: t('likut_problem_tag_notExistInAzmana'),
        resons: [
          {id: 5, desc: t('likut_problem_variations_5')}, 
          {id: 2, desc: t('likut_problem_variations_2')}
        ]
    },
    moreThenNeed: {
        num: 2,
        tag: t('likut_problem_tag_moreThenNeed'),
        resons: [
            {id: 1, desc: t('likut_problem_variations_1')}, 
            {id: 2, desc: t('likut_problem_variations_2')},
            {id: 6, desc: t('likut_problem_variations_6')}
        ],
    },
    lessThenNeed: {
        num: 3,
        tag: t('likut_problem_tag_lessThenNeed'),
        resons: [
            {id: 1, desc: t('likut_problem_variations_1')}, 
            {id: 3, desc: t('likut_problem_variations_3')}, 
            {id: 4, desc: t('likut_problem_variations_4')}, 
            {id: 2, desc: t('likut_problem_variations_2')},
            {id: 6, desc: t('likut_problem_variations_6')}
        ]
    }
  }
  return data
}

export default useReasonsLikut