import {useState, useMemo} from 'react'
import { IProductsWithVarietyItem } from '../../../../../../modules/API/Defaults/models/compliteProducts'
import styled from 'styled-components'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import SearchProductWithVariety from '../../../knisot/Create/Components/SearchProductWithVariety'
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../../../../../modules/API/Auth/models/authDataModel'
import { IGrowerItem } from '../../../../../../modules/API/Defaults/models/growers'
import SearchGrower from '../../../knisot/Create/Components/SearchGrower'
import { IPostRecommendationProductFunctionProps } from '../types/createRecommendationProductProps'
import useDataFetcher from '../../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../../modules/API/Defaults/defaults'
import { IVarietyListResponse } from '../../../../../../modules/API/Defaults/models/varietyList'
import SearchVaiety from '../Components/SearchVaiety'
import { IProductListItemNode } from '../../../../../../modules/API/Defaults/models/productListNode'
import SearchProduct from '../Components/SearchProduct'
import { useLocation, useParams, useRouteMatch } from 'react-router-dom'

interface Props  {
  productList: IProductListItemNode[]
  user: IUser
  growerList: IGrowerItem[]
  handleCreateRecommendation: (data: IPostRecommendationProductFunctionProps) => void
}

const CreateRecommendationProductModal = ({
  productList, 
  handleCreateRecommendation, 
  user, 
  growerList
}: Props) => {
  const location = useLocation()
  const defaultGrowerFromUrl = () => {
    const params = location.pathname.split('/').at(-1) || 'as'
    if(!Number.isNaN(+params)) return Number(params)
    return 0
  }
  console.log(defaultGrowerFromUrl())
  const [t] = useTranslation('buttons')
  const isGrower = user.rights.includes("GROWER")
  const [selectedProduct, setSelectedProduct] = useState<number>(0)
  const [selectedVariety, setSelectedVariety] = useState<number>(0)
  const [size, setSize] = useState<string>("")
  const [growerID, setGrowerID] = useState<number>(() => defaultGrowerFromUrl())
  
  const {
    data: varietyList
  } = useDataFetcher<IVarietyListResponse>(
    Defaults.getVarietyByProductID, `${selectedProduct}`
  )
  const imageID = useMemo(() => {
    if (!selectedProduct) return 0
    return productList.find(item => item.ProductID === selectedProduct)?.GeneralProductID || 0
  }, [selectedProduct, productList])

  const handleSave = () => {
    if (!selectedProduct) return
    handleCreateRecommendation({
      ProductID: selectedProduct,
      VarietyID: selectedVariety,
      SizeID: size,
      Code_Dest_Suppl: isGrower ? +user.code_internal : growerID
    })
  }

  const isAllowToSave = isGrower ?
    selectedProduct !== 0 :
    selectedProduct !== 0 && growerID !== 0

  return (
    <MainWrapper>
      <ImageWrapper>
        <ProductImage id={imageID} />
      </ImageWrapper>
      <BodtWrapper>
        {
          !isGrower && 
            <SearchBlockWithTitle
              title={"בחר ספק"}
            >
              <SearchGrower
                defaultID={growerID} 
                setGrowerID={setGrowerID}
                required
                list={growerList}
              />
            </SearchBlockWithTitle>
        }
        <SearchBlockWithTitle
          title={"בחר פריט"}
        >
          <SearchVaiety 
            setID={setSelectedVariety}
            defaultID={selectedVariety}
            list={varietyList?.variety || []}
            
          />
          <SearchProduct 
            defaultProductID={selectedProduct}
            setDefaultProductID={setSelectedProduct}
            required
            list={productList}
          />
        </SearchBlockWithTitle>
      </BodtWrapper>
        {
          isAllowToSave &&
            <ControlButton 
              lable={t('save')}
              handleClick={handleSave}
            />
        }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 15em;
`
const BodtWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
export default CreateRecommendationProductModal