import { AnyAction } from "redux"
import { GenericType } from "typescript"
import { IOrderPackageTitle } from "../../components/modules/API/Packages/models/createPackageOrderModel"
import { CLEAR_DRIVER_STATE, SELECT_DATA_FOR_ORDER_PACKAGE, SELECT_DATE_DRIVERS } from "../actions/actionsTypes"

export interface IDriverState{
    orderPackage: IOrderPackage,
    date: string | null
}

interface IOrderPackage {
    carID: number | null,
    sender: number | null,
    reference: string | null,
    date: string | null,
    clientName: string | null,
    isReverseSender: boolean,
    finalFunc?: (item: GenericType) => void,
    deliveryNum: number | null,
    TransferZone?: number | null
}

const initialState: IDriverState = {
    orderPackage: {
        carID: null,
        sender: null,
        reference: null,
        date: null,
        clientName: null,
        isReverseSender: false,
        finalFunc: undefined,
        deliveryNum: null,
        TransferZone: null
    },
    date: null

}

const driverDerucer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_DATA_FOR_ORDER_PACKAGE:
            return {
                ...state,
                orderPackage: {
                    ...state.orderPackage,
                    ...action.payload
                }
            }
        case SELECT_DATE_DRIVERS:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_DRIVER_STATE:
            return {
                ...initialState
            }
        default: return state
    }
}

export default driverDerucer