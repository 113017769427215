import { AcceptIcon } from "../../../../../../generic/icons/Icons";
import { ControlButton, ControlButtonWrapper } from "./ButtonStyle";
import { useTranslation } from 'react-i18next';

export default function SaveAzmanotBtn({createAzmana, azmanaAccept, setStatisFilter, blockSave, disabelSave}){
    const [t] = useTranslation(['buttons']);
    
    return(
        <ControlButtonWrapper style={{display: disabelSave ? 'none' : 'flex'}}>
            <ControlButton disabled={blockSave} onClick={() => {createAzmana()}} >
                {t('save')} <AcceptIcon />
            </ControlButton>
        </ControlButtonWrapper>
    );
}