import axios from 'axios'
import React, { useContext, useEffect } from 'react'

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { IS_NEW_PALLET, LIKUT_WITHOUT_AZMANA_CLIENT, LOAD, LOAD_END, LOGOUT, SAVE_CLIENTNAME, SELECT_PALLET } from '../../../../../../store/actions/actionsTypes'
import {  PrintIcon, SettingsIcon, SpeedIcon } from '../../../../../generic/icons/Icons'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import Title from '../../../../../generic/Title/Title'
import { GET_AZMANA_PALLETS, GET_CUSTOMER_LIST, GET_ORDER, GET_PALLET_EXIST, GET_PDF_TEUDOT, GET_PRINTERS, GET_PRODUCT_TYPES, PRINT_PALLET } from '../../../../../modules/API/settings'
import { LikutModals } from '../../../../../modules/Context/CefiNum'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { SelectPanelWrapper } from '../../secondbar/azmanotmain/SelectBar'
import { ContentWrapper, Decorator, MainWrapper } from '../../secondbar/mainbar2'
import { WrapperClients } from '../../secondbar/modalAzmanot/inc/search-item'
import { ListContainerClient } from '../../secondbar/modalAzmanot/inc/searchinglist'
import LikutSectionMain from '../likutSection/LikutSectionMain'
import LikutExpressPalletItem from './likutExpressPalletItem'
import LikutPalletTypeList from './palletType/LikutPalletTypeList'
import LikutExpressPrintersModal from './printers/LikutExpressPrintersModal'
import SiumLikutModal, { ButtonContainer } from './siumLikut/SiumLikutModal'
import history from '../../../../../../history'
import { getAzmanot } from '../../../../../modules/requests/getAzmanot'
import { updateStatusAzmana } from '../../../../../modules/requests/updateAzmanaStatus'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { Certificates } from '../../../../../modules/API/Certificates/certificates'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import { format } from 'date-fns'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import SelectDriver from '../../trucks/SelectDriver'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import SelectByTwo from '../../../../../generic/modals/SelectByTwo'
import { Pallets } from '../../../../../modules/API/Pallets/pallets'
import { LINKS } from '../../../../../Routs/config'
import { Orders } from '../../../../../modules/API/Orders/orders'
import useConfigLefiMishkal from '../useConfigLefiMishkal'
import { useTranslation } from 'react-i18next'
import { SET_IS_OPEN } from '../../../../../../store/reducers/printer'

export const IconWrapper = styled.div`
    min-height: 3em;
    min-width: 3em;
    background: #eff3f4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    color: #7ab0ab;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        background: #7ab0ab;
        color: #fdfdfd;
    }
`



export default function LikutExpressPalletSection({ palletList, azmanaNum, selectedDateStart, setAzmanotListStart, filterAzmanotByBrod }) {
    const [t] = useTranslation(['likut'])
    const [isWeightCalculation, calcWeightPerUnit] = useConfigLefiMishkal()
    const defaults = useSelector(e => e.defaults?.defaults);
    const defaultClientList = useSelector(e => e.defaults?.clientList?.list);
    const isHovaRehev = selectBrodMigdal(defaults, 340, 'Logical_Value');
    const defaultCarID = selectBrodMigdal(defaults, 427, 'Num_Value');
    const [selectedMishtah, setSelectedMishtah] = useState({});
    const [palletType, setPalletType] = useState(null);
    const [palletAzmana, setPalletAzmana] = useState([])
    const [checkedPallets, setCheckedPallets] = useState([]);
    const [isOpenSelectDriver, setIsOpenSelectDriver] = useState(false);
    const [carList, setCarList] = useState([])
    const [carID, setCarID] = useState(null);
    const [defaultCar, setDefaultCar] = useState(null)
    const [tempTeudaData, setTempTeudaData] = useState(null)
    const [isAutomaticCreateTeuda, setIsAutomaticSaveTeuda] = useState(false);
    const [date, setDate] = useState(new Date());
    const [isOpenPrintWindow, setIsOpenPrintWindow] = useState(false);
   
    const [clientList, setClientList] = useState(defaultClientList);
    const [clientListDefault, setClientListDefault] = useState(defaultClientList);
    const [clientID, setClientID] = useState(null)
    const [showClientList, setShowClientList] = useState(false);
    const [inputClients, setInputClients] = useState('')
    
    const getToken = useSelector(token => token.mainReducer.token)
    const user = useSelector(token => token.mainReducer.user);
    const printerState = useSelector(info => info.printerReducer);
    const workerList = useSelector(info => info.defaults.workerList);
    const defaultPrinterNum = () => {
        if (!user.rights.includes("WORKER")) return null
        const wItem = workerList.find(e => e.WorkerID === user.WorkerID)
        if(!wItem) return null
        return wItem.DefaultPrinter
    }
    const CancelToken = axios.CancelToken.source();
    const likutInfo = useSelector(num => num.likut);
    const chekLikut = useSelector(num => num.likut?.withoutAzmana);
    const dispatch = useDispatch()
    const [resonState, setResonState] = useContext(LikutModals);
    const [modal, setModal] = useContext(ContextModal);
    const [printersList, setPrintersList] = useState([]);
    // const [printerNum, setPrinterNum] = useState(() => defaultPrinterNum());
    // const [printerFormat, setPrinterFormat] = useState(null);
    const [numMishtah, setNumMishtah] = useState(null);
    
    const [azmanotListForSiumLikut, setAzmanotListForSiumLikut] = useState([]);
    const [azmanaDetails, setAzmanaDetails] = useState(null);
    const [siumModalIsOpen, setSiumModalIsOpen] = useState(false);
    const [orderTypes, setOrderTypes] = useState({})
    const defaultPackageList = useSelector(info => info.defaults.packageList);
    const defaultPackage = useSelector(item => item.defaults.defaults === null ? 
            '' : item.defaults.defaults.filter(e => {
                return e.Int_ID === 14 && e?.Sub_ID?.trim() === '1'
    })[0])
    const activeArizotCalc = selectBrodMigdal(defaults, 583, 'Logical_Value') 
        && (orderTypes[likutInfo.azmanaGroup] ? 
            orderTypes[likutInfo.azmanaGroup]?.control : true)
    
    const isExistUncheckedPallet = palletAzmana?.some(e => e.CheckDate === '00000000')
    const alertIfExistUnckeckedPallet = () => {
        setModal({
            open: true,
            value: 'קיים משטח שלא נבדק',
            mood: false
        });
        setTimeout(() => {
            setModal({
                open: false,
                value: '2'
            });
        }, 3000);
    }
    // console.log(orderTypes[likutInfo.azmanaGroup]?.control)
    const getPalletTypesForClient = async () => {
        try{
            let res = await Pallets.getPalletTypesForClient(clientID || likutInfo.clientId)
            const find = res.filter(pallet => pallet.IsMainCode);
            // console.log(find)
            if (find.length > 0) {
                defaultPallet(find[0].Pallete_Type)
            } else {
                defaultPallet()
            }
        } catch (err) {

        }
    }
    useEffect(() => {
        getPalletTypesForClient()
    }, [clientID, likutInfo.clientId])

    const getProdTypes = async () => {
        try {
            let response = await axios.get(`${GET_PRODUCT_TYPES}`, {
                headers: {
                    Authorization: 'Bearer ' + getToken
                }
            },
                {
                    cancelToken: CancelToken.token
                })
                let reduced = response.data.productsGroups.reduce((acc, cur) => {
                    acc[cur.GroupID] = {
                        desc: cur.GroupDesc?.trim(),
                        control: +cur.Pallet_Control ? true : false
                    }
                    return acc
                }, {})
                setOrderTypes(reduced)
            
        } catch (err) {
            // console.log(err.response)
            if (err.response.status !== 404) {
                setModal({
                    open: true,
                    value: err.response.data.message,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
                if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                    dispatch({ type: LOGOUT, payload: false });
                }
            }

        }

    }
    const getAzmanotDetailes = async (OrderID) => {
        dispatch({ type: LOAD })
        try {
            let response = await Orders.getOrder(OrderID)
            dispatch({ type: LOAD_END })
            let concatArr = addIDForTheList(response.orderDetails);
            // (Math.abs(item.SupplyWeight - item.OrderWeight) > calcWeightPerUnit(item))
            let sorted = isWeightCalculation ? 
                concatArr.filter(e => (Math.abs(e.SupplyWeight - e.OrderWeight) > calcWeightPerUnit(e)) || (e.ScanProblem !== 0) || e.ChangeReason === 4).sort((a, b) => b.CollectorSort - a.CollectorSort)
                    :
                        concatArr.filter(e => (e.OrderPacks - e.SupplyQty > 0) || (e.ScanProblem !== 0) || e.ChangeReason === 4).sort((a, b) => b.CollectorSort - a.CollectorSort)
            setAzmanotListForSiumLikut(sorted);
            setAzmanaDetails(response?.orderTitle)
            setSiumModalIsOpen(true);
        } catch (err) {
            dispatch({ type: LOAD_END })
            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }

        }

    }

    const siumLikut = async (num) => {
        dispatch({ type: LOAD })
        try {
            let res = await Orders.changeOrderStatus(num, 3)
            updateStatusAzmana(num, user, 3)
            dispatch({ type: LOAD_END });
            setSiumModalIsOpen(false);
            setModal({
                open: true,
                value: 'success',
                mood: true
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: ''
                });
            });
            history.push(LINKS.likut.likutExperess.mainUrl);
            getAzmanot(getToken, selectedDateStart, selectedDateStart, setAzmanotListStart, filterAzmanotByBrod);

        } catch (err) {
            dispatch({ type: LOAD_END })
            setModal({
                open: true,
                value: err.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            });
        }
    }
    // set deafault value for palletType list, in work
    const defaultPallet = (defaulType) => {

       const defType = defaulType | defaultPackage.Num_Value
        const filteredVal = palletList.filter(e => e?.Pallete_Type === defType)

        if (filteredVal.length > 0) {

            dispatch({
                type: SELECT_PALLET, payload:
                {
                    palletNum: 999999,
                    palletDesc: filteredVal[0].Pallete_Desc?.trim(),
                    palletType: filteredVal[0].Pallete_Type

                }
            });
            setSelectedMishtah({
                name: filteredVal[0].Pallete_Desc?.trim(),
                type: filteredVal[0].Pallete_Type
            })
        }
        return filteredVal


    }
    

    const getPrinters = async () => {
        try {
            let res = await axios.get(GET_PRINTERS, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })

            setPrintersList(res?.data?.printers)
        } catch (err) {
            // console.log(err)
        }
    }
    // console.log(defaultPallet())
    const getPallet = async (id) => {
        dispatch({ type: LOAD })
        try {
            let res = await axios.get(`${GET_AZMANA_PALLETS}${chekLikut ? 0 : (azmanaNum || likutInfo.azmanaNum)}/${id || likutInfo.clientId || 0}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })

            setPalletAzmana(res.data.pallets);
            dispatch({ type: LOAD_END });



        } catch (err) {
            dispatch({ type: LOAD_END })
            setModal({
                open: true,
                value: err.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            });
        }

    }


    const createTeuda = async (carID) => {
        dispatch({type: LOAD})
        const palletsListReduced = palletAzmana.reduce((acc, cur) => {
            acc.push(cur.Pallet_Num)
            return acc
        }, [])
        const certData = {
            CustomerID: clientID,
            DeliveryDate: date,
            DeliveryTime: "",
            OrderNum: 0,
            PalletNums: palletsListReduced,
            Tranzit: 0,
            Vehicle_Code: carID || defaultCar?.CarID || defaultCarID || 0
        }
        try {
            const res = await Certificates.createCert(certData)
            setTempTeudaData(res)
            setModal({
                open: true,
                value: `${res.deliveryNum}`,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            setIsOpenPrintWindow(true)
            getPallet(clientID)
        } catch (err) {
            setModal({
                open: true,
                value: 'שגיאה ביצירת תעודה',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            })
        } finally {
            dispatch({type: LOAD_END})
        }
    }
    
    const checValidDataForTeuda = () => {
        /*
            first saving step: check brod migdal. 
            If carID is required, pass default carID, 
            if it exist - run createTeuda, if not - show select driver window
            If brod migdal - false, run createTeuda with previous selected driver or with carID = 0
        */
        if(isHovaRehev) {
            if(!defaultCar?.CarID && !defaultCarID) {
                // call modal for select driver
                setIsAutomaticSaveTeuda(true)
                handleSelectDriverModal()
                return
            } 
        } 
        createTeuda()
    }

    const handleSaveCarID = async (item) => {
        /*
            Select driver from the list and set default driver.
            @isAutomaticCreateTeuda - tell us that user already clicked create teuda, 
            and we need to pass carID param to API request and run createTeuda function.
            Otherwise if isAutomaticCreateTeuda - false, 
            user saving default driver and close window
        */
        setDefaultCar(item)
        if(isAutomaticCreateTeuda) {
            try {
                createTeuda(item.CarID)
            } catch (err) {
                return
            }
        }
        handleSelectDriverModal()
    }
    const handleSelectDriverModal = () => {
        setIsOpenSelectDriver(!isOpenSelectDriver)
    }
    const finishTeuda = () => {
        setIsOpenPrintWindow(false)
        setTempTeudaData(null);
        setClientID(null)
        setInputClients('');
    }
    const getExistPalletInfo = async (id) => {
        try {
            let res = await Pallets.getPalletItems(id)
            return res.detailsPallet
        }
        catch (err) {
        }
    }
    const printTeuda = async () => {
        dispatch({ type: LOAD });
        try {
          let response = await fetch(
            `${GET_PDF_TEUDOT}deliveryNum=${tempTeudaData?.deliveryNum}&deliverySub=${tempTeudaData?.deliverySub}`,
            {
              headers: {
                Authorization: "Bearer " + getToken,
              },
            }
          );
          const data = await response.blob();
          // Receive PDF Steam as as Blob
          const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
          const fileURL = URL.createObjectURL(file); //Build a URL from the file
          window.open(fileURL); //Open the URL on new Window
          finishTeuda()
        } catch (err) {
        //   console.log(err);
        } finally {
          dispatch({ type: LOAD_END });
        }
      };

    const printPallet = async (pallet_num) => {
        dispatch({ type: LOAD });
        try {
            const data = {
                "ZPL_15_75": 3,
                "PalletEntryNum": +pallet_num,
                "PalletZPLFormat": +printerState.formatID
            }
            const defPrinter = printerState.printerID ||  defaultPrinterNum()
            let res = await Pallets.printPalletZPL(defPrinter, data)

            if (typeof res !== 'number') {
                let modifyed = res.split('.il').join('.il:9086');
                window.open(modifyed);
            } else {
                setResonState({
                    ...resonState,
                    'resonTag': 'success',
                    'active': true
                })
            }
            dispatch({ type: LOAD_END });
        } catch (err) {
            if (err?.response?.status === 404) {
                setModal({
                    open: true,
                    value: 'שגיאה הדפסת משטח',
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
                // console.log(err.response)
            }
            else {
                setModal({
                    open: true,
                    value: err?.response?.data?.message,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
            dispatch({ type: LOAD_END });
        }
    }

    const searchClient = (input) => {
        setShowClientList(true);
        const filtered = clientListDefault.filter(item => {
            return item.Customer_Name?.trim().toLowerCase().includes(input.toLowerCase())
        })
        setInputClients(input)
        setClientList(filtered);
    }

    // collect data from selected client
    const selectClient = (name, id) => {
        setClientID(id);
        setShowClientList(false);
        setInputClients(name)
        dispatch({ type: LIKUT_WITHOUT_AZMANA_CLIENT, payload: id })
        dispatch({ type: SAVE_CLIENTNAME, payload: name })
    }

    const handleClickNewPallet = () => {
        if (isExistUncheckedPallet && activeArizotCalc) {
            alertIfExistUnckeckedPallet()
            return
        }
        if (likutInfo?.pallet?.palletType) {
            if (likutInfo.withoutAzmana && !likutInfo?.clientId) {
                setModal({
                    open: true,
                    value: 'בחר לקוח',
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
            dispatch({
                type: SELECT_PALLET, payload:
                {
                    palletNum: 999999,
                    palletDesc: likutInfo?.pallet?.palletDesc,
                    palletType: likutInfo?.pallet?.palletType

                }
            });
            dispatch({ type: IS_NEW_PALLET, payload: true });
        }
        else {
            setModal({
                open: true,
                value: 'בחר סוג משטח',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }

    }

    const getCars = async () => {
        try{
            let res = await Defaults.getCarList();
            setCarList(res.cars)
            if (isHovaRehev && defaultCarID !== 0) {
                /*
                    If isHovaRehev is true and defaultCarID is not 0, it will find the car in res.cars with the specified defaultCarID.
                    If the car is found, it will be set as the default car using setDefaultCar.
                    If the car is not found, setDefaultCar will be called with a value of null.
                */
                let def = res.cars.find(e => e.CarID === defaultCarID)
                setDefaultCar(def || null)
            }
        } catch (err) {

        }
    }
    const handleClickPrint = (palletNum) => {
        if(printerState.printerID && printerState.formatID) {
            printPallet(palletNum)
        } else {
            dispatch({type: SET_IS_OPEN, payload: true})
        }
    } 
    const palletListProps = {
        defaultPackage: defaultPackage,
        printPallet: handleClickPrint,
        setNumMishtah: setNumMishtah ,
        likutInfo: likutInfo
    }
    const selectDriverP = {
        list: carList,
        saveFunc: handleSaveCarID,
        defaultCar: defaultCar,
        isFullObject: true
        
    }

    useEffect(() => {
        if (chekLikut) {
            getCars();
        }
        else {
            getPallet();
        }
        return () => {
            CancelToken.cancel("Component got unmounted");
        }
    }, [])
    useEffect(() => {
        getProdTypes()
        getPrinters();
    }, [])
    useEffect(() => {
        setTempTeudaData(null)
    }, [clientID])
    const handleOpenPrinterSettings = () => {
        dispatch({type: SET_IS_OPEN, payload: true})
    }
    return (
        <Switch>
            <Route exact path={LINKS.likut.likutExperess.palletSection}>
                <MainWrapper>
                    {
                        isOpenPrintWindow &&
                            <SelectByTwo 
                                label={`№ ${tempTeudaData.deliveryNum}`}
                                chooseLableOne={'להדפיס ולסיים'}
                                chooseLabeTwo={'סיים'}
                                closeFunc={finishTeuda}
                                selectFuncTwo={finishTeuda}
                                selectFuncOne={printTeuda}
                            />
                    }
                    {
                        isOpenSelectDriver &&
                            <ColumModal
                                title='בחר נהג'
                                controlFunc={handleSelectDriverModal}
                                Children={SelectDriver}
                                childrenProps={selectDriverP}
                            />
                    }
                    {siumModalIsOpen ?
                        <SiumLikutModal
                            azmanotListForSiumLikut={azmanotListForSiumLikut}
                            azmanaDetails={azmanaDetails}
                            defaultPackageList={defaultPackageList}
                            siumLikut={siumLikut}
                            siumModalIsOpen={siumModalIsOpen}
                            setSiumModalIsOpen={setSiumModalIsOpen}
                            isWeightCalculation={isWeightCalculation}

                        />
                        : ''
                    }

                    <Decorator />
                    <Title label={t('likut_pallets_title')} />
                    {chekLikut ?
                        <SelectPanelWrapper >
                            <DatePickerModule 
                                multyDate={false}
                                setSelectedDateStart={setDate}
                                ignorBrod
                            />
                            <SearchInput input={inputClients} setInput={searchClient} value={'בחר לקוח'} />
                            {
                                showClientList && <ListContainerClient style={{ right: '1em' }}>
                                    {clientList.map(item => <WrapperClients key={item.CustomerID} onClick={() => { selectClient(item.Customer_Name.trim(), item.CustomerID); getPallet(item.CustomerID) }}>
                                        {item.Customer_Name.trim()}
                                    </WrapperClients>)}
                                </ListContainerClient>
                                    
                            }
                        </SelectPanelWrapper>
                        :
                        ''
                    }
                    {
                        !chekLikut || clientID ?
                            <SelectPanelWrapper>
                                {/* <SearchInput input={likutInfo?.pallet?.palletDesc || 'בחר משטח'} iconHide disabled /> */}
                                {
                                    tempTeudaData &&
                                        <CircleActiveFilterBtn
                                            func={printTeuda}
                                        >
                                            <PrintIcon />
                                        </CircleActiveFilterBtn>
                                }
                                <LikutPalletTypeList
                                    palletList={palletList}
                                    setPalletType={setPalletType}
                                    likutInfo={likutInfo}
                                    isExistUncheckedPallet={isExistUncheckedPallet}
                                    alertIfExistUnckeckedPallet={alertIfExistUnckeckedPallet}
                                    activeArizotCalc={activeArizotCalc}
                                />
                                {
                                    chekLikut &&
                                        <CircleActiveFilterBtn
                                            func={() => {
                                                setIsAutomaticSaveTeuda(false)
                                                handleSelectDriverModal()
                                            }}
                                        >
                                            <SpeedIcon />
                                        </CircleActiveFilterBtn>
                                }
                                <IconWrapper onClick={handleOpenPrinterSettings}>
                                    <SettingsIcon />
                                </IconWrapper>
                            </SelectPanelWrapper>
                            : ''
                    }
                    <ContentWrapper>
                        <TitlesList
                            list={palletAzmana}
                            uniqueKey={'Pallet_Num'}
                            additionalData={palletListProps}
                            Element={LikutExpressPalletItem}
                        />
                    </ContentWrapper>
                    <ButtonContainer>
                        {
                            !chekLikut ?
                                <ControlButton 
                                    lable={t('likut_pallets_button_save')}
                                    handleClick={() => { getAzmanotDetailes(likutInfo.azmanaNum) }}
                                /> 
                                    : clientID && palletAzmana?.length > 0 ?
                                        <ControlButton 
                                            lable='צור משלוח'
                                            handleClick={checValidDataForTeuda}
                                        /> : ''
                        }
                        <Link
                            style={{ width: '100%' }}
                            to={
                                (likutInfo.withoutAzmana && !likutInfo?.clientId) || !likutInfo?.pallet?.palletType || (isExistUncheckedPallet && activeArizotCalc) ?
                                    LINKS.likut.likutExperess.palletSection : LINKS.likut.likutExperess.likutWorkSection
                            }
                        >
                            <ControlButton 
                                lable={t('likut_pallets_button_new')}
                                handleClick={handleClickNewPallet}
                            />
                        </Link>
                    </ButtonContainer>

                </MainWrapper>
            </Route>
            <Route
                path={LINKS.likut.likutExperess.likutWorkSection}
                render={() => <LikutSectionMain
                    getPallet={getPallet}
                    getExistPalletInfo={getExistPalletInfo}
                    printPallet={printPallet}
                    setPrintersModal={handleOpenPrinterSettings}
                    printerNum={printerState.printerID || defaultPrinterNum()}
                    inputClients={inputClients}
                    numMishtah={numMishtah}
                    palletAzmana={palletAzmana}
                    activeArizotCalc={activeArizotCalc}
                />
                }

            />

        </Switch>
    )
}
