import styled from "styled-components"
import React from 'react'
import LikutPalletTypeItem from "./LikutPalletTypeItem"
import { useDispatch } from "react-redux"
import { IS_NEW_PALLET, SELECT_PALLET } from "../../../../../../../store/actions/actionsTypes"
import history from "../../../../../../../history"
import { LINKS } from "../../../../../../Routs/config"



export const Wrapper = styled.div`
    /* background: red; */
    width: 100%;
    height: 3.5em;
    border-radius: 30px;
    position: relative;
`
export const Select = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    outline: none;
    border: none;
    font-size: 1em;
    background: #eff3f4;
    padding: 0 0.7em;
    appearance: none;
    direction: rtl;
    cursor: pointer;
    color: #297f76;
`

export default function LikutPalletTypeList({palletList, setPalletType, likutInfo, isExistUncheckedPallet, alertIfExistUnckeckedPallet, activeArizotCalc}) {
    const dispatch = useDispatch()
    return (
        <Wrapper>
            <Select onChange={(e) => {
                if (isExistUncheckedPallet && activeArizotCalc) {
                    alertIfExistUnckeckedPallet();
                    return
                } else {
                    setPalletType(e.target.value);
                    dispatch({ type: IS_NEW_PALLET, payload: true });
                    dispatch({ type: SELECT_PALLET, payload: 
                                                        {
                                                            palletNum: 999999,
                                                            palletDesc: e.target.value.split(',')[1]?.trim(),
                                                            palletType: +e.target.value.split(',')[0]
                                                            
                                                        } 
                    });
                    history.push(LINKS.likut.likutExperess.likutWorkSection); 

                }
            }}>
                <option disabled={false}>בחר סוג משטח</option>
                {palletList.map((item) => <LikutPalletTypeItem key={item.Pallete_Type} {...item} likutInfo={likutInfo} />)}
            </Select>
        </Wrapper>
    )
}