import React, {useState, useMemo} from 'react'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultID: number
  setOrderType: React.Dispatch<React.SetStateAction<number>>
  required?: boolean
}
const ORDER_TYPE = [
  {
    ID: 1,
    Desc: 'הזמנות לקוח'
  },
  {
    ID: 2,
    Desc: 'הזמנות רכש'
  },
]
const SearchOrderType = ({
  defaultID, setOrderType, required

}: Props) => {
  const defOrderType = ORDER_TYPE.find((item) => item.ID === defaultID)
  const [inputOrderType, setInputOrderType] = useState<string>(defOrderType?.Desc.trim() || "")
  const [selectedOrderType, setSelectedOrderType] = useState<{ID: number, Desc: string} | null>(() => defOrderType || null)
  const [isOpenListOrderType, setIsOpenListOrderType] = useState<boolean>(false)

  const handleSearchOrderType = (e: string) => {
    setIsOpenListOrderType(true)
    setInputOrderType(e)
    if (!e) {
      setSelectedOrderType(null)
    }
  }
  const handleSelectOrderType = (item: {ID: number, Desc: string}) => {
    setSelectedOrderType(item)
    setInputOrderType(item.Desc.trim())
    setIsOpenListOrderType(false)
    setOrderType(item.ID)
  }
  const renderOrderTypeList = useMemo(() => {
    if (!ORDER_TYPE) return []
    return ORDER_TYPE.filter((item) => {
      return item.Desc.trim().toLowerCase().includes(inputOrderType.toLowerCase().trim())
    })
  }, [inputOrderType])
  return (
    <SearchFromTheList 
      input={inputOrderType}
      list={renderOrderTypeList}
      itemDisplayName='Desc'
      nameIdElement='ID'
      isNeedFullObject
      placeHolder={'בחר סוג הזמנה'}
      searchFunc={handleSearchOrderType}
      selectFunc={handleSelectOrderType}
      isOpenList={isOpenListOrderType}
      size={'full'}
      required={!selectedOrderType}
    
    />
  )
}

export default SearchOrderType