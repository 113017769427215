export const createObjectsArray = <IEntriesKnisotItem>(object: IEntriesKnisotItem, multiplier: number, isCopyWithWeight: boolean): IEntriesKnisotItem[] => {
  const result = []

  for (let i = 0; i < multiplier; i++) {
    console.log(i)
    if(!isCopyWithWeight && (i !== 0 || multiplier === 1)) {
      result.push({ ...object, Gross: 0 })
      continue
    }
    result.push(object)
  }
  return result
}