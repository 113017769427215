import { LINKS } from "../../../../../Routs/config"
import { Bills } from "../../../../../modules/API/Bills/bills"
import ClientsBills from "../Clients/ClientsBills"

const CustomerBills = () => {
    return (
    <ClientsBills 
        url={LINKS.bills.customerBills.editGeneral}
        getBills={Bills.getCustomerBills}
        title={'חשבוניות לקוח'}
        selectItemLink={LINKS.bills.customerBills.editByID}
        getDetailes={Bills.getCustomerBillDetailsByID}
        printUrl={`invoice/customer/`}
    />
  )
}

export default CustomerBills