import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { langList } from './components/generic/artcoded/langs';
import { translation } from './components/generic/Translation';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translation,
    // lng: "he",
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    supportedLngs: ['en', 'he', 'ru', 'ar', 'th'],
    fallbackLng: "he",
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });