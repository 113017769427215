import { BankIcon, CashIcon, ChequeIcon, CreditCardIcon } from "../../../../../generic/icons/Icons"

export const PAYMENT_TYPE = {
  'מ': {
    name: 'מזומן',
    code: 'מ',
    icon: <CashIcon />
  },
  'ה': {
    name: 'העברה',
    code: 'ה',
    icon: <BankIcon />
  },
  'ש': {
    name: 'שיק',
    code: 'ש',
    icon: <ChequeIcon />
  },
  'כ': {
    name: 'כרטיס',
    code: 'כ',
    icon: <CreditCardIcon />
  } 
  
}

export type PaymentType = keyof typeof PAYMENT_TYPE

export const getDetailesByPaymentType = (paymentType: PaymentType) => {
  return PAYMENT_TYPE[paymentType]
}

