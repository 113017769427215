import ArizotHead from './arizotHead/arizotHead'
import ArizotBody from './arizotBody/arizotBody'
import styled from "styled-components";
import { useContext, useEffect, useState } from 'react';
import { ContextModal } from '../../../../modules/modal/modalContext'
// import { Route, Switch } from 'react-router';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { GET_AZMANOT_ARIZOT, DELETE_ARIZOT_AZMANA } from '../../../../modules/API/settings'
import { Decorator, MainWrapper, SectionMain } from '../secondbar/mainbar2';
import Title from '../../../../generic/Title/Title';
import ControlButton from '../../../../generic/buttons/ControlButton';
import ArizotViewMain from './View/ArizotViewMain';
import { LOAD, LOAD_END } from '../../../../../store/actions/actionsTypes';
import { Packages } from '../../../../modules/API/Packages/packages';
import ArizotAddMenuMain from './arizotAddMenu/arizotAddMenuMain';
import { LINKS } from '../../../../Routs/config';
import ColumModal from '../../../../generic/Layouts/ColumModal';
import { addIDForTheList } from '../../../../generic/utils/addUIDForTheList';
import TeudotModalBody from './Modals/TeudotModalBody';
import PrintModal from './Modals/PrintModal';
import { useTranslation } from 'react-i18next';



export const TagContainer = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 2em;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`


// fromDate=20191007&toDate=20211222
export default function ArizotMain() {
    const {token, workerType, user} = useSelector(token => token.mainReducer)
    let { path } = useRouteMatch();
    const dispatch = useDispatch()
    const [t] = useTranslation(['packageOrder', 'buttons'])
    const isAllowTeuda = user.rights.includes("OFFICE") ||
        user.rights.includes("ADMIN") || workerType === 7
    const CancelToken = axios.CancelToken.source();
    const [modal, setModal] = useContext(ContextModal);

    const [arizotList, setArizotList] = useState([]);
    const [arizotListDefault, setArizotListDefault] = useState([]);

    const [isOpenTeudaModal, setIsOpenTeudaModal] = useState(false);
    const [isOpenPrintModal, setIsOpenPrintModal] = useState(false);
    const [selectedOrderForTeuda, setSelectedOrderForTeuda] = useState({});
    const [selectedDetailesList, setSelectedDetailesList] = useState(null);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const data = format(startDate, "yyyyMMdd")
    const dataEnd = endDate ? format(endDate, "yyyyMMdd") : data
    // const ctx = useSelector(ctx => ctx.mainReducer.user.ctx)
    console.log(selectedDetailesList)
    const handlePrintFunction = (item, isTeuda) => {
        setSelectedOrderForTeuda(item)
        if(item.PD_Num !== 0) {
            console.log(item.PD_Num)
            handlePrintModal()
        } else {
            printFunc(item.PO_Num, isTeuda)
        }
    }
    const handlePrintModal = () => {
        setIsOpenPrintModal(!isOpenPrintModal)
    }
    const getLines = async (id) => {
        // dispatch({type: LOAD})
        try {
            let res = await Packages.getArizotLinesWithTitle(id);
            const {Packs_Pallets, ...rest} = res
            
            setSelectedDetailesList(addIDForTheList(Packs_Pallets.filter(e => e.Qty > 0).map(e => {
                return {
                    ...e,
                    QtyTeuda: e.Qty
                }
            })))
            setSelectedOrderForTeuda(rest)
        } catch (err) {
            setModal({
                open: true,
                value: "משהו השתבש",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            // dispatch({type: LOAD_END})
        }
    }
    const handleControlTeudaModal = () => {
        setIsOpenTeudaModal(!isOpenTeudaModal)
    }
    const handleOpenModalFunc = async (id) => {
        try {
            await getLines(id);
            handleControlTeudaModal()
        } catch (err) {

        }
    }

    const getArizot = async () => {
        try {
            let response = await axios.get(`${GET_AZMANOT_ARIZOT}fromDate=${data}&toDate=${dataEnd}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            },
                {
                    cancelToken: CancelToken.token
                })
            // console.log(response.data.orders)
            setArizotList(response.data.orders)
            setArizotListDefault(response.data.orders)

        } catch (err) {
            // console.log(err.response.data.message)
        }


    }
    const deleteArizot = async (id) => {
        try {
            let response = await axios.delete(`${DELETE_ARIZOT_AZMANA}${id}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            setArizotList(arizotList.filter(item => {
                return item.PO_Num !== id
            }))
            setModal({
                open: true,
                value: response.data.message,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            // console.log(response.data.message)
        } catch (err) {
            // console.log(err.response.data.message)
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }

    }
    const printFunc = async (id, isTeuda) => {
        dispatch({ type: LOAD })
        const url = isTeuda ? `https://my.agroline.co.il/v2/package/delivery/${id}/pdf` :
                                    `https://my.agroline.co.il/v2/package/order/${id}/pdf`
        try {
            // let res = await Packages.printPackageOrder(id)
            let response = await fetch(
                url,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );
            const data = await response.blob();

            // Receive PDF Steam as as Blob
            const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
            const fileURL = URL.createObjectURL(file); //Build a URL from the file
            window.open(fileURL); //Open the URL on new Window
        } catch (err) {

        } finally {

            dispatch({ type: LOAD_END })
        }
    }
    const createTeuda = async (sendMail) => {
        if(!selectedDetailesList) return
        if(selectedDetailesList.some(e => e.QtyTeuda > e.Qty)) {
            setModal({
                open: true,
                value: "לא ניתן לתת מעבר להזמה",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            return
        }
        const postData = {
            ClientID_Recipient: selectedOrderForTeuda.ClientID_Recipient,
            ClientID_Sender: selectedOrderForTeuda.ClientID_Sender,
            PD_Date: selectedOrderForTeuda.PO_Date,
            PO_Num: selectedOrderForTeuda.PO_Num,
            Vehicle_Num: selectedOrderForTeuda.Vehicle_Num + '',
            Driver_Name: selectedOrderForTeuda.Driver_Name,
            Remarks: selectedOrderForTeuda.Remarks,
            Packs_Pallets: selectedDetailesList.map(pack => {
                pack.Qty = pack.QtyTeuda
                let {QtyTeuda, id, RecID, PD_ID, Remark, Description, ...rest} = pack
                return rest
            })
        }
        console.log(postData)
        try{
            console.log(selectedDetailesList)
            let res = await Packages.createTeuda(postData)
            let newDef = [...arizotListDefault].map(e => {
                if(e.PO_Num === res.PO_Num) {
                    e.PO_Status = 9;
                    e.PD_Num = res.PD_Num
                }
                return e
            })
            let newCur = [...arizotList].map(e => {
                if(e.PO_Num === res.PO_Num) {
                    e.PO_Status = 9;
                    e.PD_Num = res.PD_Num
                }
                return e
            })
            console.log(newCur)
            setModal({
                open: true,
                value: "תעודה נוצרה בהצלחה",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            
            handleControlTeudaModal()
            if (sendMail) {
                await sendEmail(res.PD_Num).then(() => {
                     newDef = [...newDef].map(e => {
                        if(e.PO_Num === res.PO_Num) {
                            e.Sent = true;

                        }
                        return e
                    })
                    newCur = [...newCur].map(e => {
                        if(e.PO_Num === res.PO_Num) {
                            e.Sent = true;
                            
                        }
                        return e
                    })
                })
            }
            setArizotListDefault(newDef)
            setArizotList(newCur)
        } catch (err) {
            setModal({
                open: true,
                value: "משהו השתבש",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {

        }
    }
    const sendEmail = async (teudaNum) => {
        try {
            const res = await Packages.sendEmail(teudaNum);
            setModal({
                open: true,
                value: `דוא"ל נשלח בהצלחה`,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } catch (err) {
            console.log(err.response)
            if(err.response.status === 405) {
                setModal({
                    open: true,
                    value: "אין מייל",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            } else {
                setModal({
                    open: true,
                    value: "משהו השתבש",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
        }
    }
    const patchStateTeuda = (qty, id) => {
        if (!selectedDetailesList) return
        const newState = [...selectedDetailesList]
        const found = newState.find(e => e.id === id);
        if (!found) return
        const indexOfItem = newState.indexOf(found);
        newState[indexOfItem].QtyTeuda = qty;
        setSelectedDetailesList(newState)
        if(selectedDetailesList.some(e => e.QtyTeuda > e.Qty)) {
            setModal({
                open: true,
                value: "לא ניתן לתת מעבר להזמנה",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
      }
    const teudaProps = {
        list: selectedDetailesList,
        title: selectedOrderForTeuda,
        saveFunc: createTeuda,
        editFunc: patchStateTeuda
    }
    const PrintProps = {
        title: selectedOrderForTeuda,
        saveFunc: printFunc,
    }
    useEffect(() => {
        getArizot()
        return () => {
            CancelToken.cancel("Component got unmounted");
        };
    }, [endDate])
    
    return (
        <Switch>
            <Route path={path} exact>
                <SectionMain>
                    <MainWrapper>
                        {
                           isOpenTeudaModal &&
                            <ColumModal 
                                controlFunc={handleControlTeudaModal}
                                Children={TeudotModalBody}
                                childrenProps={teudaProps}
                                title={t('packageOrder_modal_teuda_title', {num: `${selectedOrderForTeuda.PO_Num}`})}
                            />
                        }
                        {
                           isOpenPrintModal &&
                            <ColumModal 
                                controlFunc={handlePrintModal}
                                Children={PrintModal}
                                childrenProps={PrintProps}
                                title={t('packageOrder_modal_print_title')}
                            />
                        }
                        <Decorator />
                        <Title label={t('packageOrder_orders_title')} />
                        <ArizotHead
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            setArizotList={setArizotList}
                            arizotListDefault={arizotListDefault}
                        />
                        <ArizotBody
                            arizotList={arizotList}
                            deleteArizot={deleteArizot}
                            printFunc={handlePrintFunction}
                            handleOpenModalFunc={handleOpenModalFunc}
                            isAllowTeuda={isAllowTeuda}
                            
                        />
                        <Link to={LINKS.arizot.arizaAhzara.create}>
                            <ControlButton
                                lable={t('buttons:new')}
                            />
                        </Link>
                    </MainWrapper>
                </SectionMain>
            </Route>
            <Route path={LINKS.arizot.arizaAhzara.editGeneral} component={ArizotViewMain} />
            <Route path={LINKS.arizot.arizaAhzara.create} render={() => <ArizotAddMenuMain getArizot={getArizot} />} />

        </Switch>
    );
}