// import PropTypes from 'prop-types'

const vibration = window.navigator;
const durationTypes = {
    success: 200,
    criticalError: [500, 100, 1000],
    warning: 500
};

export const vibrationFunc = (duration = 'success') => {
    try {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // mobile
            if (vibration.vibrate) {
                vibration.vibrate(durationTypes[duration])
            } else {
                console.log('vibration is not supportin in your browser')
            }
          } else {
            // other
            console.log('desktop')
        } 
    } catch (error) {
        console.log('error', error)
    }
}
