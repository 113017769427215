import React, { useState, useMemo } from "react";
import styled from "styled-components";
import DatePickerModule from "../../../../../../generic/datePicker/datePicker";
import { IReportListItem } from "../../../../../../modules/API/Reports/models/reportList";
import SearchFromTheList from "../../../../../../generic/customSearchComponents/SearchFromTheList";
import ControlButton from "../../../../../../generic/buttons/ControlButton";
import ImageComponentHead from "../../../../../../generic/imageComponents/ImageComponentHead";
import Image from "../../../../../../generic/assets/cogsPNG.png";

type Props = {
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  reportList: IReportListItem[];
  handleSave: (RepID: number) => void;
  selectedDate: any[] | Date;
  handleDate: (e: object | object[]) => void;
  setDefaultDate?: string | null | [string | null, string | null];
  cashReport: IReportListItem | null;
  handleDispatchReport: (data: IReportListItem) => void;
};

const MainFilter = ({
  setStartDate,
  reportList,
  setEndDate,
  handleSave,
  selectedDate,
  handleDate,
  setDefaultDate,
  cashReport,
  handleDispatchReport,
}: Props) => {
  const [inputReport, setInputReport] = useState<string>(
    cashReport?.Rep_Name.trim() || ""
  );
  const [selectedReport, setSelectedReport] = useState<IReportListItem | null>(
    () => cashReport || null
  );
  const [isOpenListReport, setIsOpenListReport] = useState<boolean>(false);
  const handleSearchReport = (e: string) => {
    setIsOpenListReport(true);
    setInputReport(e);
    if (!e) {
      setSelectedReport(null);
    }
  };
  const handleSelectReport = (item: IReportListItem) => {
    setSelectedReport(item);
    setInputReport(item.Rep_Name.trim());
    setIsOpenListReport(false);
    handleDispatchReport(item);
  };
  const renderReportList = useMemo(() => {
    if (!reportList) return [];
    return reportList.filter((item: IReportListItem) => {
      return item.Rep_Name.trim()
        .toLowerCase()
        .includes(inputReport.toLowerCase().trim());
    });
  }, [reportList, inputReport]);

  const handleClickSave = () => {
    if (!selectedReport) return;
    handleSave(selectedReport.RepID);
  };
  return (
    <MainWrapper>
      <ImageWrapper>
        <ImageComponentHead img={Image} large />
      </ImageWrapper>

      <SelectWrapper>
        <SelectorWrapper>
          <Title>
            <p>בחר תאריכים</p>
          </Title>
          <DatePickerModule
            multyDate
            setSelectedDateStart={setStartDate}
            setSelectedDateEnd={setEndDate}
            ignorBrod
            disabled={false}
            selected={setDefaultDate ? selectedDate : false}
            defEndDate={false}
            cashFunc={handleDate}
            large
          />
        </SelectorWrapper>
        <SelectorWrapper>
          <Title>
            <p>בחר דוח</p>
          </Title>
          <SearchFromTheList
            input={inputReport}
            list={renderReportList}
            itemDisplayName="Rep_Name"
            nameIdElement="RepID"
            isNeedFullObject
            placeHolder={"חיפוש דוח"}
            searchFunc={handleSearchReport}
            selectFunc={handleSelectReport}
            isOpenList={isOpenListReport}
            size={"full"}
            required={!selectedReport}
          />
        </SelectorWrapper>
      </SelectWrapper>
      <ControlButton lable={"הצג"} handleClick={handleClickSave} />
    </MainWrapper>
  );
};
const ImageWrapper = styled.div`
  max-height: 30%;
`

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`

const SelectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
  padding: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  align-items: flex-end;
  background: rgb(233, 240, 240, 0.2);
`

const Title = styled.div`
  text-align: end;
  color: #297f76;
  font-weight: 600;
  padding: 0 0.5em;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`

export default MainFilter;
