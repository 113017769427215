import React, {useState} from 'react'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import styled from 'styled-components'
import SwipeContent from '../../knisot/Components/SwipeContent'
import { ITitleItem } from '../../../../../modules/API/Cefi/models/titleItem'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { CalendatDay, LinkIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { convertToDate } from '../../../../../modules/convertDateString'
import DeletePart from './DeletePart'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'

interface IProps extends ITitleItem {
    handleDelete: (id: number) => void
}

const CefiMainItem = ({
    EntryDate, handleDelete, EntryNum, GrowerName, Reference
}: IProps) => {
    const history = useHistory();
    const [isSettleDelete, setIsSettleDelete] = useState<boolean>(false);
    const [isDeletedItem, setIsDeletedItem] = useState<boolean>(false);
    const handleSettleDelete = () => {
        setIsSettleDelete(!isSettleDelete)
    }
    const formatedDate = convertToDate(EntryDate)
    const handleDeleteItem = () => {
        try {
            setIsDeletedItem(true)
            setTimeout(() => {
                handleDelete(EntryNum)
                handleSettleDelete()
            }, 500)

        } catch (error) {
            setIsDeletedItem(false)
            console.log(error)
        }
    }
    const handlePushPage = () => {
        history.push(`${LINKS.cefi.cefi.editByID}${EntryNum}`)
    }
  return (
    <TitleLayout
        isDraggable
        isDelete={isDeletedItem}
    >
        <SwipeableListItem
            swipeRight={{
                content: <SwipeContent text={'עיון'}  />,
                action: handlePushPage,
            }}
            swipeLeft={{
                content: <SwipeContent
                text={'למחוק'}  
                position='right'
                isPrimal
            />,
                action: handleSettleDelete
            }}
            threshold={0.3}
        >
            <MainWrapper>
                <BodyTitle>
                    <TitleRow>
                        <TitleRowItem name={formatedDate} icon={<CalendatDay />} />
                    </TitleRow>
                    <TitleRow>
                        <TitleRowItem name={GrowerName?.trim()} icon={<UserIcon />} />
                    </TitleRow>
                    <TitleRow>
                        <TitleRowItem name={`${Reference}`} icon={<LinkIcon />} />
                    </TitleRow>
                </BodyTitle>
                {
                    isSettleDelete && 
                        <DeletePart 
                            handleClick={handleDeleteItem}
                        />
                }
            </MainWrapper>
        </SwipeableListItem>
    </TitleLayout>
  )
}
const MainWrapper = styled.div`
    display: flex;
    width: 100%;
`
const BodyTitle = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
export default CefiMainItem