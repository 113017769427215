import { instance, requests } from "../config"
import { IAuthDataModel } from "./models/authDataModel"

export const Auth = {
    login: async (data: IAuthDataModel) => {
        try {
            let res = await requests.post('auth', 'go', data)
            localStorage.setItem('user', JSON.stringify(res.token));
            instance.updateToken = res.token
            return res
        } catch (err) {
            throw err
        } 
    },
    loadUser: async (token?:string) => {
        if (token) {
            instance.updateToken = token
        }
        try {
            let res = await requests.get('auth', 'node')
            return res
        } catch (err) {
            throw err
        }
    },
    getDataBases: (data: string) => requests.get('auth/database', 'node'),
    patchDataBase: (data: {dbId: string}) => requests.patch('auth', 'node', data),
    getCompanies: (data: string) => requests.get('auth/company', 'node'),
    patchDataBaseGo: (companyId : number | string, companyInternal: number | string) => 
        requests.get(`auth/change_company?CompanyId=${companyId}&CompanyInternal=${companyInternal}`, 'go'),
    getInternalCompanies: (id: number | string) => requests.get(`auth/companies_internal?CompanyId=${id}`, 'go'),
    updateCompany: (data: {
        year: string,
        company_internal: string
    }) => requests.put('auth', 'node', data),
    
}