import React from 'react'
import { IRecieptItemDetailes } from '../../../../../../modules/API/Reciept/models/receipt'
import { currencyIconByCode } from '../../../../../../generic/artcoded/currency'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import { InvoiceIcon } from '../../../../../../generic/icons/Icons'
import { getDetailesByPaymentType } from '../../generic/getDetailesByPaymentType'
// import { getDetailesByPaymentType } from '../../generic/getDetailesByPaymentType'

interface Props extends IRecieptItemDetailes {}

const ReceiptDetaileItem = ({
  Currency, Sum, Cheque, PaymentType, BankName, 
}: Props) => {
  const currency = currencyIconByCode(Currency);
  const paymentDetailes = getDetailesByPaymentType(PaymentType)
  const paymentDescription = paymentDetailes.name === 'שיק' ? `שיק #${Cheque}` : 
    paymentDetailes.name === 'העברה' ? `העברה ${BankName.trim()}` : 
    paymentDetailes.name === 'כרטיס' ? `אשראי ${BankName.trim()}` :
    paymentDetailes.name === 'מזומן' ? `${paymentDetailes.name}` : ''
  return (
    <TitleLayout>
      <TitleRow>
        <TitleRowItem name={`${Sum.toLocaleString()}`}  icon={currency}/>
        <TitleRowItem name={`${paymentDescription}`}  icon={paymentDetailes.icon}/>
        {/* <TitleRowItem name={`${Cheque}`}  icon={<InvoiceIcon />}/> */}
      </TitleRow>
    </TitleLayout>
  )
}

export default ReceiptDetaileItem