import React, {useState, Dispatch, SetStateAction} from 'react'
import styled from 'styled-components'
import { AcceptIcon } from '../icons/Icons';

type Props = {
    title: string;
    stateFunc: Dispatch<SetStateAction<boolean>>;
    defaultState?: boolean;
    disabled?: boolean;
}

const CheckBoxWithTitle = ({
    title, stateFunc, defaultState=false, disabled
}: Props) => {
    const [isChecked, setIsChecked] = useState(defaultState)
    const handleFunc = () => {
        if(disabled) return
        setIsChecked(!isChecked);
        stateFunc(!isChecked)
    }

  return (
    <MainWrapper>
        <TitleWrapper>
            {title}
        </TitleWrapper>
        <CheckBox 
            isChecked={isChecked} 
            onClick={handleFunc}
        >
            <AcceptIcon />
        </CheckBox>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    /* background: red; */
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    color: #297f76;
`
const CheckBox = styled.div<{isChecked: boolean}>`
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));
    filter: drop-shadow(3px 2px 2px rgba(41,127,118,0.51));
    cursor: pointer;
    i {
        transition: 0.5s;
        transform: ${props => props.isChecked ? 'scale(1)': 'scale(0)'}
    }
`
const TitleWrapper = styled.div`
    width: max-content;
    /* background: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
`
export default CheckBoxWithTitle