import React, {useState, useEffect, useMemo} from 'react'
import { IClientsItem } from '../../../../../../modules/API/Defaults/models/customers'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  required?: boolean
  list: IClientsItem[]
  defaultID: number
  setCustomerID: React.Dispatch<React.SetStateAction<number>>
  placeholder?: string,
  markedLinePattern?: (item: IClientsItem) => boolean
}

const SearchCustomers = ({
  list, required, setCustomerID, defaultID, placeholder, markedLinePattern

}: Props) => {
  const defaultCustomer = list.filter(item => item.CustomerID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedCustomer, setSelectedCustomer] = useState<IClientsItem | null>(defaultCustomer || null)
  const [search, setSearch] = useState<string>(() => defaultCustomer?.Customer_Name.trim() || '')

  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedCustomer(null)
      setIsOpenDropDown(false)
      setSearch('')
      setCustomerID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IClientsItem) => {
    setSearch(item.Customer_Name.trim())
    setSelectedCustomer(item)
    setIsOpenDropDown(false)
    setCustomerID(item.CustomerID)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.Customer_Name.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])
  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={placeholder || 'חפש לקוח'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='Customer_Name'
      nameIdElement='CustomerID'
      required={required}
      size={'full'}
      markedLinePattern={markedLinePattern}
    
    />
  )
}

export default SearchCustomers


// REFFERENCE:

/*

type Props = {
  required?: boolean
  list: IWarhouseListItem[]
  defaultID: number
  setWarehouseID: React.Dispatch<React.SetStateAction<number>>
}

const SearchWarehouse = ({
  list, required, setWarehouseID, defaultID
}: Props) => {
  console.log(defaultID)
  const defaultWarehouse = list.filter(item => item.WarehouseID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState<IWarhouseListItem | null>(defaultWarehouse || null)
  const [search, setSearch] = useState<string>(() => defaultWarehouse?.WarehouseDesc.trim() || '')
  console.log(defaultWarehouse)
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedWarehouse(null)
      setIsOpenDropDown(false)
      setSearch('')
      setWarehouseID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IWarhouseListItem) => {
    setSearch(item.WarehouseDesc.trim())
    setSelectedWarehouse(item)
    setIsOpenDropDown(false)
    setWarehouseID(item.WarehouseID)
  }
  // useEffect(() => {
  //   if(defaultWarehouse) {
  //     setSelectedWarehouse(defaultWarehouse)
  //     setWarehouseID(defaultWarehouse.WarehouseID)
  //     setSearch(defaultWarehouse.WarehouseDesc.trim())
  //   }
  // }, [defaultWarehouse])
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.WarehouseDesc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])
  
  return (
    <SearchFromTheList
      isOpenList={isOpenDropDown}
      placeHolder={'חפש מחסן'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='WarehouseDesc'
      nameIdElement='WarehouseID'
      required={required}
      size={'full'}
    />
  )
}

*/