import React, {useMemo} from 'react'
import { IPalletDetails, IPalletsDetailsSum } from '../../../../../../modules/API/Pallets/models/palletDetails'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout';
import TitleRow from '../../../kosherTeudot/generic/TitleRow';
import { convertToDate } from '../../../../../../modules/convertDateString';
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem';
import { CalendatDay, PackageIcon, PalletIcon, ScalesIcon, ShekelIcon } from '../../../../../../generic/icons/Icons';
import styled from 'styled-components';
import info from '../../../../../../generic/assets/SVGIcons/info.png'

type Props = {
    detailsList: IPalletsDetailsSum | null;
    date1: string;
    date2: string;
    rights: string;
    onInfoClick: () => void
    // diferentPrice: number;
    
}

const TeudotDetailsInfoTitle = ({
    detailsList, date1, date2, rights, onInfoClick
}: Props) => {
    const date1Str = convertToDate(date1)
    const date2Str = convertToDate(date2)
    const isGrower = useMemo(() => rights === 'GROWER', [rights])

    // const generalPrice
    const {generalPalletQty, generalProductQty, generalPrice, geteralWeight} = useMemo(() => {
        if(!detailsList) return {
            generalPalletQty: 0,
            generalProductQty: 0,
            generalPrice: 0
        }
        const generalPalletQty = detailsList.Quantity_Pallets
        const generalProductQty = detailsList.Quantity
        const generalPrice = isGrower ? detailsList.BuyPrice
            : detailsList.SalePrice
        const geteralWeight = isGrower ? detailsList.WeightBuy :
            detailsList.WeightNeto
        return {
            generalPalletQty,
            generalProductQty,
            generalPrice,
            geteralWeight
        }
        // const reduced = detailsList.reduce((acc: Record<string, number>, item) => {
        //     const generalPriceGrower = item.BuyPrice * (item.Type_BuyCalculation === 1 ? item.Quantity : item.WeightBuy);
        //     const generalPriceSeller = item.SalePrice * (item.Type_SaleCalculation === 1 ? item.Quantity : item.WeightNeto);
        //     acc['generalPalletQty'] = acc['generalPalletQty'] ? acc['generalPalletQty'] + item.Quantity_Pallets : item.Quantity_Pallets
        //     acc['generalProductQty'] = acc['generalProductQty'] ? acc['generalProductQty'] + item.Quantity : item.Quantity
        //     if(isGrower) {
        //         acc['generalPrice'] = acc['generalPrice'] ? acc['generalPrice'] + generalPriceGrower : generalPriceGrower
        //     } else {
        //         acc['generalPrice'] = acc['generalPrice'] ? acc['generalPrice'] + generalPriceSeller : generalPriceSeller
        //     }
        //     return acc
        // }, {})
     
        // return {...reduced}
        
    }, [detailsList, isGrower])
  return (
    <TitleLayout>
        <SideWrapper onClick={onInfoClick}>
            <Img src={info} alt='info' />
        </SideWrapper>
        <TitleRow>
            <TitleRowItem name={`${date2Str} - ${date1Str}`} icon={<CalendatDay />}/>
        </TitleRow>
        <TitleRow>
            <TitleRowItem name={`${Math.round(generalPrice || 0).toLocaleString()}`} icon={<ShekelIcon />}/>
            <TitleRowItem name={`${generalProductQty?.toLocaleString()}`} icon={<PackageIcon />}/>
            {/* <TitleRowItem name={`${generalPalletQty}`} icon={<PalletIcon />}/> */}
        </TitleRow>
        <TitleRow>
            {/* <TitleRowItem name={`${Math.round(generalPrice || 0).toLocaleString()}`} icon={<ShekelIcon />}/>
            <TitleRowItem name={`${generalProductQty?.toLocaleString()}`} icon={<PackageIcon />}/> */}
            <TitleRowItem name={`${generalPalletQty.toLocaleString()}`} icon={<PalletIcon />}/>
            <TitleRowItem name={`${Math.round(geteralWeight || 0).toLocaleString()}`} icon={<ScalesIcon />}/>
        </TitleRow>
    </TitleLayout>
  )
}

const SideWrapper = styled.div`
    position: absolute;
    left: 0;
    top: calc(50% - 3em);
    height: 6em;
    width: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;

    :hover {
        img {
            transform: scale(1.1);
        }
    }
`
const Img = styled.img`
    width: 70%;
`

export default TeudotDetailsInfoTitle