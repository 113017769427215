import { requests } from "../config";
import { SAVE_COMMENT_ON_PALLET } from "../settings";
import { IPostCustomer, IPostProduct, IUpdateProduct } from "./models/marketRequests";

export const Market = {
    // getCustmersListWithDetails: (data: string) => requests.get("pallet/not_exit", "go"),
    deleteCustomer: (custSeller: string) => requests.delete(`deletecustomer?CustomerSeller=${custSeller}`, 'wspallets'),
    getCustmersListWithDetails: (data: string) => requests.get("getdata", 'wspallets'),
    createCustomer: (data: IPostCustomer) => requests.post("customer", 'go', data),
    saveProduct: (data: IPostProduct) => requests.post(SAVE_COMMENT_ON_PALLET, 'node', data),
    updateProduct: (RecID: number, data: IUpdateProduct) => requests.put(`/pallet/not_exit/detail/${RecID}`, 'go', data),
    getGrowerListByProductID: (productID: string | number) => requests.get(`grower/by_product?ProductID=${productID}`, 'go'),
    getItemByDelivery: (deliveryNum: number, deliverySub: number) => requests.get(`pallet/by_delivery?Delivery_Num=${deliveryNum}&Delivery_Sub=${deliverySub}`, 'go'),
    patchStatusPallet: (palletStatus: number | string, id: string) =>
        requests.get(`palletstatus?CustomerSeller=${id}&Status=${palletStatus}`, 'wspallets')

}
