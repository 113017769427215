import React from "react";
import { LogIcon } from "../../../../../generic/icons/Icons";
import styled from "styled-components";

type Props = {
    handleControl: () => void
};

const LogButton = ({handleControl}: Props) => {
  return (
    <FilterIndicator
      onClick={handleControl}
    >
      <ButtonWrapper>
        <LogIcon />
      </ButtonWrapper>
    </FilterIndicator>
  );
};
export const FilterIndicator = styled.div<{common?: boolean}>`
    position: relative;
    height: 3.5em;
    min-width: 3.5em;
    background: #eff3f4 ;
    border-radius: 50%;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: #297f76;
    display: flex;
    justify-content: center;
    align-items: center;
    
    :hover {
        background: ${props => props.common ? '#eff3f4' : '#297f76'} ;
    }
    
`
export const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #eff3f4;
    border-radius: 30px;
    align-items: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    :hover {
        transform: translateX(3px) rotate(45deg) translateY(-3px);
    }
`
export default LogButton;
