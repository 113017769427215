import { requests } from "../config";
import { SAVE_LIKUT } from "../settings";
import { ICreateCefi } from "./models/req";

export const Cefi = {
    // date/GrowerID - query string
    getProducts: (query: string) => requests.get(`expect/product/${query}`, 'node'),
    getCefiByID: (id: string | number) => requests.get(`expect/${id}`, 'node'),
    createCefiOrder: (body: ICreateCefi) => requests.post('expect', "node", body),
    deleteCefiOrder: (id: string | number) => requests.delete(`expect/${id}`, 'node'),
}