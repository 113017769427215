import React, { useState, useMemo } from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IPaymentItem } from '../../../../../modules/API/Bills/models/payments'
import { Bills } from '../../../../../modules/API/Bills/bills'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import styled from 'styled-components'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { CalendatDay, ShekelIcon } from '../../../../../generic/icons/Icons'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { convertToDate } from '../../../../../modules/convertDateString'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import PaymentItem from './Components/PaymentItem'


interface IPaymentTitle {
  total: number
  bank: number
  check: number
  paid: number,
  date: string
}

const tabs = ['טיוטה', 'בוצע', 'הכול']

const Payments = () => {
  const [dateStart, setDateStart] = useState<string>('')
  const [dateEnd, setDateEnd] = useState<string>('')
  const [inputSearch, setInputSearch] = useState<string>('')
  const [activeTab, setActiveTab] = useState<string | null>('הכול');

  //DateFrom=2&DateTo=3
  const {
    data: paymentsList
  } = useDataFetcher<IPaymentItem[]>(
    Bills.getPaymentsByDateRange, `DateFrom=${dateStart}&DateTo=${dateEnd}`
  )

  const paymentsTitle: IPaymentTitle | null = useMemo(() => {
    let total: IPaymentTitle = {
      total: 0,
      bank: 0,
      check: 0,
      paid: 0,
      date: ''
    }
    total.date = `${convertToDate(dateStart)} - ${convertToDate(dateEnd)}`

    if (!paymentsList?.length) return total

    total = {
      ...total,
      ...paymentsList.reduce((acc: any, cur): IPaymentTitle => {
      console.log(cur.PaymentType, cur.PaySum)
      acc = {
        total: acc.total ? acc.total + (cur.PaySum * cur.Exchange_Rate || 1) : cur.PaySum,
        // bank: acc.bank && cur.PaymentType === 0 ? acc.bank + cur.PaySum : cur.PaymentType === 0 ? cur.PaySum : acc.bank || 0 ,
        // check: acc.check && cur.PaymentType === 1 ? acc.check + cur.PaySum : cur.PaymentType === 1 ? cur.PaySum : acc.check || 0,
        // paid: 0,
      }
      return acc
    }, {})
  }
    return total
  }, [paymentsList, dateEnd, dateStart])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  const render = useMemo(() => {
    if (!paymentsList) return []
    const filtered = paymentsList.filter((item) => {
      return (item.MutavName.trim().toLowerCase().includes(inputSearch.toLocaleLowerCase()))
        && (activeTab === 'הכול' ? true : activeTab === 'בוצע' ? item.ChequeNum > 0  : item.ChequeNum < 0)
    })
    return filtered
  }, [paymentsList, inputSearch, activeTab])
  return (
    <OrderlikeLayout label='תשלומים'>
      <TitleLayout disableShadow>
        <TitleRow>
          <TitleRowItem name={paymentsTitle?.date || ''} icon={<CalendatDay />} />
        </TitleRow>
        <TitleRow>
          <TitleRowItem name={`${paymentsTitle?.total.toLocaleString() || 0}`} icon={<ShekelIcon />} />
        </TitleRow>
      </TitleLayout>
      <ControlSearchPannelLayout >
        <DatePickerModule
          multyDate
          setSelectedDateStart={setDateStart}
          setSelectedDateEnd={setDateEnd}
          selected={false}
          defEndDate={false}
          endDateMultiple={182}
          disabled={false}
        />
        <SearchInput
          setInput={setInputSearch}
          input={inputSearch}
        />
      </ControlSearchPannelLayout>
      <Container>
        <TabContainer>
          {tabs.map((type) => (
            <Tab
              key={type}
              active={activeTab === type}
              onClick={() => handleTabClick(type)}
            >
              {type}
            </Tab>
          ))}
        </TabContainer>
        <TitlesList
          uniqueKey={'InternalNum'}
          borderLess
          list={render}
          Element={PaymentItem}
          noPadding={false}
        />
      </Container>
    </OrderlikeLayout>
  )
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  z-index: 1;
  ${props =>
    props.active ?
    `
      background: #fff;
      border-bottom: none;
      color: #7ab0ab;
    `:``
  }
`;
export default Payments