import { useParams } from 'react-router-dom'
import useDataFetcher from '../../../knisot/hooks/useFetchData'
import { IRecieptItem } from '../../../../../../modules/API/Reciept/models/receipt'
import { Reciept } from '../../../../../../modules/API/Reciept/reciept'
import OrderlikeLayout from '../../../../../../generic/Layouts/OrderlikeLayout'
import ReceiptTitleLine from '../ReceiptTitleLine'
import LoaderSpinner from '../../../../../../modules/loadSpinner/LoaderSpinner'
import BodyScrollContainer from '../../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../../generic/titlesRowItem/TitlesList'
import ReceiptDetaileItem from './ReceiptDetaileItem'
import styled from 'styled-components'



const ReceiptEdit = () => {
  const {id} = useParams<{id: string}>()
  const {
    data: itemData 
  } = useDataFetcher<IRecieptItem>(
    Reciept.getRecieptByID, id
  )
  console.log(id)
  return (
    <OrderlikeLayout label={`קבלה #${id}`}>
      {
        itemData ?
          <>
            <ReceiptTitleLine
              {...itemData}
            /> 
          <BodyScrollContainer>
            <TitlesList 
              list={itemData.Lines}
              uniqueKey={'RecID'}
              Element={ReceiptDetaileItem}
              additionalData={{}}
              noPadding={false}

            />
          </BodyScrollContainer>
          </>
          :
          <LoaderWrapper>
            <LoaderSpinner />
          </LoaderWrapper>
      }
    </OrderlikeLayout>
  )
}
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export default ReceiptEdit