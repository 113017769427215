import React from 'react'

export default function FilterAzmanotItem({id, desc}) {
    // console.log(id)
    return (
        <option value={id}>
            {desc?.trim()}
        </option>
    )
}
