import React, { useMemo } from 'react'
import { IRecommendationItem } from '../../../../../../../modules/API/Recommendation/models/recomendationItem'
import { ForcastProductItem } from '../../../../../../../modules/API/Forcasts/models/forcastProductitem'
import styled from 'styled-components'
import RecommendationItem from '../RecommendationItem'
import { Link, useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../../../Routs/config'
import { IRecommendationModifiedItem } from '../../types/recommendationModified'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'

type Props = {
  recList: IRecommendationModifiedItem[],
  selectedDataForRec: {index: number, list: ForcastProductItem[]},
  createForecastFromRec: (recItems: IRecommendationModifiedItem[]) => void
  growerID: number,
  calculationMethod: number
}

const ReccomendationModal = ({
  recList, selectedDataForRec, createForecastFromRec, growerID,
  calculationMethod

}: Props) => {
  const [t] = useTranslation("buttons")
  const history = useHistory()
  const [reclistState, setRecListState] = React.useState<IRecommendationModifiedItem[]>(recList)
  
  const uniqeRecList = useMemo(() => {
    if(!reclistState) return []
    return reclistState.filter((rec) => {
      return selectedDataForRec.list.every((item) => {
        return !(rec.ProductID === item.ProductID && rec.VarietyID === item.VarietyID)
      })
    })
  }, [reclistState, selectedDataForRec]) 

  const handleChangeRecItem = (index: number, amount: number, pallets: number, weight: number) => {
    setRecListState(() => {
      const newState = [...uniqeRecList]
      newState[index] = {
        ...newState[index],
        amount: amount,
        pallets: pallets,
        weight: weight
      }
      return newState
    })
  }
  console.log(reclistState)
  const handleSave = () => {
    if (createForecastFromRec) {
      console.log(uniqeRecList)
      const filteredArray = uniqeRecList.filter((item) => item.amount > 0 || item.pallets > 0 || item.weight > 0)
      console.log(filteredArray)
      createForecastFromRec(filteredArray)
    }
  }
  const handleLink = () => {
    history.push(`${LINKS.forecasts.products.mainURL}/create/${growerID}`)
  }
  return (
    <MainWrapper>
       <LinkButton
        onClick={handleLink}
      >
        הוסף פריט 
      </LinkButton>
      <BodyContainer>
        {
          uniqeRecList.map((item, index) => 
            <RecommendationItem
              item={item}
              key={item.RecID}
              index={index}
              {...item}
              calculationMethod={calculationMethod}
              // createForecastFromRec={createForecastFromRec}
              handleChangeRecItem={handleChangeRecItem}
            />
          )
        }

      </BodyContainer>
     <ControlButton 
      lable={t("save")}
      handleClick={handleSave}
     />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1em;
  display: flex;
  flex-flow: column;
  gap: 1em;
  /* ::-webkit-scrollbar {
    width: 0;
  } */
`
const LinkButton = styled.button`
  width: 100%;
  padding: 0.5em 1em;
  border: none;
  background: #8CC53D;
  color: #fff;
  font-size: 1.1em;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1em;
  width: 100%;
  height: 85%;
  overflow: auto;
  padding: 1em;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default ReccomendationModal