import React from 'react'
import styled from 'styled-components'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import WarningMessage from '../../../../../generic/messages/WarningMessage'

const AdminFieldWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`


const AdminPassField = styled.div` 
    width: 100%;
    height: 90%;
    /* background: red; */
    display: flex;
    
`
const InputWrapper = styled.div` 
    width: 100%;
`

export default function AdminCheckField({setHandlePass}) {
    return (
        <AdminFieldWrapper>
            <WarningMessage
                messageArray={['השתמשו בכל הניסיונות', 'נא להזין סיסמה']}
            />
            <AdminPassField>
                <InputWrapper>
                    <SearchInput 
                        setInput={setHandlePass} 
                        value={'סיסמת מנהל'}
                        type={'password'} 
                        iconHide 
                    />
                </InputWrapper>
            </AdminPassField>
        </AdminFieldWrapper>
    )
}
