import React from 'react'
import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import SettingsNav from './Components/Nav/SettingsNav'
import { Route, Switch } from 'react-router-dom'
import AccountIndex from './Account/AccountIndex'
import PreferencesIndex from './Preferences/PreferencesIndex'
import { useTranslation } from 'react-i18next'

type Props = {}

const SettingsIndex = (props: Props) => {
  const [t] = useTranslation(['settings'])
  return (
    <OrderlikeLayout label={t("title")}>
      <SettingsNav />
      <Switch>
        <Route path='/main/settings/account' component={AccountIndex} exact />
        <Route path='/main/settings/preferences' component={PreferencesIndex} />
      </Switch>
    </OrderlikeLayout>
  )
}

export default SettingsIndex