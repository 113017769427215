import React, { useMemo, useState, useEffect, SetStateAction, Dispatch } from 'react'
import { IPalletDetails } from '../../../../../../modules/API/Pallets/models/palletDetails'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import styled from 'styled-components'
import { AppleIcon, CalendatDay, EditIcon, InvoiceIcon, InvoiceMoneyIcon, LinkIcon, PackageIcon, PalletIcon, ScalesIcon, ShekelIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import ProductImage from './ProductImage'
import { convertToDate } from '../../../../../../modules/convertDateString'
import RowInput from '../../../../../../generic/inputs/RowInput'
import backPic from '../../../../../../generic/assets/SVGIcons/backPicAlt.png'
import { IPatchItem } from '../../../../../../modules/API/Certificates/models/patchItem'
import useDebounce from '../helpers/useDebounce'
import SelectType from './SelectType'
import selectOnFocus from '../../../../../../modules/autoselect'
import { ItemForRemark } from '../TeudotDetailsMain'


interface IProps extends IPalletDetails {
    userRights: string;
    isAllowToShowPriceForGrower: boolean;
    updateFields: (id: number, data: IPatchItem, index: number) => void;
    index: number;
    isAllowToAutoEditPriceKniya: boolean;
    isAllowToAutoEditWeightKniya: boolean;
    handleSelectRemark: (item: ItemForRemark, RecID: number) => void;
}

// Define a generic type for the state
type State<T> = T;

// Define a generic type for the action
type Action<T> = SetStateAction<T>;

const ICON_COLOR = '#297f76'
const TeudotDetailsItem = ({
    Delivery_Num, Delivery_Sub, ProductDesc, GeneralProductID, Delivery_Date,
    VarietyDesc, Quantity, Pack_Name, Pallet_Num, Quantity_Pallets, userRights,
    WeightBuy, BuyPrice, SalePrice, WeightNeto, isAllowToShowPriceForGrower,
    InvoiceBuy, InvoiceSale, Customer_Name, GrowerName, updateFields, RecID,
    Type_BuyCalculation, Type_SaleCalculation, OrderLineRef, isAllowToAutoEditPriceKniya,
    isAllowToAutoEditWeightKniya, Reference_Delivery_Entry, Price_Guide_From,
    Price_Guide_To, Remarks, handleSelectRemark
}: IProps) => {
    const [priceGuideFrom, setPriceGuideFrom] = useState<number>(Price_Guide_From);
    const [priceGuideTo, setPriceGuideTo] = useState<number>(Price_Guide_To);
   
    const date = convertToDate(Delivery_Date);
    const [isShowBackside, setIsShowBackside] = useState<boolean>(false);
    const [remark, setRemark] = useState<string>(Remarks);
    const [salePrice, setSalePrice] = useState(+SalePrice.toFixed(1));
    const [buyPrice, setBuyPrice] = useState<number>(+BuyPrice.toFixed(1));
    const [bruttoWeight, setBruttoWeight] = useState(() => Math.round(WeightBuy));
    const [nettoWeight, setNettoWeight] = useState(() => Math.round(WeightNeto));
    const [typeBuyCalculation, setTypeBuyCalculation] = useState<number>(Type_BuyCalculation);
    const [typeSaleCalculation, setTypeSaleCalculation] = useState<number>(Type_SaleCalculation);
    const isAllowToEditBuy = userRights !== 'GROWER' && userRights !== 'CUSTOMER' && InvoiceBuy === 0;
    const isAllowToEditSale = userRights !== 'GROWER' && userRights !== 'CUSTOMER' && InvoiceSale === 0;
    const isAllowToEditPriceRange = userRights !== 'GROWER' && userRights !== 'CUSTOMER'
    const isVisiblePriceForGrower = userRights !== 'GROWER' || InvoiceBuy !== 0 || isAllowToShowPriceForGrower;

    const isAutoEditPrice = isAllowToAutoEditPriceKniya && (BuyPrice === SalePrice || BuyPrice === 0);
    const isAutoEditWeight = isAllowToAutoEditWeightKniya && (WeightBuy === WeightNeto || WeightBuy === 0);
    // console.log(isAutoEditWeight)
    const handleSelectRemarkItem = () => {
        handleSelectRemark({
            id: RecID,
            body: Remarks,
            title: `${ProductDesc?.trim()} ${VarietyDesc?.trim()}`,
            itemData: {
                BuyPrice: +buyPrice,
                SalePrice: +salePrice,
                WeightBuy: +bruttoWeight,
                WeightNeto: +nettoWeight,
                Type_BuyCalculation: +typeBuyCalculation,
                Type_SaleCalculation: +typeSaleCalculation,
                Price_Guide_From: +priceGuideFrom,
                Price_Guide_To: +priceGuideTo,
                Remarks: Remarks
            }
            
        }, RecID)
    }
    function handleOnChange<T>(
        dispatch: Dispatch<Action<T>>,
    ): (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, fieldName: string) => void {
        return (event, fieldName) => {
            const newValue = event.target.value;
            dispatch(newValue as Action<T>);
            handleDebounceInput(newValue, fieldName);
        };
    }

    const handleDebounceInput = useDebounce({
        callback: (e: string | number, fieldName) => {
            // console.log(fieldName, e)
            if (!fieldName) return
            let data: Record<string, number | string> = {
                BuyPrice: +buyPrice,
                SalePrice: +salePrice,
                WeightBuy: +bruttoWeight,
                WeightNeto: +nettoWeight,
                Type_BuyCalculation: +typeBuyCalculation,
                Type_SaleCalculation: +typeSaleCalculation,
                Price_Guide_From: +priceGuideFrom,
                Price_Guide_To: +priceGuideTo,
                Remarks: Remarks
            }
            data[fieldName] = +e
            if (isAutoEditPrice && fieldName === 'SalePrice') {
                data['BuyPrice'] = +e
                setBuyPrice(+e)
            } else if (isAutoEditWeight && fieldName === 'WeightNeto') {
                data['WeightBuy'] = +e
                setBruttoWeight(+e)
            }
            // console.log(data)
            let isNeedToRecalculateHead = 0
            if (fieldName === 'Type_BuyCalculation' || fieldName === 'Type_SaleCalculation') {
                isNeedToRecalculateHead = 1
            }
            updateFields(RecID, {
                ...data,
                [fieldName]: +e
            }, isNeedToRecalculateHead)
        },
        delay: 2000
    })

    const handleClickBackside = () => {
        setIsShowBackside(!isShowBackside)
    }

    return (
        <TitleLayout
        >
            <SideWrapper onClick={handleClickBackside}>
                {
                    isShowBackside ?
                        <Img src={backPic} alt='back' /> :
                        <ProductImage size='lg' id={GeneralProductID} />
                }
            </SideWrapper>
            <BacksideWrapper isShow={isShowBackside}>
                {
                    userRights !== 'GROWER' && userRights !== 'CUSTOMER' &&
                    <TitleRow>
                        <TitleRowItem name={`מגדל - ${GrowerName?.trim()}`} icon={<UserIcon />} />
                    </TitleRow>
                }
                <TitleRowItem name={`${Reference_Delivery_Entry}`} icon={<LinkIcon />} />

                {/* {
                    (userRights !== 'GROWER' || Customer_Name?.trim()) &&
                    <TitleRow>
                        <TitleRowItem name={`לקוח - ${Customer_Name?.trim()}`} icon={<UserIcon />} />
                    </TitleRow>
                } */}
                {/* <TitleRow>
                    <TitleRowItem name={`${Pack_Name?.trim()}`} icon={<PackageIcon />} />
                </TitleRow> */}
                {
                    userRights !== 'GROWER' &&
                    <TitleRow>                        
                        <SelectType
                            defaulValue={Type_SaleCalculation}
                            isDisabled={!isAllowToEditSale}
                            onChange={(e) => {
                                handleOnChange(setTypeSaleCalculation)(e, 'Type_SaleCalculation')
                            }
                            }
                        />
                        {
                            userRights !== 'CUSTOMER' &&
                            <TitleRowItem name={`(מ)`} icon={<></>} />
                        }
                    </TitleRow>
                }
                {
                    userRights !== 'CUSTOMER' &&
                    <TitleRow>
                        <SelectType
                            defaulValue={Type_BuyCalculation}
                            isDisabled={!isAllowToEditBuy}
                            onChange={(e) => {
                                handleOnChange(setTypeBuyCalculation)(e, 'Type_BuyCalculation')
                            }
                            }
                        />
                        {
                            userRights !== 'GROWER' &&
                            <TitleRowItem name={`(ק)`} icon={<></>} />
                        }
                    </TitleRow>

                }
            </BacksideWrapper>
            <TitleRow>
            {
                    userRights !== 'GROWER' && userRights !== 'CUSTOMER' &&
                    <IconWrapper 
                        isActive={!!Remarks.trim()}
                        onClick={handleSelectRemarkItem}
                    >
                        <TitleRowItem name={``} icon={<EditIcon />} />
                        
                    </IconWrapper>
                }
                <TitleRowItem name={`${Delivery_Num}-${Delivery_Sub}`} icon={<InvoiceIcon />} />
                <TitleRowItem name={`${date}`} icon={<CalendatDay />} />
            </TitleRow>
            <TitleRow>
                <TitleRowItem name={`${Quantity}`} icon={<PackageIcon />} />
                <TitleRowItem name={`${ProductDesc?.trim()} ${VarietyDesc?.trim()}`} icon={<AppleIcon />} />
            </TitleRow>
            <TitleRow>
                
                {
                            (userRights !== 'GROWER' || Customer_Name?.trim()) &&
                                <TitleRowItem name={`${Customer_Name?.trim()}`} icon={<UserIcon />} />
                            // <TitleRow>
                            // </TitleRow>
                        }
            </TitleRow>
            
                    <TitleRow>
                        <Input 
                            value={priceGuideTo} 
                            onChange={
                                (e) => {handleOnChange(setPriceGuideTo)(e, 'Price_Guide_To') }
                
                            }
                            disabled={!isAllowToEditPriceRange}
                            onFocus={selectOnFocus}
                        />
                        -
                        <Input 
                            value={priceGuideFrom} 
                            onChange={
                                (e) => {handleOnChange(setPriceGuideFrom)(e, 'Price_Guide_From') }
                
                            }
                            disabled={!isAllowToEditPriceRange}
                            onFocus={selectOnFocus}
                        />
                        <TitleRowItem name="מחיר שיערוך" icon={<></>} />
                    </TitleRow>
                
            {
                userRights !== 'GROWER' &&
                <TitleRow>
                    {
                        InvoiceSale > 0 &&
                    <TitleRowItem name={`${InvoiceSale}`} icon={<InvoiceMoneyIcon />} />
                    }

                    
                    <RowInput
                        // defValue={salePrice}
                        value={salePrice}
                        handleInput={
                            (e) => { handleOnChange(setSalePrice)(e, 'SalePrice') }
                            // setSalePrice(e.target.value)
                        }
                        icon={<ShekelIcon />}
                        isDisabled={!isAllowToEditSale}
                        iconColor={ICON_COLOR}
                    />
                    <RowInput
                        // defValue={nettoWeight}
                        value={nettoWeight}
                        handleInput={
                            (e) => { handleOnChange(setNettoWeight)(e, 'WeightNeto') }
                            // (e) => setNettoWeight(+e.target.value)
                        }
                        icon={<ScalesIcon />}
                        isDisabled={!isAllowToEditSale}
                        iconColor={ICON_COLOR}
                    />
                    {
                        userRights !== 'CUSTOMER' &&
                        <TitleRowItem name={`(מ)`} icon={<></>} />
                    }
                </TitleRow>
            }
            {
                userRights !== 'CUSTOMER' &&
                <TitleRow>
                    {
                        InvoiceBuy > 0 &&
                        <TitleRowItem name={`${InvoiceBuy}`} icon={<InvoiceMoneyIcon />} />
                    }
                    {
                        isVisiblePriceForGrower &&
                        <RowInput
                            value={buyPrice}
                            // defValue={buyPrice}
                            handleInput={
                                (e) => { handleOnChange(setBuyPrice)(e, 'BuyPrice') }
                                // (e) => setBuyPrice(e.target.value)
                            }
                            icon={<ShekelIcon />}
                            isDisabled={!isAllowToEditBuy}
                            iconColor={ICON_COLOR}
                        />
                    }
                    <RowInput
                        value={bruttoWeight}
                        // defValue={bruttoWeight}
                        handleInput={
                            // (e) => setBruttoWeight(+e.target.value)
                            (e) => { handleOnChange(setBruttoWeight)(e, 'WeightBuy') }
                        }
                        icon={<ScalesIcon />}
                        isDisabled={!isAllowToEditBuy}
                        iconColor={ICON_COLOR}
                    />
                    {
                        userRights !== 'GROWER' &&
                        <TitleRowItem name={`(ק)`} icon={<></>} />
                    }
                </TitleRow>
            }
        </TitleLayout>
    )
}

const BacksideWrapper = styled.div<{ isShow: boolean }>`
    position: absolute;
    height: 100%;
    width: 75%;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    font-size: 1.1em;
    gap: 0.5em;
    background: #fff;
    border-radius: 30px;
    padding: 0.5em 1em;
    color: #297f76;
    z-index: 2;
    transition: all 0.4s ease-in-out;
    transform: ${({ isShow }) => isShow ? 'translateX(0)' : 'translateX(100%)'};
    opacity: ${({ isShow }) => isShow ? '1' : '0'};
    i {
        color: #297f76;
    }
`
const Input = styled.input`
    outline: none;
    width: 2em;
    border: none;
    border-bottom: 1px solid #297f76;
    background: none;
    text-align: right;
    color: #297f76;
`

const SideWrapper = styled.div`
    position: absolute;
    left: 0;
    top: calc(50% - 3em);
    height: 6em;
    width: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;
`

const Img = styled.img`
    width: 90%;
`
const IconWrapper = styled.div<{isActive?: boolean}>`
    cursor: pointer;
    i {
        color: ${props => props.isActive ? 'tomato' : '#297f76'};
    }
`
export default TeudotDetailsItem