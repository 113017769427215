import React from 'react'
import styled from 'styled-components'
import LogoIMG from '../assets/BigLogo.png'

type Props = {
    size?: 'lg' | 'md' | 'sm'
}


function Logo({size}: Props) {
  return (
    <LogoWrapper 
        href='https://www.magicline.co.il/' 
        target="_blank" 
        rel="noopener noreferrer"
    >
        <Img 
            size={size} 
            src={LogoIMG} 
            alt="logo"
        />
    </LogoWrapper>
  )
}

export default Logo

const LogoWrapper = styled.a<Props>`
    width: 100%;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
`
const Img = styled.img<Props>`
    min-width: ${props => props.size === 'lg' ? '300px' : props.size === 'md' ? '200px' : '140px'};
`