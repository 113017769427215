import React, { useMemo } from 'react'
import { IMarketCustomerWithDetails, IMarketPalletDetails } from '../../../../../../../modules/API/Market/models/marketRequests'
import styled from 'styled-components'
import TitleRowItem from '../../../../kosherTeudot/generic/TitleRowItem'
import { AcceptIcon, BackHumanIcon, CloseIcon, DollarIcon, InvoiceIcon, UserIcon, VerticalDots } from '../../../../../../../generic/icons/Icons'
import TitlesList from '../../../../../../../generic/titlesRowItem/TitlesList'
import PalletItem from './PalletItem'
import AddClientButton from '../AddClientButton'

interface IProps extends IMarketCustomerWithDetails {
    selectProduct: (data: IMarketPalletDetails) => void;
    createTeuda: (item: IMarketCustomerWithDetails) => void;
    item: IMarketCustomerWithDetails;
    removeCustomerFromTheList: (id: string) => void;
    deleteProduct: (pallet: number, client: number, redID: number) => void;
    handleSelectForPallet: (pallet: number, client: number, name: string, seller: number) => void;
    updateTeuda: (item: IMarketCustomerWithDetails) => void;
    deletePallet: (pallet: number, client: number) => void;
    printPallet: (palletNum: number) => void,
    clientCash: number;
    isWorker: boolean;
    setSelectedSeller: React.Dispatch<React.SetStateAction<number>>;
    handleOrderStatus: (item: IMarketCustomerWithDetails, status: number) => void;
}

const ClientDataItem = ({
    Customer_Name, Pallets, selectProduct, deleteProduct,
    handleSelectForPallet, CustomerID, item, createTeuda,
    updateTeuda, removeCustomerFromTheList, deletePallet,
    printPallet, clientCash, isWorker, CustomerSeller, 
    SellerID, Seller_Desc, handleOrderStatus
}: IProps) => {
    // TODO: make dependant on the pallets status
    const isAllowToReturnToWorker = useMemo(() => {
        return !Pallets.some(item => item.Status === 0) || isWorker
    }, [Pallets, isWorker])
    const palletProps = {
        selectProduct: selectProduct,
        handleSelectForPallet: handleSelectForPallet,
        CustomerID: CustomerID,
        deleteProduct: deleteProduct,
        CustomerSeller: CustomerSeller,
        deletePallet: deletePallet,
        printPallet: printPallet,
        isWorker: isWorker
    }

    const handleSelectPallet = () => {
        handleSelectForPallet(
            0, 
            CustomerID, 
            Customer_Name?.trim(), 
            Number(CustomerSeller.split('-')[1])
        )
    }
    console.log(Number(CustomerSeller.split('-')[1]))
    const handleCreateTeuda = () => {
        createTeuda(item)
    }
    const handleChangeStatus = () => {
        const statusData = isWorker ? 1 : 0
        handleOrderStatus(item, statusData)
    }
    const handleUpdateTeuda = () => {
        // if(!item.Delivery_Num || item.Pallets.every(item => item.isTeudaExist)) return
        updateTeuda(item)
    }
    const isAllowToRemove = item.Delivery_Num || item.Pallets.length === 0 || isWorker
    
    const handleRemoveCustomer = () => {
        if(!isAllowToRemove) return
        removeCustomerFromTheList(CustomerSeller)
    }
    return (
        <MainWrapper isExistTeuda={!!item.Delivery_Num}>
            <TitleWrapper>
                {
                    isAllowToRemove &&
                    <RemoveButton
                        onClick={handleRemoveCustomer}
                    >
                        <CloseIcon
                            handler={() => {}}
                        />
                    </RemoveButton>
                
                }
                <TitleInfoWrapper>
                    <TitleRow>
                        <TitleRowItem
                            name={Customer_Name?.trim()} 
                            icon={<UserIcon />} 
                            iconColor={clientCash === item.ClientID ? 'tomato' : ''}
                        />
                    </TitleRow>
                    <TitleRow>
                        {
                            item.Delivery_Num &&
                                <TitleRowItem
                                name={item.Delivery_Num.toString()} 
                                icon={<InvoiceIcon />} 
                                // iconColor={}
                            />
                        }
                        {
                            Seller_Desc?.trim() &&
                                <TitleRowItem
                                name={Seller_Desc?.trim()} 
                                icon={<DollarIcon />} 
                                // iconColor={}
                            />
                        }
                        
                    </TitleRow>
                    
                </TitleInfoWrapper>
                <LeftSection>
                    {/* <DropdownByIcon
                        icon={<VerticalDots />}
                    >
                        <DropDownMenu
                            func={handleCreateTeuda}
                        />
                    </DropdownByIcon> */}
                </LeftSection>
            </TitleWrapper>
            <TitlesList
                list={Pallets}
                uniqueKey={'Pallet_Num'}
                additionalData={palletProps}
                Element={PalletItem}
                noPadding={false}
            />
           
                 <ProductListWrapper>
                        <AddClientButton
                            hight='40px'
                            size='full'
                            onClick={handleSelectPallet}
                            isPrimal
                        />
                         {/* {
                            item.Delivery_Num ?
                                <AdditionalButton
                                    onClick={handleUpdateTeuda}
                                >
                                    <InvoiceIcon />
                                </AdditionalButton> 
                                :
                                <AdditionalButton
                                    onClick={handleCreateTeuda}
                                >
                                    <InvoiceIcon />
                                </AdditionalButton> 
                           
                        } */}
                        {
                            // TODO: make dependant on the pallets status
                        }
                        {
                            !isWorker ?
                            item.Delivery_Num ?
                                <AdditionalButton
                                    onClick={handleUpdateTeuda}
                                >
                                    <InvoiceIcon />
                                </AdditionalButton> 
                                :
                                <AdditionalButton
                                    onClick={handleCreateTeuda}
                                >
                                    <InvoiceIcon />
                                </AdditionalButton> 
                            : ''
                        }
                       <AdditionalButton
                            onClick={handleChangeStatus}
                            disabled={!isAllowToReturnToWorker}
                        >
                            {
                                isWorker ?
                                <AcceptIcon /> :
                                <BackHumanIcon />
                            }
                        </AdditionalButton> 
                    </ProductListWrapper>

                
          
        </MainWrapper>
    )
}
const ProductListWrapper = styled.div`
    border-radius: 30px;
    padding: 0 0.5em ;
    display: flex;
    gap: 0.5em;
`
const AdditionalButton = styled.button`
    width: 20%;
    height: 100%;
    border-radius: 12px;
    outline: none;
    border: none;
    background: #81B4AF;
    color: #fff;
    font-size: 1.1em;
    transition: 0.3s;
    cursor: pointer;    
    :hover {
        background: #297F76;
        font-size: 1.2em;
    }
    :disabled {
        opacity: 0.5;
        :hover {
            font-size: 1.1em;
            background: #81B4AF;
        }
    }
`

const MainWrapper = styled.div<{isExistTeuda?: boolean}>`
    min-width: 19em;
    height: 100%;
    background: ${props => props.isExistTeuda ? 'rgba(128,224,129, 0.5)' : '#E9F0F0'} ;
    /* background: #E9F0F0; */
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    padding: 0.5em;
`
const RemoveButton = styled.div`
    position: absolute;
    width: 2.5em;
    left: 0.25em;
    top: calc(50% - 1.25em);
    height: 2.5em;
    border-radius: 50%;
    background: #FBFCFC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        color: tomato;
    }
`
const TitleWrapper = styled.div`
    position: relative;
    max-width: 100%;
    height: 3.5em;
    border-radius: 30px;
    background: #ffff;
    color: #757575;
    display: flex;
`

const TitleInfoWrapper = styled.div`
    max-height: 100%;
    width: 78%;
    display: flex;
    flex-flow: column;
    /* gap: 0.1em; */
`
const TitleRow = styled.div`
    width: 100%;
    display: flex;
    padding: 0.3em 1em 0 0;
    overflow: hidden;
    justify-content: flex-end;
    direction: ltr
`

const LeftSection = styled.div`
    position: absolute;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        background: none;
        :hover {
            background: none;
            transform: none;
        }
    }
`

export default ClientDataItem