import { de } from "date-fns/locale";
import { useEffect, useState, useMemo, useRef } from "react"
import SearchBarGoods from '../goods/searchbarGoods'
import selectOnFocus from '../../../../../../modules/autoselect'
import { EXIST_PATH } from '../../../../../../modules/API/settings'
import WrapperList from '../../modalAzmanot/goods/wrappers/wrappersList'
import styled from "styled-components";
import { format } from "date-fns";
import { BackDataWrapper, BackItemInput, BackItemWrapper, BackWrapper, ContentWrapper, ControlitemWrapper, CountButton, CountButtonMinus, CountContainer, CountInput, FrontWrapper, IconWrapper, ImageBlock, ImageContainer, ItemMainWrapper, TextField, TitleFrontWrapper } from "../goods/goodsItem";
import { ClickIcon, MinusIcon, PackageIcon, PlusIcon, ScalesIcon, ShekelIcon } from "../../../../../../generic/icons/Icons";
import { roundToTwo } from "../../../../../../modules/roundToTwo";
import { useSelector } from "react-redux";
import DetailesModal from './DetailesModal'
import { checkPackType } from "../../../../../../generic/utils/checkPackType";

const IconDiv = styled.div`
    height: 85%;
    width: 85%;
    background: #fff;
    border-radius: 50%;
    left: 7%;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    color: #297f76;
`



export default function GoodsItemEdit({
    id, ChangeReason, Pallets, SupplyQty, isEditArizot, Price, AllowEdit, PackWeight,
    OrderPacks, ExistingStock, SalePrice, Remarks, RecID, ProductID, VarietyID, getMlai, getMhir,
    setEditGoods, productNonOrder, setProductNonOrder, ProductDesc, VarietyDesc, SizeID,
    ImageUrl, PackID, packegeList, disabelSave, showDetailes, OrderWeight,
    setDataForDetailes, getGoodPrice, item, Packs, ProductCalculationMethod, AvailablePacks
}) {

    const getRights = useSelector(rights => rights.mainReducer.user.rights);
    const editMhirMlaiStatus = getRights.some(e => e === 'OFFICE' || e === 'SELLER' || e === 'ADMIN');
    const weightRef = useRef()
    const orderedPacksRef = useRef()
    const [side, setSide] = useState(false)
    const [packs, setPacks] = useState(OrderPacks || +'');
    const [remark, setRemark] = useState(Remarks || '');
    const [packType, setPackType] = useState(PackID || '')
    const [priceGood, setPriceGood] = useState(Price || SalePrice ? roundToTwo(Price || SalePrice) : 0)
    const [mlai, setMlai] = useState(ExistingStock || +'')

    const disableSelectPacks = disabelSave || !isEditArizot
    const isAllowToEditPercanal = AllowEdit === undefined ? true : AllowEdit;
    const isDisableGeneral = !isAllowToEditPercanal || disabelSave
    // console.log('isDisableGeneral', isDisableGeneral)

    const collectData = () => {
        let newArr = [...productNonOrder]
        let found = newArr.find((item) => {
            return item.id === id;
        })
        if (found) {
            let indProd = newArr.indexOf(found)
            newArr.splice((indProd), 1)
        }
        setProductNonOrder([
            ...newArr,
            {
                "id": id,
                "RecID": RecID,
                "ProductID": ProductID,
                "VarietyID": VarietyID,
                "SizeID": SizeID,
                "PackID": +packType,
                "Packs": packs,
                "Mlai": +mlai,
                "Remark": remark,
                "Price": +priceGood || SalePrice,
                "OrderPacks": packs,
                "ProdName": `${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeID?.trim()}`
            }
        ])
    }

    const toggleSide = () => { //switch goods side
        setSide(!side)
    }

    const minusValue = () => {
        if (packs > 0) {
            setPacks(packs - 1)
        }
    }
    
    const weightCalc = useMemo(() => {
        let weight = 0
        const packTypeDef = checkPackType(packegeList, +packType)
        const packIcon = packTypeDef === '2' ? <PackageIcon /> : 
            packTypeDef === '1' ? <ScalesIcon /> :
            ProductCalculationMethod === 1 ? <PackageIcon /> : <ScalesIcon />
        console.log(packs)
        if(OrderPacks !== undefined) {
            console.log(packTypeDef)
            if(weightRef.current && orderedPacksRef.current){
                const weightperPack = Math.round(weightRef.current / orderedPacksRef.current)
                weight = packs * weightperPack
            } else {
                console.log(PackWeight)
                weight = +packs * PackWeight
            }
            console.log(weightRef)
            console.log(orderedPacksRef)
            // console.log(OrderWeight / OrderPacks)
        } else {
                console.log(packs)
                const weightPerPack = PackWeight 
                console.log(weightPerPack)
                weight = +packs * PackWeight
            

        }
        return {weight, packIcon: packIcon}
    }, [weightRef.current, orderedPacksRef.current, OrderPacks, packs,packType, ProductCalculationMethod])

    useEffect(() => {
        weightRef.current = OrderWeight
        orderedPacksRef.current = OrderPacks
    }, [])

    let bacPic = ImageUrl === '' ? 'url("https://pngicon.ru/file/uploads/otkrytaja-korobka-128x85.png") center center no-repeat' : `url("${EXIST_PATH}${ImageUrl}") center center no-repeat`
    let counterToggle = packs > 0 || OrderPacks > 0 ? 'block' : 'none';
    let displayToggle = side ? 'translateY(100%)' : 'translateY(0)';
    let displayToggleBack = !side ? 'translateY(100%)' : 'translateY(0)';
    let mlaiToggle = !getMlai ? 'hidden' : 'visible'
    let mhirToggle = getMhir || editMhirMlaiStatus ? 'visible' : 'hidden'
    let setlectedPack = productNonOrder.filter(e => e.id === id)[0]?.PackID
    console.log(AvailablePacks)
    return (
        <ItemMainWrapper
            onClick={() => {
                if (!SalePrice && !Price) {
                    console.log('getGoodPrice')
                    getGoodPrice(ProductID, VarietyID, SizeID, PackID, id, item)
                }
                setDataForDetailes({
                    data: Pallets,
                    title: ChangeReason,
                })
            }}
            onBlur={collectData}
            tabIndex={0}
            style={{ zIndex: '1' }}
        >
            <ImageContainer onClick={toggleSide}  >
                <ImageBlock style={{ background: bacPic, backgroundSize: 'contain' }} />
            </ImageContainer>
            {
                !isDisableGeneral ?
                    <div className="modal-azmanot-wrapper-inner__cards-item__front-counter" style={{ display: counterToggle }}>
                        <span>{packs}</span>
                    </div>
                    : ''
            }
            <ContentWrapper
                onClick={() => { showDetailes(isDisableGeneral, ChangeReason) }}
            >
                <FrontWrapper >
                    <TitleFrontWrapper>{ProductDesc} {VarietyDesc} {SizeID}</TitleFrontWrapper>
                    <ControlitemWrapper >
                        <PackageWrapper>
                            <WrapperList
                                disableInputs={disableSelectPacks}
                                packegeList={AvailablePacks}
                                setPackType={setPackType}
                                PackID={PackID}
                                setlectedPack={setlectedPack}
                            />
                           
                            <WeightWrapper>
                                {weightCalc.weight}
                                {weightCalc.packIcon}
                            </WeightWrapper>
                        </PackageWrapper>
                        <CountContainer style={{ background: (ChangeReason === 5) || (OrderPacks !== SupplyQty) ? 'rgba(255,99,71, 0.7)' : '' }} >
                            {
                                isDisableGeneral ?
                                    <IconDiv>
                                        <p style={{ borderBottom: '1px solid #297f76' }}>{SupplyQty}</p>
                                        <p>{packs}</p>
                                    </IconDiv> :
                                    <CountInput
                                        onWheel={(e) => e.currentTarget.blur()}
                                        
                                        placeholder="0"
                                        max="999"
                                        min={0}
                                        inputMode="numeric"
                                        type={isDisableGeneral ? 'text' : "number"}
                                        onClick={selectOnFocus}
                                        value={
                                            isDisableGeneral ? `${SupplyQty}/${packs}` : packs
                                        }
                                        disabled={isDisableGeneral}
                                        onChange={(e) => { setPacks(+e.target.value) }}
                                    />
                            }
                            {
                                isDisableGeneral ?
                                    ChangeReason === 5 ?
                                        <CountButton >
                                            <ClickIcon />
                                        </CountButton>
                                        :
                                        ''
                                    :
                                    <CountButton disabled={isDisableGeneral} onClick={() => { setPacks(packs + 1) }}>
                                        <PlusIcon />
                                    </CountButton>

                            }
                            {
                                isDisableGeneral ? '' :
                                    <CountButtonMinus disabled={isDisableGeneral} onClick={minusValue}>
                                        <MinusIcon />
                                    </CountButtonMinus>
                            }
                        </CountContainer>
                    </ControlitemWrapper>
                </FrontWrapper>
                <BackWrapper style={{ transform: displayToggleBack }}>
                    <BackDataWrapper >
                        <BackItemWrapper style={{ visibility: mhirToggle }}>
                            <BackItemInput
                                style={{ marginLeft: '0.1em' }}
                                onClick={selectOnFocus}
                                readOnly={!editMhirMlaiStatus}
                                placeholder="מחיר"
                                value={priceGood || roundToTwo(SalePrice)}
                                inputMode="decimal"
                                disabled={isDisableGeneral}
                                onChange={(e) => { setPriceGood(e.target.value) }}
                            />
                            <IconWrapper><ShekelIcon /></IconWrapper>
                        </BackItemWrapper>
                        <BackItemWrapper style={{ visibility: mlaiToggle }}>
                            <BackItemInput
                                style={{ marginLeft: '0.1em' }}
                                onClick={selectOnFocus}
                                type="number"
                                placeholder=""
                                value={roundToTwo(mlai)}
                                disabled={isDisableGeneral}
                                onChange={(e) => { setMlai(e.target.value) }}
                            />
                            <IconWrapper><PackageIcon /></IconWrapper>
                        </BackItemWrapper>
                    </BackDataWrapper>
                    <TextField
                        // onFocus={selectOnFocus}
                        type="text"
                        placeholder="הערות"
                        value={remark.trim()}
                        disabled={isDisableGeneral}
                        onChange={(e) => setRemark(e.target.value)}
                    />
                </BackWrapper>
            </ContentWrapper>
        </ItemMainWrapper>
    )
}

const PackageWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    gap: 0.2em;
    /* background-color: red; */
    padding: 0.2em 0;
`
const WeightWrapper = styled.div`
    height: 1.8em;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #297F76;
    padding: 0 0.5em;
    gap: 0.3em;
    border-radius: 30px;
    background-color:#eff3f4;
`