import React from 'react'
import { IGetCefiByIDLines } from '../../../../../modules/API/Cefi/models/req'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import styled from 'styled-components'
import ProductImage from '../../queries/TeudotDetails/Components/ProductImage'
import { AppleIcon, PackageIcon, PalletIcon, ScalesIcon } from '../../../../../generic/icons/Icons'

interface IProps extends IGetCefiByIDLines {

}

const CefiItemView = ({
  AmountPack, AmountPallets, Gross, ProductDesc, 
  VarietyDesc, SizeDesc, ImageUrl
}: IProps) => {
  const img = ImageUrl ? Number(`${ImageUrl}`.split("/").at(-1)) ?? 0 : 0;

  return (
    <TitleLayout>
      <SideWrapper>
            <ProductImage
                id={img}
                size='sm'
            />
        </SideWrapper>
        <TitleRow>
          <TitleRowItem 
            name={`${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeDesc?.trim()}`}
            icon={<AppleIcon />}
          />
        </TitleRow>
        <TitleRow>
          <TitleRowItem 
            icon={<PalletIcon />}
            name={`${AmountPallets}`}
          />
          <TitleRowItem 
            icon={<ScalesIcon />}
            name={`${Gross.toLocaleString()}`}
          />
          <TitleRowItem 
            icon={<PackageIcon />}
            name={`${AmountPack}`}
          />
        </TitleRow>
        <TitleRow>
          <></>
        </TitleRow>
    </TitleLayout>
  )
}
const SideWrapper = styled.div`
    position: absolute;
    left: 1em;
    top: 0;
`
export default CefiItemView