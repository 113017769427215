import { AnyAction } from "redux"
import { ISortingListItem } from "../../components/modules/API/Miun/models/sortingList"
import { CLEAR_PACKAGES_STATE, SELECT_DATE_FOR_PACKAGES, SELECT_SORTING_DATA, SELECT_SORTING_DATE, UPDATE_ENTRIES } from "../actions/actionsTypes"

export interface IPacakgeData {
    date: string | null | [string | null, string | null]
}

const initialState: IPacakgeData = {
    date: null
}

const packageReducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_DATE_FOR_PACKAGES:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_PACKAGES_STATE:
            return initialState
              
        default: return state
    }
}

export default packageReducer