import React from 'react'
import styled from 'styled-components'

type Props = {
    children?: React.ReactNode | React.ReactNode[]
}

const ControlSearchPannelLayout = ({children}: Props) => {
  return (
    <SelectPanelWrapper>
        {children}
    </SelectPanelWrapper>
  )
}

export default ControlSearchPannelLayout

export const SelectPanelWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 4.5em;     
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
    background: #fdfdfd;
    padding: 0 0.5em;
    border-radius: 40px;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
`