 import React from 'react'
import './footer.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';



function Footer() {
    AOS.init();
    return(
        <footer className="footer" id="mainFooter">
            <div className="footer-container">

                <div data-aos="fade-right" data-aos-once="true" className="footer-container__column column column-map"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3373.262732992556!2d34.8580889!3d32.2779135!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzLCsDE2JzQxLjkiTiAzNMKwNTEnMjkuNiJF!5e0!3m2!1sen!2sil!4v1609674120288!5m2!1sen!2sil" width="100%" height="250px" frameBorder="0"  allowFullScreen="" aria-hidden="false" tabIndex="0" title="map"></iframe></div>
                <ul className="footer-container__column column column-contact">
                    <h2>צור קשר</h2>
                    <li className="column-item item-mob">
                        <a href="tel:+972522680167">052-2680167</a>
                        <i className="fas fa-mobile-alt"></i>
                    </li>
                    <li className="column-item item-phone">
                        <a href="tel:+972778855866">077-8855866</a>
                        <i className="fas fa-phone-square-alt"></i>
                    </li>
                    <li className="column-item item-faks">
                        <a href="tel:+972778855866">072-3355866</a>
                        <i className="fas fa-fax"></i>
                    </li>
                    <li className="column-item item-mail">
                        <a href="mailto:info@magicline.co.il">info@magicline.co.il</a>
                        
                        <i className="fas fa-envelope"></i>
                    </li>
                    
                    <div className="item-container">
                        {/* links */}
                        <ul className="item-container-icon">
                            <li className="icon-item"  ><a href="https://www.facebook.com/MagicLineSoftware/"><i className="fab fa-facebook-square" alt="facebook"></i></a></li>
                            <li className="icon-item" ><a href="https://twitter.com/intent/follow?original_referer=http%3A%2F%2Fwww.magicline.co.il%2F&ref_src=twsrc%5Etfw&region=follow_link&screen_name=magicline_&tw_p=followbutton"><i className="fab fa-twitter-square" alt="twitter"></i></a></li>
                            <li className="icon-item" ><a href="https://wa.me/972522680167"><i className="fab fa-whatsapp-square" alt="whatsapp"></i></a></li>
                            <li className="icon-item" ><a href="skype:magic.line"><i className="fab fa-skype" alt="skype"></i></a></li>
                        </ul>
                    </div>
                </ul>
                <div data-aos="fade-left" data-aos-once="true" className="footer-container__column column-about"><h2>אודות</h2>
                    <p>
                    מג'יק ליין הינו בית תוכנה שהוקם בשנת 1998,<br />
                     מתמחה באפיון, תכנון וביצוע של פרויקטים<br /> 
                     מורכבים הכוללים בסיסי נתונים, מערכות תוכנה<br /> 
                     כשירות (SaaS), יישומי אינטרנט עשירים (RIA),<br /> 
                     פיתוח ותחזוקת מערכות מידע, אינטגרציה ,תוכנות<br />
                     ניהול לעסקים ולארגונים המותאמים לצרכים<br /> 
                     הספציפיים של החברה. חברתנו הינה שותף עסקי<br /> בכיר של חברת מג'יק תעשיות תוכנה בע"מ ובעלת<br/> 
                     ידע מוכח ביעוץ ותמיכה מקצועית למערכות<br />
                      מחשוב ארגוניים.
                    </p>
                
                </div>
            </div>
        </footer>

    );
}

export default Footer;
