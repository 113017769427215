import GoodsItemEdit from './goodsItemEdit'
import { Wrapper } from '../goods/goodsList';

export default function ModalEditGoodsList({editGoods , editGoodsDefault, productEditList, 
                                            setProdactEditList, setEditGoods, productNonOrder, 
                                            getMlai, getMhir, showDetailes, setDataForDetailes,
                                            setProductNonOrder,disabelSave, packegeList,
                                            isEditArizot, getGoodPrice
                                        }) {
    
    
    return(
        <Wrapper>
            {editGoods.map(item => <GoodsItemEdit key={item.id} {...item} 
                setProductNonOrder={setProductNonOrder}
                productEditList={productEditList} 
                setProdactEditList={setProdactEditList}
                setEditGoods={setEditGoods}
                productNonOrder={productNonOrder}
                packegeList={packegeList}    
                disabelSave={disabelSave}
                getMlai={getMlai}
                getMhir={getMhir}
                showDetailes={showDetailes}
                setDataForDetailes={setDataForDetailes}
                isEditArizot={isEditArizot}
                item={item}
                getGoodPrice={getGoodPrice}
                />)}
        </Wrapper>
        
    );
}