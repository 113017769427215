import React from 'react'
import styled from 'styled-components'
import { ICollectDataOutput, ITitleItemP, TitleItem, TitleWrapper } from './StatisticTable'

interface IMarckerWrapper {
    percents?: number
}

const TitleCombineWrapper = styled.div`
    display: flex;
    width: 64%;
    flex-flow: column;
    gap: 0.4em;
`

const InnerRaw = styled.div<ITitleItemP>`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    
`

const ModTitleItem = styled.div<ITitleItemP>`
    height: 1.3em;
    display: flex;
    min-width: ${props => props.isLong ? '28%' : '20.7%'};
    flex-flow: column;
    align-items: flex-end;
    color: #297f76;
    font-weight: 600;
    border-left: ${props => props.border ? '2px solid #297f76' : 'none'} ;
    /* background: red; */
`

const MarckerWrapper = styled.div<IMarckerWrapper>`
    padding: 0.2em;
    width: 5em;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #fff;
    background: ${props => props.percents && props.percents > 100 ? 'rgba(255,99,71, 0.8)' : 
        props.percents && props.percents > 90 ? '#80e081' : 
            props.percents && props.percents < 50 ? 'rgba(255,99,71, 0.8)' : 
                'rgba(255,165,0, 0.8)'
    
    };
`

const RowElement = ({CarNum, Detailes, DriverName}: ICollectDataOutput) => {
    // console.log(Detailes)
  return (
    <TitleWrapper length={Detailes.length > 1 ? Detailes.length : 1.5}>
        <TitleItem border isLong>{DriverName}</TitleItem>
        <TitleItem border>{CarNum}</TitleItem>
        <TitleCombineWrapper>
            {Detailes.map((item, index)=>
                <InnerRaw key={index} >
                    <ModTitleItem border isLong>{item.AreaDesc}</ModTitleItem>    
                    <ModTitleItem border>{item.UniqPoints}</ModTitleItem>    
                    <ModTitleItem border>{item.PalletsQty}</ModTitleItem>    
                    <ModTitleItem >
                        <MarckerWrapper percents={Math.round(item.PalletsQty / item.MaxFillCarByPallets * 100)}>
                            {Math.round(item.PalletsQty / item.MaxFillCarByPallets * 100)}%
                        </MarckerWrapper>
                    </ModTitleItem>    
                </InnerRaw>
            )}
        </TitleCombineWrapper>
    </TitleWrapper>
  )
}

export default RowElement