import { AnyAction } from "redux"
import { CLEAR_CLIENT_BILLS, SELECT_DATA_CLIENT_BILLS } from "../actions/actionsTypes"

export interface IDriverState{
    date: string | null | [string | null, string | null]
}

const initialState: IDriverState = {
    date: null
}

const clientBillsReducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SELECT_DATA_CLIENT_BILLS:
            return {
                ...state,
                date: action.payload
            }
        case CLEAR_CLIENT_BILLS:
            return {
                ...initialState
            }
        default: return state
    }
}

export default clientBillsReducer