
import { addIDForTheList } from '../../generic/utils/addUIDForTheList';

export const getPallets = async (token, url, setState, setStateDefault, message, setMessage) => {
    let axios = require('axios');
    const CancelToken = axios.CancelToken.source();
    try{
        let response = await axios.get(`${url}`, {
            headers: {
                'Authorization' : 'Bearer ' + token
            }
        },
        {
            cancelToken: CancelToken.token
        })
        let concatArr = addIDForTheList(response.data.pallets);
        setState(concatArr);
        setStateDefault(concatArr);
    
    } catch (err) {
        setMessage({
            open: true,
            value: err.response.data.message,
            mood: false
          })
          setTimeout(() => {
            setMessage({
              ...message,
              open: false
            })
          }, 3000)
    }
}