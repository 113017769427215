import React, {useState, useMemo} from 'react'
import { IPackageLogData } from '../../../../../modules/API/Drivers/models/packageLogData'
import styled from 'styled-components'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import PackageLogItem from './PackageLogItem'
import { PackageIcon, PalletIcon } from '../../../../../generic/icons/Icons'

type Props = {
  list: IPackageLogData[]
}

const StatisticPackageModal = ({
  list
}: Props) => {
  const [activeTab, setActiveTab] = useState<string | null>('הכול');

  const tabs = useMemo(() => {
    const data = Array.from(new Set(list.map(item => item.PD_Type)))
    data.push('הכול')
    return data
  }, [list])

  const render = useMemo(() => {
    if (!list) return []
    if (activeTab && activeTab !== 'הכול') {
      return list.filter(item => item.PD_Type === activeTab).sort((a, b) => Number(a.HasTeuda) - Number(b.HasTeuda))
    } else {
      return list.sort((a, b) => Number(a.HasTeuda) - Number(b.HasTeuda))
    }
  }, [activeTab, list])

  return (
    <Container>
      <TabContainer>
        {tabs.map(tab => (
          <Tab 
            key={tab}
            active={activeTab === tab}
            onClick={() => setActiveTab(tab)}
          >
            {
              tab === 'א' ? <PackageIcon /> :
              tab === 'מ' ? <PalletIcon /> :
              tab
            }
          </Tab>
        ))}
      </TabContainer>
      <TitlesList 
        list={render}
        noPadding={false}
        Element={PackageLogItem}
      />
    </Container>
  )
}


const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  /* background: blue; */

  display: flex;
  flex-direction: column;
`;
const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
  border-radius: 30px 30px 0 0;
`;

const Tab = styled.div<{active: boolean}>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${props => 
        props.active ? 
            `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
            :
            `
            
            `
  }
`;
export default StatisticPackageModal