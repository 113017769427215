import React from 'react'
import styled from 'styled-components'
import CustomBlockLoader from '../../../knisot/generic/CustomBlockLoader'

type Props = {
    children: React.ReactNode | React.ReactNode[],
    isLoading?: boolean;
}

const Q4Layout = ({children, isLoading}: Props) => {
  return (
    <MainWrapper>
        {
            isLoading ?
                <CustomBlockLoader /> :
                    children
        }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 1em;
    background: #fff;
    border-radius: 30px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

`
export default Q4Layout