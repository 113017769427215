import React from 'react'
import { Select, Wrapper } from '../palletType/LikutPalletTypeList'
import LikutExpressPrintersItem from './LikutExpressPrintersItem'

export default function LikutExpressPrintersList({printersList, setPrintersModal, saveToLocalPrinter, printerNum, format}) {
    return (
        <Wrapper>
            <Select onChange={e => {
                    saveToLocalPrinter(e.target.value);
                    // setPrintersModal(false) 
                }} >
                <option value=''>
                    {format ? 'בחר פורמט הדפסה': 'בחר מדפסת'} 
                </option>
                {printersList.map(item => <LikutExpressPrintersItem key={item.NumWeight} {...item} printerNum={printerNum} />)}
            </Select>
        </Wrapper>
    )
}
