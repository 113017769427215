import { TMainCodes } from "../components/generic/artcoded/modulesList";
import { IAuthResponse, ICompanyItem } from "../components/modules/API/Auth/models/authDataModel";

export interface IInitialState {
    companies: ICompanyItem[] | null,
    current_company_id: number | null,
    current_company_internal: number | null,
    refresh_token: string | null,
    token: string | null
    isAuthenticated: boolean,
    loading: boolean,
    user: IUser | null,
    error: string | null,
    login: string | null,
    modules: IModules[] | null,
    workerType: number | null
}

const initialState = {
    // token: localStorage.getItem('ctx'),
    isAuthenticated: false,
    loading: false,
    user: null,
    error: null,
    login: null,
    modules: null,
    workerType: null,
    companies: null,
    current_company_id: null,
    current_company_internal: null,
    refresh_token:null,
    token: null
};

interface IUser {
    ctx: string 
    name: string 
    code_internal: string 
    company: string 
    company_id: string 
    company_internal: string 
    sql_address_internal: string
    sql_server_name: string 
    year: string,
    rights: string[],
    tranzit: string,
    term: string,
    internal: boolean,
  
}
export interface IModules {
    Description: string,
    ModuleId: TMainCodes,
    Sub: ISubModules[]
}
interface ISubModules {
    Sub_ModuleID: number,
    Description: string  
}
export default initialState;