import React, { useEffect } from 'react'
import './statdiv.scss'
import AOS from 'aos';
import 'aos/dist/aos.css'; 



function Block() {
  
    useEffect(()=> {
        const counters = document.querySelectorAll('.stat-container__item');
        const speed = 400;
        counters.forEach(counter => {
            const updateCount = () => {
                const target = +counter.getAttribute('data-target');
                const count = +counter.innerText;
                // console.log(target);
                const inc = target / speed;
                //   console.log(inc);
                if(count < target){
                    counter.innerText = Math.ceil(count + inc);
                    setTimeout(updateCount, 1)
                } 
            }     
            updateCount()
        });

    }, [])
    AOS.init();
    return(
        <section className="stat" >
            <div className="stat-container" data-aos-once="true" data-aos="fade-up">
                <span><i className="fas fa-user"></i></span>
                <h2>לקוחות</h2>
                <div className="stat-container__item" data-target="150">0</div>
            </div>
            <div className="stat-container" data-aos-once="true" data-aos="fade-up">
                <span><i className="far fa-calendar-check"></i></span>
                <h2>מוניטין</h2>
                <div className="stat-container__item" data-target="23">0</div>
            </div>
            <div className="stat-container" data-aos-once="true" data-aos="fade-up">
                <span><i className="fas fa-cog"></i></span>
                <h2>משתמשים</h2>
                <div className="stat-container__item" data-target="500">0 </div>
            </div>
        </section>
    );
}

export default Block;