import React, { useContext, useState, useTransition } from 'react'
import { useSelector } from 'react-redux';
import ControlButton from '../../../../../generic/buttons/ControlButton';
import { CloseIcon } from '../../../../../generic/icons/Icons';
import { LikutModals } from '../../../../../modules/Context/CefiNum';
import CloseBtnReson from './parts/CloseBtnReson';
import PassFieldReson from './parts/PassFieldReson';
import ResonsList from './parts/ResonsList';
import { CloseButtonReson, ResonBtnAcceptWrapper, ResonContentItemElement, ResonContentItemTitle, ResonContentItemWrapper, ResonContentWrapper, ResonWrapper } from './ResonWindowsStyles'
import { useTranslation } from 'react-i18next';

export default function MoreThenNeed({resons}) {
    const [t] = useTranslation('buttons')
    const artPass = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 577)[0].Text_Value?.trim())
   
    const [resonState, setResonState] = useContext(LikutModals);
    const [resonId, setResonId] = useState(null);
    const [inputPass, setInputPass] = useState('');

    const acceptFunc = () => {
        if (!resonId) return
        // if cant find good in order 
        if(resonId === 2 && resons.num === 1 ) {
            if (inputPass === artPass) {
                resonState.action(true);
                setResonState({
                    ...resonState, 
                    'active': false,
                    'props': {
                        ...resonState.props,
                        resonID: resonId,
                        problemID: resons.num
                    }
                })
            } else {
                
            }
        }

        else if (resonId === 5) {
            resonState?.props?.setRowModal(true);
            setResonState({
                ...resonState, 
                'active': false,
                'props': {
                    ...resonState.props,
                    resonID: resonId,
                    problemID: resons.num
                }
            })
        }

        else {
            
            if (resonId === 2) {
                if(inputPass === artPass) {
                    resonState.action(resons.num, resonId); 
                    setResonState({...resonState, 'active': false});
                }
                // atamat mishkal =>
            } else if (resonId === 1) {
                // resonState.action(resons.num, resonId);
                // calc itra by order weigth 
                resonState?.props?.setPacks(resonState?.props?.calcFunc())
            
                
                setResonState({
                    ...resonState, 
                    'active': false,
                    'props': {
                        ...resonState.props,
                        resonID: resonId,
                        problemID: resons.num
                    }
                })
                // setResonState({...resonState, 'active': false});

            }
            
            else {
                resonState.action(resons.num, resonId); 
                setResonState({...resonState, 'active': false});
            }
        }
    }
    return (
        <ResonWrapper>
            <CloseBtnReson />
            {/* <Decorator /> */}
            <ResonContentWrapper>
                <ResonContentItemWrapper>
                    <ResonContentItemTitle primal>
                        {resons?.tag}
                    </ResonContentItemTitle>
                    <ResonContentItemElement>
                        <ResonsList resons={resons?.resons} setResonId={setResonId} resonId={resonId} setInputPass={setInputPass} artPass={artPass} acceptFunc={acceptFunc} />
                    </ResonContentItemElement>
                </ResonContentItemWrapper>
                <ResonBtnAcceptWrapper>
                    <ControlButton 
                        lable={t("save")}
                        handleClick={acceptFunc}
                        primal={resonId}
                    />
                </ResonBtnAcceptWrapper>
            </ResonContentWrapper>
        </ResonWrapper>
    )
}
