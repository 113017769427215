import React from 'react'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { IEntrieDetails } from '../../../../../modules/API/Knisot/models/entrie'
import { stringToTime } from '../generic/helpers/convert'
import { convertToDate } from '../../../../../modules/convertDateString'
import { BarCodeIcon, CalendatDay, InvoiceIcon, TimeIcon, UserIcon } from '../../../../../generic/icons/Icons'

type Props = {
  data: IEntrieDetails
}

const TitleKnisa = ({data}: Props) => {
  const formatedTime = stringToTime(data.EntryTime || "")
  const formatedDate = convertToDate(data.EntryDate)
  return (
    <TitleLayout disableShadow>
        <TitleRow>
          <TitleRowItem name={data.Reference.toString() || ""} icon={<InvoiceIcon />} />
          <TitleRowItem name={data.EntryNum.toString() || ""} icon={<BarCodeIcon />} />
        </TitleRow>
        <TitleRow>
          <TitleRowItem name={formatedTime} icon={<TimeIcon />} />
          <TitleRowItem name={formatedDate} icon={<CalendatDay />} />
        </TitleRow>
        <TitleRow>
          <TitleRowItem name={data.GrowerName.trim() || ''} icon={<UserIcon />} />
        </TitleRow>
      </TitleLayout>
  )
}

export default TitleKnisa