import React from 'react'
import styled from 'styled-components';
import CustomBlockLoader from '../../../knisot/generic/CustomBlockLoader';

type Props = {

}

const LoaderInfinitScroll = ({}: Props) => {
  return (
    <MainWrapper>
        <CustomBlockLoader />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    width: 100%;
    height: 4.5em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
`
export default LoaderInfinitScroll