import React from 'react'
import { IPatchStatusOrderLine } from '../../../../../modules/API/AzmanotRehesh/models/order';
import { IOrderReheshSelected } from '../AzmanotReheshMain';
import styled from 'styled-components';
import Image from '../../../../../generic/assets/cogsPNG.png'
import { statusOrderRehesh } from '../helpers/statuses';
import { AppleIcon } from '../../../../../generic/icons/Icons';

interface SaveFunction {
    (data: IPatchStatusOrderLine[], parentID: number): void;
    (status: number): void;
}

type Props = {
    data: IOrderReheshSelected;
    save: SaveFunction
    isMassSave?: boolean
}

const StatusModal = ({ data, save, isMassSave }: Props) => {
    const [Remarks, setRemarks] = React.useState<string>(data?.Remarks?.trim() || '')
    const handleSelectStatus = (status: number) => {
        if (!isMassSave) {
            const dataToSave: IPatchStatusOrderLine[] = [
                {
                    RecID: data.RecID,
                    Status: status,
                    Remarks: Remarks
                }
            ]
            save(dataToSave, data.parrentID)
            return
        }
        save(status)
    }
    return (
        <MainWrapper>
            <Img src={Image} alt='pic' />
            <SelectStatusWrapper>
                {
                    !isMassSave ?
                        <DescBlock>
                            <DescItem>
                                {`${data.ProductDesc?.trim()} ${data.VarietyDesc?.trim()}`}
                                <p> - </p>
                                {`עדכון סטטוס`}
                            </DescItem>
                        </DescBlock> :
                        <DescBlock>
                            עדכון סטטוס לכל הפריטים
                        </DescBlock>
                }
                {
                    !isMassSave &&
                    <TextArea
                        placeholder='הערות'
                        value={Remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                }
                <StatusWrapper
                    isPrimal
                    onClick={() => handleSelectStatus(3)}
                >
                    {statusOrderRehesh[3]}
                </StatusWrapper>
                <StatusWrapper
                    onClick={() => handleSelectStatus(2)}
                >
                    {statusOrderRehesh[2]}
                </StatusWrapper>
            </SelectStatusWrapper>
        </MainWrapper>
    )
}
const DescBlock = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-flow: column;
    /* justify-content: flex-end; */
    padding: 1em;
    color: #297F76;

`
const DescItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* font-size: 1.2em; */
    gap: 0.5em;

`
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 1em;
    padding: 1em;
`
const Img = styled.img`
    min-width: 17em;
`
const SelectStatusWrapper = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
`
const StatusWrapper = styled.div<{ isPrimal?: boolean }>`
    width: 100%;
    height: 2.5em;
    border-radius: 30px;
    background: ${props => props.isPrimal ? '#297F76' : 'tomato'};
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        opacity: 0.8;
    }
`
const TextArea = styled.textarea`
  appearance: none;
  border-radius: 30px;
  border: none;
  width: 100%;
  background: #EFF3F4;
  padding: 1em;
  font-size: 1em;
  font-family: 'Assistant', sans-serif;
  outline: none;
  direction: rtl;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default StatusModal