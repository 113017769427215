import React, { useEffect, useState } from 'react';

interface Position {
  latitude: number;
  longitude: number;
}

interface GeolocationState {
  position: Position | null;
  error: string | null;
  isPermissionDenied: boolean;
}
interface SyncManager {
    getTags(): Promise<string[]>;
    register(tag: string): Promise<void>;
  }
  
//   declare global {
//     interface ServiceWorkerRegistration {
//       readonly sync: SyncManager;
//     }
  
//     interface SyncEvent extends ExtendableEvent {
//       readonly lastChance: boolean;
//       readonly tag: string;
//     }
  
//     interface ServiceWorkerGlobalScopeEventMap {
//       sync: SyncEvent;
//     }
//   }
const useGeolocation = (promptForPermission = false): GeolocationState => {
  const [position, setPosition] = useState<Position | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isPermissionDenied, setIsPermissionDenied] = useState<boolean>(false);

  useEffect(() => {
    let watchId: number;

    const successHandler = (position: GeolocationPosition) => {
      const newPosition = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      setPosition(newPosition);
      setError(null);
      setIsPermissionDenied(false);
    
    };

    const errorHandler = (error: GeolocationPositionError) => {
      setError(error.message);
      setIsPermissionDenied(error.code === error.PERMISSION_DENIED);
      if (promptForPermission && error.code === error.PERMISSION_DENIED) {
        const shouldPrompt = window.confirm('Please enable location sharing to proceed.');
        if (shouldPrompt) {
          window.location.reload();
        }
      }
    };
    console.log(navigator.geolocation)
    watchId = navigator.geolocation.watchPosition(successHandler, errorHandler);

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [promptForPermission]);


  return { position, error, isPermissionDenied };
};

export default useGeolocation;