import React, {useState, useEffect, useMemo} from 'react'
import styled from 'styled-components';
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead';
import FullSizeLayout from '../../../../../generic/Layouts/FullSizeLayout';
import { Decorator } from '../../../../../generic/Layouts/OrderlikeLayout';
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout';
import Title from '../../../../../generic/Title/Title';
import { Gathering } from '../../../../../modules/API/Gathering/gathering';
import { IStatisticWorkerItem } from '../../../../../modules/API/Gathering/models/statisticWorker'
import ImageGeneral from '../../../../../generic/assets/package.png'
import ImageLess from '../../../../../generic/assets/ahslanot.png'
import ImageAvg from '../../../../../generic/assets/warhouseWork.webp'
import MainWorkers from './workersPanel/MainWorkers';
import { format } from 'date-fns';
import { useInterval } from '../../../../../modules/autoCarusel';


type Props = {}

interface IStatisticReduce {
    collectedGeneral: number,
    // collectedAvg?: number,
    topCollector: IStatisticWorkerItem,
    lessCollector: IStatisticWorkerItem
}
const setDateFunc = () => {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    return {
        now: format(now, 'yyyyMMdd'),
        firstDay: format(firstDay, 'yyyyMMdd'),
    }
}
const WorkersStatisticMain = (props: Props) => {
    const [workersData, setWorkersData] = useState<IStatisticWorkerItem[]>([]);
    const [isActiveInterval, setIsActiveInterval] = useState(true);
    const [isDayFlag, setIsDayFlag] = useState<boolean>(true)
    const [currentDate, setCurrentDate] = useState<string>(() => setDateFunc().now);
    const [startOfMonthDate, setStartOfMonthtDate] = useState<string>(() => setDateFunc().firstDay);

    const intercalFunc = () => {
        if(!isActiveInterval) return
        setIsDayFlag(!isDayFlag)
    }
    
    const getData = async () => {
        const startDate = isDayFlag ? currentDate : startOfMonthDate
        try {
            const res = await Gathering.gatheringStats(startDate, currentDate)
            setWorkersData(res)
        } catch (err) {

        } finally {

        }
    }
    const statisticData = useMemo(() => {
        const {collectedGeneral, topCollector, lessCollector} = workersData.reduce((acc: Record<string, any>, cur) => {
            acc.collectedGeneral = (acc.collectedGeneral || 0) + cur.Qty
            acc.topCollector = acc.topCollector ? 
            acc.topCollector.Qty < cur.Qty ? cur : acc.topCollector 
                    : cur
                    acc.lessCollector = acc.lessCollector ? 
                acc.lessCollector.Qty > cur.Qty ? cur : acc.lessCollector 
                    : cur
                    

            return acc
        }, {})
        const collectedAvg = Math.round(collectedGeneral / workersData.length)

        return {collectedGeneral, collectedAvg, topCollector, lessCollector}
    }, [workersData])
    const avgInPers = () => {
        return Math.round(statisticData.collectedAvg / statisticData.topCollector?.Qty * 100)
    }
    const leastOfAllInPers = () => {
        return Math.round(statisticData.lessCollector?.Qty / statisticData.collectedAvg * 100)
    }
    console.log(avgInPers())
    console.log(statisticData)
    useEffect(() => {
        getData()
    }, [isDayFlag])
    useInterval(intercalFunc, 30000)
   
  return (
    <FullSizeLayout label='דשבורד מלקטים'>
        <ControlSearchPannelLayout>
            <SelectedDateIntervalWrapper 
                isActive={isDayFlag}
            >
                יומי 
            </SelectedDateIntervalWrapper>
            <SelectedDateIntervalWrapper 
                isActive={!isDayFlag}
            >
                חודשי
            </SelectedDateIntervalWrapper>
        </ControlSearchPannelLayout>
        <BodyContentWrapper>
            <GeneralStatisticWrapper>
                <GeneralStatisticTitle>
                    <StatIndicatorWrapper>
                        <CircleWrapper>
                            {/* <DecoratorStat>
                                general Collected
                            </DecoratorStat> */}
                            <InnetText>
                                {statisticData.collectedGeneral || 0}
                            </InnetText>
                            <Svg>
                                <Circle primal={100} cx="75" cy="75" r='75'></Circle>
                                <Circle primal={100} cx="75" cy="75" r='75'></Circle>
                            </Svg>

                        </CircleWrapper>
                    </StatIndicatorWrapper>
                    <StatContntWrapper>
                        <ImageWrapper>
                            <ImageComponentHead 
                                img={ImageAvg}
                                
                            />

                        </ImageWrapper>
                        <Paragraph>
                          סה"כ
                        </Paragraph>
                    </StatContntWrapper>
                </GeneralStatisticTitle>
                <GeneralStatisticTitle>
                    <StatIndicatorWrapper>
                        <CircleWrapper>
                            {/* <DecoratorStat>
                                Avg by worker
                            </DecoratorStat> */}
                            <InnetText>
                                {statisticData.collectedAvg || 0}
                            </InnetText>
                            <Svg>
                                <Circle primal={avgInPers()} cx="75" cy="75" r='75'></Circle>
                                <Circle color='#ff9f00' primal={avgInPers()} cx="75" cy="75" r='75'></Circle>
                            </Svg>

                        </CircleWrapper>
                    </StatIndicatorWrapper>
                    <StatContntWrapper>
                        <ImageWrapper>
                            <ImageComponentHead 
                                img={ImageGeneral}
                            />
                        </ImageWrapper>
                        <Paragraph>
                            ממוצע למלקט
                        </Paragraph>
                    </StatContntWrapper>
                </GeneralStatisticTitle>
                <GeneralStatisticTitle>
                    <StatIndicatorWrapper>
                        <CircleWrapper>
                            {/* <DecoratorStat>
                                least of all
                            </DecoratorStat> */}
                            <InnetText>
                                {statisticData.lessCollector?.Qty || 0}
                            </InnetText>
                            <Svg>
                                <Circle primal={leastOfAllInPers()} cx="75" cy="75" r='75'></Circle>
                                <Circle color='tomato' primal={leastOfAllInPers()} cx="75" cy="75" r='75'></Circle>
                            </Svg>
                        </CircleWrapper>
                    </StatIndicatorWrapper>
                    <StatContntWrapper>
                        <ImageWrapper>
                            <ImageComponentHead 
                                img={ImageLess}
                                small
                            />
                        </ImageWrapper>
                        <Paragraph>
                            מקום אחרון
                        </Paragraph>
                    </StatContntWrapper>
                </GeneralStatisticTitle>
            </GeneralStatisticWrapper>
            <PersanalStatisticWrapper>
                <MainWorkers list={workersData} avgCollection={statisticData.collectedAvg} />
            </PersanalStatisticWrapper>
        </BodyContentWrapper>

    </FullSizeLayout>
  )
}
const BodyContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    background: #ffff;
    border-radius: 30px;
    padding: 0.5em;
    overflow: hidden;
    /* z-index: 1; */
`
const GeneralStatisticWrapper = styled.div`
    width: 40%;
    height: 100%;
    background: #f0f7f7;
    border-radius: 30px;
    z-index: 1;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    gap: 1em;
`
const GeneralStatisticTitle = styled.div`
    width: 100%;
    height: 30%;
    /* background: red; */
    background: #fff;
    border-radius: 30px;
    display: flex;
    -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
    filter: drop-shadow(2px 3px 3px rgb(215,231,242));
`
const StatIndicatorWrapper = styled.div`
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    /* padding-top: 1em; */
    /* background: rgba(255,255,255,0.6); */
    -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
    filter: drop-shadow(2px 3px 3px rgb(215,231,242));

    
`
const CircleWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
`
export const InnetText = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #297f76;
  /* background: red; */
`
const SelectedDateIntervalWrapper = styled.div<{isActive: boolean}>`
    width: 100%;
    height: 80%;
    background: ${props => props.isActive ? '#297f76' : '#edf5f6'};
    color: ${props => props.isActive ? '#fff' : '#297f76'};
    opacity: ${props => props.isActive ? '1' : '0.7'};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
    transition: 0.5s ease-in-out;
    border-radius: 30px;
`
const StatContntWrapper = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    /* background: yellowgreen; */
`
const ImageWrapper = styled.div`
    height: 70%;
    width: 100%;
`
const Paragraph = styled.p`
    color: #297f76;
    font-weight: 600;
    font-size: 1.1em;
`
const PersanalStatisticWrapper = styled.div`
    width: 60%;
    height: 100%;
    background: #f0f7f7;
    border-radius: 30px;
    z-index: 1;
    
`

const Svg = styled.svg`
  position: relative;
  width: 160px;
  height: 160px;
  
  /* background: red; */
  
  
`
const Circle = styled.circle<{primal: string | number, color?: string}>`
  fill: none;
  stroke-width: 10;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 470;
  stroke-dashoffset: 470;
  :nth-child(1) {
    stroke-dashoffset: 0;
    stroke: #f3f3f3;
  }
  :nth-child(2) {
    stroke-dashoffset: ${props => props.primal ? `calc(470 - (470 * ${props.primal}) / 100)` : '440'};
    stroke: ${props => props.color ? props.color : '#81b4af'};
    border-radius: 50%;
    stroke-linecap: round;
  }

`


export default WorkersStatisticMain