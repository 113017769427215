import styled from 'styled-components'
import InternalCompaniesItem from './internalCompaniesItem'

const Select = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 2px solid #80e081;
    outline: none;
    appearance: none;
    direction: rtl;
    padding: 0 0.5em;
    background: #fff;
    cursor: pointer;
    font-size: 1em;
    padding-left: 2em;
`


export default function InternalCompaniesList({internalList, setInternalId}){

    return(
        <Select
            onChange={(e)=>{setInternalId(e.target.value)}}
        >
            {internalList?.map(item => <InternalCompaniesItem key={item.id}{...item} />)}
        </Select>
    )
}