import { requests } from "../config";
import { IPostRecommendationProduct } from "./models/postModel";
import { IRecommendationItem } from "./models/recomendationItem";

export const Recommendation = {
  getRecommendationByGrowerID: (growerID: string| number): Promise<IRecommendationItem[]> => 
    requests.get(`recommendation?GrowerID=${growerID}`, 'go'),
  createRecommendation: (data: IPostRecommendationProduct): Promise<IRecommendationItem> => requests.post('recommendation', 'go', data),
  deleteRecommendation: (RecID: number | string) => requests.delete(`recommendation/${RecID}`, 'go'),
  
}