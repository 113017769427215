import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SELECT_DATA_FOR_ORDER_PACKAGE } from '../../../../../../store/actions/actionsTypes'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import { IGrowerItem } from '../../../../../modules/API/Drivers/models/kavData'
import { IOrderPackageTitle } from '../../../../../modules/API/Packages/models/createPackageOrderModel'
import { LINKS } from '../../../../../Routs/config'
import GroverMain from './QuestsComponents/GroverMain'

type Props = {
  list: IGrowerItem[],
  carID: number,
  date: string,
  updateState: (item: IOrderPackageTitle) => void
}

const QuestsModal = ({list, carID, date, updateState}: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  console.log(list)
  const listData = useMemo(() => {
    return list
  }, [list])
  console.log(updateState)
  const selectDataForPackageOrder = (data: IGrowerItem) => {
    const collection = {
      carID: carID,
      sender: data.ClientID,
      reference: '',
      date: date,
      clientName: data.GrowerName?.trim(),
      isReverseSender: true,
      finalFunc: updateState
    }
    dispatch({type: SELECT_DATA_FOR_ORDER_PACKAGE, payload: collection})
    if (data.PO_Num === 0) {
      history.push(LINKS.drivers.quests.createOrderPackages)

    } else {
      history.push(LINKS.drivers.quests.editByID + `${data.PO_Num}`);
    }
  }
  const growerItemDataP = {
    selectDataForPackageOrder: selectDataForPackageOrder
  }
  return (
    <TitlesList 
      list={listData}
      uniqueKey={'GrowerID'}
      Element={GroverMain}
      additionalData={growerItemDataP}
      noPadding={false}
    
    />
    
  )
}

export default QuestsModal