import React from 'react'
import BodySettingsLayout from '../Layouts/BodySettingsLayout'
import LangSwitcher from '../../../../../generic/particles/LangSwitcher'
import PrinterSettingsModal from '../../../../../generic/modals/PrinterSettingsModal'
import styled from 'styled-components'
import BodySection from '../Components/Body/BodySection'

type Props = {}

const PreferencesIndex = (props: Props) => {
  return (
    <BodySettingsLayout>
      <BodySection
        title='הגדרות הדפסה'
      >
        <MainWrapper>
          <PrinterSettingsModal />

        </MainWrapper>
      </BodySection>
    
    </BodySettingsLayout>
  )
}
const MainWrapper = styled.div`
  background: #fff;
  height: 520px;
  width: 500px;
`
export default PreferencesIndex