import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { TWorkerList } from '../../../../../../models/Defaults/workerList';
import ControlButton from '../../../../../generic/buttons/ControlButton';
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList';
import { useDetectOutsideClick } from '../../../../../generic/hooks/useDetectOutsideClick';
import OpenCloseButton from '../../../../../generic/particles/OpenCloseButton'
import Title from '../../../../../generic/Title/Title';
type Props = {
    controlFunc: () => void,
    title: string,
    data: TWorkerList,
    order: object,
    saveFunc: (order:object, id:number | null, action:string) => void
}

interface IModalContainer {
    isShow?: boolean,
    isSave?: boolean
} 
const ModalContainer = styled.div<IModalContainer>`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${props => props.isShow ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0)'};
    z-index: 5;
    padding: 0 0.5em;
    
`
const BodyWrapper = styled.div<IModalContainer>`
    position: relative;
    width: 400px;
    height: 700px;
    background: #fff;
    border-radius: 30px;
    transform: ${props => props.isShow ? 'translateY(0)' : 'translateY(-200%)'};
    transition: 0.5s;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    padding: 0.5em;
    
`
export const SearchWrapper = styled.div<IModalContainer>`
    position: ${props => props.isSave ? 'absolute' : 'relative'};
    bottom: ${props => props.isSave ? '2em' : '0'};
    width: 80%;
    display: flex;
    justify-content: center;
`

const TitleImg = styled.img`
    min-width: 300px;
`
export default function SelectWorkerModal({controlFunc, title, data, order, saveFunc}: Props) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const modalRef = useRef<HTMLDivElement>(null)
    const [input, setInput] = useState<string>('')
    const [workerList, setWorkerList] = useState(data);
    const [isOpenList, setIsOpenList] = useState<boolean>(false)
    const [workerID, setWorkerID] = useState<number | null>(null)
    const workerFilter = (input:string): void => {
        const filtered = data.filter(e => e.WorkerName.trim().toLowerCase().includes(input.toLowerCase()));
        setInput(input);
        setWorkerList(filtered);
        setIsOpenList(filtered.length > 0 && input ? true : false);
        if (!input.trim()) {
            setWorkerID(null);
        }
    }
    const selectWorker = (id: number, name: string): void => {
        setWorkerID(id)
        setInput(name);
        setIsOpenList(false)
    
    }
    const closeFunc = ():void => {
        setIsOpen(false);
        setTimeout(() => {
            controlFunc()
        }, 500)
    }
    useEffect(() => {
        setIsOpen(true)
    }, [])
    useDetectOutsideClick(modalRef, closeFunc)
  return (
    <ModalContainer isShow={isOpen}>
        <BodyWrapper isShow={isOpen} ref={modalRef}>
            {/* <OpenCloseButton /> */}
            <OpenCloseButton close func={closeFunc} />
            <Title label={title} color={'#297f76'} />
            <TitleImg src={'/images/ahslanot.png'}  alt='title'/>
            <SearchWrapper>
                <SearchFromTheList
                    list={workerList}
                    input={input}
                    placeHolder={'בחר מלקט'}
                    itemDisplayName='WorkerName'
                    nameIdElement='WorkerId'
                    isOpenList={isOpenList}
                    selectFunc={selectWorker}
                    searchFunc={workerFilter}
                    size={'full'}
                />
            </SearchWrapper>
            {
                workerID && 
                    <SearchWrapper isSave>
                        <ControlButton 
                            primal
                            lable={'עדכן'}
                            handleClick={() => {saveFunc(order, workerID, '+')}}
                        />
                    </SearchWrapper>      
            }
        </BodyWrapper>
    </ModalContainer>
  )
}