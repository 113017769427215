

import { SELECT_PALLET, SELECT_AZMANA, LIKUT_WITHOUT_AZMANA, PATCH_PALLET_NUM, LIKUT_WITHOUT_AZMANA_CLIENT, SAVE_CLIENTNAME, IS_NEW_PALLET, CHECK_ATTEMPTS, SELECT_CURRENT_ORDER } from '../actions/actionsTypes'

const initialState = {
    withoutAzmana: false,
    azmanaNum: null,
    clientName: '',
    clientId: null,
    CustomerID: null,
    isNewPallet: false,
    pallet: {
        palletNum: null,
        palletDesc: null,
        palletType: null
    },
    components: {
        notExistInAzmana: {
            num: 1,
            tag: 'מוצר לא קיים בהזמנה',
            resons: [{id: 5, desc: 'החלפת מוצר'}, {id: 2, desc: 'אישור מנהל'}]
        },
        moreThenNeed: {
            num: 2,
            tag: 'כמות גבוהה מההזמנה',
            resons: [
                {id: 1, desc: 'התאמת משקל'}, 
                {id: 2, desc: 'אישור מנהל'},
                {id: 6, desc: 'משטח קומפלט'}
            ],
        },
        lessThenNeed: {
            num: 3,
            tag: 'כמות נמוכה מההזמנה',
            resons: [
                {id: 1, desc: 'התאמת משקל'}, 
                {id: 3, desc: 'מחסור במלאי'}, 
                {id: 4, desc: 'פיצול'}, 
                {id: 2, desc: 'אישור מנהל'},
                {id: 6, desc: 'משטח קומפלט'}
                
            ]
        }
    },
    checkAttempts: {

    }
}

// initial state as ts interface
// export interface LikutState {
//     withoutAzmana: boolean,
//     azmanaNum: number | null,
//     clientName: string,
//     clientId: number | null,
//     CustomerID: number | null,
//     isNewPallet: boolean,
//     pallet: {
//         palletNum: number | null,
//         palletDesc: string | null,
//         palletType: string | null
//     },
//     components: {
//         notExistInAzmana: {
//             num: number,
//             tag: string,
//             resons: {id: number, desc: string}[]
//         },
//         moreThenNeed: {
//             num: number,
//             tag: string,
//             resons: {id: number, desc: string}[]
//         },
//         lessThenNeed: {
//             num: number,
//             tag: string,
//             resons: {id: number, desc: string}[]
//         }
//     },
//     checkAttempts: {
//         [key: string]: number
//     }
// }


const likutReducer = (state = initialState, action) => {

    switch (action.type) {
        case SELECT_CURRENT_ORDER:
            return {
                ...state,
                order: action.payload
            }
        case SELECT_AZMANA:
            return {
                ...state,
                // ...action.payload,
                withoutAzmana: false,
                azmanaNum: action.payload,
                azmanaGroup: action.group,
                azmanaStatus: action.status,
                clientId: action.client
            }
        case SAVE_CLIENTNAME:
            return {
                ...state,
                // ...action.payload,
                clientName: action.payload
            }
        case SELECT_PALLET:
            return {
                ...state,
                // ...action.payload,
                pallet: {
                    ...state.pallet,
                    ...action.payload,
                    // asda: 123
                }
            }
        case PATCH_PALLET_NUM:
            return {
                ...state,
                pallet: {
                    ...state.pallet,
                    palletNum: action.payload
                }
            }
        case LIKUT_WITHOUT_AZMANA:
            return {
                ...initialState,
                withoutAzmana: true,
                azmanaNum: 0
            }
        case LIKUT_WITHOUT_AZMANA_CLIENT:
            return {
                ...state,
                clientId: action.payload
            }
        case IS_NEW_PALLET:
            return {
                ...state,
                isNewPallet: action.payload
            }
        case CHECK_ATTEMPTS:
            return {
                ...state,
                checkAttempts: {
                    ...action.payload
                }
                
            }    
        default: return state;
    }
}

export default likutReducer