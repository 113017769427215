import React from 'react'
import styled from 'styled-components'
import { DeleteIcon } from '../../../../../generic/icons/Icons'

type Props = {
    handleClick: () => void;
}

const DeletePart = ({
    handleClick
}: Props) => {
  return (
    <MainWrapper
        onClick={handleClick}
    >
        <DeleteIcon />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    min-width: 4em;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: tomato;
    color: #fff;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    margin-left: 0.5em;
`

export default DeletePart