import { PackageIcon, ScalesIcon, ShopBagIcon } from "../icons/Icons";

export const stylePackByID = (packType: number | string) => {
    let weightsType = {
      color: "#6B78A8",
      component: <ScalesIcon />,
    };
    let ihidot = {
      color: "#A1E396",
      component: <ShopBagIcon />,
    };
    let common = {
      color: "#7AB0AB",
      component: <PackageIcon />,
    };
    return packType === "1"
      ? weightsType
      : packType === "2"
      ? ihidot
      : common;
  };