import React, {useState, useMemo} from 'react'
import styled from 'styled-components';
import SelectFromTheList from '../../../../../../generic/customSearchComponents/SelectFromTheList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers/rootReducer';
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList';
import { IClientsItem } from '../../../../../../modules/API/Defaults/models/customers';

type Props = {
  selectedID: number;
  setCustomerID: React.Dispatch<React.SetStateAction<number>>;
}

const CustomerFilter = ({
  selectedID, setCustomerID
}: Props) => {
  const customerList: IClientsItem[]  = useSelector((state: RootState) => state.defaults?.clientList?.list)
  const defaultCustomer = customerList?.filter(item => item.CustomerID === selectedID)[0]
  const [selectedCustomer, setSelectedCustomer] = useState<IClientsItem | null>(defaultCustomer)
  const [inputCustomer, setInputCustomer] = useState<string>(defaultCustomer?.Customer_Name?.trim() || '')
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const handleSelectCustomer = (item: IClientsItem) => {
    console.log(item)
    setSelectedCustomer(item)
    setCustomerID(item.CustomerID)
    setInputCustomer(item.Customer_Name?.trim())
    setIsOpenDropDown(false)

  }
  const handleSearchCustomer = (e: string) => {
    if(e === '') {
      setSelectedCustomer(null)
      setCustomerID(0)
      
    }
    setIsOpenDropDown(true)
    setInputCustomer(e)
  }
  const render = useMemo(() => {
    if(!customerList) return []
    return customerList.filter(item => item?.Customer_Name?.trim()?.toLowerCase().includes(inputCustomer.toLowerCase()))
  }, [customerList, inputCustomer])
  console.log(customerList)
  return (
    <MainWrapper>
      <Text>לקוח</Text>
      <SearchFromTheList
        list={render}
        input={inputCustomer}
        placeHolder={'חפש לקוח'}
        searchFunc={handleSearchCustomer}
        selectFunc={handleSelectCustomer}
        isOpenList={isOpenDropDown}
        nameIdElement='CustomerID'
        // selectedValue={selectedID}
        itemDisplayName='Customer_Name'
        isNeedFullObject
        size={'full'}

      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    padding: 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    /* max-width: 150px; */
    /* overflow: hidden; */
    background: #fff;
    border-radius: 30px;
`
const Text = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5em;
`
export default CustomerFilter