import React, {useState, useMemo} from 'react'
import MarketMainLayout from '../../market/Collect/Layout/MarketMainLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import { IReportQ8, IReportQ8Response } from '../../../../../modules/API/Reports/models/q8'
import { Reports } from '../../../../../modules/API/Reports/reports'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import SelectDateAndClient from './Components/Modals/SelectDateAndClient'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { FilterIcon } from '../../../../../generic/icons/Icons'
import Table from './Layouts/Table/Table'
import TableHead from './Layouts/Table/TableHead'
import { Q8_TABLE_HEAD } from './Layouts/Table/q8TableHead'
import TableHeadCell from './Layouts/Table/TableHeadCell'
import Q8Table from './Components/Wrappers/Q8Table'
import { IClientItem } from '../../../../../modules/API/Defaults/models/clients'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'

type Props = {}

const MainQ8 = (props: Props) => {
  const [searchReport, setSearchReport] = useState<string>("");
  const [isOpenMainFilter, setIsOpenMainFilter] = useState<boolean>(false);
  const [report, setReport] = useState<IReportQ8Response[]>();
  const {
    data: clientListData
  } = useDataFetcher<{clients: IClientItem[], message: string}>(
    Defaults.getClientList, "data"
  )
  const getReportQ8 = async (
    customerID: number, dateFrom: string, dateTo: string
    ) => {
    const dataFetch: IReportQ8 = {
      ClientID: customerID,
      DateFrom: dateFrom,
      DateTo: dateTo
    }
    try {
      const res = await Reports.getReportQ8(dataFetch)
      setReport(addIDForTheList(res))
      handleControlMainFilter()
    } catch (error) {
      
    }
  }


  const handleControlMainFilter = () => {
    setIsOpenMainFilter(!isOpenMainFilter)
  }

  const modalProps = {
    acceptFunc: getReportQ8,
    customerList: clientListData?.clients || [],
  }
    const renderReportList = useMemo(() => {
        if (!report) return []
        return report.filter((item: IReportQ8Response) => {
            return item.Description.trim().toLowerCase().includes(searchReport.toLowerCase().trim())
        })
    }, [report, searchReport])
  return (
    <MarketMainLayout
      size='lg'
      title="יתרות אריזה/משטחים"
    >
        {
          !isOpenMainFilter &&
          <ColumModal 
          title='סינון'
          controlFunc={handleControlMainFilter}
          Children={SelectDateAndClient}
          childrenProps={modalProps}
            /> 
        }
        <ControlSearchPannelLayout>
          <CircleActiveFilterBtn
            withAction={false}
            func={handleControlMainFilter}
          >
            <FilterIcon />
          </CircleActiveFilterBtn>
          <SearchInput 
              input={searchReport}
              setInput={setSearchReport}
              />
        </ControlSearchPannelLayout>
        {
          report &&
            <Table>
              <TableHead>
                {
                  Q8_TABLE_HEAD.map((item, index) => 
                    <TableHeadCell key={index}>
                      {item}
                    </TableHeadCell>
                  )
                }
              </TableHead>
              <Q8Table 
                list={renderReportList}
              />
            </Table>
        }
    </MarketMainLayout>
  )
}

export default MainQ8