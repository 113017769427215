import React from 'react'
import styled from 'styled-components'
import Title from '../../../../../../generic/Title/Title'
import { SelectPanelWrapper } from '../../../secondbar/azmanotmain/SelectBar'
import LikutPalletTypeList from './LikutPalletTypeList'

export const ModalWindow = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(233,240,240, 0.5);
    z-index: 2;
    
`
export const Container = styled.div`
    max-width: 500px;
    height: 100%;
    /* background: red; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
`
export const InnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 20em;
    background: #b2d0cd;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    gap: 0.5em;
`
export default function LikutPalletTypeModal({
    palletList, setShowModal, setPalletType
}) {
    return (
        <ModalWindow >
            <Container>
                <InnerWrapper>
                    <Title label={'סוג משטחים'} />
                    <SelectPanelWrapper>
                        <LikutPalletTypeList palletList={palletList} setShowModal={setShowModal} setPalletType={setPalletType} />
                    </SelectPanelWrapper>
                </InnerWrapper>
            </Container>
        </ModalWindow>
    )
}
