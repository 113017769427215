import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDetectOutsideClick } from '../../../../../generic/hooks/useDetectOutsideClick';
import { ErrorIcon } from '../../../../../generic/icons/Icons';
import { AddBtn, FilterContainer, MainModalWrapper } from '../likutWorkers/filterAzmanot/ModalFilterAzmanot';
import FilterAzmanotList from '../likutWorkers/filterAzmanot/selects/FilterAzmanotList';

export const CheckErrorRowsWrapper = styled.div`
    height: 3em;
    min-width: 3em;
    border-radius: 50%;
    background: ${props => props.active ? 'tomato' : '#eff3f4'};
    color: ${props => props.active ? '#fff' : '#297f76'};
    margin: 0 0.5em 0.5em 0;
    align-self: flex-start;
    display: ${props => props.exist ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    font-size: 1em;
`
export default function FilterModal({
    isOpenOrderFilter, workerGroupList, deliveryCodeList, azmanotListDefault, 
    listSetter, setIsOpenOrderFilter, setAzmanotList, searchSium, siumInputValue, 
    prodTypes, customerList, marketAreaList, isExistErrorRow, setFilterFunc, customerGroup, customersDict
}) {
    // console.log(deliveryCodeList)
    const ref = useRef()
    const { transportAreas } = useSelector(e => e.defaults);
    const [transportArea, setTransportArea] = useState(null);
    console.log(transportAreas)
    const [deliveryID, setDeliveryID] = useState(null);
    const [workerKwucaID, setWorkerKwucaID] = useState(null);
    const [prodTypesID, setProdTypesID] = useState(null);
    const [areaID, setAreaID] = useState(null);
    const [customersIdArray, setCustomersIdArray] = useState([]);
    const [isErrorFilter, setIsErrorFilter] = useState(false);
    const [customerGroupID, setCustomerGroupID] = useState(null);

    
    
    console.log(workerGroupList)
    // item.ChangeReason === 0 && item.SupplyQty - item.OrderPacks > 0)
    const handleFilterAzmanot = (array) => {
        const filtered = array.filter(item =>
            (deliveryID ? item.Delivery_Code === +deliveryID : true) &&
            (workerKwucaID ? item.PickupGroup === +workerKwucaID : true) &&
            (isErrorFilter ? (item.detailes?.some(e => e.ChangeReason === 0 && e.SupplyQty - e.OrderPacks > 0)) : true) &&
            (prodTypesID ? item.GroupID === +prodTypesID : true) &&
            (customerGroupID ? customersDict[item.CustomerID]?.Customer_GroupID === +customerGroupID : true) &&
            (areaID ? customersIdArray.includes(item.CustomerID) : true) &&
            (transportArea ? item.TransferZone ===  +transportArea: true)
        

        )
        if (deliveryID || workerKwucaID || isErrorFilter || prodTypesID || areaID || transportArea) {
            console.log('filter is active')
            setAzmanotList(filtered);

        } else {
            setAzmanotList([])
        }
        setFilterFunc({
            get: handleFilterAzmanot
        })
        console.log(filtered)
        // listSetter(filtered)
        searchSium(siumInputValue, filtered)
        setIsOpenOrderFilter(false);
    }

    const filterCustomersBySellerID = (areaID) => {
        setAreaID(areaID);
        const filter = customerList.filter(item => item.Marketing_AreaID === +areaID).reduce((acc, cur) => {
            return acc.concat(cur.CustomerID)
        }, [])
        setCustomersIdArray(filter)
        
    }
    useDetectOutsideClick(ref, () => {setIsOpenOrderFilter(false)})
    console.log(workerGroupList)
    return <MainModalWrapper ref={ref} primal={isOpenOrderFilter}>
        <FilterContainer style={{ flexFlow: 'row wrap', alignContent: 'flex-end' }}>
            <FilterAzmanotList
                setId={setDeliveryID}
                list={deliveryCodeList}
                label={'סבב הפצה - הכל'}
                id={'Delivery_Code'}
                desc={'Delivery_Description'}
                selectedValue={deliveryID}
            />
            <FilterAzmanotList
                setId={setWorkerKwucaID}
                list={workerGroupList}
                label={'קבוצת ליקוט - הכל'}
                id={'SecondCode'}
                desc={'Description'}
                selectedValue={workerKwucaID}
            />
            <FilterAzmanotList
                setId={setTransportArea}
                list={transportAreas.array?.sort((a, b) => a.AreaDesc.trim() - b.AreaDesc.trim())}
                label={'קו הפצה - הכל'}
                id={'AreaID'}
                desc={'AreaDesc'}
                selectedValue={transportArea}
            />
            <CheckErrorRowsWrapper exist={isExistErrorRow} active={isErrorFilter} onClick={() => { setIsErrorFilter(!isErrorFilter) }}>
                <ErrorIcon />
            </CheckErrorRowsWrapper>
        </FilterContainer>
        <FilterContainer>
            <FilterAzmanotList
                setId={filterCustomersBySellerID}
                list={marketAreaList}
                label={'איזורי שיווק - הכל'}
                id={'SecondCode'}
                desc={'Description'}
                selectedValue={areaID}
            />
            <FilterAzmanotList
                setId={setProdTypesID}
                list={prodTypes}
                label={'קבוצת הזמנה - הכל'}
                id={'GroupID'}
                desc={'GroupDesc'}
                selectedValue={prodTypesID}
            />
            <FilterAzmanotList
                    setId={setCustomerGroupID}
                    list={customerGroup}
                    label={'קבוצת לקוח - הכל'}
                    id={'GroupID'}
                    desc={'GroupDesc'}
                    selectedValue={customerGroupID}
                />
        </FilterContainer>
        <AddBtn onClick={() => {handleFilterAzmanot(azmanotListDefault)}}>סנן</AddBtn>
    </MainModalWrapper>;
}
