import { USER_LOADED, LOAD, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, LOAD_USER, LOAD_END, LOAD_MODULES, LOAD_WORKER_TYPE } from '../actions/actionsTypes'
import initialState, { IInitialState } from '../initialState'


const reducer = (state: IInitialState = initialState, action: any): IInitialState => {

    switch (action.type) {
            case USER_LOADED:
                return {
                    ...state,
                    // ...action.payload,
                    token: action.payload,
                    loading: false,
                    // user: setUser.user
                    // user: getFromLocal['user']
            };
            case LOAD_USER:
                
                return {
                    ...state,
                    // ...action.payload,
                    user: action.payload,
                    loading: false,
                }
            case LOAD_WORKER_TYPE:
                return {
                    ...state,
                    workerType: action.payload
                }

            case LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                // token: getFromLocal['token'],
                isAuthenticated: true,
                loading: false,
                error: 'ok',
                login: action.payload.companies[0]?.name || ''
            };
            case LOGOUT:
                return initialState
            case LOAD:
                return {
                    ...state,
                    // ...action.payload,
                    loading: true
                };
            case LOAD_MODULES:
                return {
                    ...state,
                    modules: action.payload
                };
            case LOAD_END:
                return {
                    ...state,
                    ...action.payload,
                    loading: false
                }
            case LOGIN_FAIL:
            return {
                ...state,
                ...action.payload,
                error: 'User not found',
                loading: false
            }

        
            
        default: return state;  
    }

}

export default reducer