import React, {useMemo, useState} from 'react'
import { ISupplyCustomerDataProduct } from '../../../../../../modules/API/Forcasts/models/seller'
import styled from 'styled-components'
import ProductLayout from '../generic/ProductLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PackageIcon, PalletIcon, ScalesIcon } from '../../../../../../generic/icons/Icons'
import CustomerGrowerItem from './CustomerGrowerItem'

interface Props extends ISupplyCustomerDataProduct {
  item: ISupplyCustomerDataProduct
}

const CustomerProductItem = ({
  item,
  Product,
  OrderPacks,
  OrderWeight,
  Order_Pallets,
  Growers,
  SupplyQty,
  SupplyWeight,
  Supply_Pallets
}: Props) => {
  const [isOpenGrowers, setIsOpenGrowers] = useState(false)
  // const palletFormated = Order_Pallets % 2 === 0 ? Order_Pallets : Order_Pallets.toFixed(1)
  const handleControlBody = () => {
    setIsOpenGrowers(!isOpenGrowers)
  }
  const color = useMemo(() => {
    const colorObg = {
      pallet: Order_Pallets === Supply_Pallets && Order_Pallets !== 0 ? 
        '#8CC53D' : Order_Pallets > Supply_Pallets && Supply_Pallets !== 0 ? 
        'orange' : Order_Pallets === 0 && Supply_Pallets === 0 ?
        '#297F76' :
        'tomato',
      weight: OrderWeight === SupplyWeight ?
        '#8CC53D' : OrderWeight > SupplyWeight && SupplyWeight !== 0 ?
        'orange' : 
        'tomato',
      qty: OrderPacks === SupplyQty ?
        '#8CC53D' : OrderPacks > SupplyQty && SupplyQty !== 0 ?
        'orange' : 
        'tomato'
    }
    return colorObg
  }, [SupplyQty, OrderPacks, SupplyWeight, OrderWeight, Supply_Pallets, Order_Pallets])
  return (
    <MainWrapper
      onClick={handleControlBody}
    >
      <ProductLayout
        product={Product}
      >
        <TitleRow>
          <TitleRowItem 
            name={`${Math.round(Supply_Pallets)} / ${Math.round(Order_Pallets)}`}
            icon={<PalletIcon />}
            iconColor={color.pallet}
          />
          {/* <TitleRowItem 
            name={`${Math.round(SupplyWeight)} / ${Math.round(OrderWeight)}`}
            icon={<ScalesIcon />}
          /> */}
          <TitleRowItem 
            name={`${Math.round(SupplyQty)} / ${Math.round(OrderPacks)}`}
            icon={<PackageIcon />}
            iconColor={color.qty}
          />
          
        </TitleRow>
      
      </ProductLayout>
      {
        Growers.length > 0 && isOpenGrowers &&
          <BodyProductWrapper>
            {
              Growers.map((grower, index) => 
                <CustomerGrowerItem 
                  {...grower}
                  key={`${grower.Grower.GrowerID}`}
                  item={grower}
                />
              )
            }
        </BodyProductWrapper>
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  /* background: ; */
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  cursor: pointer;
  background: #f0f0f0;
  border-radius:20px;
  overflow: hidden;

`
const BodyProductWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
export default CustomerProductItem