import React from 'react'
import RecommendationItem from '../../Distribution/Components/RecommendationItem'
import { IRecommendationItem } from '../../../../../../modules/API/Recommendation/models/recomendationItem'

type Props = {
  item: IRecommendationItem
  index: number
  deleteFunc: (recID: number) => void,
  calculationMethod: number
}

const RecomendationItem = (props: Props) => {
  return (
    
    <RecommendationItem
      item={props.item}
      index={props.index}
      amount={0}
      pallets={0}
      weight={0}
      deleteFunc={props.deleteFunc}
      {...props.item}
      calculationMethod={props.calculationMethod}
    />
  )
}

export default RecomendationItem