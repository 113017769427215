import React, { useState, useMemo, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  LOAD,
  LOAD_END,
} from "../../../../../../../store/actions/actionsTypes";
import { RootState } from "../../../../../../../store/reducers/rootReducer";
import ControlButton from "../../../../../../generic/buttons/ControlButton";
import SearchFromTheList from "../../../../../../generic/customSearchComponents/SearchFromTheList";
import DatePickerModule from "../../../../../../generic/datePicker/datePicker";
import { EditIcon } from "../../../../../../generic/icons/Icons";
import SearchInput from "../../../../../../generic/inputs/inputSearch";
import ColumModal from "../../../../../../generic/Layouts/ColumModal";
import OrderlikeLayout from "../../../../../../generic/Layouts/OrderlikeLayout";
import BodyScrollContainer from "../../../../../../generic/Layouts/Wrappers/BodyScrollContainer";
import ControlSearchPannelLayout from "../../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import CircleActiveFilterBtn from "../../../../../../generic/particles/CircleActiveFilterBtn";
import { addIDForTheList } from "../../../../../../generic/utils/addUIDForTheList";
import { Defaults } from "../../../../../../modules/API/Defaults/defaults";
import { IClientItem } from "../../../../../../modules/API/Defaults/models/clients";
import { IClientsItem } from "../../../../../../modules/API/Defaults/models/customers";
import { IPackTypeItem } from "../../../../../../modules/API/Packages/models/mlaiTypes";
import { Packages } from "../../../../../../modules/API/Packages/packages";
import { ContextModal } from "../../../../../../modules/modal/modalContext";
import { LINKS } from "../../../../../../Routs/config";
import { ControlPanelWrapper } from "../../../secondbar/modalAzmanot/modalAzmanot";
import RemarkModal from "../../View/RemarkModal";
import ArizotContentList from "./ArizotContentList";
import TimeInput, { initialTime } from "./TimeInput";

interface IRemarkData {
    title: string,
    body: string,
    id: string | number
}

const ArizotCreateMain: React.FC = () => {
  const isUserPnimi =
    useSelector((state: RootState) => state.mainReducer.user?.code_internal) !==
    "0";
  const customerList = useSelector(
    (state: RootState) => state.defaults.clientList?.list
  );
  const defaultClient = isUserPnimi ? customerList[0] : undefined;
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenRemarkWindow, setIsOpenRemarkWindow] = useState<boolean>(false)
  const [modal, setModal] = useContext(ContextModal);
  const [date, setDate] = useState("");
  const [selectedTime, setSelectedTime] = useState<string>(() => initialTime());
  const [isValidTime, setIsValidTime] = useState<boolean>(true);

  const [usedPackagesList, setUsedPackagesList] = useState<IPackTypeItem[]>();
  const [input, setInput] = useState<string>("");

  const [clientList, setClientList] = useState<IClientItem[]>();
  const [clientInput, setClientInput] = useState<string>(
    defaultClient?.Customer_Name?.trim() || ""
  );
  const [client, setClient] =
    useState<IClientItem | IClientsItem>(defaultClient);
  const [isOpenClientList, setIsOpenClientList] = useState<boolean>(false);
  const initialRemarkState = {
    title: defaultClient?.ClientName ,
    body: '',
    id: client?.ClientID
  }
  const [remarkData, setRemarkData] = useState<IRemarkData>(initialRemarkState)

  const getClientList = async () => {
    try {
      let res = await Defaults.getClientList();
      let filtered = addIDForTheList(
        res.clients.filter((item: IClientItem) => item.IsActive)
      );
      setClientList(filtered);
    } catch (err) {
    } finally {
    }
  };
  const getUsedTypes = async (id?: number) => {
    dispatch({ type: LOAD });
    try {
      let res = await Packages.getUsedTypes(id);
      setUsedPackagesList(addIDForTheList(res));
    } catch (err) {
    } finally {
      dispatch({ type: LOAD_END });
    }
  };
  const renderList = useMemo(() => {
    if (!usedPackagesList) return;
    const filtered = usedPackagesList?.filter((item) =>
      item.Description.trim().toLowerCase().includes(input.toLowerCase())
    );
    return filtered;
  }, [usedPackagesList, input]);

  const renderClientList = useMemo(() => {
    if (!clientList) return;
    const filtered = clientList.filter((item) =>
      item.ClientName?.trim().toLowerCase().includes(clientInput.toLowerCase())
    );
    return filtered;
  }, [clientList, clientInput]);

  const handleSelectClient = (item: IClientItem) => {
    setClient(item);
    setClientInput(item.ClientName.trim());
    getUsedTypes(item.ClientID);
    console.log("123");

    setTimeout(() => {
      setIsOpenClientList(false);
    });
  };
  const handleClientSearch = (e: string) => {
    setIsOpenClientList(true);
    setClientInput(e);
  };
  const handleTime = (time: string, valid: boolean) => {
    setSelectedTime(time);
    setIsValidTime(valid);
  };
  const patchState = (qty: number, id: string) => {
    if (!usedPackagesList) return;
    const newState = [...usedPackagesList];
    const found = newState.find((e) => e.id === id);
    if (!found) return;
    const indexOfItem = newState.indexOf(found);
    newState[indexOfItem].Qty = qty;

    setUsedPackagesList(newState);
  };
  const isCreateActive = useMemo(() => {
    const find = usedPackagesList?.find((e) => e.Qty > 0);
    return !!find && isValidTime;
  }, [usedPackagesList, isValidTime]);
  const errorHandle = () => {
    if (!isValidTime) {
      throw Error("ערך זמן לא חוקי");
    }
  };
  const modifyTime = () => {
    let time = selectedTime.split(":").join("").concat("00");
    return time;
  };
  const createSfira = async () => {
    if (!usedPackagesList) return;

    dispatch({ type: LOAD });
    const editedLine = usedPackagesList?.map((e) => {
      let { id, ...rest } = e;
      return { ...rest };
    });
    const postData = {
      ClientID: client.ClientID,
      RegDate: date,
      RegTime: modifyTime(),
      Packs: editedLine,
      Remarks: remarkData.body
    };
    try {
      errorHandle();
      let res = await Packages.createSfira(postData);
      history.push(LINKS.arizot.arizaMlai.mainUrl);
      setModal({
        open: true,
        value: `הספירה נוצרה בהצלחה`,
        mood: true,
      });
      setTimeout(() => {
        setModal({
          open: false,
          value: "2",
        });
      }, 3000);
    } catch (err: any) {
      if (err.message) {
        setModal({
          open: true,
          value: err.message,
          mood: false,
        });
        setTimeout(() => {
          setModal({
            open: false,
            value: "2",
          });
        }, 3000);
      } else {
        setModal({
          open: true,
          value: `משהו השתבש`,
          mood: false,
        });
        setTimeout(() => {
          setModal({
            open: false,
            value: "2",
          });
        }, 3000);
      }
    } finally {
      dispatch({ type: LOAD_END });
    }
  };
  useEffect(() => {
    if (isUserPnimi) {
    } else {
      getClientList();
    }
  }, []);
  useEffect(() => {
    if (isUserPnimi) {
      getUsedTypes(client?.ClientID);
    }
  }, []);
  const arizotListProps = {
    updateInput: patchState,
  };
  const handleRemarkModal = () => {
      setIsOpenRemarkWindow(!isOpenRemarkWindow)

  }
  const handleSaveRemark = (input: string) => {
    setRemarkData(prev => {
        return {
            ...prev,
            body: input
        }
    })
    handleRemarkModal()
  }
  const remarkWindowProps = {
    data: remarkData,
    handleSave: handleSaveRemark,
    isActive: true
  }
  return (
    <OrderlikeLayout label="ספירה חדשה">
      {isOpenRemarkWindow && (
        <ColumModal
          title={remarkData?.title || ""}
          Children={RemarkModal}
          controlFunc={handleRemarkModal}
          childrenProps={remarkWindowProps}
        />
      )}
      <ControlSearchPannelLayout>
        <SearchFromTheList
          input={clientInput}
          searchFunc={handleClientSearch}
          list={renderClientList}
          isOpenList={isOpenClientList}
          size={"full"}
          placeHolder={"בחר לקוח"}
          selectFunc={handleSelectClient}
          itemDisplayName={"ClientName"}
          isNeedFullObject
          disabled={isUserPnimi}
        />
      </ControlSearchPannelLayout>
      {client && (
        <ControlSearchPannelLayout>
          {/* <SearchInput input={input} setInput={setInput}  /> */}
          <CircleActiveFilterBtn
            func={handleRemarkModal}
          >
            <EditIcon />
          </CircleActiveFilterBtn>
          <DatePickerModule
            multyDate={false}
            setSelectedDateStart={setDate}
            setSelectedDateEnd={false}
            disabled={false}
            selected={false}
            ignorBrod={true}
            defEndDate={false}
            large={false}
          />
          <TimeInput onChange={handleTime} />
          
        </ControlSearchPannelLayout>
      )}
      <BodyScrollContainer>
        <ArizotContentList list={renderList} props={arizotListProps} />
      </BodyScrollContainer>
      <ControlPanelWrapper>
        <Link style={{ width: "100%" }} to={LINKS.arizot.arizaMlai.mainUrl}>
          <ControlButton lable={"חזרה"} />
        </Link>
        {isCreateActive && (
          <ControlButton lable={"שמור"} handleClick={createSfira} />
        )}
      </ControlPanelWrapper>
    </OrderlikeLayout>
  );
};

export default ArizotCreateMain;
