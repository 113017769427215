import React from 'react'
import styled from 'styled-components'
import { UserIcon } from '../../../../../generic/icons/Icons'

const MainWrapper = styled.div`
    width: 100%;
    min-height: 3.5em;
    background: #fff;
    border-radius: 30px;
    padding: 0 1em 0 0;
    color: #75759e;
    -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
    filter: drop-shadow(2px 3px 3px rgb(215,231,242));
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    font-size: 1.1em;
`

export default function ReadyForDeliveryItem({Customer_Name, scrollIndex, curRef, index}) {
    let flag = scrollIndex === index ? curRef : null
    
  return (
    <MainWrapper ref={flag}>
        {Customer_Name?.trim()}
        <UserIcon />
    </MainWrapper>
  )
}
