import React from 'react'
import { CalendarContainerProps } from '../types/dayColumn'
import styled from 'styled-components'
import { format } from 'date-fns'
import ForecastItem from './ForecastItem'
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn'
import { PlusIcon } from '../../../../../../generic/icons/Icons'
import { IDataForEditForecastItem } from '../types/dataForEditForecastItem'

interface Props {
  item: CalendarContainerProps
  index: number,
  selectDayForRec: (index: number, list: any) => void
  selectDayForForecast: (data: IDataForEditForecastItem) => void
  calculationMethod: number,
  isAwailableToCreateForecast: boolean
}

const DateColumnItem = ({
  item,
  index,
  selectDayForRec,
  selectDayForForecast,
  calculationMethod,
  isAwailableToCreateForecast

}: Props) => {
  const { list, dateDesc, dateRequestFormat} = item
  const isToday = dateRequestFormat === format(new Date(), 'yyyyMMdd')
  const handleSelectDayForRec = () => {
    selectDayForRec(index, list)
  }
  return (
    <MainWrapper>
      <Header isToday={isToday}>
        {dateDesc}
        {
          isAwailableToCreateForecast && 
          <IconAddWrapper>
              <CircleActiveFilterBtn
                func={handleSelectDayForRec}
                >
                <PlusIcon />
              </CircleActiveFilterBtn>
            </IconAddWrapper>
        }
      </Header>
      <Body>
        {
          list.map((item, index) => 
            <ForecastItem 
              key={item.RecID}
              item={item}
              {...item}
              columnIndex={index}
              index={index}
              calculationMethod={calculationMethod}
              selectDayForForecast={selectDayForForecast}
            />
          )
        }
      </Body>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  min-width: 360px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-flow: column;
  gap: 1em;
  padding: 0.3em;
`
const Header = styled.div<{isToday: boolean}>`
position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6em 1em;
  background: ${props => props.isToday ? '#8CC53D' : '#EFF3F4'};
  border-radius: 20px;
  font-size: 1.1em;
  color: ${props => props.isToday ? '#fff' : '#297F76'};
`
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  direction: ltr;
  gap: 0.5em;
  padding: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
const IconAddWrapper = styled.div`
  position: absolute;
  left: 0.2em;
  top: calc(50% - 1.04em);
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
`
export default DateColumnItem