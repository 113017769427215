import React, {useMemo} from 'react'
import styled from 'styled-components'
import { IStatisticWorkerItem } from '../../../../../../modules/API/Gathering/models/statisticWorker'
import RestWorkersItem from './RestWorkersItem'
import TopWorkersItem from './TopWorkersItem'

type Props = {
    list: IStatisticWorkerItem[],
    avgCollection: number
}

const MainWorkers = ({list, avgCollection}: Props) => {
    const {topArr, rest} = useMemo(() => {
        const topArr = list.slice(0, 3);
        const rest = list.slice(3);
        return {topArr, rest}
    }, [list])

  return (
    <MainWrapper>
        <TopWorkersWrapper>
            {
                topArr.map((item, index) =>
                    <TopWorkersItem
                        key={index}
                        index={index}
                        avgCollection={avgCollection}
                        {...item}
                    />
                )
            }
        </TopWorkersWrapper>
        <RestWorkersWrapper>
            {
                rest.map((item, index) => 
                    <RestWorkersItem
                        key={index}
                        index={index}
                        {...item}
                    />
                )
            }
        </RestWorkersWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: red; */
    border-radius: 30px;
    padding: 0 0.5em;
    display: flex;
    gap: 1em;
`
const TopWorkersWrapper = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    border-radius: 30px;

    padding: 0.5em;


`
const RestWorkersWrapper = styled(TopWorkersWrapper)`
    justify-content: flex-start;
    gap: 0.5em;
    overflow: auto;
    padding: 1em 0.5em;

    
    ::-webkit-scrollbar {
        width: 0;  
    }
`
export default MainWorkers