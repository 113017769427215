import React from 'react'
import Block from './statdiv/statdiv'
import Acordion from './acordion/acordion'
// import RegForm from './regform/regform'
import Performance from './goods/performans'
import Forma from './newRegForma/Forma'
import ReferalBlock from './referalBlock/ReferalBlock'
import FeedBack from './feedback/feedback'
import ManageBlock from './manageBlock/manageBlock'
import HeaderMain from '../../header/header'
import Footer from '../../footer/footer'
import MainCarusel from '../welcompage/carusel/mainCarusel'
import styled from 'styled-components'


const Section = styled.section`
    scroll-snap-type: y mandatory;
    overflow-y: auto;
`

function WelcPage() {
   

    return(
        <Section>
            <HeaderMain />
            
            <ManageBlock/>
            <MainCarusel />
            <Acordion />
            <Performance />
            {/* <RegForm /> */}
            <Forma />
            <FeedBack/>
            <Block />
            <ReferalBlock />
            {/*   */}
            <Footer />
        </Section>
        

    );
}


export default WelcPage;