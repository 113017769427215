import React, {useState, useEffect, useMemo, useContext} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SwipeItemLayout from '../../../../../generic/Layouts/Wrappers/SwipeItemLayout'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { IMlaiTitlesItem } from '../../../../../modules/API/Packages/models/mlaiTypes'
import { Packages } from '../../../../../modules/API/Packages/packages'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { LINKS } from '../../../../../Routs/config'
import RemarkModal from '../View/RemarkModal'
import ArizotAccountingItem from './ArizotAccountingItem'
import ArizotCreateMain from './create/ArizotCreateMain'
import ArizotEditMain from './edit/ArizotEditMain'

type Props = {}
interface IRemarkData {
    title: string,
    body: string,
    id: string | number
}
const ArizotAccoutingMain = (props: Props) => {
    // const client
    const isUserPnimi = useSelector((state: RootState) => 
        state.mainReducer.user?.code_internal
    ) !== '0'
    const customerList = useSelector((state: RootState) => 
        state.defaults.clientList?.list
    )
    const defaultClient = isUserPnimi ? customerList[0].ClientID : 0
    const dispatch = useDispatch();
    let { path } = useRouteMatch();
    const [modal, setModal] = useContext(ContextModal);
    const [remarkData, setRemarkData] = useState<IRemarkData>()
    const [isOpenRemarkWindow, setIsOpenRemarkWindow] = useState<boolean>(false)
    const [mlaiList, setMlaiList] = useState<IMlaiTitlesItem[]>();
    const [input, setInput] = useState<string>('');
    const [date, setDate] = useState<string>('')

    const renderList = useMemo(() => {
        const filtered = mlaiList?.filter(e => 
            e.ClientName?.trim().toLowerCase().includes(input.toLowerCase())
        )
        return filtered
    }, [mlaiList, input])

    const getMlaiList = async () => {
        dispatch({type: LOAD})
        try {
            let res = await Packages.getSfirotList(defaultClient, date)
            setMlaiList(addIDForTheList(res))
        } catch (err) {
            setModal({
                open: true,
                value: `משהו השתבש`,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
        } finally {
            dispatch({type: LOAD_END})
        }
    }
    const deleteSfiraFromTheList = (id: number) => {
        const filtered = mlaiList?.filter(item => item.PC_Num !== id)
        setMlaiList(filtered);
    }
    const deleteSfira = async (id: number) => {
        try {
            let res = await Packages.deleteSfira(id)
            deleteSfiraFromTheList(id)
            setModal({
                open: true,
                value: "הספירה נמחקה בהצלחה",
                mood: true
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
        } catch(err) {

        } finally {

        }
    }
    useEffect(() => {
        if(!date) return
        getMlaiList()
    }, [date])
    const selectRemarkLine = (data: IRemarkData) => {
        setRemarkData(data)
        setIsOpenRemarkWindow(true)
    }
    const handleRemarkModal = () => {
        setIsOpenRemarkWindow(!isOpenRemarkWindow)
    }
    const handleSaveRemark = () => {
        
    }
    const Plist = {
        deleteItem: deleteSfira,
        selectRemark: selectRemarkLine
    }
    const remarkWindowProps = {
        // isActive: true,
        data: remarkData,
        handleSave: handleSaveRemark
    }
  return (
    <Switch>
        <Route path={path} exact>
            <OrderlikeLayout label='ספירת מלאי'>
                {
                    isOpenRemarkWindow &&
                        <ColumModal
                            title={remarkData?.title || ''}
                            Children={RemarkModal}
                            controlFunc={handleRemarkModal}
                            childrenProps={remarkWindowProps}
                        />
                }
                <ControlSearchPannelLayout>
                    <DatePickerModule 
                        multyDate={false}
                        setSelectedDateStart={setDate}
                        setSelectedDateEnd={false}
                        defEndDate={false}
                        selected={false}
                        ignorBrod={true}
                        disabled={false}
                        large={false}
                    />
                    <SearchInput 
                        input={input}
                        setInput={setInput}
                    />
                </ControlSearchPannelLayout>
                <BodyScrollContainer>
                    <TitlesList 
                        list={renderList}
                        additionalData={Plist}
                        Element={ArizotAccountingItem}
                        uniqueKey={'id'}
                        noPadding={false}
                        

                    />
                </BodyScrollContainer>
                <Link to={LINKS.arizot.arizaMlai.create}>
                    <ControlButton
                        lable={'חדשה'}
                    />
                </Link>
            </OrderlikeLayout>
        </Route>
        <Route path={LINKS.arizot.arizaMlai.editGeneral} component={ArizotEditMain}/>
        <Route path={LINKS.arizot.arizaMlai.create} component={ArizotCreateMain}  />
    </Switch>
  )
}

export default ArizotAccoutingMain