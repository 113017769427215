import React, {useState, useMemo} from 'react'
import { ITeudotTypeItem } from '../../../../../../modules/API/Defaults/models/teudotTypes'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  list: ITeudotTypeItem[],
  defaultID: number,
  setTeudaTypeID: React.Dispatch<React.SetStateAction<number>>
  required?: boolean
}


const SearchTeudaTypes = ({
  list, defaultID, setTeudaTypeID, required 
}: Props) => {
  const defaultT = list.filter(item => item.SerialID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedTeudaType, setSelectedTeudaType] = useState<ITeudotTypeItem | null>(defaultT || null)
  const [search, setSearch] = useState<string>(defaultT?.TeudaDesc.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedTeudaType(null)
      setIsOpenDropDown(false)
      setSearch('')
      setTeudaTypeID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: ITeudotTypeItem) => {
    setSearch(item.TeudaDesc.trim())
    setSelectedTeudaType(item)
    setIsOpenDropDown(false)
    setTeudaTypeID(item.SerialID)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.TeudaDesc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])

  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={'חפש סוג תעודה'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='TeudaDesc'
      nameIdElement='SerialID'
      required={required}
      size={'full'}
    
    />
  )
}

export default SearchTeudaTypes