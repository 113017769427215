const viewWindow = {
"packageOrder_ViewWindow_title": 'คำสั่ง/คืน - บรรจุภัณฑ์ {{defID}}',
}

export const packageOrderTranslationTH = {
  packageOrder: {
    "packageOrder_orders_title": 'คำสั่ง/คืน - บรรจุภัณฑ์',
    "packageOrder_orders_search_placeholder": 'ค้นหา',
    "packageOrder_modal_teuda_title": 'คำสั่งบรรจุภัณฑ์: {{num}}',
    "packageOrder_modal_teuda_checkbox": 'ส่งอีเมล',
    "packageOrder_modal_remark_title": 'ภาชนะพลาสติก Dol',
    "packageOrder_modal_print_title": 'พิมพ์',
    "packageOrder_modal_print_order": 'คำสั่ง',
    "packageOrder_modal_print_certificate": 'ใบรับรอง',
    "packageOrder_NewOrder_lable_date": 'วันที่',
    "packageOrder_NewOrder_lable_sender": 'ผู้ส่ง',
    "packageOrder_NewOrder_lable_receipient": 'ผู้รับ',
    "packageOrder_NewOrder_lable_car": 'รถ',
    "packageOrder_NewOrder_lable_refference": 'อ้างอิง',
    "packageOrder_NewOrder_placeholder_sender": ' เลือกผู้ส่ง',
    "packageOrder_NewOrder_placeholder_receipient": ' เลือกผู้รับ',
    "packageOrder_NewOrder_placeholder_car": ' เลือกรถ',
    "packageOrder_NewOrder_placeholder_refference": 'อ้างอิง',
    "packageOrder_NewOrder_checkbox": 'ขอการจัดส่ง',
    ...viewWindow
  }
}