import styled from "styled-components";

const Wrapper = styled.option`
    
`

export default function FilterGoodsItem({GroupDesc, GroupID}){


    return(

        <Wrapper value={GroupID}>
            {GroupDesc}
        </Wrapper>
    );
}