import React from "react";
import styled from "styled-components";
import selectOnFocus from "../../modules/autoselect";

type Props = {
    defValue?: number | string,
    handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void
    icon: JSX.Element,
    isDisabled: boolean,
    onBlur?: () => void,
    bgColor?: string,
    value?: number | string,
    iconColor?: string
    width?: number,

};

const RowInput = ({
  defValue, handleInput, icon, isDisabled, 
  onBlur, bgColor, value, iconColor, width
}: Props) => {

  return (
    <InputWrapper 
      width={width}
    >
      <InputIcon iconColor={iconColor}>{icon}</InputIcon>
      <Input
        type={"number"}
        min={0}
        max={999}
        defaultValue={defValue}
        onChange={handleInput}
        disabled={isDisabled}
        inputMode="decimal"
        onFocus={selectOnFocus}
        onBlur={onBlur}
        bgcolor={bgColor}
        value={value}
      />
    </InputWrapper>
  );
};
export default RowInput
const InputWrapper = styled.div<{width?: number}>`
    position: relative;
    width: ${props => props.width ? `${props.width}em` : 'auto'};
    max-height: 2.5em;
    
`
const InputIcon = styled.div<{iconColor?: string}>`
    position: absolute;
    /* height: 2em;
    width: 2em; */
    top: calc(50% - 0.7em);
    right: 0.2em;
    color: ${props => props.iconColor ? props.iconColor : '#297F76'};
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
`
const Input = styled.input<{bgcolor?: string}>`
    /* direction: rtl; */
    min-width: 4em;
    width: 100%;
    height: 100%;
    border-radius: 10px 5px 10px 10px;
    padding: 0.2em 0.5em;
    outline: none;
    border: none;
    color:#7ab0ab;
    border-bottom: 2px solid #7ab0ab;
    background: ${props => props.bgcolor ? props.bgcolor : '#fff'};
    :disabled {
        opacity: 0.7;
        background:${props => props.bgcolor ? props.bgcolor : '#fff'};
    }
`
