import { useSelector } from 'react-redux';
import styled from 'styled-components'
import DbItem from './dbItem'

const Wrapper = styled.div`
    width: 100%;
    background: #fff;
    position: absolute;
    border-radius: 5px;
    z-index: 2;
    max-height: 10rem;
    overflow: auto;
    
    

`


export default function DbList({dataBase, setInput, input, setChooseDb, saveValue, patchDB, chooseDb, setInputDefault, showDbList}){
    const getID = useSelector(token => token.mainReducer.user.company_id);

    return(
        <Wrapper style={{display: showDbList}}>
            {dataBase.map((item) => <DbItem key={item.id}{...item} getID={getID} setInput={setInput} input={input} setChooseDb={setChooseDb} setInputDefault={setInputDefault} patchDB={patchDB}/>)}
        </Wrapper>
    );
}