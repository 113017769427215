import { useMemo, useState } from "react";
import styled from "styled-components";
import { PackageIcon, PalletIcon } from "../../../../../generic/icons/Icons";
import TitlesList from "../../../../../generic/titlesRowItem/TitlesList";
import AddArizotItem from "./arizotAddPacks/addArizotItem";
import AddArizotList from './arizotAddPacks/addArizotList'


const Wrapper = styled.div`
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    display: flex;
    overflow: hidden;
    background: rgba(255,255,255, 0.8);
    padding: 0.5em;
    transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
    @media screen and (max-width: 600px) {
        min-width: 100%;
    }

`
const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  /* background: blue; */

  display: flex;
  flex-direction: column;
`;
const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
  border-radius: 30px 30px 0 0;
`;

const Tab = styled.div`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${props => 
        props.active ? 
            `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
            :
            `
            
            `
  }
`;


export default function ArizotAddBody({
    sideToggle, packs, handleRemarkModal,
    patchState, setRemarkData, isDisabled=false, isActiveButton=true
}) {
    const [activeTab, setActiveTab] = useState('הכול');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  console.log(123)
  const render = useMemo(() => {
    if (!packs) return
    if (activeTab && activeTab !== 'הכול') {
        return packs.filter((item) => item.Pack_Pallet === activeTab);
      } else {
        return packs;
      }
  }, [activeTab, packs])

  const uniqueTypes = useMemo(() => {
    let data = Array.from(new Set(packs?.map((item) => item.Pack_Pallet)));
    data.push('הכול')

    return data
  }, [packs])

  const addictionalData = {
    patchState: patchState,
    setRemarkData: setRemarkData,
    handleRemarkModal:handleRemarkModal,
    isDisabled: isDisabled,
    isActiveButton: isActiveButton
  }
    return(
        <Wrapper style={{transform: sideToggle}}>
            <Container >
                <TabContainer>
                    {uniqueTypes.map((type) => (
                        <Tab
                            key={type}
                            active={activeTab === type}
                            onClick={() => handleTabClick(type)}
                        >
                            {type === 'מ' ? <PalletIcon /> : type === 'א' ? <PackageIcon /> : type}
                        </Tab>
                    ))}
                </TabContainer>
                <TitlesList
                    list={render}
                    uniqueKey={'id'}
                    Element={AddArizotItem}
                    noPadding={false}
                    borderLess={true}
                    additionalData={addictionalData}
                />
                    {/* <AddArizotList packs={packs} palletPacks={palletPacks} setPalletPacks={setPalletPacks} /> */}
            </Container>
        </Wrapper>
    );
}