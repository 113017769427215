import React, { useEffect, useRef, useState } from 'react'
import { TOrderStatusList } from '../../../../../../models/Defaults/orderSatuses';
import { SelectedIndicator } from '../../../../../generic/customSearchComponents/SelectFromTheList';
import { useDetectOutsideClick } from '../../../../../generic/hooks/useDetectOutsideClick'
import { AcceptIcon, PlusIcon } from '../../../../../generic/icons/Icons';
import { ButtonWrapper, ClearButton, FilterBodyItemContainer, FilterBodyWrapper, FilterIndicator } from '../../trucks/MultiSelect';
import OrderSelectItem from './OrderSelectItem';

type Props = {
    controlFunc: (id?: number | null, clear?: boolean) => void;
    data: TOrderStatusList;
    state: Record<number, boolean>
}

const SelectOrderToShow = ({controlFunc, data, state}: Props) => {
    const [isOpenDistrictSelect, setIsOpenDistrictSelect] = useState(false);
    const bodyRef = useRef(null)
    const openCloseBody = (): void => {
      if(isOpenDistrictSelect) {
        setIsOpenDistrictSelect(false)
      } else {
        setIsOpenDistrictSelect(true)
      }
    }
    const selectedIcon = <AcceptIcon />
    const iconSelector = isOpenDistrictSelect ? <p style={{transform: 'rotate(45deg)'}}><PlusIcon /></p> : selectedIcon
    const isSelected = Object.values(state).some(e => !e)
    useDetectOutsideClick(bodyRef, () => {setIsOpenDistrictSelect(false)})

  return (
    <FilterIndicator>
      {
        isSelected &&
          <SelectedIndicator />
      }
      <ButtonWrapper onClick={openCloseBody}>
        {iconSelector}
      </ButtonWrapper>
      <FilterBodyWrapper
        ref={bodyRef}
        isOpen={isOpenDistrictSelect}
        isReversePosition
      >
        {/* {
          isSelected &&
            <ClearButton onClick={()=> {controllFunc(null, true)}}>מחק</ClearButton>
        } */}
        <FilterBodyItemContainer>
          {data.map((item) => (
            <OrderSelectItem
              key={item.id}
              item={item}
              {...item}
              controlFunc={controlFunc}
              state={state}
            />
          ))}
        </FilterBodyItemContainer>
      </FilterBodyWrapper>
    </FilterIndicator>
  );
};


export default SelectOrderToShow