import React, { FC, useRef, useState } from "react";
import styled from "styled-components";
import DestinationItem, { DestinationItemProps } from "./DestinationItem";
import { LocationIcon, PlusIcon, SpeedIcon } from "../../../../generic/icons/Icons";
import { MarketingAreaObjectP } from "../../../../../models/tableOfTables/marketingArea";
import { useDetectOutsideClick } from "../../../../generic/hooks/useDetectOutsideClick";
import { SelectedIndicator } from "../../../../generic/customSearchComponents/SelectFromTheList";
import { ITableOfTableItem } from "../../../../modules/API/Defaults/models/tableOfTables";

type Props = {
  data: ITableOfTableItem[];
  controllFunc: (item: ITableOfTableItem | null, clear?: boolean) => void;
  selectedDistricts: ITableOfTableItem[];
  Icon?:any,
  isReversePosition?: boolean;
};

interface FilterP {
    common?: boolean; 
    isOpen?: boolean;
    isReversePosition?: boolean;
}
export const FilterIndicator = styled.div<FilterP>`
    position: relative;
    height: 3.5em;
    min-width: 3.5em;
    background: #eff3f4 ;
    border-radius: 50%;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: #297f76;
    display: flex;
    justify-content: center;
    align-items: center;
    
    :hover {
        background: ${props => props.common ? '#eff3f4' : '#297f76'} ;
    }
    
`

export const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #eff3f4;
    border-radius: 30px;
    align-items: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    :hover {
        transform: translateX(3px) rotate(45deg) translateY(-3px);
        
       
    }
    /* :hover ${FilterIndicator}  {
        background: #297f76 ;
       
    } */
`

export const FilterBodyWrapper = styled.div<FilterP>`
    position: absolute;
    bottom: -290px;
    left: ${props => props.isReversePosition ? 0 : 'none'};
    right: ${props => props.isReversePosition ? 'none' : 0};
    width: 300px;
    height: 290px;
    background: #eff3f4;
    z-index: 2;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1em;
    padding: 1em;
    border-radius: 30px;
    -webkit-filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    img {
        min-width: 300px;
    }

`
export const FilterBodyItemContainer = styled.div`
    width: 100%;
    padding: 0.2em;
    background: #fff;
    border-radius: 30px;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
    /* gap: 0.5em; */
    ::-webkit-scrollbar {
        width: 0;
    }
`
export const ClearButton = styled.div`
  position: absolute;
  top: 0.5em;
  left: 1em;
  padding: 0.2em 0.4em;
  background: tomato;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;

`

const MultiSelect = ({ 
  data, controllFunc, selectedDistricts, Icon,
  isReversePosition
}: Props) => {
    const [isOpenDistrictSelect, setIsOpenDistrictSelect] = useState(false);
    const bodyRef = useRef(null)
    const openCloseBody = (): void => {
      if(isOpenDistrictSelect) {
        setIsOpenDistrictSelect(false)
      } else {
        setIsOpenDistrictSelect(true)
      }
    }
    const selectedIcon = Icon ? <Icon /> : <LocationIcon />
    const iconSelector = isOpenDistrictSelect ? <p style={{transform: 'rotate(45deg)'}}><PlusIcon /></p> : selectedIcon
    const isSelected = selectedDistricts.length > 0
    useDetectOutsideClick(bodyRef, () => {setIsOpenDistrictSelect(false)})
  return (
    <FilterIndicator>
      {
        isSelected &&
          <SelectedIndicator />
      }
      <ButtonWrapper onClick={openCloseBody}>
        {iconSelector}
      </ButtonWrapper>
      <FilterBodyWrapper
        ref={bodyRef}
        isOpen={isOpenDistrictSelect}
        isReversePosition={isReversePosition}
      >
        {
          isSelected &&
            <ClearButton onClick={()=> {controllFunc(null, true)}}>מחק</ClearButton>
        }
        <FilterBodyItemContainer>
          {data.map((item) => (
            <DestinationItem
              key={item['SecondCode']}
              item={item}
              {...item}
              controlFunc={controllFunc}
              selectedDistricts={selectedDistricts}
            />
          ))}
        </FilterBodyItemContainer>
      </FilterBodyWrapper>
    </FilterIndicator>
  );
};

export default MultiSelect;
