import React, {useState, useRef} from 'react'
import TitleLayout from '../../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../../kosherTeudot/generic/TitleRowItem'
import { IMarketPallet, IMarketPalletDetails } from '../../../../../../../modules/API/Market/models/marketRequests'
import { AppleIcon, CloseIcon, DeleteIcon, PackageIcon, ScalesIcon, ShekelIcon, VerticalDots } from '../../../../../../../generic/icons/Icons'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../../../../../../../generic/hooks/useDetectOutsideClick'

interface Props extends IMarketPalletDetails {
    selectProduct: (data: IMarketPalletDetails, sellerID: number) => void;
    item: IMarketPalletDetails;
    deleteProduct: (pallet: number, client: string, recID: number) => void;
    CustomerID: number;
    isWorker: boolean;
    CustomerSeller: string;
    itemPallet: IMarketPallet
}

const ProductItem = ({
    ProductDesc, VarietyDesc, SizeID, WeightNeto, CustomerID,
    SalePrice, Quantity, selectProduct, item, deleteProduct, RecID,
    isWorker, CustomerSeller, itemPallet, InvoiceSale
}: Props) => {
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const handleDeleteProduct = (e: React.MouseEvent<HTMLElement>) => {
            if (InvoiceSale !== 0) return
            setIsOpenMenu(false); 
            e.stopPropagation();
            deleteProduct(item.Pallet_Num, CustomerSeller, RecID)

            
    }
    const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setIsOpenMenu(!isOpenMenu)
    }
    useDetectOutsideClick(menuRef, () => setIsOpenMenu(false));
  return (
    <TitleLayout
        onClick={() => selectProduct({...item, CustomerID}, itemPallet.Reseller_Code)}
        isDelete={isDelete}
    >
        {
            InvoiceSale === 0 &&
            <SideWrapper 
                onClick={handleOpenMenu}
            >
                <VerticalDots />
            </SideWrapper>
        }
        <MainWrapper 
            onClick={e => e.stopPropagation()}
            ref={menuRef} 
            isOpen={isOpenMenu}
        >
            <SideWrapper>
                <CloseIcon 
                    handler={handleOpenMenu}
                />
            </SideWrapper>
            <DeleteButton
                onClick={handleDeleteProduct}
            >
                <DeleteIcon />
            </DeleteButton>
        </MainWrapper>
        <TitleRow>
            <TitleRowItem 
                name={`${ProductDesc.trim()} ${VarietyDesc.trim()} ${SizeID.trim()}`}
                icon={<AppleIcon />} 
            />
        </TitleRow>
        <TitleRow>
            {
                !isWorker && 
                    <TitleRowItem 
                        name={`${SalePrice.toFixed(1)}`}
                        icon={<ShekelIcon />} 
                        iconColor={SalePrice === 0 ? 'tomato' : ''}
                    />

            }
            <TitleRowItem 
                name={`${Quantity}`}
                icon={<PackageIcon />} 
                iconColor={Quantity <= 0 ? 'tomato' : ''}
            />
            <TitleRowItem 
                name={`${WeightNeto.toFixed(1)}`}
                icon={<ScalesIcon />} 
                iconColor={WeightNeto <= 0 ? 'tomato' : ''}
            />
        </TitleRow>
    </TitleLayout>
  )
}
const SideWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 3em;
    height: 2em;
    display: flex;
    padding-right: 0.5em;
    justify-content: center;
    align-items: center;
    color: #297F76;
    cursor: pointer;
    /* background: red; */
`
const MainWrapper = styled.div<{isOpen: boolean}>`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 20px;
    transition: 0.5s ease-in-out;
    transform: ${props => props.isOpen ? "translateX(0)" : "translateX(-110%)"};
    display: flex;
    justify-content: flex-end;
    padding: 0.5em;
`
const DeleteButton = styled.div`
    width: 3em;
    height: 100%;
    background: tomato;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #fff;
    cursor: pointer;
`
export default ProductItem