import React from 'react'
import styled from 'styled-components'
import LoaderSpinner from '../../../../../modules/loadSpinner/LoaderSpinner'

type Props = {}

const CustomBlockLoader = () => {
  return (
    <MainWrapper>
        <LoaderSpinner />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export default CustomBlockLoader