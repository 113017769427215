import React from 'react'
import styled from 'styled-components'
import CustomerFilter from './CustomerFilter'
import InvoiceFilter from './InvoiceFilter'
import PriceFilter from './PriceFilter'
import { IGrowerItem } from '../../../../../../modules/API/Defaults/models/growers'
import GrowerFilter from './GrowerFilter'

type Props = {
  selectedID: number,
  setCustomerID: React.Dispatch<React.SetStateAction<number>>,
  growerList: IGrowerItem[],
  growerID: number,
  setGrowerID: React.Dispatch<React.SetStateAction<number>>,
  //invice
  valueM: number,
  valueK: number,
  setValueInvoice: (e: number, isMihira: boolean) => void,
  //price
  pvalueM: number,
  pvalueK: number,
  setValuePrice: (e: number, isMihira: boolean) => void,

  rights: string
}

const SettingsMain = ({
  selectedID,
  setCustomerID,
  valueM,
  valueK,
  setValueInvoice,
  pvalueM,
  pvalueK,
  setValuePrice,
  rights,
  growerList,
  growerID,
  setGrowerID
}: Props) => {
  return (
    <MainWrapper>
      <InnerContent>

        <CustomerFilter
          selectedID={selectedID}
          setCustomerID={setCustomerID}
        />
        <GrowerFilter
          growerList={growerList}
          growerID={growerID}
          setGrowerID={setGrowerID}
        />
        <InvoiceFilter
          valueM={valueM}
          valueK={valueK}
          setValue={setValueInvoice}
          rights={rights}
        />
        <PriceFilter
          valueM={pvalueM}
          valueK={pvalueK}
          setValue={setValuePrice}
          rights={rights}
        />
      </InnerContent>

    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  color: #297F76;
  background-color: #F5F5F5;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1em 0;
  border-radius: 30px;
`
const InnerContent = styled.div`
  width: fit-content;
  display: flex;
  flex-flow: column;
  gap: 1em;
  
`
export default SettingsMain