import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import { Context } from "../../main/second/mainbar/secondbar/azmanotContext";
import { ContextModal } from "../modal/modalContext"
import { getAzmanot } from '../requests/getAzmanot'
import styled from "styled-components";

const Wrapper = styled.div`
    max-width: 150px;
    span {
      display: none;
    }
  .datePicker {
    height: 3.2em;
    border: none; 
    padding: 0.5em 1em; 
    max-width: 100%;
    text-align: right;
    border-radius: 30px;
    background: #eff3f4;
    font-size: 1.1em;
    max-width: 150px;
    color: #838484;
    outline: none;
  }
`
const AzmanotDataPicker = ({ setDate, setAzmanotDefault, dayJump, setSelectedDateStart, setSelectedDateEnd }) => {
  const getToken = useSelector(token => token.mainReducer.token);
  const axios = require('axios');
  const CancelToken = axios.CancelToken.source();
  
  const [modal, setModal] = useContext(ContextModal)
  const [azmanot, setAzmanot] = useContext(Context)
  
  const defDate = new Date(Number(new Date()));
  let a = defDate.setDate(defDate.getDate() + 4);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(a);

  // console.log(endDate)

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    
  };
  
  const formedDateStart = format(startDate, "yyyyMMdd");
  const formedDateEnd = endDate ? format(endDate, "yyyyMMdd") : formedDateStart;

  
  
  const data = format(startDate, "yyyyMMdd")
  const dataEnd = endDate ? format(endDate, "yyyyMMdd") : data

  useEffect(() => {
    console.log('active')
    setSelectedDateStart(formedDateStart);
    setSelectedDateEnd(formedDateEnd);
    // getAzmanot(getToken, data, dataEnd, setAzmanot, setAzmanotDefault, modal, setModal );
    return () => { CancelToken.cancel("axios request cancelled") } 
  }, [endDate])

  return (
    <Wrapper>
      <DatePicker
        className="datePicker"
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        shouldCloseOnSelect={false}
        dateFormat="dd/MM/yyyy"
      />
    </Wrapper>
  );
};

export default AzmanotDataPicker