import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LOAD, LOAD_END, UPDATE_ENTRIES } from '../../../../../../store/actions/actionsTypes'
import { IMiunData } from '../../../../../../store/reducers/miunReducer'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { Miun } from '../../../../../modules/API/Miun/miun'
import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import CreateEntrieItem from './CreateEntrieItem'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import DivideModal from '../modal/DivideModal'
import { IPutDivideData } from '../../../../../modules/API/Miun/models/fetchData'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import styled from 'styled-components'
import { AppleIcon, BarCodeIcon, LocationIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { useHistory } from 'react-router-dom'
import useScannedDataHandler from '../../kosherTeudot/Hooks/useScannedDataHandler'
import ScanInput from '../../kosherTeudot/Components/ScanInput'


type Props = {
  saveFunc?: () => void
}
interface IRef {
  focusInput: () => void;
  blurInput: () => void;
}
const CreateMiun = ({saveFunc}: Props) => {
  const storedData = useSelector((state: RootState): IMiunData => state.miunReducer);
  const initialIgenState = useMemo(() => {
    if(!storedData.selectedSortingItem?.Entries) return []
    return addIDForTheList(storedData.selectedSortingItem?.Entries.map(e => {
      e.isSelected = true
      e.Given = true
      e.Type = 'במיון'
      return e
    }))
  }, [storedData])
  const [modal, setModal] = useContext(ContextModal);

  const [input, setInput] = useState<string>('');
  const [entries, setEntries] = useState<IEntriesItem[]>()
  const [givenEntries, setGivenEntries] = useState<IEntriesItem[]>(initialIgenState)
  const [isOpenDivideModal, setIsOpenDivideModal] = useState(false)
  const [selectedItemForDivide, setSelectedItemForDivide] = useState<IEntriesItem>()
  const dispatch = useDispatch();
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState('למיון');
  const refBody = useRef<IRef | null>(null);

  const handleOpenDivideModal = () => {
    setIsOpenDivideModal(!isOpenDivideModal)
  }
  const selectForDivide = (item: IEntriesItem) => {
    if(item.AmountPack <= 1) {
      setModal({
          open: true,
          value: "can not divided",
          mood: false
      })
      setTimeout(() => {
          setModal({
              ...modal,
              open: false
          })
      }, 3000)
    return
    }
    setSelectedItemForDivide(item)
    handleOpenDivideModal()
  }
  
  const fetchDivide = async (data: IPutDivideData, restItem: IEntriesItem, item: IEntriesItem) => {
    dispatch({type: LOAD})
    try {
      let res: IEntriesItem = await Miun.putDivideEntrie(data)
      let toggle = restItem.Given 
      if(!entries) return
      let initialArr = toggle ? givenEntries : entries
      let newArr = [...initialArr].map(item => {
        if(item.EntryNum === restItem.EntryNum && item.DetailNum === restItem.DetailNum) {
          item = {
            ...restItem,
            Gross: Math.round(item.Gross - data.Gross),
            isSelected: item.isSelected
          }
        }
        return item
      })
      let pushItem = addIDForTheList([{
        ...res,
        isSelected: item.isSelected,
        Given: restItem.Given,
        Type: 'למיון'
      }])
      newArr.push(...pushItem)
      if(toggle) {
        setGivenEntries(newArr)
      } else {
        setEntries(newArr)
      }
      handleOpenDivideModal()
    } catch (err) {
      
    } 
    finally {
      dispatch({type: LOAD_END})
    }
  }
  
  const saveDevidedItem = (item: IEntriesItem, qty: number, restItem: IEntriesItem) => {
    if(qty < 1 || item.AmountPack <= qty){
      setModal({
          open: true,
          value: "can not divided",
          mood: false
      })
      setTimeout(() => {
          setModal({
              ...modal,
              open: false
          })
      }, 3000)
      return
    }

    const putData = {
      AmountPack: +qty,
      DetailNum: item.DetailNum,
      EntryNum: item.EntryNum,
      Gross: Math.round((item.Gross / item.AmountPack) * qty),
      OutPackId: item.OutPackId
    }
    fetchDivide(putData, restItem, item)
    
  }

  const MoveEntriesAPI = async (item: IEntriesItem, given?: boolean) => {
    let dataFetch = {
      "DetailNum": item.DetailNum,
      "EntryNum": item.EntryNum,
      "Gross": item.Gross,
      "SortNum": storedData.selectedSortingItem?.Sorting_Num || 0
    }


    try {
      await given ? Miun.unLinkEntrie(dataFetch) : Miun.linkEntrie(dataFetch)
      moveItem(item, given)
    } catch (err) {

    }
  }
  useEffect(() => {
    (async function getEntries() {
      if(!storedData.selectedSortingItem) return
      dispatch({type: LOAD})
      try {
        const res = await Miun.getEtriesForSortingByNum(storedData.selectedSortingItem?.Sorting_Num)
        setEntries(addIDForTheList(res).map(e => {
          e.Selected = false
          e.Type = 'למיון'
          return e
        }));
      } catch (err) {

      } finally {
        dispatch({type: LOAD_END})
      }
    })()
  }, [])

  const render = useMemo(() => {
    if(!entries) return
    let unionArr = [...entries, ...givenEntries]
    if(selectedTab) {
      unionArr = unionArr.filter(e => e.Type === selectedTab)
    }
    unionArr = unionArr.filter(item => 
      item.EntryDetailNum.includes(input.toLowerCase())
    )
    return unionArr
  }, [input, entries, givenEntries, selectedTab])

  const changeWeight = async (item:IEntriesItem, qty: number) => {
    let dataFetch = {
      "DetailNum": item.DetailNum,
      "EntryNum": item.EntryNum,
      "Gross": qty,
      "OutPackId": item.OutPackId
    }
    try {
      await Miun.changeEntrieData(dataFetch)
      if(!entries) return
      let temp = item.Given ? [...givenEntries] : [...entries]
      let arr: IEntriesItem[] = temp.map((e) => 
        {
          if(e.EntryNum === item.EntryNum && e.DetailNum === item.DetailNum) {
            e.Gross = qty
          }
          return e
        }
        )
        if(item.Given) {
            setGivenEntries(arr)
        } else {
          setEntries(arr) 

        }
    } catch (err) {

    }
  }
  const moveItem = (item: IEntriesItem, given?: boolean) => {
    console.log(item)
    if (!entries) return
    let unLinked = [...entries]
    let linked = [...givenEntries]
    
    if(!given) {
      unLinked = unLinked.filter(e => 
        !(e.DetailNum === item.DetailNum && e.EntryNum === item.EntryNum)
      )
      console.log(unLinked)
      linked.push({
        ...item,
        Given: true,
        Type: 'במיון'
      })
    } else {
      linked = linked.filter(e => 
        !(e.DetailNum === item.DetailNum && e.EntryNum === item.EntryNum)
      )
      unLinked.push({
        ...item,
        Given: false,
        Type: 'למיון'
      })
    }
    setEntries(unLinked);
    setGivenEntries(linked)
    dispatch({type: UPDATE_ENTRIES, payload: linked})
  }
  
  const handleInputFunction = (e: React.FormEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value)
    setInput(e.currentTarget.value)
  }
  const EntrieProps = {
    selectForDivide: selectForDivide,
    moveItem: MoveEntriesAPI,
    changeWeight: changeWeight
  }
  const divideModalP = {
    item: selectedItemForDivide,
    saveFunc: saveDevidedItem
  }
  const Etabs: ['במיון', 'למיון'] = ['במיון', 'למיון']
  const handleTabClick = (type: 'במיון' | 'למיון') => {
    
    setSelectedTab(type)
  }
  const handleBack = () => {
    history.goBack()
    if(!saveFunc) return
    saveFunc()
  }
  useScannedDataHandler(refBody)
  return (
    <OrderlikeLayout label={`#${storedData.selectedSortingItem?.Sorting_Num} - שליפת כניסות למיון`}>
      {
        isOpenDivideModal &&
          <ColumModal 
            title={`${selectedItemForDivide?.EntryNum}-${selectedItemForDivide?.DetailNum} פיצול כניסה`}
            Children={DivideModal}
            childrenProps={divideModalP}
            controlFunc={handleOpenDivideModal}
          />
      }
       <ContentTitleWrapper>
        <StatWrapper>
          <StatContent>
            {givenEntries.length}
          </StatContent>
          <StatLable>
            במיון
          </StatLable>
        </StatWrapper>
        <TitleInnerWrapper>
          <TitleRowWrapper>
            <TitleItem>
              {`${storedData.selectedSortingItem?.ProductDesc?.trim()} ${storedData.selectedSortingItem?.VarietyDesc?.trim()}`}
              <AppleIcon/>
            </TitleItem>
          </TitleRowWrapper>
          <TitleRowWrapper>
            <TitleItem>
              {storedData.selectedSortingItem?.WarehouseDesc?.trim()}
              <LocationIcon/>
            </TitleItem>
            <TitleItem>
              {storedData.selectedSortingItem?.GrowerName?.trim()}
              <UserIcon/>
            </TitleItem>
          </TitleRowWrapper>
        </TitleInnerWrapper>
      </ContentTitleWrapper>
      <ControlSearchPannelLayout>
        <ScanInput 
          ref={refBody}
          input={input}
          setInput={setInput}
          icon={<BarCodeIcon />}
        />
        {/* <SearchInput 
          enterFunc={handleInputFunction} 
          setInput={setInput}
          input={input}
        /> */}
      </ControlSearchPannelLayout>
     
      <BodyScrollContainer>
      <TabContainer>
        {Etabs.map((type) => (
          <Tab
            key={type}
            active={selectedTab === type}
            onClick={() => handleTabClick(type)}
          >
            {type}
          </Tab>
        ))}
      </TabContainer>
        <TitlesList 
          list={render}
          uniqueKey={'id'}
          additionalData={EntrieProps}
          Element={CreateEntrieItem}
          noPadding={false}
          borderLess
        />
      </BodyScrollContainer>
      
          <ControlButton
            lable={'סיים'}
            handleClick={handleBack}
          />
      
    </OrderlikeLayout>
  )
}

export default CreateMiun

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${(props) =>
    props.active
      ? `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
      : `
            
            `}
`;

const ContentTitleWrapper = styled.div`
  width: 100%;
  min-height: 5em;
  background: #fff;
  z-index: 1;
  border-radius: 30px;
  display: flex;
  padding: 0.5em 1em;
  color: #757575;
  i {
    color: #297f76;
  }
`
const StatWrapper = styled.div`
    min-width: 3em;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 0.2em;
    

`
const StatLable = styled.div`
  
`
const StatContent = styled.div`
    height: 3em;
    width: 3em;
    border-radius: 50%;
    background: #eff3f4;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: .5px solid #297f76; */
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
`

const TitleInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* background: blanchedalmond; */
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 0.5em;
`
const TitleRowWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: flex-end;
`
const TitleItem = styled.div`
  display: flex;
  gap: 0.5em;
`
