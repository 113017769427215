import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { statusAzmanotList } from '../../../../../generic/artcoded/statusAzmanot'

const WorkItemMainWrapper = styled.div`
  position: relative;
  min-width: 47%;
  height: 250px;
  background: #fff;
  border-radius: 30px;
  -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
  filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
`
const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Img = styled.div`
  position: relative;
  width: 50%;
  height: 100%;

  /* background: url('/images/over.png') center center no-repeat; */
  /* background-size: contain; */
`
export const ContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  /* background: blue; */
  display: flex;
  flex-flow: column;

`
export const ContentMainSection = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: drop-shadow(2px 3px 3px rgb(215,231,242));
  filter: drop-shadow(2px 3px 3px rgb(215,231,242));
`
export const InnetText = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #297f76;
  /* background: red; */
`
const Decorator = styled.div`
  position: absolute;
  width: 150px;
  height: 40px;
  bottom: -1em;
  border-radius: 30px;
  /* border: 4px solid rgba(129,180,175, 0.7); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297f76;

  font-size: 1.3em;
  /* background: #81b4af; */
`
const Svg = styled.svg`
  position: relative;
  width: 150px;
  height: 150px;
  
  /* background: red; */
  
  
`
const Circle = styled.circle`
  fill: none;
  stroke-width: 10;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  :nth-child(1) {
    stroke-dashoffset: 0;
    stroke: #f3f3f3;
  }
  :nth-child(2) {
    stroke-dashoffset: ${props => props.primal ? `calc(440 - (440 * ${props.primal}) / 100)` : '440'};
    stroke: #81b4af;
    border-radius: 50%;
    stroke-linecap: round;
  }

`


const WorkStatusItem = ({data, status, azmanotGeneralList}) => {
  // console.log(data.length)
  const selectImg = (status) => {
    const imgCollection = {
      0: "url('/images/order.png') center center no-repeat ",
      1: "url('/images/likut.png') center center no-repeat ",
      2: "url('/images/checked.png') center center no-repeat ",
      3: "url('/images/WR.png') center center no-repeat ",
      4: "url('/images/delivery.png') center center no-repeat ",
      5: "url('/images/delivery.png') center center no-repeat ",
      7: "url('/images/ahslanot.png') center center no-repeat",
      9: "url('/images/over.png') center center no-repeat",
    }
    return imgCollection[status]
  }
  const statusConverter = () => {
    const filtered = statusAzmanotList.filter(item => item.id === status)
    return filtered[0]?.Desc
}
  const calcAzmanotInPercents = () => {
    return (+data.length / +azmanotGeneralList.length) * 100
  }
  // console.log(calcAzmanotInPercents())
  return (
    <WorkItemMainWrapper>
      <InnerWrapper>
        <ContentWrapper>
            <ContentMainSection>
              <Decorator>
                {statusConverter()}
              </Decorator>
              <InnetText>
                {data.length}
              </InnetText>
              <Svg>
                <Circle primal={calcAzmanotInPercents()} cx="70" cy="70" r='70'></Circle>
                <Circle primal={calcAzmanotInPercents()} cx="70" cy="70" r='70'></Circle>
              </Svg>
            </ContentMainSection>
        </ContentWrapper>
        <Img style={{background: selectImg(status), backgroundSize: 'contain'}} />
      </InnerWrapper>
    </WorkItemMainWrapper>
  )
}

WorkStatusItem.propTypes = {
  data: PropTypes.array,
  status: PropTypes.number
}

export default React.memo(WorkStatusItem)