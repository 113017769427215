
import { CloseIcon } from '../../../../../../generic/icons/Icons';
import { ControlButton, ControlButtonWrapper } from './ButtonStyle';
import { useTranslation } from 'react-i18next';
export default function CloseModalAzmanot({closeModalAzmanot}) {
    const [t] = useTranslation(['buttons']);
    return(
        <ControlButtonWrapper >
            <ControlButton onClick={() =>{closeModalAzmanot()}}>
                {t('close')} <CloseIcon />  
            </ControlButton>
        </ControlButtonWrapper>
    );
}