import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import { IArizotLine } from '../../../../../modules/API/Packages/models/arizotLine'
import { Packages } from '../../../../../modules/API/Packages/packages'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import ArizotItem from './ArizotItem'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import RemarkModal from './RemarkModal'
import { IOrderPackageTitle } from '../../../../../modules/API/Packages/models/createPackageOrderModel'
import { ControlPanelWrapper } from '../../secondbar/modalAzmanot/modalAzmanot'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { LINKS } from '../../../../../Routs/config'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { PackageIcon } from '../../../../../generic/icons/Icons'
import TeudotModalBody from '../Modals/TeudotModalBody'
import { is } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'

interface IProps {
    linkBack?: string;
    searchParam?: string;
    closeFunc?: () => void;
    isAllowTeuda?: boolean;
    handleOpenTeudaWindow?: () => void;
}
interface IParams {
    id: string
} 

export interface IRemarkData {
    title: string,
    body: string,
    id: string | number
}
function ArizotViewMain({
    linkBack, searchParam, closeFunc, isAllowTeuda, 
    handleOpenTeudaWindow
} : IProps) {
    let {id} = useParams<IParams>();
    const defID = searchParam || id
    const [t] = useTranslation(['packageOrder', 'buttons'])
    const dispatch = useDispatch();
    const history = useHistory();
    const [modal, setModal] = useContext(ContextModal)
    const [arizotLines, setArizotLines] = useState<IArizotLine[]>();
    const [input, setInput] = useState<string>("");
    const [isOpenTeudaWindow, setIsOpenTeudaWindow] = useState<boolean>(false);
    const [isOpenRemarkWindow, setIsOpenRemarkWindow] = useState<boolean>(false);
    const [remarkData, setRemarkData] = useState<IRemarkData | null>(null);
    const [title, setTitle] = useState<IOrderPackageTitle>()
    const [isEditActive, setIsEditActive] = useState<boolean>(false)
    const pageBack = linkBack || LINKS.arizot.arizaAhzara.mainUrl

    const handleControlTeudaModal = () => {
        setIsOpenTeudaWindow(!isOpenTeudaWindow)
    }
    const getLines = async () => {
        dispatch({type: LOAD})
        try {
            let res = await Packages.getArizotLinesWithTitle(defID);
            const {Packs_Pallets, ...rest} = res

            setArizotLines(addIDForTheList(Packs_Pallets).map(e => {
                return {
                    ...e,
                    QtyTeuda: e.Qty
                }
            }))
            setTitle(rest)
        } catch (err) {
            
        } finally {
            dispatch({type: LOAD_END})
        }
    }

    const editOrderPackage = async () => {
        if(!arizotLines) return
        dispatch({type: LOAD})
        const mapped = arizotLines?.map(e => {
            const {id, QtyTeuda, ...rest} = e
            return rest
        })
        const dataReq = {
            Packs_Pallets: mapped
        }
        try {
            let res = await Packages.putPackageLines(defID, dataReq);
            setModal({
                open: true,
                value: 'הזמנה התנהלה בהצלחה',
                mood: true
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
            if(searchParam) {
                closeFunc?.()

            } else {
                history.push(pageBack)
            }
        } catch (err) {
            
        } finally {
            dispatch({type: LOAD_END})

        }
    }

    const renderList = useMemo(() => {
        const filtered = arizotLines?.filter(item => 
            item.Description.trim().toLocaleLowerCase().includes(input.toLocaleLowerCase())
        )
        return filtered
    }, [arizotLines, input])

    const handleRemarkModal = () => {
        setRemarkData(null)
        setIsOpenRemarkWindow(false);
    }

    const selectRemarkLine = (data: IRemarkData) => {
        setRemarkData(data)
        setIsOpenRemarkWindow(true)
    }
    const patchState = (qty: number, id: string) => {
        if (!arizotLines) return
        const newState = [...arizotLines]
        const found = newState.find(e => e.id === id);
        if (!found) return
        setIsEditActive(true)
        const indexOfItem = newState.indexOf(found);
        newState[indexOfItem].Qty = qty;
    
        setArizotLines(newState)
    
      }

    useEffect(() => {
        getLines()
    }, [id])

    const itemProps = {
        openRemark: selectRemarkLine,
        updateInput: patchState,
        isActive: title?.PO_Status === 0

    }
    const remarkWindowProps = {
        data: remarkData,
        close: handleRemarkModal
    }


    const createTeuda = async (sendMail: boolean) => {
        if(!arizotLines) return
        if(arizotLines.some(e => (e.QtyTeuda || 0) > e.Qty)) {
            setModal({
                open: true,
                value: "לא ניתן לתת מעבר להזמה",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            return
        }
        const postData = {
            PO_Num: +defID,
            ClientID_Recipient: title?.ClientID_Recipient,
            ClientID_Sender: title?.ClientID_Sender,
            PD_Date: title?.PO_Date,
            Vehicle_Num: title?.Vehicle_Num + '',
            Driver_Name: title?.Driver_Name,
            Remarks: title?.Remarks,
            Packs_Pallets: arizotLines.map(pack => {
                pack.Qty = pack.QtyTeuda || 0
                let {QtyTeuda, id, RecID, PD_ID, Remark, Description, Season, ...rest} = pack
                return rest
            })
        }
        console.log(postData)
        try{
            // console.log(selectedDetailesList)
            let res = await Packages.createTeuda(postData)
            
            setModal({
                open: true,
                value: "תעודה נוצרה בהצלחה",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            
            handleControlTeudaModal()
            if (sendMail) {
                await sendEmail(res.PD_Num)
            }
            closeFunc?.()
        } catch (err) {
            setModal({
                open: true,
                value: "משהו השתבש",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {

        }
    }
    const sendEmail = async (teudaNum: number) => {
        try {
            const res = await Packages.sendEmail(teudaNum);
            setModal({
                open: true,
                value: `דוא"ל נשלח בהצלחה`,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } catch (err: any) {
            console.log(err.response)
            if(err.response.status === 405) {
                setModal({
                    open: true,
                    value: "אין מייל",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            } else {
                setModal({
                    open: true,
                    value: "משהו השתבש",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
        }
    }
    const patchStateTeuda = (qty: number, id: string) => {
        console.log(123)
        if (!arizotLines) return
        const newState = [...arizotLines]
        const found = newState.find(e => e.id === id);
        if (!found) return
        const indexOfItem = newState.indexOf(found);
        newState[indexOfItem].QtyTeuda = qty;
        setArizotLines(newState)
        if(arizotLines.some(e => (e?.QtyTeuda || 0) > e.Qty)) {
            setModal({
                open: true,
                value: "לא ניתן לתת מעבר להזמנה",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
      }
    const teudaProps = {
        list: arizotLines,
        editFunc: patchStateTeuda,
        saveFunc: createTeuda
    }
  return (
    <OrderlikeLayout label={t('packageOrder_ViewWindow_title', {defID: defID})}>
        {
            isAllowTeuda && isOpenTeudaWindow &&
            <ColumModal 
                controlFunc={handleControlTeudaModal}
                Children={TeudotModalBody}
                childrenProps={teudaProps}
                title={`הזמנה אריזה #${defID}`}
            />
        }
        {
            isOpenRemarkWindow &&
                <ColumModal
                    title={remarkData?.title || ''}
                    Children={RemarkModal}
                    controlFunc={handleRemarkModal}
                    childrenProps={remarkWindowProps}
                />
        }
        {
            isAllowTeuda && !title?.PD_Num &&
            <ControlSearchPannelLayout>
                <span style={{color: '#297f76'}}> ? ליצור תעודה</span>
                
                <CircleActiveFilterBtn 
                    func={handleControlTeudaModal}
                    children={<PackageIcon />}
                />
            </ControlSearchPannelLayout>
        }
        <BodyScrollContainer>
            <TitlesList 
                list={renderList}
                uniqueKey={'id'}
                Element={ArizotItem}
                noPadding={false}
                additionalData={itemProps}
            />
        </BodyScrollContainer>
        <ControlPanelWrapper>
            {
                !searchParam ?
                    <Link style={{width: '100%'}} to={pageBack}>
                        <ControlButton lable={t('buttons:cancel')} />
                    </Link> 
                        :
                        <ControlButton lable={t('buttons:cancel')} handleClick={closeFunc} />

            }
            {
                isEditActive && title?.PO_Status === 0 &&
                    <ControlButton lable={t('buttons:save')} handleClick={editOrderPackage} />
            }
        </ControlPanelWrapper>
        
    </OrderlikeLayout>
  )
}

export default React.memo(ArizotViewMain)