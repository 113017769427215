import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode | React.ReactNode[]
}
const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #eff3f4;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    padding: 1em 0.5em;
    gap: 1em;
    z-index: 1;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0;
    }
`

function BodyWrapContainer({children}: Props) {
  return (
    <MainWrapper>
        {children}
    </MainWrapper>
  )
}

export default BodyWrapContainer