import { Link } from "react-router-dom"
import styled from "styled-components"


const Section = styled.section`
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items:center;

    
`


export default function NotFound(){

    return(
        <Section>
           USER DOESN'T HAVE ACCESS 
        </Section>
    )
}