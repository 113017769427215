
import React from 'react'
import { IPalletListWithDetails } from '../../../../../modules/API/Pallets/models/palletList'
import PalletDetailesItem from '../../kosherTeudot/create/PalletDetailesItem';
import PalletDetailsProductItem from './PalletDetailsProductItem';
import styled from 'styled-components';
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead';
import Image from '../../../../../generic/assets/package.png';
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList';
type Props = {
    palletFound: IPalletListWithDetails;
}

const PalletDetailsModal = ({
    palletFound
}: Props) => {
    return (
        <MainWrapper>
            <ImageWrapper>
                <ImageComponentHead
                    img={Image}
                    large
                />
            </ImageWrapper>
            <TitlesList
                list={palletFound.Details}
                uniqueKey={"RecID"}
                Element={PalletDetailsProductItem}
                noPadding={false}
                additionalData={{}}
                backgroundColor={"fff"}

            />
        </MainWrapper>
    )
}
const ImageWrapper = styled.div`
    width: 100%;
    height: 25%;
   
`
const MainWrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 1em;

`
export default PalletDetailsModal