import { startOfWeek, endOfWeek, eachDayOfInterval, format } from 'date-fns';
import { he } from 'date-fns/locale';
import { CalendarContainerProps } from '../types/dayColumn';

// Function to get the week array and formatted date range
export const getWeekDetailsByDate = (selectedDate: Date): [CalendarContainerProps[], string[]] => {
  // Determine the start and end of the week (Sunday to Saturday)
  const start = startOfWeek(selectedDate, { weekStartsOn: 0 , locale: he});
  const end = endOfWeek(selectedDate, { weekStartsOn: 0 , locale: he});

  // Generate an array of days from Sunday to Saturday
  const weekRange = eachDayOfInterval({ start, end }, );
  const weekRangeHebrew: CalendarContainerProps[] = weekRange.map(day => {
    return {
      dateDesc: format(day, 'E d', { locale: he }),
      dateRequestFormat: format(day, 'yyyyMMdd'),
      list: []
      
    }
  });
  console.log(weekRangeHebrew)
  // Format the date range for the week
  const formattedRange = [`${format(start, 'yyyyMMdd')}`, `${format(end, 'yyyyMMdd')}`];

  // Return both the array of week dates and the formatted string
  return [weekRangeHebrew, formattedRange];
}

