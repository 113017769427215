import { Route, Switch, useRouteMatch } from 'react-router-dom'
import TitlesMain from './TitlesMain';
import LikutMain from './LikutMain';
import { LINKS } from '../../../../../Routs/config';

const Index = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route 
        exact  
        path={path}
        component={TitlesMain}
      />
      <Route 
        path={LINKS.mishtahim.likutPrahim.editGeneral}
        component={LikutMain} 
        />
    </Switch>
  )
}

export default Index