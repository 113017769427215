import React, { useState, useMemo, useContext, useRef } from 'react'
import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import BodyColumnLayout from './Layouts/BodyColumnLayout'
import SearchBlockWithTitle from '../procedures/Q8/Layouts/SearchBlockWithTitle'
import styled from 'styled-components'
import ColumnModal from '../../../../generic/Layouts/ColumModal'
import ImageComponentHead from '../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../generic/assets/warhouseWork.webp'
import useDataFetcher from '../knisot/hooks/useFetchData'
import { Pallets } from '../../../../modules/API/Pallets/pallets'
import SearchFromTheList from '../../../../generic/customSearchComponents/SearchFromTheList'
import { ILocationForPallet, IPutLocationData } from '../../../../modules/API/Pallets/models/locations'
import { ContextModal } from '../../../../modules/modal/modalContext'
import { IPalletListWithDetails } from '../../../../modules/API/Pallets/models/palletList'
import PalletDetailsModal from './Components/PalletDetailsModal'
import ControlButton from '../../../../generic/buttons/ControlButton'
import useFocus from '../../../../generic/hooks/useFocus'
import { useDispatch } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../store/actions/actionsTypes'
import { BarCodeIcon } from '../../../../generic/icons/Icons'
import ImageWrapper from './Components/ImageWrapper'
import PalletNumSwitcher from './Components/PalletNumSwitcher'
import PalletInput from './Components/PalletInput'
const PalletLocationMain = () => {
    
    const dispatch = useDispatch()
    const [modal, setModal] = useContext(ContextModal)
    const [palletNumber, setPalletNumber] = useState<number>(0)
    const [searchLocation, setSearchLocation] = useState<string>("")
    const [isOpenLocationList, setIsOpenLocationList] = useState<boolean>(false)
    const [selectedLocation, setSelectedLocation] = useState<ILocationForPallet | null>(null)
    const [palletFound, setPalletFound] = useState<IPalletListWithDetails | null>(null);
    const [isOpenPalletDetails, setIsOpenPalletDetails] = useState<boolean>(false)
    const [actriveToggle, setActriveToggle] = useState<boolean>(false);
    const isSelectedExternalPallet = actriveToggle

    const handleToggle = () => {
        setActriveToggle(!actriveToggle)
    }

    const {
        data: dataLocations
    } = useDataFetcher<ILocationForPallet[]>(
        Pallets.getPalletLocations, "data"
    )

    const handleChangePalletNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPalletNumber(Number(e.target.value))
    }

    const handleChangeLocation = (e: string) => {
        setSearchLocation(e)
        setIsOpenLocationList(true)
        if (!e) {
            setSelectedLocation(null)
        }
    }

    const handleControlPalletDetails = () => {
        setIsOpenPalletDetails(!isOpenPalletDetails)
    }

    const getPalletByID = async () => {
        try {
            const toggle = isSelectedExternalPallet ? 1 : 0
            const res = await Pallets.getPalletWithDitailesByPalletNum(palletNumber, toggle)
            setPalletFound(res)
            handleControlPalletDetails()   

        } catch (error) {
            console.log(error)
            setModal({
                open: true,
                value: "משטח לא נמצא",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
    }


    const handleSelectLocation = (item: ILocationForPallet) => {
        setSelectedLocation(item)
        setSearchLocation(item.Description.trim())
        setIsOpenLocationList(false)
    }

    const renderLocations = useMemo(() => {
        if (!dataLocations) return []
        return dataLocations.filter((item: any) => {
            return item.Description.trim().toLowerCase().includes(searchLocation.toLowerCase().trim())
                || item.Code.trim().toLowerCase().includes(searchLocation.toLowerCase().trim())
        })
    }, [dataLocations, searchLocation])

   
    const palletDetailsProps = {
        palletFound: palletFound,
        handleControlPalletDetails: handleControlPalletDetails
    }

    const handleEnterFunc = () => {
        if (searchLocation && renderLocations?.length === 1) {
            setSelectedLocation(renderLocations[0])
            setIsOpenLocationList(false)
            setModal({
                open: true,
                value: "איתור נמצא",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } else {
            setSelectedLocation(null)
            if(renderLocations?.length === 0) {
                setModal({
                    open: true,
                    value: "איתור לא נמצא",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            } else {
                setModal({
                    open: true,
                    value: "בחר איתור מהרשימה",
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
        }
    }

    const putLocation = async () => {
        if(!palletFound || !selectedLocation) return
        dispatch({type: LOAD})
        try {
            let data: IPutLocationData = {
                ExtermalNum: isSelectedExternalPallet ? palletFound.Pallet_Num : 0,
                Location: selectedLocation?.Code || "",
                Pallet_Num: !isSelectedExternalPallet ? palletFound.Pallet_Num : 0
            }
            let res = await Pallets.putPalletLocation(data)
            setModal({
                open: true,
                value: "איתור נוסף בהצלחה",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            setSelectedLocation(null);
            setSearchLocation("");
            setPalletNumber(0);
            setPalletFound(null);
        } catch (error) {
            setModal({
                open: true,
                value: "משהו השתבש",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            dispatch({type: LOAD_END})
        }
    }

    return (
        <OrderlikeLayout
            label='עדכון איתור למשטח'
        >
            {
                isOpenPalletDetails && palletFound &&
                    <ColumnModal
                        title='פרטי משטח'
                        controlFunc={handleControlPalletDetails}
                        Children={PalletDetailsModal}
                        childrenProps={palletDetailsProps}
                    />
            }
            <BodyColumnLayout>
                <ImageWrapper
                    size='large'
                    img={Image}
                />
                <SearchBlockWithTitle
                    title='מספר משטח'
                >
                    <PalletNumSwitcher
                        handleToggle={handleToggle}
                        actriveToggle={actriveToggle}
                    />
                    <PalletInput 
                        isShowIcon={palletNumber !== 0}
                        value={palletNumber}
                        handleChangePalletNumber={handleChangePalletNumber}
                        handleFunction={getPalletByID}
                        iconVariationSwitcher={actriveToggle}
                    />
                </SearchBlockWithTitle>
                <SearchBlockWithTitle
                    title='איתור למשטח'
                >
                    <SearchFromTheList
                        list={renderLocations}
                        placeHolder={'הכנס איתור'}
                        input={searchLocation}
                        searchFunc={handleChangeLocation}
                        enterFunc={handleEnterFunc}
                        selectFunc={handleSelectLocation}
                        itemDisplayName='Description'
                        nameIdElement='id'
                        isNeedFullObject
                        isOpenList={isOpenLocationList}
                        size={'full'}
                    />
                </SearchBlockWithTitle>
                {
                    selectedLocation && palletFound &&
                        <ControlButton 
                            handleClick={putLocation}
                            lable={'עדכן'}
                        />
                }
            </BodyColumnLayout>
        </OrderlikeLayout>
    )
}

export default PalletLocationMain